
const CanvasZoomControls = ({stageRef, setStageScale, setStagePosition, barnLayout}) => {
  const zoomOut = () => {
    setStageScale((prevScale) => prevScale / 1.5) // Decrease scale
  }

  const zoomIn = () => {
    setStageScale((prevScale) => prevScale * 1.5) // Increase scale
  }

  const fitView = () => {
    const stage = stageRef.current
    if (!stage || !barnLayout.structure) return // Guard against missing stage or structure

    const padding = 30

    // Get the available space
    const containerWidth = stage.width()
    const containerHeight = stage.height()

    // Get the barn dimensions and position
    const layoutWidth = barnLayout.structure?.width || 1
    const layoutHeight = barnLayout.structure?.height || 1
    const structureX = barnLayout.structure?.x || 50
    const structureY = barnLayout.structure?.y || 50

    // Calculate scaling factors with padding
    const scaleX = (containerWidth - padding * 2) / layoutWidth
    const scaleY = (containerHeight - padding * 2) / layoutHeight

    // Use the smaller scale to maintain aspect ratio
    const newScale = Math.min(scaleX, scaleY)

    // Calculate center position considering structure's current position
    const offsetX = (containerWidth - layoutWidth * newScale) / 2 - structureX * newScale
    const offsetY = (containerHeight - layoutHeight * newScale) / 2 - structureY * newScale

    setStageScale(newScale)
    setStagePosition({
      x: offsetX,
      y: offsetY,
    })
  }

  // Styles as JS object
  const styles = {
    container: {
      position: 'absolute',
      bottom: '20px',
      left: '20px',
      zIndex: 1000,
      backgroundColor: '#fff',
      padding: '4px',
      borderRadius: '4px',
      boxShadow: '0 0px 6px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },
    button: {
      alignItems: 'center',
      background: '#eee',
      border: 'none',
      borderBottom: '1px solid #ddd',
      boxSizing: 'content-box',
      cursor: 'pointer',
      display: 'flex',
      height: '16px',
      justifyContent: 'center',
      padding: '6px',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
      width: '16px',
      borderRadius: '4px',
      transition: 'background-color 0.2s ease',
      '&:hover': {
        backgroundColor: '#e9e8ee',
      },
    },
    svg: {
      width: '12px',
    },
  }

  return (
    <div style={styles.container}>
      <button style={styles.button} onClick={zoomIn}>
        <svg style={styles.svg} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
          <path d='M32 18.133H18.133V32h-4.266V18.133H0v-4.266h13.867V0h4.266v13.867H32z'></path>
        </svg>
      </button>
      <button style={styles.button} onClick={zoomOut}>
        <svg style={styles.svg} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 5'>
          <path d='M0 0h32v4.2H0z'></path>
        </svg>
      </button>
      <button style={styles.button} onClick={fitView}>
        <svg style={styles.svg} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 30'>
          <path d='M3.692 4.63c0-.53.4-.938.939-.938h5.215V0H4.708C2.13 0 0 2.054 0 4.63v5.216h3.692V4.631zM27.354 0h-5.2v3.692h5.17c.53 0 .984.4.984.939v5.215H32V4.631A4.624 4.624 0 0027.354 0zm.954 24.83c0 .532-.4.94-.939.94h-5.215v3.768h5.215c2.577 0 4.631-2.13 4.631-4.707v-5.139h-3.692v5.139zm-23.677.94c-.531 0-.939-.4-.939-.94v-5.138H0v5.139c0 2.577 2.13 4.707 4.708 4.707h5.138V25.77H4.631z'></path>
        </svg>
      </button>
    </div>
  )
}
export default CanvasZoomControls
