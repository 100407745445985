import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { ListToolbarMain } from '../list-toolbar/ListToolbarMain';
import { KTCard, KTCardBody } from '../../../_metronic/helpers';
import OutputListingStatusBar from './OutputListingStatusBar';
import { useOutputContext, useOutputContextUpdater } from './OutputListingContext';
import { AgGridReact } from 'ag-grid-react';
import LoadingOverlay from './renderers/LoadingOverlay';
import { useIntl } from 'react-intl'
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {useAuth} from '../../../app/modules/auth'
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setCurrentListRecordIndex, setListIds } from '../../redux/reducers/currentDetailPageInfoReducer';
import { SaveModifiedColumnDefinitions, overwriteColumnDefinitions, getAllRecordIds, getSelectedRowIndex, clearSessionStorageByKeysPrefix, getNarrowHeaderHeight, getNarrowRowHeight, getNonSystemReportIds, getRowIndexForNonSystemReport } from '../../modules/sgl-utils/agGridHelpers';
import OutputListingBarMenu from './OutputListingBarMenu';
import { useSyncUpdateContext } from './synchronizeUpdateContext';
import useAccessChecker from '../hooks/use-access-checker';
import { FeesTabs } from '../list-toolbar/header/FeesTabs';
import axios from 'axios';
import { clearPreviousClassDetails } from '../../redux/reducers/previousClassDetailsReducer';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { useLoadingOverlay } from '../sgl-utils/DialogsProvider';

const OutputListingComponent = (props) => {
  const intl = useIntl()
  const [gridApi, setGridApi] = useState(null);
  const areasToAddFeeTabs = ['MasterFees', 'Fees']
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  let gridHeight = areasToAddFeeTabs.includes(props?.area) ? '95%' : '100%'
  const gridStyle = useMemo(() => ({ height: gridHeight, width: '100%'}), []);
  const outputContext = useOutputContext();
  const { showVerificationColumns, showLoadingOverlay, outputGrid, setOutputSearchType, setIsListOpen, setOutputSearchTerm , setSavedRefinedSelectionRecordIDs, getSearchStateKey, shouldHighlightSearch, shouldFocusSearch, ControlMovedManuallyToAGGrid, searchInputRef, isDetailToList, outputSearchTerm, outputSearchType, getOutputSelectionRecordsData, searchBehavior} = useOutputContext()
  const outputContextUpdater = useOutputContextUpdater()
  const dispatch = useAppDispatch();
  const {currentUser} = useAuth()
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const current_show_id = useAppSelector(state => state.currentShow.show_id);
  const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);
  const { addSubscribedEvents, removeAllEventListeners, sseReady, sse } = useSyncUpdateContext()
  const { hasSpecialPermissionToAccess } = useAccessChecker();
  const {showReportSelector, setShowReportSelector} = useOutputContext();
  const [os, setOS] = useState("");
  let refreshQueues = {}; // Separate queues for each area
  let refreshTimers = {}; // Separate timers for each area
  const isTabVisible = useRef(true);
  const loadingOverlay = useLoadingOverlay();

  const searchStateRef = useRef({
    searchValue: '',
    searchType: 'Replace' // Default value
  });

  // Update the ref whenever search term or type changes
  useEffect(() => {
    searchStateRef.current = {
      searchValue: outputSearchTerm,
      searchType: outputSearchType
    };
  }, [outputSearchTerm, outputSearchType]);

  useEffect(() => {
      setOS(getOS());
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      isTabVisible.current = !document.hidden;
    };
  
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
      ],
    };
  }, []);

  const getOS = () => {
      const userAgent = window.navigator.userAgent;
      if (userAgent.indexOf("Win") !== -1) return "Windows";
      if (userAgent.indexOf("Mac") !== -1) return "MacOS";
      if (userAgent.indexOf("X11") !== -1) return "UNIX";
      if (userAgent.indexOf("Linux") !== -1) return "Linux";
      return "Unknown";
  }


  // Helper function to get the search param based on the area
  function getOptionalSearchId(area){
    let triggeredId = '';

    switch(area){
      case 'Entries':
          triggeredId = 'entry_id';
          break;
  
      case 'Classes':
          triggeredId = 'class_id';
          break;
  
      case 'Shows':
          triggeredId = 'show_id';
          break;
  
      case 'Divisions':
          triggeredId = 'division_id';
          break;
  
      case 'TaxRates':
          triggeredId = 'show_tax_rates_id';
          break;
  
      case 'Showfees':
          triggeredId = 'showfees_id';
          break;
  
      case 'People':
          triggeredId = 'people_id';
          break;
  
      case 'Horses':
          triggeredId = 'horse_id';
          break;
  
      case 'Stables':
          triggeredId = 'stable_id';
          break;
  
      case 'CircuitDivisions':
          triggeredId = 'circuit_division_id';
          break;
  
      case 'Circuits':
          triggeredId = 'circuit_id';
          break;
  
      case 'MasterFees':
          triggeredId = 'master_fee_id';
          break;
  
      case 'Payments':
          triggeredId = 'payment_id';
          break;
  
      case 'ClassRules':
          triggeredId = 'class_rule_id';
          break;
  
      case 'Rings':
          triggeredId = 'ring_id';
          break;
  
      case 'Facilities':
          triggeredId = 'facility_id';
          break;
  
      case 'Organizations':
          triggeredId = 'organization_id';
          break;
  
      case 'ShowSeries':
          triggeredId = 'show_series_id';
          break;
  
      case "Barns":
          triggeredId = 'barn_id';
          break;
  
      case "PaymentBatches":
          triggeredId = 'payment_batch_id';
          break;
    }

    return triggeredId;
  }


  useEffect(() => { // Remove all existing event listener and then generate and add new events with callback in SSE on change of output grid area
    if (!sseReady) return;
    const logPrefix = `[Sync:${outputGrid.area}]`;
    removeAllEventListeners()
    if(outputGrid.area){
      let syncEvents = []
      let eventId = ''
      // For Areas who contains show_id should add show id in its event id
      switch(outputGrid.area){
        case 'Entries':
        case 'Classes':
        case 'Shows':
        case 'Divisions':
        case 'Fees': // Showfees entity name
        case 'TaxRates':
          eventId = `${outputGrid.area}-${customer_id}-${current_show_id}`
          break;

        default:
          eventId = `${outputGrid.area}-${customer_id}`
          break;
      }

      // Sync Event will hold an array of eventId and callback
      syncEvents.push({
        eventId, 
        callback: (event) => { 
          if (!isTabVisible.current) {
            console.log(`${logPrefix} Sync skipped because of inactive tab`)
            return; // Skip refresh for inactive tabs
          }
          //outputContextUpdater({ action: 'getServerData' });
          // Extract the data from the emitted event
          const eventData = JSON.parse(event.data);
          if(eventData?.additional_data?.triggered_id){
            const toggleSwitch = document.querySelector('.card-header .toggle-switch');
            const timeout_duration = toggleSwitch?.classList.contains('checked') ? 300000 : 60000;
            fetchAndUpdateRecord(outputGrid.area, eventData?.additional_data?.triggered_id, timeout_duration);
          }
          
        }
      })
      addSubscribedEvents(syncEvents)
      
    }

    // Cleanup function
    return () => {
      console.log(`${logPrefix} Cleanup - clearing timers`);
      // Clear all timers for this area on unmount
      Object.keys(refreshTimers).forEach(area => {
        if (refreshTimers[area]) {
          console.log(`${logPrefix} Clearing timer for area`, { area });
          clearTimeout(refreshTimers[area]);
          refreshTimers[area] = null;
        }
      });
    };
  }, [outputGrid.area, customer_id, current_show_id, sseReady]);

  // Fetch and update a record
  async function fetchAndUpdateRecord(area, triggered_id, timeout_duration) {
    try {
      //SK - Direct Grid Row update is temporarily disabled
      // let rowNode = null;
      // let optionalSearchIdField = getOptionalSearchId(area)
      // outputContext.outputGrid.gridApi.forEachNode((node) => {
      //   if (node.data[optionalSearchIdField] === triggered_id) {
      //     rowNode = node;    
      //   }
      // });

      // if (rowNode) {
      //   let response = await getSyncDataForGridUpdate(triggered_id)
      //   // Update the data for the specific row
      //   rowNode.setData(response);
      // } else {
        // If the row node is not found, queue the triggered_id for the area
        addRequestToQueue(area, triggered_id, timeout_duration);
        // console.warn('Row node for entry_id not found');
      // }

    } catch (error) {
      console.error('Error fetching updated record:', error);
    }
  }

  // Add request to the queue based on area and triggered_id
  function addRequestToQueue(area, triggered_id, timeout_duration) {
    const logPrefix = `[Sync:${area}]`;
    // Initialize queue for the area if not already created
    if (!refreshQueues[area]) {
      console.log(`${logPrefix} Initializing queue for area`);
      refreshQueues[area] = [];
    }
    
    // Add the triggered_id to the area's queue
    refreshQueues[area].push(triggered_id);
    console.log(`${logPrefix} Added to queue`, {
      queueLength: refreshQueues[area].length,
      triggeredId: triggered_id,
      currentQueue: refreshQueues[area]
    });

    
    // If no timer is running, refresh immediately and start a delay
    if (!refreshTimers[area]) {
      // console.log(`${logPrefix} No active timer, processing immediately`);
      //processRefreshQueue(area, triggered_id);  // Immediate refresh

      console.log(`${logPrefix} Setting refresh timer`, {
        timeoutDuration: timeout_duration
      });
      refreshTimers[area] = setTimeout(() => {
        console.log(`${logPrefix} Timer expired, processing queue`);
        processRefreshQueue(area, triggered_id); // Refresh after delay
        clearTimeout(refreshTimers[area]);
        refreshTimers[area] = null; // Clear timer reference after timeout
      }, timeout_duration); // delay for batching
    }else{
      console.log(`${logPrefix} Timer already active, request queued`);
    }
  }

  // Process the refresh queue for a specific area
  function processRefreshQueue(area, triggered_id) {
    const logPrefix = `[Sync:${area}]`;

    console.log(`${logPrefix} Processing queue`, {
      queueLength: refreshQueues[area]?.length || 0,
      triggeredId: triggered_id
    });

    if (refreshQueues[area]?.length > 0) {
      const currentSearchState = searchStateRef.current;

      // Skip if there's an active search with Add, Refine or Replace modes
      if (customer_id!==0 && currentSearchState.searchValue && (currentSearchState.searchType === 'Add' || currentSearchState.searchType === 'Refine'))
       {
        console.log(`${logPrefix} Sync skipped because of active search in add or refine mode`)
        refreshQueues[area] = [];
      }else{

      if(customer_id!==0){//Don't remove filters for site monitoring list due to sync refresh
          //const savedState = localStorage.getItem(outputGrid.area + "_" + currentUser.id + "_" + customer_id +'_searchState');
          const stateKey = getSearchStateKey(outputGrid.area, currentUser.id, customer_id);
          const savedState = sessionStorage.getItem(stateKey);
          if (savedState) {
            const stateJSON = JSON.parse(savedState);

            setOutputSearchTerm(stateJSON.outputSearchTerm);
            setOutputSearchType(stateJSON.outputSearchType);
            setSavedRefinedSelectionRecordIDs(stateJSON.savedRefinedSelectionRecordIDs);
            setIsListOpen(false)
          }else{
            setIsListOpen(true)
          }
        }

        console.log(`${logPrefix} Triggering grid refresh`);
        // Perform the grid refresh for the specific area
        outputContextUpdater({ action: 'getServerData' });

        console.log(`${logPrefix} Clearing queue`, {
          clearedItems: refreshQueues[area].length
        });
        // Clear the queue for that area
        refreshQueues[area] = [];

        // Clear and reset the timer
        // clearTimeout(refreshTimers[area]);
        // refreshTimers[area] = null;
      }
    }
  }
  

  useEffect(() => {
    if(props.area != outputContext.outputGrid.area){
      //set default values in context on start
      outputContext.outputGrid.area = props.area;
      outputContext.outputGrid.apiEndPoint = props.apiEndPoint;
      outputContextUpdater({action: 'init'});
    }
  }, []);

  //set grid context params so that datasource has the latest state of context objects
  const gridContext = {
    outputContext: outputContext,
  }

  const datasource = {
    rowCount: 0,
    totalRecordsCount: 0,
    async getRows(params) {
      let dsContext = params.context;
      let requestObj = {
        startRow: params.startRow,
        endRow: params.endRow,
        sortModel: params.sortModel,
      };
      
      try{
        //Call Backend NestJS API to get listing data
        if(showLoadingOverlay.current){
          dsContext.outputContext.outputGrid.gridApi?.showLoadingOverlay();
        }else{
          showLoadingOverlay.current = true
        }

        const response = await dsContext.outputContext.getServerData(requestObj);
        dsContext.outputContext.outputGrid.totalRecords = response.recordsTotal;
        dsContext.outputContext.outputGrid.filteredRecords = response.recordsFiltered;
        dsContext.outputContext.outputGrid.displayedRecords = response.data.length;

        if(dsContext.outputContext?.verifiedData.length){
          response.data = outputContext.addVerificationData(response.data, dsContext.outputContext?.verifiedData)
        }

        params.successCallback(response.data, response.recordsFiltered);
        dsContext.outputContext.outputGrid.gridApi?.redrawRows();

        if('footer' in response){ //Set Footer data for columns
          let footerRows = [];
          if(response.footer && response.recordsFiltered > 0){ //Data returned
            footerRows.push(response.footer);
          }
          dsContext.outputContext.outputGrid.gridApi?.setPinnedBottomRowData(footerRows);
        }
        dsContext.outputContext.outputGrid.gridApi?.hideOverlay();

        if(['Add', 'Refine'].includes(dsContext.outputContext.outputSearchType)) {
          dsContext.outputContext.saveSearchState();
        }

        if(isDetailToList.current && outputSearchTerm!=""){
          isDetailToList.current = false;
          searchInputRef.current.focus();
          searchInputRef.current.select();
        }
      }
      catch(error) {
        console.log(error);
        params.failCallback();
      }
    }
  };

  const [columnDefs, setColumnDefs] = useState(props.columnDefs);

  useEffect(()=>{
    if (showVerificationColumns && props.area === 'Entries') {
      const updatedColumnDefs = columnDefs.map((column) => {
        if (column.field === 'verification' || column.field === 'verification_status') {
          return { ...column, hide: false };
        }
        return column;
      });

      setColumnDefs(updatedColumnDefs)

      // JSON data from the localStorage
      const jsonString = localStorage.getItem('Entries');

      if (jsonString !== null) {
        const entries = JSON.parse(jsonString);

        // Find the verify entires fields and update the "hide" attribute to false
        for (const entry of entries) {
          if (entry.field === 'verification' || entry.field === 'verification_status') {
            entry.hide = false;
          }
        }

        const updatedJsonString = JSON.stringify(entries);

        // Save the updated JSON back to localStorage
        localStorage.setItem('Entries', updatedJsonString);

      }
    }
  },[showVerificationColumns])

  var dataRendered = false;
  var storedInitialColumnDefs = false;
  var previousColumnDefs = null;
  var initialColumnDefs = null;

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 150,
      width: 150,
      resizable: true,
      sortable: true,
      cellStyle: function(params) {
        if (typeof params.value === 'number') {
            return {textAlign: 'center'};
        } else {
          return {textAlign: 'left'};
        }
      },
      wrapHeaderText: true,
      autoHeaderHeight: true,
      headerClass: "ag-center-aligned-header",
    };
  }, []);


  const onGridReady = useCallback((params) => {
    //Set grid api
    setGridApi(params);

    //set new grid api in output context
    outputContext.outputGrid.gridApi = params.api;

    //set column api in output context
    outputContext.outputGrid.columnApi = params.columnApi;

    // register datasource with the grid
    params.api.setDatasource(datasource); 
  }, []);

  const getRowId = useCallback(function (params) {
    return params.data.sgl_id;
  }, []);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: OutputListingStatusBar, align: 'left' },
        { statusPanel: OutputListingBarMenu, align: 'right',statusPanelParams: { defaultDefs:props.columnDefs } },
      ],
    };
  }, []);

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };
  
  const convertToCSV = (data, columns) => {
    const escapeCsv = (value) => {
      if (typeof value === 'string') {
        return `"${value.replace(/"/g, '""')}"`;
      }
      return value ?? '';
    };
  
    const headers = columns
      .filter(col => !col.hide)
      .map(col => escapeCsv(col.headerName || col.field))
      .join(',');
  
    const rows = data.map(row => {
      return columns
        .filter(col => !col.hide)
        .map(col => {
          let value;
          // Handle nested properties
          if (col.field.includes('.')) {
            value = getNestedValue(row, col.field);
          } else {
            value = row[col.field];
          }
  
          if (col.valueGetter) {
            value = typeof col.valueGetter === 'function' 
              ? col.valueGetter({ data: row })
              : getNestedValue(row, col.valueGetter);
          }
          
          if (col.valueFormatter) {
            value = typeof col.valueFormatter === 'function'
              ? col.valueFormatter({ value, data: row })
              : col.valueFormatter;
          }
          return escapeCsv(value);
        })
        .join(',');
    }).join('\n');
  
    return `${headers}\n${rows}`;
  };
  
const handleExportAll = async (type = 'csv', params) => {
    try {
        const { api } = params;
        loadingOverlay({ show: true, message: "Exporting Data..." });

        const allData = await getOutputSelectionRecordsData(false);

        const visibleColumns = outputContext.outputGrid.columnApi?.getAllDisplayedColumns()
            .filter(col => col.isVisible())
            .map(col => api.getColumnDef(col));

        if (type === 'csv') {
            const csvContent = convertToCSV(allData, visibleColumns);
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            saveAs(blob, 'export.csv');
        } else {
            const worksheetData = allData.map(row => {
              const processedRow = {};
              visibleColumns.forEach(col => {
                let value;
                
                // Handle nested properties
                if (col.field.includes('.')) {
                  value = getNestedValue(row, col.field);
                } else {
                  value = row[col.field];
                }
            
                if (col.valueGetter) {
                  value = typeof col.valueGetter === 'function' 
                    ? col.valueGetter({ data: row })
                    : getNestedValue(row, col.valueGetter);
                }
                
                if (col.valueFormatter) {
                  value = typeof col.valueFormatter === 'function'
                    ? col.valueFormatter({ value, data: row })
                    : col.valueFormatter;
                }
                
                processedRow[col.headerName || col.field] = value;
              });
              return processedRow;
            });

            const worksheet = XLSX.utils.json_to_sheet(worksheetData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, 'export.xlsx');
        }
    } catch (error) {
        console.error('Export failed:', error);
    } finally {
        loadingOverlay({ show: false });
    }
};

  const getContextMenuItems=(params)=> {
    let selectedRowsLength=params.api.getSelectedRows().length
    let result = [
      {
        // custom item
        name:intl.formatMessage({id: 'CONTEXTMENU.LABEL.EDIT'}),
        disabled:selectedRowsLength>1?true:false,
        action: () => onRowDoubleClicked(params.node)
      },
      {
        // custom item
        name: intl.formatMessage({id: 'CONTEXTMENU.LABEL.NEWTAB'}),
        disabled:selectedRowsLength>1?true:false,
        action: () => props.onNewTabClick(params.node.data)
      },
      'separator',
      {
        // custom item
        name: intl.formatMessage({id: 'TOOLBAR.SELECTION.OMITSUBSET'}),
        shortcut: os === 'MacOS' ? "Cmd + O" : "Ctrl + O",
        action: () => {outputContextUpdater({action: 'omitSubset'})
        },
      },
      {
        // custom item
        name: intl.formatMessage({id: 'TOOLBAR.SELECTION.SHOWSUBSET'}),
        shortcut: os === 'MacOS' ? "Cmd + U" : "Ctrl + U",
        action:() => {outputContextUpdater({action: 'showSubset'})
        }
      },
      {
        // custom item
        name:intl.formatMessage({id: 'TOOLBAR.SELECTION.ALL'}),
        shortcut: os === 'MacOS' ? "Cmd + G" : "Ctrl + G",
        action: () => {outputContextUpdater({action: 'clearFiltersAndRefresh'})
        },
      },
       // built in separator
      'separator',
      {
        name:intl.formatMessage({id: 'TOOLBAR.SELECTION.SAVE'}),
        action: () => {outputContextUpdater({action: 'saveSelection'})
        },
      },
      {
        name: intl.formatMessage({id: 'TOOLBAR.SELECTION.USESAVED'}),
        action: () => {outputContextUpdater({action: 'useSavedSelection'})
        },
      }, // built in separator
      'separator',
      {
        // custom item
        name:intl.formatMessage({id: 'TOOLBAR.LABEL.PRINT'})[0].toUpperCase()
            +intl.formatMessage({id: 'TOOLBAR.LABEL.PRINT'}).slice(1).toLowerCase(),
        shortcut: os === 'MacOS' ? "Cmd + P" : "Ctrl + P",
        action: () => {
            // Do not show print dialogue on reports area
            if (outputContext.outputGrid.area === 'Reports') {
                return;
            }
            setShowReportSelector(true)
        }
      },
      "separator",
      {
        name: intl.formatMessage({id: "CONTEXTMENU.LABEL.EXPORT"}),
        subMenu: [
          {
            name: intl.formatMessage({id: "CONTEXTMENU.LABEL.EXPORT.CSV"}),
            action: () => handleExportAll('csv', params),
          },
          {
            name: intl.formatMessage({id: "CONTEXTMENU.LABEL.EXPORT.EXCEL"}),
            action: () => handleExportAll('excel', params),
          },
        ],
      },
    ];
  
    return result;
  }
  
  const onCellRightClick=(e)=>{
    let selectedRowsLength=e.api.getSelectedRows().length
    if(selectedRowsLength===0 || selectedRowsLength==1){ //if 1 rows is selected or no row is selected, right click will select the clicked row
      e.node.setSelected(true,true)
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {  

      if(event.code==='ArrowUp' || event.code==='ArrowDown' || event.code==='Enter'){ // Up Down Enter keys
          const isModalOpen = JSON.parse(sessionStorage.getItem("modal_is_open") || "false");
          const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
          const modalElement = document.getElementById('kt_modal_create_app');
          const activeElement = document.activeElement;
          let isFormElementFocused = false;
          if (
          (activeElement && (activeElement.tagName === 'BUTTON' || (activeElement.tagName === 'INPUT' && activeElement.id != "simple-search-bar") || activeElement.tagName === 'SELECT'))
          ||
          (activeElement && activeElement.id && activeElement.id.startsWith('react-select'))
          ){
            isFormElementFocused = true;
          }

          if (!isFormElementFocused && modalElement == null && (((!forms_open) || (forms_open && forms_open.length == 1)) && !isModalOpen) ) {
            if(outputContext.outputGrid.gridApi?.getSelectedRows()?.length==0){
              shouldHighlightSearch.current = false;
              shouldFocusSearch.current = false;
              ControlMovedManuallyToAGGrid.current = true

              selectGridRow(outputContext.outputGrid.gridApi?.getRenderedNodes()[0]?.rowIndex);
              if(event.code==='Enter'){
                let isInSearchInput = activeElement && activeElement.getAttribute('id') === 'simple-search-bar';
                if(searchBehavior && searchBehavior == 'hit-return'){
                  isInSearchInput = activeElement && !activeElement.getAttribute('id') === 'simple-search-bar';
                }
                const firstNode = outputContext.outputGrid.gridApi?.getDisplayedRowAtIndex(0);
                if (firstNode && isInSearchInput) {
                  onRowDoubleClicked({ 
                    data: firstNode.data,
                    api: outputContext.outputGrid.gridApi 
                  });
                }
              }
            }
          }
      }

      if((event.ctrlKey  || event.metaKey )  && event.code==='KeyA'){ //Ctrl + A
          event.preventDefault()
          const isModalOpen = JSON.parse(sessionStorage.getItem("modal_is_open") || "false");
          const modalElement = document.getElementById('kt_modal_create_app');

          const activeElement = document.activeElement;
          let isFormElementFocused = false;
          if (
          (activeElement && (activeElement.tagName === 'BUTTON' || (activeElement.tagName === 'INPUT') || activeElement.tagName === 'SELECT'))
          ||
          (activeElement && activeElement.id && activeElement.id.startsWith('react-select'))
          ){
            isFormElementFocused = true;
          }

          if (!isFormElementFocused && modalElement == null && !isModalOpen) { //checking if any modal is open
            outputContextUpdater({action: 'selectAll'})
          }
      }

      if((event.ctrlKey  || event.metaKey ) && event.code==='KeyU'){  //Ctrl + U
          event.preventDefault()
          const isModalOpen = JSON.parse(sessionStorage.getItem("modal_is_open") || "false");
          const modalElement = document.getElementById('kt_modal_create_app');
          if (modalElement == null && !isModalOpen) { //checking if any modal is open
            outputContextUpdater({action: 'showSubset'})
          }
      }

      if ((event.ctrlKey  || event.metaKey) && event.code === 'KeyG'){  // Meta key is Command on MAC (Ctrl/Cmd + G)
          event.preventDefault()
          const isModalOpen = JSON.parse(sessionStorage.getItem("modal_is_open") || "false");
          const modalElement = document.getElementById('kt_modal_create_app');
          if (modalElement == null && !isModalOpen) { //checking if any modal is open
            outputContextUpdater({action: 'clearFiltersAndRefresh'})
          }
      }

      // omit subset for Ctrl + O
      if((event.ctrlKey  || event.metaKey ) && event.code==='KeyO'){
        event.preventDefault()
        const isModalOpen = JSON.parse(sessionStorage.getItem("modal_is_open") || "false");
        const modalElement = document.getElementById('kt_modal_create_app');
        if (modalElement == null && !isModalOpen) { //checking if any modal is open
          outputContextUpdater({action: 'omitSubset'})
        }
    }
      
    };
    // Attach the keydown event listener
    document.addEventListener('keydown', handleKeyDown);
    // Cleanup the event listener when the component unmounts
    return () => { document.removeEventListener('keydown', handleKeyDown) }
  }, [])

  useEffect(() => {
    const handleMouseUp = (event) => {
        const isModalOpen = JSON.parse(sessionStorage.getItem("modal_is_open") || "false");
        const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
        const modalElement = document.getElementById('kt_modal_create_app');
        const activeElement = document.activeElement;
        const targetElement = event.target;
        const quickActionMenu = targetElement.closest('#quick-action-top-menu');
        let isFormElementFocused = false;
        if (
        (activeElement && (activeElement.tagName === 'BUTTON' || (activeElement.tagName === 'INPUT' && activeElement.id != "simple-search-bar") || activeElement.tagName === 'SELECT' || activeElement.tagName === 'A'))
        ||
        (activeElement && activeElement.id && activeElement.id.startsWith('react-select'))
        ||
        quickActionMenu
        ){
          isFormElementFocused = true;
        }

        let sidebarClasses = ["ag-virtual-list-viewport", "ag-column-select-column", "ag-column-select-column-label", "ag-virtual-list-item", "ag-column-select-column-drag-handle", "ag-virtual-list-container","header-fixed"]
        if (!isFormElementFocused && modalElement == null && (((!forms_open) || (forms_open && forms_open.length == 1)) && !isModalOpen) ) {
            let gridDiv = document.querySelector('#output-listing-grid')
            if (!gridDiv.contains(event.target)) {
              outputContext.outputGrid.gridApi?.clearFocusedCell();
              outputContext.outputGrid.gridApi?.deselectAll();
            }   
            
            if(!sidebarClasses.some(className => targetElement.classList.contains(className))){
              if (outputContext.outputGrid.gridApi && outputContext.outputGrid.gridApi?.isSideBarVisible()) {
                outputContext.outputGrid.gridApi?.closeToolPanel();
              }
            }
        }
    };
    // Attach the keydown event listener
    document.addEventListener('click', handleMouseUp);
    // Cleanup the event listener when the component unmounts
    return () => { document.removeEventListener('click', handleMouseUp) }
  }, [])

  const paginationChanged = useCallback((params) => {
    const modalElement = document.getElementById('kt_modal_create_app');
    if (modalElement == null) {
      if(outputContext.outputGrid.gridApi?.getSelectedRows()?.length > 0){
        setTimeout(function(){
          let cell = outputContext.outputGrid.gridApi?.getFocusedCell();
          if ( cell ) {
            outputContext.outputGrid.gridApi?.setFocusedCell( cell.rowIndex, cell.column );
          }
        }
        , 300);
      }else{
          setTimeout(function(){
            let last_row_selected = localStorage.getItem(outputContext.outputGrid.area + "_" + currentUser.id + "_" + customer_id);// || outputContext.outputGrid.gridApi?.getRenderedNodes()[0]?.id;
            selectGridRow(last_row_selected);
          }
          , 300);
      }
    }
  });

  const selectionChanged = useCallback((params) => {
    // if(localStorage.getItem(outputContext.outputGrid.area + "_" + currentUser.id + "_" + customer_id)!==null){
    if(localStorage.getItem(outputContext.outputGrid.area + "_" + currentUser.id + "_" + customer_id)!==null && !ControlMovedManuallyToAGGrid.current){
      setTimeout(function(){
        let last_row_selected = localStorage.getItem(outputContext.outputGrid.area + "_" + currentUser.id + "_" + customer_id);// || outputContext.outputGrid.gridApi?.getRenderedNodes()[0]?.id;
        selectGridRow(last_row_selected);
        localStorage.removeItem(outputContext.outputGrid.area + "_" + currentUser.id + "_" + customer_id);
      }
      , 300);
    }
  });

  const selectGridRow=(last_row_selected)=>{    
    // if((!shouldHighlightSearch.current && !shouldFocusSearch.current)){
    if((!shouldHighlightSearch.current && !shouldFocusSearch.current) || (ControlMovedManuallyToAGGrid.current)){
    last_row_selected = parseInt(last_row_selected);
    last_row_selected = last_row_selected >= 0 ? last_row_selected : 0
    //var row_node_to_focus =  outputContext.outputGrid.gridApi?.getRowNode(last_row_selected);
    //if (last_row_selected >= 0) {
      // var row_index_to_focus = row_node_to_focus?.rowIndex;
      let first_col = outputContext.outputGrid.columnApi?.getAllDisplayedColumns()[0];
      outputContext.outputGrid.gridApi?.setFocusedCell(last_row_selected, first_col);
      outputContext.outputGrid.gridApi?.getDisplayedRowAtIndex(last_row_selected)?.setSelected(true);
      outputContext.outputGrid.gridApi?.ensureIndexVisible(last_row_selected, last_row_selected > 0?"middle":null);
    //}
    }
  }

  const handleCellKeyDown=(e)=>{
    if(e.event.key==="Enter"){
      onRowDoubleClicked(e)
    }
  }

  const navigateToNextCell = useCallback((params) => {
    let suggestedNextCell = params.nextCellPosition;
    let KEY_UP = 'ArrowUp';
    let KEY_DOWN = 'ArrowDown';
    let args={select:true,onlyOne:true} //storing arguments to pass to setSelected fucction
    let noUpOrDownKeyPressed = params.key !== KEY_DOWN && params.key !== KEY_UP;

    if (noUpOrDownKeyPressed || !suggestedNextCell) {
      return suggestedNextCell;
    }
    if(params.event.shiftKey){   //if shift key is pressed, onlyOne will be set to false, to allow multiple selection on shift key up/down
      args.onlyOne=false
    }
    params.api.forEachNode((node) =>{ 
      if (node.rowIndex === suggestedNextCell.rowIndex) { //selecting the cell on key up or down where rowIndex match
        node.setSelected(args.select,args.onlyOne);
      }
    });
    return suggestedNextCell;
  }, []);
  
  // this event will trigger on column sort
  const onSortChanged = useCallback((params) => {
    if(params.source === 'uiColumnSorted'){
      outputContext.outputGrid.gridApi?.clearFocusedCell();
      outputContext.outputGrid.gridApi?.deselectAll();
      localStorage.removeItem(outputContext.outputGrid.area + "_" + currentUser.id + "_" + customer_id);
      SaveModifiedColumnDefinitions(params.api.getColumnDefs(), outputContext.outputGrid.area, currentUser, customer_id)
    }
  });
  
  // this event will trigger on column resize
  const onColumnResized = useCallback((params) => {
    if(params.source === 'uiColumnDragged' && params.finished) // safety check to tell column resize is done manually and resize drag is complete
    {
      SaveModifiedColumnDefinitions(params.api.getColumnDefs(), outputContext.outputGrid.area, currentUser, customer_id)
    }
  });

  const onColumnVisible = useCallback((params) => {
    if (params.source === 'toolPanelUi') {
      const colId = params.column ? [ params.column.colId ] : params.columns.map(col => col.colId)
      const value = params.api.getColumnDefs().map(v => {
          if (v.colId === colId.find(c => c === v.colId)) {
              params.visible ? v.hide = false : v.hide = true
          }
          return v
      })
      if (value) {
          SaveModifiedColumnDefinitions(value, outputContext.outputGrid.area, currentUser, customer_id)
      }
    }
  });

  const onColumnPinned = useCallback((params) => {
    if(params.source === 'uiColumnDragged'){
      SaveModifiedColumnDefinitions(params.api.getColumnDefs(), outputContext.outputGrid.area, currentUser, customer_id)
    }
  });

  const onColumnMoved = useCallback((params) => {
    if(params.source === 'uiColumnDragged' || params.source === 'api'){
      SaveModifiedColumnDefinitions(params.api.getColumnDefs(), outputContext.outputGrid.area, currentUser, customer_id)
    }
  });

  const onFirstDataRendered = useCallback((params) => { // when data is loaded in the grid for very first time
    if (dataRendered == false) { 
      previousColumnDefs = params.api.getColumnDefs(); // setting initial widths to reset widths for columns other than last column
      dataRendered = true;
    }
  });

  const onRowDoubleClicked = useCallback((params) => {
    if ( typeof( props.onRowDoubleClicked ) === 'function') {
      props.onRowDoubleClicked(params.data)
    }
    dispatch(setCurrentListRecordIndex(getSelectedRowIndex(params)))
    dispatch(setListIds(getAllRecordIds( outputContext.outputGrid.area, params, props.columnDefs)))

    // In case of reports, if the user has no permission to edit system reports, then the user should not be able to see system reports.
    if (outputContext.outputGrid.area === 'Reports') {
        if (!hasSpecialPermissionToAccess('cloud_reports:edit-system-reports')) {
            dispatch(setCurrentListRecordIndex(getRowIndexForNonSystemReport(params)))
            dispatch(setListIds(getNonSystemReportIds( outputContext.outputGrid.area, params, props.columnDefs)))
        }
    }

    let selectedNodes =  params.api?.getSelectedNodes()
    if(selectedNodes){
      let selectedRowIdx = selectedNodes[0]?.rowIndex
      localStorage.setItem(outputContext.outputGrid.area + "_" + currentUser.id + "_" + customer_id, selectedRowIdx)
    }
  }, []);
  
  const loadingOverlayComponent = useMemo(() => {
    return LoadingOverlay;
  }, []);

  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: intl.formatMessage({ id: 'GRID.LOADING.MESSAGE' }),
    };
  }, []);

  useEffect(()=>{
    clearSessionStorageByKeysPrefix(['forms_open', 'dialog_is_open', 'searchOnSubset'])
    dispatch(clearPreviousClassDetails())
    // Reset Search Add/Refine to default
    if(row_ids.length === 0){ // to detect detail page was not opened before
      setOutputSearchType('Replace')
    }
    // Reset redux stored changes for the next/prev buttons to their initial state when the area is changed or closed.
    dispatch(setCurrentListRecordIndex(-1))
    dispatch(setListIds([]))
    let colDefs = localStorage.getItem(outputContext.outputGrid.area)
    if (colDefs != "undefined" && colDefs != "" ){
      let custom_column_definitions = JSON.parse(colDefs)
      let overwrite_colDefs = overwriteColumnDefinitions(custom_column_definitions, props.columnDefs)
      if(overwrite_colDefs.length > 0){
        setColumnDefs(overwrite_colDefs)
      }
    }
  },[])

  useEffect(()=>{
    // preventing default browser actions on key presses
    document.onkeydown = (e) =>{
      if ((e.ctrlKey  || e.metaKey ) && (e.code === 'KeyU' || e.code=== 'KeyG' || e.code === 'KeyA' || e.code === 'KeyO')) {
        return false;
      } else {
        return true;
      }
  };
  },[])

  

  return (
    <KTCard>
      <ListToolbarMain/>
      <KTCardBody>
        <div style={containerStyle}>
          {areasToAddFeeTabs.includes(props.area) ? <FeesTabs /> : null}
          <div style={gridStyle} className="ag-theme-alpine" id='output-listing-grid'>
            {props.columnDefs.length > 0 &&
            <AgGridReact
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              context={gridContext}
              rowModelType={"infinite"}
              rowSelection='multiple'
              serverSideInfiniteScroll={true}
              cacheBlockSize={props.cacheBlockSize ?? 500}
              maxBlocksInCache={2}
              blockLoadDebounceMillis={10}
              onGridReady={onGridReady}
              statusBar={statusBar}
              getRowId={getRowId}
              enableRangeSelection={false}
              onRowDoubleClicked={onRowDoubleClicked}
              onFirstDataRendered={onFirstDataRendered}
              loadingOverlayComponent={loadingOverlayComponent}
              loadingOverlayComponentParams={loadingOverlayComponentParams}
              onSortChanged={onSortChanged}
              onColumnResized={onColumnResized}
              onColumnVisible={onColumnVisible}
              onColumnPinned={onColumnPinned}
              onColumnMoved={onColumnMoved}
              getContextMenuItems={getContextMenuItems}
              onCellKeyDown={(e)=>handleCellKeyDown(e)}
              navigateToNextCell={navigateToNextCell}
              onCellContextMenu={(e)=>onCellRightClick(e)}
              rowHeight={getNarrowRowHeight}
              headerHeight={getNarrowHeaderHeight}
              maxConcurrentDatasourceRequests={1}
              onPaginationChanged={paginationChanged}
              onSelectionChanged = {selectionChanged}
              sideBar = {sideBar}
              rowBuffer={10}
            ></AgGridReact>
            }
          </div>
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default OutputListingComponent;
  