import moment from 'moment';
import { findReplaceOrAdd, removeElementFromArray } from '../../../../modules/sgl-utils/SglFunctions';

export const updateMembershipRow = (gridApi, organization, data) => {
    let rowData = []
    gridApi.forEachNode(node => { //Map on Left grid Data
        if(organization == node.data.organization_code.toLowerCase()){
            if (organization == 'norcal' ){ //For Nrcal
                node.data.valid_start = data?.StartDate ?? "" //Update validStart in frontend
                node.data.valid_end = data?.EndDate ?? "" //Update validend in frontend
            }
            else if (organization == 'ec'){ //For EC
                node.data.valid_start = data?.membership_start_date ?? "" //Update validStart in frontend
                node.data.valid_end = data?.membership_end_date ?? "" //Update validend in frontend
            }
        }
        rowData.push(node.data)
    })
    gridApi.setRowData(rowData) //Set Grid after update
}

//Function for Gender Abbreviation
export const gender = (sex) => {
    if (sex == 'G'){
        sex = "Gelding"
    }
    else if (sex == "M"){
        sex = "Mare"
    }
    else if (sex == 'S'){
        sex = "Stallion"
    }
    else{
        sex = sex
    }
    return sex
}

//Function for isNumber valid from string
export const isNumber = (char) => {
    if (typeof char !== 'string') {
      return false;
    }
  
    if (char.trim() === '') {
      return false;
    }
    return !isNaN(char);
}

// 4D-> horse_convertHeight
export const horseConvertHeight = (height, measure = 'cm') => {
    height = height ? height.toString() : '';
    let result = 0, counter = 0, spaceFound = false, digitPart = 0, fractionPart = "", hands = 0, inches = 0, fraction_numerator = 0, fraction_denominator = 0;
    let digit_part = "", fraction_part = "", extra_part = "", result_unit = "cm";
    
    if (measure === "inches") {
        result_unit = "inches";
    }
    
    // Handle special characters in height input
    // Handle 16:02 as 16 hands 2 inches
    // Handle 16:3 as 16 hands 3 inches
    if (height.includes(":") && !height.includes(".")) {
        height = height.replace(":", " ");
    }
    //Handle 16,3 as 16 hands 3 inches
    if (height.includes(",") && !height.includes(".")) {
        height = height.replace(",", " ");
    }
    
    //fix invalid hands values having invalid space >>>
    //12.13/4 hands -> 12.1 3/4 hands
    const find_slash = height.indexOf("/");
    if (find_slash > 0) {
        const temp = height.split('');
        if (!isNaN(parseInt(temp[find_slash - 1]))) {
            temp.splice((find_slash - 1), 0, ' '); //There was bug in this line. for input 12.2 3/4 it was making it "12.2 3 /4" instead of "12.2 3 /4" and causing incorrect height calculation in cm
            height = temp.join('');
        }
    }
    
    //Fetch/Seperate Digit part, fraction Part and extraPart
    for (counter = 0; counter < height.length; counter++) {
        
        if ((height[counter].trim()!="" && !isNaN(height[counter])) || height[counter] === "." || height[counter] === "/") { //isNaN(" ") and isNaN("") return false, which causes wrong calculations. Added fix for it.
            if (!spaceFound) {
                digit_part += height[counter];
            } else {
                fraction_part += height[counter];
            }
        } 
        else {
            if (digit_part !== "") {
                if (height[counter] === " " || height[counter] === "-" || height[counter] === "'") {
                    spaceFound = true;
                }
            }
            extra_part += height[counter];
        }
    }
    //Remove space from etra part
    extra_part = extra_part.trim();
    digitPart = parseFloat(digit_part);
    
    if (digitPart > 0) {
        hands = Math.floor(digitPart);
        inches = parseFloat((digitPart - Math.floor(digitPart)).toFixed(3)) * 10;
        if (hands > 2 && hands <= 24) {
            result = hands * 4;
            // Check if inches less than 4
            if (inches > 0 && inches < 4) {
                result += inches;
            }
            
            if (fraction_part !== "") {
                const find = fraction_part.indexOf("/");
                
                if (find <= 0 || find === fraction_part.length) {
                    fraction_numerator = parseInt(fraction_part);
                    
                    if (fraction_numerator > 0) {
                        result += fraction_numerator;
                    }
                } else {
                    fraction_numerator = parseFloat(fraction_part.substring(0, find));
                    fraction_denominator = parseFloat(fraction_part.substring(find + 1));
                    
                    if (fraction_numerator > 0 && fraction_denominator > 0) {
                        result += fraction_numerator / fraction_denominator;
                    }
                }
            }
        } else if (digitPart <= 2 || extra_part.toLowerCase() === "m" || extra_part.toLowerCase() === "meters" || extra_part.toLowerCase() === "meter") {
            result = digitPart * 39.37;
        } else if (digitPart > 24 && digitPart < 250) {
            result = digitPart / 2.54;
        }
    }
    
    if (result > 0 && result_unit === "cm") {
        result *= 2.54;
    }
    
    result = parseFloat(result.toFixed(2));

    return result;
};

//Function for update membership number on frontend
export const getMembershipNumber = (gridApi, organization, horse) => {
    let rowData = []
        gridApi.forEachNode(node => {
            if(organization == node.data.organization_code){
                if (horse.usea_no){
                    node.data.membership_number = horse?.usea_no ?? ""
                }
                else if (horse.usdf_no){
                    node.data.membership_number = horse?.usdf_no ?? ""
                }
                else if (horse.ec_ppn){
                        node.data.membership_number = horse?.ec_ppn ?? ""
                }
                else if (horse.fei_id){
                    node.data.membership_number = horse?.fei_id ?? ""
                }
            }
            rowData.push(node.data)
        })
        gridApi.setRowData(rowData)
}

// Function for horseType
export const horseType = (height) =>{
    let type = ""
    if (height > 0){
        
        if (height <= 127){  //Small ponies are 12.2 hands (50 inches (130 cm)) and under
            type ="Small Pony"
        }
        else if (height <= 137.16){  //medium ponies are over 12.2 but no taller than 13.2 hands (54 inches (140 cm))
            type ="Medium Pony"
        }
        else if (height <= 147.32){  //large ponies are over 13.2 hands but no taller than 14.2 hands (58 inches)
            type ="Large Pony"
        }
    
        else if (height <= 162.56){  //Small horse - up to 16.0 hands
            type ="Small Horse"
        }
    
        else if (height > 162.56){  //Large horse - exceeding 16.0 hands
            type ="Large Horse"
        }
    
    }
    return type
}

//Function for competitionYear
export const competitionYear = (horse) =>{
    let competitionYearForDate = ""
        if(horse != ""){
            competitionYearForDate = horse
        }
        else{
            competitionYearForDate = moment()
        }
        if (moment(horse).month() >= 11){ //If month is December
            competitionYearForDate = moment(competitionYearForDate).add(1, "years")
        }
        return competitionYearForDate
}

//Get date from USEF method
export const USEFDate = (horse) =>{ //
    if(horse.usef_status == "Life" && moment(horse.USEFStartDate).isValid()) {
        horse.USEFEndDate = moment(competitionYear(horse.USEFStartDate)).add(10, "years").format("YYYY-MM-DDTHH:mm:SS") //ExpirationDate 
    }
    return moment(horse.USEFEndDate).format("MM/DD/YYYY")
}

//Get horseAge
export const horseAge = (foalDate) =>{
    let horseAges = ""
    if(moment(foalDate).isValid()) {
        let date = new Date(moment(foalDate).year(), "0", "1");
        horseAges = moment().diff(date,"years")
        if (moment(foalDate).add(horseAges + 1, "years") < moment()){
            horseAges = horseAges + 1
        }
        if (horseAges < 1){
            horseAges = 0
        }
    }else{
        horseAges = ""
    }
    return horseAges == "" ? horseAges: "Age: "+horseAges
}

//Update row MembershipDates in left bar and object from response
export const updateMembershipRowDates = (gridApi, organization, data, organizationMembers) => {
    let rowData = []
    let startDate = ""
    let endDate = ""
    let gridNode
    gridApi.forEachNode(node => {
        if(organization == node.data.organization_code.toLowerCase()){
            gridNode = node
            if(organization == "usef"){
                startDate = moment(data.USEFStartDate).format("MM/DD/YYYY")
                endDate = USEFDate(data)
            }
            if(organization == "norcal"){
                startDate = moment(data?.StartDate).format("MM/DD/YYYY")
                endDate = moment(data?.EndDate).format("MM/DD/YYYY")
            }
            if(organization == "fei"){
                startDate = moment(data?.membership_start_date??null).format("MM/DD/YYYY")
                endDate = moment(data?.membership_end_date??null).format("MM/DD/YYYY")
            }
            if (moment(startDate).isValid() && moment(endDate).clone().isValid()){
                node.data.valid_start = startDate
                if(node.data.valid_end?.trim() != ""){
                    node.data.valid_end = endDate
                }
                if (node.data.valid_start <= moment() && node.data.valid_end >= moment()){
                    organizationMembers.map(membershipData =>{
                        if (membershipData.organization_code.toLowerCase() == organization){
                            membershipData.is_membershipvalid = true
                        }
                    })
                }
                else{
                    organizationMembers.map(membershipData =>{
                        if (membershipData.organization_code.toLowerCase() == organization){
                            membershipData.is_membershipvalid = false
                        }
                    })
                }
            }
        }
        rowData.push(node.data)
    })
    gridApi.setRowData(rowData)
    return gridNode
}

//Fee_paid_year function
export const feePaidYear = (gridApi, organization, data, organizationMembers) => {
    let rowData = []
    gridApi.forEachNode(node => {
        if(organization == node.data.organization_code.toLowerCase()){
            let date1 = new Date(moment(node.data.valid_start).year(), "0", "1");
            let endDate = new Date(moment(date1).add(1, "years").subtract(1, "days"))
            // if (organization == 'usef' ){
            node.data.valid_start = date1
            node.data.valid_end = endDate
            if (node.data.valid_end >= moment()){
                organizationMembers.map(membershipData =>{
                    if (membershipData.organization_code.toLowerCase() == organization){
                        if (node.data.ec_status == "DECEASED" || node.data.ec_status == "RETIRED"){
                            membershipData.is_membershipvalid = false
                        }
                        else{
                            membershipData.is_membershipvalid = true
                        }
                    }
                })
            }
            // }
        }
        rowData.push(node.data)
    })
    gridApi.setRowData(rowData)
}

//Function for membership ID
export const membershipIDUpdate = (gridApi, organization, data, organizationMembers) => {
    let rowData = []
    gridApi.forEachNode(node => {
        if(organization == node.data.organization_code.toLowerCase()){
            if (node.data?.membership_number == ""){
                let organizationMembershipNumber = 0
                let organizationCode = ""
                if (organization == "usef"){
                    organizationMembershipNumber = data?.Memberships[0]?.MemberNumber
                    organizationCode = "USEF"
                }
                else if (organization == "fei"){
                    organizationMembershipNumber = data?.Memberships[0]?.MemberNumber
                    organizationCode = "FEI"
                }
                else if (organization == "norcal"){
                    organizationMembershipNumber = data.Memberships[0].MemberNumber
                    organizationCode = "Norcal"
                }
                if (organizationMembershipNumber?.trim() != ""){
                    node.data.organization_code = organizationCode
                    node.data.membership_number = organizationMembershipNumber
                    organizationMembers.map(membershipData =>{
                    if (membershipData.organization_code.toLowerCase() == organization){
                        if (organization == data?.Memberships[0].MemberNumber)
                        membershipData.organization_code = organizationCode
                        membershipData.membership_number = organizationMembershipNumber
                    }
                })
                }
            }
        }
        rowData.push(node.data)
    })
    gridApi.setRowData(rowData)
}


export const hasVerifiedMeasurementCard = (card, measurement_override_date) => {
    let flag = false

    if(card && card != '' && isJsonString(card)){
        let measurement_card = JSON.parse(card)
        if(measurement_card['MeasurementCards'] != null && measurement_card['MeasurementCards'].length > 0){
            if(JSON.stringify(measurement_card['MeasurementCards'][0]).includes("Heel") )
            {
                flag = true
            }
        }
        
        // return flag
    }
    if(!flag){ 
        flag = measurement_override_date >= moment().format('YYYY-MM-DD')
    }
    return flag

}

function isJsonString(str) {
    try {
      var json = JSON.parse(str);
      return (typeof json === 'object');
    } catch (e) {
      return false;
    }
  }


const hasValidLookupOrgMembership_2 = (type, entityID, organizationMembers, org = "") => {
    let result = {
        hasValidMembership: false,
        organization: null
    };

    if (entityID > 0) {
        let orgs = ["USEF", "EC", "FEI", "USHJA"];
        
        // If a specific organization is provided, only check that one
        if (org) {
            orgs = [org];
        }

        let orgsLength = orgs.length;

        for (let row = 0; row < orgsLength && !result.hasValidMembership; row++) {
            let organizationCode = orgs[row];
            let membership;

            if (type === "Horse") {
                membership = organizationMembers.find(data => data.organization_code === organizationCode && data.horse_id === entityID);
            } else {
                membership = organizationMembers.find(data => data.organization_code === organizationCode && data.people_id === entityID);
            }

            if (membership) {
                if (moment().isSameOrAfter(moment(membership.valid_start)) && moment().isSameOrBefore(moment(membership.valid_end))) {
                    result.hasValidMembership = true;
                    result.organization = organizationCode;
                }
            }
        }
    }
    
    return result;
}

//  4D hasValidLookupOrgMembership
const hasValidLookupOrgMembership = (type, entityID, organizationMembers, org = "") => {
    let hasValidMembership = false;

    if (entityID > 0) {
        let orgs = ["USEF", "EC", "FEI"];
        
        // If a specific organization is provided, only check that one
        if (org) {
            orgs = [org];
        }

        let orgsLength = orgs.length;

        for (let row = 0; row < orgsLength && !hasValidMembership; row++) {
            let organizationCode = orgs[row];
            let membership;

            if (type === "Horse") {
                membership = organizationMembers.find(data => data.organization_code === organizationCode && data.horse_id === entityID);
            } else {
                membership = organizationMembers.find(data => data.organization_code === organizationCode && data.people_id === entityID);
            }

            if (membership) {
                if (moment().isSameOrAfter(moment(membership.valid_start)) && moment().isSameOrBefore(moment(membership.valid_end))) {
                    hasValidMembership = true;
                }
            }
        }
    }
    
    return hasValidMembership;
}

// 4D horse_ControlLookupFields
export const controlLookupFields = (horse, organizationMember) =>{
        let result = {}, returnobj= {}, hasValidMembership = false
        
        let usef_lookup_data = horse.usef_response_eti_lookup && isJsonString(horse.usef_response_eti_lookup)?JSON.parse(horse.usef_response_eti_lookup):{}
        let ec_lookup_data = horse.ec_lookup_response && isJsonString(horse.ec_lookup_response)?JSON.parse(horse.ec_lookup_response):{}
        let fei_lookup_data = horse.fei_lookup_response && isJsonString(horse.fei_lookup_response)?JSON.parse(horse.fei_lookup_response):{}
        let ushja_lookup_data = horse.ushja_lookup_response && isJsonString(horse.ushja_lookup_response)?JSON.parse(horse.ushja_lookup_response):{}
        
        if (horse.horse_id > 0){
            hasValidMembership = hasValidLookupOrgMembership("Horse", horse.horse_id, organizationMember)
            if((hasValidMembership) && moment(horse.dob).isValid()){
                returnobj.disDob = true
            }
            else{
                returnobj.disDob = false
            }
            if(usef_lookup_data['MeasurementCards']?.length > 0){
                returnobj.disHeight = true
                returnobj.disType = true
            }
            else{
                returnobj.disHeight = false
                returnobj.disType = false
            }

            result = hasValidLookupOrgMembership_2("Horse", horse.horse_id, organizationMember)
            if(result.organization == "USEF"){
                if((result?.hasValidMembership) && horse?.name && usef_lookup_data['Name'] && horse?.name?.toLowerCase() == usef_lookup_data['Name']?.toLowerCase()){
                    returnobj.disName = true
                }
                else{
                    returnobj.disName = false
                }
            }
            if(result.organization == "EC"){
                if((result?.hasValidMembership) && horse?.name && ec_lookup_data['HorseName'] && horse?.name?.toLowerCase() == ec_lookup_data['HorseName']?.toLowerCase()){
                    returnobj.disName = true
                }
                else{
                    returnobj.disName = false
                }
            }
            if(result.organization == "FEI"){
                if((result?.hasValidMembership) && horse?.name && fei_lookup_data['CompleteName'] && horse?.name?.toLowerCase() == fei_lookup_data['CompleteName']?.toLowerCase()){
                    returnobj.disName = true
                }
                else{
                    returnobj.disName = false
                }
            }
            if(result.organization == "USHJA"){
                if((result?.hasValidMembership) && horse?.name && ushja_lookup_data['HorseName'] && horse?.name?.toLowerCase() == ushja_lookup_data['HorseName']?.toLowerCase()){
                    returnobj.disName = true
                }
                else{
                    returnobj.disName = false
                }
            }
        }
        return returnobj
}

export const deleteMembershipOverride = (membership, membership_override_added, membership_override_removed) => {
    membership_override_added = removeElementFromArray(membership_override_added, membership.membership_override, 'organization_code')
    membership_override_removed = findReplaceOrAdd(membership_override_removed, membership.membership_override, 'organization_code')
    membership.membership_override = null
    membership.override = false
    membership.memberships_override_id = 0

    return {membership, membership_override_added, membership_override_removed}
}

//Check if the manual membership override is allowed or not
export const allowManualMembershipOverrideForHorse = (orgCode) => {
    //Note: add organization on backend also
    return (orgCode == "USEF" || orgCode == "USHJA" || orgCode == "EC" || orgCode == "FEI")
}