import {useIntl} from 'react-intl'
import {useFormContext, Controller} from 'react-hook-form'
import Select from 'react-select'
import {reactSelectStyles} from '../../../modules/sgl-utils/fieldControls'
import {useMemo, useEffect, useState} from 'react'
import {NumericFormat} from 'react-number-format'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic'
import NumberRenderer from '../../../modules/output-listing/renderers/NumberRenderer'
import {horseConvertHeight} from '../../horses/Detail/AdditonalTab/MembershipFunctions'
import {useAlert} from '../../../modules/sgl-utils/DialogsProvider'
import { standardizeFenceHeight, determineFenceUnit } from './FenceHeightConversionLogiceHelper'

const GeneralTab = (props) => {
  const intl = useIntl()
  const methods = useFormContext()
  const show_financials_locked = methods.watch('show_tables.islocked')
  const alertDialog = useAlert()

  const columnDefs = [
    {
      field: 'number',
      headerName: intl.formatMessage({id: 'FORM.INPUT.CLASSES.TAB.GENERAL.ClASSNUMBER'}),
      cellStyle: {textAlign: 'center'},
      cellRenderer: NumberRenderer,
    },
    {
      field: 'name',
      flex: 2,
      headerName: intl.formatMessage({id: 'FORM.INPUT.CLASSES.TAB.GENERAL.ClASSNAME'}),
    },
    {
      field: 'Division.name',
      flex: 1,
      headerName: intl.formatMessage({id: 'FORM.INPUT.CLASSES.TAB.GENERAL.DIVISIONNAME'}),
    },
  ]

  const rowData = methods.getValues('combined_classes')

  const containerStyle = useMemo(() => ({width: '100%', height: '300px'}), [])


  // State to store the conversion message
  const [conversionMessage, setConversionMessage] = useState(null)

  // Add useEffect for initialization after API response
  useEffect(() => {
    // Only proceed if we have the API response data
    if (props.form_meta?.fence_height_types?.length > 0) {
      const initialValue = methods.getValues('classes.level')

      // Custom Rules for Fence Height if in inches
      // Rule 1 : if fence height drop down unit is already in inches then no feet part is entered no need to convert
      if (
        methods.getValues('classes.unit') === 'Inch' &&
        /^\s*\d+(?:\.\d+)?\s*$/.test(initialValue)
      ) {
        // Skip calculations as no feet part is given
        return
      }

      // Rule 2: if the fence height has a combination like this x.xxx" then no need to convert
      // Check if the value is a single numeric value (integer or decimal) ending with a double quote
      if (/^\s*\d+(?:\.\d+)?\"\s*$/.test(initialValue)) {
        methods.setValue('classes.unit', 'Inch', {
          shouldDirty: true,
        })
        return
      }

      if (initialValue) {
        const standardizeFenceHeightResult = standardizeFenceHeight(initialValue)
        const determineFenceUnitResult = determineFenceUnit(initialValue)

        if (
          standardizeFenceHeightResult.isEligible ||
          determineFenceUnitResult.convertedValue ||
          determineFenceUnitResult.isValid
        ) {
          if (determineFenceUnitResult.unit) {
            // Set the standardized value

            methods.setValue(
              'classes.level',
              formatNumberValue(determineFenceUnitResult.convertedValue),
              {
                shouldDirty: true,
              }
            )

            let unitAccordingToDropDown = normalizeUnitAndSetMessage(
              determineFenceUnitResult.unit,
              initialValue,
              determineFenceUnitResult.convertedValue
            )

            // Find and set matching dropdown option
            const matchingOption = props.form_meta.fence_height_types.find(
              (option) => option.value === unitAccordingToDropDown
            )

            methods.setValue('classes.unit', matchingOption?.value || '', {
              shouldDirty: true,
            })
          }
        } else {
          // set value as it is
          methods.setValue('classes.level', standardizeFenceHeightResult.modified, {
            shouldDirty: true,
          })
          
        }
      }
    }
  }, [props.form_meta.fence_height_types]) // Run when fence_height_types are available

  function normalizeUnitAndSetMessage(unit, originalValue, convertedValue) {
    let normalizedUnit = unit

    switch (unit) {
      case 'feet_inches':
        setConversionMessage({
          message: intl.formatMessage(
            {id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.FENCEHEIGHTUNITCONVERSIONMESSAGE'},
            {
              orignal_value: originalValue,
              converted_value: convertedValue,
            }
          ),
          type: 'success',
        })
        normalizedUnit = 'Inch'
        break
      case 'meters':
        normalizedUnit = 'Meter'
        break
      case 'inches':
        normalizedUnit = 'Inch'
        break

      case 'feet':
        setConversionMessage({
          message: intl.formatMessage(
            {id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.FENCEHEIGHTUNITCONVERSIONMESSAGE'},
            {
              orignal_value: originalValue,
              converted_value: convertedValue,
            }
          ),
          type: 'success',
        })
        normalizedUnit = 'Inch'
        break
    }

    return normalizedUnit
  }

  const formatNumberValue = (value) => {
    const num = parseFloat(value)
    if (Number.isInteger(num)) {
      return num.toString()
    }
    // First convert to fixed 2 to handle long decimals
    const fixed = num.toFixed(2)
    // Remove trailing zeros after decimal
    return parseFloat(fixed).toString()
  }

  const processFenceHeight = (value) => {
    const trimmedValue = value.trim()
    // Custom Rules for Fence Height if in inches
    // Rule 1 : if fence height drop down unit is already in inches then no feet part is entered no need to convert
    if (
      methods.getValues('classes.unit') === 'Inch' &&
      /^\s*\d+(?:\.\d+)?\s*$/.test(trimmedValue)
    ) {
      // Skip calculations as no feet part is given
      return
    }
    // Rule 2: if the fence height has a combination like this x.xxx" then no need to convert
    // Check if the value is a single numeric value (integer or decimal) ending with a double quote
    if (/^\s*\d+(?:\.\d+)?\"\s*$/.test(trimmedValue)) {
      methods.setValue('classes.unit', 'Inch', {
        shouldDirty: true,
      })
      return
    }

    const standardizeFenceHeightResult = standardizeFenceHeight(trimmedValue)
    const determineFenceUnitResult = determineFenceUnit(trimmedValue)

    if (
      !standardizeFenceHeightResult.isEligible ||
      !determineFenceUnitResult.convertedValue ||
      !determineFenceUnitResult.isValid
    ) {
      // Reset dropdown to default
      // set value as the original value
      methods.setValue('classes.level', determineFenceUnitResult.standardized, {
        shouldDirty: true,
      })

      // set the drop down value as select...
      if(!methods.getValues('classes.heights_in_inches') || methods.getValues('classes.heights_in_inches') == ''){ // clear height unit only if mutiple height are not provided too
        methods.setValue('classes.unit', '', {
          shouldDirty: true,
        })
      }
      return
    }

    if (determineFenceUnitResult.unit) {
      // Set the numeric value
      methods.setValue(
        'classes.level',
        formatNumberValue(determineFenceUnitResult.convertedValue),
        {
          shouldDirty: true,
        }
      )

      let unitAccordingToDropDown = normalizeUnitAndSetMessage(
        determineFenceUnitResult.unit,
        trimmedValue,
        determineFenceUnitResult.convertedValue
      )

      // Find matching option from fence_height_types
      const matchingOption = props.form_meta.fence_height_types.find(
        (option) => option.value === unitAccordingToDropDown
      )

      // Set the Select component value using the full option object
      methods.setValue('classes.unit', matchingOption?.value || '', {
        shouldDirty: true,
      })
    }
  }

  const handleHeightsUnit = () => {
         // set the drop down value as select... if height is empty too
         if(methods.getValues('classes.level').trim()== ''){ // clear height unit only if mutiple height and heigh both are not provided
          methods.setValue('classes.unit', '', {
            shouldDirty: true,
          })
        }
  }  

  return (
    <>
      <div className='form-group row'>
        {methods.getValues('classes.combined_class') ? (
          <>
            <label className='col col-form-label fw-bold fs-5 py-1 w-80px pe-0'>
              {intl.formatMessage({id: 'FORM.INPUT.CLASSES.LABEL.COMBINEDCLASS'})}
            </label>
            <OutputListingGridStatic
              onRowDoubleClicked={(row_data) => {
                props.callbackFromParent(row_data.class_id, 'Classes', 'ClassDetail', {}, row_data)
              }}
              area={'Classes - General'}
              columnDefs={columnDefs}
              rowData={rowData}
              containerStyle={containerStyle}
            />
          </>
        ) : (
          <>
            <div className='row mb-2'>
              <fieldset>
                <legend className='fs-5 fw-bold d-flex'>
                  {intl.formatMessage({
                    id: 'FORM.INPUT.CLASSES.TAB.GENERAL.GROUPLABEL.CLASSSPECIFICATIONS',
                  })}{' '}
                  <b></b>
                </legend>
                <div className='row mb-2'>
                  <div className='col-lg-6'>
                    {' '}
                    {/* Column 1 */}
                    <div className='row mb-2'>
                      <label
                        className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0'
                        htmlFor='fei_competition_code'
                        data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.CLASSSPECIFICATIONS.LABEL.FEICOMPETITION'
                      >
                        {intl.formatMessage({
                          id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.FEICOMPETITION',
                        })}
                      </label>

                      <div className='col ps-0'>
                        <input
                          {...methods.register('classes.fei_competition_code', {
                            required: false,
                          })}
                          id='fei_competition_code'
                          type='text'
                          disabled={
                            methods.getValues('classes.fei_import') &&
                            methods.getValues('classes.fei_competition_code') != ''
                          }
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        />
                      </div>

                      <label
                        className='col-lg-1 col-form-label fs-5 py-1 px-0 w-100px'
                        htmlFor='fei_event_type_code'
                        data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.CLASSSPECIFICATIONS.LABEL.FEIEVENTTYPE'
                      >
                        {intl.formatMessage({
                          id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.FEIEVENTTYPE',
                        })}
                      </label>

                      <div className='col-lg-2'>
                        <input
                          {...methods.register('classes.fei_event_type_code', {
                            required: false,
                          })}
                          id='fei_event_type_code'
                          type='text'
                          disabled={
                            methods.getValues('classes.fei_import') &&
                            methods.getValues('classes.fei_event_type_code') != ''
                          }
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        />
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <label
                        className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0'
                        htmlFor='usef_section_code'
                        data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.CLASSSPECIFICATIONS.LABEL.USEFSECTION'
                      >
                        {intl.formatMessage({
                          id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.USEFSECTION',
                        })}
                      </label>

                      <div className='col align-items-center ps-0'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <Controller
                              name='classes.usef_section_code'
                              control={methods.control}
                              defaultValue=''
                              render={({field}) => (
                                <NumericFormat
                                  {...field}
                                  id='usef_section_code'
                                  type='text'
                                  allowNegative={false}
                                  decimalScale={0}
                                  className='form-control form-control-sm fs-6 min-h-20px py-1'
                                  onBlur={(e) => {
                                    if (
                                      props.form_meta.usef_section.filter(
                                        (ss) => ss.value === field.value
                                      ).length === 0
                                    ) {
                                      field.onChange('', {shouldDirty: true})
                                    }
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className='col-lg-9'>
                            {props.form_meta.usef_section && (
                              <Controller
                                name='classes.usef_section_code'
                                render={({field: {value, name}}) => (
                                  <Select
                                    options={props.form_meta.usef_section}
                                    getOptionLabel={(option) => `${option.label} [${option.value}]`}
                                    // add substring search for USEF code
                                    value={
                                      value !== undefined
                                        ? props.form_meta.usef_section.filter(
                                            (ss) => ss.value === value
                                          )
                                        : ''
                                    }
                                    id='usef_section_code'
                                    name={name}
                                    isSearchable={true}
                                    // setting value for USEF code field - two way binding
                                    onChange={(usef_section) => {
                                      methods.setValue(
                                        'classes.usef_section_code',
                                        usef_section.value,
                                        {shouldDirty: true}
                                      )
                                    }}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                    })}
                                    styles={reactSelectStyles}
                                    placeholder='Select'
                                  />
                                )}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <label
                        className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0'
                        htmlFor='ec_section_code'
                        data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.CLASSSPECIFICATIONS.LABEL.ECSECTION'
                      >
                        {intl.formatMessage({id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.ECSECTION'})}
                      </label>

                      <div className='col align-items-center ps-0'>
                        <div className='row'>
                          <div className='col-lg-3'>
                            <Controller
                              name='classes.ec_section_code'
                              control={methods.control}
                              defaultValue=''
                              render={({field}) => (
                                <NumericFormat
                                  {...field}
                                  id='ec_section_code'
                                  type='text'
                                  allowNegative={false}
                                  decimalScale={0}
                                  className='form-control form-control-sm fs-6 min-h-20px py-1'
                                  onBlur={(e) => {
                                    if (
                                      props.form_meta.ec_section.filter(
                                        (ss) => ss.value === field.value
                                      ).length === 0
                                    ) {
                                      methods.setValue('classes.ec_classtypeid', 0, {
                                        shouldDirty: true,
                                      })
                                      methods.setValue('classes.ec_sanctionlevelid', 0, {
                                        shouldDirty: true,
                                      })
                                      field.onChange('', {shouldDirty: true})
                                    }
                                  }}
                                />
                              )}
                            />
                          </div>
                          <div className='col-lg-9'>
                            {props.form_meta.ec_section && (
                              <Controller
                                name='classes.ec_section_code'
                                render={({field: {onChange, value, name}}) => (
                                  <Select
                                    options={props.form_meta.ec_section}
                                    getOptionLabel={(option) => `${option.label} [${option.value}]`}
                                    // add substring search for EC code
                                    value={
                                      value !== undefined
                                        ? props.form_meta.ec_section.filter(
                                            (ss) => ss.value === value
                                          )
                                        : ''
                                    }
                                    id='ec_section_code'
                                    name={name}
                                    isSearchable={true}
                                    // setting value for EC code field - two way binding
                                    onChange={(ec_section) => {
                                      methods.setValue(
                                        'classes.ec_section_code',
                                        ec_section.value,
                                        {shouldDirty: true}
                                      )
                                      if (methods.getValues('classes.ec_section_code') == '') {
                                        methods.setValue('classes.ec_classtypeid', 0, {
                                          shouldDirty: true,
                                        })
                                        methods.setValue('classes.ec_sanctionlevelid', 0, {
                                          shouldDirty: true,
                                        })
                                      }
                                    }}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                    })}
                                    styles={reactSelectStyles}
                                    placeholder='Select'
                                  />
                                )}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <label
                        className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0'
                        htmlFor='ec_classtypeid'
                        data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.CLASSSPECIFICATIONS.LABEL.ECCLASSTYPE'
                      >
                        {intl.formatMessage({
                          id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.ECCLASSTYPE',
                        })}
                      </label>

                      <div className='col align-items-center ps-0'>
                        {props.form_meta.ec_class_type && (
                          <Controller
                            name='classes.ec_classtypeid'
                            render={({field: {onChange, value, name}}) => (
                              <Select
                                options={props.form_meta.ec_class_type}
                                value={
                                  value !== undefined
                                    ? props.form_meta.ec_class_type.find((ct) => ct.value == value)
                                    : ''
                                }
                                id='ec_classtypeid'
                                isDisabled={!methods.watch('classes.ec_section_code')}
                                name={name}
                                isSearchable={true}
                                onChange={(ec_class_type) => {
                                  onChange(ec_class_type.value)
                                }}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                })}
                                styles={reactSelectStyles}
                                placeholder='Select'
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <label
                        className='col-lg-1 col-form-label fs-5 py-1 pe-0 w-150px'
                        htmlFor='ec_sanctionlevelid'
                        data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.CLASSSPECIFICATIONS.LABEL.ECSANCTIONLEVEL'
                      >
                        {intl.formatMessage({
                          id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.ECSANCTIONLEVEL',
                        })}
                      </label>

                      <div className='col align-items-center ps-0'>
                        {props.form_meta.ec_sanction_type && (
                          <Controller
                            name='classes.ec_sanctionlevelid'
                            render={({field: {onChange, value, name}}) => (
                              <Select
                                options={props.form_meta.ec_sanction_type}
                                value={
                                  value !== undefined
                                    ? props.form_meta.ec_sanction_type.find(
                                        (ct) => ct.value == value
                                      )
                                    : ''
                                }
                                id='ec_sanctionlevelid'
                                name={name}
                                isSearchable={true}
                                isDisabled={!methods.watch('classes.ec_section_code')}
                                onChange={(ec_sanction_type) => {
                                  onChange(ec_sanction_type.value)
                                }}
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                })}
                                styles={reactSelectStyles}
                                placeholder='Select'
                              />
                            )}
                          />
                        )}
                      </div>
                    </div>
                  <div className='row'>
                    <label
                      className='col-lg-2 col-form-label fs-5 py-1 w-150px pe-0'
                      htmlFor='class_price'
                      data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.CLASSSPECIFICATIONS.LABEL.CLASSPRICE'
                    >
                      {intl.formatMessage({
                        id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.CLASSPRICE',
                      })}
                    </label>

                    <div className='col align-items-center ps-0'>
                      <div className='row mb-2'>
                        <div className='col-lg-4'>
                          <Controller
                            control={methods.control}
                            name='classes.class_price'
                            render={({field: {onChange, name, value}}) => (
                              <NumericFormat
                                prefix={'$'}
                                id='class_price'
                                value={value !== '' ? Number(value) : ''}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                onValueChange={(e) => {
                                  // Only update the value if it's changed by the user
                                  if (Number(e.value) !== Number(value)) {
                                    onChange(e.value)
                                  }
                                }}
                                onFocus={(e) => {
                                  e.target.select() // Highlight the value
                                }}
                                className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                                disabled={
                                  show_financials_locked &&
                                  methods.getValues('classes.class_id') > 0
                                }
                              />
                            )}
                          />
                        </div>

                        <label
                    // moved division price in front of class price  

                      className='col-lg-4 col-form-label fs-5 py-1 pe-0'
                      htmlFor='divisionPrice.price'
                      data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.CLASSSPECIFICATIONS.LABEL.DIVISIONPRICE'
                      >
                        {intl.formatMessage({
                          id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.DIVISIONPRICE',
                        })}
                      </label>
                      
                      <div className='col-lg-4 '>
                          <Controller
                            control={methods.control}
                            name='divisionPrice.price'
                            render={({field: {onChange, name, value}}) => (
                              <NumericFormat
                                prefix={'$'}
                                id='divisionPrice.price'
                                disabled={true}
                                value={value !== '' ? Number(value) : ''}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                onValueChange={(e) => {
                                  // Only update the value if it's changed by the user
                                  if (Number(e.value) !== Number(value)) {
                                    onChange(e.value)
                                  }
                                }}
                                onFocus={(e) => {
                                  e.target.select() // Highlight the value
                                }}
                                className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <label
                      className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0'
                      htmlFor='level'
                      data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.CLASSSPECIFICATIONS.LABEL.FENCEHEIGHT'
                    >
                      {intl.formatMessage({
                        id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.FENCEHEIGHT',
                      })}
                    </label>

                    <div className='col align-items-center ps-0'>
                      <div className='row mb-2'>
                        {/* Fence Height (Single) */}
                        <div className='col'>
                          <input
                            {...methods.register('classes.level', {
                              required: false,
                              onBlur: (e) => {
                                processFenceHeight(e.target.value)
                              },
                            })}
                            id='level'
                            className='form-control form-control-sm fs-6 min-h-20px py-1'
                            onKeyDown={(e) => {
                              setConversionMessage(null)
                              const char = e.key
                              const currentValue = e.target.value
                              const cursorPosition = e.target.selectionStart

                              // Skip special keys like backspace, delete, arrows etc
                              if (e.key.length > 1) return

                              // Allow only numbers, decimal point, single quote, double quote
                              const allowedChars = /[0-9.'"]/

                              // Check if character is allowed
                              if (!allowedChars.test(char)) {
                                e.preventDefault()
                                return
                              }

                              // First character validation
                              if (cursorPosition === 0 || currentValue.length === 0) {
                                // Allow numbers and decimal point as first character
                                if (!/[0-9.]/.test(char)) {
                                  e.preventDefault()
                                  return
                                }
                              }

                              // If first char was decimal, next char must be number
                              if (currentValue === '.' && !/[0-9]/.test(char)) {
                                e.preventDefault()
                                return
                              }

                              // Check for duplicate special characters
                              if (
                                (char === '.' && currentValue.includes('.')) ||
                                (char === "'" && currentValue.includes("'")) ||
                                (char === '"' && currentValue.includes('"'))
                              ) {
                                e.preventDefault()
                                return
                              }

                              // Check for consecutive quote combinations
                              if (char === "'" || char === '"') {
                                // Check if adding quote at cursor position would create invalid sequence
                                const beforeCursor = currentValue.slice(0, cursorPosition)
                                const afterCursor = currentValue.slice(cursorPosition)

                                // Check for "' or '" combinations
                                if (
                                  (char === "'" &&
                                    (beforeCursor.endsWith('"') ||
                                      afterCursor.startsWith('"'))) ||
                                  (char === '"' &&
                                    (beforeCursor.endsWith("'") ||
                                      afterCursor.startsWith("'")))
                                ) {
                                  e.preventDefault()
                                  return
                                }
                              }

                              // New validation: Prevent single quote if there's already a double quote
                              if (char === "'" && currentValue.includes('"')) {
                                e.preventDefault()
                                return
                              }

                              // New validation: Prevent single quote after number followed by double quote
                              if (char === "'" && /\d+"/.test(currentValue)) {
                                e.preventDefault()
                                return
                              }

                              // New validation: Prevent decimal point after double quote
                              if (
                                char === '.' &&
                                currentValue.slice(0, cursorPosition).includes('"')
                              ) {
                                e.preventDefault()
                                return
                              }
                            }}
                          />
                          <span className="col-form-label fs-7 py-1 pe-0">
                            {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.FENCEHEIGHTSINGLE' })}
                          </span>
                          {conversionMessage && (
                            <span className={`#5E6278`}>{conversionMessage.message}</span>
                          )}
                        </div>

                        {/* Multi-height (previously heights_in_inches) */}
                        <div className='col'>
                          <input
                            {...methods.register('classes.heights_in_inches', {
                              required: false,
                            })}
                            id='heights_in_inches'
                            type='text'
                            className='form-control form-control-sm fs-6 min-h-20px py-1'
                            onInput={(e) => {
                              // Allow only numbers and commas
                              e.target.value = e.target.value.replace(/[^0-9,.]/g, '')
                            }}
                            onBlur={(e) => {
                              const trimmedValue = e.target.value.trim()

                              if (trimmedValue === '') {                              
                                handleHeightsUnit()
                                return
                              }

                              const values = trimmedValue
                                .split(',')
                                .map((v) => v.trim())
                                .filter((v) => v !== '')
                              if (values.length <= 1) {
                                alertDialog({
                                  message: intl.formatMessage({
                                    id: 'FORM.INPUT.CLASSES.TAB.GENERAL.CLASSSPECIFICATIONS.HEIGHT.VALIDATION',
                                  }),
                                })
                                // e.target.focus(); // Bring the focus back to the input field
                              }                                                          
                            }}
                          />
                          <span className="col-form-label fs-7 py-1 pe-0" >
                            {intl.formatMessage({ id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.FENCEHEIGHTMULTI' })}
                          </span>
                        </div>

                        {/* Fence Height Type (Unit) */}
                        <div className='col'>
                          {props.form_meta.fence_height_types && (
                            <Controller
                              name='classes.unit'
                              render={({field: {onChange, value, name}}) => (
                                <Select
                                  options={props.form_meta.fence_height_types}
                                  value={props.form_meta.fence_height_types.find(
                                    (ss) => ss.value === value
                                  )}
                                  id='unit'
                                  name={name}
                                  isSearchable={true}
                                  onChange={(fence_height_types) => {
                                    onChange(fence_height_types.value)                                    
                                  }}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                  })}
                                  styles={reactSelectStyles}
                                  placeholder='Select'
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  </div>{' '}
                  {/* End Column 1 */}
                  <div className='col-lg-6'>
                    {' '}
                    {/* Column 2 */}
                    <div className='row mb-2'>
                      <label
                        className='col-lg-1 col-form-label fs-5 py-1 w-125px pe-0'
                        htmlFor='fei_event_code'
                        data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.CLASSSPECIFICATIONS.LABEL.FEIEVENTID'
                      >
                        {intl.formatMessage({
                          id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.FEIEVENTID',
                        })}
                      </label>

                      <div className='col-lg-4 ps-0'>
                        <input
                          {...methods.register('classes.fei_event_code', {
                            required: false,
                          })}
                          id='fei_event_code'
                          type='text'
                          disabled={
                            methods.getValues('classes.fei_import') &&
                            methods.getValues('classes.fei_event_code') != ''
                          }
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        />
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <label
                        className='col-lg-2 col-form-label fs-5 py-1 w-125px pe-0'
                        htmlFor='fei_competition_rule'
                        data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.CLASSSPECIFICATIONS.LABEL.FEIRULE'
                      >
                        {intl.formatMessage({id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.FEIRULE'})}
                      </label>

                      <div className='col-lg-4 ps-0'>
                        <input
                          {...methods.register('classes.fei_competition_rule', {
                            required: false,
                          })}
                          id='fei_competition_rule'
                          type='text'
                          disabled={
                            methods.getValues('classes.fei_import') &&
                            methods.getValues('classes.fei_competition_rule') != ''
                          }
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        />
                      </div>

                      <div className='col-lg-2'>
                        <input
                          {...methods.register('classes.fei_competition_round', {
                            required: false,
                          })}
                          id='fei_competition_round'
                          type='text'
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        />
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <label
                        className='col-lg-12 col-form-label fs-5 py-1'
                        htmlFor='description'
                        data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.CLASSSPECIFICATIONS.LABEL.DESCRIPTION'
                      >
                        {intl.formatMessage({
                          id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.DESCRIPTION',
                        })}
                      </label>

                      <div className='col-lg-12'>
                        <textarea
                          {...methods.register('classes.description', {
                            required: false,
                          })}
                          id='description'
                          rows='5'
                          className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                        ></textarea>
                      </div>
                    </div>
                  </div>{' '}
                  {/* EndColumn 2 */}
                </div>
              </fieldset>
            </div>
            <div className='row mb-2'>
              <div className='col-lg-6'>
                <fieldset>
                  <legend className='fs-5 fw-bold d-flex'>
                    {intl.formatMessage({
                      id: 'FORM.INPUT.CLASSES.TAB.GENERAL.GROUPLABEL.HORSEREQUIREMENTS',
                    })}{' '}
                    <b></b>
                  </legend>
                  <div className='row mb-2'>
                    <label
                      className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0'
                      htmlFor='class_requirements.Measurement_Card.value'
                      data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.HORSEREQUIREMENTS.LABEL.MEASCARD'
                    >
                      {intl.formatMessage({id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.MEASCARD'})}
                    </label>

                    <div className='col align-items-center ps-0'>
                      <div className='row'>
                        <div className='col-lg-7'>
                          {props.form_meta.verify_action && (
                            <Controller
                              name='class_requirements.Measurement_Card.value'
                              render={({field: {onChange, value, name}}) => (
                                <Select
                                  options={props.form_meta.verify_action}
                                  value={props.form_meta.verify_action.find(
                                    (ss) => ss.value === value
                                  )}
                                  id='class_requirements.Measurement_Card.value'
                                  name={name}
                                  isSearchable={true}
                                  onChange={(verify_action) => {
                                    onChange(verify_action.value)
                                  }}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                  })}
                                  styles={reactSelectStyles}
                                  placeholder='Select'
                                />
                              )}
                            />
                          )}
                        </div>
                        <div className='col-lg-5'>
                          {props.form_meta.requirement_action && (
                            <Controller
                              name='class_requirements.Measurement_Card.error_warn_status'
                              render={({field: {onChange, value, name}}) => (
                                <Select
                                  options={props.form_meta.requirement_action}
                                  value={props.form_meta.requirement_action.find(
                                    (ss) => ss.value === value
                                  )}
                                  id='class_requirements.Measurement_Card.error_warn_status'
                                  name={name}
                                  isSearchable={true}
                                  onChange={(requirement_action) => {
                                    onChange(requirement_action.value)
                                  }}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                  })}
                                  styles={reactSelectStyles}
                                  placeholder='Select'
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <label
                      className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0'
                      htmlFor='class_requirements.Horse_Age_Verified.value'
                      data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.HORSEREQUIREMENTS.LABEL.AGEVERIFIED'
                    >
                      {intl.formatMessage({id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.AGEVERIFIED'})}
                    </label>

                    <div className='col align-items-center ps-0'>
                      <div className='row'>
                        <div className='col-lg-7'>
                          {props.form_meta.verify_action && (
                            <Controller
                              name='class_requirements.Horse_Age_Verified.value'
                              render={({field: {onChange, value, name}}) => (
                                <Select
                                  options={props.form_meta.verify_action}
                                  value={props.form_meta.verify_action.find(
                                    (ss) => ss.value === value
                                  )}
                                  id='class_requirements.Horse_Age_Verified.value'
                                  name={name}
                                  isSearchable={true}
                                  onChange={(verify_action) => {
                                    onChange(verify_action.value)
                                  }}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                  })}
                                  styles={reactSelectStyles}
                                  placeholder='Select'
                                />
                              )}
                            />
                          )}
                        </div>
                        <div className='col-lg-5'>
                          {props.form_meta.requirement_action && (
                            <Controller
                              name='class_requirements.Horse_Age_Verified.error_warn_status'
                              render={({field: {onChange, value, name}}) => (
                                <Select
                                  options={props.form_meta.requirement_action}
                                  value={props.form_meta.requirement_action.find(
                                    (ss) => ss.value === value
                                  )}
                                  id='class_requirements.Horse_Age_Verified.error_warn_status'
                                  name={name}
                                  isSearchable={true}
                                  onChange={(requirement_action) => {
                                    onChange(requirement_action.value)
                                  }}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                  })}
                                  styles={reactSelectStyles}
                                  placeholder='Select'
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <label
                      className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0'
                      htmlFor='class_requirements.Horse_Age.from'
                      data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.HORSEREQUIREMENTS.LABEL.REQUIREDAGE'
                    >
                      {intl.formatMessage({
                        id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.HORSEREQUIREDAGEFROM',
                      })}
                    </label>

                    <div className='col align-items-center ps-0'>
                      <div className='row'>
                        <div className='col-lg-7'>
                          <div className='row'>
                            <div className='col-lg-5'>
                              <input
                                {...methods.register('class_requirements.Horse_Age.from', {
                                  required: false,
                                  valueAsNumber: true,
                                  // React hook form custom validation
                                  validate: {
                                    validate: (value) => {
                                      if (
                                        (value >
                                          methods.getValues('class_requirements.Horse_Age.to') &&
                                          methods.getValues('class_requirements.Horse_Age.to') !=
                                            0) ||
                                        value < 0 ||
                                        !value
                                      ) {
                                        methods.setValue('class_requirements.Horse_Age.from', 0)
                                      }
                                    },
                                  },
                                })}
                                id='class_requirements.Horse_Age.from'
                                type='text'
                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                              />
                            </div>
                            <div className='col-lg-2'>
                              <label
                                className='col-lg-3 col-form-label fs-5 py-1'
                                htmlFor='class_requirements.Horse_Age.to'
                              >
                                {intl.formatMessage({
                                  id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.HORSEREQUIREDAGETO',
                                })}
                              </label>
                            </div>
                            <div className='col-lg-5'>
                              <input
                                {...methods.register('class_requirements.Horse_Age.to', {
                                  required: false,
                                  valueAsNumber: true,
                                  // React hook form custom validation
                                  validate: {
                                    validate: (value) => {
                                      if (
                                        (value <
                                          methods.getValues('class_requirements.Horse_Age.from') &&
                                          methods.getValues('class_requirements.Horse_Age.from') !=
                                            0) ||
                                        value < 0 ||
                                        !value
                                      ) {
                                        methods.setValue('class_requirements.Horse_Age.to', 0)
                                      }
                                    },
                                  },
                                })}
                                id='class_requirements.Horse_Age.to'
                                type='text'
                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-5'>
                          {props.form_meta.requirement_action && (
                            <Controller
                              name='class_requirements.Horse_Age.error_warn_status'
                              render={({field: {onChange, value, name}}) => (
                                <Select
                                  options={props.form_meta.requirement_action}
                                  value={props.form_meta.requirement_action.find(
                                    (ss) => ss.value === value
                                  )}
                                  id='class_requirements.Horse_Age.error_warn_status'
                                  name={name}
                                  isSearchable={true}
                                  onChange={(requirement_action) => {
                                    onChange(requirement_action.value)
                                  }}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                  })}
                                  styles={reactSelectStyles}
                                  placeholder='Select'
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Horse Type Section */}
                  <div className='row mb-2'>
                    <label
                      className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0'
                      htmlFor='class_requirements.Horse_Type.value'
                      data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.HORSEREQUIREMENTS.LABEL.HORSETYPE'
                    >
                      {intl.formatMessage({id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.HORSETYPE'})}
                      <div className='mt-7'>OR</div>
                    </label>
                    <div className='col align-items-center ps-0'>
                      <div className='row'>
                        <div className='col-lg-7'>
                          {props.form_meta.horse_type && (
                            <select
                              {...methods.register('class_requirements.Horse_Type.value', {
                                required: false,
                                onChange: (e) => {
                                  const options = e.target.options
                                  const deselectOption = options[0]
                                  const isDeselectSelected = deselectOption.selected
                                  if (isDeselectSelected) {
                                    for (let i = 1; i < options.length; i++) {
                                      options[i].selected = false
                                    }
                                  }
                                },
                              })}
                              multiple
                              className='form-select  form-select-sm fs-6 min-h-20px py-1'
                              name='class_requirements.Horse_Type.value'
                              disabled={
                                (methods.watch('class_requirements.Horse_Height.from') &&
                                  methods.watch('class_requirements.Horse_Height.from') !== '') ||
                                (methods.watch('class_requirements.Horse_Height.to') &&
                                  methods.watch('class_requirements.Horse_Height.to') !== '')
                              }
                            >
                              {props.form_meta.horse_type.map((ht, index) => (
                                <option key={index} value={ht.label}>
                                  {ht.value}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                        <div className='col-lg-5'>
                          {props.form_meta.requirement_action && (
                            <Controller
                              name='class_requirements.Horse_Type.error_warn_status'
                              render={({field: {onChange, value, name}}) => (
                                <Select
                                  options={props.form_meta.requirement_action}
                                  value={props.form_meta.requirement_action.find(
                                    (ss) => ss.value === value
                                  )}
                                  id='class_requirements.Horse_Type.error_warn_status'
                                  name={name}
                                  isSearchable={true}
                                  onChange={(requirement_action) => {
                                    onChange(requirement_action.value)
                                  }}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                  })}
                                  styles={reactSelectStyles}
                                  placeholder='Select'
                                  isDisabled={
                                    (methods.watch('class_requirements.Horse_Height.from') &&
                                      methods.watch('class_requirements.Horse_Height.from') !==
                                        '') ||
                                    (methods.watch('class_requirements.Horse_Height.to') &&
                                      methods.watch('class_requirements.Horse_Height.to') !== '')
                                  }
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END */}

                  {/* Horse Height Section*/}
                  <div className='row mb-2'>
                    <label
                      className='col-lg-1 col-form-label fs-5 py-1 w-150px pe-0'
                      htmlFor='class_requirements.Horse_Height.from'
                      data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.HORSEREQUIREMENTS.LABEL.HORSEHEIGHT'
                    >
                      {intl.formatMessage({
                        id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.HORSEREQUIREDHEIGHTFROM',
                      })}
                    </label>

                    <div className='col align-items-center ps-0'>
                      <div className='row'>
                        <div className='col-lg-7'>
                          <div className='row'>
                            <div className='col-lg-5'>
                              <input
                                {...methods.register('class_requirements.Horse_Height.from', {
                                  required: false,
                                  validate: {
                                    validate: (value) => {
                                      const toValue = methods.getValues(
                                        'class_requirements.Horse_Height.to'
                                      )
                                      const heightInCm = horseConvertHeight(value, 'cm')
                                      const toHeightInCm = horseConvertHeight(toValue, 'cm')

                                      if (
                                        heightInCm === 0 &&
                                        (toValue === '' || toHeightInCm === 0)
                                      ) {
                                        return true
                                      } else if (heightInCm > toHeightInCm && toHeightInCm !== 0) {
                                        methods.setValue('class_requirements.Horse_Height.from', '')
                                      }
                                    },
                                  },
                                })}
                                disabled={
                                  methods.watch('class_requirements.Horse_Type.value') &&
                                  methods.watch('class_requirements.Horse_Type.value')?.length >
                                    0 &&
                                  methods.watch('class_requirements.Horse_Type.value') != ''
                                    ? true
                                    : false
                                }
                                id='class_requirements.Horse_Height.from'
                                type='text'
                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                              />
                              {methods.watch('class_requirements.Horse_Height.from') &&
                              methods.watch('class_requirements.Horse_Height.from') !== '' ? (
                                <div className='mt-1'>
                                  <span className='fs-6'>
                                    {horseConvertHeight(
                                      methods.watch('class_requirements.Horse_Height.from'),
                                      'cm'
                                    )}{' '}
                                    cm
                                  </span>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='col-lg-2'>
                              <label
                                className='col-lg-3 col-form-label fs-5 py-1'
                                htmlFor='class_requirements.Horse_Height.to'
                              >
                                {intl.formatMessage({
                                  id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.HORSEREQUIREDAGETO',
                                })}
                              </label>
                            </div>
                            <div className='col-lg-5'>
                              <input
                                {...methods.register('class_requirements.Horse_Height.to', {
                                  required: false,
                                  validate: {
                                    validate: (value) => {
                                      const fromValue = methods.getValues(
                                        'class_requirements.Horse_Height.from'
                                      )
                                      const heightInCm = horseConvertHeight(value, 'cm')
                                      const fromHeightInCm = horseConvertHeight(fromValue, 'cm')

                                      if (
                                        heightInCm === 0 &&
                                        (fromValue === '' || fromHeightInCm === 0)
                                      ) {
                                        return true
                                      } else if (
                                        heightInCm < fromHeightInCm &&
                                        fromHeightInCm !== 0
                                      ) {
                                        methods.setValue('class_requirements.Horse_Height.to', '')
                                      }
                                    },
                                  },
                                })}
                                disabled={
                                  methods.watch('class_requirements.Horse_Type.value') &&
                                  methods.watch('class_requirements.Horse_Type.value')?.length >
                                    0 &&
                                  methods.watch('class_requirements.Horse_Type.value') != ''
                                    ? true
                                    : false
                                }
                                id='class_requirements.Horse_Height.to'
                                type='text'
                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                              />
                              {methods.watch('class_requirements.Horse_Height.to') &&
                              methods.watch('class_requirements.Horse_Height.to') !== '' ? (
                                <div className='mt-1'>
                                  <span className='fs-6'>
                                    {horseConvertHeight(
                                      methods.watch('class_requirements.Horse_Height.to'),
                                      'cm'
                                    )}{' '}
                                    cm
                                  </span>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-5'>
                          {props.form_meta.requirement_action && (
                            <Controller
                              name='class_requirements.Horse_Height.error_warn_status'
                              render={({field: {onChange, value, name}}) => (
                                <Select
                                  options={props.form_meta.requirement_action}
                                  menuPosition='fixed'
                                  value={props.form_meta.requirement_action.find(
                                    (ss) => ss.value === value
                                  )}
                                  id='class_requirements.Horse_Height.error_warn_status'
                                  name={name}
                                  isSearchable={true}
                                  onChange={(requirement_action) => {
                                    onChange(requirement_action.value)
                                  }}
                                  theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                  })}
                                  menuPlacement='top'
                                  styles={reactSelectStyles}
                                  placeholder='Select'
                                  isDisabled={
                                    methods.watch('class_requirements.Horse_Type.value') &&
                                    methods.watch('class_requirements.Horse_Type.value')?.length >
                                      0 &&
                                    methods.watch('class_requirements.Horse_Type.value') != ''
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END */}
                </fieldset>
              </div>
              <div className='col-lg-6'>
                <fieldset>
                  <legend className='fs-5 fw-bold d-flex'>
                    {intl.formatMessage({
                      id: 'FORM.INPUT.CLASSES.TAB.GENERAL.GROUPLABEL.RIDERREQUIREMENTS',
                    })}{' '}
                    <b></b>
                  </legend>
                  <div className='row mb-2'>
                    <label
                      className='col-lg-1 col-form-label fs-5 py-1 w-125px pe-0'
                      htmlFor='class_requirements.Rider_Membership.value'
                      data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.RIDERREQUIREMENTS.LABEL.MEMBEROF'
                    >
                      {intl.formatMessage({id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.MEMBEROF'})}
                    </label>

                    <div className='col-lg-4 ps-0'>
                      {props.form_meta.member_of && (
                        <Controller
                          name='class_requirements.Rider_Membership.value'
                          render={({field: {onChange, value, name}}) => (
                            <Select
                              options={props.form_meta.member_of}
                              value={props.form_meta.member_of.find((ss) => ss.label === value)}
                              id='class_requirements.Rider_Membership.value'
                              name={name}
                              isSearchable={true}
                              onChange={(member_of) => {
                                onChange(member_of.label)
                              }}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                              })}
                              styles={reactSelectStyles}
                              placeholder='Select'
                              menuPlacement='top'
                            />
                          )}
                        />
                      )}
                    </div>

                    <div className='col-lg-4'>
                      {props.form_meta.requirement_action && (
                        <Controller
                          name='class_requirements.Rider_Membership.error_warn_status'
                          render={({field: {onChange, value, name}}) => (
                            <Select
                              options={props.form_meta.requirement_action}
                              value={props.form_meta.requirement_action.find(
                                (ss) => ss.value === value
                              )}
                              id='class_requirements.Rider_Membership.error_warn_status'
                              name={name}
                              isSearchable={true}
                              onChange={(requirement_action) => {
                                onChange(requirement_action.value)
                              }}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                              })}
                              styles={reactSelectStyles}
                              placeholder='Select'
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <label
                      className='col-lg-1 col-form-label fs-5 py-1 w-125px pe-0'
                      htmlFor='class_requirements.Rider_Age.from'
                      data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.RIDERREQUIREMENTS.LABEL.REQUIREDAGE'
                    >
                      {intl.formatMessage({
                        id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.RIDERREQUIREDAGEFROM',
                      })}
                    </label>

                    <div className='col-lg-4 align-items-center ps-0'>
                      <div className='row'>
                        <div className='col-lg-5'>
                          <input
                            {...methods.register('class_requirements.Rider_Age.from', {
                              required: false,
                              valueAsNumber: true,
                              validate: {
                                // React hook form custom validation
                                validate: (value) => {
                                  if (
                                    (value > methods.getValues('class_requirements.Rider_Age.to') &&
                                      methods.getValues('class_requirements.Rider_Age.to') != 0) ||
                                    value < 0 ||
                                    !value
                                  ) {
                                    methods.setValue('class_requirements.Rider_Age.from', 0)
                                  }
                                },
                              },
                            })}
                            id='class_requirements.Rider_Age.from'
                            type='text'
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                          />
                        </div>
                        <div className='col-lg-2'>
                          <label
                            className='col-lg-3 col-form-label fs-5 py-1'
                            htmlFor='class_requirements.Rider_Age.to'
                          >
                            {intl.formatMessage({
                              id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.RIDERREQUIREDAGETO',
                            })}
                          </label>
                        </div>
                        <div className='col-lg-5'>
                          <input
                            {...methods.register('class_requirements.Rider_Age.to', {
                              required: false,
                              valueAsNumber: true,
                              // React hook form custom validation
                              validate: {
                                validate: (value) => {
                                  if (
                                    (value <
                                      methods.getValues('class_requirements.Rider_Age.from') &&
                                      methods.getValues('class_requirements.Rider_Age.from') !=
                                        0) ||
                                    value < 0 ||
                                    !value
                                  ) {
                                    methods.setValue('class_requirements.Rider_Age.to', 0)
                                  }
                                },
                              },
                            })}
                            id='class_requirements.Rider_Age.to'
                            type='text'
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      {props.form_meta.requirement_action && (
                        <Controller
                          name='class_requirements.Rider_Age.error_warn_status'
                          render={({field: {onChange, value, name}}) => (
                            <Select
                              options={props.form_meta.requirement_action}
                              value={props.form_meta.requirement_action.find(
                                (ss) => ss.value === value
                              )}
                              id='class_requirements.Rider_Age.error_warn_status'
                              name={name}
                              isSearchable={true}
                              onChange={(requirement_action) => {
                                onChange(requirement_action.value)
                              }}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                              })}
                              styles={reactSelectStyles}
                              placeholder='Select'
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <label
                      className='col-lg-1 col-form-label fs-5 py-1 w-125px pe-0'
                      htmlFor='class_requirements.Rider_Status.value'
                      data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.SECTION.RIDERREQUIREMENTS.LABEL.RIDERSTATUS'
                    >
                      {intl.formatMessage({id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.RIDERSTATUS'})}
                    </label>

                    <div className='col-lg-4 ps-0'>
                      <select
                        multiple
                        {...methods.register('class_requirements.Rider_Status.value', {
                          required: false,
                          onChange: (e) => {
                            //deselect other options if "SELECT..." is active
                            const options = e.target.options
                            const deselectOption = options[0]
                            const isDeselectSelected = deselectOption.selected
                            if (isDeselectSelected) {
                              for (let i = 1; i < options.length; i++) {
                                options[i].selected = false
                              }
                            }
                          },
                        })}
                        className='form-select  form-select-sm fs-6 min-h-20px py-1'
                        name='class_requirements.Rider_Status.value'
                      >
                        <option value=''>SELECT...</option>
                        <option value='JUNIOR'>JUNIOR</option>
                        <option value='AMATEUR'>AMATEUR</option>
                        <option value='PROFESSIONAL'>PROFESSIONAL</option>
                      </select>
                    </div>

                    <div className='col-lg-4'>
                      {props.form_meta.requirement_action && (
                        <Controller
                          name='class_requirements.Rider_Status.error_warn_status'
                          render={({field: {onChange, value, name}}) => (
                            <Select
                              options={props.form_meta.requirement_action}
                              value={props.form_meta.requirement_action.find(
                                (ss) => ss.value === value
                              )}
                              id='class_requirements.Rider_Status.error_warn_status'
                              name={name}
                              isSearchable={true}
                              onChange={(requirement_action) => {
                                onChange(requirement_action.value)
                              }}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                              })}
                              styles={reactSelectStyles}
                              placeholder='Select'
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className='row mb-2 mt-2'>
              <div className='col-lg-2 form-check-sm form-check-custom ps-3 w-225px'>
                <input
                  {...methods.register('classes.required', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='required'
                  disabled={show_financials_locked}
                />
                <label
                  className='col-form-label mx-2 fs-5 py-1 ignore-max-width'
                  htmlFor='required'
                  data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.LABEL.REQUIREDBYDIVISION'
                >
                  {' '}
                  {intl.formatMessage({
                    id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.REQUIREDBYDIVISION',
                  })}
                </label>
              </div>
              <div className='col-lg-3 form-check-sm form-check-custom'>
                <input
                  {...methods.register('classes.no_results', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='no_results'
                />
                <label
                  className='col-form-label mx-2 fs-5 py-1 ignore-max-width'
                  htmlFor='no_results'
                  data-tooltip-id='CLASS.DETAIL.TAB.GENERAL.LABEL.NORESULTFORTHISCLASS'
                >
                  {' '}
                  {intl.formatMessage({
                    id: 'FORM.INPUT.CLASSES.TAB.GENERAL.LABEL.NORESULTSFORCLASS',
                  })}
                </label>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export {GeneralTab}
