import { useAppSelector } from '../../redux/hooks';
import { useAlert, useLoadingOverlay, useProgress } from '../sgl-utils/DialogsProvider';
import { useOutputContext } from '../output-listing/OutputListingContext';
import axios from 'axios';
import useFileDownloader from './use-file-downloader';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const useGenerateExport = () => {
    const { getOutputSelectionAreaIDs, getEventID } = useOutputContext();
    const customerId = useAppSelector(state=> state.showCompany.company_id)
    const currentShowId = useAppSelector(state => state.currentShow.show_id);
    const loadingOverlay = useLoadingOverlay()
    const {progressBar} = useProgress()
    const { startFileDownloader, forceCloseFileDownloader } = useFileDownloader()
    const alertDialog = useAlert();

    const generateExport = (settings) => {
        loadingOverlay({ show: true })

        // list of exports for which subset or whole list of record is considered for export
        let exportGeneratedForSubset = ['Classes DJS Export']
        let considerUserSelection = exportGeneratedForSubset.includes(settings?.progressBarTitle)  ? false : true
        getOutputSelectionAreaIDs(considerUserSelection)
        .then(res => {             
            loadingOverlay({ show: false })
            
            if (res.length === 0) { // if there are no records is selection, loading overlay never closes. fixed the issue
                return
            }

            // Generate Event Id for progress bar and display progress bar and open event stream one
            const progressEventId = getEventID(settings.progressBarTitle) 
            progressBar({ show: true, eventID: progressEventId, showProgress: 'inline', title: settings.progressBarTitle, timeElapsed: true })

            // Generate Event Id for file buffer response and open event stream two, to receive files from server
            const fileBufferEventId = getEventID(`${settings.progressBarTitle}-filebuffer`)
            startFileDownloader(fileBufferEventId)

            axios.post(`${NEST_API_URL}/${settings.url}`, {
                customer_id: customerId,
                current_show_id: currentShowId,
                record_ids: res,
                progress_event_id: progressEventId,
                file_buffer_event_id: fileBufferEventId,
                export_type: settings?.exportType ? settings?.exportType : '',   // added export type for division standings exports
                show_id: settings?.showId ? settings.showId : '',
                fee_paid_year: settings?.feePaidYear ? settings?.feePaidYear : '',
                master_fee_id: settings?.masterFeeId ? settings?.masterFeeId : '',
                roles: settings?.roles ? settings?.roles : '',
            })
            .then((response) => {
                progressBar({ show: false, eventID: progressEventId })
                if ('success' in response.data && !response.data.success) {
                    alertDialog({message: response.data.message})
                }
            })
            .catch(() =>  { 
                progressBar({ show: false, eventID: progressEventId })
                forceCloseFileDownloader()   
            })
        })
        .catch(() => loadingOverlay({ show: false }))
    }

    return generateExport
}

export default useGenerateExport