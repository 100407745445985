import axios from "axios"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { setShowCompany } from "../../redux/reducers/showCompanyReducer"
import { useAuth } from "../auth"
import { getAllColumnDefinitions, getLoggedInUser } from "../sgl-utils/agGridHelpers"
import { clearCurrentShow, setCurrentShow } from "../../redux/reducers/currentShowReducer"
const API_URL = process.env.REACT_APP_NEST_API_URL

const useChangeCompany = () => {
    const{ setCurrentCompany, currentUser } = useAuth()
    const dispatch = useAppDispatch()
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    // const oldCompany = useAppSelector(state => state.showCompany)

    // Sets company without user's manual intervention
    // 1. if passed in url OR
    // 2. has access to only 1 company
    const setCompanyAuto = async (user, company) => {
        const oldCompany = getLastSetCompany()
        // Remove old show from redux state
        dispatch(clearCurrentShow({}))

        // Set company in redux state
        dispatch(setShowCompany(company));

        // Set company in auth context (for routing)
        setCurrentCompany(company)

        // Get column definition for single company user
        await getAllColumnDefinitions(user.id, oldCompany?.company_id, "remove")
        await getAllColumnDefinitions(user.id, company.company_id, "set")

        await getLoggedInUser(company.company_id)

        // On Company change remove the logged in user from old company logged in users list
        axios.get(API_URL+ `/utility/updateLoginUser?customer_id=${company.company_id}&delRecord=${false}`)
    }

    const changeCompany = async (newCompany, _currentUser = null) => {
        try {
            // if user is not passed, only then get/use user from state.
            if (!_currentUser) _currentUser = currentUser

            if (newCompany) {
                let delRecord = false
                const oldCompany = getLastSetCompany() //Get old show company from storage instead of redux as it is not set on load
                if (oldCompany?.company_id !== newCompany.company_id) { // Company changed or not already set.
                    // Update default company in database
                    const response = await axios.patch( API_URL + '/users/setDefaultSGLLoginCompany', {
                        company_id: newCompany.company_id
                    })

                    if (response.data.success) {
                        delRecord = true
                        setCompanyAuto(_currentUser, newCompany)
                    }
                } else {

                    // Set company in auth context
                    setCurrentCompany(newCompany)

                    // Set company in redux state
                    dispatch(setShowCompany(newCompany))

                    // Do not wait for show id to be updated from axios call, set show in redux from session
                    if(!currentShowID){
                        let show = sessionStorage.getItem('currentShow');
                        if(!show){
                            show = localStorage.getItem('currentShow');
                        }
                        if(show){
                            show = JSON.parse(show);
                            show.currentShowSetAt = Date.now();
                            dispatch(setCurrentShow(show));
                        }
                    }
                    sessionStorage.removeItem("modal_is_open");
                }

                // On Company change remove the logged in user from old company logged in users list
                await axios.get(API_URL+ `/utility/updateLoginUser?customer_id=${oldCompany.company_id}&new_customer_id=${newCompany.company_id}&delRecord=${delRecord}`)

            } 
        } catch(err) {
            console.error("Error changing company: ", err)
        }       
    }

    const getLastSetCompany = () => {
        // first check in session storage, if not found then get from local starage
        // we need to use session storage as we need to add support for multiple companies in different tabs
        let storedShowCompany = sessionStorage.getItem('currentShowCompany');
        if(!storedShowCompany){
            storedShowCompany = localStorage.getItem('currentShowCompany');
        }
        if(storedShowCompany){ //show in localstorage found?
          return JSON.parse(storedShowCompany);
        }

        return null;
    } 

    const clearLastSetCompany = () => {
        sessionStorage.removeItem('currentShowCompany')
        localStorage.removeItem('currentShowCompany')
    }
   
    return { changeCompany, getLastSetCompany, setCompanyAuto, clearLastSetCompany }
}

export default useChangeCompany