import { useState, useEffect, useRef } from 'react';
import { useAnnouncerToolContext } from './AnnouncerToolContext';
import { useIntl } from 'react-intl';
import axios from "axios";
import { addFloatingNumbers, getCurrentTimeStamp, loadingSpinnerBtnRelease, loadingSpinnerBtnWait, overrideEventTarget, toFixedTrunc } from '../../../modules/sgl-utils/SglFunctions';
import { useAlert } from '../../../modules/sgl-utils/DialogsProvider';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ScoringMode = (props) => {
  const alertDialog = useAlert();
  const intl = useIntl();
  const { currentClassGroup, currentTrip, dropdowns, currentShowID, customerID, updateCompletedTripsForClass, setUpcomingTrips, warningForTripsSetOfToQuickly, lastStaggerUpdateRef, setLastIngateActivity, selectedRing, farmtekRound, setFarmtekRound } = useAnnouncerToolContext()
  const [ tripData, setTripData ] = useState()
  const [ disqualifyStatusOneList, setDisqualifyStatusOneList ] = useState([])
  const [ disqualifyStatusTwoList, setDisqualifyStatusTwoList ] = useState([])
  const [ disqualifyStatusThreeList, setDisqualifyStatusThreeList ] = useState([])
  const [ farmtekSse, setFarmtekSse ] = useState()
  const [ farmtekData, setFarmtekData ] = useState()
  const [ farmtekDataTimer, setFarmtekDataTimer ] = useState()
  const [ lastFarmtekData, setLastFarmtekData ] = useState(null)
  let farmtekSseEventHandler = useRef() // using ref to persist data between renders

  //state variables to handle retain user overrides
  let [updatedFields, setUpdatedFields] = useState([]) //keep track of which fields are updated by user
  let textFields = ['trip_one_status', 'disqualify_status_one', 'trip_two_status', 'disqualify_status_two', 'trip_three_status', 'disqualify_status_three'];

  useEffect(() => {
    setTripData((prevTripData) => { 
        let trip = {...currentTrip};
        let prevTripDataFields = []
        if(currentTrip){
            if(currentTrip?.entryxclasses_id == prevTripData?.entryxclasses_id){ //selected trip is same (in outside call sync update received from backend)
                //Retain user overrides in current trip data and update non-empty/non-zero data in state for latest update for current trip
                for(let field of updatedFields){//loop through updatedFields to retain their values
                    trip[field] = prevTripData[field]; //retain data for updated fields
                    prevTripDataFields.push(field);
                }
            }
            setDecimalPlacesForScoringFields(trip)
        }
        setUpdatedFields(prevTripDataFields)//Reset the updatedFields
        return trip;
    })
  },[currentTrip])

  useEffect(() => {
    setDisqualifyStatusOneList([])
    setDisqualifyStatusTwoList([])
    setDisqualifyStatusThreeList([])
    if(tripData)
    {
        // Set values for disqualify 1 drop down
        const disqualifyStatusOneList =  dropdowns?.disqualify_status_list?.length>0 && dropdowns?.disqualify_status_list?.map((dis, index) => {
            let value = (index !== 0) ? dis.value : (tripData?.disqualify_status_one !== '' && tripData?.disqualify_status_one !== 'Reinstate Trip') ? 'Reinstate Trip' : ''
            let label = (index !== 0) ? dis.label : (tripData?.disqualify_status_one !== '' && tripData?.disqualify_status_one !== 'Reinstate Trip') ? 'Reinstate Trip' : ''
            return (
                <option key={dis.value} value={value} selected = { dis.value == tripData?.disqualify_status_one }>
                    {label}
                </option>
            )
        })
        setDisqualifyStatusOneList(disqualifyStatusOneList)

        // Set values for disqualify 2 drop down
        const disqualifyStatusTwoList =  dropdowns?.disqualify_status_list?.length>0 && dropdowns?.disqualify_status_list?.map((dis, index) => {
            let value = (index !== 0) ? dis.value : (tripData?.disqualify_status_two !== '' && tripData?.disqualify_status_two !== 'Reinstate Trip') ? 'Reinstate Trip' : ''
            let label = (index !== 0) ? dis.label : (tripData?.disqualify_status_two !== '' && tripData?.disqualify_status_two !== 'Reinstate Trip') ? 'Reinstate Trip' : ''
            return (
                <option key={dis.value} value={value} selected = { dis.value == tripData?.disqualify_status_two }>
                    {label}
                </option>
            )
        })
        setDisqualifyStatusTwoList(disqualifyStatusTwoList)

        // Set values for disqualify 3 drop down
        const disqualifyStatusThreeList =  dropdowns?.disqualify_status_list?.length>0 && dropdowns?.disqualify_status_list?.map((dis, index) => {
            let value = (index !== 0) ? dis.value : (tripData?.disqualify_status_three !== '' && tripData?.disqualify_status_three !== 'Reinstate Trip') ? 'Reinstate Trip' : ''
            let label = (index !== 0) ? dis.label : (tripData?.disqualify_status_three !== '' && tripData?.disqualify_status_three !== 'Reinstate Trip') ? 'Reinstate Trip' : ''
            return (
                <option key={dis.value} value={value} selected = { dis.value == tripData?.disqualify_status_three }>
                    {label}
                </option>
            )
        })
        setDisqualifyStatusThreeList(disqualifyStatusThreeList)
        
    }
  },[tripData?.disqualify_status_one, tripData?.disqualify_status_two, tripData?.disqualify_status_three])

    //for opening stream for farmtek data update
    useEffect(() => {
        // if an event source already created, then close it first
        if (farmtekSse) {
            farmtekSse.close()
        }

        // if ring is selected ring 
        if (selectedRing) {
            setFarmtekData(null)
            setLastFarmtekData(null)
            setFarmtekSse(new EventSource(`${process.env.REACT_APP_NEST_API_URL}/utility/farmtekSyncUpdate?event_id=farmtek_trip_association_${customerID}_${selectedRing}`))
        }
    }, [selectedRing, customerID]);

    useEffect(() => {
        //listen to message if stream is open
        if (farmtekSse) {
            // removing previous listener, so multiple listeners are not assigned
            farmtekSse.removeEventListener('farmtek', farmtekSseEventHandler.current)
            farmtekSseEventHandler.current = e => {
                let farmtekDataObj = JSON.parse(e.data)
                if (farmtekDataObj && 'status' in farmtekDataObj && 'data' in farmtekDataObj) {
                    farmtekDataObj.data = getTimeFromFarmtekData(farmtekDataObj) // convert time data to num
                    farmtekDataObj.receivedAt = Date.now();
                    setFarmtekData(farmtekDataObj);
                }
            }

            // adding new listener
            farmtekSse.addEventListener('farmtek', farmtekSseEventHandler.current)
        }
    }, [farmtekSse, tripData])
    
    // This use effect will close the farmtek sse event stream when the area is dismounted
    useEffect(() => {

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            if (farmtekSse) {
                farmtekSse.close()
            }
        };
    }, [farmtekSse])

    useEffect(() => {
        if (farmtekData) {
            //clear previous state and timer
            if (farmtekDataTimer) {
                clearTimeout(farmtekDataTimer)
            }
            const timer = setTimeout(() => {
                setFarmtekData(null)
            }, 5 * 60 * 1000); // set time exceeded to true after 5 minutes

            // set new timer
            setFarmtekDataTimer(timer)
        }
    }, [farmtekData])

    useEffect(() => {
        //save last received farmtek data update
        let currentFarmtekRound = farmtekRound;
        let first_ft_update = false;
        let setTripFullTime = false;
        
        if (farmtekData && 'status' in farmtekData && 'data' in farmtekData) {
            //Reset round 
            if (lastFarmtekData) {
                if (lastFarmtekData?.status == "FT" && farmtekData.status != "FT") {
                    currentFarmtekRound = "R1";  //reset round on FT update
                    setFarmtekRound('R1');
                }
                //ignore consecutive full time updates
                first_ft_update = (lastFarmtekData?.status != "FT" && farmtekData.status == "FT")
            }
             
            //Check trip is selected and first FT update received 
            //Note: check non-empty value of vl_EntryNumber as vl_EntryXClass_ID is not cleared on group change
            setTripFullTime = (first_ft_update && tripData?.entryxclasses_id > 0 && currentClassGroup?.group_type == 'Jumpers');
        }

        //check if time for round not set but received from farmtek 
        if (setTripFullTime) {
            if (currentFarmtekRound == 'R1' && Boolean(Number(tripData.time_one)) == false && farmtekData?.status == "FT") { // update trip data state with new time_one
                setTripData({ ...tripData, time_one: farmtekData.data })
                tripData.time_one = farmtekData.data;
                updateScoreAndTimeForTrip(null, 'updateTimeFaultsR1')
            }

            if (currentFarmtekRound == 'JO' && Boolean(Number(tripData.time_two)) == false && farmtekData?.status == "FT") { // update trip data state with new time_two
                setTripData({ ...tripData, time_two: farmtekData.data })
                tripData.time_two = farmtekData.data;
                updateScoreAndTimeForTrip(null, 'updateTimeFaultsR2')
            }
        }

        setLastFarmtekData(farmtekData) //setting last farmtek data
    }, [farmtekData])

    const getTimeFromFarmtekData = (farmtekDataObj) => {
        // convert time for CD from mm:ss format to seconds
        if (farmtekDataObj.status == "CD" && farmtekDataObj.data.includes(":")) {
            // get index of colon
            let colonPos = farmtekDataObj.data.indexOf(":")
            // get mins and seconds, convert to number
            let mins = Number(farmtekDataObj.data.substring(0,colonPos))
            let seconds = Number(farmtekDataObj.data.substring(colonPos + 1))
            return (mins * 60 + seconds)
        }
        else {
            // just convert to number in case of  FT and OC
            return Boolean(Number(farmtekDataObj.data)) ? Number(farmtekDataObj.data) : 0 //handle NaN and undefined values
        }
    }

  const updateScoreAndTimeForTrip = (event, action) => {
     /*  ***List of actions for which webservice will be called***

      Type: Hunters
      1- { operation: saveScore button is clicked, Action: saveScore }

      Type: Hunter Derby
      1- { operation: round 1 saveScore button is clicked, Action: saveScoreR1 }
      2- { operation: round 2 saveScore button is clicked, Action: saveScoreR2 }
      3- { operation: round 2 R2 start button is clicked, Action: startR2 }

      Type: Jumpers
      Round 1
      1- { operation: round 1 +4 is clicked, Action: incrementJumpFaultsR1 }
      2- { operation: round 1 -4 is clicked, Action: decrementJumpFaultsR1 }
      3- { operation: round 1 +1 is clicked, Action: incrementTimeFaultsR1 } ***currently disabled
      4- { operation: round 1 -1 is clicked, Action: decrementTimeFaultsR1 } ***currently disabled
      5- { operation: round 1 store is clicked, Action: saveTimeR1 }
      Round 2
      1- { operation: round 2 +4 is clicked, Action: incrementJumpFaultsR2 }
      2- { operation: round 2 -4 is clicked, Action: decrementJumpFaultsR2 }
      3- { operation: round 2 +1 is clicked, Action: incrementTimeFaultsR2 } ***currently disabled
      4- { operation: round 2 -1 is clicked, Action: decrementTimeFaultsR2 } ***currently disabled
      5- { operation: round 2 store is clicked, Action: saveTimeR2 }
      6- { operation: JO start is clicked, Action: startR2 }

      General: Disqualify
      1- { operation: round 1 disqualify status is set, Action: disqualifyR1 }
      2- { operation: round 2 disqualify status is set, Action: disqualifyR2 }

 */

 /*
      ***List of actions for which front end functions will be called***

      Type: Jumpers
      Round 1
      1- { operation: Time 1 value is changed, Action: updateTimeFaultsR1, function: calculateTimeFaults }
      2- { operation: Time 2 value is changed, Action: updateTimeFaultsR2, function: calculateTimeFaults }

      Type: Hunters
      1- { operation: when any score field is changed for hunter like scoreone, scoretwo etc, Action: updateHunterTotalScore, function:calculateHunterTripTotalScore  }

      Type: Hunter Derby
      1- { operation: when any score field is changed in derby round 1 like derbyroneponebasescore etc, scoretwo etc, Action: updateHunterDerbyScore1, function:calculateHunterTripTotalScore }
      2- { operation: when any score field is changed in derby round 2 like derbyrtwoponebasescore etc, Action: updateHunterDerbyScore2, function:calculateHunterTripTotalScore }
  */

    if(currentTrip && action)
    {
        let updatedTripData = tripData; //IMPORTANT: DO NOT CHANGE THIS, it causes issue if moved to copy object
        setDecimalPlacesForScoringFields(updatedTripData)

        //Note: we need to call this outside setTripData because axios is executed before setTripData
        if(action == 'disqualifyR1'){
            updatedTripData.disqualify_status_one = event.target.value
        }else if(action == 'disqualifyR2'){
            updatedTripData.disqualify_status_two = event.target.value
        } else if (action === 'disqualifyR3') {
            updatedTripData.disqualify_status_three = event.target.value
        }

        //update state variable as per new values
        setTripData((prevTripData) => { // update faults_one/ faults_two in trip data
            let trip = {...updatedTripData};
            if(action == "incrementJumpFaultsR1"){
                trip.faults_one = trip.faults_one + 4
            }else if(action == "decrementJumpFaultsR1"){
                trip.faults_one = trip.faults_one - 4
            }else if(action == "incrementJumpFaultsR2"){
                trip.faults_two = trip.faults_two + 4
            }else if(action == "decrementJumpFaultsR2"){
                trip.faults_two = trip.faults_two - 4
            } else if (action === 'decrementJumpFaultsR3') {
                trip.faults_three = trip.faults_three - 4
            } else if (action === 'incrementJumpFaultsR3') {
                trip.faults_three = trip.faults_three + 4
            }
            return trip;
        })

        if(action === 'saveScore' || action === 'saveScoreR1' || action === 'saveScoreR2' || action === 'startR2' || action === 'incrementJumpFaultsR1' || action === 'decrementJumpFaultsR1' || action === 'saveTimeR1' || action === 'incrementJumpFaultsR2' || action === 'decrementJumpFaultsR2' || action === 'saveTimeR2' || action === 'disqualifyR1' || action === 'disqualifyR2' || ['saveTimeR3', 'startR3', 'decrementJumpFaultsR3', 'incrementJumpFaultsR3', 'disqualifyR3'].includes(action)) // if action is set call webservice
        {
            lastStaggerUpdateRef.current =  getCurrentTimeStamp(); 
            // Display spinner inplace of button text. It will only be displayed for buttons
            (event?.target?.type == "button") && loadingSpinnerBtnWait(event)
            axios.patch(`${NEST_API_URL}/ingate-announcer/updateTripTimeAndScores`, {
                show_id: currentShowID,
                customer_id: customerID,
                class_group_id: currentClassGroup?.class_group_id,
                class_id: currentTrip?.class_id,
                entryxclasses_id: currentTrip?.entryxclasses_id,
                trip_data: updatedTripData,
                action: action
            })
            .then(response => {
                if (response.data.success) {
                   setUpcomingTrips(response?.data?.upcomingTrips)
                   updateCompletedTripsForClass(currentTrip?.class_id, response?.data?.classCompletedTrips);  
                   lastStaggerUpdateRef.current =  getCurrentTimeStamp() 
                }
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            })
            .finally(() =>{
                //Hide spinner from button text. It will only work for buttons
                (event?.target?.type == "button") && loadingSpinnerBtnRelease(event)
            });

            switch(action) {
                case 'disqualifyR1':
                    setLastIngateActivity('Disqualify R1 Trip')
                    break;
                case 'disqualifyR2':
                    setLastIngateActivity(currentClassGroup?.group_type == 'Jumpers' ?  'Disqualify JO Trip' : 'Disqualify R2 Trip')
                    break;
                case 'saveScore':
                    setLastIngateActivity('Save Score')
                    break;
                case 'saveScoreR1':
                    setLastIngateActivity('Save R1 Score')
                    break;
                case 'saveTimeR1':
                    setLastIngateActivity('Save Time1')
                    break;
                case 'saveScoreR2':
                    setLastIngateActivity('Save R2 Score')
                    break;
                case 'saveTimeR2':
                    setLastIngateActivity('Save JO Score')
                    break;
                case 'startR2':
                    if(currentClassGroup?.has_jo_round){ //set farmtek updates for JO round
                        setFarmtekRound('JO');
                    }
                    setLastIngateActivity(currentClassGroup?.group_type == 'Jumpers' ?  'Save JO Start' : 'Save R2 Start')
                    break;
                case 'saveTimerR3':
                    // todo:
                    break;
                case 'startR3':
                    // todo:
                    break;
                default:
                  return setLastIngateActivity('')
            }
        }
    }

  }

  //Determine Total Hunter Score based on Sum or Average
  const calculateHunterTripTotalScore = (trip, classGroup) => {
    if(classGroup?.hunter_type.includes('Hunter Derby')){
        return calculateHunterDerbyTripTotalScore(trip, classGroup);
    }

    const scores = [
        toFixedTrunc(trip.scoreone) ?? 0,
        toFixedTrunc(trip.scoretwo) ?? 0,
        toFixedTrunc(trip.scorethree) ?? 0,
        toFixedTrunc(trip.scorefour) ?? 0,
        toFixedTrunc(trip.scorefive) ?? 0,
        toFixedTrunc(trip.scoresix) ?? 0,
    ];
    
    //Total Score
    return calculateHunterScoresByHunterScoreBy(classGroup, scores);
  }

  const calculateHunterDerbyTripTotalScore = (trip, classGroup) => {
    if(['Hunter Derby - Hunter Spectacular'].includes(classGroup?.hunter_type)){ //Hunter Derby rules for which scores of both rounds are averaged.
        //$150,000 WCHR PETER WETHERILL PALM BEACH HUNTER SPECTACULAR
        //F. In entry’s Overall Score is the AVERAGE of round one and the AVERAGE of round two score. 
        // The Sum of round one and round two will then be averaged. 
        // Example: if an entry receives a 90 in round one and a 92 in round two, then their average Overall Score will be 91.
        //https://media.wellingtoninternational.com/media/uvmmfrut/2025-wef-prize-list_draft-1-24-25.pdf
        let scores = [
            toFixedTrunc(trip.scoreone) ?? 0,
            toFixedTrunc(trip.scoretwo) ?? 0,
        ];
        return calculateHunterScoresByHunterScoreBy({...classGroup, hunter_scoreby: 'Average Score'}, scores);
    }

    return addFloatingNumbers([trip.scoreone, trip.scoretwo], 3, true);
  }

  const calculateHunterScoresByHunterScoreBy = (classGroup, scores) => {
    if(classGroup?.hunter_scoreby == 'Average Score' ){
        // Filter out the non-zero scores
        const nonZeroScores = scores.filter((score) => { return Number(score) !== 0 && !isNaN(score); })
    
        // Calculate the average of non-zero scores
        const sumOfNonZeroScores = addFloatingNumbers(nonZeroScores, 3, true);
        
        return nonZeroScores.length > 0 ? toFixedTrunc((parseFloat(sumOfNonZeroScores) / nonZeroScores.length)) : '0.000';
    }

    //Total Score
    return addFloatingNumbers(scores, 3, true);
  }

  // !4D -> annc_calcTimeFaults
  const calculateTimeFaults = (isJumpOff, classGroup, timeAllowed, tripTime) => {
    let timeFaults = 0
    if (timeAllowed > 0 && tripTime > timeAllowed) { // If trip has run over-time

        // Any Partial seconds over time are counted as ONE fault so we add 0.499 and Round to Zero to achieve that
        let timeDiff = tripTime - timeAllowed
        while(timeDiff > 0) {
            timeDiff -= 1
            timeFaults++
        }

        // FEI Time fault rule amendment 2022 - One Penalty for each second commenced instead of four seconds
        if (classGroup?.day < '2022-01-01' && classGroup?.day !== '0000-00-00') {
            // One penalty for every four seconds commenced
            if ((['FEI Rule 238.2.1',  'FEI Rule 238.2.2', 'FEI Rule 239'].includes(classGroup.jumper_table)) && !isJumpOff) {
                timeFaults = Math.trunc((timeFaults + 3)/4)
            } else if (classGroup?.jumper_table == 'FEI Nations Cup') {
                timeFaults = Math.trunc((timeFaults + 3)/4)
            } else if (['FEI Rule 274.5.6', 'FEI Rule 274.2.5', 'FEI Rule 274.1.5.2', 'FEI Rule 274.5.3', 'FEI Rule 274.1.5.3'].includes(classGroup.jumper_table)) {
                timeFaults = Math.trunc((timeFaults + 3)/4)
            }
        }
        // From 2022 onwards - Nations Cup is judged under Table A, both rounds against the clock
        //Exceeding the time allowed in all Competitions run under Table A, One Penalty for each second commenced
        /*
        else if (classGroup?.jumper_table === 'FEI Nations Cup') { // 1 fault per 4 seconds over TA for Nations Cup
            timeFaults = Math.trunc((timeFaults + 3)/4)
        }
        */
    }
    
    return timeFaults
  }

  // Ignore when input is minus
  const preventNonNumericKeys = (event) => {
    const alphabets = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z",'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
    const specialCharacters = [ "!", "\"", "#", "$", "%", "&", "'", "(", ")", "*", "+", ",", "-", "/",  ":", ";", "<", "=", ">", "?", "_", "{", "}", "[", "]", "@", "^", "|", "~", "`", "\\"];
    if (specialCharacters.includes(event.key) || alphabets.includes(event.key)) {
        event.preventDefault();
    }
  }

  const onJumperR1KeyDown = (event) => {
    if(event.key == 'Enter'){
        updateScoreAndTimeForTrip(overrideEventTarget(event, 'saveTimeR1'), 'saveTimeR1')
    }
    if(event.target.id != "trip_one_status"){
        preventNonNumericKeys(event);
    }
  }
  
  const onJumperR2KeyDown = (event) => {
    if(event.key == 'Enter'){
        updateScoreAndTimeForTrip(overrideEventTarget(event, 'saveTimeR2'), 'saveTimeR2')
    }
    if(event.target.id != "trip_two_status"){
        preventNonNumericKeys(event);
    }
  }

  const onJumperR3KeyDown = (event) => {
    if(event.key == 'Enter'){
        updateScoreAndTimeForTrip(overrideEventTarget(event, 'saveTimeR3'), 'saveTimeR3')
    }

    if(event.target.id != "trip_three_status"){
        preventNonNumericKeys(event);
    }
  }

  const onHunterDerbyR2KeyDown = (event) => {
    if(event.key == 'Enter'){
        updateScoreAndTimeForTrip(overrideEventTarget(event, 'saveScoreR2'), 'saveScoreR2');
    }
    preventNonNumericKeys(event);
  }

  const onHunterDerbyR1KeyDown = (event) => {
    if(event.key == 'Enter'){
        updateScoreAndTimeForTrip(overrideEventTarget(event, 'saveScoreR1'), 'saveScoreR1');
    }
    preventNonNumericKeys(event);
  }

  const onHunterKeyDown = (event) => {
    if(event.key == 'Enter'){
        updateScoreAndTimeForTrip(overrideEventTarget(event, 'saveScore'), 'saveScore');
    }
    preventNonNumericKeys(event);
  }
  
  const setDecimalPlacesForScoringFields = (updatedTripData) => {
    if(!currentTrip) { return true; }
    if(!currentClassGroup) { return true; }

    if(currentClassGroup.group_type == "Jumpers"){
        updatedTripData.faults_one = parseFloat(updatedTripData.faults_one) ? parseFloat(updatedTripData.faults_one) : 0;
        updatedTripData.time_one = toFixedTrunc(updatedTripData.time_one) // Jumpers
        updatedTripData.time_fault_one = calculateTimeFaults(false, currentClassGroup, currentClassGroup?.timeallowed_tripone, toFixedTrunc(updatedTripData?.time_one, 'number'))
        
        updatedTripData.faults_two = parseFloat(updatedTripData.faults_two) ? parseFloat(updatedTripData.faults_two) : 0;
        updatedTripData.time_two = toFixedTrunc(updatedTripData.time_two)
        updatedTripData.time_fault_two = calculateTimeFaults(true, currentClassGroup, currentClassGroup?.timeallowed_jo, toFixedTrunc(updatedTripData?.time_two, 'number'))

        updatedTripData.faults_three = parseFloat(updatedTripData.faults_three) ? parseFloat(updatedTripData.faults_three) : 0
        updatedTripData.time_three = toFixedTrunc(updatedTripData.time_three)
        updatedTripData.time_fault_three = calculateTimeFaults(true, currentClassGroup, currentClassGroup?.timeallowed_r3, toFixedTrunc(updatedTripData?.time_three, 'number'))
    }
    else{
        updatedTripData.scoreone = toFixedTrunc(updatedTripData.scoreone)// Hunter
        updatedTripData.scoretwo = toFixedTrunc(updatedTripData.scoretwo)
        updatedTripData.scorethree = toFixedTrunc(updatedTripData.scorethree)
        updatedTripData.scorefour = toFixedTrunc(updatedTripData.scorefour)
        updatedTripData.scorefive = toFixedTrunc(updatedTripData.scorefive)
        updatedTripData.scoresix = toFixedTrunc(updatedTripData.scoresix)

        if(currentClassGroup.group_score_type == "Hunter Derby"){
            //Round 1 Panel 1
            updatedTripData.derbyroneponeoptionbonus = toFixedTrunc(updatedTripData.derbyroneponeoptionbonus)//Hunter derby R1
            updatedTripData.derbyroneponehandybonus = toFixedTrunc(updatedTripData.derbyroneponehandybonus)
            updatedTripData.derbyroneponebasescore = toFixedTrunc(updatedTripData.derbyroneponebasescore)
            updatedTripData.derbyroneponescore = addFloatingNumbers([updatedTripData.derbyroneponeoptionbonus, updatedTripData.derbyroneponehandybonus, updatedTripData.derbyroneponebasescore], 3, true);

            //Round 1 Panel 2
            updatedTripData.derbyroneptwooptionbonus = toFixedTrunc(updatedTripData.derbyroneptwooptionbonus)
            updatedTripData.derbyroneptwohandybonus = toFixedTrunc(updatedTripData.derbyroneptwohandybonus)
            updatedTripData.derbyroneptwobasescore = toFixedTrunc(updatedTripData.derbyroneptwobasescore)
            updatedTripData.derbyroneptwoscore = addFloatingNumbers([updatedTripData.derbyroneptwooptionbonus, updatedTripData.derbyroneptwohandybonus, updatedTripData.derbyroneptwobasescore], 3, true);
            
            //Round 1 Panel 3
            updatedTripData.derbyronepthreeoptionbonus = toFixedTrunc(updatedTripData.derbyronepthreeoptionbonus)
            updatedTripData.derbyronepthreehandybonus = toFixedTrunc(updatedTripData.derbyronepthreehandybonus)
            updatedTripData.derbyronepthreebasescore = toFixedTrunc(updatedTripData.derbyronepthreebasescore)
            updatedTripData.derbyronepthreescore = addFloatingNumbers([updatedTripData.derbyronepthreeoptionbonus, updatedTripData.derbyronepthreehandybonus, updatedTripData.derbyronepthreebasescore], 3, true);
            
            //Round 1 Panel 4
            updatedTripData.derbyronepfouroptionbonus = toFixedTrunc(updatedTripData.derbyronepfouroptionbonus)
            updatedTripData.derbyronepfourhandybonus = toFixedTrunc(updatedTripData.derbyronepfourhandybonus)
            updatedTripData.derbyronepfourbasescore = toFixedTrunc(updatedTripData.derbyronepfourbasescore)
            updatedTripData.derbyronepfourscore = addFloatingNumbers([updatedTripData.derbyronepfouroptionbonus, updatedTripData.derbyronepfourhandybonus, updatedTripData.derbyronepfourbasescore], 3, true);
            
            updatedTripData.scoreone = calculateHunterScoresByHunterScoreBy(currentClassGroup, [updatedTripData.derbyroneponescore, updatedTripData.derbyroneptwoscore, updatedTripData.derbyronepthreescore, updatedTripData.derbyronepfourscore]);
            
            //Round 2 Panel 1
            updatedTripData.derbyrtwoponeoptionbonus = toFixedTrunc(updatedTripData.derbyrtwoponeoptionbonus)//Hunter derby R2
            updatedTripData.derbyrtwoponehandybonus = toFixedTrunc(updatedTripData.derbyrtwoponehandybonus)
            updatedTripData.derbyrtwoponebasescore = toFixedTrunc(updatedTripData.derbyrtwoponebasescore)
            updatedTripData.derbyrtwoponescore = addFloatingNumbers([updatedTripData.derbyrtwoponeoptionbonus, updatedTripData.derbyrtwoponehandybonus, updatedTripData.derbyrtwoponebasescore], 3, true);
            
            //Round 2 Panel 2
            updatedTripData.derbyrtwoptwooptionbonus = toFixedTrunc(updatedTripData.derbyrtwoptwooptionbonus)
            updatedTripData.derbyrtwoptwobasescore = toFixedTrunc(updatedTripData.derbyrtwoptwobasescore)
            updatedTripData.derbyrtwoptwohandybonus = toFixedTrunc(updatedTripData.derbyrtwoptwohandybonus)
            updatedTripData.derbyrtwoptwoscore = addFloatingNumbers([updatedTripData.derbyrtwoptwooptionbonus, updatedTripData.derbyrtwoptwohandybonus, updatedTripData.derbyrtwoptwobasescore], 3, true);
            
            //Round 2 Panel 3
            updatedTripData.derbyrtwopthreeoptionbonus = toFixedTrunc(updatedTripData.derbyrtwopthreeoptionbonus)
            updatedTripData.derbyrtwopthreehandybonus = toFixedTrunc(updatedTripData.derbyrtwopthreehandybonus)
            updatedTripData.derbyrtwopthreebasescore = toFixedTrunc(updatedTripData.derbyrtwopthreebasescore)
            updatedTripData.derbyrtwopthreescore = addFloatingNumbers([updatedTripData.derbyrtwopthreeoptionbonus, updatedTripData.derbyrtwopthreehandybonus, updatedTripData.derbyrtwopthreebasescore], 3, true);
            
            //Round 2 Panel 4
            updatedTripData.derbyrtwopfouroptionbonus = toFixedTrunc(updatedTripData.derbyrtwopfouroptionbonus)
            updatedTripData.derbyrtwopfourhandybonus = toFixedTrunc(updatedTripData.derbyrtwopfourhandybonus)
            updatedTripData.derbyrtwopfourbasescore = toFixedTrunc(updatedTripData.derbyrtwopfourbasescore)
            updatedTripData.derbyrtwopfourscore = addFloatingNumbers([updatedTripData.derbyrtwopfouroptionbonus, updatedTripData.derbyrtwopfourhandybonus, updatedTripData.derbyrtwopfourbasescore], 3, true);
            
            updatedTripData.scoretwo = calculateHunterScoresByHunterScoreBy(currentClassGroup, [updatedTripData.derbyrtwoponescore, updatedTripData.derbyrtwoptwoscore, updatedTripData.derbyrtwopthreescore, updatedTripData.derbyrtwopfourscore]);
        }
        
        //set total score
        updatedTripData.total_score = calculateHunterTripTotalScore(updatedTripData, currentClassGroup)
    }

    return updatedTripData
  }

  const setDataForTrip = (field, value) => {
    
    if (tripData && tripData.hasOwnProperty(field)) {
        setTripData(prevTripData => {
            const updatedTripData = { ...prevTripData, [field]: value };
            let action = 'remove';

            if(textFields.includes(field)){//If text field than perform simple comparison
                if(updatedTripData[field] !== currentTrip[field]){
                    action = 'add'; //add field in updatedFields Array
                }
            }
            else{//If numeric field than convert it to floating number and than perform comparison
                if (toFixedTrunc(updatedTripData[field]) !== toFixedTrunc(currentTrip[field])) {
                    action = 'add'; //add field in updatedFields Array
                }
            }

            if(action == 'add'){
                if(!updatedFields.includes(field)){ //field is not already set in state
                    setUpdatedFields([...updatedFields, field])
                }
            }
            else if(action == 'remove'){
                if(updatedFields.includes(field)){ //field is present in state
                    setUpdatedFields(updatedFields.filter(item => item !== field))
                }
            }
            return updatedTripData;
        });
    }
  };

    const getHighestAddedScore = () => {
        if (!currentTrip)
            return currentClassGroup ? currentClassGroup.number_of_scores : 6

        let highestAddedScore = 1
        if (currentTrip.scoresix > 0) 
            highestAddedScore = 6
        else if (currentTrip.scorefive > 0)
            highestAddedScore = 5
        else if (currentTrip.scorefour > 0) 
            highestAddedScore = 4
        else if (currentTrip.scorethree > 0)
            highestAddedScore = 3
        else if (currentTrip.scoretwo > 0)
            highestAddedScore = 2
        return highestAddedScore
    }

  return (
    <>
        { (currentClassGroup?.group_type == "Hunters" && currentClassGroup?.group_score_type != "Hunter Derby") &&
        <>
            <fieldset className='mb-1 mt-1'>
                <legend className="fs-6 fw-bold d-flex">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.GROUPLABEL.SCORINGFAULTSTIME'})}<b></b></legend>

                <div className='row mb-0'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='SCORE' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND1.LABEL.SCORE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.SCORE'})}</label>
                    <div className='col'>
                        <div className='row mb-0'>
                            <div className='col-lg-4'>
                                <input
                                    id='scoreone'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.scoreone && tripData.scoreone > 0 ? tripData.scoreone : ''}
                                    onChange={(e) => { setDataForTrip('scoreone', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterTotalScore') }}
                                    onKeyDown={(e) => {onHunterKeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='Score 1'
                                />
                            </div>
                            { (currentClassGroup.number_of_scores > 1 || getHighestAddedScore() > currentClassGroup.number_of_scores) &&
                            <div className='col-lg-4'>
                                <input
                                    id='scoretwo'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.scoretwo && tripData.scoretwo > 0? tripData.scoretwo : ''}
                                    onChange={(e) => { setDataForTrip('scoretwo', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterTotalScore') }}
                                    onKeyDown={(e) => {onHunterKeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='Score 2'
                                />
                            </div> }

                            { (currentClassGroup.number_of_scores > 2 || getHighestAddedScore() > currentClassGroup.number_of_scores) &&
                            <div className='col-lg-4'>
                                <input
                                    id='scorethree'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.scorethree && tripData.scorethree > 0 ? tripData.scorethree : ''}
                                    onChange={(e) => { setDataForTrip('scorethree', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterTotalScore') }}
                                    onKeyDown={(e) => {onHunterKeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='Score 3'
                                />
                            </div> }
                        </div>
                        { (currentClassGroup.number_of_scores > 3 || getHighestAddedScore() > currentClassGroup.number_of_scores) &&
                        <div className='row'>
                            { (currentClassGroup.number_of_scores > 3 || getHighestAddedScore() > currentClassGroup.number_of_scores) &&
                            <div className='col-lg-4'>
                                <input
                                    id='scorefour'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.scorefour && tripData?.scorefour > 0 ? tripData.scorefour : ''}
                                    onChange={(e) => { setDataForTrip('scorefour', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterTotalScore') }}
                                    onKeyDown={(e) => {onHunterKeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='Score 4'
                                />
                            </div> }
                            { (currentClassGroup.number_of_scores > 4 || getHighestAddedScore() > currentClassGroup.number_of_scores) &&
                            <div className='col-lg-4'>
                                <input
                                    id='scorefive'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input' 
                                    value = {tripData && tripData?.scorefive && tripData.scorefive > 0 ? tripData.scorefive : ''}
                                    onChange={(e) => { setDataForTrip('scorefive', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterTotalScore') }}
                                    onKeyDown={(e) => {onHunterKeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='Score 5'
                                />
                            </div> }
                            { (currentClassGroup.number_of_scores > 5 || getHighestAddedScore() > currentClassGroup.number_of_scores) &&
                            <div className='col-lg-4'>
                                <input
                                    id='scoresix'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input' 
                                    value = {tripData && tripData.scoresix && tripData.scoresix > 0 ? tripData.scoresix : ''}
                                    onChange={(e) => { setDataForTrip('scoresix', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterTotalScore') }}
                                    onKeyDown={(e) => {onHunterKeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='Score 6'
                                />
                            </div> }
                        </div> }
                    </div>
                </div>

                <div className='row my-2'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='SCORE' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND1.LABEL.TOTAL">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.TOTAL'})}</label>
                    <div className='col'>
                        <div className='row flex justify-content-between'>
                            <div className='col-lg-4'>
                                <input
                                    id='hunter_total'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.total_score != null ? tripData?.total_score : ''}
                                    placeholder='0.000'
                                    inputMode="numeric"
                                    disabled
                                />
                            </div>
                            { farmtekData?.data ? 
                            <div className='col-lg-3 d-flex justify-content-start align-self-start pe-0'>
                                <label className='col-lg-2 col-form-label fs-6 pe-0 py-1' htmlFor='FARMTEKDATA'>{`${farmtekData?.status}`}</label>
                                <label className='col-lg-1 col-form-label fs-6 pe-0 py-1  mx-3' htmlFor='FARMTEKDATA'>{`${farmtekData?.data}`}</label>
                            </div> 
							: null }
                            <div className='col-lg-5 d-flex justify-content-end align-self-center'> 
                                <button type="button" className='btn btn-sm btn-secondary w-100px py-1 px-1 fw-bold h-30px rect button-no-wrap' id='saveScore' onClick={(e)=>{updateScoreAndTimeForTrip(e, 'saveScore')}} onKeyDown={(e) => {onHunterKeyDown(e)}} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND1.BUTTON.SAVESCORE"><span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true" data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND1.BUTTON.SAVESCORE"></span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.SAVESCORE'})}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-0'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='DISQUALIFY' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND1.LABEL.DISQUALIFY">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.DISQUALIFY'})}</label>
                    <div className='col'>
                        <select id="DISQUALIFY" className='form-select form-select-sm fs-6 h-30px py-1' onChange={(e) => { tripData && updateScoreAndTimeForTrip(e, 'disqualifyR1') }} >
                            {disqualifyStatusOneList}
                        </select>
                    </div>
                </div>
            </fieldset>
        </>
        }

        { (currentClassGroup?.group_score_type == "Hunter Derby") &&
        <>

          <div className='row' style={{marginTop:"-21px",marginBottom:"5px"}}>
              <label className='col col-form-label fs-6 py-1 text-end fw-bold blue' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND1.LABEL.TOTALSCORE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.TOTALSCORE'})} : {tripData?.total_score && tripData?.total_score || 0}</label>
          </div>  

            <fieldset className='mb-0'>
                <legend className="fs-6 fw-bold d-flex justify-content-between less-margin"><span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.GROUPLABEL.SCORINGFAULTSTIME'})}</span> <span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.GROUPLABEL.ROUND1'})}</span></legend>

                <div className='row'>
                    <div className='col-lg-3 d-flex justify-content-start align-self-center'> 
                    </div>
                    <div className='col'>
                        <div className='row'>
                            <label className='col-lg-3 col-form-label fs-6 py-1' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.OPBO">
                                {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.OPBO'})}
                            </label>
                            <label className='col-lg-3 col-form-label fs-6 py-1' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.HNDBO">
                                {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.HNDBO'})}
                            </label>
                            <label className='col-lg-3 col-form-label fs-6 py-1' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.BASE">
                                {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.BASE'})}
                            </label>
                            <label className='col-lg-3 col-form-label fs-6 py-1' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.SUBTOTAL">
                                {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.SUBTOTAL'})}
                            </label>
                        </div>
                    </div>
                </div>
                        

                <div className='row mb-0'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='OPBONUS1' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND1.LABEL.PANEL1">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.PANEL1'})}</label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyroneponeoptionbonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyroneponeoptionbonus != null ? tripData?.derbyroneponeoptionbonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyroneponeoptionbonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore1') }}
                                    onKeyDown={(e) => {onHunterDerbyR1KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            {/* Panel 1 - Handy Bonus */}
                            <div className='col-lg-3'>
                                <input
                                    id='derbyroneponehandybonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyroneponehandybonus != null ? tripData?.derbyroneponehandybonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyroneponehandybonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore1') }}
                                    onKeyDown={(e) => {onHunterDerbyR1KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyroneponebasescore'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyroneponebasescore != null ? tripData?.derbyroneponebasescore : ''}
                                    onChange={(e) => { setDataForTrip('derbyroneponebasescore', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore1') }}
                                    onKeyDown={(e) => {onHunterDerbyR1KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyroneponescore'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyroneponescore != null ? tripData?.derbyroneponescore : ''}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>

                { (currentClassGroup.number_of_scores > 1 || currentTrip?.derbyroneptwoscore > 0) &&
                <div className='row mb-0'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='OPBONUS2' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND1.LABEL.PANEL2">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.PANEL2'})}</label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyroneptwooptionbonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyroneptwooptionbonus != null ? tripData?.derbyroneptwooptionbonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyroneptwooptionbonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore1') }}
                                    onKeyDown={(e) => {onHunterDerbyR1KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            {/* Panel 2 - Handy Bonus */}
                            <div className='col-lg-3'>
                                <input
                                    id='derbyroneptwohandybonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyroneptwohandybonus != null ? tripData?.derbyroneptwohandybonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyroneptwohandybonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore1') }}
                                    onKeyDown={(e) => {onHunterDerbyR1KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyroneptwobasescore'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyroneptwobasescore != null ? tripData?.derbyroneptwobasescore : ''}
                                    onChange={(e) => { setDataForTrip('derbyroneptwobasescore', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore1') }}
                                    onKeyDown={(e) => {onHunterDerbyR1KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyroneptwoscore'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyroneptwoscore != null ? tripData?.derbyroneptwoscore : ''}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div> }

                { (currentClassGroup.number_of_scores > 2 || currentTrip?.derbyronepthreescore > 0) &&
                <div className='row mb-0'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='OPBONUS3' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND1.LABEL.PANEL3">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.PANEL3'})}</label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyronepthreeoptionbonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyronepthreeoptionbonus != null ? tripData?.derbyronepthreeoptionbonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyronepthreeoptionbonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore1') }}
                                    onKeyDown={(e) => {onHunterDerbyR1KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            {/* Panel 3 - Handy Bonus */}
                            <div className='col-lg-3'>
                                <input
                                    id='derbyronepthreehandybonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyronepthreehandybonus != null ? tripData?.derbyronepthreehandybonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyronepthreehandybonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore1') }}
                                    onKeyDown={(e) => {onHunterDerbyR1KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyronepthreebasescore'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyronepthreebasescore != null ? tripData?.derbyronepthreebasescore : ''}
                                    onChange={(e) => { setDataForTrip('derbyronepthreebasescore', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore1') }}
                                    onKeyDown={(e) => {onHunterDerbyR1KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyronepthreescore'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyronepthreescore != null ? tripData?.derbyronepthreescore : ''}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div> }

                { (currentClassGroup.number_of_scores > 3 || currentTrip?.derbyronepfourscore > 0) &&
                <div className='row mb-0'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='OPBONUS4' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND1.LABEL.PANEL4">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.PANEL4'})}</label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyronepfouroptionbonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyronepfouroptionbonus != null ? tripData?.derbyronepfouroptionbonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyronepfouroptionbonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore1') }}
                                    onKeyDown={(e) => {onHunterDerbyR1KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            {/* Panel 4 - Handy Bonus */}
                            <div className='col-lg-3'>
                                <input
                                    id='derbyronepfourhandybonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyronepfourhandybonus != null ? tripData?.derbyronepfourhandybonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyronepfourhandybonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore1') }}
                                    onKeyDown={(e) => {onHunterDerbyR1KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyronepfourbasescore'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyronepfourbasescore != null ? tripData?.derbyronepfourbasescore : ''}
                                    onChange={(e) => { setDataForTrip('derbyronepfourbasescore', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore1') }}
                                    onKeyDown={(e) => {onHunterDerbyR1KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyronepfourscore'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyronepfourscore != null ? tripData?.derbyronepfourscore : ''}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div> }

                <div className='row mb-0 d-flex justify-content-end'>
                    <label className='col-lg-3 col-form-label fs-6 w-125px pe-0 py-1 fw-bold text-end' htmlFor='ROUND1SCORE' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND1.LABEL.ROUND1SCORE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.ROUND1SCORE'})}</label>
                        <div className='col-lg-3'>
                            <input
                                id='scoreone'
                                type='text'
                                className='form-control fs-6 h-30px py-1 px-1 number-input'
                                value = {tripData && tripData?.scoreone != null ? tripData?.scoreone : ''}
                                placeholder='0.000'
                                inputMode="numeric"
                                disabled
                            />
                        </div>
                </div>

                <div className='row mb-1 mt-1'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='DISQUALIFY1' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND1.LABEL.DISQUALIFY">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.DISQUALIFY'})}</label>
                    <div className='col-lg-5'>
                        <select id="DISQUALIFY1" className='form-select form-select-sm fs-6 h-30px py-1' onChange={(e) => tripData && updateScoreAndTimeForTrip(e, 'disqualifyR1')} >
                            {disqualifyStatusOneList}
                        </select>
                    </div>
                    <div className='col d-flex justify-content-end align-self-center'> 
                        <button type="button" className='btn btn-sm btn-secondary w-100px py-1 px-1 fw-bold h-30px rect button-no-wrap' id='saveScoreR1' onClick={(e)=>{updateScoreAndTimeForTrip(e, 'saveScoreR1')}} onKeyDown={(e) => {onHunterDerbyR1KeyDown(e)}} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND1.BUTTON.SAVESCORE"><span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.SAVESCORE'})}</button>
                    </div>
                </div>
            </fieldset>


            <fieldset className='mb-0'>
            <legend className="fs-6 fw-bold d-flex justify-content-between less-margin"><span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.GROUPLABEL.SCORINGFAULTSTIME'})}</span> <span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.GROUPLABEL.ROUND2'})}</span></legend>

                <div className='row'>
                    <div className='col-lg-3 d-flex justify-content-start align-self-center'> 
                        <button type="button" className='btn btn-sm btn-secondary w-70px py-1 px-1 mt-1 fw-bold h-25px rect fw-bold cursor-pointer r2_start button-no-wrap' onClick={(e)=>{updateScoreAndTimeForTrip(e, 'startR2')}} onKeyDown={(e) => {if(e.key == 'Enter'){updateScoreAndTimeForTrip(e, 'startR2')}}} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETWO.BUTTON.R2START"><span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.R2START'})}</button>
                    </div>
                    <div className='col'>
                        <div className='row'>
                            <label className='col-lg-3 col-form-label fs-6 py-1' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.OPBO">
                                {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.OPBO'})}
                            </label>
                            <label className='col-lg-3 col-form-label fs-6 py-1' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.HNDBO">
                                {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.HNDBO'})}
                            </label>
                            <label className='col-lg-3 col-form-label fs-6 py-1' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.BASE">
                                {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.BASE'})}
                            </label>
                            <label className='col-lg-3 col-form-label fs-6 py-1' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.SUBTOTAL">
                                {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.SUBTOTAL'})}
                            </label>
                        </div>
                    </div>
                </div>

                <div className='row mb-0'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='R2-OPBONUS1' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.PANEL1">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.PANEL1'})}</label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwoponeoptionbonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwoponeoptionbonus != null ? tripData?.derbyrtwoponeoptionbonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyrtwoponeoptionbonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore2') }}
                                    onKeyDown={(e) => {onHunterDerbyR2KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwoponehandybonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwoponehandybonus != null ? tripData?.derbyrtwoponehandybonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyrtwoponehandybonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore2') }}
                                    onKeyDown={(e) => {onHunterDerbyR2KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwoponebasescore'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwoponebasescore != null ? tripData?.derbyrtwoponebasescore : ''}
                                    onChange={(e) => { setDataForTrip('derbyrtwoponebasescore', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore2') }}
                                    onKeyDown={(e) => {onHunterDerbyR2KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwoponescore'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwoponescore != null ? tripData?.derbyrtwoponescore : ''}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>

                { (currentClassGroup.number_of_scores > 1 || currentTrip?.derbyrtwoptwoscore > 0) &&
                <div className='row mb-0'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='R2-OPBONUS2' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.PANEL2">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.PANEL2'})}</label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwoptwooptionbonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwoptwooptionbonus != null ? tripData?.derbyrtwoptwooptionbonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyrtwoptwooptionbonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore2') }}
                                    onKeyDown={(e) => {onHunterDerbyR2KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwoptwohandybonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwoptwohandybonus != null ? tripData?.derbyrtwoptwohandybonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyrtwoptwohandybonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore2') }}
                                    onKeyDown={(e) => {onHunterDerbyR2KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwoptwobasescore'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwoptwobasescore != null ? tripData?.derbyrtwoptwobasescore : ''}
                                    onChange={(e) => { setDataForTrip('derbyrtwoptwobasescore', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore2') }}
                                    onKeyDown={(e) => {onHunterDerbyR2KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwoptwoscore'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwoptwoscore != null ? tripData?.derbyrtwoptwoscore : ''}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div> }

                { (currentClassGroup.number_of_scores > 2 || currentTrip?.derbyrtwopthreescore > 0) &&
                <div className='row mb-0'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='R2-OPBONUS3' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.PANEL3">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.PANEL3'})}</label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwopthreeoptionbonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwopthreeoptionbonus != null ? tripData?.derbyrtwopthreeoptionbonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyrtwopthreeoptionbonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore2') }}
                                    onKeyDown={(e) => {onHunterDerbyR2KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwopthreehandybonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwopthreehandybonus != null ? tripData?.derbyrtwopthreehandybonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyrtwopthreehandybonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore2') }}
                                    onKeyDown={(e) => {onHunterDerbyR2KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwopthreebasescore'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwopthreebasescore != null ? tripData?.derbyrtwopthreebasescore : ''}
                                    onChange={(e) => { setDataForTrip('derbyrtwopthreebasescore', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore2') }}
                                    onKeyDown={(e) => {onHunterDerbyR2KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwopthreescore'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwopthreescore != null ? tripData?.derbyrtwopthreescore : ''}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div> }

                { (currentClassGroup.number_of_scores > 3 || currentTrip?.derbyrtwopfourscore > 0) &&
                <div className='row mb-0'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='R2-OPBONUS4' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.PANEL4">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.PANEL4'})}</label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwopfouroptionbonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwopfouroptionbonus != null ? tripData?.derbyrtwopfouroptionbonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyrtwopfouroptionbonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore2') }}
                                    onKeyDown={(e) => {onHunterDerbyR2KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwopfourhandybonus'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwopfourhandybonus != null ? tripData?.derbyrtwopfourhandybonus : ''}
                                    onChange={(e) => { setDataForTrip('derbyrtwopfourhandybonus', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore2') }}
                                    onKeyDown={(e) => {onHunterDerbyR2KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwopfourbasescore'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwopfourbasescore != null ? tripData?.derbyrtwopfourbasescore : ''}
                                    onChange={(e) => { setDataForTrip('derbyrtwopfourbasescore', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateHunterDerbyScore2') }}
                                    onKeyDown={(e) => {onHunterDerbyR2KeyDown(e)}}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                />
                            </div>
                            <div className='col-lg-3'>
                                <input
                                    id='derbyrtwopfourscore'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.derbyrtwopfourscore != null ? tripData?.derbyrtwopfourscore : ''}
                                    inputMode="numeric"
                                    placeholder='0.000'
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div> }


                <div className='row mb-0 d-flex justify-content-end'>
                    <label className='col-lg-3 col-form-label fs-6 w-125px pe-0 py-1 fw-bold text-end' htmlFor='ROUND2SCORE' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.ROUND2SCORE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.ROUND2SCORE'})}</label>
                        <div className='col-lg-3'>
                            <input
                                id='scoretwo'
                                type='text'
                                className='form-control fs-6 h-30px py-1 px-1 number-input'
                                value = {tripData && tripData?.scoretwo != null ? tripData?.scoretwo : ''}
                                placeholder='0.000'
                                inputMode="numeric"
                                disabled
                            />
                        </div>
                </div>

                <div className='row mb-0 mt-1'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='DISQUALIFY2' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.LABEL.DISQUALIFY">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.DISQUALIFY'})}</label>
                    <div className='col-lg-5'>
                        <select id="DISQUALIFY2" className='form-select form-select-sm fs-6 h-30px py-1' onChange={(e) => { tripData && updateScoreAndTimeForTrip(e, 'disqualifyR2')} }>
                            {disqualifyStatusTwoList}
                        </select>
                    </div>
                    <div className='col d-flex justify-content-end align-self-center'> 
                        <button type="button" className='btn btn-sm btn-secondary w-100px py-1 px-1 fw-bold h-30px rect button-no-wrap' id='saveScoreR2' onClick={(e)=>{updateScoreAndTimeForTrip(e, 'saveScoreR2')}} onKeyDown={(e) => {onHunterDerbyR2KeyDown(e)}} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTS/TIMEROUND2.BUTTON.SAVESCORE"><span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.SAVESCORE'})}</button>
                    </div>
                </div>
            </fieldset>
        </>
        }


        { (currentClassGroup?.group_type == "Jumpers") &&
        <>
            <fieldset className='mb-1 mt-1'>
            <legend className="fs-6 fw-bold d-flex justify-content-between"><span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.GROUPLABEL.SCORINGFAULTSTIME'})}</span> </legend>

                <div className='row mb-1'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='TRIP1FAULTS' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPEONE.LABEL.TRIP1FAULTS">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.TRIP1FAULTS'})}</label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <input
                                    id='faults_one'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.faults_one != null ? tripData?.faults_one : ''}
                                    onChange={(e) => { setDataForTrip('faults_one', e.target.value) }}
                                    onKeyDown={onJumperR1KeyDown}
                                    inputMode="numeric"
                                    placeholder='0'
                                    min={0}
                                />
                            </div>
                            <div className='col-lg-2 d-flex justify-content-start align-self-start pe-0'>
                                <button type="button" className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-40px rect button-no-wrap' 
                                    onClick={(e)=>{
                                        updateScoreAndTimeForTrip(e, 'incrementJumpFaultsR1')
                                    }} 
                                    onKeyDown={(e) => { if (e.key === 'Enter') { updateScoreAndTimeForTrip(e, 'incrementJumpFaultsR1') }}}
                                    data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPEONE.BUTTON.PLUSFOUR"
                                >
                                    <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                    {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.+4'})}
                                </button>
                            </div>
                            <div className='col-lg-2 d-flex justify-content-start align-self-start pe-0'>
                                <button type="button" className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-40px rect button-no-wrap' 
                                    onClick={(e)=>{ 
                                        if(toFixedTrunc(tripData?.faults_one, 'number') >= 4){
                                            updateScoreAndTimeForTrip(e, 'decrementJumpFaultsR1')
                                        }
                                    }}
                                    onKeyDown={(e) => { if (e.key === 'Enter' && toFixedTrunc(tripData?.faults_one, 'number') >= 4) { updateScoreAndTimeForTrip(e, 'decrementJumpFaultsR1') }}}
                                    data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPEONE.BUTTON.MINUSFOUR"
                                >
                                    <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                    {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.-4'})}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-1'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='TRIP1TIME' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPEONE.LABEL.TIME1FAULTS">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.TIME1FAULTS'})}</label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <input
                                    id='time_fault_one'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.time_fault_one != null ? tripData?.time_fault_one : ''}
                                    inputMode="numeric"
                                    placeholder='0'
                                    disabled
                                />
                            </div>
                            <div className='col-lg-2 d-flex justify-content-start align-self-start pe-0'>
                                <button tabindex="-1" type="button" className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-40px rect disabled' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPEONE.BUTTON.PLUSONE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.+1'})}</button>
                            </div>
                            <div className='col-lg-2 d-flex justify-content-start align-self-start pe-0'>
                                <button tabindex="-1" type="button" className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-40px rect disabled' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPEONE.BUTTON.MINUSONE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.-1'})}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-1'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='TIME1' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPEONE.LABEL.TIME1">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.TIME1'})}</label>
                    <div className='col'>
                        <div className='row flex justify-content'>
                            <div className='col-lg-4'>
                                <input
                                    id='time_one'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.time_one != null ? tripData?.time_one : ''}
                                    onChange={(e) => { setDataForTrip('time_one', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateTimeFaultsR1') }}
                                    onKeyDown={onJumperR1KeyDown}
                                    placeholder='0.000'
                                    inputMode="numeric"
                                />
                            </div>
                            { farmtekRound == 'R1' && farmtekData?.data ? 
                            <div className='col-lg-3 d-flex justify-content-start align-self-start pe-0'>
                                <label className='col-lg-2 col-form-label fs-6 pe-0 py-1' htmlFor='FARMTEKDATA'>{`${farmtekData?.status}`}</label>
                                <label className='col-lg-1 col-form-label fs-6 pe-0 py-1  mx-3' htmlFor='FARMTEKDATA'>{`${farmtekData?.data}`}</label>
                            </div> 
							: null }
                        </div>
                    </div>
                </div>

                <div className='row mb-1'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='trip_one_status' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPEONE.LABEL.DESCRIPTION">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.DESCRIPTION'})}</label>
                    <div className='col'>
                        <div className='row flex justify-content-between'>
                            <div className='col-lg-8'>
                                <input
                                    id='trip_one_status'
                                    type='text'
                                    value={tripData && tripData?.trip_one_status != null ? tripData?.trip_one_status : ''}
                                    onChange={(e) => { setDataForTrip('trip_one_status', e.target.value) }}
                                    className='form-control fs-6 h-30px py-1'
                                    onKeyDown={onJumperR1KeyDown}
                                />
                            </div>

                            <div className='col-lg-4 d-flex justify-content-end align-self-center'> 
                                <button type="button" className='btn btn-sm btn-secondary w-100px py-1 px-1 fw-bold h-30px rect button-no-wrap' id='saveTimeR1' onClick={(e) => {updateScoreAndTimeForTrip(e, 'saveTimeR1')}} onKeyDown={onJumperR1KeyDown} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPEONE.BUTTON.STORE"><span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.STORE'})}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-1'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='DISQUALIFY1' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPEONE.LABEL.DISQUALIFY">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.DISQUALIFY'})}<span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span></label>
                    <div className='col'>
                        <select id="DISQUALIFY1" className='form-select form-select-sm fs-6 h-30px py-1' onChange={(e) => { updateScoreAndTimeForTrip(e, 'disqualifyR1')}}>
                            {disqualifyStatusOneList}
                        </select>
                    </div>
                </div>
            </fieldset>

            <fieldset className='mb-1'>
            <legend className="fs-6 fw-bold d-flex justify-content-between"><span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.GROUPLABEL.SCORINGFAULTSTIME'})}</span></legend>

                <div className='row mb-1'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='TRIP2FAULTS' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETWO.LABEL.TRIP2FAULTS">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.TRIP2FAULTS'})}</label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <input
                                    id='faults_two'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.faults_two != null ? tripData?.faults_two : ''}
                                    onChange={(e) => { setDataForTrip('faults_two', e.target.value) }}
                                    onKeyDown={onJumperR2KeyDown}
                                    inputMode="numeric"
                                    placeholder='0'
                                />
                            </div>
                            <div className='col-lg-2 d-flex justify-content-start align-self-start pe-0'>
                                <button type="button" className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-40px rect button-no-wrap' onClick={(e) => {updateScoreAndTimeForTrip(e, 'incrementJumpFaultsR2')}}  onKeyDown={(e) => { if (e.key === 'Enter') { updateScoreAndTimeForTrip(e, 'incrementJumpFaultsR2') }}} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETWO.BUTTON.PLUSFOUR"><span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.+4'})}</button>
                            </div>
                            <div className='col-lg-2 d-flex justify-content-start align-self-start pe-0'>
                                <button type="button" className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-40px rect button-no-wrap' 
                                    onClick={(e) => 
                                    {
                                        if(toFixedTrunc(tripData?.faults_two, 'number') >= 4)
                                        {
                                            updateScoreAndTimeForTrip(e, 'decrementJumpFaultsR2')
                                        }
                                    }}
                                    onKeyDown={(e) => { if (e.key === 'Enter' && toFixedTrunc(tripData?.faults_two, 'number') >= 4) { updateScoreAndTimeForTrip(e, 'decrementJumpFaultsR2') }}}
                                    data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETWO.BUTTON.MINUSFOUR"
                                >
                                    <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                    {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.-4'})}
                                </button>
                            </div>
                            <div className='col-lg-4 d-flex justify-content-start align-self-start'>
                                <button type="button" className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-100px rect button-no-wrap' onClick={(e) => {updateScoreAndTimeForTrip(e, 'startR2')}} onKeyDown={(e) => { if (e.key === 'Enter') { updateScoreAndTimeForTrip(e, 'startR2') }}} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETWO.BUTTON.R2START"><span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                    { intl.formatMessage({
                                        id: `FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.${(currentClassGroup?.round_labels?.r2?.abbr) ? currentClassGroup.round_labels.r2.abbr : 'R2'}START`
                                    })}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-1'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='TRIP2TIME' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETWO.LABEL.TIME2FAULTS">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.TIME2FAULTS'})}</label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <input
                                    id='time_fault_two'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.time_fault_two != null ? tripData?.time_fault_two : ''}
                                    inputMode="numeric"
                                    placeholder='0'
                                    disabled
                                />
                            </div>
                            <div className='col-lg-2 d-flex justify-content-start align-self-start pe-0'>
                                <button tabindex="-1" type="button" className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-40px rect disabled' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETWO.BUTTON.PLUSONE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.+1'})}</button>
                            </div>
                            <div className='col-lg-2 d-flex justify-content-start align-self-start pe-0'>
                                <button tabindex="-1" type="button" className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-40px rect disabled' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETWO.BUTTON.MINUSONE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.-1'})}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-1'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='TIME2' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETWO.LABEL.TIME2">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.TIME2'})}</label>
                    <div className='col'>
                        <div className='row flex justify-content'>
                            <div className='col-lg-4'>
                                <input
                                    id='time_two'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData && tripData?.time_two != null ? tripData?.time_two : ''}
                                    onChange={(e) => { setDataForTrip('time_two', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateTimeFaultsR2') }}
                                    onKeyDown={onJumperR2KeyDown}
                                    placeholder='0.000'
                                    inputMode="numeric"
                                />
                            </div>
                            { farmtekRound == 'JO' && farmtekData?.data ?  
                            <div className='col-lg-3 d-flex justify-content-start align-self-start pe-0'>
                                <label className='col-lg-2 col-form-label fs-6 pe-0 py-1' htmlFor='FARMTEKDATA'>{`${farmtekData?.status}`}</label>
                                <label className='col-lg-1 col-form-label fs-6 pe-0 py-1  mx-3' htmlFor='FARMTEKDATA'>{`${farmtekData?.data}`}</label>
                            </div> 
							: null }
                        </div>
                    </div>
                </div>

                <div className='row mb-1'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='trip_two_status' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETWO.LABEL.DESCRIPTION">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.DESCRIPTION'})}</label>
                    <div className='col'>
                        <div className='row flex justify-content-between'>
                            <div className='col-lg-8'>
                                <input
                                    id='trip_two_status'
                                    type='text'
                                    value={tripData && tripData?.trip_two_status != null ? tripData?.trip_two_status : ''}
                                    onChange={(e) => { setDataForTrip('trip_two_status', e.target.value) }}
                                    className='form-control fs-6 h-30px py-1'
                                    onKeyDown={onJumperR2KeyDown}
                                />
                            </div>

                            <div className='col-lg-4 d-flex justify-content-end align-self-center'> 
                                <button type="button" className='btn btn-sm btn-secondary w-100px py-1 px-1 fw-bold h-30px rect button-no-wrap' id='saveTimeR2' onClick={(e) => {updateScoreAndTimeForTrip(e, 'saveTimeR2')}} onKeyDown={onJumperR2KeyDown} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETWO.BUTTON.STORE"><span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.STORE'})}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-1'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='DISQUALIFY2' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETWO.LABEL.DISQUALIFY">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.DISQUALIFY'})}</label>
                    <div className='col'>
                        <select id="DISQUALIFY2" className='form-select form-select-sm fs-6 h-30px py-1' onChange={(e) => { tripData && updateScoreAndTimeForTrip(e, 'disqualifyR2') }}>
                            {disqualifyStatusTwoList}
                        </select>
                    </div>
                </div>
            </fieldset>   

            { /* Either trip is qualified for r3 or for someone there is round 3 data added for trip */  
            (currentTrip && (currentTrip.r3_qualified || currentTrip.time_fault_three > 0 || currentTrip.time_three > 0 || currentTrip.faults_three > 0 ||  currentTrip.disqualify_status_three !== '')) &&
            <fieldset className='mb-1'>
                <legend className="fs-6 fw-bold d-flex justify-content-between"><span>{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.GROUPLABEL.SCORINGFAULTSTIME'})}</span></legend>
                <div className='row mb-1'>
                    <label 
                        className='col-lg-3 col-form-label fs-6 pe-0 py-1' 
                        htmlFor='faults_three'
                        data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETHREE.LABEL.TRIP3FAULTS"
                    >
                        {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.TRIP3FAULTS'})}
                    </label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <input
                                    id='faults_three'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData?.faults_three ?? ''}
                                    onChange={(e) => { setDataForTrip('faults_three', e.target.value) }}
                                    onKeyDown={onJumperR3KeyDown}
                                    inputmode="numeric"
                                    placeholder='0'
                                />
                            </div>
                            <div className='col-lg-2 d-flex justify-content-start align-self-start pe-0'>
                                <button 
                                    type="button" 
                                    className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-40px rect button-no-wrap' 
                                    onClick={(e) => {updateScoreAndTimeForTrip(e, 'incrementJumpFaultsR3')}}  
                                    onKeyDown={(e) => { 
                                        if (e.key === 'Enter') { 
                                            updateScoreAndTimeForTrip(e, 'incrementJumpFaultsR3') 
                                        }
                                    }}
                                    data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETHREE.BUTTON.PLUSFOUR"
                                >
                                    <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                    {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.+4'})}
                                </button>
                            </div>
                            <div className='col-lg-2 d-flex justify-content-start align-self-start pe-0'>
                                <button type="button" className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-40px rect button-no-wrap' 
                                    onClick={(e) => 
                                    {
                                        if(toFixedTrunc(tripData?.faults_three, 'number') >= 4)
                                        {
                                            updateScoreAndTimeForTrip(e, 'decrementJumpFaultsR3')
                                        }
                                    }}
                                    onKeyDown={(e) => { if (e.key === 'Enter' && toFixedTrunc(tripData?.faults_three, 'number') >= 4) { updateScoreAndTimeForTrip(e, 'decrementJumpFaultsR3') }}}
                                    data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETHREE.BUTTON.MINUSFOUR"
                                >
                                    <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                    {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.-4'})}
                                </button>
                            </div>
                            <div className='col-lg-4 d-flex justify-content-start align-self-start'>
                                <button 
                                    type="button" 
                                    className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-100px rect button-no-wrap' onClick={(e) => {
                                        updateScoreAndTimeForTrip(e, 'startR3')}
                                    } 
                                    onKeyDown={(e) => { 
                                        if (e.key === 'Enter') { 
                                            updateScoreAndTimeForTrip(e, 'startR3') 
                                        }
                                    }}
                                    data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETHREE.BUTTON.R3START"
                                >
                                    <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                    { intl.formatMessage({
                                        id: `FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.${(currentClassGroup?.round_labels?.r3?.abbr) ? currentClassGroup.round_labels.r3.abbr : 'R3'}START`
                                    })}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-1'>
                    <label 
                        className='col-lg-3 col-form-label fs-6 pe-0 py-1' 
                        htmlFor='time_fault_three'
                        data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETHREE.LABEL.TIME3FAULTS">
                        {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.TIME3FAULTS'})}
                    </label>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <input
                                    id='time_fault_three'
                                    type='text'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData?.time_fault_three ?? ''}
                                    inputmode="numeric"
                                    placeholder='0'
                                    disabled
                                />
                            </div>
                            <div className='col-lg-2 d-flex justify-content-start align-self-start pe-0'>
                                <button tabindex="-1" type="button" className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-40px rect disabled' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETHREE.BUTTON.PLUSONE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.+1'})}</button>
                            </div>
                            <div className='col-lg-2 d-flex justify-content-start align-self-start pe-0'>
                                <button tabindex="-1" type="button" className='btn btn-sm btn-secondary py-1 px-1 fw-bold h-30px w-40px rect disabled' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETHREE.BUTTON.MINUSONE">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.-1'})}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-1'>
                    <label 
                        className='col-lg-3 col-form-label fs-6 pe-0 py-1' 
                        htmlFor='time_three'
                        data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETHREE.LABEL.TIME3"
                    >
                        {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.TIME3'})}
                    </label>
                    <div className='col'>
                        <div className='row flex justify-content'>
                            <div className='col-lg-4'>
                                <input
                                    id='time_three'
                                    type='number'
                                    className='form-control fs-6 h-30px py-1 px-1 number-input'
                                    value = {tripData?.time_three ?? ''}
                                    onChange={(e) => { setDataForTrip('time_three', e.target.value) }}
                                    onBlur={(e) => { updateScoreAndTimeForTrip(e, 'updateTimeFaultsR3') }}
                                    onKeyDown={onJumperR3KeyDown}
                                    placeholder='0.000'
                                    inputmode="numeric"
                                />
                            </div>
                            { farmtekRound == 'JO' && farmtekData?.data ?  
                            <div className='col-lg-3 d-flex justify-content-start align-self-start pe-0'>
                                <label className='col-lg-2 col-form-label fs-6 pe-0 py-1' htmlFor='FARMTEKDATA'>{`${farmtekData?.status}`}</label>
                                <label className='col-lg-1 col-form-label fs-6 pe-0 py-1  mx-3' htmlFor='FARMTEKDATA'>{`${farmtekData?.data}`}</label>
                            </div> 
							: null }
                        </div>
                    </div>
                </div>

                <div className='row mb-1'>
                    <label  
                        className='col-lg-3 col-form-label fs-6 pe-0 py-1' 
                        htmlFor='trip_three_status'
                        data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETHREE.LABEL.DESCRIPTION"
                    >
                        {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.DESCRIPTION'})}
                    </label>
                    <div className='col'>
                        <div className='row flex justify-content-between'>
                            <div className='col-lg-8'>
                                <input
                                    id='trip_three_status'
                                    type='text'
                                    value={tripData?.trip_three_status  ?? ''}
                                    onChange={(e) => { setDataForTrip('trip_three_status', e.target.value) }}
                                    className='form-control fs-6 h-30px py-1'
                                    onKeyDown={onJumperR3KeyDown}
                                />
                            </div>

                            <div className='col-lg-4 d-flex justify-content-end align-self-center'> 
                                <button 
                                    type="button" 
                                    className='btn btn-sm btn-secondary w-100px py-1 px-1 fw-bold h-30px rect button-no-wrap' id='saveTimeR3' 
                                    onClick={(e) => {updateScoreAndTimeForTrip(e, 'saveTimeR3')}} 
                                    onKeyDown={onJumperR3KeyDown}
                                    data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETHREE.BUTTON.STORE"
                                >
                                    <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                    {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.BUTTON.STORE'})}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row mb-1'>
                    <label className='col-lg-3 col-form-label fs-6 pe-0 py-1' htmlFor='DISQUALIFY3' data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.SECTION.SCORINGFAULTSTYPETHREE.LABEL.DISQUALIFY">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.SCORINGMODE.LABEL.DISQUALIFY'})}</label>
                    <div className='col'>
                        <select id="DISQUALIFY3" className='form-select form-select-sm fs-6 h-30px py-1' onChange={(e) => { tripData && updateScoreAndTimeForTrip(e, 'disqualifyR3') }}>
                            {disqualifyStatusThreeList}
                        </select>
                    </div>
                </div>
            </fieldset>    
            }      
        </>
        }
        {warningForTripsSetOfToQuickly && 
            <label className='warning-trips-set-of-quickly'> {intl.formatMessage({id: 'ANNOUNCER.INGATEANNOUNCER.WARNING.TRIPS.CHECKED.OF.TO.QUICKLY'})}</label>
        }
    </>
  );
};

export default ScoringMode;
  