import { useIntl } from 'react-intl'
import { ShowSection } from '../ShowSection';
import MoveCreditToAccountGrid from './MoveCreditToAccountGrid';
import { useEffect, useState } from 'react';
import { usePaymentBatchContext } from '../../PaymentBatchContext';
import axios from 'axios';
import { useAppSelector } from '../../../../redux/hooks';
import { useAlert, useFlashAlert, useProgress } from '../../../../modules/sgl-utils/DialogsProvider';
import { useOutputContext } from '../../../../modules/output-listing/OutputListingContext';
import { getRowData } from '../../../../modules/sgl-utils/agGridHelpers';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const MoveCreditToAccountTab = () => {
    const intl = useIntl();
    const { closePaymentBatchDetail, selectedShow, setSelectedTab, setUpdateCreateBatchTabData, refreshPaymentBatch, setUpdateCheckoutEntriesTabData }= usePaymentBatchContext()
    const {progressBar} = useProgress()
    const { getEventID } = useOutputContext()
    const customerId = useAppSelector(state => state.showCompany.company_id)
    const [applyPrizeMoney, setApplyPrizeMoney] = useState(true)
    const [includeMissingSSNPrizeMoney, setIncludeMissingSSNPrizeMoney] = useState(false)
    const [moveCreditToAccountsGridRef, setMoveCreditToAccountsGridRef] = useState(null)
    const [moveCreditToAccountDataUpdated, setMoveCreditToAccountDataUpdated] = useState(false)
    const [feiClassesOnly, setFeiClassesOnly] = useState(false)
    const [selectAllEntries, setSelectAllEntries] = useState(false)
    const [notAwardedEntries, setNotAwardedEntries] = useState('')
    const [summary, setSummary] = useState({})
    const [creditType, setCreditType] = useState('Prize Money')
    const alertDialog = useAlert()
    const flashAlert = useFlashAlert()
    const currencyFormatter = useCurrencyFormatter()

    const getMetadata = async () => {
        try {
            moveCreditToAccountsGridRef.showLoadingOverlay()
            const response = await axios.get(`${NEST_API_URL}/payment-batches/getTransferableCreditEntries`, {
                params: { 
                    customer_id: customerId,
                    show_id: selectedShow,
                    apply_prize_money: true, //applyPrizeMoney
                    include_missing_ssn_prize_money: true, //includeMissingSSNPrizeMoney
                    credit_type: creditType,
                    feiClassesOnly: feiClassesOnly
                }
            })

            if (!response.data.success) {
                alertDialog({ message: response.data.error })
            } else {
                response.data.processedEntries.forEach(entry => entry.include = entry.transferCredit === 0 ? false : selectAllEntries)
                moveCreditToAccountsGridRef.setRowData(response.data.processedEntries)
                setMoveCreditToAccountDataUpdated(!moveCreditToAccountDataUpdated)
                let _notAwardedEntries = ''
                if (response.data.unAppliedPrizeMoneyEntries > 0) {
                    _notAwardedEntries = `${response.data.unAppliedPrizeMoneyEntries} ${response.data.unAppliedPrizeMoneyEntries > 1 ? 'entries' : 'entry'} with unapplied prize money.`
                } 

                if (response.data.prizeMoneyCannotBeApplied > 0) {
                    _notAwardedEntries += ` Prize Money cannot be applied from ${response.data.prizeMoneyCannotBeApplied} ${response.data.prizeMoneyCannotBeApplied > 1 ? 'entries' : 'entry'}.`
                }
                setNotAwardedEntries(_notAwardedEntries)
            }
        } catch (reason) {
            alertDialog({ message: reason?.response?.data?.message ?? 'Something went wrong..' })
        } finally {
            moveCreditToAccountsGridRef.hideOverlay()
        }
    }

    useEffect(() => {
        if (selectedShow && moveCreditToAccountsGridRef) {
            getMetadata()
        }
    }, [moveCreditToAccountsGridRef, selectedShow, applyPrizeMoney, includeMissingSSNPrizeMoney, refreshPaymentBatch, creditType, feiClassesOnly])

    useEffect(() => {
        if(creditType != 'Prize Money'){
            setFeiClassesOnly(false)
        }
    }, [creditType])

    const moveCreditToAccount = async () => {
        const eventID = getEventID('move-credit-to-account')
        const rowData = getRowData(moveCreditToAccountsGridRef)
        const entryIds = rowData.filter(row => row.include).map(row => row.entryId)

        try {
            progressBar({ show: true, eventID, timeElapsed: true, title: 'Move Credit To Account' })

            const response = await axios.post(`${NEST_API_URL}/payment-batches/moveCreditToAccount`, {
                customer_id: customerId,
                entry_ids: entryIds,
                event_id: eventID,
                include_missing_ssn_prize_money: true, //includeMissingSSNPrizeMoney
                credit_type: creditType,
                feiClassesOnly: feiClassesOnly
            })

            if (response.data.errors.length > 0) {
                flashAlert({ type: 'info', message: `Operation didn't succeed for some entries.` });
            }
            await getMetadata()
            setUpdateCreateBatchTabData(prevUpdateCreateBatchTabData => !prevUpdateCreateBatchTabData)
            setUpdateCheckoutEntriesTabData(updateCheckoutEntriesTabData => !updateCheckoutEntriesTabData)
            setSelectedTab(1)
        } catch(reason) {

        } finally {
            progressBar({ show: false, eventID })
        }
    }

    const numberOfEntries = moveCreditToAccountsGridRef ? getRowData(moveCreditToAccountsGridRef).length : 0
    return (
        <>   
            <div className='row'>
                <div className='col-lg-9'>   
                    <ShowSection tabName={'MOVECREDITTOACCOUNT'} />
                    {/* Checkbox */}
                    {/* <div className='row col-8 mw-800px'>
                        <div className='col-lg-1 label-width-130 pe-0'></div>                           
                        <div className='col form-check-sm form-check-custom px-3'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='applyPrizeMoney'
                                checked={applyPrizeMoney}
                                onChange={e => setApplyPrizeMoney(e.target.checked)}
                            />
                            <label className='col-form-label mx-2 fs-5 py-1' htmlFor='applyPrizeMoney' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.MOVECREDITTOACCOUNT.LABEL.APPLYALLUNAPPLIEDPRIZEMONEY">
                                {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TOACCOUNT.TAB.APPLYPRIZEMONEY.LAB' })}
                            </label>
                        </div>
                        <div className='col form-check-sm form-check-custom px-3'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                id='includeMissingSSNPrizeMoney'
                                checked={includeMissingSSNPrizeMoney}
                                onChange={e => setIncludeMissingSSNPrizeMoney(e.target.checked)}
                            />
                            <label className='col-form-label mx-2 fs-5 py-1' htmlFor='includeMissingSSNPrizeMoney' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.MOVECREDITTOACCOUNT.LABEL.INCLUDEACCOUNTSWITHMISSINGSSNTIN">
                                {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TOACCOUNT.TAB.INCLUDEMISSINGSSN.LAB' })}
                            </label>
                        </div>
                    </div> */}
                    {/* Checkbox */}
                </div>

                <div className='col-lg-3'>
                    <div className='row'>
                        {/* RTO Summary Separator */}
                        <label className='fs-7 rto-summary separator-heading mt-1'>
                            {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TO.ACCOUNT.SUMMARY.TITLE' })}
                        </label>
                    </div>

                    {/* UNAPPLIED PRIZE MONEY */}
                    <div className='row align-items-center justify-content-end'>
                        <div className='col-6'>
                            <label className='fs-7'>
                                {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TO.ACCOUNT.SUMMARY.LABEL.PRIZE.MONEY' })}
                            </label>
                        </div>
                        
                        <div className='col-3'>
                            <span className='fs-7 text-end'>
                                {summary?.unappliedPrizeMoney?.count ?? 0}
                            </span>
                        </div>

                        <div className='col-3'>
                            <span className='fs-7 d-flex justify-content-end'>
                                {`${currencyFormatter(summary?.unappliedPrizeMoney?.amount ?? 0)}`}
                            </span>
                        </div>
                    </div>

                    {/* BALANCE REFUND */}
                    <div className='row align-items-center justify-content-end'>
                        <div className='col-6'>
                            <label className='fs-7'>
                                {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TO.ACCOUNT.SUMMARY.LABEL.BALANCE.REFUND' })}
                            </label>
                        </div>
                        
                        <div className='col-3'>
                            <span className='fs-7 text-end'>
                                {summary?.balance?.count ?? 0}
                            </span>
                        </div>

                        <div className='col-3'>
                            <span className='fs-7 d-flex justify-content-end'>
                                {`${currencyFormatter(summary?.balance?.amount ?? 0)}`}
                            </span>
                        </div>
                    </div>

                    {/* TOTAL */}
                    <div className='row align-items-center justify-content-end'>
                        <div className='col-6'>
                            <label className='fs-7'>
                                {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TO.ACCOUNT.SUMMARY.LABEL.TOTAL' })}
                            </label>
                        </div>
                        
                        <div className='col-3'>
                            <span className='fs-7 text-end'>
                                {summary?.total?.count ?? 0}
                            </span>
                        </div>

                        <div className='col-3'>
                            <span className='fs-7 d-flex justify-content-end'>
                                {`${currencyFormatter(summary?.total?.amount ?? 0)}`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* FEI Classes Checkbox */}
            <div className='col-lg-9 form-check-sm form-check-custom px-0'>
            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 label-width-130 pe-0'> </label>
                <input
                    className='form-check-input'
                    type='checkbox'
                    id='feiClasses'
                    checked={feiClassesOnly}
                    onChange={e => {
                        setCreditType('Prize Money')
                        setFeiClassesOnly(e.target.checked)
                    }}
                />
                <label htmlFor='feiClasses' className='col-lg-1 col-form-label fw-bold fs-5 py-1 label-width-130 pe-0 ms-2'> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.LABEL.FEI.CLASSES' })}</label>
            </div>

            {/* Credit Type Radio Button */}
            <div className='row mb-2 col-lg-12'>
                <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 label-width-130 pe-0'>{intl.formatMessage({ id: `FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TO.ACCOUNT.CHECKBOX.LABEL.CREDITTYPE` })}</label>
                <div className='col-lg-3 form-check-sm form-check-custom px-3 label-width-180'>
                    <input
                        className='form-check-input'
                        type='radio'
                        id='prizeMoneyOnly'
                        checked={creditType === 'Prize Money'}
                        onChange={(e) => {
                            setCreditType('Prize Money')
                        }}
                    />
                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='prizeMoneyOnly'> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TO.ACCOUNT.CHECKBOX.LABEL.PRIZEMONEY' })}</label>
                </div> 
                <div className='col-lg-3 form-check-sm form-check-custom px-3 label-width-180'>
                    <input
                        className='form-check-input'
                        type='radio'
                        id='transferCreditBalancesOnly'
                        checked={creditType === 'Balance Refund'}
                        onChange={(e) => {
                            setCreditType('Balance Refund')
                        }}
                    />
                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='transferCreditBalancesOnly'> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TO.ACCOUNT.CHECKBOX.LABEL.BALANCEREFUND' })}</label>
                </div> 
                <div className='col-lg-3 form-check-sm form-check-custom px-3 mw-100px'>
                    <input
                        className='form-check-input'
                        type='radio'
                        id='both'
                        checked={creditType === 'Both'}
                        onChange={(e) => {
                            setCreditType('Both')
                        }}
                    />
                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='both'> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TO.ACCOUNT.CHECKBOX.LABEL.BOTH' })}</label>
                </div>                   
            </div>
            {/* Credit Type Radio Button */}

            {/* Error Message */}
            {/* <div className='row col'>
                <div className='col-lg-1 label-width-130 pe-0'></div>                           
                <div className='col'>
                    <label className='col-form-label fs-5 py-1 text-danger' htmlFor='ApplyPrizeMoney'>{ notAwardedEntries }</label>
                </div>
                <label className='col-form-label fs-5 py-1 me-1 col-lg-1 text-end'>
                    {  numberOfEntries  } { numberOfEntries > 0 && ( numberOfEntries === 1 ? 'entry' : 'entries')}
                </label>
            </div> */}
            {/* Error Message */}

            {/* Grid */}
            <MoveCreditToAccountGrid 
                summary={summary}
                setSummary={setSummary}
                selectAllEntries={selectAllEntries}
                setSelectAllEntries={setSelectAllEntries}
                moveCreditToAccountsGridRef={moveCreditToAccountsGridRef}
                setMoveCreditToAccountsGridRef={setMoveCreditToAccountsGridRef} 
                moveCreditToAccountDataUpdated={moveCreditToAccountDataUpdated}
                setMoveCreditToAccountDataUpdated={() => setMoveCreditToAccountDataUpdated(!moveCreditToAccountDataUpdated)}
                creditType={creditType}
            />
            {/* Grid */}

            {/* Footer */}
            <div className='card-footer d-flex justify-content-end py-3 px-0'>
                {/* Info Legend */}
                <div className='col form-check-sm form-check-custom'>
                    <div className="square-shape-payment-batch"></div>
                    <label className='col-form-label mx-2 fs-5 py-1'> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TO.ACCOUNT.SNNFEDERALIDMISSING.LAB' })}</label>
                </div>

                <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={closePaymentBatchDetail} data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.MOVECREDITTOACCOUNT.BUTTON.CLOSE">
                    {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TOACCOUNT.TAB.BUTTON.CLOSE' })}
                </button>

                <button 
                    type="button" 
                    className='btn btn-sm btn-secondary fw-bold text-uppercase' 
                    autoFocus
                    disabled={summary?.total === 0}
                    onClick={e => moveCreditToAccount(e)}
                    data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.MOVECREDITTOACCOUNT.BUTTON.MOVECREDITTOACCOUNT"
                >
                    <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                    {intl.formatMessage({ id: "FORM.INPUT.ENTRIES.TAB.ACCOUNT.BUTTON.MOVECREDITTOACCOUNT" })}
                </button>
            </div>
            {/* Footer */}
        </>
    );
}

export { MoveCreditToAccountTab }