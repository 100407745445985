import { useMemo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import axios from 'axios';
import { useAppSelector } from '../../../../redux/hooks';

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'
import { useOutputContext } from '../../../../modules/output-listing/OutputListingContext';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../../modules/sgl-utils/DialogsProvider';

import { downloadTextFile, loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from "../../../../modules/sgl-utils/SglFunctions";
import { useLoadReportAndPrint } from "../../../reports/ReportTypeModal";
import useAccessChecker from "../../../../modules/hooks/use-access-checker";

const ShowsGrid = ({ rowData, showId, onClose, setDisplayErrors }) => {
    const intl = useIntl()
    const {hasSpecialPermissionToAccess} = useAccessChecker()
    const { getEventID } = useOutputContext();
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const [haveErrors, setHaveErrors] = useState (false)
    const {progressBar} = useProgress()
    const confirmDialog = useConfirm()
    
    const [gridRef, setGridRef] = useState();
    const [errorGridRef, setErrorGridRef] = useState();
    const [selectedCount, setSelectedCount] = useState(0)
    const [isDisabled, setIsDisabled] = useState ((false))
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const [displayErrorGrid, setDisplayErrorGrid] = useState (false)
    const [errorsRowData, setErrorsRowData] = useState ([])
    const [uploadResponse, setUploadResponse] = useState ('')
    const [uploadstatus, setUploadstatus] = useState (true)    
    const {loadReportsAndPrint, loadSinglePageReportsandPrint} = useLoadReportAndPrint();
    const [errorReportDetails, setErrorReportDetails] = useState(null)    

    const renderCheckBox = (params) => {
        return (
            <input
                type="checkbox"
                onChange={
                    (e) => {
                        params.node.setDataValue(params.column.colId, e.target.checked);
                        handleCount()
                    }

                }
                defaultChecked={params.value}
            />
        )
    }

    useEffect(()=>{
        priorityColor(rowData)
    }, [])

    useEffect(()=>{
        handleCount()  
    }, [])

    function handleCount() {

        // check how manyrows were selected
        const selectCount = rowData.reduce((acc, row) => row.selected ? acc + 1 : acc, 0);
        const redCount = rowData.reduce((acc, row) => (row.color == 'red') ? acc + 1 : acc, 0);
        setSelectedCount(selectCount)

        // check how many rows were red, and selected 
        const disabledCount = rowData.reduce((acc, row) => (row.selected && row.color == 'red') ? acc + 1 : acc, 0);
        if (disabledCount  > 0 || redCount == rowData.length){
            setIsDisabled (true)
            setHaveErrors(true)
        } else {
            setIsDisabled (false)
            setHaveErrors(false)
        }
        if(selectCount == 0){
            setIsDisabled (true)
        }
    }
    
    const rowClassRules = useMemo(() => {
        return {
            'bg-expired': 'data.expired',
            'bg-valid': 'data.valid',
            'bg-none': 'data.none',
        };
    }, []);

    async function exportResults(){
        let selected_rows = rowData.filter(row => row.selected)
        if(selected_rows.length > 0){
            let class_ids = selected_rows.map(row => row.class_id)            
            const eventID = getEventID("export-show-EC-Results")
            progressBar({ show: true, eventID, showProgress: 'inline', title: "Preparing Data for Export", timeElapsed: true })
            
            try {
                const response = await axios.post(process.env.REACT_APP_NEST_API_URL + `/shows/exportECShowResults`, {
                    customer_id: customerId,
                    show_id: showId,
                    class_ids: class_ids,
                    event_id: eventID
                })
    
                progressBar({ show: false, eventID })
                if (response.data.success) {
                    if (response.data.error_list && response.data.error_list.length > 0) {
                        setErrorsRowData(response.data.error_list)
                        setDisplayErrorGrid(true)
                        setDisplayErrors(true)
                        gridRef?.setRowData(rowData)
                        errorGridRef?.setRowData(response.data.error_list)
                        setErrorReportDetails(response?.data?.error_report)
                    }
                    downloadTextFile(response.data.text_data, response.data.doc_name)
                }
            } catch (e) {
                console.log(e)
                progressBar({ show: false, eventID })
            }
        }
        
    }

    async function uploadResults(){
        let selected_rows = rowData.filter(row => row.selected)
        if(selected_rows.length > 0){
            let choice = await confirmDialog({message: intl.formatMessage({ id: 'SHOWS.EXPORTECRESULTS.MODAL.UPLOADCONFIRM'})})
            if(choice){
                
                let class_ids = selected_rows.map(row => row.class_id)
                const eventId = getEventID("export-show-EC-Results")
                progressBar({ show: true, eventID: eventId, showProgress: 'inline', title: "Preparing Data for Export", timeElapsed: true })
                                
                axios.post( process.env.REACT_APP_NEST_API_URL + `/shows/uploadECShowResults`, {
                    customer_id: customerId,
                    show_id: showId,
                    class_ids: class_ids,
                    event_id: eventId
                })
                .then(async response => {

                    progressBar({ show: false, eventID: eventId })
                    if(response.data.success){
                        setUploadResponse(response.data.message)
                        setUploadstatus(response.data.uploaded)
                    }
                }
                )
                .catch(reason => {
                    if (reason.response.data.error) {
                        // alertDialog({ message: reason.response.data.error })
                    }

                    progressBar({ show: false, eventID: eventId })
                })
            }
        }
        
    }
    
    function exportErrors(){
        let separater = '\t'
        if(errorsRowData.length>0){
            let headers ="No"+separater+"Class"+separater+"Entry"+separater+"Place"+separater+"Horse"+separater+"Rider"+separater+"Owner"+separater+"Type"+separater+"Errors\n"
            // const tabSeparatedData = errorsRowData.map(item => `${item.name}\t${item.age}\t${item.country}`).join('\n');
            var tabSeparatedData = errorsRowData.map((item, index) => 
                `${index + 1}\t${item.class_number}\t${item.entry_number}\t${item.place}\t${item.horse_name}\t${item.rider_name}\t${item.owner_name}\t${item.error_type}\t${item.errors}`).join('\n');
       
            let date = Date()
            let doc_name = 'EC_Results_Export_Errors'+getCurrentDateTimeFormatted()+'.txt'
            downloadTextFile(headers+tabSeparatedData, doc_name)
        }
    }

    // Print errors, this will call Active report js to create an EC Results Export Errors report
    const printExportErrors = async (e) => {
        try{
            loadingSpinnerBtnWait(e);
            if (errorReportDetails) {
                let report = { ...errorReportDetails };
                report.report_definition = JSON.parse(report.report_definition);
                await loadReportsAndPrint(report)
            } 
            loadingSpinnerBtnRelease(e);
        } catch (error) {
            // console.log(error)
            loadingSpinnerBtnRelease(e);
        }
    }

    const getCurrentDateTimeFormatted = () => {
        const now = new Date();
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
    
        const formattedDateTime = `${day}-${month}-${year}-${hours}-${minutes}-${seconds}`;
        return formattedDateTime;
    };
       
    const priorityColor = (row_data) => {

        // setHaveErrors (false)
        let rowData = row_data?.map((data) => {
            // initializing color schemes
            data.none = false;
            data.expired = false;
            data.valid = false;
            switch(data.color){
                case 'white':
                    data.none = true;
                    break;
                case 'red':
                    data.expired = true;
                    // setHaveErrors (true)
                    break;
                case 'green':
                    data.valid = true;
                    break;
            }

            return data
        })
        gridRef?.setRowData(rowData)
    }

    const getColumnDefs = () => {
        
        if (displayErrorGrid) {
            return [
                { field: 'class_number', cellStyle: {textAlign: 'center'}, width: 80, sortable: true, headerName: intl.formatMessage({ id: 'SHOWS.EXPORTECRESULTS.MODAL.GRID.CLASS' })},                
                { field: 'entry_number', cellStyle: {textAlign: 'center'}, width: 80, sortable: true, headerName: intl.formatMessage({ id: 'SHOWS.EXPORTECRESULTS.MODAL.GRID.ENTRY' })},
                { field: 'place', cellStyle: {textAlign: 'center'}, width: 80, sortable: true, headerName: intl.formatMessage({ id: 'SHOWS.EXPORTECRESULTS.MODAL.GRID.PLACE' })},
                { field: 'horse_name',  cellStyle: {textAlign: 'left'}, width: 200, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTECRESULTS.MODAL.GRID.HORSE" })},
                { field: 'rider_name', cellStyle: {textAlign: 'left'}, width: 200, sortable: true, headerName: intl.formatMessage({ id: 'SHOWS.EXPORTECRESULTS.MODAL.GRID.RIDER' })},
                { field: 'owner_name', cellStyle: {textAlign: 'left'}, width: 200, sortable: true, headerName: intl.formatMessage({ id: 'SHOWS.EXPORTECRESULTS.MODAL.GRID.OWNER' })},
                { field: 'error_type', cellStyle: {textAlign: 'left'}, width: 150, sortable: true, headerName: intl.formatMessage({ id: 'SHOWS.EXPORTECRESULTS.MODAL.GRID.TYPE' })},
                { field: 'errors', cellStyle: {textAlign: 'left'}, width: 1000, sortable: true, headerName: intl.formatMessage({ id: 'SHOWS.EXPORTECRESULTS.MODAL.GRID.ERRORS' })}
            ]
        } else {
            return [
                // In your column definitions, update the 'selected' field:
                {
                    field: 'selected',
                    headerName: "-", 
                    cellStyle: {textAlign: 'center'},
                    suppressSizeToFit: true,
                    maxWidth: 50,
                    cellRenderer: renderCheckBox,
                    headerComponentFramework: CustomHeaderCheckbox,
                    headerComponentParams: {
                        context: {
                            parentComponent: {
                                handleCount: handleCount                                                              
                            }
                        }
                    }
                },
                { field: 'ec_section_code', cellStyle: {textAlign: 'center'},  width:150, sortable: true, headerName: intl.formatMessage({ id: 'SHOWS.FEESPLIT.MODAL.GRID.ECSECTION' })},
                { field: 'number', cellStyle: {textAlign: 'center'}, width:100, sortable: true, headerName: intl.formatMessage({ id: 'SHOWS.FEESPLIT.MODAL.GRID.NUMBER' })},
                { field: 'name',  cellStyle: {textAlign: 'left'}, width: 400, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.FEESPLIT.MODAL.GRID.NAME" }) },
                { field: 'export_status', cellStyle: {textAlign: 'left'}, width: 400, sortable: true, headerName: intl.formatMessage({ id: 'SHOWS.FEESPLIT.MODAL.GRID.STATUS' })},
            ]
        }
        
      };
      // Custom Header Checkbox Component. We cannot use AG grid default check All feature because we need to skip rows in Red color during Select All. So need custom Select All.      
      const CustomHeaderCheckbox = (props) => {
          const [checked, setChecked] = useState(true);
          
          // Function to handle the select/deselect all action
          const handleSelectAll = (e) => {
              const isChecked = e.target.checked;
              setChecked(isChecked);
              
              // Get access to the grid API through props
              const gridApi = props.api;              
              // Loop through all rows
              gridApi.forEachNode(node => {
                  // Only select rows that are not red when checking
                  if (isChecked) {
                      // Only set selected=true for rows that don't have color='red'
                      if (node.data.color !== 'red') {                                                      
                        node.data.selected = true
                    }
                  } else {
                      // Unselect all rows when unchecking                      
                      node.data.selected = false
                    }                                
            // Force refresh of the specific row to update the UI
            gridApi.refreshCells({
                rowNodes: [node],
                columns: ['selected'],
                force: true
            });                        
              });
              
              // Call handleCount to update the selected count and disabled state
              setTimeout(() => {
                  // This timeout allows the grid to update before counting
                  gridApi.redrawRows();
                  props.context.parentComponent.handleCount();
              }, 0);
          };
          
          return (
              <div className="d-flex justify-content-center align-items-center h-100">
                  <input 
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={checked}
                  />
              </div>
          );
      };

    return (
        <div>

            {(displayErrorGrid)?
                <div style={containerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                        
                        <OutputListingGridStatic
                            // area ={"Shows - Export EC Results Errors"}
                            key='error-grid'
                            columnDefs={getColumnDefs()}
                            rowData={errorsRowData}
                            // setGridRef={setErrorGridRef}
                            containerStyle={containerStyle}
                            // rowClassRules={rowClassRules}
                        ></OutputListingGridStatic>
                    </div>
                </div>
            :

                <div style={containerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                        
                        <OutputListingGridStatic
                            // area ={"Shows - Export EC Results"}
                            key='export-grid'
                            columnDefs={getColumnDefs()}
                            rowData={rowData}
                            setGridRef={setGridRef}
                            containerStyle={containerStyle}
                            rowClassRules={rowClassRules}                                                        
                        ></OutputListingGridStatic>
                    </div>
                </div>
            }
          
            {(!displayErrorGrid) &&
            <>  
                <div className="row mb-2">
                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-275px text-success'>
                    {intl.formatMessage({ id: 'SHOWS.QUICKACTION.GRID.TOTALCLASSESMESSAGE' })}{ rowData.length }{intl.formatMessage({ id: 'SHOWS.QUICKACTION.GRID.SELECTED' })} {selectedCount}
                    </label>
                </div>

                { haveErrors &&   
                    <label className="col-lg-1 col-form-label fs-5 py-1 w-lg-275px text-danger">
                        { haveErrors && intl.formatMessage({ id: "SHOWS.QUICKACTION.GRID.ERRORMESSAGE" }) }
                    </label>
                }
            </>
            }

            {(!displayErrorGrid)?

                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                    <button type="button" onClick={(e) => { onClose(); e.preventDefault()}} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' >
                        {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.MODAL.BUTTON.CLOSE' })}
                    </button>
                    <button type="button" disabled={isDisabled} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={exportResults} data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTECRESULTS.BUTTON.EXPORTECRESULTS">
                        {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.MODAL.BUTTON.EXPORTECRESULTS' })}
                    </button>
                    {(hasSpecialPermissionToAccess('cloud_shows:ec_submit-show-results')) &&( 
                    <button type="button" disabled={isDisabled} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={uploadResults} data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTECRESULTS.BUTTON.UPLOADEVENTRESULTS">
                        {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.MODAL.BUTTON.UPLOADEVENTRESULTS' })}
                    </button>)}
                    
                </div>
            :
                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                    <button type="button" onClick={(e) => { onClose(); e.preventDefault()}} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' >
                        {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.MODAL.BUTTON.CLOSE' })}
                    </button>
                    <button type="button" disabled={isDisabled} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={(e) => printExportErrors(e)} data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTECRESULTS.BUTTON.PRINTECRESULTS">
                        <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                        {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.MODAL.BUTTON.PRINTECERRORS' })}
                    </button>
                    <button type="button" disabled={isDisabled} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={exportErrors} data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTECRESULTS.BUTTON.EXPORTECERRORS">
                        {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.MODAL.BUTTON.EXPORTECERRORS' })}
                    </button>
                    
                </div>
            }

            {(!displayErrorGrid && uploadResponse != '') &&
                <div>
                    <label className="col-lg-2 col-form-label fs-5 py-1 w-lg-200px fw-bold">
                        { intl.formatMessage({ id: "SHOWS.QUICKACTION.MODAL.RESPONSEMESSAGE" })}
                    </label>
                    <label className={"col-form-label fs-5 py-1 fw-bold"+(uploadstatus?' text-success':' text-danger')}>
                        { uploadResponse}
                    </label>
                </div>
            }

        </div>
       
    )
}


export default ShowsGrid