import { createContext, useCallback, useContext, useEffect, useRef, useState} from "react"
import { AlertDialog } from "../modals/AlertDialog";
import { ConfirmDialog } from "../modals/ConfirmDialog";
import { ProgressBar } from "../modals/ProgressBar";
import { LoadingOverlay } from "../modals/LoadingOverlay";
import { PersistentLoadingOverlay } from "../modals/PersistentLoadingOverlay";
import { DateRangeDialog } from "../modals/DateRangeDialog";
import { DatePickerDialog } from "../modals/DatePickerDialog";
import TaskScheduler from "../modals/TaskScheduler";
import FlashAlert from "../modals/FlashAlert";
import { FeeSelectionModal } from "../../pages/entries/Detail/Classes/FeeSelectionModal";
import { SupplyDeliveryOptionsModal } from "../modals/SupplyDeliveryOptions";
import { UpdateEntryNumberForm } from "../../pages/entries/UpdateEntrNumberForm";
import { FormProvider, useForm } from "react-hook-form";
import { DeleteConfirmDialog } from "../modals/DeleteConfirmDialog";
  
//ALERT DIALOG
const AlertDialogContext = createContext()
export function AlertDialogProvider({ children }) {
  const fn = useRef()
  const [states, setStates] = useState([])

  const alertDialog = (data) => { 
    return new Promise((resolve) => {
      setStates(prevStates => { 
          const newStates = [...prevStates]
          newStates.push({ ...data, show: true })
          return newStates
      })
      fn.current = (choice) => { 
        resolve(choice) 
      }
    })
  };   

  return (
    <AlertDialogContext.Provider value={alertDialog}>
      {children}
      { 
            states.map((state, index) => <AlertDialog key={index} {...state} handleClose={() => {
                  setStates(
                      prevStates => {
                          const newStates = [...prevStates]
                          newStates.splice(index, 1)
                          return newStates
                      }
                  )

                  fn.current(true)
                }}
            />) 
        }
    </AlertDialogContext.Provider>
  )
}
  
export function useAlert() {
  return useContext(AlertDialogContext)
}

//CONFIRM DIALOG
const ConfirmDialogContext = createContext()

export function ConfirmDialogProvider({ children }) {
  const [state, setState] = useState({ show: false })
  const fn = useRef()
  const confirmDialog = (data) => { 
    return new Promise((resolve) => {
      setState((prev) => ({...data, show: !prev.show}))
      fn.current = (choice) => { 
        resolve(choice) 
      }
    })
  }

  return (
    <ConfirmDialogContext.Provider value={confirmDialog}>
      {children} 
      <ConfirmDialog
        {...state}
        onClose={() => fn.current(false)}   
        onConfirm={() => fn.current(true)}  
      />
    </ConfirmDialogContext.Provider>
  )
}

export function useConfirm() {
  return useContext(ConfirmDialogContext)
}

const ProgressBarContext = createContext()
export function ProgressBarProvider({ children }) {
    const [states, setStates] = useState({});
    const [cancelFlags, setCancelFlags] = useState({});
  
    const progressBar = (data) => {
        if (data.show) { // Show Progress Bar
            setStates(prevStates => ({ ...prevStates, [data.eventID]: data }))
        } else { // Hide Progress Bar
            setStates(prevStates => {
                const newStates = {...prevStates}
                delete newStates[data.eventID];
                return newStates
            });
        }  
    }

    const cancelProgress = (eventID) => {
        setCancelFlags(prevFlags => {
            const newFlags = { ...prevFlags, [eventID]: true };
            //console.log("Cancel flags updated:", newFlags); // Check the updated flags
            return newFlags;
        });
    };
    
    const resetCancelFlag = (eventID) => {
        setCancelFlags(prevFlags => {
            const newFlags = { ...prevFlags };
            delete newFlags[eventID];
            //console.log("Cancel flags after reset:", newFlags); // Check the flags after reset
            return newFlags;
        });
    };
  
    return (
      <ProgressBarContext.Provider value={{ progressBar, cancelProgress, resetCancelFlag, cancelFlags }}>
        {children}
        { 
            Object.keys(states).map(eventID => <ProgressBar key={eventID} {...states[eventID]} handleCancel={() => cancelProgress(eventID)}/>)
        }
      </ProgressBarContext.Provider>
    )
  }

// PROGRESS BAR
const LegacyProgressBarContext = createContext()

export function LegacyProgressBarProvider({ children }) {
  const [state, setState] = useState({ show: false })

  const progressBar = (data) => { 
    setState({ ...data })
  };   

  return (
    <ProgressBarContext.Provider value={progressBar}>
      {children}
      <ProgressBar
        {...state}
      />
    </ProgressBarContext.Provider>
  )
}
  
export function useProgress() {
  return useContext(ProgressBarContext)
}

// LOADING SPINNER
const LoadingOverlayContext = createContext()
export function LoadingOverlayProvider({ children }) {
  const [state, setState] = useState({ show: false })

    const loadingOverlay = (data) => { 
      setState({ ...data })
    }; 

    return (
        <LoadingOverlayContext.Provider value={loadingOverlay}>
            {children}
            <LoadingOverlay
                {...state}
            />
        </LoadingOverlayContext.Provider>
    );
}
  
export function useLoadingOverlay() {
   return useContext(LoadingOverlayContext)
}

// PERSISTENT LOADING SPINNER
const PersistentLoadingOverlayContext = createContext()
export function PersistentLoadingOverlayProvider({ children }) {
  const [state, setState] = useState({ show: false })

    const persistentLoadingOverlay = (data) => { 
      setState({ ...data })
    }; 

    return (
        <PersistentLoadingOverlayContext.Provider value={persistentLoadingOverlay}>
            {children}
            <PersistentLoadingOverlay
                {...state}
            />
        </PersistentLoadingOverlayContext.Provider>
    );
}
  
export function usePersistentLoadingOverlay() {
   return useContext(PersistentLoadingOverlayContext)
}

//DATE RANGE DIALOG
const DateRangeDialogContext = createContext()

export function DateRangeDialogProvider({ children }) {
  const [state, setState] = useState({ show: false })
  const fn = useRef()

  const dateRangeDialog = (data) => { 
    return new Promise((resolve) => {
      setState((prev) => ({...data, show: !prev.show}))
      fn.current = (dates) => { 
        resolve(dates) 
      }
    })
  }

  return (
    <DateRangeDialogContext.Provider value={dateRangeDialog}>
      {children}
      <DateRangeDialog
        {...state}
        onClose={() => fn.current(null)}   
        onConfirm={(data) => fn.current(data)}  
      />
    </DateRangeDialogContext.Provider>
  )
}

export function useDateRange() {
    return useContext(DateRangeDialogContext)
}


//DATE PICKER DIALOG
const DatePickerDialogContext = createContext()

export function DatePickerDialogProvider({ children }) {
  const [state, setState] = useState({ show: false })
  const fn = useRef()

  const dateRangeDialog = (data) => { 
    return new Promise((resolve) => {
      setState((prev) => ({...data, show: !prev.show}))
      fn.current = (dates) => { 
        resolve(dates) 
      }
    })
  }

  return (
    <DatePickerDialogContext.Provider value={dateRangeDialog}>
      {children}
      <DatePickerDialog
        {...state}
        onClose={() => fn.current({'date': '0000-00-00'})}   
        onConfirm={(date) => fn.current({'date': date})}  
      />
    </DatePickerDialogContext.Provider>
  )
}
export function useDatePicker() {
    return useContext(DatePickerDialogContext)
}

// TASK SCHEDULER
const TaskSchedulerContext = createContext()

export function TaskSchedulerContextProvider({ children }) {
    const [state, setState] = useState({ show: false })
    const fn = useRef()

    const confirmDialog = (data) => { 
        return new Promise((resolve) => {
            setState((prev) => ({...data, show: !prev.show}))
            fn.current = (choice) => { 
                resolve(choice) 
            }
        })
    }

    return (
        <TaskSchedulerContext.Provider value={confirmDialog}>
            {children} 
            <TaskScheduler
                {...state}
                handleClose={() => setState({ show: false })}
                onClose={() => fn.current(false)}   
                onConfirm={() => fn.current(true)}  
            />
        </TaskSchedulerContext.Provider>
    )
}

export function useTaskScheduler() {
    return useContext(TaskSchedulerContext)
}

//Flash Alert
const FlashAlertContext = createContext()
export function FlashAlertProvider({ children }) {
  const fn = useRef()
  const [states, setStates] = useState([])

  const flashAlert = (data) => { 
    return new Promise((resolve) => {
      setStates(prevStates => { 
          const newStates = [...prevStates]
          newStates.push({ ...data, show: true })
          return newStates
      })
      fn.current = (choice) => { 
        resolve(choice) 
      }
    })
  };   

  return (
    <FlashAlertContext.Provider value={flashAlert}>
      {children}
      { 
          states.map((state, index) => <FlashAlert key={index} {...state} handleClose={() => {
                setStates(
                    prevStates => {
                        const newStates = [...prevStates]
                        newStates.splice(index, 1)
                        return newStates
                    }
                )

                fn.current(true)
              }}
          />) 
      }
    </FlashAlertContext.Provider>
  )
}
  
export function useFlashAlert() {
  return useContext(FlashAlertContext)
}

const FeeSelectionDialogContext = createContext();

export function FeeSelectionProvider({ children }) {
  const [state, setState] = useState({ show: false, feesToDrop: [] });
  const fn = useRef(null);

  const feeSelectionDialog = (data) => {
    return new Promise((resolve) => {
      setState((prev) => ({ ...prev, ...data, show: true }));
      fn.current = (choice) => {
        resolve(choice);
        setState((prev) => ({ ...prev, show: false }));
      };
    });
  };
  

  return (
    <FeeSelectionDialogContext.Provider value={feeSelectionDialog}>
      {children}
      <FeeSelectionModal
        {...state}
        onClose={() => fn.current(null)} 
        onConfirm={(selectedFees) => fn.current(selectedFees)} 
      />
    </FeeSelectionDialogContext.Provider>
  );
}

export function useFeeSelectionDialog() {
  return useContext(FeeSelectionDialogContext);
}

// Delivered/Printed Dialog for Supply Delivery Report
const SupplyDeliveryOptionsContext = createContext()

export function SupplyDeliveryOptionsProvider({ children }) {
    const [state, setState] = useState({ show: false })
    const fn = useRef()

    const supplyDeliveryOptions = (data) => {
        return new Promise((resolve) => {
            setState((prev) => ({ ...data, show: !prev.show }))
            fn.current = (choice) => {
                resolve(choice)
            }
        })
    }

    return (
        <SupplyDeliveryOptionsContext.Provider value={supplyDeliveryOptions}>
            {children}
            <SupplyDeliveryOptionsModal
                {...state}
                onClose={() => fn.current(null)}   
                onConfirm={(data) => fn.current(data)}  
                />
        </SupplyDeliveryOptionsContext.Provider>
    )
}

export function useSupplyDeliveryOptions() {
    return useContext(SupplyDeliveryOptionsContext)
}

const UpdateEntryNumberDialogContext = createContext();

export function UpdateEntryNumberProvider({ children }) {
  const [state, setState] = useState({ show: false, seriesEntryNumber: {}, methods: null });
  const fn = useRef(null);

  const updateEntryNumberDialog = (data, formMethods) => {
    return new Promise((resolve) => {
      setState({ ...data, show: true, methods: formMethods });
      fn.current = (updatedSeries) => {
        resolve(updatedSeries);
        setState({ show: false, seriesEntryNumber: {}, methods: null });
      };
    });
  };

  return (
    <UpdateEntryNumberDialogContext.Provider value={updateEntryNumberDialog}>
      {children}
      {state.methods && (
        <FormProvider {...state.methods}>
          <UpdateEntryNumberForm
            {...state}
            onClose={() => fn.current(null)}
            onConfirm={(updatedEntries) => fn.current(updatedEntries)}
          />
        </FormProvider>
      )}
    </UpdateEntryNumberDialogContext.Provider>
  );
}

export function useUpdateEntryNumberDialog() {
    return useContext(UpdateEntryNumberDialogContext);
}

const DeleteConfirmDialogContext = createContext()

export function DeleteConfirmDialogProvider({ children }) {
  const [state, setState] = useState({ show: false, moduleName: '' })
  const fn = useRef(null)

  const deleteConfirmDialog = (data) => {
    return new Promise((resolve) => {
      setState({ ...data, show: true })
      fn.current = (choice) => {
        setState({ show: false, moduleName: '' })  
        resolve(choice)
      }
    })
  }

  return (
    <DeleteConfirmDialogContext.Provider value={deleteConfirmDialog}>
      {children}
      <DeleteConfirmDialog
        {...state}
        onClose={() => {
          fn.current && fn.current(false) 
          setState({ show: false, moduleName: '' })  
        }}
        onConfirm={() => {
          fn.current && fn.current(true)
        }}
      />
    </DeleteConfirmDialogContext.Provider>
  )
}

export function useDeleteConfirm() {
  return useContext(DeleteConfirmDialogContext)
}

