import { useIntl } from 'react-intl'
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo, useState } from 'react';
import { getExtraNarrowHeaderHeight, getNarrowRowHeight, getRowData, numberValueSetter, renderCheckBox, updateAGGridWithoutFlickering } from '../../../../modules/sgl-utils/agGridHelpers';
import CheckboxHeader from '../../../../modules/components/CheckboxHeader';
import { addFloatingNumbers } from '../../../../modules/sgl-utils/SglFunctions';

const BalanceTransferTab = ({ handleClose, selectedEntries, applyPayment }) => {
    const intl = useIntl();
    const currencyFormatter = useCurrencyFormatter()
    const alertDialog = useAlert()

    const [checkAll, setCheckAll] = useState(true)
    const [gridRef, setGridRef] = useState()
    const [entriesWithBalanceOnly, setEntriesWithBalanceOnly] = useState(false)
    const [creditType, setCreditType] = useState('PMR Credit') // by default select the PMR credit
    const [allRowData, setAllRowData] = useState([])
    const [recalAmountApplied, setRecalAmountApplied] = useState(true)

    const containerStyle = useMemo(() => ({ width: '100%', height: '470px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const defaultColDef = {
        suppressMenu: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: params => {
            let style = {textAlign: 'left'}
            if (typeof params.value === 'number') {
                style = { textAlign: 'right' }
            } else if (typeof params.value === 'boolean') {
                style = { textAlign: 'center' }
            }
           return { ...style } 
        },
        headerClass: "ag-center-aligned-header",
        sortable: true,
        resizable: true
    }

    const columnDefs = [
        { 
            field: 'Show.show_name', 
            headerName: intl.formatMessage({ id: 'ENTRIES.FEESPLIT.MODAL.GRID.SHOW' }), 
            flex: 2,
        },
        { 
            field: 'number',
            headerName: intl.formatMessage({ id: "LIST.ENTRIES.NUMBER" }),
            width: 90,
            cellClass: 'text-center'
        },
        { 
            field: 'horse', 
            headerName: intl.formatMessage({ id: "LIST.ENTRIES.HORSE" }), 
            flex: 2,
        },
        { 
            field: 'responsibleparty', 
            headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.RP" }), 
            flex: 2,
        },
        { 
            field: 'prizemoneyrecipient', 
            headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.PMR" }), 
            flex: 2,
        },
        { 
            field: 'arBalance', 
            headerName: `${creditType == 'RP Credit' ? 'RP' : 'PMR'} ${intl.formatMessage({ id: "APPLY.PAYMENT.TOOL.TAB.COL.AR.BALANCE" })}`, 
            width: 125,
            cellRenderer: params => params.node.rowPinned ? null : currencyFormatter(params.value, true),
            cellStyle: { textAlign: 'right' },
            valueGetter: function getTeam(params) { 
                if(creditType =='RP Credit' ) { return params?.data?.rpARBalance }
                if(creditType =='PMR Credit' ) { return params?.data?.pmrARBalance }
                return 0
            } 
        },
        { 
            field: 'availableCredit', 
            headerName: `${creditType == 'RP Credit' ? 'RP' : 'PMR'} ${intl.formatMessage({ id: "APPLY.PAYMENT.TOOL.TAB.COL.CREDIT" })}`, 
            width: 125,
            cellRenderer: params => params.node.rowPinned ? null : currencyFormatter(params.value),
            cellStyle: { textAlign: 'right' },
            valueGetter: function getTeam(params) { 
                if(creditType =='RP Credit' ) { return params?.data?.rpCredit }
                if(creditType =='PMR Credit' ) { return params?.data?.pmrCredit }
                return 0
            },
            cellStyle: params => getAvailableAmountClass(params)
        },
        { 
            field: 'status', 
            headerName: `${creditType == 'RP Credit' ? 'RP' : 'PMR'} ${intl.formatMessage({ id: "APPLY.PAYMENT.TOOL.TAB.COL.STATUS" })}`, 
            flex: 2,
            cellStyle: { textAlign: 'left' },
            valueGetter: function getTeam(params) { 
                if(creditType =='RP Credit' && params?.data?.rpARBalance != 0) { return params?.data?.rpStatus?.join(', ') }
                if(creditType =='PMR Credit' && params?.data?.pmrARBalance != 0 ) { return params?.data?.pmrStatus?.join(', ') }
                return ''
            } 
        },
        { 
            field: 'balance', 
            headerName: intl.formatMessage({ id: 'ENTRIES.APPLYPAYMENTS.MODAL.GRID.BALANCE' }),
            width: 110,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: "right" }
        },
        { 
            field: 'apply', 
            width: 120,
            cellRenderer: params => params.node.rowPinned ? null : renderCheckBox(params, unCheckEntryPayment, params.node.data.balance <= 0 || (creditType == 'RP Credit' ? params.node.data.rpCredit <= 0 : params.node.data.pmrCredit <= 0)),
            headerComponentFramework: CheckboxHeader,
            headerComponentParams: {
                headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.APPLY" }),
                onChange: (checked) => { 
                    setCheckAll(checked)
                    updateIncludeForAllEntries(checked)
                    setRecalAmountApplied(!recalAmountApplied)
                },
                checked: checkAll
            },
            resizable: false,
            cellStyle: { textAlign: "center" }
        },
        { 
            field: 'amountApplied',
            headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.AMOUNTAPPLIED" }),
            width: 125,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: "right" },
            editable: params => params.data.apply && params.data.balance > 0 && creditType == 'RP Credit' ? params.data.rpCredit > 0 : params.data.pmrCredit > 0, // Allow edit only if apply is true and entry has some debit balance
            cellStyle: params => getEditableCellStyles(params),
            valueSetter: numberValueSetter
        }
    ]

    // when entry include checkbox is unchecked. uncheck the header include if it is checked.
    const unCheckEntryPayment = (checked) => {
        if(!checked && checkAll){
            setCheckAll(false)
        }

        setRecalAmountApplied(!recalAmountApplied)
    }

    const getEditableCellStyles = (params) => {
        if(params.node.rowPinned){ // bottom pinned row
            return { textAlign: "right"}
        }

        if(params.data.apply){ // payment column is editable
            return { textAlign: "right", border: '1px solid rgb(202, 202, 202)' }
        }
        
        // payment column is not editable
        return { textAlign: "right", backgroundColor: '#f4f8fb', border: '1px solid rgb(202, 202, 202)' }
    }

    const onCellEditingStopped = (params) => {
        let entries = getRowData(gridRef)
        let newAmount = parseFloat(params.data.amountApplied)
        let availableCredit = creditType == 'RP Credit' ? params.data.rpCredit : params.data.pmrCredit

        if(newAmount > availableCredit){
            alertDialog({message: `${intl.formatMessage({ id: 'APPLY.PAYMENT.TOOL.TAB.BUTTON.AVAILABLE.CREDIY.EXCEED.ERROR' })}`});
            newAmount = params.oldValue
        }else if(newAmount > params.data.balance){
            alertDialog({message: `${intl.formatMessage({ id: 'APPLY.PAYMENT.TOOL.TAB.BUTTON.APPLY.PRIZE.MONEY.BALANCE.ERROR' })}`});
            newAmount = params.oldValue
        }

        let updatedRowData = entries.map((rowdata) => {
            if(rowdata.entry_id === params.data.entry_id){
                rowdata.amountApplied = parseFloat(newAmount)
            }

            return rowdata
        })

        updateAGGridWithoutFlickering({ current: {api: gridRef }}, updatedRowData)
        calculateTotals()
    }

    // update include value for all entries when header checkbox is checked or unchecked
    const updateIncludeForAllEntries = (checkAll) => {
        const rowData = getRowData(gridRef).map(row => ({ ...row, apply: row.balance > 0 && ((creditType == 'RP Credit' && row.rpCredit > 0) || (creditType == 'PMR Credit' && row.pmrCredit > 0)) ? checkAll : false }))
        updateAGGridWithoutFlickering({ current: {api: gridRef }}, rowData)
        setRecalAmountApplied(!recalAmountApplied)
    }

    const getAvailableAmountClass = (params) => {
        let arBalance = creditType == 'RP Credit' ? params.data.rpARBalance : params.data.pmrARBalance
        let availableCredit = creditType == 'RP Credit' ? params.data.rpCredit : params.data.pmrCredit
        if( arBalance != 0){
            if(availableCredit == 0){
                return {color: '#f04332', textAlign: 'right' };
            }
        }

        return {textAlign: 'right'}
    }

    const recalculateAmountApplied = (recalDistributions=true) => {
        let rowsData = getRowData(gridRef)
        let rtosData = []

        // get availabl credit RPs and PMRs
        rowsData.map((row) => {
            let existingRP = rtosData.find((rto) => rto.people_id == row.responsibleparty_id)
            if(!existingRP){
                rtosData.push({people_id: row.responsibleparty_id, availableCredit: row.rpCredit})
            }

            if(row.responsibleparty_id != row.prizemoneyrecipient_id){
                let existingPMR = rtosData.find((rto) => rto.people_id == row.prizemoneyrecipient_id)
                if(!existingPMR){
                    rtosData.push({people_id: row.prizemoneyrecipient_id, availableCredit: row.pmrTransferableCredit})
                }
            }
        })
        
        // distribute the available credit amount entries
        for(let data of rowsData){

            if(!recalDistributions && data.amountApplied > 0){ // don't recalculate the amount applied if called wen column editing is stopped
                continue;
            }

            if(data.balance <= 0 || !data.apply){ // entry has credit balance or it is not selected
                data.amountApplied = 0
                continue;
            }

            // get the RTO that will be used for entry payment >>>
            let rtoUsedForPayment
            if(creditType == 'RP Credit' && data.rpCredit > 0){ // RP used for payment
                rtoUsedForPayment = rtosData.find((rto) => rto.people_id == data.responsibleparty_id)
            }else if(data.pmrCredit > 0){ // PMR used for payment
                rtoUsedForPayment = rtosData.find((rto) => rto.people_id == data.prizemoneyrecipient_id)
            }
            
            if(!rtoUsedForPayment){// should never happen?
                data.amountApplied = 0
                continue; 
            }
            // get the RTO that will be used for entry payment <<<

            // check if there is credit available that will be used for entry payment
            if(rtoUsedForPayment.availableCredit <= 0){
                data.amountApplied = 0
                continue; 
            }

            // determine the credit that will be applied to the entry
            data.amountApplied = Math.min(data.balance, rtoUsedForPayment.availableCredit)

            // update the available credit for the RTO
            rtoUsedForPayment['availableCredit'] = addFloatingNumbers([rtoUsedForPayment['availableCredit'], -1*data.amountApplied], 2)
            
        }

        updateAGGridWithoutFlickering({ current: {api: gridRef }}, rowsData)
        calculateTotals()
    }

    const transferCreditFromAccount = async(event) => {
        
        const appliedRows = []
        let rtosWithAppliedEntries = []
        let rowData = getRowData(gridRef)
        for (let row of rowData) {
            let availableAmount = row.pmrTransferableCredit
            let rtoId = row.prizemoneyrecipient_id
            let rto = row.prizemoneyrecipient
            if(creditType == 'RP Credit'){
                availableAmount = row.rpCredit
                rtoId = row.responsibleparty_id
                rto = row.responsibleparty    
            }

            if (row.apply && availableAmount > 0 && row.amountApplied > 0) {
                row.creditTransferred = row.amountApplied

                // Use only amount that will make balance 0 if unapplied prize money is greater than entry balance
                if (row.creditTransferred >= row.balance) {
                    row.creditTransferred = row.balance
                }

                appliedRows.push(row)
                let existingRTO = rtosWithAppliedEntries.find((rto) => rto.people_id == rtoId)
                if(existingRTO){
                    existingRTO['amountUsed'] = addFloatingNumbers([existingRTO['amountUsed'], row.creditTransferred], 2)
                }else{
                    rtosWithAppliedEntries.push({people_id: rtoId, name: rto, availableCredit: availableAmount, amountUsed: row.creditTransferred})
                }
            }
        }

        if(appliedRows.length == 0){
            alertDialog({message: 'No entries found where credit will be transferred.'})
            return
        }

        for(let rto of rtosWithAppliedEntries){
            if(rto['amountUsed'] > rto['availableCredit']){
                alertDialog({message: `Credit used for entry payments (${currencyFormatter(rto['amountUsed'])}) cannot exceed the available credit (${currencyFormatter(rto['availableCredit'])}) for ${rto['name']} (${creditType === 'RP Credit' ? 'RP' : 'PMR'}).`})
                return
            }
        }

        // Create request payload
        let data = {
            paymentAmount: 0, 
            moveCreditFromAccount: true,
            autoApplyPrizeMoney: false,
            autoApplyPrizeMoneyPayment: false,
            addPayment: false,
            CardOwner: 0, 
            CardOwnerID: 0,
            paymentTypeId: 0,
            saveSource: "apply payment to multiple entries",
            moveFrom: creditType == 'RP Credit' ? 'RP' : 'PMR'
        }

        await applyPayment(event, appliedRows, data)
    }

    // calculate the total balance, prize money payment and payment
    const calculateTotals = () => {
        const rowData = getRowData(gridRef)
        let totalBalance = 0
        let totalPayment = 0

        rowData.forEach(row => {           
            totalBalance = addFloatingNumbers([row.balance, totalBalance], 2)
            if (row.apply) {
                totalPayment = addFloatingNumbers([row.amountApplied, totalPayment], 2)
            }
        })

        gridRef?.setPinnedBottomRowData([{
            balance: totalBalance,
            amountApplied: totalPayment
        }])
    }

    useEffect(() => {
        if(gridRef){
            const rowData = selectedEntries
            setAllRowData(rowData)
            updateAGGridWithoutFlickering({ current: {api: gridRef }}, rowData)
            calculateTotals()
        }
    }, [gridRef, selectedEntries])

    useEffect(() => {
        if(checkAll && gridRef){
            updateIncludeForAllEntries(checkAll)
        }
    }, [checkAll, gridRef, selectedEntries])

    useEffect(() => {
        if (gridRef) {
            const rowData = getRowData(gridRef) 
            const filteredRowData = []

            if (entriesWithBalanceOnly) { // Display entries with balance
                for (let row of rowData) { 
                    if (row.balance > 0) {
                        filteredRowData.push(row)
                    }
                }
            } else { // Display all entries
                for (let row of allRowData) {
                    // Find row in current display
                    let foundRow = rowData.find(r => r.entry_id == row.entry_id) 
                    if (foundRow) {
                        filteredRowData.push(foundRow)
                    } else {
                        filteredRowData.push({ ...row, apply: row.balance > 0 && (creditType == 'RP Credit' ? row.rpCredit > 0 : row.pmrCredit > 0) ? checkAll : false })
                    }
                }
            }
            updateAGGridWithoutFlickering({ current: {api: gridRef }}, filteredRowData)
            calculateTotals()
        }
    }, [entriesWithBalanceOnly, gridRef])

    useEffect(() => {
        if(gridRef){
            recalculateAmountApplied()
        }
    }, [recalAmountApplied, selectedEntries, gridRef])

    useEffect(() => {
        if(gridRef){
            updateIncludeForAllEntries(checkAll)
        }
    }, [gridRef, creditType])

    return (
        <>
            {/* Credit Type */}
            <div className='row my-2 col-lg-12 mb-0'>
                <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 label-width-130 pe-0'>{intl.formatMessage({ id: `APPLY.PAYMENT.TOOL.TAB.RADIO.CREDIT.TYPE` })}</label>
                
                <div className='col-lg-3 form-check-sm form-check-custom px-3 mw-200px'>
                    <input
                        className='form-check-input'
                        type='radio'
                        id='pmrCredit'
                        checked={creditType === 'PMR Credit'}
                        onChange={(e) => {
                            setCreditType('PMR Credit')
                        }}
                    />
                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='pmrCredit'> {intl.formatMessage({ id: 'APPLY.PAYMENT.TOOL.TAB.RADIO.PMR.CREDIT' })}</label>
                </div>  

                <div className='col-lg-3 form-check-sm form-check-custom px-3 mw-150px'>
                    <input
                        className='form-check-input'
                        type='radio'
                        id='rpCredit'
                        checked={creditType === 'RP Credit'}
                        onChange={(e) => {
                            setCreditType('RP Credit')
                        }}
                    />
                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='rpCredit'> {intl.formatMessage({ id: 'APPLY.PAYMENT.TOOL.TAB.RADIO.RP.CREDIT' })}</label>
                </div>  

                {/* Entries with balance only */}
                <div className='col d-flex justify-content-end'>
                    <div className="row mb-0 mt-0 me-0">
                        <div className='w-lg-25px form-check-sm form-check-custom ps-lg-3 py-2'>
                            <input
                                className='form-check-input'
                                type="checkbox"
                                id="prizeMoneyEntriesWithBalance" 
                                checked={entriesWithBalanceOnly}
                                onChange={e => setEntriesWithBalanceOnly(e.target.checked)} 
                            />
                        </div>

                        <label 
                            className='col-form-label fs-5 py-1 w-lg-200px'
                            htmlFor="entriesWithBalance"
                            data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.ENTRIESWITHBALANCEONLY"
                        >
                            { intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.LABEL.ENTRIESWITHBALANCE" }) }
                        </label>
                    </div>  
                </div>           
            </div>

            {/* Entries Grid */}
            <div style={containerStyle} className='row mb-2'>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        onGridReady={params => setGridRef(params.api)}
                        defaultColDef={defaultColDef}
                        rowData={[]}
                        rowStyle={{ fontSize: '13px', 'paddingLeft': 0, 'paddingRight': 0}}
                        columnDefs={columnDefs} 
                        suppressScrollOnNewData={true}
                        headerHeight={getExtraNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        stopEditingWhenCellsLoseFocus={true}
                        singleClickEdit={true}
                        getRowStyle={params => {
                            if (params.node.rowPinned) {
                                return { fontWeight: "bold" };
                            }
                        }}
                        onCellEditingStopped={onCellEditingStopped}
                    />
                </div>
            </div>

            {/* Footer */}
            <div className='card-footer d-flex justify-content-end me-6 align-items-center'>
                {/* Info label */}
                <div className='row col-9'>
                    <div className='d-flex align-items-center'>
                        <div className='me-2' style={{ width:'35px',height:'7px', backgroundColor: '#ff8c81' }}></div>
                        <label className='col-form-label fs-5 py-0'>
                            {intl.formatMessage({ id: 'APPLY.PAYMENT.TOOL.LABEL.ERROR' })}
                        </label>
                    </div>
                </div>
                
                <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} id='ApplyPaymentCloseButton'>
                    {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CLOSE' })}
                </button>
                <button 
                    type='button' 
                    className='btn btn-sm btn-dark fw-bold text-uppercase' 
                    onClick={transferCreditFromAccount} 
                    id='ApplyPrizeMoneySubmitButton'
                >
                    <span className="spinner-border spinner-border-sm d-none me-2"></span>
                    {intl.formatMessage({ id: 'APPLY.PAYMENT.TOOL.TAB.BUTTON.MOVE.CREDIT.FROM.ACCOUNT' })}
                </button>
            </div>
        </>
    );
}

export { BalanceTransferTab }