import {useIntl} from 'react-intl'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import {useFormContext, Controller} from 'react-hook-form'
import Select from 'react-select'
import moment from 'moment'
import {
  reactSelectStyles,
  date_options,
  time_options_12hours,
  setTime,
  setDate,
} from '../../../modules/sgl-utils/fieldControls'
import {Modal, Button, Form} from 'react-bootstrap'
import {useState} from 'react'
import {NumericFormat} from 'react-number-format'
import { maskDateInput } from '../../../modules/sgl-utils/fieldControls'

const WebTab = (props) => {
  const intl = useIntl()
  const methods = useFormContext()
  const [show, setShow] = useState(false)
  const [disallowReasonMessage, setDisallowReasonMessage] = useState('')

  const handleOK = () => {
    setShow(false)
    methods.setValue('show.disallowwebentryreason', disallowReasonMessage, {shouldDirty: true})
    if(methods.getValues('show.disallowwebentryreason') === ''){
      methods.setValue('disallowwebentryreason',0)
    }
  }

  const handleClose = () => {
    setShow(false)
    if(methods.getValues('show.disallowwebentryreason') === ''){
      methods.setValue('disallowwebentryreason',0)
    }
    if(methods.getValues('show.suppress_on_web')){
      methods.setValue('show.disallowwebentryreason','Web entries disabled.', {shouldDirty:true})
    }
  }

  const handleShow = () => { methods.getValues('disallowwebentryreason')? setShow(methods.watch('disallowwebentryreason')): methods.setValue('show.disallowwebentryreason','',{shouldDirty:true})}

  function resetDepositeData() {
    if (!methods.getValues('show.web_deposit_required')) {
      methods.setValue('show.web_deposit_fixed_amount', 0)
      methods.setValue('show.web_deposit_fees', 0)
      methods.setValue('show.web_deposit_class', 0)
      methods.setValue('show.web_deposit_type', '')
    }
  }

  function handleInputChange(event) {
    setDisallowReasonMessage(event.target.value)
  }

   function enableDisallowWebEntry(e){
    if(e.target.checked){
      methods.setValue('disallowwebentryreason',1)
      methods.setValue('show.disallowwebentryreason','Web entries disabled.',{shouldDirty:true})
    }
   }

   const handleRange = (e) => {
    if (e.target.value >= methods.getValues('show.starting_traineraccount_entryno')) {
      methods.setValue(
        'show.starting_webentry_number',
        Number(methods.getValues('show.starting_entry_number')) + 10000,
        {shouldDirty: true}
      )
      methods.setError('show.starting_webentry_number', {
        type: 'custom',
        message:
          'The entered number belongs to the range of numbers allotted for Trainer Accounts.',
      })
    }  else {
      methods.setValue('show.starting_webentry_number', e.target.value, {shouldDirty: true})
      methods.clearErrors('show.starting_webentry_number')
    }
  }

  return (
    <>
    {show && 
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        backdrop='static'
        dialogClassName='modal modal-dialog modal-dialog-centered mw-400px search-form'
        show={show}
        onHide={handleClose}
        onKeyDown={(event) => { //replaced onKeyDown with onKeyPress
          let isButtonFocused = false
          let activeElement = document.activeElement //gets the currently focussed element
          if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
              isButtonFocused = true;
          }
          if (event.key == "Enter" && !event.shiftKey && !isButtonFocused) {
            event.preventDefault()
              handleOK()
          }
        }}
      >
        <Modal.Body>
          <Form onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
            <Form.Group className='mb-3'>
              <Form.Label>Please enter a reason for disallowing web entries</Form.Label>
              <Form.Control as='textarea' rows={2} autoFocus onChange={handleInputChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' size='sm' type='button' onClick={handleClose}
          >
            {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.DISALLOWREASON.BUTTON.CANCEL'})}
          </Button>
          <button className='btn btn-sm btn-dark fw-bold'  type='button' onClick={handleOK}>
            {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.DISALLOWREASON.BUTTON.OK'})}
          </button>
        </Modal.Footer>
      </Modal>
    }
      <div className='form-group row px-2'>
        {/* Column Group */}
        <div className='form-group row'>
          {/* Column Group */}
          <div className='col-lg-8'>
            {/* Column 1 */}
            <div className='row mb-2'>
              <label
                className='col-lg-3 col-form-label  fs-5 py-1'
                htmlFor='web_entry_closing_date'
                data-tooltip-id="SHOW.DETAIL.TAB.WEB.LABEL.WEBENTRYCLOSINGDATE"
              >
                <span className=''>
                  {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.WEBENTRYCLOSING'})}
                </span>
              </label>

              <div className='col-lg-3 '>
                <div className='input-group date'>
                    <Controller
                      name='show.web_entry_closing_date'
                      render={({ field: { onChange, value, name } }) => {
                          const formattedValue = (value && moment(value).isValid())
                              ? moment(value).format('MM/DD/YYYY')
                              : '';

                          return (
                              <Flatpickr
                                  className='form-control form-control-sm fs-6 min-h-20px py-1'
                                  id='web_entry_closing_date'
                                  name={name}
                                  autoComplete="off"
                                  value={formattedValue}
                                  placeholder='MM/DD/YYYY'
                                  options={date_options}
                                  onClose={(value, dateStr, instance) => {
                                      const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                      if (!isValidDate) {
                                          instance.input.value = ''; // Clear the input if the date is invalid
                                      }
                                      setDate(dateStr, onChange);
                                  }}
                                  onChange={(value, dateStr, instance) => {
                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                    if (!isValidDate) {
                                        instance.input.value = ''; // Clear the input if the date is invalid
                                    }
                                    setDate(dateStr, onChange);
                                  }}
                                  onInput={(e) => { maskDateInput(e) }}
                              />
                          );
                      }}
                  />
                  <div className='input-group-append'>
                    <label htmlFor='web_entry_closing_date' className='date-label'
                      onClick={(e) => {
                        e.stopPropagation(); 
                        document.getElementById('web_entry_closing_date')._flatpickr.open(); 
                      }}
                    >
                      <span className='input-group-text'>
                        <i className='la la-calendar'></i>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              {methods.getValues('show.web_entry_closing_date') ? (
                <label
                  className='col-lg col-form-label  fw-bold fs-5 py-1 me-5 text-danger'
                >
                  {intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.WEBENTRYCLOSINGDATE'}) +
                    ' ' +
                    moment(methods.getValues('show.web_entry_closing_date')).format('MM/DD/YYYY')}
                </label>
              ) : (
                ''
              )}
            </div>

            <div className='row mb-2'>
              <label
                className='col-lg-3 col-form-label  fs-5 py-1'
                htmlFor='starting_webentry_number'
                data-tooltip-id="SHOW.DETAIL.TAB.WEB.LABEL.WEBENTRYRANGE"
              >
                <span className=''>
                  {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.WEBENTRYRANGE'})}
                </span>
              </label>

              <div className='col-lg-3'>
                 <Controller
                    control={methods.control}
                    name="show.starting_webentry_number"
                    render={({ field: { onChange, name, value } }) => (
                    <NumericFormat
                        id="starting_webentry_number"
                        value={Number(value)}
                        className='form-control form-control-sm  mb-2 mb-lg-0  fs-6 min-h-20px py-1'
                        type="text"
                        name={name}
                        decimalScale={0}
                        valueIsNumericString={true} // Allow only numeric input
                        allowNegative={false} // disallow negative values
                        onValueChange={(e)=>{
                                onChange(e.value)
                        }}
                        disabled={true}
                    />
                    )}
                 />
              </div>

              <div className='col-lg-1 w-25px'>-</div>
              <div className='col-lg-3'>
                  <Controller
                    control={methods.control}
                    name="show.ending_web_entry_number"
                    render={({ field: { onChange, name, value } }) => (
                    <NumericFormat
                        id="ending_web_entry_number"
                        value={Number(value)}
                        className='form-control form-control-sm  mb-2 mb-lg-0  fs-6 min-h-20px py-1'
                        type="text"
                        name={name}
                        decimalScale={0}
                        valueIsNumericString={true} // Allow only numeric input
                        allowNegative={false} // disallow negative values
                        onValueChange={(e)=>{
                                onChange(e.value)
                        }}
                        disabled={true}
                    />
                    )}
                 />
              </div>
              {methods.formState.errors?.show?.starting_webentry_number && (
                <div>
                  <span className='error_message' style={{paddingLeft: '200px'}}>
                    {methods.formState.errors.show.starting_webentry_number?.message}
                  </span>
                </div>
              )}
            </div>

            <div className='row mb-2'>
              <label
                className='col-lg-3 col-form-label  fs-5 py-1'
                htmlFor='starting_traineraccount_entryno'
                data-tooltip-id="SHOW.DETAIL.TAB.WEB.LABEL.TRAINERACCOUNTRANGE"
              >
                <span className=''>
                  {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.TRAINERACCOUNT'})}
                </span>
              </label>

              <div className='col-lg-3'>
                  <Controller
                    control={methods.control}
                    name="show.starting_traineraccount_entryno"
                    render={({ field: { onChange, name, value } }) => (
                    <NumericFormat
                        id="starting_traineraccount_entryno"
                        value={Number(value)}
                        className='form-control form-control-sm  mb-2 mb-lg-0  fs-6 min-h-20px py-1'
                        type="text"
                        name={name}
                        decimalScale={0}
                        valueIsNumericString={true} // Allow only numeric input
                        allowNegative={false} // disallow negative values
                        onValueChange={(e)=>{
                                onChange(e.value)
                        }}
                    />
                    )}
                 />
              </div>
              <div className='col-lg-1 w-25px'>-</div>
              <div className='col-lg-3'>
                  <Controller
                    control={methods.control}
                    name="show.ending_traineraccount_entryno"
                    render={({ field: { onChange, name, value } }) => (
                    <NumericFormat
                        id="ending_traineraccount_entryno"
                        value={Number(value)}
                        className='form-control form-control-sm  mb-2 mb-lg-0  fs-6 min-h-20px py-1'
                        type="text"
                        name={name}
                        decimalScale={0}
                        valueIsNumericString={true} // Allow only numeric input
                        allowNegative={false} // disallow negative values
                        onValueChange={(e)=>{
                          onChange(e.value)
                        }}
                    />
                    )}
                 />
              </div>
            </div>
            <fieldset>
              <legend className='fs-5 fw-bold d-flex'>
                {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.GROUPLABEL.WEBSETTINGS'})}
              </legend>
              <div className='row mb-2'>
                {/* <div className='col-lg-4 d-flex align-items-center'>
                  <div className='form-check-sm form-check-custom  me-2'>
                    <input
                      {...methods.register('show.web_auto_capitalize', {
                        required: false,
                      })}
                      className='form-check-input'
                      type='checkbox'
                      id='web_auto_capitalize'
                    />
                  </div>
                  <label
                    className='col-form-label  fs-5 py-1 ignore-max-width'
                    htmlFor='web_auto_capitalize'
                    data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.AUTOCAPITALIZEWEBENTRIES"
                  >
                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.AUTOCAPITALIZE'})}
                  </label>
                </div> */}

                <div className='col-lg-3 d-flex align-items-center'>
                  <div className='form-check-sm form-check-custom  me-2'>
                    <input
                      {...methods.register('show.suppress_on_web', {
                        required: false,
                        onChange: enableDisallowWebEntry
                      })}
                      className='form-check-input'
                      type='checkbox'
                      id='suppress_on_web'
                     
                    />
                  </div>
                  <label
                    className='col-form-label  fs-5 py-1 ignore-max-width'
                    htmlFor='suppress_on_web'
                    data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.SUPPRESSHOWONWEB"
                  >
                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.SUPPRESSSHOW'})}
                  </label>
                </div>

                <div className='col-lg-3 d-flex align-items-center'>
                  <div className='form-check-sm form-check-custom me-2'>
                    <input
                      {...methods.register('show.web_disallow_addentrytoseries', {
                        required: false,
                      })}
                      className='form-check-input'
                      type='checkbox'
                      id='web_disallow_addentrytoseries'
                    />
                  </div>
                  <label
                    className='col-form-label  fs-5 py-1 ignore-max-width'
                    htmlFor='web_disallow_addentrytoseries'
                    data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.DISALLOWADDENTRYTOSERIESONWEB"
                  >
                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.DISALLOWADDENTRY'})}
                  </label>
                </div>
                <div className='col-lg-6 d-flex align-items-center' style={{paddingLeft: '40px'}}>
                  <label className='col-lg-4 col-form-label  fs-5 py-1' htmlFor='max_web_entry_limit' data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.WEBENTRYCODE">
                    <span className=''>
                      {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.WEBENTRYLIMIT'})} 
                    </span>
                  </label>

                  <div className='col-lg-2 '>
                    <input
                      {...methods.register('show.max_web_entry_limit', {
                        required: false,
                      })}
                      id='max_web_entry_limit'
                      type='text'
                      className='form-control form-control-sm  fs-6 min-h-20px py-1'
                      // placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.WEBENTRYCODE'})}
                    />
                  </div>
                  <div className='col-lg-5 '>
                  <label className='col-form-label  fs-5 py-1 pl-5'>
                    <span className=''>
                      {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.WEBENTRYADDED'})
                      +' ' +
                      props?.showData?.web_entries_count}
                    </span>
                  </label>
                  </div>
                </div>
              </div>
              <div className='form-group row'>
                {/* Column Group */}
                <div className='col-lg-12'>
                  {/* Column 1 */}

                  <div className='row mb-2'>
                    <label
                      className='col-lg-3 col-form-label  fs-5 py-1'
                      htmlFor='no_adds_days_before'
                      data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.STOPWEBADDS"
                    >
                      {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.STOPWEBADDS'})}
                    </label>

                    <div className='col-lg-2 '>
                      <Controller
                        control={methods.control}
                        name="show.no_adds_days_before"
                        render={({ field: { onChange, name, value } }) => (
                        <NumericFormat
                            id="no_adds_days_before"
                            value={Number(value)}
                            className='form-control form-control-sm  mb-2 mb-lg-0  fs-6 min-h-20px py-1'
                            type="text"
                            name={name}
                            decimalScale={0}
                            valueIsNumericString={true} // Allow only numeric input
                            allowNegative={false} // disallow negative values
                            onValueChange={(e)=>{
                              onChange(e.value)
                            }}
                        />
                        )}
                      />
                    </div>

                    <label
                      className='col-lg-1 col-form-label  fs-5 py-1 w-125px'
                      htmlFor='no_adds_time_of_day'
                      data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.STOPWEBADDSDAYSBEFOREAT"
                    >
                      {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.DAYSBEFORE'})}
                    </label>
                    <div className='col-lg-3'>
                      <div className='input-group date'>
                        <Controller
                          name='show.no_adds_time_of_day'
                          render={({field: {onChange, value, name}}) => (
                            <Flatpickr
                              className='form-control form-control-sm  fs-6 min-h-20px py-1'
                              id='no_adds_time_of_day'
                              name={name}
                              autoComplete={"off"}
                              value={value && value !== "00:00:00" ? value : null}
                              placeholder='Select Time'
                              options={time_options_12hours}
                              onClose={(value) => setTime(value, onChange)}
                              onChange={(value) => setTime(value, onChange)}
                            />
                          )}
                        />
                        <div className='input-group-append'>
                          <label htmlFor='no_adds_time_of_day' className='date-label'
                            onClick={(e) => {
                              e.stopPropagation(); 
                              document.getElementById('no_adds_time_of_day')._flatpickr.open(); 
                            }}
                          >
                            <span className='input-group-text'>
                              <i className='la la-clock-o'></i>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-2'>
                    <label
                      className='col-lg-3 col-form-label  fs-5 py-1'
                      htmlFor='no_drops_days_before'
                      data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.STOPWEBDROPS"
                    >
                      {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.STOPWEBDROPS'})}
                    </label>

                    <div className='col-lg-2 '>
                      <Controller
                        control={methods.control}
                        name="show.no_drops_days_before"
                        render={({ field: { onChange, name, value } }) => (
                        <NumericFormat
                            id="no_drops_days_before"
                            value={Number(value)}
                            className='form-control form-control-sm  mb-2 mb-lg-0  fs-6 min-h-20px py-1'
                            type="text"
                            name={name}
                            decimalScale={0}
                            valueIsNumericString={true} // Allow only numeric input
                            allowNegative={false} // disallow negative values
                            onValueChange={(e)=>{
                              onChange(e.value)
                            }}
                        />
                        )}
                    />
                    </div>

                    <label
                      className='col-lg-1 col-form-label  fs-5 py-1 w-125px'
                      htmlFor='no_drops_time_of_day'
                      data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.STOPWEBDROPSDAYSBEFOREAT"
                    >
                      {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.DAYSBEFORE'})}
                    </label>
                    <div className='col-lg-3'>
                      <div className='input-group date'>
                        <Controller
                          name='show.no_drops_time_of_day'
                          render={({field: {onChange, value, name}}) => (
                            <Flatpickr
                              className='form-control form-control-sm  fs-6 min-h-20px py-1'
                              id='no_drops_time_of_day'
                              name={name}
                              autoComplete={"off"}
                              value={value && value !== "00:00:00" ? value : null}
                              placeholder='Select Time'
                              options={time_options_12hours}
                              onClose={(value) => setTime(value, onChange)}
                              onChange={(value) => setTime(value, onChange)}
                            />
                          )}
                        />
                        <div className='input-group-append'>
                          <label htmlFor='no_drops_time_of_day' className='date-label'
                            onClick={(e) => {
                              e.stopPropagation(); 
                              document.getElementById('no_drops_time_of_day')._flatpickr.open(); 
                            }}
                          >
                            <span className='input-group-text'>
                              <i className='la la-clock-o'></i>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-2'>
                    <div className='col-lg-4 d-flex align-items-center'>
                      <div className='form-check-sm form-check-custom  me-2'>
                        <input
                          {...methods.register('show.charge_drop_fee_on_web', {
                            required: false,
                          })}
                          className='form-check-input'
                          type='checkbox'
                          id='charge_drop_fee_on_web'
                        />
                      </div>
                      <label
                        className='col-form-label  fs-5 py-1 ignore-max-width'
                        htmlFor='charge_drop_fee_on_web'
                        data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.CHARGEDROPFEEONWEB"
                      >
                        {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.CHARGEDROP'})}
                      </label>
                    </div>

                    <div className='col-lg-4 d-flex align-items-center'>
                      <div className='form-check-sm form-check-custom  me-2'>
                        <input
                          {...methods.register('show.charge_add_fee_on_web', {
                            required: false,
                          })}
                          className='form-check-input'
                          type='checkbox'
                          id='charge_add_fee_on_web'
                        />
                      </div>
                      <label
                        className='col-form-label  fs-5 py-1 ignore-max-width'
                        htmlFor='charge_add_fee_on_web'
                        data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.CHARGEADDFEEONWEB"
                      >
                        {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.CHARGEADD'})}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          {/* END Column 1 */}
          <div className='col-lg-4'>
            {/* Column 2 */}
            <fieldset>
              <legend className='fs-5 fw-bold d-flex'>
                {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.GROUPLABEL.WEBORDERS'})} <b></b>
              </legend>

              <div className='row mb-2'>
                <label
                  className='col-lg-5 col-form-label  fs-5 py-1'
                  htmlFor='order_delivery_cutoff_time'
                  data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBORDERS.LABEL.DELIVERYCUTOFFTIME"
                >
                  {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.DELIVERYCUTOFF'})}
                </label>
                <div className='col-lg-5'>
                  <div className='input-group date'>
                    <Controller
                      name='show.order_delivery_cutoff_time'
                      render={({field: {onChange, value, name}}) => (
                        <Flatpickr
                        //   disabled = {true} // Not disabled in 4D
                          className='form-control form-control-sm  fs-6 min-h-20px py-1'
                          id='order_delivery_cutoff_time'
                          name={name}
                          autoComplete={"off"}
                          value={value && value !== "00:00:00" ? value : null}
                          placeholder='Select Time'
                          options={time_options_12hours}
                          onClose={(value) => setTime(value, onChange)}
                          onChange={(value) => setTime(value, onChange)}
                        />
                      )}
                    />
                    <div className='input-group-append'>
                      <label htmlFor='order_delivery_cutoff_time' className='date-label'
                        onClick={(e) => {
                          e.stopPropagation(); 
                          document.getElementById('order_delivery_cutoff_time')._flatpickr.open(); 
                        }}
                      >
                        <span className='input-group-text'>
                          <i className='la la-clock-o'></i>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <p style={{paddingLeft: '15px'}}>
                <em>{intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.WEBORDERS'})}</em>
              </p>

              <div className='row mb-2'>
                <div className='col-lg-12'>
                  <textarea
                    id='order_delivery_cutoff_message'
                    {...methods.register('show.order_delivery_cutoff_message', {
                      required: false,
                    })}
                    name='CUTOFFTIME'
                    rows='2'
                    className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                    placeholder={intl.formatMessage({
                      id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.CUTOFFTIME',
                    })}
                    onChange={e =>  methods.setValue('show.order_delivery_cutoff_message',e.target.value,{shouldDirty:true})}
                  ></textarea>
                </div>
                {/* Column 2 */}
                <div className='col-lg-12 d-flex align-items-center mt-4'>
                  <div className='form-check-sm form-check-custom  me-2'>
                    <input
                      {...methods.register('disallowwebentryreason', {
                        onChange: handleShow,
                      })}
                      className='form-check-input'
                      type='checkbox'
                      id='disallowwebentryreason'
                    />
                  </div>
                  <label
                    className='col-form-label  fs-5 py-1 ignore-max-width'
                    htmlFor='disallowwebentryreason'
                    data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBORDERS.LABEL.DISALLOWWEBENTRIES"
                  >
                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.DISALLOWWEB'})}
                  </label>
                </div>

                <div className='col-lg-12'>
                  <textarea
                    {...methods.register('show.disallowwebentryreason', {
                      required: false,
                    })}
                    readOnly
                    id=''
                    name=''
                    rows='1'
                    className='form-control form-control-sm mb-2 mb-lg-0 fs-6 py-1'
                  ></textarea>
                  {methods.watch('show.publish_on_web') &&
                   methods.watch('show.suppress_on_web') &&
                   methods.getValues('show.disallowwebentryreason') === '' &&
                   !methods.getValues('disallowwebentryreason')
                    ? (
                    <label
                      className='col-lg col-form-label  fw-bold fs-5 py-1 me-5 text-danger'
                    >
                      {intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.WEBENTRIESENABLED'})}
                    </label>
                  ) : (
                    ''
                  )}
                </div>
                {/* </div> */}
                {/* END Column 2 */}
              </div>
            </fieldset>
          </div>
          <div className='row mb-2'>
            <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='web_post_entry_fee' data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.WEBPOSTENTRY">
              {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.WEBPOST'})}
            </label>
            <div className='col-lg-3'>
              {props.form_meta.show_fees && (
                <Controller
                  name='show.web_post_entry_fee'
                  render={({field: {onChange, value, name}}) => (
                    <Select
                      options={props.form_meta.show_fees}
                      menuPosition='fixed'
                      value={props.form_meta.show_fees.find((ss) => ss.value === value) ?? ""}
                      id='web_post_entry_fee'
                      name={name}
                      isSearchable={true}
                      onChange={(show_fees) => {
                        onChange(show_fees.value)
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                      })}
                      styles={{...reactSelectStyles,  menuPortal: base => ({ ...base, zIndex: 9999 })}}
                      placeholder='Select'
                    />
                  )}
                />
              )}
            </div>

            <label
              className='col-lg-2 col-form-label  fs-5 py-1 w-125px'
              htmlFor='web_post_entry_date'
              data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.CHARGEAFTER"
            >
              <span className=''>
                {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.CHARGEAFTER'})}
              </span>
            </label>

            <div className='col-lg-2 w-175px'>
              <div className='input-group date'>
                <Controller
                    name='show.web_post_entry_date'
                    render={({ field: { onChange, value, name } }) => {
                        const formattedValue = (value && moment(value).isValid())
                            ? moment(value).format('MM/DD/YYYY')
                            : '';

                        return (
                            <Flatpickr
                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                id='web_post_entry_date'
                                name={name}
                                autoComplete="off"
                                value={formattedValue}
                                placeholder='MM/DD/YYYY'
                                options={date_options}
                                onClose={(value, dateStr, instance) => {
                                    const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                    if (!isValidDate) {
                                        instance.input.value = ''; // Clear the input if the date is invalid
                                    }

                                    // Use setDate with dateStr and onChange
                                    setDate(dateStr, onChange);
                                }}
                                onChange={(value, dateStr, instance) => {
                                  const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                  if (!isValidDate) {
                                      instance.input.value = ''; // Clear the input if the date is invalid
                                  }

                                  // Use setDate with dateStr and onChange
                                  setDate(dateStr, onChange);
                                }}
                                onInput={(e) => { maskDateInput(e) }}
                            />
                        );
                    }}
                />
                <div className='input-group-append'>
                  <label htmlFor='web_post_entry_date' className='date-label'
                    onClick={(e) => {
                      e.stopPropagation(); 
                      document.getElementById('web_post_entry_date')._flatpickr.open(); 
                    }}
                  >
                    <span className='input-group-text'>
                      <i className='la la-calendar'></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <label
              className='col-lg-1 col-form-label  fs-5 py-1 w-25px'
              htmlFor='web_post_entry_time'
              data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.AT"
            >
              {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.AT'})}
            </label>
            <div className='col-lg-2 w-150px'>
              <div className='input-group date'>
                <Controller
                  name='show.web_post_entry_time'
                  render={({field: {onChange, value, name}}) => (
                    <Flatpickr
                      className='form-control form-control-sm  fs-6 min-h-20px py-1'
                      id='web_post_entry_time'
                      name={name}
                      autoComplete={"off"}
                      value={value && value !== "00:00:00" ? value : null}
                      placeholder='Select Time'
                      options={time_options_12hours}
                      onClose={(value) => setTime(value, onChange)}
                      onChange={(value) => setTime(value, onChange)}
                    />
                  )}
                />
                <div className='input-group-append'>
                  <label htmlFor='web_post_entry_time' className='date-label'
                    onClick={(e) => {
                      e.stopPropagation(); 
                      document.getElementById('web_post_entry_time')._flatpickr.open(); 
                    }}
                  >
                    <span className='input-group-text'>
                      <i className='la la-clock-o'></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-lg-3 d-flex align-items-center'>
              <div className='form-check-sm form-check-custom  me-2'>
                <input
                  {...methods.register('show.disallow_agent_signature', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='disallow_agent_signature'
                />
              </div>
              <label
                className='col-form-label  fs-5 py-1 ignore-max-width'
                htmlFor='disallow_agent_signature'
                data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.DISALLOWAGENTSIGNATURE"
              >
                {intl.formatMessage({
                  id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.DISALLOWAGENTSIGNATURE',
                })}
              </label>
            </div>
            <div className='col-lg-3 d-flex align-items-center'>
              <div className='form-check-sm form-check-custom  me-2'>
                <input
                  {...methods.register('show.require_web_entry_code', {
                    required: false,
                  })}
                  className='form-check-input'
                  type='checkbox'
                  id='require_web_entry_code'
                />
              </div>
              <label
                className='col-form-label  fs-5 py-1 ignore-max-width'
                htmlFor='require_web_entry_code'
                data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.REQUIREWEBENTRYCODE"
              >
                {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.REQUIREWEB'})}
              </label>
            </div>
            <div className='col-lg-3 d-flex align-items-center'>
              <label className='col-lg-5 col-form-label  fs-5 py-1' htmlFor='web_entry_code' data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBSETTINGS.LABEL.WEBENTRYCODE">
                <span className=''>
                  {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.WEBENTRYCODE'})}
                </span>
              </label>

              <div className='col-lg-6 '>
                <input
                  {...methods.register('show.web_entry_code', {
                    required: false,
                  })}
                  id='web_entry_code'
                  type='text'
                  className='form-control form-control-sm  fs-6 min-h-20px py-1'
                  // placeholder={intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.WEBENTRYCODE'})}
                />
              </div>
            </div>
          </div>
        </div>
        {/* END Column Group */}

        {/* END Column Group */}

        <div className='form-group row mt-5'>
          {/* Column Group */}
          <div className='col-lg-12'>
            {/* Column 1 */}
            <fieldset>
              <legend className='fs-5 fw-bold d-flex'>
                {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.GROUPLABEL.WEBDEPOSITS'})}
                <b></b>
              </legend>

              <div className='row mb-2'>
                <div className='col-lg-2 d-flex align-items-center w-175px me-3'>
                  <div className='form-check-sm form-check-custom  me-2'>
                    <input
                      {...methods.register('show.web_deposit_required', {
                        onChange: resetDepositeData,
                      })}
                      className='form-check-input'
                      type='checkbox'
                      id='web_deposit_required'
                    />
                  </div>
                  <label
                    className='col-form-label  fs-5 py-1 ignore-max-width'
                    htmlFor='web_deposit_required'
                    data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBDEPOSITS.LABEL.DEPOSITSREQUIRED"
                  >
                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.DEPOSITREQUIRED'})}
                  </label>
                </div>

                <div className='col-lg-4 d-flex align-items-center'>
                  <div className='form-check-sm form-check-custom  me-2'>
                    <input
                      {...methods.register('show.web_require_credit_card_on_file', {
                        required: false,
                      })}
                      className='form-check-input'
                      type='checkbox'
                      id='web_require_credit_card_on_file'
                    />
                  </div>
                  <label
                    className='col-form-label  fs-5 py-1 ignore-max-width'
                    htmlFor='web_require_credit_card_on_file'
                    data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBDEPOSITS.LABEL.REQUIRECREDITCARDONFILE"
                  >
                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.REQUIRECC'})}
                  </label>
                </div>
              </div>

              <div className='row mb-2'>
                <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='web_deposit_type' data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBDEPOSITS.LABEL.REQUIRECREDITCARDONFILE">
                  {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.DEPOSITTYPE'})}
                </label>

                <div className='col-lg-4 '>
                  {props.form_meta.web_deposit_type && (
                    <Controller
                      name='show.web_deposit_type'
                      render={({field: {onChange, value, name}}) => (
                        <Select
                          options={props.form_meta.web_deposit_type}
                          menuPosition='fixed'
                          isDisabled={!methods.watch('show.web_deposit_required')}
                          value={props.form_meta.web_deposit_type.find((wd) => wd.value === value) ?? ""}
                          id='web_deposit_type'
                          name={name}
                          isSearchable={true}
                          onChange={(web_deposit_type) => {
                            onChange(web_deposit_type.value)
                            if (web_deposit_type.value === 'Custom Amount') {
                              methods.setValue('show.web_deposit_fixed_amount', 0)
                            }
                            if (web_deposit_type.value === 'Fixed Amount') {
                              methods.setValue('show.web_deposit_fees', 0)
                              methods.setValue('show.web_deposit_class', 0)
                            }
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                          })}
                          styles={{...reactSelectStyles,  menuPortal: base => ({ ...base, zIndex: 9999 })}}
                          placeholder='Select'
                        />
                      )}
                    />
                  )}
                </div>
                {!methods.watch('show.web_deposit_type') ? (
                  <label
                    className='col-lg col-form-label  fw-bold fs-5 py-1 me-5 text-danger' data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBDEPOSITS.LABEL.DEPOSITTYPE"
                  >
                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.DEPOSITTYPEMESSAGE'})}
                  </label>
                ) : (
                  ''
                )}
              </div>

              <div className='row mb-2'>
                <label
                  className='col-lg-2 col-form-label  fs-5 py-1'
                  htmlFor='web_deposit_fixed_amount'
                  data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBDEPOSITS.LABEL.FIXEDDEPOSIT"
                >
                  <span className=''>
                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.FIXEDDEPOSIT'})}
                  </span>
                </label>

                <div className='col-lg-4 '>
                  <Controller
                    control={methods.control}
                    name='show.web_deposit_fixed_amount'
                    render={({field: {onChange, name, value}}) => (
                      <NumericFormat
                        prefix={'$'}
                        allowNegative={false}
                        value={value !== '' ? Number(value) : ''}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        onValueChange={(e) => {
                          if (Number(e.value) !== Number(value)) {
                            onChange(e.value);
                          }
                        }}
                        onFocus={(e) => {
                          e.target.select(); // Highlight the value
                        }}
                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                        disabled={
                          !methods.watch('show.web_deposit_required') ||
                          methods.watch('show.web_deposit_type') === 'Custom Amount'
                            ? true
                            : false
                        }
                      />
                    )}
                  />
                </div>
              </div>

              <div className='row mb-2'>
                <label className='col-lg-2 col-form-label  fs-5 py-1' htmlFor='DEPOSITTYPE' data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBDEPOSITS.LABEL.CUSTOMDEPOSIT">
                  {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.CUSTOMDEPOSIT'})}
                </label>

                <div className='col-lg-2 d-flex align-items-center'>
                  <div className='form-check-sm form-check-custom  me-2'>
                    <input
                      {...methods.register('show.web_deposit_fees', {
                        required: false,
                      })}
                      disabled={
                        !methods.watch('show.web_deposit_required') ||
                        methods.watch('show.web_deposit_type') === 'Fixed Amount'
                          ? true
                          : false
                      }
                      className='form-check-input'
                      type='checkbox'
                      id='web_deposit_fees'
                    />
                  </div>
                  <label
                    className='col-form-label  fs-5 py-1 ignore-max-width'
                    htmlFor='web_deposit_fees'
                    data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBDEPOSITS.LABEL.FEEDEPOSIT"
                  >
                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.FEEDEPOSIT'})}
                  </label>
                </div>

                <div className='col-lg-3 d-flex align-items-center'>
                  <div className='form-check-sm form-check-custom  me-2'>
                    <input
                      {...methods.register('show.web_deposit_class', {
                        required: false,
                      })}
                      disabled={
                        !methods.watch('show.web_deposit_required') ||
                        methods.watch('show.web_deposit_type') === 'Fixed Amount'
                          ? true
                          : false
                      }
                      className='form-check-input'
                      type='checkbox'
                      id='web_deposit_class'
                    />
                  </div>
                  <label
                    className='col-form-label  fs-5 py-1 ignore-max-width'
                    htmlFor='web_deposit_class'
                    data-tooltip-id="SHOW.DETAIL.TAB.WEB.SECTION.WEBDEPOSITS.LABEL.CLASSDEPOSIT"
                  >
                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.WEB.LABEL.CLASSDEPOSIT'})}
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
          {/* END Column 1 */}
        </div>
        {/* END Column Group */}
      </div>
      {/* END Column Group */}
    </>
  )
}

export {WebTab}
