import {useState, memo, useEffect} from 'react'
import '../BarnDesigner/barn-designer.css'
import {
  stallTypeColorLookup,
  isStallDNRColor,
  isStallTackColor,
  isStallAvailableColor,
} from './CanvasUtility'

import {
  Circle as CircleIcon,
  Trash2,
  Copy,
  Minus,
  Lock,
  RectangleHorizontal,
  Palette,
} from 'lucide-react'

function MultipleElementSelectionPanel({
  setCanvasElements,
  selectedElement,
  canvasElements,
  getStallTextArea,
  getStallPoints,
  selectedShapeRef,
  lastAisleNumber,
  setLastAisleNumber,
  totalVerticalAisles,
  handleSelect,
  setLastStallNumber,
  setTotalVerticalAisles,
  totalHorizontalAisles,
  setTotalHorizontalAisles,
  lastStallNumber,
  stageRef,
  deleteElement,
  selectedElements,
  moveElementUp,
  moveElementDown,
  moveElementToFront,
  moveElementToBack,
  setSelectedElements,
  transformerRef,
  handleOnDuplicate,
  handleOnDelete,
  handleOnUnGroup,
  handleOnGroup,
  isGrouped,
  barnMetaData,
}) {
  const CANVAS_FONT_FAMILY = "'Helvetica Neue', Arial, sans-serif"

  const {stallTypes: stallTypesList, stallSizes: stallSizesList} = barnMetaData

  const [initalElementText, setInitalElementText] = useState('')
  const [initialFontSize, setInitialFontSize] = useState(1)
  const [initialStrokeWidth, setInitalStrokeWidth] = useState(1)
  const [initialLineWidth, setInitialLineWidth] = useState(1)
  // state for stall size and type
  const [stallSize, setStallSize] = useState(
    stallSizesList.find((size) => size.value === 'standard')['value']
  )
  const [stallType, setStallType] = useState(
    stallTypesList.find((type) => type.value === 'standard')['value']
  )

  useEffect(() => {
    setInitalElementText(element.children?.find((child) => child.type === 'text')?.text || '')
    setInitialFontSize(
      element.isGroup
        ? element.children[0].children?.find((child) => child.type === 'text')?.fontSize || 1
        : element.children?.find((child) => child.type === 'text')?.fontSize || 1
    )
    setInitalStrokeWidth(
      element.isGroup
        ? element.children[0].children?.[0]?.strokeWidth || 1
        : element.children?.[0]?.strokeWidth || 1
    )

    setInitialLineWidth(
      element.isGroup
        ? element.children[0].children?.[0]?.strokeWidth || 1
        : element.children?.[0]?.strokeWidth || 1
    )

    setStallSize(stallSizesList.find((size) => size.value === 'standard')['value'])
    setStallType(stallTypesList.find((type) => type.value === 'standard')['value'])
  }, [selectedElement, selectedElements])

  const element = canvasElements.find((el) => el.id === selectedElement)

  if (!element) {
    return null
  }

  // --------------------------- IMPORTANT ELEMENTS CHECK ---------------------------//
  const isStandaloneLine =
    element?.children?.[0]?.type === 'line' && !element.isStall && element.shapeType !== 'triangle'
  const isTextElement = element.children?.length === 1 && element.children[0].type === 'text'
  const isBasicShape =
    element.children?.[0]?.type === 'rectangle' ||
    element.children?.[0]?.type === 'circle' ||
    (element.children?.[0]?.type === 'line' && element.shapeType === 'triangle')
  const isTopBottomStall =
    element.isStall && (element.orientation === 'top' || element.orientation === 'bottom')
  const isLeftRightStall =
    element.isStall && (element.orientation === 'left' || element.orientation === 'right')

  const handleContainerClick = (e) => {
    e.stopPropagation()
  }

  const ColorPickerExpanded = ({onColorChange, initialColor = '#333333'}) => {
    const [selectedColor, setSelectedColor] = useState(initialColor)

    const colors = [
      '#333333', // Dark gray
      '#e9edef', // Default gray
      '#FFB6C1', // Light pink
      '#ADD8E6', // Light blue
      '#98FB98', // Pale green
      '#DDA0DD', // Plum
      '#F0E68C', // Khaki
      '#E6E6FA', // Lavender
      '#FFDAB9', // Peach
      '#D3D3D3', // Light gray
      '#FFFFFF', // White
      '#90EE90', // Light green
      '#FFA07A', // Light salmon
      '#f6f6f6', // Very Light gray
      '#86dcc2', // Alt Green
      '#cbc8f7', // Light Purple
    ]

    const handleColorClick = (color) => {
      setSelectedColor(color)
      onColorChange(color)
    }

    return (
      <div
        style={{
          marginTop: '4px',
          padding: '4px',
          background: 'white',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '4px',
          width: '170px',
        }}
      >
        {colors.map((color) => (
          <button
            key={color}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleColorClick(color)
            }}
            style={{
              width: '24px',
              height: '24px',
              backgroundColor: color,
              border: `2px solid ${color === selectedColor ? '#333' : '#ddd'}`,
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            title={color}
            onMouseDown={(e) => e.stopPropagation()}
          />
        ))}
      </div>
    )
  }

  // ===========================HANDLE FILL COLOR CHANGE =================================//
  const handleFillColorChange = (color) => {
    setCanvasElements((prev) =>
      prev.map((el) => {
        if (selectedElements.includes(el.id)) {
          if (el.isGroup) {
            // Handle grouped elements
            // return {
            //   ...el,
            //   children: el.children.map((groupChild) => ({
            //     ...groupChild,
            //     children: groupChild.children?.map((child) => {
            //       console.log("🚀 ~ children:groupChild.children?.map ~ child:", child)

            //       if (child.type === 'line' && child.closed) {
            //         return {...child, fill: color}
            //       }
            //       if (['rectangle', 'circle'].includes(child.type)) {
            //         return {...child, fill: color}
            //       } else if (
            //         child.type === 'text' &&
            //         (el.isGroup
            //           ? el.children.every(
            //               (child) =>
            //                 (child.isStall === undefined || child.isStall === false) &&
            //                 (child.isLine === undefined || child.isLine === false) &&
            //                 (child.isAisle === undefined || child.isAisle === false)
            //             )
            //           : (el.isStall === undefined || el.isStall === false) &&
            //             (el.isLine === undefined || el.isLine === false) &&
            //             (el.isAisle === undefined || el.isAisle === false))
            //       ) {
            //         return {...child, fill: color}
            //       }
            //       return child
            //     }),
            //   })),
            // }
            return {
              ...el,
              children: el.children.map((groupChild) => {
                if (
                  groupChild.id.startsWith('text-element-') &&
                  groupChild.children[0].type === 'text' &&
                  groupChild.children.length === 1
                ) {
                  return {
                    ...groupChild,
                    children: groupChild.children.map((child) => {
                      if (child.type === 'text') {
                        return {...child, fill: color}
                      }
                      return child
                    }),
                  }
                }
                return {
                  ...groupChild,
                  children: groupChild.children?.map((child) => {
                     // Skip color change for badge elements
                  if (child.isBadge || child.isBadgeText) {
                    return child
                  }
                    if (child.type === 'line' && child.closed) {
                      return {...child, fill: color}
                    }
                    if (['rectangle', 'circle'].includes(child.type)) {
                      return {...child, fill: color}
                    }
                    return child
                  }),
                }
              }),
            }
          } else {
            // Handle non-grouped elements
            return {
              ...el,
              children: el.children.map((child) => {
                // Skip color change for badge elements
              if (child.isBadge || child.isBadgeText) {
                return child
              }
                if (child.type === 'line' && child.closed) {
                  return {...child, fill: color}
                }
                if (['rectangle', 'circle'].includes(child.type)) {
                  return {...child, fill: color}
                } else if (
                  child.type === 'text' &&
                  (el.isStall === undefined || el.isStall === false) &&
                  (el.isLine === undefined || el.isLine === false) &&
                  (el.isAisle === undefined || el.isAisle === false) &&
                  !el.id.startsWith('element-')
                ) {
                  return {...child, fill: color}
                }

                return child
              }),
            }
          }
        }
        return el
      })
    )
  }

  // =================== RELATED TO THE BORDER CONTROL =======================//
  const handleStrokeWidth = (strokeWidth) => {
    if (strokeWidth > 0 && !isNaN(strokeWidth)) {
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (selectedElements.includes(el.id)) {
            if (el.isGroup) {
              return {
                ...el,
                children: el.children.map((groupChild) => ({
                  ...groupChild,
                  children: groupChild.children?.map((child) => {
                    if (child.type !== 'text') {
                      return {
                        ...child,
                        strokeWidth: strokeWidth,
                      }
                    } else if (
                      child.type === 'text' ||
                      (child.type === 'group' &&
                        (el.isGroup
                          ? el.children.every(
                              (child) =>
                                (child.isStall === undefined || child.isStall === false) &&
                                (child.isLine === undefined || child.isLine === false) &&
                                (child.isAisle === undefined || child.isAisle === false)
                            )
                          : (el.isStall === undefined || el.isStall === false) &&
                            (el.isLine === undefined || el.isLine === false) &&
                            (el.isAisle === undefined || el.isAisle === false)))
                    ) {
                      return {...child, strokeWidth: strokeWidth}
                    }
                    return child
                  }),
                })),
              }
            } else {
              return {
                ...el,
                children: el.children.map((child) => {
                  if (child.type !== 'text') {
                    return {
                      ...child,
                      strokeWidth: strokeWidth,
                    }
                  } else if (
                    child.type === 'text' &&
                    (el.isStall === undefined || el.isStall === false) &&
                    (el.isLine === undefined || el.isLine === false) &&
                    (el.isAisle === undefined || el.isAisle === false)
                  ) {
                    return {
                      ...child,
                      strokeWidth: strokeWidth,
                    }
                  }
                  return child
                }),
              }
            }
          }
          return el
        })
      )
    }
  }

  const BorderColorExpandedPallete = ({initialColor = '#333333', onBorderChange}) => {
    const [selectedColor, setSelectedColor] = useState(initialColor)
    const colors = [
      '#333333', // Dark gray
      '#666666', // Medium gray
      '#999999', // Light gray
      '#000000', // Black
      '#1a237e', // Dark blue
      '#4CAF50', // Green
      '#f44336', // Red
      '#2196F3', // Blue
      '#FF9800', // Orange
      '#9C27B0', // Purple
      '#795548', // Brown
      '#607D8B', // Blue gray
    ]
    const handleColorClick = (color) => {
      setSelectedColor(color)
      onBorderChange(color)
    }

    return (
      <div
        style={{
          marginTop: '4px',
          padding: '4px',
          background: 'white',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '4px',
          width: '170px',
        }}
      >
        {colors.map((color) => (
          <button
            key={color}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleColorClick(color)
            }}
            style={{
              width: '24px',
              height: '24px',
              backgroundColor: color,
              border: `2px solid ${color === selectedColor ? '#333' : '#ddd'}`,
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            title={color}
            onMouseDown={(e) => e.stopPropagation()}
          />
        ))}
      </div>
    )
  }

  // =================== HANDLE STROKE COLOR CHANGE =======================//
  const handleOnChangeStrokeColor = (color) => {
    if (color && color !== '') {
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (selectedElements.includes(el.id)) {
            if (el.isGroup) {
              // return {
              //   ...el,
              //   children: el.children.map((groupChild) => ({
              //     ...groupChild,
              //     children: groupChild.children?.map((child) => {
              //       if (child.type !== 'text') {
              //         return {
              //           ...child,
              //           stroke: color,
              //         }
              //       } else if (
              //         child.type === 'text' &&
              //         (el.isGroup
              //           ? el.children.every(
              //               (child) =>
              //                 (child.isStall === undefined || child.isStall === false) &&
              //                 (child.isLine === undefined || child.isLine === false) &&
              //                 (child.isAisle === undefined || child.isAisle === false)
              //             )
              //           : (el.isStall === undefined || el.isStall === false) &&
              //             (el.isLine === undefined || el.isLine === false) &&
              //             (el.isAisle === undefined || el.isAisle === false))
              //       ) {
              //         return {...child, stroke: color}
              //       }
              //       return child
              //     }),
              //   })),
              // }
              return {
                ...el,
                children: el.children.map((groupChild) => {
                  if (
                    groupChild.id.startsWith('text-element-') &&
                    groupChild.children[0].type === 'text' &&
                    groupChild.children.length === 1
                  ) {
                    return {
                      ...groupChild,
                      children: groupChild.children.map((child) => {
                        if (child.type === 'text') {
                          return {...child, stroke: color}
                        }
                        return child
                      }),
                    }
                  }

                  return {
                    ...groupChild,
                    children: groupChild.children?.map((child) => {
                      if (child.type === 'line' && child.closed) {
                        return {...child, stroke: color}
                      }
                      if (['rectangle', 'circle'].includes(child.type)) {
                        return {...child, stroke: color}
                      }
                      return child
                    }),
                  }
                }),
              }
            } else {
              return {
                ...el,
                children: el.children.map((child) => {
                  if (child.type !== 'text') {
                    return {
                      ...child,
                      stroke: color,
                    }
                  } else if (
                    child.type === 'text' &&
                    (el.isStall === undefined || el.isStall === false) &&
                    (el.isLine === undefined || el.isLine === false) &&
                    (el.isAisle === undefined || el.isAisle === false) &&
                    !el.id.startsWith('element-')
                  ) {
                    return {
                      ...child,
                      stroke: color,
                    }
                  }
                  return child
                }),
              }
            }
          }
          return el
        })
      )
    }
  }

  // =================== HANDLE FONT SIZE CHANGE =======================//
  const handleFontSizeChange = (fontSize) => {
    if (!isNaN(fontSize) && fontSize > 0) {
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (selectedElements.includes(el.id)) {
            if (el.isGroup) {
              return {
                ...el,
                children: el.children.map((groupChild) => ({
                  ...groupChild,
                  children: groupChild.children?.map((child) => {
                    if (child.isStall) {
                      const scaleX = el.scaleX || 1
                      const scaleY = el.scaleY || 1
                      const textArea = el.isStall
                        ? getStallTextArea(el.width, el.height, el.orientation)
                        : el.isText
                        ? {
                            x: el.width / 2,
                            y: el.height / 2,
                            // width: el.width - 20,
                            // height: el.height - 20,
                            width: undefined,
                            height: undefined,
                            offsetX: (el.width - 20) / 2,
                            offsetY: (el.height - 20) / 2,
                          }
                        : {
                            x: el.width / 2,
                            y: el.height / 2,
                            width: el.width - 20,
                            height: el.height - 20,
                            offsetX: (el.width - 20) / 2,
                            offsetY: (el.height - 20) / 2,
                          }

                      return {
                        ...el,
                        children: el.children.map((child) => {
                          if (child.type === 'text') {
                            return {
                              ...child,
                              ...textArea,
                              fontSize: fontSize,
                              // scaleX: 1 / scaleX,
                              // scaleY: 1 / scaleY,
                            }
                          }
                          return child
                        }),
                      }
                    }

                    if (child.type === 'text') {
                      return {
                        ...child,
                        fontSize: fontSize,
                      }
                    }
                    return child
                  }),
                })),
              }
            } else {
              const scaleX = el.scaleX || 1
              const scaleY = el.scaleY || 1
              const textArea = el.isStall
                ? getStallTextArea(el.width, el.height, el.orientation)
                : el.isText
                ? {
                    x: el.width / 2,
                    y: el.height / 2,
                    // width: el.width - 20,
                    // height: el.height - 20,
                    width: undefined,
                    height: undefined,
                    offsetX: (el.width - 20) / 2,
                    offsetY: (el.height - 20) / 2,
                  }
                : {
                    x: el.width / 2,
                    y: el.height / 2,
                    width: el.width - 20,
                    height: el.height - 20,
                    offsetX: (el.width - 20) / 2,
                    offsetY: (el.height - 20) / 2,
                  }

              return {
                ...el,
                children: el.children.map((child) => {
                  if (child.type === 'text') {
                    return {
                      ...child,
                      ...textArea,
                      fontSize: fontSize,
                      // scaleX: 1 / scaleX,
                      // scaleY: 1 / scaleY,
                    }
                  }
                  return child
                }),
              }
            }
          }
          return el
        })
      )
    }
  }

  const handleLineWidthChange = (updatedLineWidth) => {
    if (updatedLineWidth && !isNaN(updatedLineWidth)) {
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (selectedElements.includes(el.id)) {
            // Get the initial line template to use its base dash pattern
            const initialDash = [15, 15] // Original dash pattern from shapeTemplates
            const baseWidth = 5 // Original stroke width from shapeTemplates

            return {
              ...el,
              children: el.children.map((child) =>
                child.type === 'line'
                  ? {
                      ...child,
                      strokeWidth: updatedLineWidth,
                      // Scale dash pattern proportionally to width changes
                      dash: child.dash
                        ? initialDash.map((d) => d * (updatedLineWidth / baseWidth))
                        : undefined,
                    }
                  : child
              ),
            }
          }
          return el
        })
      )
    }
  }

  const isAllElementsInSelectionStall = selectedElements.every((elementId) => {
    // find the element in the canvasElements array
    const element = canvasElements.find((el) => el.id === elementId)
    return (
      element.isStall === true ||
      (element.isGroup === true && element.children.every((child) => child.isStall === true))
    )
  })

  console.log('isAllElementsStall', isAllElementsInSelectionStall)

  // =================== HANDLE STALL SIZE CHANGE =======================//
  const handleStallSizeChange = (e) => {
    const newSize = e.target.value
    setStallSize(newSize)

    // Create a proper size abbreviation mapping
    const sizeAbbreviations = {
      'small': 'S',
      'large': 'L',
      'standard': 'STD',
      'extra-large': 'XL',
    };
    
    // Get the appropriate abbreviation or use first letter as fallback
    const sizeAbbr = sizeAbbreviations[newSize] || newSize.charAt(0).toUpperCase();

    setCanvasElements((prev) => {
      return prev.map((el) => {
        // Handle groups with stalls
        if (el.isGroup && selectedElements.includes(el.id)) {
          return {
            ...el,
            // Update stallSize for all stall children in the group
            children: el.children.map((groupChild) => {
              if (groupChild.isStall) {
                // Get current stall text
                const textChild = groupChild.children?.find(child => child.type === 'text' && !child.isBadge);
                const currentText = textChild?.text || '';
                
                // Extract the stall number using regex - match "Stall" followed by one or more digits
                const stallPattern = /Stall\s+(\d+)/i;
                const stallMatch = currentText.match(stallPattern);
                
                let newText = currentText; // Default to keeping the current text
                
                if (stallMatch) {
                  const stallNumber = stallMatch[1]; // The captured number
                  
                  if (newSize === 'standard') {
                    // For standard size, just use the stall number without size indicator
                    newText = `Stall ${stallNumber}`;
                  } else {
                    // For non-standard sizes, format with the size indicator
                    newText = `Stall ${stallNumber} ${sizeAbbr}`;
                  }
                }
                
                return {
                  ...groupChild,
                  stallSize: newSize,
                  // Also update the name property if it exists
                  ...(groupChild.name ? { name: newText } : {}),
                  // Update child elements
                  children: groupChild.children.map(child => {
                    if (child.type === 'text' && !child.isBadge) {
                      return {
                        ...child,
                        text: newText
                      };
                    }
                    return child;
                  })
                };
              }
              return groupChild;
            }),
          };
        }
        // Handle individual stalls
        else if (selectedElements.includes(el.id) && el.isStall) {
          // Get current stall text
          const textChild = el.children?.find(child => child.type === 'text' && !child.isBadge);
          const currentText = textChild?.text || '';
          
          // Extract the stall number using regex - match "Stall" followed by one or more digits
          const stallPattern = /Stall\s+(\d+)/i;
          const stallMatch = currentText.match(stallPattern);
          
          let newText = currentText; // Default to keeping the current text
          
          if (stallMatch) {
            const stallNumber = stallMatch[1]; // The captured number
            
            if (newSize === 'standard') {
              // For standard size, just use the stall number without size indicator
              newText = `Stall ${stallNumber}`;
            } else {
              // For non-standard sizes, format with the size indicator
              newText = `Stall ${stallNumber} ${sizeAbbr}`;
            }
          }
          
          return {
            ...el,
            stallSize: newSize,
            // Also update the name property if it exists
            ...(el.name ? { name: newText } : {}),
            // Update the text child to show the size indicator
            children: el.children.map((child) => {
              if (child.type === 'text' && !child.isBadge) {
                return {
                  ...child,
                  text: newText
                };
              }
              return child;
            })
          };
        }
        return el;
      });
    });
  };

  // =================== HANDLE STALL Type CHANGE =======================//
  // const handleStallTypeChange = (e) => {
  //   const newStallType = e.target.value
  //   setStallType(newStallType)

  //   // Find the type object to get its color
  //   const stallTypeColor = stallTypeColorLookup[newStallType] || '#f0f4f8' // Default to light blue-gray if not found

  //   setCanvasElements((prev) =>
  //     prev.map((el) => {
  //       if (el.isGroup && selectedElements.includes(el.id)) {
  //         // Check if this group is selected and contains stalls
  //         return {
  //           ...el,
  //           // Map through the group's children
  //           children: el.children.map((groupChild) => {
  //             if (groupChild.isStall) {
  //               // Update the stall child with new type
  //               return {
  //                 ...groupChild,
  //                 stallType: newStallType,
  //                 // Update the stall's children to apply the new fill color
  //                 children: groupChild.children.map((child) => {
  //                   if (child.type === 'line') {
  //                     return {
  //                       ...child,
  //                       fill: stallTypeColor,
  //                     }
  //                   }
  //                   return child
  //                 }),
  //               }
  //             }
  //             return groupChild
  //           }),
  //         }
  //       } else if (selectedElements.includes(el.id)) {
  //         // Handle non-grouped elements (same as before)
  //         return {
  //           ...el,
  //           stallType: newStallType,
  //           children: el.children.map((child) => {
  //             if (child.type === 'line') {
  //               return {
  //                 ...child,
  //                 fill: stallTypeColor,
  //               }
  //             }
  //             return child
  //           }),
  //         }
  //       }
  //       return el
  //     })
  //   )
  // }

  const handleStallTypeChange = (e) => {

    const newStallType = e.target.value
    setStallType(newStallType)
  
    // Find the type object to get its color
    const typeColor = stallTypeColorLookup[newStallType] || '#f0f4f8'
    const typeObj = stallTypesList.find(type => type.value === newStallType)
    const typeLabel = typeObj?.label || newStallType
    const badgeText = typeLabel.charAt(0).toUpperCase()
  
    setCanvasElements((prev) =>
      prev.map((el) => {
        if (selectedElements.includes(el.id)) {
          // Handle individual stalls
          if (el.isStall) {
            // If type is standard, remove any existing badges
            if (newStallType === 'standard') {
              return {
                ...el,
                stallType: newStallType,
                children: el.children.filter(child => !child.isBadge && !child.isBadgeText)
              }
            }
  
            // For non-standard types, continue with badge creation/update
            const orientation = el.orientation || 'top'
            let badgePosition = {}
            
            // Set badge position based on orientation
            switch(orientation) {
              case 'top':
                badgePosition = { x: 20, y: el.height - 20 }; // bottom left
                break;
              case 'bottom':
                badgePosition = { x: 20, y: 20 }; // top left
                break;
              case 'right':
                badgePosition = { x: 20, y: 20 }; // top left
                break;
              case 'left':
                badgePosition = { x: el.width - 20, y: 20 }; // top right
                break;
              default:
                badgePosition = { x: 20, y: 20 }; // default to top left
            }
  
            const badgeRadius = 18;
            
            // Badge configuration
            const badgeConfig = {
              type: 'circle',
              x: badgePosition.x,
              y: badgePosition.y,
              radius: badgeRadius,
              fill: typeColor,
              stroke: '#333333',
              strokeWidth: 1,
              isBadge: true
            }
  
            // Badge text configuration
            const badgeTextConfig = {
              type: 'text',
              x: badgePosition.x,
              y: badgePosition.y,
              text: badgeText,
              fontSize: 32,
              fontStyle: 'bold',
              fill: '#ffffff',
              align: 'center',
              verticalAlign: 'middle',
              width: badgeRadius * 2,
              height: badgeRadius * 2,
              offsetX: badgeRadius,
              offsetY: badgeRadius,
              isBadge: true,
              isBadgeText: true,
              fontFamily: CANVAS_FONT_FAMILY,

            }
  
            // Create new children array with updated or added badge
            let newChildren = el.children.filter(child => !child.isBadge && !child.isBadgeText)
            newChildren.push(badgeConfig, badgeTextConfig)
  
            return {
              ...el,
              stallType: newStallType,
              children: newChildren
            }
          }
          // Handle groups containing stalls
          else if (el.isGroup) {
            return {
              ...el,
              children: el.children.map(groupChild => {
                if (groupChild.isStall) {
                  // If type is standard, remove any existing badges
                  if (newStallType === 'standard') {
                    return {
                      ...groupChild,
                      stallType: newStallType,
                      children: groupChild.children.filter(child => !child.isBadge && !child.isBadgeText)
                    }
                  }
  
                  // For non-standard types, add/update badges
                  const orientation = groupChild.orientation || 'top'
                  let badgePosition = {}
                  
                  // Set badge position based on orientation
                  switch(orientation) {
                    case 'top':
                      badgePosition = { x: 20, y: groupChild.height - 20 };
                      break;
                    case 'bottom':
                      badgePosition = { x: 20, y: 20 };
                      break;
                    case 'right':
                      badgePosition = { x: 20, y: 20 };
                      break;
                    case 'left':
                      badgePosition = { x: groupChild.width - 20, y: 20 };
                      break;
                    default:
                      badgePosition = { x: 20, y: 20 };
                  }
  
                  const badgeRadius = 18;
  
                  // Create new children array with updated or added badge
                  let newChildren = groupChild.children.filter(child => !child.isBadge && !child.isBadgeText)
                  newChildren.push({
                    type: 'circle',
                    x: badgePosition.x,
                    y: badgePosition.y,
                    radius: badgeRadius,
                    fill: typeColor,
                    stroke: '#333333',
                    strokeWidth: 1,
                    isBadge: true
                  }, {
                    type: 'text',
                    x: badgePosition.x,
                    y: badgePosition.y,
                    text: badgeText,
                    fontSize: 20,
                    fontStyle: 'bold',
                    fill: '#ffffff',
                    align: 'center',
                    verticalAlign: 'middle',
                    width: badgeRadius * 2,
                    height: badgeRadius * 2,
                    offsetX: badgeRadius,
                    offsetY: badgeRadius,
                    isBadge: true,
                    isBadgeText: true
                  })
  
                  return {
                    ...groupChild,
                    stallType: newStallType,
                    children: newChildren
                  }
                }
                return groupChild
              })
            }
          }
        }
        return el
      })
    )
  }

  // =================== HANDLE TACK STALL CHANGE =======================//
  const onChangeIsTackStall = (e) => {
    const isChecked = e.target.checked
    setCanvasElements((prev) =>
      prev.map((el) => {
        // Handle individual stalls
        if (selectedElements.includes(el.id) && el.isStall) {
          return {
            ...el,
            isTackStall: isChecked,
            // If tack is checked, ensure DNR is unchecked
            isDnrStall: isChecked ? false : el.isDnrStall,
            // Update children to apply the new fill color to the stall
            children: el.children.map((child) => {
              if (child.type === 'line') {
                return {
                  ...child,
                  fill: isChecked
                    ? isStallTackColor
                    : el.isDnrStall && !isChecked
                    ? isStallDNRColor
                    : isStallAvailableColor,
                }
              }
              return child
            }),
          }
        }
        // Handle groups with stalls
        else if (el.isGroup && selectedElements.includes(el.id)) {
          return {
            ...el,
            // Update isTackStall for all stall children in the group
            children: el.children.map((groupChild) => {
              if (groupChild.isStall) {
                return {
                  ...groupChild,
                  isTackStall: isChecked,
                  // If tack is checked, ensure DNR is unchecked
                  isDnrStall: isChecked ? false : groupChild.isDnrStall,
                  // Update children to apply the new fill color to the stall
                  children: groupChild.children.map((child) => {
                    if (child.type === 'line') {
                      return {
                        ...child,
                        fill: isChecked
                          ? isStallTackColor
                          : groupChild.isDnrStall && !isChecked
                          ? isStallDNRColor
                          : isStallAvailableColor,
                      }
                    }
                    return child
                  }),
                }
              }
              return groupChild
            }),
          }
        }
        return el
      })
    )
  }

  // =================== HANDLE DNR STALL CHANGE =======================//
  const onChangeIsDNR = (e) => {
    const isChecked = e.target.checked
    setCanvasElements((prev) =>
      prev.map((el) => {
        // Handle individual stalls
        if (selectedElements.includes(el.id) && el.isStall) {
          return {
            ...el,
            isDnrStall: isChecked,
            // If DNR is checked, ensure tack is unchecked
            isTackStall: isChecked ? false : el.isTackStall,
            // Update children to apply the new fill color to the stall
            children: el.children.map((child) => {
              if (child.type === 'line') {
                return {
                  ...child,
                  fill: isChecked
                    ? isStallDNRColor
                    : el.isTackStall && !isChecked
                    ? isStallTackColor
                    : isStallAvailableColor,
                }
              }
              return child
            }),
          }
        }
        // Handle groups with stalls
        else if (el.isGroup && selectedElements.includes(el.id)) {
          return {
            ...el,
            // Update isDnrStall for all stall children in the group
            children: el.children.map((groupChild) => {
              if (groupChild.isStall) {
                return {
                  ...groupChild,
                  isDnrStall: isChecked,
                  // If DNR is checked, ensure tack is unchecked
                  isTackStall: isChecked ? false : groupChild.isTackStall,
                  // Update children to apply the new fill color to the stall
                  children: groupChild.children.map((child) => {
                    if (child.type === 'line') {
                      return {
                        ...child,
                        fill: isChecked
                          ? isStallDNRColor
                          : groupChild.isTackStall && !isChecked
                          ? isStallTackColor
                          : isStallAvailableColor,
                      }
                    }
                    return child
                  }),
                }
              }
              return groupChild
            }),
          }
        }
        return el
      })
    )
  }

  return (
    <div
      className='element-selection-panel editor-sidebar'
      onClick={handleContainerClick}
      onMouseDown={handleContainerClick}
      style={{
        position: 'absolute',
        right: '0px',
        top: '61px',
        padding: '15px',
        backgroundColor: 'white',
        borderRadius: '0px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
        width: '210px', // inherited from editor-sidebar as it has rule of !important 210px
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        height: '100%',
      }}
    >
      {/* Stall Properties : will be visible only if all the elements in the selection are stalls */}
      {isAllElementsInSelectionStall && (
        <div className='editor-section' style={{marginBottom: '0px'}}>
          <h5 style={{marginBottom: '2px'}}>
            <RectangleHorizontal className='text-gray-600 mb-1 me-1' size={16} /> Stall Properties
          </h5>
        </div>
      )}

      {/* Stall Size Drop Down */}
      {isAllElementsInSelectionStall && (
        <div className='editor-section'>
          <h6>Stall Size</h6>
          <div>
            <select
              style={{
                width: '100%',
                padding: '3px',
                paddingLeft: '6px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                backgroundColor: '#fff',
                fontSize: '14px',
                color: '#333',
                appearance: 'menulist',
                cursor: 'pointer',
                boxSizing: 'border-box',
                height: 'auto',
              }}
              onChange={handleStallSizeChange}
              value={stallSize}
              onBlur={(e) => (e.target.style.boxShadow = '0 1px 2px rgba(0,0,0,0.05)')}
            >
              {stallSizesList.map((size) => (
                <option key={size.value} value={size.value}>
                  {size.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {/* Stall Type Drop Down */}
      {isAllElementsInSelectionStall && (
        <div className='editor-section'>
          <h6>Stall Type</h6>
          <div>
            <select
              style={{
                width: '100%',
                padding: '3px',
                paddingLeft: '6px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                backgroundColor: '#fff',
                fontSize: '14px',
                color: '#333',
                appearance: 'menulist',
                cursor: 'pointer',
                boxSizing: 'border-box',
                height: 'auto',
              }}
              onChange={handleStallTypeChange}
              value={stallType}
              onBlur={(e) => (e.target.style.boxShadow = '0 1px 2px rgba(0,0,0,0.05)')}
            >
              {stallTypesList.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      {/* Tack Stall Checkbox */}
      {isAllElementsInSelectionStall && (
        <div className='editor-section'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '5px',
            }}
          >
            <input
              type='checkbox'
              id='multiTackStallCheckbox'
              checked={selectedElements.every((id) => {
                const el = canvasElements.find((e) => e.id === id)
                if (!el) return false

                // For individual stalls
                if (el.isStall) {
                  return el.isTackStall === true
                }

                // For groups containing stalls
                if (el.isGroup) {
                  // Check if ALL stalls in the group are marked as tack stalls
                  return el.children.every((child) => !child.isStall || child.isTackStall === true)
                }

                return false
              })}
              onChange={onChangeIsTackStall}
              style={{
                marginRight: '8px',
                cursor: 'pointer',
              }}
            />
            <label
              htmlFor='multiTackStallCheckbox'
              style={{
                fontSize: '14px',
                cursor: 'pointer',
              }}
            >
              Tack Stall(s)
            </label>
          </div>
        </div>
      )}

      {/* DNR Checkbox */}
      {isAllElementsInSelectionStall && (
        <div className='editor-section'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '5px',
            }}
          >
            <input
              type='checkbox'
              id='multiDNRCheckbox'
              checked={selectedElements.every((id) => {
                const el = canvasElements.find((e) => e.id === id)
                if (!el) return false

                // For individual stalls
                if (el.isStall) {
                  return el.isDnrStall === true
                }

                // For groups containing stalls
                if (el.isGroup) {
                  // Check if ALL stalls in the group are marked as DNR
                  return el.children.every((child) => !child.isStall || child.isDnrStall === true)
                }

                return false
              })}
              onChange={onChangeIsDNR}
              style={{
                marginRight: '8px',
                cursor: 'pointer',
              }}
            />
            <label
              htmlFor='multiDNRCheckbox'
              style={{
                fontSize: '14px',
                cursor: 'pointer',
              }}
            >
              DNR Stall(s)
            </label>
          </div>
        </div>
      )}

      {isAllElementsInSelectionStall && <hr className='m-1' style={{borderColor: '#aaaaf6'}} />}

      {/* Font Size Increase Decrease */}
      {!selectedElements.some((el) => {
        if (el.startsWith('group-')) {
          // checking if the group's children include a line element based on id
          return element.children.some((child) => child.id && child.id.startsWith('line-'))
        }
        return el.startsWith('line-')
      }) && (
        <div className='editor-section '>
          <h5>
            <span
              style={{fontSize: '15px', fontWeight: 'normal'}}
              className='text-gray-600 mb-1 me-1'
            >
              Aa
            </span>{' '}
            Font Size
          </h5>
          <div>
            <input
              type='number'
              min={1}
              value={initialFontSize}
              onChange={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const value = e.target.value
                // Remove any non-digit characters
                const validValue = value.replace(/[^\d]/g, '')
                // Allow empty input during typing
                if (validValue === '') {
                  setInitialFontSize('')
                  return
                }
                let updatedFontSize = parseInt(validValue, 10)
                updatedFontSize = updatedFontSize < 1 ? 1 : updatedFontSize
                setInitialFontSize(updatedFontSize)
                handleFontSizeChange(updatedFontSize)
              }}
              onBlur={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const value = e.target.value
                // Allow empty input during typing
                if (value === '') {
                  setInitialFontSize(1)
                  return
                }
                const updatedFontSize = parseInt(e.target.value)
                handleFontSizeChange(updatedFontSize)
              }}
              onKeyDown={(e) => {
                // if arrow keys are pressed, up key +1 and down key -1

                if (e.key === 'ArrowUp') {
                  e.stopPropagation()
                  e.preventDefault()
                  const updatedFontSize = parseInt(initialFontSize) + 1
                  setInitialFontSize(updatedFontSize)
                  handleFontSizeChange(updatedFontSize)
                }
                if (e.key === 'ArrowDown') {
                  e.stopPropagation()
                  e.preventDefault()
                  const updatedFontSize = parseInt(initialFontSize) - 1
                  setInitialFontSize(updatedFontSize)
                  handleFontSizeChange(updatedFontSize)
                }
              }}
              step={1}
              style={{
                width: '100%',
                padding: '3px',
                paddingLeft: '6px',
                paddingRight: '6px',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            />
          </div>
        </div>
      )}

      {selectedElements.every(
        (el) => el.startsWith('line-')
        // ||
        // (el.startsWith('group-') &&
        // element.children.some((child) => child.id && child.id.startsWith('line-')))
      ) && <hr className='m-1' style={{borderColor: '#aaaaf6'}} />}

      {/* Show Line Width button only for lines group or multiple lines selected*/}
      {selectedElements.every(
        (el) => el.startsWith('line-')
        // ||
        // (el.startsWith('group-') &&
        // element.children.some((child) => child.id && child.id.startsWith('line-')))
      ) && (
        <div className='editor-section '>
          <h3>Line Width</h3>
          <div>
            <input
              type='number'
              min={1}
              value={initialLineWidth}
              onChange={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const value = e.target.value
                // Allow empty input during typing
                if (value === '') {
                  setInitialLineWidth('')
                  return
                }
                const updatedLineWidth = parseInt(e.target.value)
                setInitialLineWidth(updatedLineWidth)
                handleLineWidthChange(updatedLineWidth)
              }}
              onBlur={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const updatedLineWidth = parseInt(e.target.value)
                setInitialLineWidth(updatedLineWidth)
                handleLineWidthChange(updatedLineWidth)
              }}
              onKeyDown={(e) => {
                // if arrow keys are pressed, up key +1 and down key -1
                if (e.key === 'ArrowUp') {
                  e.stopPropagation()
                  e.preventDefault()
                  const updatedLineWidth = parseInt(initialLineWidth) + 1
                  setInitialLineWidth(updatedLineWidth)
                  handleLineWidthChange(updatedLineWidth)
                }
                if (e.key === 'ArrowDown') {
                  e.stopPropagation()
                  e.preventDefault()
                  const updatedLineWidth = parseInt(initialLineWidth) - 1
                  setInitialLineWidth(updatedLineWidth)
                  handleLineWidthChange(updatedLineWidth)
                }
              }}
              step={1}
              style={{
                width: '100%',
                padding: '4px',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            />
          </div>
        </div>
      )}

      {handleOnGroup !== null && <hr className='m-1' style={{borderColor: '#aaaaf6'}} />}

      {handleOnGroup !== null && (
        <div className='editor-section'>
          <h5>
            <Lock size={16} className='text-gray-600 mb-1 me-1' /> Group / Ungroup
          </h5>

          <div className='shape-grid' style={{display: 'block'}}>
            <button
              className='shape-button'
              onMouseDown={(e) => e.stopPropagation()}
              title={isGrouped ? 'Ungroup' : 'Group'}
              onClick={isGrouped ? handleOnUnGroup : handleOnGroup}
              style={{width: '100%', borderColor: '#d5d5d5'}}
            >
              <span style={{marginTop: '0px'}}>{isGrouped ? 'Ungroup' : 'Group'}</span>
            </button>
          </div>
        </div>
      )}

      {!selectedElements.some((el) => {
        if (el.startsWith('group-')) {
          // checking if the group's children include a line element based on id
          return element.children.some((child) => child.id && child.id.startsWith('line-'))
        }
        return el.startsWith('line-')
      }) && <hr className='m-1' style={{borderColor: '#aaaaf6'}} />}

      {/* Background Fill */}
      {
        // Show fill color picker only if the element is not a line
        !selectedElements.some((el) => {
          if (el.startsWith('group-')) {
            // checking if the group's children include a line element based on id
            return element.children.some((child) => child.id && child.id.startsWith('line-'))
          }
          return el.startsWith('line-')
        }) && (
          <div className='editor-section'>
            <h5>
              <Palette className='text-gray-600 mb-1 me-1' size={16} /> Background Color
            </h5>

            <div style={{width: '100%'}}>
              {/* Div showing currently filled color */}
              <ColorPickerExpanded
                initialColor={
                  element.isGroup
                    ? element.children?.[0]?.children?.[0]?.fill || '#e9edef'
                    : element.children?.[0]?.fill || '#e9edef'
                }
                onColorChange={handleFillColorChange}
              />
            </div>
          </div>
        )
      }

      {!isStandaloneLine && <hr className='m-1' style={{borderColor: '#aaaaf6'}} />}

      {!isStandaloneLine && (
        <div className='editor-section'>
          <h5 style={{marginBottom: '2px'}}>
            <RectangleHorizontal className='text-gray-600 mb-1 me-1' size={16} />
            Border Properties
          </h5>
        </div>
      )}

      {!isStandaloneLine && (
        <div className='editor-section '>
          <h6>
            <Minus className='text-gray-600 mb-1 me-1' size={16} /> Width
          </h6>
          <input
            type='number'
            value={initialStrokeWidth}
            onChange={(e) => {
              let value = e.target.value

              // Handle empty input
              if (value === '') {
                setInitalStrokeWidth('')
                return
              }

              // Convert to number and apply constraints
              let updatedStrokeWidth = parseInt(value.replace(/[^0-9]/g, ''))

              // Constrain between 1 and 20
              if (updatedStrokeWidth < 1) {
                updatedStrokeWidth = 1
              } else if (updatedStrokeWidth > 50) {
                updatedStrokeWidth = 50
              }

              setInitalStrokeWidth(updatedStrokeWidth)
              handleStrokeWidth(updatedStrokeWidth)
            }}
            onKeyDown={(e) => {
              // if arrow keys are pressed, up key +1 and down key -1

              if (e.key === 'ArrowUp') {
                e.stopPropagation()
                e.preventDefault()
                const updatedStrokeWidth = parseInt(initialStrokeWidth) + 1
                setInitalStrokeWidth(updatedStrokeWidth)
                handleStrokeWidth(updatedStrokeWidth)
              }
              if (e.key === 'ArrowDown') {
                e.stopPropagation()
                e.preventDefault()
                const updatedStrokeWidth = parseInt(initialStrokeWidth) - 1
                setInitalStrokeWidth(updatedStrokeWidth)
                handleStrokeWidth(updatedStrokeWidth)
              }
            }}
            step={1}
            style={{
              width: '100%',
              padding: '3px',
              paddingRight: '6px',
              paddingLeft: '6px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
        </div>
      )}

      {!isStandaloneLine && (
        <div className='editor-section'>
          <h6>
            <Palette className='text-gray-600 mb-1 me-1' size={16} /> Color
          </h6>

          <BorderColorExpandedPallete
            // initialColor={element.children?.[0]?.stroke || '#f44336'}
            initialColor={
              element.isGroup
                ? element.children?.[0]?.children?.[0]?.stroke || '#333333'
                : element.children?.[0]?.stroke || '#333333'
            }
            onBorderChange={handleOnChangeStrokeColor}
          />
        </div>
      )}

      <hr className='m-1' style={{borderColor: '#aaaaf6'}} />

      {/* Other Operations */}
      <div className='editor-section'>
        <div className='shape-grid'>
          <button
            className='shape-button'
            onMouseDown={(e) => e.stopPropagation()}
            title='Duplicate'
            onClick={handleOnDuplicate}
          >
            <Copy size={24} />
            <span>Duplicate</span>
          </button>
          <button
            className='shape-button'
            style={{color: '#ff4444'}}
            onMouseDown={(e) => e.stopPropagation()}
            title='Delete'
            onClick={handleOnDelete}
          >
            <Trash2 size={24} />
            <span>Delete</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default memo(MultipleElementSelectionPanel)
