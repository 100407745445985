import { useMemo, useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import { useFormContext, Controller } from "react-hook-form";
import { useAppSelector } from '../../../../redux/hooks';
import { SearchInput } from '../SearchInput';
import axios from 'axios';

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'
import { MembershipDetailForm } from './MembershipDetailForm';
import { ManualOverrideForm } from './ManualOverrideForm';
import { SignatureAuthRequestForm } from './SignatureAuthRequestForm';
import { PaperEntryConfirmationForm } from './PaperEntryConfirmationForm';
import { PersonLookupForm } from './PersonLookupForm';
import { renderIcon, getExtraNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers'
import { PatternFormat } from 'react-number-format';
import { USEFLookupMismatchModal } from '../../../people/Detail/AdditonalTab/USEFLookupMismatchModal';
import Select from 'react-select'
import useAccessChecker from '../../../../modules/hooks/use-access-checker'
import { updateAGGridWithoutFlickering } from '../../../../modules/sgl-utils/agGridHelpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";
import earthLogo from '../../../../../_metronic/assets/CustomIcons/blue_earth.png'
import { useAlert, useConfirm } from '../../../../modules/sgl-utils/DialogsProvider';
import { reactSelectStyles } from '../../../../modules/sgl-utils/fieldControls';

// Constants
import { date_options, setDate, maskDateInput } from '../../../../modules/sgl-utils/fieldControls';
import DateRenderer from '../../../../modules/output-listing/renderers/DateRenderer';
import { useLoadingOverlay } from '../../../../modules/sgl-utils/DialogsProvider';
import { FeiEcNameMismatchAlert } from '../../../people/Detail/AdditonalTab/FeiEcNameMismatchAlert';
import TaxFormInformationModal from '../../../people/Detail/AdditonalTab/TaxFormInformaionModal';
import { allowManualOverrideForRTO, deleteMembershipOverride, isEcCoachMembershipValidByShowLevel, isEcMembershipValid, controlLookupFields } from '../../../people/Detail/AdditonalTab/MembershipFunctions';
import { AdditionalManualOverrideForm } from '../../../people/Detail/AdditonalTab/AdditionalManualOverrideForm';
import { DateStringEditor } from '../../../../modules/sgl-utils/DateStringEditor';
import parsePhoneNumber from 'libphonenumber-js'
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';
import { PhoneNumberFormatter, usePhoneNumberFormatter } from '../../../../modules/sgl-utils/Formatters'
import { handleTaxStatusFieldDisable } from '../../../../modules/sgl-utils/SglFunctions';

const PersonSection = (props) => {
    const intl = useIntl();
    const governingOrganization = useAppSelector(state => state.showCompany.governing_organization)

    const methods = useFormContext();
    const { watch } = methods
    const loadingOverlay = useLoadingOverlay()
    const inputRef = useRef(null);    

    const watchPersonDOB = watch('linked_people['+ props.person_id +'].people_data.dob')

    const [selectedRow, setSelectedRow] = useState(false)
    const [showManualOverrideForm, setShowManualOverrideForm] = useState(false)
    const [showMembershipDetailForm, setShowMembershipDetailForm] = useState(false)
    const [showSignAuthRequestForm, setShowSignAuthRequestForm] = useState(false)
    const [showManualSignatureForm, setShowManualSignatureForm] = useState(false)
    const [login_id, setLoginId] = useState();
    const [isLocked, setIsLocked] = useState(true)
    const [gridRef, setGridRef] = useState();
    const [rowData, setRowData] = useState();
    const [rider, setRider] = useState();
    const [showPersonLookup, setShowPersonLookup] = useState(false)
    const [searchField, setSearchField] = useState("")
    const [showUsefMismatchForm, setShowUsefMismatchForm] = useState(false);
    const [usefMismatch, setUsefMismatch] = useState(false);
    const [personData, setPersonData] = useState({});
    const [mismatchPerson, setMismatchPerson] = useState({});
    const [ECNameMismatch, setECNameMismatch] = useState(false);
    const [ecMismatch, setEcMismatch] = useState(false);
    const [FEINameMismatch, setFEINameMismatch] = useState(false);
    const [feiMismatch, setFeiMismatch] = useState(false);
    const [riderUserStatus, setRiderUserStatus] = useState('');
    const currentShowEndDate = useAppSelector(state => state.currentShow.end_date);
    const customer_id = useAppSelector(state=> state.showCompany.company_id)
    const alertDialog = useAlert()
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const [person_search_status, setPersonSearchStatus] = useState('');
    const [search_count_status, setSearchCountStatus] = useState('');
    const [default_org, setDefaultOrg] = useState(governingOrganization);
    const [people_search_list, setPeopleSearchList] = useState([]);
    const [lookupResponse, setLookupResponse] = useState([]);
    const [entryAuthorizationRequest, setEntryAuthorizationRequest] = useState({});
    const confirmDialog = useConfirm()
    const [disableFields, setDisableFields] = useState(!props.person_id);
    const watchTrainerAccount = watch("entry.trainer_account")
    const show_financials_locked = methods.watch('show.islocked')
    const { hasSpecialPermissionToAccess } = useAccessChecker()
    const phoneFormatter = usePhoneNumberFormatter()
    const [membershipCardUrl, setMembershipCardUrl] = useState('')


    const [dialogData, setDialogData] = useState({
        first_name: '',
        last_name: '',
        city: '',
        state:'',
        usef_no: 0,
        search_term: '',
        company_name: ''
    })
    const [personStatus, setPersonStatus] = useState(''); // use to store status like EC Non-Active Coach for RTO

    methods.watch()

    useEffect(() => {
        if (gridRef) {
          gridRef.setColumnDefs(columnDefs)
          setDisableFields(!props.person_id || handleTaxStatusFieldDisable('social_security_number', methods.watch(`linked_people[${props.person_id}].people_data`), hasSpecialPermissionToAccess, props?.form_meta?.isTaxFormLookupEnabled))
        }
      }, [props, gridRef])

    // Enables/Disables DOB field for RTO's
    useEffect(() => {
        const people_id_default = {people_id: 0}
        let disableStates = controlLookupFields(methods.getValues(`linked_people[${props.person_id}].people_data`) ?? people_id_default, methods.getValues(`linked_people[${props.person_id}].membership_data`))
        methods.setValue(`${props.type}_disableDOB_${props.person_id}`, disableStates.disDob)
    }, [methods.getValues(`linked_people[${props.person_id}].people_data`), props.person_id])

    // function to check if membership date is editable
    function checkDateEditable(params) {
        const { data } = params.node;
        // make the organization uneditable if it is managed and system-org >>>
        let organizations = methods.getValues("people_organization") ?? []
        let currentOrganization = organizations?.find((org) => org.organization_code === data.organization_code)
        let isEditable = !(currentOrganization?.managed && currentOrganization?.system_org);
        // make the organization uneditable if it is managed and system-org <<<

        const disableEditingForOrgs = ['Tax Status', 'EC-SAFESPORT']
        // If Tax Status row, then date editing should not be allowed
        if (disableEditingForOrgs.includes(data.organization_code)) {
            isEditable = false;
        }

        const cellClass = isEditable ? 'editable-cell' : 'non-editable-cell';
        return {
          editable: isEditable,
          cellClass: cellClass,
        };
    }
    
    const columnDefs = [
        { field: 'organization_code', headerName: "", sortable: false, cellRenderer: renderIcon, maxWidth: 30, lockVisible: false, lockPosition: 'left', cellClass: 'text-center pl-r-5 image-cell', headerClass: 'ag-header-cell-wrap-text pl-r-5', resizable: true },
        { field: 'organization_code', cellStyle: {'paddingLeft': 5, 'paddingRight': 5} , headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.CODE' }),  maxWidth: 140, lockVisible: false, lockPosition: 'left', headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header', resizable: true },
        { field: 'membership_number', cellStyle: {'paddingLeft': 5, 'paddingRight': 5} , headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.MEMBERSHIPID' }),  maxWidth: 110, lockVisible: false, lockPosition: 'left',  editable: (params) => checkMembershipNumberEditable(params).editable, cellClass: (params) => checkMembershipNumberEditable(params).cellClass, headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header', resizable: true },
        { field: 'valid_start', cellStyle: {'paddingLeft': 5, 'paddingRight': 5} , cellClass: (params) => checkDateEditable(params).cellClass, cellEditor: DateStringEditor, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.STARTDATE' }),  lockVisible: false, lockPosition: 'left', editable: (params) => checkDateEditable(params).editable, minWidth: 115,  maxWidth: 115, cellRenderer: DateRenderer, resizable: true, cellStyle: {textAlign: 'right'} },
        { field: 'valid_end', cellStyle: {'paddingLeft': 5, 'paddingRight': 5} , cellClass: (params) => checkDateEditable(params).cellClass, cellEditor: DateStringEditor, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.ENDDATE' }),  lockVisible: false, lockPosition: 'left', editable: (params) => checkDateEditable(params).editable, minWidth: 115, maxWidth: 115, cellRenderer: DateRenderer, resizable: true, cellStyle: {textAlign: 'right'}},
        { field: 'membership_level', cellStyle: {'paddingLeft': 5, 'paddingRight': 5} , headerName: intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.LEVEL' }),  minWidth: 200, lockVisible: false, lockPosition: 'left', cellClass: 'non-editable-cell', headerClass: 'ag-header-cell-wrap-text ag-center-aligned-header', cellStyle: {textAlign: 'left'}, valueGetter: function getMembershipLevel(params) { 
            if(params?.data?.organization_code)
            {
                if(params?.data?.organization_code === 'JA' || params?.data?.organization_code === 'Tax Status'){
                    //Display membership level
                    return params?.data?.membership_level
                }else if(params?.data?.organization_code === 'EC'){
                    if(params?.data?.membership_override?.override_membershiplevel){// override exists than 
                        //Display override membership level
                        return params?.data?.membership_override?.override_membershiplevel
                    }else if(params?.data?.membership_number){
                        //Display ec_sport_license_type
                        let ec_sport_license_type = methods.getValues(`linked_people[${params?.data?.people_id}].people_data.ec_sport_license_type`)
                        return ec_sport_license_type
                    }
                }
            }else{
              return ''
            }
        } }
    ]

    // function to check if membership id is editable, in case of Tax Status, it's not editable
    function checkMembershipNumberEditable(params) {
        if(!props.person_id){ return false}
        
        const { data } = params.node;
        let isEditable = true;
        const disableEditingForOrgs = ['Tax Status', 'EC-SAFESPORT']
        if (disableEditingForOrgs.includes(data.organization_code)) {
            isEditable = false;
        }

        const cellClass = `${isEditable ? 'editable-cell' : 'non-editable-cell'} ag-left-aligned-cell`;
        return {
            editable: isEditable,
            cellClass: cellClass,
        };
    }

    const onCellDoubleClick = (params) => {
        let farmrtoUrl = ''
        if(methods.getValues(`linked_people[${props.person_id}].people_data.isfarmrto`)){
            farmrtoUrl = '&entityType=O'
        }
        setSelectedRow(params.data)
        switch (params.column.colId) {
            case "organization_code": 
                if(params?.data?.organization_code == 'USEF' && params.data?.membership_number){
                    // window.open(`https://members.usef.org/card?id=${params.data.membership_number}${farmrtoUrl}`, '_blank')
                    const membershipCardUrl = `https://members.usef.org/card?id=${params.data.membership_number}${farmrtoUrl}`;
                    setMembershipCardUrl(membershipCardUrl)
                    manageMembershipDetail(params)
                }else{
                    setMembershipCardUrl('')
                    manageMembershipDetail(params)
                }
                break;
            case "organization_code_1":
                if(params.value == 'Tax Status') {
                    props.setPersonIdForTaxStatusLookup(props.person_id)
                    props.setShowTaxInformation(true);
                }else if(allowManualOverrideForRTO(params.data.organization_code) && props.person_id !== 0){// check if this org is allow to override or not
                    setShowManualOverrideForm(true); 
                }
                break;
        }
    }

    const manageMembershipDetail = (params, showMembershipDetail = true) => {
        let endPoint = ''
        let lookupResponse = ''
        if(params.data?.organization_code === 'USEF' && params.data?.membership_number){
            endPoint = 'usefLookup'
            lookupResponse = 'usef_response_eti_lookup'
        }else if(params.data?.organization_code == 'EC'  && params.data?.membership_number){
            endPoint = 'ecLookup'
            lookupResponse = 'ec_lookup_response'
        }else if(params.data?.organization_code == 'USHJA'  && params.data?.membership_number){
            endPoint = 'ushjaLookup'
            lookupResponse = 'ushja_lookup_response'
        }else if(params.data?.organization_code == 'FEI'  && params.data?.membership_number){
            endPoint = 'feiLookup'
            lookupResponse = 'fei_lookup_response'
        }else if(params.data?.organization_code == 'PCHA' && params.data?.membership_number){
            window.open( "http://www.pchorseshows.org/pcha_members.php", '_blank')
        }else if((params.data?.organization_code?.toUpperCase() == 'NORCAL' || params.value == 'Norcal') && params.data?.membership_number){
            endPoint = 'emLookup'
            lookupResponse = 'norcal_lookup_response'
        }else if(params.value == 'JA' && params.data?.membership_number){
            window.open(`http://app.equestrianmemberships.com/members/lookupMembershipInfo/membership_number/"+${params.data.membership_number}+"/tenant_id/1`, "_blank")
        }else if(params.data?.organization_code == 'Tax Status') {
            props.setPersonIdForTaxStatusLookup(props.person_id)
            props.setShowTaxInformation(true);
        }else if(params.data?.organization_code == 'OHJA'){
            endPoint = 'emLookup'
            lookupResponse = 'ohja_lookup_response'
        }
        
        if(endPoint == 'ushjaLookup' && methods.getValues(`linked_people[${props.person_id}].people_data.isfarmrto`)){
            return ;
        }

        if(endPoint !== ''){   
            loadingOverlay({show: true})
            axios.post( process.env.REACT_APP_NEST_API_URL + '/people/'+ endPoint, {
                params: {
                    people: methods.getValues(`linked_people[${props.person_id}].people_data`),
                    customer_id: customer_id,
                    membership_obj: params.data,
                    accept_mismatch_checked: false
                }
            }).then((response) => {
                loadingOverlay({show: false})
                if(response.data.success) {
                    methods.setValue(`linked_people[${response.data.people.people_id}].people_data`, response.data.people)
                    if(endPoint == 'usefLookup' && response.data.mismatch_fields && response.data.mismatch_fields.length > 0 && showMembershipDetail){
                        methods.setValue('usef_lookup_field', response.data.mismatch_fields)
                        setUsefMismatch(true)
                        setEcMismatch(false)
                        setFeiMismatch(false)
                    }else if(endPoint == 'ecLookup'){
                        if(response.data.mismatch_fields?.length){
                            // setEcMismatch(true)
                            handleUpdateData(response.data.people, endPoint)
                            setMismatchPerson(response.data.mismatch_fields[0])
                        }
                        // setUsefMismatch(false)
                        // setFeiMismatch(false)
                    }else if(endPoint == 'feiLookup'){
                        if(response.data.mismatch_fields?.length){
                            handleUpdateData(response.data.people, endPoint)
                            setMismatchPerson(response.data.mismatch_fields[0])
                        }
                        axios.post( process.env.REACT_APP_NEST_API_URL + '/people/' + 'feiStatsLookup', {
                            params: {
                                feiId: methods.getValues(`linked_people[${props.person_id}].people_data`)?.fei_id,
                                people_master_sgl_id: methods.getValues(`linked_people[${props.person_id}].people_data`)?.master_id,
                                customer_id: methods.getValues(`linked_people[${props.person_id}].people_data`)?.customer_id ?? -1                                
                            }
                        })
                        // setEcMismatch(false)
                        // setUsefMismatch(false)
                    }
                    setPersonData(response.data.people)
                    let lookup = JSON.parse(response.data.people[lookupResponse])
                    parseLookupData(lookup, lookupResponse)
                    if(showMembershipDetail){
                        setShowMembershipDetailForm(true);
                    }
                    else{
                        handleUpdateData(response.data.people)
                    }

                    // Preserve existing overrides before updating
                    const currentMembershipData = methods.getValues(`linked_people[${props.person_id}].membership_data`);
                    const updatedMemberships = Object.fromEntries(
                        Object.entries(response?.data?.updated_memberships_list || {}).map(([key, updated]) => {
                            if (!updated) return [key, updated]
                    
                            const existing = currentMembershipData.find(m => m?.organization_code === updated?.organization_code);
                            return [
                                key,
                                existing?.membership_override
                                    ? { ...updated, membership_override: existing.membership_override }
                                    : updated
                            ];
                        })
                    )             
                    
                    updateMembership(updatedMemberships)

                    if(endPoint == 'usefLookup' && response.data.usef_show_pass_override){
                        let usef_show_pass_overrides = methods.getValues('usef_show_pass_overrides')
                        if (usef_show_pass_overrides){
                            if(!(usef_show_pass_overrides.find(s => s.show_id == response.data.usef_show_pass_override.show_id && s.people_id == response.data.usef_show_pass_override.people_id))){
                                usef_show_pass_overrides.push(response.data.usef_show_pass_override)
                                methods.setValue('usef_show_pass_overrides', usef_show_pass_overrides, {shouldDirty: true})
                            }
                        } else {
                            methods.setValue('usef_show_pass_overrides', [response.data.usef_show_pass_override], {shouldDirty: true})

                        }
                    }
                } else {
                    let error_id = 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.TAB.ERROR.'+response.data.error_message_id
                    alertDialog({message: intl.formatMessage({ id: error_id })})
                    props.clearMembership(props.person_id, params.data.organization_code)
                }
            
            })
        }
        
    }

    const parseLookupData = (lookup, lookupResponse) => {
        if (lookup){
            var lookup_entries = Object.entries(lookup)
            let lookupData = [{'key': lookupResponse, 'value': JSON.stringify(lookup)}]
            lookup_entries.map(([key, value]) => {
                // if(value != null && Array.isArray(value)){
                //     if(value.length > 0){
                //         value.map((data) => {
                //             let lookup = Object.entries(data)
                //             lookup.map(([key, value]) => {
                //                 lookupData.push({'key': key, "value": value})
                //             })
                //         })
                //     }
                // }
                // else{
                    lookupData.push({'key':key, "value":value})
                // }
            })
            setLookupResponse(lookupData)
        }
    }

    // Update person memberships return from lookups
    const updateMembership = (memberships) => {
        let peopleMemberships = methods.getValues(`linked_people[${props.person_id}].membership_data`)
        if(Object.keys(memberships).length > 0){
            for(let code in memberships){
                let index = peopleMemberships.findIndex(hm => hm.organization_code.toLowerCase() == code.toLowerCase())
                if(index >= 0){
                    if(!memberships[code]){
                        peopleMemberships[index]['membership_number'] = ''
                        peopleMemberships[index]['valid_end'] = '0000-00-00'
                        peopleMemberships[index]['valid_start'] = '0000-00-00'
                    }else{
                        peopleMemberships[index] = memberships[code]
                    }
                    peopleMemberships[index]['isUpdated'] = true
                }
            }
            methods.setValue(`linked_people[${props.person_id}].membership_data`, peopleMemberships, {shouldDirty: true})
        }
    }

    // Update horse data after user consent
    const handleUpdateData = (people, endPoint) => {        
        let person = personData
        if(people){
            person = people
        }
        setShowMembershipDetailForm(false)
        if(usefMismatch){
            setShowUsefMismatchForm(true)
        }else if(endPoint == 'ecLookup'){
            setECNameMismatch(true)
        }else if(endPoint == 'feiLookup'){
            setFEINameMismatch(true)
        }
        methods.setValue(`linked_people[${props.person_id}].people_data`, person, {shouldDirty: true})
    }

    const rowClassRules = useMemo(() => {
        return {
            'bg-override': 'data.override',
            'bg-expired': 'data.expired',
            'bg-current': 'data.current',
            'bg-valid': 'data.valid',
            'bg-special': 'data.special',
            'bg-none': 'data.none',
        };
    }, []);

    const containerStyle = useMemo(() => ({ width: '100%', height: '97%' }), []);

    useEffect(() => {
        if(gridRef && methods.getValues('linked_people[' +props.person_id+'].membership_data')){
            setLoginId(methods.watch('linked_people['+props.person_id+'].people_data.sgl_logins_id'))
            let entryAuthorizationRequestsList = methods.getValues('entryAuthorizationRequests')
            let role = props.type
            let entryAuthorizationRequest = entryAuthorizationRequestsList.find(e => e.people_id == props.person_id && e.role?.toUpperCase() != "RP")
            // setEntryAuthorizationRequest(methods.watch(`linked_people[${props.person_id}].entryAuthorizationRequest`))
            setEntryAuthorizationRequest(entryAuthorizationRequest)

            priorityColor(methods.getValues('linked_people['+props.person_id+']'))
            // setRiderUserStatus(methods.getValues('entry.EntryRiders')?.find(rider => rider.rider_id == props.person_id))
            // setRider(methods.getValues('entry.EntryRiders')?.find(rider => rider.rider_id == props.person_id))

            const entryRiders = methods.getValues('entry.EntryRiders');
            const foundRiderUserStatus = entryRiders && Array.isArray(entryRiders) && entryRiders.find(rider => rider.rider_id === props.person_id);
            const foundRider = entryRiders && Array.isArray(entryRiders) && entryRiders.find(rider => rider.rider_id === props.person_id);
            setRiderUserStatus(foundRiderUserStatus);
            setRider(foundRider);
        }
    }, [gridRef, methods.watch('linked_people[' +props.person_id+'].people_data'), methods.watch('linked_people[' +props.person_id+'].membership_data'), methods.watch('entryAuthorizationRequests')]);

    //Update the suspension status every time the membership data is changed
    useEffect(() => {
        let status = props.RTOSuspensionStatus(props.type, props.person_id)
        setPersonStatus(status)
    }, [props.type, props.person_id, methods.watch('linked_people[' +props.person_id+'].membership_data')])

    const onBlur = (e) => {
        if(e.target.value && e.target.value !=''){
            callSearchPersonApi(e.target.value)
            setSearchField(props.type == 'OWNER' ? 'owner_search' : 'trainer_search')
        }
    }

    const onSearch = (e) => {
        if(e.key == 'Enter'){
            e.target.blur();
        }
    }

    // //Function for handling Search Tem
    const handleSearchTerm = (search_term) => {
    
        let firstName = props.getFirstNameFromSearchString(search_term)
        let lastName = props.getLastNameFromSearchString(search_term)
        let searchTerm = firstName != ''? firstName + (lastName != '' ?' ':'')+ lastName : lastName == '' ? '' : lastName
        setDialogData((prevDialogData) => ({...prevDialogData, first_name: firstName, last_name: lastName, search_term: searchTerm}))
    }

    const priorityColor = (peopleDetails) => {        
        let organizationMembers = peopleDetails.membership_data
        let peopleData = peopleDetails.people_data
        let rowData = organizationMembers?.map((data) => {
            // initializing color schemes
            data.none = false;
            data.expired = false;
            data.special = false;
            data.override = false;
            data.valid = false;

            if (data.membership_number && data?.membership_number != "")
                {
                    let ab_horseMembershipValid = false;
                    let membership_color = 'white';
                    
                    let today = moment().format('YYYY-MM-DD'); // default  //ADD Time zone info
                    // if(props.form_meta.timezone_info){
                    //     today = moment(props.form_meta.timezone_info.date)
                    // }

                    // if(data.is_membershipvalid == true || data.is_membershipvalid == 1){
                    let orgToCheckEndDateOnly = ['EC', 'EC-Coach'] // we validate membership against end date only and ignore start date
                    data.is_membershipvalid = false
                    ab_horseMembershipValid = false
                    if(orgToCheckEndDateOnly.includes(data.organization_code)){
                        // Handle color coding for EC and EC-Coach
                        if(data.valid_end && data.valid_end !='' && data.valid_start && data.valid_start !=''){
                            if(moment(today).isSameOrBefore(moment(data.valid_end).format('YYYY-MM-DD'))){ 
                                // If membership end date is valid by current date
                                data.is_membershipvalid = true
                                ab_horseMembershipValid = true
                                
                            }else{
                                // If membership end date is valid by show end date for EC-Coach only
                                if(data.organization_code == 'EC-Coach' && moment(currentShowEndDate).isSameOrBefore(moment(data.valid_end).format('YYYY-MM-DD'))){
                                    data.is_membershipvalid = true
                                    ab_horseMembershipValid = true
                                }
                            }
                            // Check membership validation for special cases if membership is already valid
                            if(data.organization_code == 'EC-Coach' && data.is_membershipvalid){
                                let isCoachMembershipValid = isEcCoachMembershipValidByShowLevel(data.membership_number, methods.getValues('show.ec_rating'))
                                if(!isCoachMembershipValid){
                                    data.is_membershipvalid = false
                                    ab_horseMembershipValid = false
                                }
                            }else if(data.organization_code == 'EC' && data.is_membershipvalid){
                                let membersipLevel = ''
                                let show_data = methods.getValues('show')
                                //Get membership level >>>
                                if(data?.membership_override?.override_membershiplevel){// override exists than 
                                    membersipLevel = data?.membership_override?.override_membershiplevel
                                }else{
                                    membersipLevel = methods.getValues(`linked_people[${data?.people_id}].people_data.ec_sport_license_type`)
                                }
                                //Get membership level <<<
                                if(show_data?.require_ecmembership && show_data?.require_membership_type != ""){
                                    let ecMembershipValid = isEcMembershipValid(membersipLevel, show_data?.require_membership_type)
                                    if(!ecMembershipValid){
                                        data.is_membershipvalid = false
                                        ab_horseMembershipValid = false
                                    }
                                }
                            }
                        }
                    }else{
                        if(data.valid_end && data.valid_end !='' && data.valid_start && data.valid_start !=''){
                            if(moment(today).isSameOrBefore(moment(data.valid_end).format('YYYY-MM-DD')) && moment(today).isSameOrAfter(moment(data.valid_start).format('YYYY-MM-DD'))){ // if valid end is future date
                                data.is_membershipvalid = true
                                ab_horseMembershipValid = true
                            }
                        }
                    }
                    if(data?.USHJAMembershipType){                    
                        if(data.organization_code == "USHJA" && data?.USHJAMembershipType?.toLowerCase().includes("outreach")){                        
                            data.is_membershipvalid = false
                            ab_horseMembershipValid = false                                            
                        }
                    }
                    
                    // }

                    let membershipOverride = data.membership_override   
                    if(membershipOverride && membershipOverride.inactive_override_date >= currentShowEndDate){
                        membership_color = 'yellow'
                        if(membershipOverride.owner_nonmemberfeepaid || (membershipOverride.ec_override_reason == 20 && membershipOverride.organization_code == 'EC')){
                            membership_color = 'orange'
                        }
                    } else if (ab_horseMembershipValid){
                        membership_color = 'green';

                        // Check USEF suspension and change row color to red if suspended, regardless of their current membership validity
                        if (peopleData && peopleData?.usef_suspension == true && data?.organization_code === 'USEF') {
                            membership_color = 'red';
                        }

                        // Check EC suspension and change row color to red if suspended, regardless of their current membership validity
                        else if (peopleData && peopleData?.ec_standingsstatus == "Not In Good Standing" && data?.organization_code === 'EC') {
                            membership_color = 'red';
                        }

                        // Check FEI suspension and change row color to red if suspended, regardless of their current membership validity
                        else if (peopleData && peopleData?.fei_isactive == false && data?.organization_code === 'FEI') {
                            membership_color = 'red';
                        }
                    }else {
                        if(!data.valid_end || data?.valid_end == '0000-00-00' || data?.valid_end == ''){ //change format
                            // ec statuses in array
                            let ec_inactive_statuses = ['DECEASED', 'RETIRED', 'SOLD'];
                            if(membershipOverride && membershipOverride.membership_number != "" && membershipOverride.organization_code == "EC" && 
                            ec_inactive_statuses.includes(methods.getValues("people.ec_status") )){
                                membership_color = 'red'
                            } else {
                                membership_color = 'white'
                            }
                        } else{
                            membership_color = 'red'
                        }
                    }

                    // Set membership color for tax status override (in case of status = review or if status = manual and tin is not verified)
                    if (data.organization_code == 'Tax Status') {
                        if (data.membership_override) {
                            membership_color = 'yellow'
                        }
                    }

                    if(!membershipOverride && data?.organization_code === 'USEF'){ // there is no override and case is for USEF farm set special colors, based on status, instead of membership dates
                        if (data?.isUSEFValidFarm == 'yes'){
                            // Status = ACTIVE indicates that all requirements are fulfilled for the farm while
                            // Status = NO ELIBIGLE SENIOR ACTIVE OWNER means that one or more of the requirements are NOT fulfilled                            
                            data.is_membershipvalid = true
                            ab_horseMembershipValid = true
                            membership_color = 'green';
                        } else if (data?.isUSEFValidFarm == 'no'){
                            data.is_membershipvalid = false
                            ab_horseMembershipValid = false                               
                            membership_color = 'red';                                           
                        }
                    }

                    if(!membershipOverride && data?.organization_code === 'EC-SAFESPORT'){ // there is no override and case is for USEF farm set special colors, based on status, instead of membership dates
                        if (data?.EC_SAFESPORT_Status && data?.EC_SAFESPORT_Status == 'Valid'){
                            // Status = Valid indicates Junior, Farm or true returned from EC for safesport flag                            
                            data.is_membershipvalid = true
                            ab_horseMembershipValid = true
                            membership_color = 'green';
                        } else{
                            data.is_membershipvalid = false
                            ab_horseMembershipValid = false                               
                            membership_color = 'red';                                           
                        }
                    }
            
                    switch(membership_color){
                        case 'white':
                            data.none = true;
                            break;
                        case 'red':
                            data.expired = true;
                            break;
                        case 'orange':
                            data.special = true;
                            break;
                        case 'yellow':
                            data.override = true;
                            break;
                        case 'green':
                            data.valid = true;
                            break;
                    }
            } else{
                data.none = true
            }
            return data
        })

        // gridRef?.setRowData(rowData)
        if(gridRef){
            updateAGGridWithoutFlickering(({ current: { api : gridRef}}), rowData, "organization_code") // update grid without rerendering 
        }
    }

    async function updateLoginData(){
        loadingOverlay({show: true})
        return axios.post( process.env.REACT_APP_NEST_API_URL + '/people/updateLogins', {
          params: {
            people: methods.getValues("linked_people["+ props.person_id +"].people_data"),
            customer_id: customer_id
          }
        }).then((response) => {
            if(response.data.success) {
              let sglLoginFields = ["sgl_fl_name", "sgl_email_verified", "sgl_email", "sgl_mobile_verified", "sgl_mobile", "sgl_logins_id"]
              setLoginId(response.data.people.sgl_logins_id)
              for(let sglLoginField of sglLoginFields){
                methods.setValue("linked_people["+props.person_id+"].people_data."+ sglLoginField, response.data.people[sglLoginField], {shouldDirty: true})
              }
              methods.setValue('linked_people['+props.person_id+'].people_data.isUpdated', true)
          }
          loadingOverlay({show: false})
          }).catch((reason) => {
            loadingOverlay({show: false})
            let errors = reason.response.data.response.errors.join("<br/>")
            alertDialog({message: errors, title: 'warning'})
        })
    }

    const updateLinkedPeople = (field_name, value) => {
        let updatedPerson = {}
        let firstLastName = value
        let lastFirstName = value

        methods.setValue('linked_people['+props.person_id+'].people_data.isUpdated', true)
        methods.setValue('linked_people['+props.person_id+'].people_data.'+field_name, value)
        updatedPerson[field_name] = value

        // Assigning right side top label value under scratch button
        if(field_name.match('first|last_name|company_name')){
            // Add first or last_name field gets updated then assign firstLastName the concatenated value of first and last_name and lastFirstName the value of last_name and first concatenated value
            if(field_name != 'company_name'){
                firstLastName = methods.getValues('linked_people['+props.person_id+'].people_data.first').concat(' ', methods.getValues('linked_people['+props.person_id+'].people_data.last_name'))
                lastFirstName = methods.getValues('linked_people['+props.person_id+'].people_data.last_name').concat(' ', methods.getValues('linked_people['+props.person_id+'].people_data.first'))
            }
            methods.setValue('linked_people['+props.person_id+'].people_data.fl_name', firstLastName) // Assign fl_name the value of firstLastName
            methods.setValue('linked_people['+props.person_id+'].people_data.lf_name', lastFirstName) // Assign lf_name the value of firstLastName
            updatedPerson['fl_name'] = firstLastName
            updatedPerson['lf_name'] = lastFirstName

            // If RTO type is rider check if rider is a trainer or owner
            if(props.person_id == methods.getValues('entry.entryowner_id')){
                methods.setValue('entry.owner', firstLastName, {shouldDirty: true})
            }
            if(props.person_id == methods.getValues('entry.trainer_id')){
                methods.setValue('entry.trainer', firstLastName, {shouldDirty: true})
            }
            if(props.person_id == methods.getValues('entry.prizemoneyrecipient_id')){
                methods.setValue('entry.prizemoneyrecipient', firstLastName, {shouldDirty: true})
            }

            if(props.person_id == methods.getValues('entry.responsibleparty_id')){
                methods.setValue('entry.responsibleparty', firstLastName, {shouldDirty: true})
            }

            let riderIndex = methods.getValues('entry.EntryRiders').findIndex(rider => rider.rider_id == props.person_id)

            if(riderIndex >= 0){
                let riders = methods.getValues('entry.EntryRiders')
                riders[riderIndex].rider_name = firstLastName
                riders['isUpdated'] = true

                methods.setValue('entry.EntryRiders', riders, {shouldDirty: true})
            }

            let trips = methods.getValues('trips').map(trip => {
                if(trip.rider_id == props.person_id){
                    trip.rider_name = firstLastName
                }

                return trip
            })

            methods.setValue('trips', trips, {shouldDirty: true})
        }
        if(field_name == 'dob'){
            let age = calculateAge(value)
            methods.setValue('linked_people['+props.person_id+'].people_data.age', age)

            let entryRiders = methods.getValues('entry.EntryRiders')
            let riderIndex = entryRiders.findIndex(rider => rider.rider_id == props.person_id)
            
            if(riderIndex >= 0){
                entryRiders[riderIndex]['rider_age'] = age
                updateRiderStatus(entryRiders[riderIndex])
            }
        }

        let person = methods.getValues(`updatedPersons[${props.person_id}]`)
        if(person){
            person = { ...person, ...updatedPerson}
        }else{
            person = updatedPerson
        }

        methods.setValue(`updatedPersons[${props.person_id}]`, person)
    }

    const calculateAge = (dob, referenceDate = '0000-00-00') =>  { 
        if(!dob || dob == '0000-00-00'){
            return 0;
        }

        if(!referenceDate || referenceDate == '0000-00-00'){
            referenceDate = moment().format('YYYY-MM-DD');
        }

        //Get year for reference date
        let referenceDateYear = parseInt(moment(referenceDate).format("YYYY"));
        let referencedDateMonth = parseInt(moment(referenceDate).format('MM'));
        if(referencedDateMonth < 12){  // calculate date based on previous year's 12/01 if we are NOT in month of december
            referenceDateYear--;
        }
  
        //Get year for DOB date
        let dobDateYear = parseInt(moment(dob).format("YYYY"));
        let dobDateMonth = parseInt(moment(dob).format('MM'));
        if(dobDateMonth >= 12){  // calculate date based on previous year's 12/01 if we are NOT in month of december
            dobDateYear++;
        }

        // Calculate age based on year difference
        var usefAge = referenceDateYear - dobDateYear;
        if(usefAge < 1){
            usefAge = 0;
        }
        
        return usefAge;
    }

    // const mailto = (email) => {
    //     var win = window.open("mailto:"+email+"?subject= &body=", '_blank');
    //     win.focus();
    // }
  
    const onCellValueChanged = (params) => {
        const moment = require('moment');
        const inValidValues = [undefined, '00/00/0000', '0000-00-00']
    
        if (params.node.data.valid_start && !inValidValues.includes(params.node.data.valid_start)) { //format valid_start date if not formattted already on input
            if(!params.node.data.valid_start.includes('/') && !params.node.data.valid_start.includes('-')){ // if date is not in format (e.g. 01012024)
                params.node.data.valid_start = `${params.node.data.valid_start.slice(0, 2)}/${params.node.data.valid_start.slice(2, 4)}/${params.node.data.valid_start.slice(4)}` // convert date into MM/DD/YYYY format (e.g. 01/01/2024)
            }
        }

        if (params.node.data.valid_end && !inValidValues.includes(params.node.data.valid_end)) { //format valid_end date if not formattted already on input
            if(!params.node.data.valid_end.includes('/') && !params.node.data.valid_end.includes('-')){ // if date is not in format (e.g. 01012024)
                params.node.data.valid_end = `${params.node.data.valid_end.slice(0, 2)}/${params.node.data.valid_end.slice(2, 4)}/${params.node.data.valid_end.slice(4)}` // convert date into MM/DD/YYYY format (e.g. 01/01/2024)
            }
        }
        
        let membership_data = methods.getValues("linked_people")
        let index = membership_data[props.person_id].membership_data.findIndex(membership => membership.organization_code == params.data.organization_code)
        membership_data[props.person_id].membership_data[index] = params.data
        membership_data[props.person_id].membership_data[index].isUpdated = true
        membership_data[props.person_id].people_data.isUpdated = true
        membership_data[props.person_id].membership_data[index].valid_start = (params.data.valid_start && moment(params.data.valid_start).isValid())? params.data.valid_start : '' 
        membership_data[props.person_id].membership_data[index].valid_end = (params.data.valid_end && moment(params.data.valid_end).isValid())? params.data.valid_end : ''
        
        let lookup_variable = ''
        switch(params.data.organization_code.toUpperCase()){
            case 'USEF':
                lookup_variable = 'usef_response_eti_lookup'
                break;

            case 'EC':
                lookup_variable = 'ec_lookup_response'
                break;

            case 'FEI':
                lookup_variable = 'fei_lookup_response'
                break;

            case 'USHJA':
                lookup_variable = 'ushja_lookup_response'
                break;

            case 'OHJA':
                lookup_variable = 'ohja_lookup_response'
                break;

            case 'NORCAL':
                lookup_variable = 'norcal_lookup_response'
                break;
        }
        
        if(lookup_variable){
            membership_data[props.person_id].people_data[lookup_variable] = ''
        } 
        
        if(params.data.membership_number == ''){
            membership_data[props.person_id].membership_data[index].valid_start = '0000-00-00'
            membership_data[props.person_id].membership_data[index].valid_end = '0000-00-00'
            membership_data[props.person_id].membership_data[index].membership_level = ""
            if(membership_data[props.person_id].membership_data[index].organization_code == 'EC'){
                methods.setValue(`linked_people[${props.person_id}].people_data.ec_sport_license_type`, '', { shouldDirty: true })
            }
            //Remove membership override when membership_number is empty
            let updatedData = deleteMembershipOverride(membership_data[props.person_id].membership_data[index], membership_data[props.person_id].membership_override_data?.membership_override_added, membership_data[props.person_id].membership_override_data?.membership_override_removed)
            membership_data[props.person_id].membership_override_data = {
                entity_type: '',
                membership_override_added: updatedData.membership_override_added,
                membership_override_removed: updatedData.membership_override_removed
            }
        }
        else{
            manageMembershipDetail(params, false)
        }
        methods.setValue("linked_people", membership_data)
    }

    const renderFieldsetLegend = (type, searchField, person_id) => {
        //let status = props.RTOSuspensionStatus(type, person_id)
        if (type != 'RIDER'){
            return (
                <legend className="fs-5 fw-bold d-flex entries-search-legend">
                    <div className='d-flex align-self-center w-50px me-10'><label data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.${props.type}`}  >{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.GROUPLABEL.' + type })}</label></div>
                    <SearchInput id={searchField+"_field"}  containerClass='me-2 w-225px' onBlur={onBlur} onSearch= {onSearch} fieldName={searchField} />
                    <label className='fs-6 py-1 text-danger col-lg-3 d-flex align-items-center ms-2 text-capitalize'>{person_search_status}</label>
                    {personStatus ? 
                    <div className={'d-flex align-items-center col'}>
                        <i className="fas fa-xl fa-regular fa-triangle-exclamation me-1 text-warning"></i>
                        <label className='fs-6 py-1'>{personStatus}</label>
                    </div>
                    : '' }
                    <b></b>
                </legend>
            )
        }
    }

    async function callSearchPersonApi(search_term){
        if(search_term.trim()){
            loadingOverlay({show: true})
            let search_response = await props.callSearchPersonApi(search_term, props.type)
            loadingOverlay({show: false})
            if (search_response.showPersonLookup){
                setDialogData((prevDialogData) => ({...prevDialogData, ...search_response.dialog_data}))
                setPeopleSearchList(search_response.search_list)
                setSearchCountStatus(search_response.people_count_status)
                setDefaultOrg(search_response.default_org)
                setShowPersonLookup(search_response.showPersonLookup)
            } else {
                setPersonSearchStatus(search_response.search_status)
                methods.setValue(props.type == 'OWNER' ? 'owner_search' : 'trainer_search', '')
            }     
        } 
    }

    async function getSelectedPeople(people, organization, person_role){
        let search_status = await props.getSelectedPeople(people, organization, person_role)
        if(search_status){
            setPersonSearchStatus(search_status)
        }
    }

    const acceptMismatch = (tabname) => {
        // methods.setValue(`linked_people[${props.person_id}].people_data.first`, mismatchPerson.first)
        // methods.setValue(`linked_people[${props.person_id}].people_data.last_name`, mismatchPerson.last_name)
        // methods.setValue(`linked_people[${props.person_id}].people_data.fl_name`, mismatchPerson.first+' '+mismatchPerson.last_name)
        // methods.setValue(`linked_people[${props.person_id}].people_data.lf_name`, mismatchPerson.last_name+' '+mismatchPerson.first)
        let organizationMembers = methods.getValues(`linked_people[${props.person_id}].membership_data`);
        let index = organizationMembers.findIndex(pm => pm.organization_code.toLowerCase() == tabname.toLowerCase())

        if(index >= 0) {
            if (tabname == "EC"){
                let membership_year = methods.getValues(`linked_people[${props.person_id}].people_data.ec_last_year_active`)?parseInt(methods.getValues(`linked_people[${props.person_id}].people_data.ec_last_year_active`)):0;
                if((!organizationMembers[index].valiid_start || organizationMembers[index].valid_start == '0000-00-00') && (!organizationMembers[index].valid_end || organizationMembers[index].valid_end == '0000-00-00') && membership_year > 0){
                    organizationMembers[index].valid_start = `${membership_year}-01-01` //If year is 2019 , the data will be 01/01/2019
                    organizationMembers[index].valid_end = `${membership_year}-12-31` //set date to the 1 year after the valid start date and -1 day, e.g 12/31/2019
                    // organizationMembers[index].membership_level = mismatchPerson.first + " " + mismatchPerson.last_name
                    organizationMembers[index].membership_level = methods.getValues("people.ec_sport_license_type")

                    let current_date = moment().format('YYYY-MM-DD')
                    if (organizationMembers[index].valid_end >= current_date) { //RTO has current compition year membership of EC
                        organizationMembers[index].is_membershipvalid = true
                    }
                }
            } else if (tabname == "FEI"){
                let membership_year = methods.getValues(`linked_people[${props.person_id}].people_data.fei_membership_year`)?parseInt(methods.getValues(`linked_people[${props.person_id}].people_data.fei_membership_year`)):0;

                let fei_isactive = methods.getValues(`linked_people[${props.person_id}].people_data.fei_isactive`)
                if(organizationMembers[index].valid_start == '0000-00-00' && organizationMembers[index].valid_start == '0000-00-00' && membership_year > 0 && fei_isactive){
                    organizationMembers[index].valid_start = `${membership_year}-01-01` //If year is 2019 , the data will be 01/01/2019
                    organizationMembers[index].valid_end = `${membership_year}-12-31` //set date to the 1 year after the valid start date and -1 day, e.g 12/31/2019
                    // organizationMembers[index].membership_level = mismatchPerson.first + " " + mismatchPerson.last_name

                    let current_date = moment().format('YYYY-MM-DD')
                    if (organizationMembers[index].valid_end >= current_date) { //RTO has current compition year membership of EC
                        organizationMembers[index].is_membershipvalid = true
                    }
                
                }
            }
        //     let organization = organizationMembers[index]
            //     organizationMembers.find(membershipData =>{
            //     if (membershipData.organization_code.toLowerCase() == 'ec'){
            //         return membershipData
            //     }
            // })
            // if(!organization){
            //     organization.membership_id = 0
            //     organization.organization_code = "EC"
            //     organization.people_id = methods.getValues(`linked_people[${props.person_id}].people_data.people_id`)
            // }
            organizationMembers[index].isUpdated = true
            organizationMembers[index].name_mismatch_accepted = true
            organizationMembers[index].membership_name = mismatchPerson?.lookupField
            methods.setValue(`linked_people[${props.person_id}].membership_data`, organizationMembers)

            // organization.name_mismatch_accepted = true
        }
    }

    const handleClearMembership = (tabname) => {
        let personMemberships = methods.getValues(`linked_people[${props.person_id}].membership_data`)
        let index = personMemberships.findIndex(pm => pm.organization_code.toLowerCase() == tabname.toLowerCase())

        if(index >= 0){
            personMemberships[index].valid_start = "0000-00-00"
            personMemberships[index].valid_end = "0000-00-00"
            personMemberships[index].isUpdated = true
            personMemberships[index].membership_level = ""
            if(personMemberships[index].organization_code == 'EC'){
                methods.setValue(`linked_people[${props.person_id}].people_data.ec_sport_license_type`, '', {shouldDirty: true})
            }

            methods.setValue(`linked_people[${props.person_id}].membership_data`, personMemberships)
        }
    }

    async function updateRiderUserStatus(status, update_rider_status = false) {
        let entryRiders = methods.getValues('entry.EntryRiders')
        let riderIndex = entryRiders.findIndex(rider => rider.rider_id == props.person_id)
        if(riderIndex >= 0){
            entryRiders[riderIndex]['user_status'] = status
            entryRiders[riderIndex]['isUpdated'] = true
            console.log(props.person_id)

            if(update_rider_status){
                updateRiderStatus(entryRiders[riderIndex])
            }        
        }

        

        methods.setValue('entry.EntryRiders', entryRiders, {shouldDirty: true})

    }

    async function updateRiderStatus(entry_rider){
        let people = methods.getValues(`linked_people[${entry_rider.rider_id}]`)
        console.log(people, entry_rider)
        let entryRiders = methods.getValues('entry.EntryRiders')
        let riderIndex = entryRiders.findIndex(rider => rider.rider_id == entry_rider.rider_id)
        let rider_status = await props.getRiderStatus(people, entry_rider)
        if(riderIndex >= 0){
            entryRiders[riderIndex]['rider_status'] = people.people_data.rider_status
            if(rider_status && rider_status != ''){
                entryRiders[riderIndex]['status'] = rider_status
                entryRiders[riderIndex]['isUpdated'] = true
                setRider(entryRiders[riderIndex]);
                methods.setValue('entry.EntryRiders', entryRiders, {shouldDirty: true})
            }
        }
    }

    const handleInvalidSignature = async () => {
        let choice = await confirmDialog({message: `Are you sure you want to invalidate signature for ${methods.getValues(`linked_people[${props.person_id}].people_data.fl_name`)}?`})
        if(choice){
            digitalSignatureAction('Invalidate Signature')
        }
    }

    const digitalSignatureAction = async (action, phoneNumber = '', role = '', replicateAuthRequest = false, byPassShowCheck = false) => {
        let response = await axios.get(process.env.REACT_APP_NEST_API_URL + '/entries/digitalSignatureAction', {
            params: {
                people_id: props.person_id,
                entry_id: props.entry_id,
                show_id: methods.getValues('entry.show_id'),
                phone_number: phoneNumber,
                action,
                customer_id,
                role,
                replicate_auth_requests: replicateAuthRequest,
                bypass_show_check: byPassShowCheck
            }
        })

        if(response.data.success){
            let entryAuthorizationRequestsList = methods.getValues('entryAuthorizationRequests')
            let updatedRequest = response.data.entryAuthorizationRequests
            if (updatedRequest) {
                // entryAuthorizationRequestsList = entryAuthorizationRequestsList.map((request) => 
                //   request.people_id == updatedRequest.people_id && request.role?.toUpperCase() != "RP"
                //     ? { ...request, ...updatedRequest } // Merge updated fields into the existing object
                //     : request
                // );
                entryAuthorizationRequestsList = entryAuthorizationRequestsList.some(
                    (request) =>
                      request.people_id == updatedRequest.people_id &&
                      request.role?.toUpperCase() != "RP"
                  )
                    ? entryAuthorizationRequestsList.map((request) =>
                        request.people_id == updatedRequest.people_id &&
                        request.role?.toUpperCase() != "RP"
                          ? { ...request, ...updatedRequest } // Merge updated fields into the existing object
                          : request
                      )
                    : [...entryAuthorizationRequestsList, updatedRequest]; 
                setEntryAuthorizationRequest(updatedRequest)
                methods.setValue('entryAuthorizationRequests', entryAuthorizationRequestsList, {shouldDirty: true});
            }
            // methods.setValue(`linked_people[${props.person_id}].entryAuthorizationRequest`, response.data.entryAuthorizationRequests)
            // methods.setValue(`linked_people[${props.person_id}].updateSeriesAuthorizationRequests`, response.data.updateSeriesAuthorizationRequests)
        }else if(response.data.warning){
            let choice = await confirmDialog({message: `This show is already completed. Do you still want to Send Signature Auth Request for ${methods.getValues(`linked_people[${props.person_id}].people_data.fl_name`)}?`})
            if(choice){
                await digitalSignatureAction(action, phoneNumber, role, replicateAuthRequest, true)
            }
        }else{
            if(response.data.message){
                alertDialog({message: response.data.message})
            }
        }

        if(!byPassShowCheck){
            if(action == 'Send Signature Auth Request'){
                await props.callFormSubmit()
            }
        }

    }

    const getRTOMobilePhone = () => {
        let person = entryAuthorizationRequest?.agent_will_sign && methods.getValues('entry.trainer_id') ? methods.getValues(`linked_people[${methods.getValues('entry.trainer_id')}]`) : methods.getValues(`linked_people[${props.person_id}]`)

        if(entryAuthorizationRequest?.phone_number){
            return entryAuthorizationRequest.phone_number
        }
        else if(person?.people_data?.sgl_mobile && person?.people_data?.sgl_mobile_verified){
            return person.people_data.sgl_mobile
        }
        else if(person?.people_data?.mobile_phone){
            return person?.people_data?.mobile_phone_country_code + person.people_data.mobile_phone
        }

        return ''
    }

    const getRTONameForEntryAuthorization = () => {
        let person = entryAuthorizationRequest?.agent_will_sign && methods.getValues('entry.trainer_id') ? methods.getValues(`linked_people[${methods.getValues('entry.trainer_id')}]`) : methods.getValues(`linked_people[${props.person_id}]`)
 
        if(person?.people_data?.isfarmrto){
            return person?.people_data?.company_name
        }else{
            return `${person?.people_data?.first} ${person?.people_data?.last_name}`
        }
    }

    const sendEntryAuthorizationReq = () => {
        if(entryAuthorizationRequest?.agent_will_sign && !methods.getValues(`linked_people[${methods.getValues('entry.trainer_id')}]`)){
            // if agent will sign is true and there is no trainer added for the entry display error
            alertDialog({message: 'Please add trainer for the entry.'})
        }else{
            // display send authorization dialog
            setShowSignAuthRequestForm(true)
        }
    }

    function getEntryRiderStatus(field){
        let entryRiders = methods.getValues('entry.EntryRiders')
        let riderIndex = entryRiders.findIndex(rider => rider.rider_id == props.person_id)

        if(riderIndex >= 0){
            return entryRiders[riderIndex][field]
        }
        
    }

    return (
        <>
            {showPersonLookup && <PersonLookupForm 
                show={showPersonLookup} 
                handleClose={() => {methods.setValue(searchField, '');setShowPersonLookup(false);props.type == 'OWNER'?document.getElementById("trainer_search_field").focus():document.getElementById("stable_search_field").focus();}} 
                customer_id={customer_id} 
                fieldName={searchField} 
                dialogData={dialogData}    
                setDialogData={setDialogData}                 
                person_search_list={people_search_list}
                default_org={default_org}
                person_search_status={search_count_status}
                type = {props.type}
                show_id = {currentShowID}
                handleSearchTerm = {handleSearchTerm}
                getSelectedPeople = {getSelectedPeople}
                generateNewRTOId = {props.generateNewRTOId}
                updatePeople = {props.updatePeople}
            />}
            {showUsefMismatchForm && <USEFLookupMismatchModal show={showUsefMismatchForm} handleClose={() => {setShowUsefMismatchForm(false); setUsefMismatch(false)}} usef_lookup_field={methods.getValues('usef_lookup_field')} people={`linked_people[${props.person_id}].people_data`} />}
            {ECNameMismatch && <FeiEcNameMismatchAlert show={ECNameMismatch} handleClose={() => setECNameMismatch(false)} personFirstName={mismatchPerson?.first} personLastName={mismatchPerson?.last_name} acceptMismatch = {acceptMismatch} handleClearMembership={handleClearMembership} tabname={'EC'} name={
                methods.getValues(`linked_people[${props.person_id}].people_data.isfarmrto`)?methods.getValues(`linked_people[${props.person_id}].people_data.company_name`) : methods.getValues(`linked_people[${props.person_id}].people_data.first`) +' '+ methods.getValues(`linked_people[${props.person_id}].people_data.last_name`)} />}
            {FEINameMismatch && <FeiEcNameMismatchAlert show={FEINameMismatch} handleClose={() => setFEINameMismatch(false)} personFirstName={mismatchPerson?.first} personLastName={mismatchPerson?.last_name} acceptMismatch = {acceptMismatch} handleClearMembership={handleClearMembership} tabname={'FEI'} name={
                methods.getValues(`linked_people[${props.person_id}].people_data.isfarmrto`)?methods.getValues(`linked_people[${props.person_id}].people_data.company_name`) : methods.getValues(`linked_people[${props.person_id}].people_data.first`) +' '+ methods.getValues(`linked_people[${props.person_id}].people_data.last_name`)} />}
            
            {showMembershipDetailForm && <MembershipDetailForm show={showMembershipDetailForm} handleClose={() => setShowMembershipDetailForm(false)} lookupResponse={lookupResponse} handleUpdateData={handleUpdateData} membershipCardUrl={membershipCardUrl}/>}
            {showManualOverrideForm && <AdditionalManualOverrideForm show={showManualOverrideForm} handleClose={() => setShowManualOverrideForm(false)} rowData={selectedRow} personId={props.person_id} calledFrom={"entries"} personType={props.type}/>}
            {showSignAuthRequestForm && 
            <SignatureAuthRequestForm 
                show={showSignAuthRequestForm} 
                handleClose={() => setShowSignAuthRequestForm(false)} 
                role={''} 
                name={getRTONameForEntryAuthorization()} 
                digitalSignatureAction={digitalSignatureAction} 
                mobileNumber={getRTOMobilePhone()} 
                agentWillSign={entryAuthorizationRequest?.agent_will_sign ?? false}
            />}
            {showManualSignatureForm && 
            <PaperEntryConfirmationForm 
                show={showManualSignatureForm} 
                handleClose={() => setShowManualSignatureForm(false)} 
                role={''} 
                name={methods.getValues(`linked_people[${props.person_id}].people_data.isfarmrto`)?methods.getValues(`linked_people[${props.person_id}].people_data.company_name`) : methods.getValues(`linked_people[${props.person_id}].people_data.first`) +' '+ methods.getValues(`linked_people[${props.person_id}].people_data.last_name`)} 
                digitalSignatureAction={digitalSignatureAction} 
                futureUnsignedEntries={methods.getValues(`linked_people[${props.person_id}].futureUnsignedEntries`)}
            />}
            {renderFieldsetLegend(props.type, props.type.toLowerCase()+'_search', props.person_id)}
            <div className='row mb-2'>
                    <div className='col-lg-7'>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor={props.type + 'NAME' + props.person_id} data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.NAME`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'NAME' })}</label>
                            {/* Add Company name input field when isFarmTo is true */}
                            {methods.getValues('linked_people['+ props.person_id +'].people_data.isfarmrto')?
                            <div className='col px-0'>
                                <input
                                    value={methods.watch('linked_people['+props.person_id+'].people_data.company_name')}
                                    name={props.type + 'company_name' + props.person_id}
                                    id={props.type + 'NAME' + props.person_id}
                                    data-fieldref={'company_name'}
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.PLACEHOLDER.COMPANYNAME' })}
                                    disabled={isLocked}
                                    onChange={(e) => {updateLinkedPeople(e.target.dataset.fieldref, e.target.value)}}
                                />
                            </div>
                         :
                            <>
                                <div className='col px-0'>
                                    {/* Add Company name input field when isFarmTo is true */}
                                    <input
                                        value={methods.watch('linked_people['+props.person_id+'].people_data.first') ?? ""}
                                        name={props.type + '_first_' + props.person_id}
                                        id={props.type + 'NAME' + props.person_id}
                                        data-fieldref={'first'}
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.PLACEHOLDER.FIRSTNAME' })}
                                        disabled={isLocked}
                                        onChange={(e) => {updateLinkedPeople(e.target.dataset.fieldref, e.target.value)}}
                                    />
                                </div>
                                <div className='col pe-0'>
                                    <input
                                        value = {methods.watch('linked_people['+props.person_id+'].people_data.last_name') ?? ""}
                                        name={props.type+ '_last_name_'+ props.person_id}
                                        id={props.type + 'LAST' + props.person_id}
                                        data-fieldref={'last_name'}
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.PLACEHOLDERLASTNAME' })}
                                        disabled={isLocked}
                                        onChange={(e) => {updateLinkedPeople(e.target.dataset.fieldref, e.target.value)}}
                                    />
                                </div>
                            </>
                            }
                            <div className='col-lg-1 w-lg-60px d-flex'>
                                <div className='col-lg-1 w-lg-20px d-flex align-items-center'>
                                    <i onClick={() => props.entry_id > 0 && props.person_id? setIsLocked(!isLocked): null} className={"fas fa-solid py-3" + (isLocked ? ' fa-lock' : ' fa-lock-open')}></i>
                                </div>
                                <div className='col-lg-1 w-lg-25px d-flex align-items-center'>
                                    {props.person_id > 0 /*&& Object.keys(methods.getValues(`linked_people[${props.person_id}].people_data`)).length > 0*/ ?<i className="fa-sharp fa-solid fa-circle-info fa-xl py-3" style={{ color: '#578EBE' }}  onClick ={() =>{props.callbackFromParent(props.person_id, methods.getValues(`linked_people[${props.person_id}]`), "People", "PeopleDetail", methods.getValues(`linked_people[${props.person_id}].people_data`))}}></i>: null}
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor={props.type + 'ADDRESS' + props.person_id}  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.ADDRESS`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'ADDRESS' })}</label>
                            <div className='col ps-0'>
                                <input
                                    value={methods.watch('linked_people[' +props.person_id+'].people_data.address') ?? ""}
                                    name={props.type+ '_address_' + props.person_id}
                                    id={props.type + 'ADDRESS' + props.person_id}
                                    data-fieldref={'address'}
                                    type='text'
                                    disabled={disableFields}
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'ADDRESS' })}
                                    onChange={(e) => {updateLinkedPeople(e.target.dataset.fieldref, e.target.value)}}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor={props.type + 'CITY' + props.person_id}  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.CITY`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'CITY' })}</label>
                            <div className='col px-0'>
                                <input
                                    value={methods.watch('linked_people[' +props.person_id+'].people_data.city') ?? ""}
                                    name={props.type + '_city_' + props.person_id}
                                    id={props.type + 'CITY' + props.person_id}
                                    data-fieldref={'city'}
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'CITY' })}
                                    onChange={(e) => {updateLinkedPeople(e.target.dataset.fieldref, e.target.value)}}
                                    disabled={disableFields}
                                />
                            </div>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-60px' htmlFor={props.type + 'STATE' + props.person_id}  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.STATE`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'STATE' })}</label>
                            <div className='col-2 px-0'>
                                <input
                                    value={methods.watch('linked_people[' +props.person_id+'].people_data.state') ?? ""}
                                    name={props.type + '_state_' + props.person_id}
                                    id={props.type + 'STATE' + props.person_id}
                                    data-fieldref={'state'}
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'STATE' })}
                                    onChange={(e) => {updateLinkedPeople(e.target.dataset.fieldref, e.target.value)}}
                                    disabled={disableFields}
                                />
                            </div>
                            <label className='col-lg-1 col-form-label fs-5 py-1 px-0 ps-6 d-flex justify-content-center w-lg-55px' htmlFor={props.type + 'ZIP' + props.person_id}  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.ZIP`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'ZIP' })}</label>
                            <div className='col-3'>
                                <input
                                    value={methods.watch('linked_people[' +props.person_id+'].people_data.postal_code') ?? ""}
                                    name={props.type + '_postal_code_' + props.person_id}
                                    id={props.type + 'ZIP' + props.person_id}
                                    data-fieldref={'postal_code'}
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'ZIP' })}
                                    onChange={(e) => {updateLinkedPeople(e.target.dataset.fieldref, e.target.value)}}
                                    disabled={disableFields}
                                />
                            </div>
                        </div>
                        {props.type == 'RIDER' ?
                        <>
                        <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor='GENDER' data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.GENDER`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.RTO.GENDER' })}</label>
                                <div className='col px-0'>
                                        {props.form_meta?.gender && (
                                            <Controller
                                            name={props.type + '_gender_' + props.person_id}
                                            render={({field: {onChange, value, name, fieldref}}) => (
                                                <Select
                                                options={props.form_meta.gender}
                                                id='GENDER'
                                                data-fieldref={'gender'}
                                                value={props.form_meta.gender.find(
                                                    (ss) => ss.value === methods.watch('linked_people[' +props.person_id+'].people_data.gender')
                                                )}
                                                name={name}
                                                isSearchable={true}
                                                onChange={(gender) => {
                                                    // 
                                                    updateLinkedPeople(name.split('_')[1], gender.value)
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                })}
                                                styles={reactSelectStyles}
                                                placeholder='Select'
                                                disabled={disableFields}
                                                />
                                            )}
                                            />
                                        )}
                                </div>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor={props.type + "SSN" + props.person_id}  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.SSN`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'SSN' })}</label>
                                <div className='col ps-1'>
                                    <Controller
                                        control={methods.control}
                                        name={props.type + '_social_security_number_' + props.person_id}
                                        render={({ field: { onChange, value, name} }) => (
                                            <PatternFormat
                                                id={props.type + "SSN" + props.person_id}
                                                format="###-##-####"
                                                data-fieldref={'social_security_number'}
                                                value={methods.watch(`linked_people[${props.person_id}].people_data.social_security_number`) || ''}
                                                // value={methods.watch('linked_people[' +props.person_id+'].people_data.social_security_number') === '' ? '' : Number(methods.watch('linked_people[' +props.person_id+'].people_data.social_security_number'))}
                                                onChange={(e)=>{
                                                    const unformattedValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                                                    updateLinkedPeople(e.target.dataset.fieldref, unformattedValue)
                                                    //TODO -- methods.setValue('entry.rider_age')
                                                }}
                                                className='form-control form-control-sm fs-6 min-h-20px py-1'
                                                disabled={disableFields}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 px-0 d-flex justify-content-center w-lg-70px' htmlFor={props.type + '_dob' + props.person_id}  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.DOB`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'DOB' })}</label>
                                <div className='col ps-0'>
                                    <div className="input-group date">
                                    <Controller
                                        name={props.type + '_dob_' + props.person_id}
                                        control={methods.control}
                                        render={({ field: { onChange, value, name } }) => {
                                            const formattedValue = (methods.watch('linked_people['+ props.person_id +'].people_data.dob') && moment(methods.watch('linked_people['+ props.person_id +'].people_data.dob')).isValid())
                                            ? moment(methods.watch('linked_people['+ props.person_id +'].people_data.dob')).format('MM/DD/YYYY')
                                            : '';
                                            return (
                                                <Flatpickr
                                                    className="form-control form-control-sm fs-6 min-h-20px py-1 date-placeholder"
                                                    id={props.type + "_dob_" + props.person_id}
                                                    name={name}
                                                    value={formattedValue}
                                                    placeholder="MM/DD/YYYY"
                                                    options={date_options}
                                                    autoComplete="off"
                                                    data-fieldref={'dob'}
                                                    onClose={(value, dateStr, instance) => {
                                                        const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                        if (!isValidDate) {
                                                            instance.input.value = ''; // Clear the input if the date is invalid
                                                            updateLinkedPeople(name.split('_')[1], '0000-00-00');
                                                        }else{
                                                            updateLinkedPeople(name.split('_')[1], moment(value[0]).format('YYYY-MM-DD'));
                                                        }
                                                        setDate(dateStr, onChange); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                    }}
                                                    onChange={(value, dateStr, instance) => {
                                                        const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                        if (!isValidDate) {
                                                            instance.input.value = ''; // Clear the input if the date is invalid
                                                            updateLinkedPeople(name.split('_')[1], '0000-00-00');
                                                        }else{
                                                            updateLinkedPeople(name.split('_')[1], moment(value[0]).format('YYYY-MM-DD'));
                                                        }
                                                        setDate(dateStr, onChange); // Set the typed/selected date in the datepicker and format date to YYYY-MM-DD
                                                    }}
                                                    onInput={(e) => { maskDateInput(e) }}
                                                    disabled={methods.watch(`${props.type}_disableDOB_${props.person_id}`) || disableFields}
                                                />
                                            );
                                            }}
                                        />
                                        <div className="input-group-append">
                                            <label htmlFor={props.type + '_dob' + props.person_id} className='date-label'
                                                onClick={(e) => {
                                                    e.stopPropagation(); 
                                                    document.getElementById(`${props.type}_dob${props.person_id}`)._flatpickr.open(); 
                                                }}
                                            >
                                                <span className="input-group-text">
                                                    <i className="la la-calendar"></i>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-65px' htmlFor={props.type + 'MOBILE' + props.person_id}  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.MOBILE`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'MOBILE' })}</label>
                                <div className='col ps-0 phone-column'>
                                    <Controller
                                        control={methods.control}
                                        name={props.type + '_mobile_phone_' + props.person_id}
                                        render={({ field: { onChange, value} }) => (
                                            <IntlTelInput
                                                id={props.type + "phoneNumber" + props.person_id}
                                                data-fieldref={'mobile_phone'}
                                                ref={inputRef}
                                                containerClassName='intl-tel-input country-code-picker'
                                                inputClassName='form-control fs-6 py-0'
                                                style={{ width: '100%', borderRadius: '0px' }}
                                                preferredCountries={["us", "ca"]}
                                                defaultCountry={parsePhoneNumber(`+${methods.getValues('linked_people[' +props.person_id+'].people_data.mobile_phone_country_code')}${methods.getValues('linked_people[' +props.person_id+'].people_data.mobile_phone')}`, 'US')?.country?.toLowerCase()}
                                                allowDropdown={true}
                                                placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.MOBILENUMBER' })}
                                                value={PhoneNumberFormatter(methods.getValues(`linked_people[${props.person_id}].people_data.mobile_phone`))}
                                                onPhoneNumberChange={(_isValid, _rawValue,_countryData,_formattedValue,_extension, e) => {
                                                    const unformattedValue = _rawValue.replace(/\D/g, ''); // Remove non-digit characters
                                                    inputRef?.current?.props && 
                                                    updateLinkedPeople(inputRef.current.props['data-fieldref'], unformattedValue);
                                                    if(_countryData.dialCode){
                                                        methods.setValue('linked_people[' +props.person_id+'].people_data.mobile_phone_country_code', _countryData.dialCode, { shouldDirty: true })
                                                    }
                                                }}
                                                onSelectFlag={(currentNumber, country) => {if(country){ 
                                                    methods.setValue('linked_people[' +props.person_id+'].people_data.mobile_phone_country_code', country.dialCode, { shouldDirty: true })
                                                    methods.setValue('linked_people['+props.person_id+'].people_data.isUpdated', true)
                                                }}}
                                                disabled={disableFields}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={'row mb-2 '}>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor={props.type + 'STATUS' + props.person_id}  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.STATUS`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'STATUS' })}</label>
                                <div className='col-lg-4 px-0 pe-1 mw-175px'>
                                    <input
                                        value= {getEntryRiderStatus('user_status') ?? ""}
                                        id={props.type + 'STATUS' + props.person_id}
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'STATUS' })}
                                        onChange={(e) => {updateRiderUserStatus(e.target.value); setRider(prevData => ({...prevData, user_status: e.target.value}))}}
                                        onBlur={(e) => {updateRiderUserStatus(e.target.value, true);}}
                                    />
                                </div>
                                <label className='col-lg-3 col-form-label fs-5 py-1 px-2 d-flex justify-content-start'>{getEntryRiderStatus('status')}</label>
                            <label className='col col-form-label fs-5 py-1 ps-0 d-flex justify-content-end'  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.SAFESPORTEXPIRY`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'SAFESPORTEXP' })} {methods.getValues('linked_people['+ props.person_id +'].people_data.safe_sport_training_expirydate') != '0000-00-00'? moment(methods.getValues('linked_people['+ props.person_id +'].people_data.safe_sport_training_expirydate')).format('MM/DD/YYYY'): '00/00/0000'}</label>
                            </div>
                        </>
                        :
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-70px' htmlFor={props.type + 'SSN' + props.person_id}  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.SSN`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'SSN' })}</label>
                            <div className='col px-0'>
                            <Controller
                                control={methods.control}
                                name={props.type + '_social_security_number_' + props.person_id}
                                render={({ field: { onChange, value, name} }) => (
                                    <PatternFormat
                                        id={props.type + "SSN" + props.person_id}
                                        format="###-##-####"
                                        data-fieldref={'social_security_number'}
                                        value={methods.watch(`linked_people[${props.person_id}].people_data.social_security_number`) || ''}
                                        onChange={(e)=>{
                                            const unformattedValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                                            updateLinkedPeople(e.target.dataset.fieldref, unformattedValue)
                                        }}
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        disabled={disableFields}
                                    />
                                )}
                            />
                            </div>
                            <label className='col-lg-1 col-form-label fs-5 py-1 d-flex justify-content-center w-lg-59px' htmlFor={props.type + '_dob'+ props.person_id}  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.DOB`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'DOB' })}</label>
                            <div className='col px-0'>
                                <div className="input-group date">
                                    <Controller
                                        name={props.type + '_dob_' + props.person_id}
                                        render={({
                                            field: { onChange, value, name },
                                        }) => {
                                            const formattedValue = (methods.watch('linked_people['+ props.person_id +'].people_data.dob') && moment(methods.watch('linked_people['+ props.person_id +'].people_data.dob')).isValid())
                                                ? moment(methods.watch('linked_people['+ props.person_id +'].people_data.dob')).format('MM/DD/YYYY')
                                                : '';

                                            return (
                                                <Flatpickr 
                                                    className="form-control form-control-sm fs-6 min-h-20px py-1 date-placeholder"
                                                    id={props.type + "_dob" + props.person_id}
                                                    name={name}
                                                    data-fieldref={'dob'}
                                                    value={formattedValue}
                                                    placeholder="MM/DD/YYYY"
                                                    options={date_options}
                                                    autoComplete={"off"}
                                                    onClose={(selectedDates, dateStr, instance) => {
                                                        const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                        if (!isValidDate) {
                                                            instance.input.value = ''; // Clear the input if the date is invalid
                                                            updateLinkedPeople(name.split('_')[1], '0000-00-00');
                                                        }else{
                                                            updateLinkedPeople(name.split('_')[1], moment(selectedDates[0]).format('YYYY-MM-DD'));
                                                        }
                                                        
                                                        setDate(dateStr, onChange); // Set the valid date
                                                    }}
                                                    onChange={(selectedDates, dateStr, instance) => {
                                                        const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                        if (!isValidDate) {
                                                            instance.input.value = ''; // Clear the input if the date is invalid
                                                            updateLinkedPeople(name.split('_')[1], '0000-00-00');
                                                        }else{
                                                            updateLinkedPeople(name.split('_')[1], moment(selectedDates[0]).format('YYYY-MM-DD'));
                                                        }
                                                        
                                                        setDate(dateStr, onChange); // Set the valid date
                                                    }}
                                                    onInput={(e) => { maskDateInput(e) }}
                                                    disabled={methods.watch(`${props.type}_disableDOB_${props.person_id}`) || disableFields}  
                                                />
                                            );
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <label htmlFor={props.type + '_dob' + props.person_id} className='date-label'
                                            onClick={(e) => {
                                                e.stopPropagation(); 
                                                document.getElementById(`${props.type}_dob${props.person_id}`)._flatpickr.open(); 
                                            }}
                                        >
                                            <span className="input-group-text">
                                                <i className="la la-calendar"></i>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-60px' htmlFor={props.type + 'MOBILE' + props.person_id}  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.MOBILE`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.' + props.type + 'MOBILE' })}</label>
                                <div className='col ps-1 phone-column'>
                                    <Controller
                                        control={methods.control}
                                        name={props.type + '_mobile_phone_' + props.person_id}
                                        render={({ field: { onChange, value} }) => (
                                            <IntlTelInput
                                                id={props.type + "phoneNumber" + props.person_id}
                                                containerClassName='intl-tel-input country-code-picker'
                                                inputClassName='form-control fs-6 py-0'
                                                style={{ width: '100%', borderRadius: '0px'}}
                                                preferredCountries={["us", "ca"]}
                                                data-fieldref={'mobile_phone'}
                                                ref={inputRef}
                                                defaultCountry={parsePhoneNumber(`+${methods.getValues('linked_people[' +props.person_id+'].people_data.mobile_phone_country_code')}${methods.getValues('linked_people[' +props.person_id+'].people_data.mobile_phone')}`, 'US')?.country?.toLowerCase()}
                                                allowDropdown={true}
                                                placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.MOBILENUMBER' })}
                                                value={PhoneNumberFormatter(methods.getValues(`linked_people[${props.person_id}].people_data.mobile_phone`))}
                                                onPhoneNumberChange={(_isValid, _rawValue,_countryData,_formattedValue,_extension, e) => {
                                                    const unformattedValue = _rawValue.replace(/\D/g, ''); // Remove non-digit characters
                                                    inputRef?.current?.props &&
                                                    updateLinkedPeople(inputRef.current.props['data-fieldref'], unformattedValue);
                                                    if(_countryData.dialCode){
                                                        methods.setValue('linked_people[' +props.person_id+'].people_data.mobile_phone_country_code', _countryData.dialCode, { shouldDirty: true })
                                                    }
                                                }}
                                                onSelectFlag={(currentNumber, country) => {
                                                    if(country){ 
                                                        methods.setValue('linked_people[' +props.person_id+'].people_data.mobile_phone_country_code', country.dialCode, { shouldDirty: true })
                                                        methods.setValue('linked_people['+props.person_id+'].people_data.isUpdated', true)
                                                    }
                                                }}
                                                disabled={disableFields}
                                            />
                                        )}
                                    />
                                </div>
                        </div>}
                    </div> 
                    <div className='col-lg-5 membership-data-grid'>
                        <OutputListingGridStatic
                            // area ={"Entries - Person Section"}
                            key={methods.watch('entry.entryowner_id')}
                            setGridRef={setGridRef}
                            columnDefs={columnDefs}
                            rowData={rowData?.length > 0 ? rowData: methods.getValues('people_organization') ?? []}
                            containerStyle={containerStyle}
                            rowClassRules={rowClassRules}
                            rowHeight={getExtraNarrowRowHeight}
                            onCellDoubleClick={onCellDoubleClick}
                            onCellValueChanged={onCellValueChanged}
                            enableRangeSelection={true}
                            stopEditingWhenCellsLoseFocus={true}
                        ></OutputListingGridStatic>
                    </div>
                </div>
                <hr className='my-2' />
                <div className='row'>
                    <div className='col-lg-1 w-30px d-flex py-1 align-items-center me-1'>
                        <i onClick={updateLoginData} class="far fa-solid fa-rotate text-success fs-3 fw-bold cursor-pointer"></i>&nbsp;
                    </div>
                    <div className='col ps-0'>
                        <div className='row'>
                            {login_id>0 ?
                            <div className='col-lg-9 d-flex align-items-center connected-login-entries'>
                                <div className='col-lg-5 d-flex align-items-center'>
                                    <strong className='linked-label'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LINKEDLOGIN'})}&nbsp;</strong>
                                    <strong className='linked-value'>{methods.getValues('linked_people['+props.person_id+'].people_data.sgl_fl_name')}</strong>
                                </div>
                                <div className='col d-flex align-items-center'>
                                    <div className='col-lg-1 w-lg-20px d-flex align-items-center'>
                                        {methods.getValues('linked_people['+props.person_id+'].people_data.sgl_email') ? methods.getValues('linked_people['+props.person_id+'].people_data.sgl_email_verified')?
                                            <i className="fa-sharp fa-solid fa-check-circle fa-xl text-success"></i>
                                            :
                                            <i className="fas fa-solid fa-circle-exclamation fa-xl text-warning"></i>
                                        : ''}
                                    </div>
                                    <a className='col col-form-label fs-6 py-0 pb-1' href={"mailto:"+methods.getValues('linked_people['+props.person_id+'].people_data.sgl_email')}>{methods.getValues('linked_people['+props.person_id+'].people_data.sgl_email')}</a>
                                </div>
                                <div className='col d-flex align-items-center'>
                                    <div className='col-lg-1 w-lg-20px d-flex align-items-center'>
                                        {methods.getValues('linked_people['+props.person_id+'].people_data.sgl_mobile') ? methods.getValues('linked_people['+props.person_id+'].people_data.sgl_mobile_verified')?
                                            <i className="fa-sharp fa-solid fa-check-circle fa-xl text-success"></i>
                                            :
                                            <i className="fas fa-solid fa-circle-exclamation fa-xl text-warning"></i>
                                        : ''}
                                    </div>
                                    <label className='col col-form-label fs-6 py-1'>{ phoneFormatter(methods.getValues('linked_people['+props.person_id+'].people_data.sgl_mobile')) }</label>
                                </div>
                            </div>
                            :
                                <div className='col-lg-9 d-flex align-items-center connected-login-entries'>
                                    <strong className='linked-label'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LINKEDLOGIN'})}&nbsp;</strong>
                                    <strong className='linked-value'>NONE</strong>
                                </div>
                            }
                            {entryAuthorizationRequest?.status == 'Signed' ?
                                <div className='col d-flex align-items-center justify-content-end'>
                                    <div className='col-lg-1 w-lg-20px d-flex align-items-center'>
                                        <i className="fa-sharp fa-solid fa-check fa-xl py-3 text-success" style={{ fontSize: "1.2em" }}></i>
                                    </div>
                                    <label className='col-form-label fs-6 py-1'  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.SIGNATUREOBTAINED`}>Signature Obtained</label>
                                </div>
                            : (entryAuthorizationRequest?.phone_number != '' || entryAuthorizationRequest?.agent_will_sign ) && entryAuthorizationRequest?.status == 'Sent' ? 
                            <div className='col d-flex align-items-center justify-content-end'>
                                <div className='col-lg-1 w-lg-20px d-flex align-items-center'>
                                    <i className="fa-sharp fa-solid fa-clock fa-xl py-3" style={{ fontSize: "1.2em" }}></i>
                                </div>
                                <label className='col-form-label fs-6 py-1'  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.SIGNATUREREQUESTSENT`}>{`Signature Request Sent${entryAuthorizationRequest?.agent_will_sign?" (Agent)":""}`}</label>
                            </div>
                            :
                            <div className='col d-flex align-items-center justify-content-end'>
                                <div className='col-lg-1 w-lg-20px d-flex align-items-center'>
                                    <i className="fa-sharp fa-solid fa-circle-exclamation fa-xl py-3" style={{ color: '#e62721', fontSize: "1.2em" }}></i>
                                </div>
                                {entryAuthorizationRequest?.phone_number || 
                                methods.getValues(`linked_people[${props.person_id}].people_data.sgl_mobile`) ||
                                methods.getValues(`linked_people[${props.person_id}].people_data.sgl_mobile_verified`) ||
                                methods.getValues(`linked_people[${props.person_id}].people_data.mobile_phone`) ? 
                                <label className='col-form-label fs-6 py-1'  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.SIGNATUREMISSING`}>Signature Missing</label>
                                :
                                <label className='col-form-label fs-6 py-1'  data-tooltip-id={`ENTRIES.DETAIL.TAB.GENERAL.SECTION.${props.type}.LABEL.NONUMBERAVAILABLE`}>No Number Available</label>}
                            </div>
                            }
                        </div>
                    </div>
                    <div className='col-lg-1 w-20px ps-0 d-flex py-1 align-items-center dropdown'>
                        <div className="dropdown">
                            <a className="fa-sharp fa-solid fa-caret-down fa-lg text-dark" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a>
                            {entryAuthorizationRequest?.status == 'Signed'?
                                <ul className="dropdown-menu dropdown-menu-end mt-2" style={{zIndex: '1021'}}>
                                    <li className='dropdown-item cursor-pointer' onClick={handleInvalidSignature}>Invalidate Signature</li>
                                </ul>
                                :
                                <ul className="dropdown-menu dropdown-menu-end mt-2" style={{zIndex: '1021'}}>
                                    {
                                        ( hasSpecialPermissionToAccess('cloud_sgadmin:has_access') && 
                                            entryAuthorizationRequest?.phone_number != '' && 
                                            entryAuthorizationRequest?.status == 'Sent'
                                        )? 
                                        <li className='dropdown-item cursor-pointer' onClick={() => alertDialog({message: entryAuthorizationRequest.sms_text, icon: 'info', title: 'SMS Message', okButtonTitle: 'Close'})}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.VIEWSIGAUTHREQUESTSMS' })}</li>
                                        :<></>
                                    }
                                    <li className='dropdown-item cursor-pointer' onClick={sendEntryAuthorizationReq}>{`${intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.SENDSIGAUTHREQUEST' })} ${entryAuthorizationRequest?.agent_will_sign ? '(Agent)' : ''}`}</li>
                                    <li className='dropdown-item cursor-pointer' onClick={() => setShowManualSignatureForm(true)}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MANUALSIGNATURERECIEVED' })}</li>
                                </ul>
                            }
                        </div>
                    </div>
                </div>
        </>
    );
}

export { PersonSection }