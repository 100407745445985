// Proximity Algorithm
// This algorithm will be used to find the best stalls for the reservation

import {isStallDNR, isStallTack} from '../Components/CanvasUtility'

// Configuration arrays for stall properties - can be easily modified in the future
// const STALL_SIZES = ['any', 'standard', 'mini', 'small', 'large', 'extra large']
const STALL_SIZES = ['any', 'standard', 'mini', 'small', 'large', 'extra-large']
const STALL_TYPES = ['any', 'standard', 'premium', 'fei']

const getAvailableStalls = (enhancedStallData, request) => {
  // Filter stalls based on DNR, size, type, and booking status
  return enhancedStallData.filter((stall) => {
    // Skip invalid stalls
    if (!stall || !stall.status) return false

    // IMPORTANT: Explicitly exclude DNR stalls first - most critical filter
    if (isStallDNR(stall)) {
      return false
    }

    // Check if stall is marked as reservable
    const isReservable = stall.status.isReservable !== false
    if (!isReservable) return false

    // Filter by stall size if specified (not 'Any')
    if (request.stallSize && request.stallSize.toLowerCase() !== 'any') {
      // Check if requested size is in our valid sizes list (case insensitive)
      const requestedSizeLower = request.stallSize.toLowerCase()
      const validSizesLower = STALL_SIZES.map((size) => size.toLowerCase())

      if (!validSizesLower.includes(requestedSizeLower)) {
        // Invalid size requested, fall back to 'any'
        console.warn(`Invalid stall size requested: ${request.stallSize}. Using 'any' instead.`)
      } else if (stall.stallSize?.toLowerCase() !== requestedSizeLower) {
        return false
      }
    }

    // Filter by stall type if specified (not 'Any')
    if (request.stallType && request.stallType.toLowerCase() !== 'any') {
      // Check if requested type is in our valid types list (case insensitive)
      const requestedTypeLower = request.stallType.toLowerCase()
      const validTypesLower = STALL_TYPES.map((type) => type.toLowerCase())

      if (!validTypesLower.includes(requestedTypeLower)) {
        // Invalid type requested, fall back to 'any'
        console.warn(`Invalid stall type requested: ${request.stallType}. Using 'any' instead.`)
      } else if (stall.stallType?.toLowerCase() !== requestedTypeLower) {
        return false
      }
    }

    // Handle tack stall filtering
    if (request.includeTackStalls) {
      // If we're including tack stalls, we'll handle them separately
      // This function just returns regular stalls
      if (isStallTack(stall)) {
        return false
      }
    } else {
      // If not including tack stalls, exclude all tack stalls
      if (isStallTack(stall)) {
        return false
      }
    }

    // Check booking availability
    if (!stall.status.currentBooking) return true

    // Check if the stall is available during the requested date range
    return (
      stall.status.currentBooking.endDate <= request.dateRange.startDate ||
      stall.status.currentBooking.startDate >= request.dateRange.endDate
    )
  })
}

const processStallProximityData = (canvasElements, clickOrigin, request, setCanvasElements) => {
  // First, check if we need to adjust the origin based on tack stall requirement
  let actualOrigin = {...clickOrigin}
  let selectedTackStall = null // Track the selected tack stall

  // Fix for Tack Stall Origin: ensure we're using includeTackStalls from request
  if (request.includeTackStalls) {
    // Get all tack stalls from canvas elements
    const tackStalls = getAllStallElements(canvasElements).filter((stall) => isStallTack(stall))

    // If we have tack stalls, find the one nearest to the original click point
    if (tackStalls.length > 0) {
      let nearestTackStall = null
      let minDistance = Infinity

      tackStalls.forEach((tackStall) => {
        const stallCenter = {
          x: tackStall.x + tackStall.width / 2,
          y: tackStall.y + tackStall.height / 2,
        }

        const distance = Math.sqrt(
          Math.pow(stallCenter.x - clickOrigin.x, 2) + Math.pow(stallCenter.y - clickOrigin.y, 2)
        )

        if (distance < minDistance) {
          minDistance = distance
          nearestTackStall = tackStall
        }
      })

      // If we found a tack stall, use its position as the origin
      if (nearestTackStall) {
        selectedTackStall = nearestTackStall
        actualOrigin = {
          x: nearestTackStall.x + nearestTackStall.width / 2,
          y: nearestTackStall.y + nearestTackStall.height / 2,
        }
      }
    }
  }

  const enhancedStallData = calculateStallProximityData(
    canvasElements,
    actualOrigin,
    setCanvasElements
  )

  const availableStalls = getAvailableStalls(enhancedStallData, request)

  if (availableStalls.length === 0 || availableStalls.length < request.numberOfStalls) {
    return []
  }

  // Filter stalls based on DNR, size, and type criteria
  const filteredStallData = enhancedStallData.filter((stall) => {
    // IMPORTANT: Explicitly exclude DNR stalls first - most critical fix
    if (isStallDNR(stall)) {
      return false
    }

    // Filter by stall size if specified (not 'Any')
    if (request.stallSize && request.stallSize.toLowerCase() !== 'any') {
      // Check if requested size is in our valid sizes list (case insensitive)
      const requestedSizeLower = request.stallSize.toLowerCase()
      const validSizesLower = STALL_SIZES.map((size) => size.toLowerCase())

      if (!validSizesLower.includes(requestedSizeLower)) {
        // Invalid size requested, fall back to 'any'
        console.warn(`Invalid stall size requested: ${request.stallSize}. Using 'any' instead.`)
      } else if (stall.stallSize?.toLowerCase() !== requestedSizeLower) {
        return false
      }
    }

    // Filter by stall type if specified (not 'Any')
    if (request.stallType && request.stallType.toLowerCase() !== 'any') {
      // Check if requested type is in our valid types list (case insensitive)
      const requestedTypeLower = request.stallType.toLowerCase()
      const validTypesLower = STALL_TYPES.map((type) => type.toLowerCase())

      if (!validTypesLower.includes(requestedTypeLower)) {
        // Invalid type requested, fall back to 'any'
        console.warn(`Invalid stall type requested: ${request.stallType}. Using 'any' instead.`)
      } else if (stall.stallType?.toLowerCase() !== requestedTypeLower) {
        return false
      }
    }

    // FIXED: The key issue - when includeTackStalls is true:
    // 1. If this is the selected tack stall, exclude it (we'll add it back at the end)
    // 2. For all other stalls, include only non-tack stalls
    if (request.includeTackStalls) {
      // Exclude the selected tack stall from filtered results
      if (selectedTackStall && stall.id === selectedTackStall.id) {
        return false
      }

      // Include only non-tack stalls
      return !isStallTack(stall)
    }

    // If not including tack stalls, exclude all tack stalls
    return !isStallTack(stall)
  })

  const aisleElements = canvasElements.filter((el) => el.isAisle)
  const aisleLayout = new AisleLayout(aisleElements)

  // Sort stalls by distance from origin
  filteredStallData.sort((a, b) => a.distanceFromOrigin - b.distanceFromOrigin)

  const analyzer = new StallProximityAnalyzer(filteredStallData, aisleLayout)

  // FIXED: Adjust the number of stalls to find
  // When includeTackStalls is true, we need one less regular stall
  let adjustedNumberOfStalls = request.numberOfStalls
  if (request.includeTackStalls && selectedTackStall) {
    adjustedNumberOfStalls = Math.max(1, request.numberOfStalls - 1)
  }

  // Make sure we don't try to find more stalls than are available
  adjustedNumberOfStalls = Math.min(adjustedNumberOfStalls, filteredStallData.length)

  // Only proceed if we have stalls that match our criteria
  if (adjustedNumberOfStalls === 0 && !selectedTackStall) {
    return [] // No stalls to reserve
  }

  // Update request with adjusted stall count
  const adjustedRequest = {
    ...request,
    numberOfStalls: adjustedNumberOfStalls,
  }

  const optimalGroups = analyzer.findOptimalStallGroup(adjustedRequest)

  // // FIXED: Add the tack stall ID if we have one
  // let bestStallsIds =  optimalGroups[0]?.stalls ?? []

  // if (selectedTackStall && request.includeTackStalls) {
  //   // Add the selected tack stall to the beginning of the result
  //   bestStallsIds = [selectedTackStall.id, ...bestStallsIds]
  // }

  // return bestStallsIds

  const allBestStallGroups = optimalGroups.map((group) => {
    if (selectedTackStall && request.includeTackStalls) {
      return {
        ...group,
        stalls: [selectedTackStall.id, ...group.stalls],
      }
    }
    return group
  })

  console.log('allBestStallGroups', allBestStallGroups)

  return allBestStallGroups
}

const calculateStallProximityData = (canvasElements, clickOrigin, setCanvasElements) => {
  // Get all stalls from canvas elements
  const stallElements = getAllStallElements(canvasElements)

  // Get all aisles from canvas elements
  const aisleElements = canvasElements.filter((el) => el.isAisle)

  // Get the enhanced stall data
  const enhancedStallData = processStallsData(stallElements, aisleElements)

  // Add distance from click origin to each stall
  enhancedStallData.forEach((stall) => {
    // Calculate distance from stall center to click origin
    const stallCenter = {
      x: stall.position.x + stall.position.width / 2,
      y: stall.position.y + stall.position.height / 2,
    }

    stall.distanceFromOrigin = Math.sqrt(
      Math.pow(stallCenter.x - clickOrigin.x, 2) + Math.pow(stallCenter.y - clickOrigin.y, 2)
    )
  })

  return enhancedStallData
}

// ********************* DATA GENERATION FOR PROXIMITY ALGO START HERE *******************************

// Utility class for geometric operations
class GeometryUtils {
  static hasHorizontalOverlap(stall1, stall2) {
    const stall1Left = stall1.x
    const stall1Right = stall1.x + stall1.width
    const stall2Left = stall2.x
    const stall2Right = stall2.x + stall2.width

    const toleranceGap = Math.min(stall1.width, stall2.width) * 0.1

    return !(stall1Right + toleranceGap < stall2Left || stall2Right + toleranceGap < stall1Left)
  }

  static hasVerticalOverlap(stall1, stall2) {
    const stall1Top = stall1.y
    const stall1Bottom = stall1.y + stall1.height
    const stall2Top = stall2.y
    const stall2Bottom = stall2.y + stall2.height

    const toleranceGap = Math.min(stall1.height, stall2.height) * 0.1

    return !(stall1Bottom + toleranceGap < stall2Top || stall2Bottom + toleranceGap < stall1Top)
  }

  static calculateDistance(x1, y1, x2, y2) {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
  }

  static getCorners(stall) {
    return [
      {x: stall.x, y: stall.y}, // top-left
      {x: stall.x + stall.width, y: stall.y}, // top-right
      {x: stall.x, y: stall.y + stall.height}, // bottom-left
      {x: stall.x + stall.width, y: stall.y + stall.height}, // bottom-right
    ]
  }

  static isAdjacentInDirection(stall, otherStall, direction, threshold = 0.9) {
    if (otherStall.id === stall.id) return false

    switch (direction) {
      case 'top':
        return (
          Math.abs(otherStall.y + otherStall.height - stall.y) < threshold &&
          this.hasHorizontalOverlap(stall, otherStall)
        )
      case 'bottom':
        return (
          Math.abs(otherStall.y - (stall.y + stall.height)) < threshold &&
          this.hasHorizontalOverlap(stall, otherStall)
        )
      case 'left':
        return (
          Math.abs(otherStall.x + otherStall.width - stall.x) < threshold &&
          this.hasVerticalOverlap(stall, otherStall)
        )
      case 'right':
        return (
          Math.abs(otherStall.x - (stall.x + stall.width)) < threshold &&
          this.hasVerticalOverlap(stall, otherStall)
        )
      default:
        return false
    }
  }
}

// Class to manage aisle-related operations
class AisleLayout {
  constructor(aisles) {
    this.aisles = aisles
  }

  getAislesBetween(stall1, stall2, orientation) {
    return this.aisles.filter((aisle) => {
      if (aisle.orientation !== orientation) return false

      if (orientation === 'vertical') {
        const minX = Math.min(stall1.position.x, stall2.position.x)
        const maxX = Math.max(
          stall1.position.x + stall1.position.width,
          stall2.position.x + stall2.position.width
        )
        return aisle.x > minX && aisle.x < maxX
      } else {
        const minY = Math.min(stall1.position.y, stall2.position.y)
        const maxY = Math.max(
          stall1.position.y + stall1.position.height,
          stall2.position.y + stall2.position.height
        )
        return aisle.y > minY && aisle.y < maxY
      }
    })
  }

  getVerticalAislesBetween(stall1, stall2) {
    return this.getAislesBetween(stall1, stall2, 'vertical')
  }

  getHorizontalAislesBetween(stall1, stall2) {
    return this.getAislesBetween(stall1, stall2, 'horizontal')
  }

  getAllAislesBetween(stall1, stall2) {
    return [
      ...this.getVerticalAislesBetween(stall1, stall2),
      ...this.getHorizontalAislesBetween(stall1, stall2),
    ]
  }

  isStallAdjacentToAisle(stall, aisle) {
    const stallBounds = {
      left: stall.x,
      right: stall.x + stall.width,
      top: stall.y,
      bottom: stall.y + stall.height,
    }

    const aisleBounds = {
      left: aisle.x,
      right: aisle.x + aisle.width,
      top: aisle.y,
      bottom: aisle.y + aisle.height,
    }

    const GAP = 50

    return (
      ((Math.abs(stallBounds.right - aisleBounds.left) <= GAP ||
        Math.abs(stallBounds.left - aisleBounds.right) <= GAP) &&
        GeometryUtils.hasVerticalOverlap(stall, aisle)) ||
      ((Math.abs(stallBounds.bottom - aisleBounds.top) <= GAP ||
        Math.abs(stallBounds.top - aisleBounds.bottom) <= GAP) &&
        GeometryUtils.hasHorizontalOverlap(stall, aisle))
    )
  }

  getNearbyAisles(stall) {
    return this.aisles.filter((aisle) => this.isStallAdjacentToAisle(stall, aisle))
  }

  findPrimaryAisle(stall, nearbyAisles) {
    return nearbyAisles.find((aisle) => {
      switch (stall.orientation) {
        case 'top':
          return aisle.y < stall.y && GeometryUtils.hasHorizontalOverlap(stall, aisle)
        case 'bottom':
          return (
            aisle.y >= stall.y + stall.height && GeometryUtils.hasHorizontalOverlap(stall, aisle)
          )
        case 'left':
          return aisle.x < stall.x && GeometryUtils.hasVerticalOverlap(stall, aisle)
        case 'right':
          return aisle.x > stall.x + stall.width && GeometryUtils.hasVerticalOverlap(stall, aisle)
        default:
          return false
      }
    })
  }

  isStallOnOppositeSideOfAisle(stall1, stall2, aisle, orientation) {
    if (orientation === 'vertical') {
      const stall1OnLeft = stall1.x + stall1.width <= aisle.x
      const stall2OnRight = stall2.x >= aisle.x + aisle.width
      const stall1OnRight = stall1.x >= aisle.x + aisle.width
      const stall2OnLeft = stall2.x + stall2.width <= aisle.x

      return (stall1OnLeft && stall2OnRight) || (stall1OnRight && stall2OnLeft)
    } else {
      const stall1Above = stall1.y + stall1.height <= aisle.y
      const stall2Below = stall2.y >= aisle.y + aisle.height
      const stall1Below = stall1.y >= aisle.y + aisle.height
      const stall2Above = stall2.y + stall2.height <= aisle.y

      return (stall1Above && stall2Below) || (stall1Below && stall2Above)
    }
  }

  isFacingAcrossAisle(stall1, stall2) {
    // Check if stalls have opposing orientations
    const oppositeOrientations = {
      top: 'bottom',
      bottom: 'top',
      left: 'right',
      right: 'left',
    }

    if (stall2.orientation !== oppositeOrientations[stall1.orientation]) {
      return false
    }

    // Check if they're on opposite sides of an aisle
    const relevantAisles = this.getAllAislesBetween({position: stall1}, {position: stall2})

    if (relevantAisles.length === 0) return false

    // Check if their entrances face each other
    if (stall1.orientation === 'top' && stall2.orientation === 'bottom') {
      // Check vertical alignment
      return GeometryUtils.hasHorizontalOverlap(stall1, stall2)
    } else if (stall1.orientation === 'bottom' && stall2.orientation === 'top') {
      // Check vertical alignment
      return GeometryUtils.hasHorizontalOverlap(stall1, stall2)
    } else if (stall1.orientation === 'left' && stall2.orientation === 'right') {
      // Check horizontal alignment
      return GeometryUtils.hasVerticalOverlap(stall1, stall2)
    } else if (stall1.orientation === 'right' && stall2.orientation === 'left') {
      // Check horizontal alignment
      return GeometryUtils.hasVerticalOverlap(stall1, stall2)
    }

    return false
  }
}

// Helper functions
const calculateStallEntrance = (stall) => {
  const entrance = {
    x: stall.x,
    y: stall.y,
    direction: stall.orientation,
  }

  switch (stall.orientation) {
    case 'top':
      entrance.x += stall.width / 2
      break
    case 'bottom':
      entrance.x += stall.width / 2
      entrance.y += stall.height
      break
    case 'left':
      entrance.y += stall.height / 2
      break
    case 'right':
      entrance.x += stall.width
      entrance.y += stall.height / 2
      break
  }

  return entrance
}

const findAdjacentStall = (stall, allStalls, direction) => {
  const candidates = allStalls.filter((otherStall) =>
    GeometryUtils.isAdjacentInDirection(stall, otherStall, direction)
  )

  if (!candidates.length) return null

  // Find the closest candidate based on center alignment
  const compareAxis = direction === 'top' || direction === 'bottom' ? 'x' : 'y'
  const stallCenter = stall[compareAxis] + (compareAxis === 'x' ? stall.width : stall.height) / 2

  return candidates
    .map((c) => ({
      stall: c,
      diff: Math.abs(c[compareAxis] + (compareAxis === 'x' ? c.width : c.height) / 2 - stallCenter),
    }))
    .sort((a, b) => a.diff - b.diff)[0].stall.id
}

const findStallsAcrossAisle = (stall, allStalls, aisleElements, orientation) => {
  const aisleLayout = new AisleLayout(aisleElements)
  const relevantAisles = aisleElements.filter(
    (aisle) => aisle.orientation === orientation && aisleLayout.isStallAdjacentToAisle(stall, aisle)
  )

  return allStalls
    .filter((otherStall) => {
      if (otherStall.id === stall.id) return false
      return relevantAisles.some((aisle) =>
        aisleLayout.isStallOnOppositeSideOfAisle(stall, otherStall, aisle, orientation)
      )
    })
    .map((s) => s.id)
}

const findStallsAcrossVerticalAisle = (stall, allStalls, aisleElements) => {
  return findStallsAcrossAisle(stall, allStalls, aisleElements, 'vertical')
}

const findStallsAcrossHorizontalAisle = (stall, allStalls, aisleElements) => {
  return findStallsAcrossAisle(stall, allStalls, aisleElements, 'horizontal')
}

const findAllNeighbors = (stall, allStalls, aisleElements) => {
  // Find adjacent stalls
  const adjacent = {
    top: findAdjacentStall(stall, allStalls, 'top'),
    bottom: findAdjacentStall(stall, allStalls, 'bottom'),
    left: findAdjacentStall(stall, allStalls, 'left'),
    right: findAdjacentStall(stall, allStalls, 'right'),
  }

  // Add adjacent stalls to the stall object for reference in other calculations
  stall.neighbors = {}
  stall.neighbors.adjacent = adjacent

  // Find stalls across aisles
  const acrossAisle = {
    vertical: findStallsAcrossVerticalAisle(stall, allStalls, aisleElements),
    horizontal: findStallsAcrossHorizontalAisle(stall, allStalls, aisleElements),
  }

  return {
    adjacent,
    acrossAisle,
  }
}

// Main data processing function
const processStallsData = (stallElements, aisleElements) => {
  return stallElements.map((stall) => {
    const entrance = calculateStallEntrance(stall)
    const neighbors = findAllNeighbors(stall, stallElements, aisleElements)
    const aisleLayout = new AisleLayout(aisleElements)
    const aisleAccess = determineAisleAccess(stall, aisleElements)

    return {
      id: stall.id,
      name: stall.name || `Stall ${stall.id.split('-')[1]}`,
      position: {
        x: stall.x,
        y: stall.y,
        width: stall.width,
        height: stall.height,
      },
      entrance,
      aisleAccess,
      neighbors,
      canvas_stall_id: stall.id,
      is_reservable: true,
      orientation: stall.orientation,
      row_number: calculateRowNumber(stall),
      row_position: calculatePosition(stall, 'row'),

      // Important: Ensure these properties are explicitly included
      stallSize: stall.stallSize || 'standard', // Default to standard if not specified
      stallType: stall.stallType || 'standard', // Default to standard if not specified
      isDnrStall: stall.isDnrStall === true, // Ensure this is strictly a boolean
      isTackStall: stall.isTackStall === true, // Ensure this is strictly a boolean

      status: {
        // Consider the DNR state when determining reservability
        isReservable: stall.isReservable !== false && stall.isDnrStall !== true,
        currentBooking: stall.currentBooking || null,
      },
    }
  })
}

const determineAisleAccess = (stall, aisleElements) => {
  const aisleLayout = new AisleLayout(aisleElements)
  const nearbyAisles = aisleElements.filter((aisle) =>
    aisleLayout.isStallAdjacentToAisle(stall, aisle)
  )
  const primaryAisle = aisleLayout.findPrimaryAisle(stall, nearbyAisles)

  return {
    primaryAisle: primaryAisle?.id || null,
    primaryAisleType: primaryAisle?.orientation || null,
    secondaryAisles: nearbyAisles
      .filter((aisle) => aisle.id !== primaryAisle?.id)
      .map((aisle) => aisle.id),
  }
}

const calculateRowNumber = (stall) => {
  return Math.floor(stall.y / stall.height)
}

const calculatePosition = (stall, type) => {
  return type === 'row' ? Math.floor(stall.x / stall.width) : Math.floor(stall.y / stall.height)
}

// Utility functions for retrieving and manipulating stall elements
// Updated getAllStallElements to handle stall properties consistently
const getAllStallElements = (canvasElements) => {
  const stallElements = canvasElements.filter((el) => el.isStall)

  // Extract stall children from group elements
  const groupedStallElements = []
  canvasElements.forEach((el) => {
    if (el.isGroup) {
      el.children.forEach((child) => {
        if (child.isStall) {
          // Calculate absolute position by adding group position to child position
          const absolutePosition = {
            x: el.x + child.x, // Add group's x to child's x
            y: el.y + child.y, // Add group's y to child's y
            width: child.width * (el.scaleX || 1),
            height: child.height * (el.scaleY || 1),
          }
          // Important: Make sure child stalls inherit proper properties
          const stallWithProperties = {
            ...child,
            x: absolutePosition.x,
            y: absolutePosition.y,
            width: absolutePosition.width,
            height: absolutePosition.height,
            // If these properties aren't defined on the child, inherit from the parent group
            stallSize: child.stallSize || el.stallSize || 'standard',
            stallType: child.stallType || el.stallType || 'standard',
            isDnrStall: child.isDnrStall === true || el.isDnrStall === true,
            isTackStall: child.isTackStall === true || el.isTackStall === true,
          }
          groupedStallElements.push(stallWithProperties)
        }
      })
    }
  })

  return [...stallElements, ...groupedStallElements]
}

// ********************* DATA GENERATION FOR PROXIMITY ALGO END HERE *********************************

// ********************* PROXIMITY ALGORITHM CALCULATION PART START HERE *****************************
class StallProximityAnalyzer {
  constructor(stalls, aisleLayout) {
    this.stallMap = new Map(stalls.map((s) => [s.id, s]))
    this.aisleLayout = aisleLayout

    // Cost weights for different types of movement
    this.HORIZONTAL_WALK_ON_HORIZONTAL_AISLE_COST = 1.0
    this.VERTICAL_WALK_ON_HORIZONTAL_AISLE_COST = 0.85
    this.HORIZONTAL_WALK_ON_VERTICAL_AISLE_COST = 0.85
    this.VERTICAL_WALK_ON_VERTICAL_AISLE_COST = 1.0
    this.AISLE_CROSSING_PENALTY = 1.5

    // Additional constants for special cases and fallback values
    this.FACING_STALLS_DISCOUNT = 0.85 // 15% distance discount for stalls facing each other
    this.MAX_DISTANCE = 5000 // Maximum distance for unreachable or invalid stalls
    this.FALLBACK_GROUP_SCORE = 1000 // Score for less optimal fallback groups
    this.SCORE_NORMALIZATION_BASE = 1000 // Base value for score normalization
    this.ADDITIONAL_AISLE_PENALTY = 1.2  // 20% increase per additional aisle

    // New Scores Rules @ 24-Mar-2025
    this.ARE_STALLS_ON_SAME_SIDE_OF_AISLE = 0.90 // 10% discount for stalls on the same side of an aisle SIDE BY SIDE STALLS CASE
    this.STALLS_LIE_ON_SAME_AISLE = 0.95 // 5% discount for stalls on the same aisle BACK TO BACK STALLS CASE

    this.PER_AISLE_PENALTY =90

    this.CANDIDATE_GROUP_SAME_AISLE_DISCOUNT = 0.85 // 15% discount for candidate group stalls on the same aisle

    this.MAX_NUMBER_OF_CANDIDATE_GROUPS = 5

    this.calculateAllStallDistances()
  }

  // // Simplified method with flat key-value pairs format
  // downloadDistanceMap() {
  //   // Create a flat map of stall-to-stall distances
  //   const flatDistanceMap = {}

  //   // Iterate through all distance pairs
  //   this.distanceMap.forEach((distance, key) => {
  //     // Round distance to 2 decimal places for readability
  //     flatDistanceMap[key] = Math.round(distance * 100) / 100
  //   })

  //   // Convert to JSON with pretty formatting
  //   const dataStr = JSON.stringify(flatDistanceMap, null, 2)
  //   const dataBlob = new Blob([dataStr], {type: 'application/json'})
  //   const url = URL.createObjectURL(dataBlob)

  //   // Create download link and trigger it
  //   const downloadLink = document.createElement('a')
  //   downloadLink.href = url
  //   downloadLink.download = `barn-distances.json`
  //   document.body.appendChild(downloadLink)
  //   downloadLink.click()
  //   document.body.removeChild(downloadLink)
  //   URL.revokeObjectURL(url)
  // }

  // Calculate all stall-to-stall distances once and cache them
  calculateAllStallDistances() {
    this.distanceMap = new Map()

    const stallIds = Array.from(this.stallMap.keys())

    for (let i = 0; i < stallIds.length; i++) {
      for (let j = i + 1; j < stallIds.length; j++) {
        const stallId1 = stallIds[i]
        const stallId2 = stallIds[j]
        const distance = this.calculateWalkingDistance(stallId1, stallId2)

        // Store distances both ways for easy lookup
        this.distanceMap.set(`${stallId1}-${stallId2}`, distance)
        this.distanceMap.set(`${stallId2}-${stallId1}`, distance)
      }
    }

  }

  // Get cached distance or calculate if not available
  getWalkingDistance(stallId1, stallId2) {
    const key = `${stallId1}-${stallId2}`
    if (this.distanceMap.has(key)) {
      return this.distanceMap.get(key)
    } else {
      const distance = this.calculateWalkingDistance(stallId1, stallId2)
      this.distanceMap.set(key, distance)
      this.distanceMap.set(`${stallId2}-${stallId1}`, distance)
      return distance
    }
  }

  // Calculate weighted walking distance component
  calculateWeightedWalkingDistance(entrance1, entrance2, aisleType) {
    const horizontalDistance = Math.abs(entrance1.x - entrance2.x)
    const verticalDistance = Math.abs(entrance1.y - entrance2.y)

    let weightedDistance

    if (aisleType === 'horizontal') {
      // For horizontal aisles, apply different weights to horizontal and vertical movement
      weightedDistance =
        horizontalDistance * this.HORIZONTAL_WALK_ON_HORIZONTAL_AISLE_COST +
        verticalDistance * this.VERTICAL_WALK_ON_HORIZONTAL_AISLE_COST
    } else {
      // For vertical aisles, apply different weights to horizontal and vertical movement
      weightedDistance =
        horizontalDistance * this.HORIZONTAL_WALK_ON_VERTICAL_AISLE_COST +
        verticalDistance * this.VERTICAL_WALK_ON_VERTICAL_AISLE_COST
    }

    return weightedDistance
  }

  // Calculate the actual walking distance between two stalls based on their orientation
  calculateWalkingDistance(stallId1, stallId2) {
    const stall1 = this.stallMap.get(stallId1)
    const stall2 = this.stallMap.get(stallId2)

    if (!stall1 || !stall2) return 10000

    // Get entrance points (where the person enters/exits the stall)
    const entrance1 = stall1.entrance
    const entrance2 = stall2.entrance

    // Check if stalls are facing each other across an aisle
    const facingAcrossAisle = this.areStallsFacingAcrossAisle(stall1, stall2)

    if (facingAcrossAisle) {
      // For facing stalls, just calculate direct distance between entrances
      // This is a short path since they face each other
      return (
        this.calculateWeightedWalkingDistance(
          entrance1,
          entrance2,
          stall1.aisleAccess.primaryAisleType || 'horizontal'
        ) * this.FACING_STALLS_DISCOUNT
      ) // Additional preference for facing stalls
    }

    // Check if stalls are on the same aisle side
    const onSameAisleSide = this.areStallsOnSameAisleSide(stall1, stall2)

    if (onSameAisleSide) {
      // For stalls on same aisle, calculate path along the aisle
      return (
        this.calculateWeightedWalkingDistance(
          entrance1,
          entrance2,
          stall1.aisleAccess.primaryAisleType || 'horizontal'
        ) * this.ARE_STALLS_ON_SAME_SIDE_OF_AISLE
      )
    }

    // For stalls on different aisles or configurations, need to consider path through aisles
    return this.calculateComplexPathDistance(stall1, stall2)
  }

  // Check if stalls are directly facing each other across an aisle
  areStallsFacingAcrossAisle(stall1, stall2) {
    // Check if stalls have opposing orientations
    const oppositeOrientations = {
      top: 'bottom',
      bottom: 'top',
      left: 'right',
      right: 'left',
    }

    if (stall2.orientation !== oppositeOrientations[stall1.orientation]) {
      return false
    }

    if (stall1.aisleAccess.primaryAisle !== stall2.aisleAccess.primaryAisle) {
      return false
    }

    // Get entrance points
    const entrance1 = stall1.entrance
    const entrance2 = stall2.entrance

    // Check if entrances are relatively aligned
    if (stall1.orientation === 'top' || stall1.orientation === 'bottom') {
      // For top/bottom oriented stalls, check horizontal alignment
      const xDiff = Math.abs(entrance1.x - entrance2.x)
      return xDiff < Math.min(stall1.position.width, stall2.position.width) * 0.7
    } else {
      // For left/right oriented stalls, check vertical alignment
      const yDiff = Math.abs(entrance1.y - entrance2.y)
      return yDiff < Math.min(stall1.position.height, stall2.position.height) * 0.7
    }
  }

  // Check if stalls are on the same side of an aisle
  areStallsOnSameAisleSide(stall1, stall2) {
    // Check if stalls have the same orientation
    if (stall1.orientation !== stall2.orientation) {
      return false
    }

    // Check if they're on the same aisle
    if (
      stall1.aisleAccess.primaryAisle &&
      stall1.aisleAccess.primaryAisle === stall2.aisleAccess.primaryAisle
    ) {
      return true
    }

    // Check if they're in the same row or column (for stalls with same orientation)
    if (stall1.orientation === 'top' || stall1.orientation === 'bottom') {
      return Math.abs(stall1.row_number - stall2.row_number) <= 1
    } else {
      return Math.abs(stall1.row_position - stall2.row_position) <= 1
    }
  }

  // Calculate complex path distance between stalls on different aisles
  calculateComplexPathDistance(stall1, stall2) {
    const entrance1 = stall1.entrance
    const entrance2 = stall2.entrance

    // Determine primary aisles for each stall
    const stall1AisleType = stall1.aisleAccess.primaryAisleType || 'horizontal'
    const stall2AisleType = stall2.aisleAccess.primaryAisleType || 'horizontal'

    // Get the aisle id for each stall
    const stall1AisleId = stall1.aisleAccess.primaryAisle
    const stall2AisleId = stall2.aisleAccess.primaryAisle

    // If they're on the same aisle type AND the same physical aisle, use simple path
    // Also, if they're on the same aisle type and the same physical aisle, then they are facing each other
    if (stall1AisleType === stall2AisleType && stall1AisleId === stall2AisleId) {
      return this.calculateWeightedWalkingDistance(entrance1, entrance2, stall1AisleType) * this.STALLS_LIE_ON_SAME_AISLE
    }

    // For stalls on different aisle types, we need to calculate a more complex path
    // We'll approximate this by:
    // 1. Path from stall1 along its aisle type
    // 2. Cost of crossing between aisle types
    // 3. Path from crossing point to stall2 along its aisle type

    const crossingPoint = {
      x: entrance2.x,
      y: entrance1.y,
    }

    const distanceToIntersection = this.calculateWeightedWalkingDistance(
      entrance1,
      crossingPoint,
      stall1AisleType
    )

    const distanceFromIntersection = this.calculateWeightedWalkingDistance(
      crossingPoint,
      entrance2,
      stall2AisleType
    )

    // // Add a penalty for crossing between aisle types
    // const crossingPenalty =
    //   this.AISLE_CROSSING_PENALTY *
    //   Math.min(Math.abs(entrance1.x - entrance2.x), Math.abs(entrance1.y - entrance2.y))

    // return distanceToIntersection + distanceFromIntersection + crossingPenalty

    const aisleCount = this.countAislesBetween(stall1, stall2)
    
    // STEP 1: Calculate the original base penalty (never zero if aisles are crossed)
    const originalBaseValue = Math.max(
      Math.abs(entrance1.x - entrance2.x),
      Math.abs(entrance1.y - entrance2.y)
    );

    // Ensure a minimum penalty of 50 if there are aisles to cross
    const baseMinimum = aisleCount > 0 ? 50 : 0;
    const basePenalty = Math.max(
      this.AISLE_CROSSING_PENALTY * originalBaseValue,
      baseMinimum
    );
    
    // STEP 2: Calculate an additional penalty based on aisle count
    // Each aisle adds a penalty of 75 by default
    const aisleCountPenalty = aisleCount * this.PER_AISLE_PENALTY;
    
    // STEP 3: Apply progressive multiplier for additional aisles beyond the first
    const aisleMultiplier = aisleCount > 1 ? Math.pow(this.ADDITIONAL_AISLE_PENALTY, aisleCount - 1) : 1;
    
    // STEP 4: Calculate total penalty combining both approaches
    // Base penalty gets multiplied + additional aisle count penalty
    const totalPenalty = (basePenalty * aisleMultiplier) + aisleCountPenalty;


    return distanceToIntersection + distanceFromIntersection + totalPenalty;
  }

  countAislesBetween(stall1, stall2) {
    if (!stall1 || !stall2) return 0

    // If they're on the same aisle, return 0
    if (stall1.aisleAccess.primaryAisle === stall2.aisleAccess.primaryAisle) {
      return 0
    }

    // Check if stalls are back-to-back - this creates a "virtual aisle" between them
  if (this.areStallsBackToBack(stall1, stall2)) {
    // If back-to-back, there's effectively one aisle between them
    return 1;
  }

    // Get all aisles that would need to be crossed
    const verticalAisles = this.aisleLayout.getVerticalAislesBetween(stall1, stall2)
    const horizontalAisles = this.aisleLayout.getHorizontalAislesBetween(stall1, stall2)

    // Return total count of aisles between the stalls
    return verticalAisles.length + horizontalAisles.length
  }

  // Check if stalls are back-to-back
  // Check if stalls are back-to-back (share a wall but face opposite directions)
  areStallsBackToBack(stall1, stall2) {
    const oppositeOrientations = {
      top: 'bottom',
      bottom: 'top',
      left: 'right',
      right: 'left',
    }

    if (stall2.orientation !== oppositeOrientations[stall1.orientation]) {
      return false
    }

    // Check if they share a wall
    switch (stall1.orientation) {
      case 'top':
        return (
          stall2.position.y - (stall1.position.y + stall1.position.height) < 5 &&
          GeometryUtils.hasHorizontalOverlap(stall1.position, stall2.position)
        )
      case 'bottom':
        return (
          stall1.position.y - (stall2.position.y + stall2.position.height) < 5 &&
          GeometryUtils.hasHorizontalOverlap(stall1.position, stall2.position)
        )
      case 'left':
        return (
          stall2.position.x - (stall1.position.x + stall1.position.width) < 5 &&
          GeometryUtils.hasVerticalOverlap(stall1.position, stall2.position)
        )
      case 'right':
        return (
          stall1.position.x - (stall2.position.x + stall2.position.width) < 5 &&
          GeometryUtils.hasVerticalOverlap(stall1.position, stall2.position)
        )
    }

    return false
  }

  getDistanceToOrigin(stallId) {
    const stall = this.stallMap.get(stallId)
    return stall?.distanceFromOrigin || this.MAX_DISTANCE
  }

  // Calculate total walking distance for a group of stalls
  calculateTotalWalkingDistance(stallIds) {
    let totalDistance = 0
    let distanceHistory = []
    // Calculate sum of distances between all pairs
    for (let i = 0; i < stallIds.length; i++) {
      for (let j = i + 1; j < stallIds.length; j++) {
        totalDistance += this.getWalkingDistance(stallIds[i], stallIds[j])
        distanceHistory.push({
          stall1: stallIds[i],
          stall2: stallIds[j],
          combinedDistance: totalDistance,
          currentDistance: this.getWalkingDistance(stallIds[i], stallIds[j]),
        })
      }
    }

    return {
      distance: totalDistance,
      history: distanceHistory,
    }
  }

  calculateTotalWalkingDistanceWithAisleDiscount(stallIds) {
    let totalDistance = 0
    let distanceHistory = []
    
    // Calculate sum of distances between all pairs
    for (let i = 0; i < stallIds.length; i++) {
      for (let j = i + 1; j < stallIds.length; j++) {
        // Get the stalls by their IDs
        const stall1 = this.stallMap.get(stallIds[i])
        const stall2 = this.stallMap.get(stallIds[j])
        
        // Get the original walking distance
        let originalDistance = this.getWalkingDistance(stallIds[i], stallIds[j])
        let adjustedDistance = originalDistance
        
        // Apply discount if stalls share the same primary aisle
        if (stall1?.aisleAccess?.primaryAisle && 
            stall2?.aisleAccess?.primaryAisle && 
            stall1.aisleAccess.primaryAisle === stall2.aisleAccess.primaryAisle) {
          // Apply the same discount as used elsewhere
          adjustedDistance *= this.CANDIDATE_GROUP_SAME_AISLE_DISCOUNT
        }
        
        // Add to total distance calculation
        totalDistance += adjustedDistance
        
        // Keep track of both original and adjusted distances
        distanceHistory.push({
          stall1: stallIds[i],
          stall2: stallIds[j],
          combinedDistance: totalDistance,
          originalDistance: originalDistance,
          currentDistance: adjustedDistance,
          sameAisle: stall1?.aisleAccess?.primaryAisle === stall2?.aisleAccess?.primaryAisle
        })
      }
    }
  
    return {
      distance: totalDistance,
      history: distanceHistory,
    }
  }

  findOptimalStallGroup(request) {
    const numberOfStalls = request.numberOfStalls
    const availableStalls = this.getAvailableStalls(request.dateRange)

    // If no available stalls or too many stalls requested, return empty array
    if (availableStalls.length === 0 || numberOfStalls > availableStalls.length) return []
    if (numberOfStalls <= 1) {
      // For single stall, just return the closest to origin
      availableStalls.sort((a, b) => a.distanceFromOrigin - b.distanceFromOrigin)
      return [
        {
          stalls: [availableStalls[0].id],
          score: 100,
        },
      ]
    }

    // Initialize with starting stalls (try the 10 closest to origin)
    const candidateGroups = []
    const startStallCount = Math.min(availableStalls.length, this.MAX_NUMBER_OF_CANDIDATE_GROUPS)
    const startingStalls = [...availableStalls]
      .sort((a, b) => a.distanceFromOrigin - b.distanceFromOrigin)
      .slice(0, startStallCount)

    for (const startStall of startingStalls) {
      // Start with the seed stall
      const group = [startStall.id]
      const remainingStalls = availableStalls.filter((s) => s.id !== startStall.id)
      const startingAisle = startStall.aisleAccess?.primaryAisle


      
      // Incrementally add stalls with the shortest walking distance
      while (group.length < numberOfStalls && remainingStalls.length > 0) {
        let bestNextStall = null
        let bestDistance = Infinity

        // Find the stall that minimizes additional walking distance
        for (const candidateStall of remainingStalls) {
          // Calculate additional distance adding this stall would create
          let additionalDistance = 0
          for (const existingStallId of group) {
            additionalDistance += this.getWalkingDistance(existingStallId, candidateStall.id)
          }

          // or create a majority of stalls in the same aisle
          
          // Count stalls in each aisle in the current group
          const aisleCount = {};
          for (const existingStallId of group) {
            if (candidateStall.id ==='stall-126' && group[0] ==='stall-70' && existingStallId ==='stall-40'){
              let length = 0;
            }
            const existingStall = this.stallMap.get(existingStallId);
            const aisle = existingStall?.aisleAccess?.primaryAisle;
            if (aisle) {
              aisleCount[aisle] = (aisleCount[aisle] || 0) + 1;
            }
          }
          
          // Get the current majority aisle (if any)
          let majorityAisle = null;
          let maxCount = 0;
          for (const [aisle, count] of Object.entries(aisleCount)) {
            if (count > maxCount) {
              maxCount = count;
              majorityAisle = aisle;
            }
          }
          
          // Get candidate stall's aisle
          const candidateAisle = candidateStall.aisleAccess?.primaryAisle;
          
          // Apply discount if:
          // 1. Candidate would join the current majority aisle, or
          // 2. Candidate matches the starting stall's aisle when no majority exists yet
          if ((majorityAisle && candidateAisle === majorityAisle) || 
              (!majorityAisle && candidateAisle === startingAisle)) {
            additionalDistance *= this.CANDIDATE_GROUP_SAME_AISLE_DISCOUNT;
          }

          // Choose stall with minimum additional distance
          if (additionalDistance < bestDistance) {
            bestDistance = additionalDistance
            bestNextStall = candidateStall
          }
        }

        if (bestNextStall) {
          group.push(bestNextStall.id)
          // Remove the chosen stall from candidates
          const index = remainingStalls.findIndex((s) => s.id === bestNextStall.id)
          if (index !== -1) {
            remainingStalls.splice(index, 1)
          }
        } else {
          break
        }
      }

      // Only consider complete groups
      if (group.length === numberOfStalls) {
        const totalWalkingDistance = this.calculateTotalWalkingDistance(group)
        const totalWalkingDistanceWithAisleDiscount = this.calculateTotalWalkingDistanceWithAisleDiscount(group)
        const averageDistanceToOrigin =
          group.reduce((sum, id) => sum + this.getDistanceToOrigin(id), 0) / group.length

        // Lower score is better (less walking)
        // Weigh walking distance more heavily than distance from origin
        const score = totalWalkingDistance.distance * 3 + averageDistanceToOrigin

        candidateGroups.push({
          stalls: group,
          score: score,
          walkingDistance: totalWalkingDistance,
          originDistance: averageDistanceToOrigin,
          distanceHistory: totalWalkingDistance.history,
        })
      }
    }

    // If no complete groups, try a less optimal approach
    if (candidateGroups.length === 0) {
      // Sort by distance to origin and take the first N stalls
      const fallbackGroup = [...availableStalls]
        .sort((a, b) => a.distanceFromOrigin - b.distanceFromOrigin)
        .slice(0, numberOfStalls)
        .map((s) => s.id)

      if (fallbackGroup.length > 0) {
        return [
          {
            stalls: fallbackGroup,
            score: this.FALLBACK_GROUP_SCORE, // High score indicates less optimal
          },
        ]
      }

      return [] // No valid groups found
    }

    // Sort groups by score (lower is better)
    candidateGroups.sort((a, b) => a.score - b.score)

    // Return best groups, normalized scores (higher is better now)
    const maxScore = candidateGroups[candidateGroups.length - 1].score || this.FALLBACK_GROUP_SCORE
    const finalGroups = candidateGroups.map((group) => ({
      stalls: group.stalls,
      score: Math.abs(this.SCORE_NORMALIZATION_BASE - group.score),
      walkingDistance: group.walkingDistance,
      distanceHistory: group.distanceHistory,
    }))


    return finalGroups
  }

  downloadStallGroupsData(stallGroups, request) {
    // Create a more human-readable version of the data
    const readableData = {
      timestamp: new Date().toISOString(),
      request: {
        numberOfStalls: request.numberOfStalls,
        dateRange: request.dateRange,
        stallSize: request.stallSize || 'any',
        stallType: request.stallType || 'any',
        includeTackStalls: !!request.includeTackStalls,
      },
      results: stallGroups.map((group, index) => ({
        rank: index + 1,
        score: Math.round(group.score),
        stalls: group.stalls,
        totalWalkingDistance: Math.round(group.walkingDistance?.distance || 0),
        stallDetails: group.stalls.map(stallId => {
          const stall = this.stallMap.get(stallId);
          return {
            id: stallId,
            name: stall?.name || `Stall ${stallId}`,
            distanceFromOrigin: Math.round(stall?.distanceFromOrigin || 0),
            aisle: stall?.aisleAccess?.primaryAisle || 'unknown',
            aisleType: stall?.aisleAccess?.primaryAisleType || 'unknown',
            orientation: stall?.orientation || 'unknown',
          };
        }),
        // Include simplified distance history
        distanceBreakdown: group.distanceHistory?.map(entry => ({
          between: `${entry.stall1} and ${entry.stall2}`,
          distance: Math.round(entry.currentDistance),
        })) || []
      }))
    };

    // Convert to JSON with pretty formatting
    const dataStr = JSON.stringify(readableData, null, 2);
    const dataBlob = new Blob([dataStr], { type: 'application/json' });
    const url = URL.createObjectURL(dataBlob);

    // Create download link and trigger it
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = `stall-groups-${new Date().toISOString().split('T')[0]}.json`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  }

  getAvailableStalls(dateRange) {
    return Array.from(this.stallMap.values()).filter((stall) => {
      if (!stall || !stall.status) return false

      // IMPORTANT: Explicitly check for DNR flag to exclude DNR stalls
      if (isStallDNR(stall)) {
        return false
      }

      const isReservable = stall.status.isReservable !== false
      if (!isReservable) return false

      if (!stall.status.currentBooking) return true

      return (
        stall.status.currentBooking.endDate <= dateRange.startDate ||
        stall.status.currentBooking.startDate >= dateRange.endDate
      )
    })
  }
}

// ********************* PROXIMITY ALGORITHM CALCULATION PART ENDS HERE ******************************

export default processStallProximityData
