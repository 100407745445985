import { useState, useEffect, useRef, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { removeDialogFromSession } from '../sgl-utils/SglFunctions';

const modalsRoot = document.getElementById('root-modals') || document.body

const DeleteConfirmDialog = ({ show, onConfirm, onClose, moduleName }) => {
  const [showModal, setShowModal] = useState(false)
  const [inputText, setInputText] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const confirmRef = useRef(null);

  useEffect(() => {
    if (show) {
      if (!isLoading) {
        if (confirmRef.current) {
          confirmRef.current.focus();
        }
        sessionStorage.setItem("modal_is_open", JSON.stringify(true));
        setShowModal(true);
  
        const storedModals = JSON.parse(sessionStorage.getItem('modals_open') || '[]');
        sessionStorage.setItem('modals_open', JSON.stringify([...storedModals, 'deleteConfirmDialog']));
      } else {
        setIsLoading(false);
      }
    } else {
      setShowModal(false);
    }
  }, [show, isLoading]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isLoading || !showModal) return;

      if (event.key === "Enter") {
        if (confirmRef.current) {
          confirmRef.current.focus();
        }
        if (event.target) {
          if (event.target.id === "delete_dialog_cancel_button") {
            hideModal();
          } else if (event.target.id === "delete_dialog_submit_button" && inputText === "DELETE") {
            handleConfirm();
          }
        }
      } else if (event.key === "Escape") {
        hideModal();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showModal, inputText]);

  const handleConfirm = () => {
    if (inputText === 'DELETE') {
      onConfirm();
      setInputText('')
      setShowModal(false);
      sessionStorage.removeItem("modal_is_open");
      removeDialogFromSession('deleteConfirmDialog');
    }
  }

  const hideModal = () => {
    onClose();
    setInputText('')
    setShowModal(false);
    sessionStorage.removeItem("modal_is_open");
    removeDialogFromSession('deleteConfirmDialog');
  }

  const renderedIcon = useMemo(() => (
    <i className="fas fa-solid fa-ban fa-2xl text-danger"></i>
  ), []);

  return createPortal(
    <Modal
      id='kt_modal_delete_confirm'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px search-form'
      show={!isLoading && showModal}
      onHide={hideModal}
    >
      <div className='modal-header py-0 px-4'>
        <h2 className="fs-4 text-danger">Confirm Deletion</h2>
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={hideModal}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
  
      <div className='modal-body py-3 px-4 shadow-lg'>
        <form noValidate className='form' onSubmit={(e) => e.preventDefault()}>
          <div className='card-body p-0'>
            <div className='row mb-3'>
              <div className='col-lg-1 d-flex align-items-start' style={{ marginTop: '12px' }} >{renderedIcon}</div>
              <div className='col ms-lg-2'>
                <span className="d-inline-block align-bottom">
                  Are you sure you wish to delete this <strong>{moduleName}</strong> record permanently from the database?
                  <br />
                  Please type <strong>"DELETE"</strong> to confirm.
                </span>
                <input
                  type="text"
                  className="form-control mt-2"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  placeholder="Type DELETE to confirm"
                  autoFocus
                />
              </div>
            </div>
          </div>
  
          <div className='card-footer d-flex justify-content-end py-3 px-0'>
            <button type="button" id="delete_dialog_cancel_button" className='btn btn-sm btn-secondary me-4 fw-bold' onClick={hideModal}>
              Cancel
            </button>
            <button
              ref={confirmRef}
              type="submit"
              id="delete_dialog_submit_button"
              className='btn btn-sm btn-dark fw-bold'
              onClick={handleConfirm}
              disabled={inputText !== 'DELETE'}
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  );
}

export { DeleteConfirmDialog };
