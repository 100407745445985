import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { useState, useMemo, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import { reactSelectStyles } from '../../modules/sgl-utils/fieldControls'
import { useAppSelector } from '../../redux/hooks'
import { loadingSpinnerBtnWaitByEnter, loadingSpinnerBtnReleaseByEnter } from '../../modules/sgl-utils/SglFunctions'
import axios from 'axios'
import { useRef } from 'react'


const ReportEmailModal = ({ show, handleClose, emailOptions, reportPref, params, headerReportName, callbackFromParent=null, singleInputField=false, showOptions=[], showEntryMappings=[]}) => {
    const intl = useIntl();
    const modalsRoot = document.getElementById('root-modals') || document.body;
    const [errorMessage, setErrorMessage] = useState({color: 'black', message: ''});
    const [selectedEmail, setSelectedEmail] = useState({});
    const [customEmail, setCustomEmail] = useState("");
    const [selectedShows, setSelectedShows] = useState(null); //Store user selected show details    
    const customerID = useAppSelector(state => state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const [sentOnce, setSentOnce] = useState(false);
    const executeRef = useRef(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (event) => {
        if (isProcessing) return;
        setIsProcessing(true);

        // Clear error messages
        setErrorMessage({ color: 'black', message: '' });

        loadingSpinnerBtnWaitByEnter(event);
        // Return false if space is pressed
        if (event.key === ' ') {
            return false;
        }

        let otherEmailLists = [];

        // Check if custom email, then check if it is valid email address
        if (selectedEmail?.value === "Other" && (!customEmail || !isValidEmails(customEmail))) {
            setErrorMessage({ color: 'red', message: 'Please enter a valid email address' });

            // Hide error message after 5 seconds
            setTimeout(() => {
                setErrorMessage({ color: 'black', message: '' });
            }
            , 5000);

            loadingSpinnerBtnReleaseByEnter(event);
            setIsProcessing(false)
            return false;
           
        }       
        
        let updateEntryIds = [];        

        if (showEntryMappings && showEntryMappings.length > 0) { // Safety check
            if (selectedShows) { // Single selected show (not an array)
                showEntryMappings.forEach(ShowEntryMapping => {                                                               
                    if (selectedShows.value === ShowEntryMapping.showId) {
                        updateEntryIds.push(ShowEntryMapping.entryID); // Entry IDs from the selected show
                    }
                });

                // Updating entryIds while keeping other params
                params = { ...params, ids: updateEntryIds };
            }
        }

        // If custom email is valid, then split the emails and add to otherEmailLists array
        customEmail.split(',').forEach((email) => {
            if (email.trim() !== '') {
                // Find if the email is from the email options
                let peopleId = emailOptions.find((option) => option.value == email.trim())?.people_id;
                otherEmailLists.push({ value: email.trim(), label: email.trim(), people_id: peopleId ? peopleId : 0 });
            }
        });

        const body = {
            customer_id: customerID,
            show_id: currentShowID,
            pref_name: reportPref,
            params: params,
            email_to: selectedEmail?.value === "Other" ? customEmail : selectedEmail?.value, // Remove this later
            recipient_list: selectedEmail?.value == "Other" ? otherEmailLists : [selectedEmail],
        }

        // When sending invoice, we need to save the entry first and then generate the invoice
        // So, we need to call the callback function from the parent component to save the entry first
        // If the entry is updated once, we don't need to call the callback function again, this will reduce the number of API calls
        if (!sentOnce) {
            if (callbackFromParent) {
                await callbackFromParent();
            }
        }

        await axios.post(`${process.env.REACT_APP_NEST_API_URL}/reports/emailReport`, body)
            .then((response) => {
                setSentOnce(true);
                if (response.data.success == false) {
                    setErrorMessage({ color: 'red', message: response.data.message });
                } else if (response.data.success == true) {
                    setErrorMessage({ color: 'green', message: response.data.message });
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                loadingSpinnerBtnReleaseByEnter(event);
                setIsProcessing(false)
            })
    }

    useEffect(() => {
        let currentShow = null     
        let ongoingShow = null
        let upcomingShow = null
        let pastShow = null
        // set most current show as default selection
        if(showOptions && showOptions.length>0 ){ //safety check
            const moment = require('moment')
            let currentDate = moment().format('YYYY-MM-DD') // Get current date
            
            //Find and set recent show ID. Find the current show using these rules:        
            // Check for an ongoing show (current date falls between start and end dates)
            // If no ongoing show, pick the nearest upcoming show.
            // If no upcoming show, pick the most recent past show.                        
            
            for (let show of showOptions) {
                let showStartDate = show.showStartDate
                let showEndDate = show.showEndDate                                                

                if (showStartDate <= currentDate && showEndDate >= currentDate) {// Ongoing show found, break the loop                    
                    ongoingShow = show;                    
                    break;
                } else if (showStartDate > currentDate) { // Find the nearest upcoming show
                    
                    if (!upcomingShow || moment(showStartDate).isBefore(upcomingShow.showStartDate)) {
                        upcomingShow = show;
                    }
                } else {
                    // Find the most recent past show
                    if (!pastShow || moment(showStartDate).isAfter(pastShow.showStartDate)) {
                        pastShow = show;
                    }
                }
            }                        
        }    
        currentShow = ongoingShow || upcomingShow || pastShow;                      
        setSelectedShows( currentShow)
    } , [])    

    const isValidEmails = (emailString) => {
        const emails = emailString.split(',')
            .map(email => email.trim()) // Remove spaces
            .filter(email => email !== ''); // Ignore empty entries
    
        return emails.every(email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
    };

    useEffect(() => {
        // If other is selected and then switched back to some other option, clear the custom email
        if (selectedEmail?.value !== "Other") {
            setCustomEmail("");
        }
    } , [selectedEmail])

    useEffect(() => {
        // If single input field then set the email from email options
        if (singleInputField) {
            // Set other as selected email
            setSelectedEmail({ value: "Other", label: "Other", people_id: 0 });
            // Get email from email options 
            if (emailOptions.length > 0 && emailOptions[0].value != '' && emailOptions[0].value != 'Other') {
                setCustomEmail(emailOptions[0].value);
            }
        }

        if (emailOptions.length > 0) {
            // Make labels uppercase
            emailOptions.forEach((option) => {
                option.label = option.label.toUpperCase();
            });
        }
    }, [emailOptions])

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName={'modal-dialog modal-dialog-centered mw-600px'}
            show={show}
            onHide={handleClose}
            onEscapeKeyDown={handleClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                let executeButton = document.getElementById('submitButton')

                if (event.key === "Enter" && !isButtonFocused) { //call function only when no button is in focus
                    if (!isProcessing) {
                        handleSubmit(executeButton)
                    }
                }

                if (event.key === 'Escape') {
                    handleClose()
                }
            }}
        >
            {/* Common Header */}
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'REPORT.POPUP.SENDEMAIL.LABEL.EMAIL' })} {headerReportName ? headerReportName : intl.formatMessage({ id: 'REPORT.POPUP.SENDEMAIL.LABEL.REPORT' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            {/* Body Start */}
            <div className='modal-body py-3 px-4'>
            <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                {!singleInputField &&
                    <div className='row'>
                        <div className='col-lg-4 col-form-label fs-5 py-1'>
                            <label htmlFor='emailOptions' className='fs-5 py-1 w-lg-140px' data-tooltip-id="REPORT.POPUP.SENDEMAIL.LABEL.SELECTRECIPIENT">{intl.formatMessage({ id: 'REPORT.POPUP.SENDEMAIL.LABEL.SELECTRECIPIENT' })}</label>
                        </div>
                        <div className='col-lg-8'>
                            <div className='col col-form-label fs-5 py-1'>
                                <Select
                                    options={emailOptions}
                                    styles={reactSelectStyles}
                                    value={emailOptions.find((option) => option.value == setSelectedEmail.value)}
                                    onChange={(e) => setSelectedEmail({ value: e.value, label: e.label, people_id: e.people_id })}
                                    tabIndex={1}
                                    id='emailOptions'
                                />
                            </div>
                        </div>
                    </div>
                }

                {(selectedEmail?.value === "Other" || singleInputField) && (
                    <>
                        {singleInputField && (
                            <div className='row'>
                            <div className='col-lg-4 col-form-label fs-5 py-1'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-75px' htmlFor='SHOW'>{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITDIVISIONS.TAB.CLASSES.LABEL.SHOW' })}</label>
                            </div>
                            <div className='col-lg-8'>
                                <Select                                                                 
                                    options={showOptions}
                                    value={selectedShows}
                                    onChange={setSelectedShows}
                                />
                            </div>
                            </div>
                        )}

                        <div className='row'>
                            <div className='col-lg-4 col-form-label fs-5 py-1'>
                                <label htmlFor='otherEmail' className='fs-5 py-1 w-lg-140px' data-tooltip-id="REPORT.POPUP.SENDEMAIL.LABEL.ENTEREMAIL">{intl.formatMessage({ id: 'REPORT.POPUP.SENDEMAIL.LABEL.ENTEREMAIL' })}</label>
                            </div>
                            <div className='col-lg-8 col-form-label fs-5 py-1'>
                                <input
                                    id="otherEmail"
                                    className="form-control form-control-sm fs-6 min-h-20px py-1"
                                    placeholder="Enter Email"
                                    type='text'
                                    value={customEmail}
                                    tabIndex={2}
                                    onChange={(e) => setCustomEmail(e.target.value)}
                                    onBlur={(e) => isValidEmails(e.target.value) ? setErrorMessage({ color: 'black', message: '' }) : setErrorMessage({ color: 'red', message: 'Please enter a valid email address' })}
                                />
                            </div>
                        </div>
                        <div className='row'>
                        </div>
                    </>
                )}

                {/* Footer */}
                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                    <div className='col-lg-12'>
                        <div className='row'>
                            <div className='col-lg-7 d-flex align-items-center'>
                                <label style={{ color: errorMessage.color }} className='fs-7 fw-bold'>{errorMessage.message}</label>
                            </div>
                            <div className='col-lg-5 d-flex flex-end'>
                                <button type='button' onClick={handleClose} className='btn btn-sm btn-secondary fw-bold text-uppercase me-5' tabIndex={3}>
                                    {intl.formatMessage({ id: 'REPORT.POPUP.SENDEMAIL.BUTTON.LABEL.CLOSE' })}
                                </button>
                                <button id="submitButton" ref={executeRef} type='button' disabled={Object.keys(selectedEmail).length==0 || (selectedEmail?.value == "Other" && customEmail == "")} onClick={(event) => {handleSubmit(event)}} className='btn btn-sm btn-dark fw-bold text-uppercase' tabIndex={4}>
                                    <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                                    {intl.formatMessage({ id: 'REPORT.POPUP.SENDEMAIL.BUTTON.LABEL.SEND' })}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            </div>
            {/* Body End */}
        </Modal>,
        modalsRoot
    )

}

export default ReportEmailModal;