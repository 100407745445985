import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Modal } from 'react-bootstrap'
import { useAnnouncerToolContext } from './AnnouncerToolContext';
import CheckboxRenderer from './CheckboxRenderer';
import PositiveNumberRenderer from '../../../modules/output-listing/renderers/PositiveNumberRenderer';
import { KTSVG } from '../../../../_metronic/helpers'
import SplitViewGrid from './SplitViewGrid';
import DecimalPointRenderer, { IntegerOrFloatRenderer } from '../../../modules/output-listing/renderers/DecimalPointRenderer';
import { multiSort } from '../../../modules/sgl-utils/SglFunctions';
import { SaveModifiedColumnDefinitions, overwriteStaggerGridColumnDefinitions, updateAGGridWithoutFlickering } from '../../../modules/sgl-utils/agGridHelpers';
import { DeltaNumberRenderer } from '../../../modules/output-listing/renderers/ChangeRenderers';
import { useAuth } from '../../../modules/auth';
import useStorage from '../../../modules/hooks/use-storage';
import R3TripCheckboxRenderer from './R3TripCheckboxRenderer';


const StaggerGrid = (props) => {
  const gridRef = useRef();
  const [staggerGridRef, setStaggerGridRef] = useState(null)
  const intl = useIntl();
  const [showSplitView,setShowSplitView] = useState(false)
  const [selectedSplit, setSelectedSplit] = useState(props.splits)
  const {currentUser} = useAuth()
  
  const { currentClassGroup, classesWithCompletedTrips, highlightCurrentTrip, selectCurrentTrip, updateColumnDefToggle, orderUpcomingR2JO, showGridLoadingOverlays, customerID, showAgGridSettingsModal, setShowAgGridSettingsModal, disabledColumns} = useAnnouncerToolContext()
  const { localStorageChanged } = useStorage()
  		
  const splitOptions = {
      A: 1,
      B: 2,
      C: 3,
      D: 4,
      E: 5,
      F: 6,
  }

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState([]);

    //Grid Class Groups
    const INITIAL_COLUMN_DEFS= [
      { 
        field: 'position', 
        headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.POS'}), 
        suppressSortIcons: true, 
        suppressMenu: true, 
        cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, 
        maxWidth: 50, 
        minWidth: 50, 
        resizable :true, 
        hide: false,
        suppressMovable: true,
        cellRendererSelector: params => {
            const position = params.node.data.position;
            if (position != 0) {
                return {
                  component: DeltaNumberRenderer,
                  params: {inverse: true, value: position} //set negative values in order to display delta on position change in correct order
                }
                // return {
                //     component: 'agAnimateShowChangeCellRenderer',
                //     params: {value: position * -1} //set negative values in order to display delta on position change in correct order
                // };
            }
            else {
                return {
                    component: PositiveNumberRenderer
                };
            }
        },
      },
      { field: 'team', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.TEAM'}), suppressMenu: true, cellStyle: {fontSize: '13px', fontWeight: '500', textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, hide: false, maxWidth: 55, minWidth: 55, suppressMovable: true,
        valueGetter: function getTeam(params) { 
          //Display team abbreviation if not empty otherwise display team name for team scoring class group trips
          if(params?.data?.TeamxClass?.Team?.team_abbr)
          {
            return params?.data?.TeamxClass?.Team?.team_abbr
          }else if(params?.data?.TeamxClass?.Team?.team_name){
            return params?.data?.TeamxClass?.Team?.team_name
          }else{
            return ''
          }
        } 
      },
      { 
        field: 'TeamxClass.score', 
        headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.SCORE'}), 
        suppressMenu: true,
        cellStyle: {fontSize: '13px', fontWeight: '500', textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, 
        maxWidth: 65,  
        minWidth: 65, 
        resizable :true, 
        hide: false,
        suppressMovable: true,
        cellRenderer: params => params.value === 'JO' ? 'JO' : IntegerOrFloatRenderer(params, 3, false) 
      },
      { field: 'actual_order', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.ORD'}), suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, hide: false, maxWidth: 45, minWidth: 45, headerClass: 'ag-center-aligned-header', suppressMovable: true, cellRenderer: PositiveNumberRenderer},
      { field: 'Entry.number', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.ENTRY'}), suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, hide: false, maxWidth: 60  , minWidth: 60 ,headerClass: 'ag-center-aligned-header', suppressMovable: true},
      
      //Hunter Columns
      { 
        field: 'scoreone',
        headerName: intl.formatMessage({
            id:  currentClassGroup?.group_score_type === 'Hunter Derby' ? 'ANNOUNCER.GRID.STAGGER.LABEl.R1': 'ANNOUNCER.GRID.STAGGER.LABEl.SCORE1'
        }),  
        suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, 
        resizable :true, 
        maxWidth: 65 , 
        minWidth: 65 ,
        headerClass: 'ag-center-aligned-header', 
        hide: false,
        suppressMovable: true,
        cellRenderer: params => DecimalPointRenderer(params, 3) 
      },
      { 
        field: 'scoretwo', 
        headerName: intl.formatMessage({
            id:  currentClassGroup?.group_score_type === 'Hunter Derby' ? 'ANNOUNCER.GRID.STAGGER.LABEl.R2': 'ANNOUNCER.GRID.STAGGER.LABEl.SCORE2'
        }),
        suppressMenu: true, 
        cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, 
        resizable :true, 
        maxWidth: 65, 
        minWidth: 65,
        headerClass: 'ag-center-aligned-header', 
        hide: false,
        suppressMovable: true,
        cellRenderer: params => DecimalPointRenderer(params, 3)
      },
      { field: 'scorethree', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.SCORE3'}), suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, hide: false,maxWidth: 65 , minWidth: 65  ,headerClass: 'ag-center-aligned-header', suppressMovable: true, cellRenderer: params => DecimalPointRenderer(params, 3) },
      { field: 'scorefour', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.SCORE4'}), suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, hide: false,maxWidth: 65  , minWidth: 65  ,headerClass: 'ag-center-aligned-header', suppressMovable: true, cellRenderer: params => DecimalPointRenderer(params, 3) },
      { field: 'scorefive', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.SCORE5'}), suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, hide: false,maxWidth: 65, minWidth: 65, suppressMovable: true, cellRenderer: params => DecimalPointRenderer(params, 3) },
      { field: 'scoresix', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.SCORE6'}), suppressMenu: true,cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, maxWidth: 65,  hide: false,minWidth: 65, resizable :true , suppressMovable: true, cellRenderer: params => DecimalPointRenderer(params, 3) },      
      { 
        field: 'total_score', 
        headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.TOTAL'}), 
        suppressMenu: true, 
        cellClass: params => getScoreColumnClass(params), 
        cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, 
        maxWidth: 60, 
        minWidth: 60, 
        resizable :true, 
        hide: false,
        suppressMovable: true,
        cellRenderer: params =>  { return getScoreTimeData(params, 'R1', 'total_score'); }
      },
      
      //Jumper Columns
      { 
        field: 'time_one', 
        headerName: intl.formatMessage({
            id: `ANNOUNCER.GRID.STAGGER.LABEl.${(currentClassGroup?.round_labels?.r1.abbr) ? currentClassGroup.round_labels.r1.abbr : 'R1'}T`
        }), 
        suppressMenu: true, 
        cellClass: params => getR1ColumnClass(params), 
        cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, 
        resizable :true, 
        maxWidth: 60, 
        minWidth: 60,
        headerClass: 'ag-center-aligned-header', 
        hide: false,
        suppressMovable: true,
        cellRenderer: params => { return getScoreTimeData(params, 'R1', 'time_one'); }
      },
      { 
        field: 'total_faults_one',
        headerName: intl.formatMessage({
            id: `ANNOUNCER.GRID.STAGGER.LABEl.${(currentClassGroup?.round_labels?.r1.abbr) ? currentClassGroup.round_labels.r1.abbr : 'R1'}F`
        }), 
        suppressMenu: true, 
        cellClass: params => getR1ColumnClass(params), 
        cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, 
        resizable :true,
        maxWidth: 60, 
        minWidth: 60,
        headerClass: 'ag-center-aligned-header', 
        hide: false,
        suppressMovable: true,
        cellRenderer: params => { return getScoreTimeData(params, 'R1', 'total_faults_one'); }
      },
      { 
        field: 'time_two', 
        headerName: intl.formatMessage({
            id: `ANNOUNCER.GRID.STAGGER.LABEl.${(currentClassGroup?.round_labels?.r2?.abbr) ? currentClassGroup.round_labels.r2.abbr : 'JO'}T`
        }), 
        suppressMenu: true, 
        cellClass: params => getR2ColumnClass(params), 
        cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, 
        resizable :true,
        hide: false,
        maxWidth: 60, 
        minWidth: 60,
        headerClass: 'ag-center-aligned-header', 
        suppressMovable: true,
        cellRenderer: params => { return getScoreTimeData(params, 'JO', 'time_two'); }
      },
      { 
        field: 'total_faults_two', 
        headerName: intl.formatMessage({
            id: `ANNOUNCER.GRID.STAGGER.LABEl.${(currentClassGroup?.round_labels?.r2?.abbr) ? currentClassGroup.round_labels.r2.abbr : 'JO'}F`
        }), 
        suppressMenu: true, 
        cellClass: params => getR2ColumnClass(params), 
        cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, 
        resizable :true, 
        hide: false,
        maxWidth: 60, 
        minWidth: 60 ,
        headerClass: 'ag-center-aligned-header', 
        suppressMovable: true,
        cellRenderer: params => { return getScoreTimeData(params, 'JO', 'total_faults_two'); }
      },
      {
        field: 'r3_trip',
        headerName: intl.formatMessage({ id: 'ANNOUNCER.GRID.STAGGER.LABEl.JOTRIP' }),
        maxWidth: 70,
        minWidth: 70,
        hide: false,
        headerClass: 'ag-center-aligned-header',
        suppressMovable: true,
        cellRendererFramework: R3TripCheckboxRenderer
      },
      {
        field: 'time_three',
        headerName: intl.formatMessage({ 
            id: `ANNOUNCER.GRID.STAGGER.LABEl.${(currentClassGroup?.round_labels?.r3?.abbr) ? currentClassGroup.round_labels.r3.abbr : 'R3'}T`
        }),
        hide: false,
        maxWidth: 60,
        minWidth: 60,
        headerClass: 'ag-center-aligned-header',
        suppressMovable: true,
        cellRenderer: params => { return getScoreTimeData(params, 'R3', 'time_three'); },
        cellClass: params => getR3ColumnClass(params), 
	  },
      {
        field: 'total_faults_three',
        headerName: intl.formatMessage({ 
            id: `ANNOUNCER.GRID.STAGGER.LABEl.${(currentClassGroup?.round_labels?.r3?.abbr) ? currentClassGroup.round_labels.r3.abbr : 'R3'}F`
        }),
        maxWidth: 60,
        minWidth: 60,
        hide: false,
        headerClass: 'ag-center-aligned-header',
        cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0},
        suppressMovable: true,
        cellRenderer: params => { return getScoreTimeData(params, 'R3', 'total_faults_three'); },
        cellClass: params => getR3ColumnClass(params), 
	  },
      { field: 'rider_name', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.RIDER'}), suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, hide: false, maxWidth: 500,  minWidth: 150, cellClass: 'ag-left-aligned-cell', suppressMovable: true},
      { field: 'Entry.horse', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.HORSE'}), suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, hide: false, maxWidth: 500,  minWidth: 150, cellClass: 'ag-left-aligned-cell', suppressMovable: true},
      { field: 'class_number', hide: true, valueGetter: (params) => props.class_number,  suppressMovable: true},
      { field: 'gone_in', headerName: intl.formatMessage({id: 'ANNOUNCER.GRID.STAGGER.LABEl.GONE'}), suppressMenu: true, cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0}, resizable :true, hide: false, maxWidth: 50  , minWidth: 50  ,headerClass: 'ag-center-aligned-header',  suppressMovable: true,
      cellRendererFramework: (params) => {
        return (
            <CheckboxRenderer params = {params} column = "gone_in" grid="stagger"/>
        );
      }},
  ]

	const [columnDefs, setColumnDefs] = useState(INITIAL_COLUMN_DEFS)
  const getReferenceKey = () => {
    if (!currentClassGroup) return 'Class Group Not Found!'// should never happen
        
    let referenceKey = `Stagger Grid - ${currentClassGroup.group_type}`
    if (currentClassGroup.group_score_type) {
			referenceKey += ( ` ${currentClassGroup.group_score_type}`)
    }
		return referenceKey
	}

  //Setting Grid Ref
  useEffect(() => {
    props.gridRefs.current[props.grid_no] = gridRef;

    const initialColumnDefs = [...INITIAL_COLUMN_DEFS]; 
    props.handleInitialDefs(props.grid_no, initialColumnDefs);
  }, [props.grid_no, gridRef]); 

  useEffect(() => {  //overwrite column defs with user modified column defs
		if (currentClassGroup) {
			let colDefs = localStorage.getItem(getReferenceKey())
			if (colDefs){
				let customColumnDefinitions = JSON.parse(colDefs)
				let overwriteColDefs = overwriteStaggerGridColumnDefinitions(customColumnDefinitions, columnDefs)
				if(overwriteColDefs.length > 0){
          overwriteColDefs.forEach((colDef) => { //goes through column definitions and sets hide: false for fields without hide property
            if(colDef.hide === null || colDef.hide === undefined){
              colDef.hide = false
            }
          })
					setColumnDefs(overwriteColDefs)
				}
			} else {
				setColumnDefs(INITIAL_COLUMN_DEFS)
		} } else {
			setColumnDefs(INITIAL_COLUMN_DEFS)
		}
  }, [currentClassGroup, localStorageChanged])

  const getScoreTimeData = (params, round, column) => {
    let colData = ''
    switch(column){
      case 'total_faults_two':
      case 'total_faults_one':
			case 'total_faults_three':
        colData = PositiveNumberRenderer(params)
        break;

      case 'time_one':
      case 'time_two':
			case 'time_three':
        colData = DecimalPointRenderer(params, 3, false)
        break;

      case 'total_score':
        colData = DecimalPointRenderer(params, 3)
        colData = Number(colData) > 0 ? colData : '';
        break;
    }

    if(!colData){
			let disqualification_value = ''
			if (round === 'R1') {
				disqualification_value = params.node.data?.disqualify_status_one
			} else if (round === 'JO' || round === 'R2') {
				disqualification_value = params.node.data?.disqualify_status_two
			} else if (round === 'R3') {
				disqualification_value = params.node.data?.disqualify_status_three
			}

      colData = (disqualification_value) ? disqualification_value : colData;
    }

    return colData;
  } 

  const getR1ColumnClass = (params) => {
    if (currentClassGroup?.group_score_type == 'Team Scoring' && params.node.data?.team_id > 0 && (params.node.data?.disqualify_status_one !== '' || params.node.data?.TeamxClass?.r1DropTrip)) { //show R1 cell disqualification in R1 columns for team trips
      return 'bg-expired';
    }
    return '';
  }

  const getR2ColumnClass = (params) => {
    if (currentClassGroup?.group_score_type == 'Team Scoring' && params.node.data?.team_id > 0 && (params.node.data?.disqualify_status_two !== '' || params.node.data?.TeamxClass?.joDropTrip)) { //show R2 cell disqualification in R2 columns only for team trips
      return 'bg-expired';
    }
    return '';
  }

	const getR3ColumnClass = (params) => {
    if (currentClassGroup?.group_score_type == 'Team Scoring' && params.node.data?.team_id > 0 && (params.node.data?.disqualify_status_three !== ''|| params.node.data?.TeamxClass?.r3DropTrip)) { //show R3 cell disqualification in R3 columns only for team trips
      return 'bg-expired';
    }
    return '';
  }

  const getScoreColumnClass = (params) => {
    if (currentClassGroup?.group_score_type == 'Team Scoring' && params.node.data?.team_id > 0 && (params.node.data?.disqualify_status_one !== '' || params.node.data?.TeamxClass?.r1DropTrip)) { //show R1 cell disqualification in R1 columns for team trips
      return 'bg-expired';
    }
    return '';
  }

  const getStaggerRowClass = (params) => {
    //ignore setting disqualification color for whole row in team trips of jumper team events where rider can go in R2 even though disqualified in R1
    let teamTrip =  params.node.data?.team_id > 0 && currentClassGroup?.group_score_type == 'Team Scoring';
    
    if ((params.node.data?.disqualify_status_one !== '' || params.node.data?.disqualify_status_two !== ''|| params.node.data?.disqualify_status_three !== '') && !teamTrip) {
        return 'bg-expired';
    }
    else if (params.node.data?.r3_qualified && (!teamTrip || params.node.data?.r3_gone)) { //For team scoring show yellow for R3 gone trips otherwise show yellow bg for R3 qualified as well
      return 'bg-override'
    }
    else if (orderUpcomingR2JO && currentClassGroup?.group_type == 'Jumpers' && params.node.data?.mostRecentR2Trip && currentClassGroup?.group_score_type == 'Team Scoring') {
		  return 'bg-valid'
  	}
    else if(params.node.data?.jo_upcoming || params.node.data?.r2_upcoming){
      return 'bg-upcoming-r2';
    }
    else if(params.node.data?.jo_qualified || params.node.data?.jog_qualified){
      return 'bg-valid';
    }
    return 'no-bg-class-matched';
  }

  //Use row class rule to update bg for listbox instead of getRowClass because it does not require redrawing of row after data update
  //https://www.ag-grid.com/react-data-grid/view-refresh/#redraw-rows
  const rowClassRules = {
    'bg-override': function(params) {
        return getStaggerRowClass(params) == 'bg-override';
    },

    'bg-upcoming-r2': function(params) {
      return getStaggerRowClass(params) == 'bg-upcoming-r2';
    },

    'bg-valid': function(params) {
      return getStaggerRowClass(params) == 'bg-valid';
    },

    'bg-expired': function(params) {
      return getStaggerRowClass(params) == 'bg-expired';
    },
  }
  
  const getStaggerRowStyle = (params) => {
    if (currentClassGroup?.group_score_type == 'Team Scoring'){
      //Set logic to set separation between trips of different teams (BUT ONLY FOR MULTIPLE TRIP TEAMS IN STAGGER)
      if (params.node.rowIndex >= 0 && params.node.data?.team_id > 0) { // && rowData[params.node.rowIndex].team_id == rowData[params.node.rowIndex - 1].team_id
          if(params.node.rowIndex >= rowData.length - 1 || rowData[params.node.rowIndex].team_id !== rowData[params.node.rowIndex + 1].team_id){
            return { borderBottomWidth: '3px' };
          }
      }
    }
  }

  const suppressKeyboardEvent = (params) => {
    
    // Check if the pressed key is the up arrow key (keyCode 38) or the down arrow key (keyCode 40)
    if (params.event.keyCode === 38 || params.event.keyCode === 40) {
      // Suppress the event
      return true;
    }

    // Allow other keyboard events to proceed
    return false;
  }

  const onFirstDataRendered = useCallback((params) => {
      params.api.sizeColumnsToFit();
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
			cellStyle: {fontSize: '13px',textAlign: 'center', 'paddingLeft': 0, 'paddingRight': 0},
			suppressMenu: true,
      editable: false,
      wrapHeaderText: true,
      sortable: false,
      suppressKeyboardEvent: suppressKeyboardEvent
    };
  }, []);

  useEffect(() => {
    //Update highlighted row
    if(rowData && gridRef){
      highlightCurrentTrip(gridRef?.current?.api)
      updateColumnDefToggle(gridRef?.current?.columnApi)
      let col_Defs = localStorage.getItem(getReferenceKey())
      //over-write dynamic column visibility by the stored settings from local storage
      if (col_Defs){
        let customColumnDefinitions = JSON.parse(col_Defs)
        customColumnDefinitions.forEach((column) => {
          if(disabledColumns){
            if(!disabledColumns?.includes(column.field)){
              if(column.hide){
                gridRef?.current?.columnApi?.setColumnVisible(column.field, false)
              }
            }
          }
        })
      }
    }
  }, [rowData]);

  useEffect(() => {
    if(showGridLoadingOverlays){ //Display Grid Overlay
      gridRef?.current?.api?.showLoadingOverlay()
    }
    else{//Hide Grid Overlay
      gridRef?.current?.api?.hideOverlay()
    }
  }, [showGridLoadingOverlays, staggerGridRef]);

  useEffect(() => {
    let rows = [];
    if(classesWithCompletedTrips){
      //Get completed trips list of classes
      for(let classKey in classesWithCompletedTrips){
        if(classesWithCompletedTrips[classKey].class_id == props.class_id){
          if(classesWithCompletedTrips[classKey]?.Entryxclasses?.length > 0){
            rows =  JSON.parse(JSON.stringify(classesWithCompletedTrips[classKey].Entryxclasses)); //[...];
          }
          break;
        }
      }
    }

    //order rows in stagger based on upcoming JO/R2 setting
    rows = orderStaggerRows(rows)

    //format data in rows array to display team information in more readable format instead of showing repeated data for all team trips
    if(currentClassGroup?.group_score_type == 'Team Scoring'){
        // NOTE: Check JO Round has started (means at least 1 team's 1 trip has completed jump off, because we have to show jo combined time if thats the case. otherwise, we will show r1 combined time.
        let joStarted = false 
        for (const row of rows) {
          if(currentClassGroup?.group_type == 'Jumpers'){
            if (row.team_id > 0 && row.TeamxClass && (row.time_two > 0 || row.total_faults_two > 0 || row.disqualify_status_two)) {
              joStarted = true
              break
            }
          }
        }

        let lastTeamID = -1;
        let teamTripNumber = 1
        for(let trip of rows){
          if (trip.team_id > 0 && trip.TeamxClass && trip.TeamxClass.Team) {
            if(trip.team_id == lastTeamID){ //clear data in all non-first team trip rows in stagger
              teamTripNumber++

              trip.TeamxClass.Team.team_name = '';
              trip.TeamxClass.Team.team_abbr = '';
              trip.TeamxClass.score = 0;
              trip.position = 0;
              if (teamTripNumber === 2) { // display combined time in second team trip score column.
                if(currentClassGroup?.group_type == 'Jumpers'){
                  if (joStarted) {
                    trip.TeamxClass.score = trip.TeamxClass.joTime
                  } else {
                    trip.TeamxClass.score = trip.TeamxClass.r1Time
                  }
                }
              }

              // Show JO Label in third trip of FEI Nations Cup's r3 qualified teams (Tied for 1st Position after Round 2)
              if (currentClassGroup?.configurable_r3 && teamTripNumber === 3 && trip.r3_qualified) {
                trip.TeamxClass.score = 'JO'
              }
            } else { // new team encountered
              teamTripNumber = 1
            }
          }

          lastTeamID = trip.team_id;
        }
    }

    if (staggerGridRef) {
      updateAGGridWithoutFlickering(gridRef, rows, 'entryxclasses_id', ['position'])
    }
    
    setRowData(rows)
}, [staggerGridRef, classesWithCompletedTrips, orderUpcomingR2JO]);

  useEffect(() => {
    // update the class split grids
    renderAGGrids()
  },[selectedSplit])

  useEffect(() => {
    // When split dialog is closed reset its value
    if(!showSplitView)
    {
      setSelectedSplit(props.splits)
    }
  },[showSplitView])

  function orderStaggerRows (rows) {
    if(orderUpcomingR2JO){
      if(currentClassGroup?.after_delay_jo){
        if(currentClassGroup?.group_score_type == 'Team Scoring'){
          return customSortForUpcomingJOTeamScoringTrips(rows); //Sort Callback trips for Team Scoring
        }
        else{
          return [...rows].sort((a, b) => customSortForUpcomingJOR2Trips('jo_callback', rows, a, b)) //Sort Callback trips for Jumpers
        }
      }else if(currentClassGroup?.after_delay_r2){
        return [...rows].sort((a, b) => customSortForUpcomingJOR2Trips('jog_callback', rows, a, b)) //Sort Callback trips for Hunters
      }
      return rows;
    }else{
      return  [...rows].sort((a, b) => a.original_order - b.original_order) 
    }
  }

  function customSortForUpcomingJOTeamScoringTrips(rows) {
    let teams = [];
    let trip_ids = [];
    let mostRecentR2Trip = null

    for(let row of rows){
      trip_ids.push(row.entryxclasses_id)
      row.jo_order = 0;
      row.actual_order_original = row.actual_order;
      row.actual_order = 0;

      if(row.team_id <= 0){ continue; } //bypass independent trips
      if(!row.jo_qualified){ continue; } //ignore non-JO Qualified teams

      // Set most recent r2 flag in trip whose actual order r2 is maximum 
      if(row.actual_order_r2 > 0){
        if(row.disqualify_status_two || row.total_faults_two > 0 || row.time_two > 0){ //Has JO data
          if (!mostRecentR2Trip) {
            mostRecentR2Trip = row
          } else {
            if (mostRecentR2Trip.actual_order_r2 < row.actual_order_r2) {
                mostRecentR2Trip = row
            }
          }
        }
      }

      //find matching index for team in teams array
      let teamIndex = teams.findIndex(team => team.team_id == row.team_id)

      //add team to list
      if(teamIndex < 0){
        teams.push({
          team_id: row.team_id,
          scoreone: row.TeamxClass.scoreone,
          r1Time: row.TeamxClass.r1Time,
          r1Faults: row.TeamxClass.r1Faults,
          score: row.TeamxClass.score,
          count: 0,
          trips: []
        });
        teamIndex = teams.length - 1;
      }

      //add trip to trips list for team
      teams[teamIndex].count++;
      teams[teamIndex].trips.push({
        entryxclasses_id: row.entryxclasses_id,
        disqualify_status_one: row.disqualify_status_one,
        total_faults_one: row.total_faults_one,
        time_one: row.time_one
      })
    }

		for (const trip of rows) {
			if (trip.entryxclasses_id === mostRecentR2Trip?.entryxclasses_id) {
				trip.mostRecentR2Trip = true
			} else {
				trip.mostRecentR2Trip = false
			}
		}

    if(currentClassGroup?.jumper_table == 'FEI Nations Cup'){
      //sort teams based on position from first round and break ties on basis of first round score and time
      multiSort(teams, { r1Faults: 'desc', r1Time: 'desc', team_id: 'asc' });

      //Get trip counts for teams
      const teamTripCounts = teams.map(team => team.count).sort((a, b) => b - a); //Get descending sort of team trip counts
      const maxTeamTrips = teamTripCounts.length > 0 ? teamTripCounts[0] : 0; // Get max trips for reference
      
      //NOTE: The Chefs d'Equipe of teams comprising only three Athletes may choose in which of the three positions out of four they will start their Athletes. 
      // 6.3. The starting order of the teams in the second round will be in the reverse order of the total Penalties in the first round of the
      // best three Athletes in each team. In case of equality of Penalties the teams will start in reverse order of the combined
      // Penalties and times of their best three Athletes in the first round. 
      // Fill data for upcoming JO trips based on Nations Cup logic (We are assuming teams with less trips will sit out first trip cycle)
      let jo_order = 0;
      for (let i = 0; i < maxTeamTrips; i++) {
        for (let team of teams) {
          let skipInitialRoundsForTeam = Math.max(0, maxTeamTrips - team.trips.length);
          if (i >= skipInitialRoundsForTeam) { // Ensure teams with fewer trips are assigned later
            let tripIndexInTeam = i - skipInitialRoundsForTeam;
            if (tripIndexInTeam < 0 || tripIndexInTeam >= team.trips.length) continue; // Prevent invalid index access
            
            let tripIndex = trip_ids.indexOf(team.trips[tripIndexInTeam].entryxclasses_id);
            if (tripIndex < 0) continue;

            if(rows[tripIndex].jo_qualified){
              if(rows[tripIndex].jo_upcoming){ //Replace order for upcoming JO trips as per OOG in R2
                rows[tripIndex].jo_order = ++jo_order; 
                rows[tripIndex].position = 0;
              }
              else{ //Remove order for JO Qualified teams to avoid confusion
                rows[tripIndex].jo_order = 0;
              }
              
              rows[tripIndex].actual_order = rows[tripIndex].jo_order
            }
          }
        }
      }

      //Keep JO Gone at top, then upcoming jo trips in order of JO, then retain original order for remaining trips
      multiSort(rows, { jo_qualified: 'desc', mostRecentR2Trip: 'desc', jo_upcoming: 'desc', jo_order: 'asc', original_order: 'asc' });
    }
    else{ //Team Scoring
      multiSort(rows, { actual_order_original: 'asc' });
      let jo_order = 0;
      for(let row of rows){
        row.jo_order = 0;
        if(row.jo_qualified){
          if(row.jo_upcoming){ //Replace order for upcoming JO trips as per OOG in R2
            row.jo_order = ++jo_order;
            row.position = 0;
          }
          row.actual_order = row.jo_order;
        }
      }
      multiSort(rows, { jo_qualified: 'desc', mostRecentR2Trip: 'desc', jo_upcoming: 'desc', jo_order: 'asc', original_order: 'asc' });
    }

    return rows;
  }

    function customSortForUpcomingJOR2Trips(callback, rows, a=null, b=null) {
        if(callback == 'jo_callback')
        {
            if (a.jo_qualified && a.jo_upcoming && b.jo_qualified && b.jo_upcoming) {
                if (currentClassGroup.jumper_table_type === 5) {
                    // ARTICLE 276 COMPETITION WITH WINNING ROUND
                    // In this Competition at least 25% and a minimum of ten Athletes of the first round qualify for the winning round, in which they start in reverse order of the results (Penalties and time) of the first round.
                    // Sort on Reverse order of R1 scores for R2 Qualified trips BUT R2 not gone >>>
                    //In the second round horses will return in reverse order of their first round faults (lowest to highest) 
                    if(a.total_faults_one == b.total_faults_one){ //both trips have same faults then sort on their R1 time
                        if (a.time_one == b.time_one) {  // both trips have same time in R1 then sort on their actual order
                            return a.actual_order - b.actual_order //sort on ascending order of actual order
                        } 
                        else {
                            return b.time_one - a.time_one; 
                        }
                    }
                    
                    //Sort on Descending order of first round faults (Higher faults go first)
                    return b.total_faults_one - a.total_faults_one;
                } 
                else { 
                    //JO Round hasnt started and Round1 is cleared - Use Actual Order of the trip 
                    return a.actual_order - b.actual_order
                }
            }
        }
        else if(callback == 'jog_callback'){
            if(a.jog_qualified && b.jog_qualified){ //Sort JOG Rows only
                if(currentClassGroup.group_score_type == 'Hunter Derby'){
                    //Hunter Derby with separate R2 round
                    //Sort on Reverse order of R1 scores for R2 Qualified trips BUT R2 not gone >>>
                    //https://www.ushja.org/application/files/8516/6482/6386/2022-2023_NHD_Class_Specs_final_approved_version.pdf
                    //In the second round horses will return in reverse order of their first round scores (lowest to highest) 
                    if(a.r2_upcoming && !b.r2_upcoming){ //a trip is upcoming but b trip is completed
                        return 1; //push down a trip to show completed trip at top
                    }
                    else if(!a.r2_upcoming && b.r2_upcoming){ //a trip is completed but b trip is upcoming
                        return -1; //maintain a trip position to show completed trip at top
                    }
                    else if(!a.r2_upcoming && !b.r2_upcoming){ //both trips are completed
                        return a.original_order - b.original_order; //sort on the position for completed trips
                    }
                    else if(a.scoreone == b.scoreone){ //both callback/upcoming r2 trips have same score then sort on their Actual order
                        return a.actual_order - b.actual_order; //sort on actual order
                    }
                
                    //Sort on Reverse order of first round score
                    return a.scoreone - b.scoreone;
                }
                else { //Hunter groups with R2 callback but are not hunter derby (e.g., team scoring)
                    return a.actual_order - b.actual_order
                }
            }
        }
        //All Other Cases - Use Original Order of the trip 
        return a.original_order - b.original_order
    }

  const onGridReady = useCallback((params) => {
    setStaggerGridRef(params.api)
    updateColumnDefToggle(params?.columnApi)
  }, []);

  const splitData = (data, splitCount) => {// Populate the split data in their respective groups

    //Create splitGroups based on splitCount for example if splitCount = 2 than create two splitGroups
    const splitGroups = new Array(splitCount).fill().map(() => []); 
    
    data.forEach((trip, index) => {
        const groupIndex = index % splitCount;
        // Give synthetic position to trips
        trip.pos = splitGroups[groupIndex].length + 1
        // place the trip in the respective group
        splitGroups[groupIndex].push({ ...trip });
    });
    
    return splitGroups;
  };

  const renderAGGrids = () => {
    
    const grids = [];
    let splitViewContainerStyle = { height: '500px', width: '100%' }; // Default height
    const splitCount = splitOptions[selectedSplit]; //number of split grids to be created
    let splitViewData = [] 

    // Set Height for grids
    if (splitCount === 2) {
        splitViewContainerStyle = { height: '240px', width: '100%' };
    } else if (splitCount === 3) {
        splitViewContainerStyle = { height: '150px', width: '100%' };
    } else if (splitCount >= 4) {
        splitViewContainerStyle = { height: '150px', width: '100%' };
    }

    const splitGroupsData = splitData(rowData, splitCount); //Split the row data for each split Grid
    splitViewData = splitGroupsData; //Array of rowData for Splits e.g [[Split A row data], [Split B row data], ...]
  
    for (let i = 0; i < splitCount; i++) {
        let label = "Split - " + Object.keys(splitOptions)[i];
        grids.push(
          <SplitViewGrid label={label} splitViewContainerStyle={splitViewContainerStyle} gridStyle={gridStyle} rowData={splitViewData[i]}/>
        );
    }
    return grids;
  };

  const onColumnMoved = (params) => {
    SaveModifiedColumnDefinitions(params.api.getColumnDefs(), getReferenceKey(), currentUser, customerID)
  }

  return (
    <>
    {showSplitView && 
        <Modal
            id='kt_modal_1'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName={'modal-dialog modal-dialog-centered modal-xl announcer-split-view'}
            show={showSplitView}
            onHide={()=>setShowSplitView(false)}
            onKeyPress={(event) => {
              let isButtonFocused = false
              let activeElement = document.activeElement //gets the currently focussed element
              if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                  isButtonFocused = true;
              }
              if (event.key == 'Enter' && !isButtonFocused) { //call function only when no button is in focus
                setShowSplitView(false)
              }
            }}
        >

            {/* header */}
            <div className="modal-header py-0 px-4 h-30px">
                <h2 className="fs-4">{"Class " + props.class_number + " - Splits"}</h2>
                <div className="btn btn-sm btn-icon btn-active-color-dark" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setShowSplitView(false)}>
                    <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x"/>
                </div>
            </div>

            <div className="modal-body py-3 px-4 h-910px"> 
            <div className='row h-20px'>
              <label className='col-lg-auto col-form-label fs-5 py-1 fw-bold' htmlFor='split' data-tooltip-id="ANNOUNCERTOOL.DETAIL.MODAL.CLASSSPLITS.LABEL.SELECTSPLIT">
                {intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.STAGGER.MODAL.SELECTSPLIT'})}
                </label>
              <div className='col-lg-auto'>
                <select
                  id="split"
                  className='form-select form-select-sm fs-6 h-20px py-1'
                  onChange={(e) => {setSelectedSplit(e.target.value)}}
                  value={selectedSplit}
                >
                  <option value='A'>A</option>
                  <option value='B'>B</option>
                  <option value='C'>C</option>
                  <option value='D'>D</option>
                  <option value='E'>E</option>
                  <option value='F'>F</option>
                </select>
              </div>
            </div>

                {/* Render AG Grids based on the selected option */}
                  {renderAGGrids()}
                {/* footer */}
                <div className='card-footer d-flex justify-content-end py-3 px-0 h-60px'>
                    <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold text-uppercase' style={{ marginLeft: "auto" }} onClick={()=>setShowSplitView(false)} autoFocus>
                        {intl.formatMessage({ id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.MODAL.BUTTON.CLOSE' })}
                    </button>
                </div>
            </div>
        </Modal>
    }
    
    <div className='row py-1'>
        <div className="col fs-7 text-truncate fw-bold">{props.label}</div>
        {(props.class_id) &&
        <div className="col fs-7 text-end mw-100px"><a href="#" className="text-decoration-none text-decoration-underline text-danger" onClick={() => setShowSplitView(true)} data-tooltip-id="ANNOUNCERTOOL.DETAIL.TAB.INGATEANDANNOUNCER.LINK.VIEWSPLITS">View Splits</a></div>
        }
    </div>
    <div style={{width: "100%", height: props.dynamic_height}}>
      <div style={gridStyle} className="stagger-grid ag-theme-alpine ingate-grid">
        <AgGridReact
          ref={gridRef}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowHeight={20}
          headerHeight={26}
          onFirstDataRendered={onFirstDataRendered}
          onGridReady = {onGridReady}
          onRowClicked={selectCurrentTrip}
          getRowStyle ={getStaggerRowStyle}
          rowClassRules={rowClassRules}
          // getRowClass={getStaggerRowClass}
          suppressDragLeaveHidesColumns={true}
					onDragStopped={onColumnMoved}
          animateRows={true}
          overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while trips are loading...</span>'}
        ></AgGridReact>
      </div>
    </div>
    </>
  );
};

export default StaggerGrid;
  