import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
// Assets
import "flatpickr/dist/themes/material_blue.css";
import { KTSVG } from '../../../_metronic/helpers';
import { useEffect, useMemo, useState } from 'react';
import { useCurrencyFormatter } from '../../modules/sgl-utils/Formatters';
import { AgGridReact } from 'ag-grid-react';
import { getExtraNarrowHeaderHeight, getNarrowRowHeight, getRowData } from '../../modules/sgl-utils/agGridHelpers';
import { useAppSelector } from '../../redux/hooks';
import axios from 'axios';
import { useAlert } from '../../modules/sgl-utils/DialogsProvider';
import { addFloatingNumbers } from '../../modules/sgl-utils/SglFunctions';
import DateRenderer from '../../modules/output-listing/renderers/DateRenderer';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const CreditDetails = ({ peopleId, show, handleClose, role, rtoName }) => {
    const intl = useIntl() 
    const containerStyle = useMemo(() => ({ width: '100%', height: '80vh' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const currencyFormatter = useCurrencyFormatter()
    const [data, setData] = useState([])
    const [gridRef, setGridRef] = useState(null)
    const customerID = useAppSelector(state=> state.showCompany.company_id)
    const alertDialog = useAlert() 

    const defaultColDef = {
        suppressMenu: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: params => {
            let style = {textAlign: 'left'}
            if (typeof params.value === 'number') {
                style = { textAlign: 'right' }
            } else if (typeof params.value === 'boolean') {
                style = { textAlign: 'center' }
            }
            return { ...style } 
        },
        headerClass: "ag-center-aligned-header",
        sortable: true,
        resizable: true
    }

    const columnDefs = [   
        { 
            field: 'date',
            headerName: intl.formatMessage({ id: "ENTRIES.LIST.CREDIT.DETAIL.COL.DATE" }),
            width: 120,
            cellClass: 'text-center',
            cellRenderer: DateRenderer
        },    
        { 
            field: 'type',
            headerName: intl.formatMessage({ id: "ENTRIES.LIST.CREDIT.DETAIL.COL.TYPE" }),
            width: 200,
            cellStyle: params => { return { textAlign: 'left' } }
        },
        { 
            field: 'Entry.number',
            headerName: intl.formatMessage({ id: "ENTRIES.LIST.CREDIT.DETAIL.COL.ENTRY" }),
            width: 100,
            cellClass: 'text-center'
        },
        { 
            field: 'Show.show_name', 
            headerName: intl.formatMessage({ id: 'ENTRIES.LIST.CREDIT.DETAIL.COL.SHOW' }), 
            flex: 2,
            cellStyle: params => { return { textAlign: 'left' } }
        },
        { 
            field: 'description', 
            headerName: intl.formatMessage({ id: 'ENTRIES.LIST.CREDIT.DETAIL.COL.DESCRIPTION' }), 
            flex: 2,
            cellStyle: params => { return { textAlign: 'left' } }
        },
        { 
            field: 'amount', 
            headerName: intl.formatMessage({ id: 'ENTRIES.LIST.CREDIT.DETAIL.COL.AMOUNT' }),
            width: 120,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: params => getCellStyles(params)
        }
    ]

    useEffect(() => {
        if(peopleId && customerID && gridRef){
            gridRef?.showLoadingOverlay()
            axios.get(`${NEST_API_URL}/people/getAccountRegisterList?customer_id=${customerID}&people_id=${peopleId}&get_pending_check=${true}`)
            .then(response => {
                setData(response?.data?? [])
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                    handleClose()
                }
            })
            .finally( () => {
                gridRef?.hideOverlay()
            })
        }
    }, [gridRef])

    useEffect(() => {
        if(gridRef){
            const rowData = getRowData(gridRef)
            let totalAmount = 0

            rowData.forEach(row => {           
                totalAmount = addFloatingNumbers([row.amount, totalAmount], 2)
            })

            gridRef?.setPinnedBottomRowData([{
                amount: totalAmount
            }])
        }
    }, [gridRef, data])

    const getCellStyles = (params) => {
        if(params.node.rowPinned){ // bottom pinned row
            return { textAlign: "right", fontWeight: "bold"}
        }

        return { textAlign: "right"}
    }

    const rowClassRules = {
        'bg-expired': function(params) {
            return params.node.data?.isPendingCheck;
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered credit-detail-dialog search-form'
            show={show}
            onHide={handleClose}
        >
            <style>{"@media (min-width: 992px) { .manual-override-form .w-lg-140px { width: 140px; }}"}</style>
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{`${intl.formatMessage({ id: 'ENTRIES.LIST.CREDIT.DETAIL.HEADING' })} - ${rtoName} (${role})`}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                    <form noValidate className='form set-membership-dates-form' onSubmit={e => e.preventDefault()}>
                        <div className='card-body p-0'>
                            <div className='card-body p-0'>
                                <div style={containerStyle}>
                                    <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                        <AgGridReact
                                            onGridReady={params => setGridRef(params.api)}
                                            defaultColDef={defaultColDef}
                                            rowData={data}
                                            rowStyle={{ fontSize: '13px', 'paddingLeft': 0, 'paddingRight': 0}}
                                            columnDefs={columnDefs} 
                                            headerHeight={getExtraNarrowHeaderHeight}
                                            rowHeight={getNarrowRowHeight}
                                            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while account register credit details are loading.</span>'}
                                            rowClassRules={rowClassRules}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='card-footer d-flex justify-content-between py-1 px-0 align-items-center'>
                                {/* Info label */}
                                <div className='row'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-2' style={{ width:'35px',height:'7px', backgroundColor: '#ff8c81' }}></div>
                                        <label className='col-form-label fs-5 py-0'>
                                            {intl.formatMessage({ id: 'ENTRIES.LIST.CREDIT.LABEL.ERROR' })}
                                        </label>
                                    </div>
                                </div>
                                {/* Cancel Button */}
                                <div className='d-flex align-items-center'>
                                    <button type="button" className='btn btn-sm btn-secondary fw-bold me-0 text-uppercase' onClick={handleClose} autoFocus>
                                        {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CLOSE' })}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default CreditDetails


