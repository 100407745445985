import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import { useState, useMemo, useEffect } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { createPortal } from 'react-dom'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'
import { isValidJson } from '../../../../modules/sgl-utils/SglFunctions'
import Select from 'react-select'
import { reactSelectStyles } from '../../../../modules/sgl-utils/fieldControls'
import { useAlert, useConfirm, useLoadingOverlay } from '../../../../modules/sgl-utils/DialogsProvider'
import { useAppSelector } from '../../../../redux/hooks'
import axios from 'axios'
import useAccessChecker from '../../../../modules/hooks/use-access-checker'
import moment from 'moment'

const TaxFormInformationModal = ({ show, handleClose, updateCallbackFromParent, personData, organizationMembership, updatePersonRecordAfterTaxStatusLookup, calledFrom, clearMembership, selectedTaxFormRequestType, handleTinStatusCheck }) => {
    const [gridRef, setGridRef] = useState();
    const [rowData, setRowData] = useState();
    const intl = useIntl();
    const modalsRoot = document.getElementById('root-modals') || document.body;
    const methods = useFormContext();
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const [selectedRequestType, setSelectedRequestType] = useState('Create Request');
    const [errorMessage, setErrorMessage] = useState({color: 'black', message: ''});
    const [cancelAction, setCancelAction] = useState(false);
    const [userFormSubmission, setUserFormSubmission] = useState();
    const [formActionOptions, setFormActionOptions] = useState([]);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const company_url_path = useAppSelector(state=> state.showCompany.urlpath)
    const confirmDialog = useConfirm();
    const peopleData = personData ? personData : {};
    const [formData, setFormData] = useState({signer_name: peopleData.fl_name || '', signer_email: peopleData.email_address || ''});
    const [selectedManualForm, setSelectedManualForm] = useState({value: 'W-9', label: 'W-9'});
    const [selectedFormAction, setSelectedFormAction] = useState({value: '', label: 'Select Action'});
    const [resendRequest, setResendRequest] = useState(false);
    const [actionsVisibility, setActionsVisibility] = useState(true);
    const alertDialog = useAlert();
    const loadingOverlay = useLoadingOverlay();
    const [refetchData, setRefetchData] = useState(false);
    const [callLookup, setCallLookup] = useState(true)
    const [taxFormStatus, setTaxFormStatus] = useState('')
    const [timezoneInfo, setTimezoneInfo] = useState(null)
    const [taxFormWarning, setTaxFormWarning] = useState([])
    const [requestTaxformForManual, setRequestTaxformForManual] = useState(false)
    let organizations = calledFrom === 'RTO' ? methods.watch('organizationMembers') : methods.watch('linked_people['+ peopleData.people_id +'].membership_data')
    let taxStatusMembership = organizations ? organizations?.find((item) => item.organization_code == 'Tax Status') : ''
    let modalClass = (taxStatusMembership?.membership_exist && !resendRequest && !requestTaxformForManual) ? 'modal-dialog modal-dialog-centered mw-900px' : 'modal-dialog modal-dialog-centered mw-500px'
    const { hasSpecialPermissionToAccess } = useAccessChecker()
    const [disableTaxFormStatusChange, setDisableTaxFormStatusChange] = useState(false) // flag to check if user can change the taxfor status

    // Column defs for tin verification detail form
    const columnDefs = [
        { field: 'key', minWidth: 200, lockVisible: false, lockPosition: 'left', cellClass: 'text-start', cellStyle: { borderRight: '1px solid #eee' }, resizable: true },
        { field: 'value', minWidth: 245, flex: 1, lockVisible: false, lockPosition: 'left', cellClass: 'text-start', resizable: true, wrapText: true, autoHeight: true, },
    ]

    // Form options for manual request dropdown
    const formOptions = [
        {value: 'W-9', label: 'W-9'},
        {value: 'W8-BEN', label: 'W8-BEN'},
        {value: 'W8-BEN-E', label: 'W8-BEN-E'}
    ]

    useEffect(() => {
        getTimezoneInfo()
    }, [])

    useEffect(() => {
        if(selectedTaxFormRequestType == 'Create Request For Manual Taxform'){
            // Request taxform for rto having taxform
            setSelectedRequestType('Create Request')
            setRequestTaxformForManual(true)
        }else if(selectedTaxFormRequestType == 'Manual Request'){
             // if manual request option is selected from entry or rto from tax document action then auto select Manual Request
            setSelectedRequestType('Manual Request')
        }else{
            setSelectedRequestType('Create Request')
        }
    }, [selectedTaxFormRequestType])

    useEffect(() => {
        const getUserTaxFromSubmissionAndActions = async () => {
            loadingOverlay({show: true, message: 'Fetching Tax Form...'});
            // call lookup to get the latest membership data
            if(callLookup){
                setCallLookup(false)
                await getTaxStatusLookupData()  
            }
            let organizations = calledFrom === 'RTO' ? methods.watch('organizationMembers') : methods.watch('linked_people['+ peopleData.people_id +'].membership_data')
            let taxStatusMembership = organizations ? organizations?.find((item) => item.organization_code == 'Tax Status') : ''
            const response = await axios.post(`${process.env.REACT_APP_NEST_API_URL}/people/userTaxFormSubmissionAndActions`, {
                people_id: peopleData.people_id,
                customer_id: customerId,
                membership: taxStatusMembership ?? null
            })

            if (response?.data?.success) {
                setFormActionOptions(response.data.formActions);
                //Reset the selected action when form actions are updated
                setSelectedFormAction(response.data.formActions[0])
                if(response.data.userFormSubmission && Object.keys(response.data.userFormSubmission)?.length !== 0){
                    setUserFormSubmission(response.data.userFormSubmission)
                }else{
                    setUserFormSubmission(null)
                }

                if(
                    !hasSpecialPermissionToAccess('admin_taxforms:update-taxform-status') && // user have no permission to update tax status
                    response?.data?.userFormSubmission?.sgl_id > 0 && // user form exists
                    response?.data?.userFormSubmission?.status != 'Cancelled' // user form is not cancelled. allow user to resend the request if taxform is cancelled
                ){
                    setDisableTaxFormStatusChange(true)
                }else{
                    setDisableTaxFormStatusChange(false)
                }
            }
            loadingOverlay({show: false});
        }

        // Get the form submission along with form actions (cancel, cancel and re-request, creating them on the backend)
        getUserTaxFromSubmissionAndActions();
    }, [refetchData])

    //Update the people record after the lookup
    const getTaxStatusLookupData = async () => {
        await axios.post(`${process.env.REACT_APP_NEST_API_URL}/people/getTaxStatusLookupData`, {
            people_id: peopleData.people_id,
            customer_id: customerId,
            people: peopleData
        })
        .then( async (response) => {   
            if(response.data.success){
                let people_data = response?.data?.updated_people_data
                let taxStatusMembership = response?.data?.updated_memberships_list?.taxStatus
                if(taxStatusMembership){
                    taxStatusMembership.membership_exist = true; //  We are using membership_exist to handle show/ hide logic for Form FE components
                }
                await updatePersonRecordAfterTaxStatusLookup(peopleData.people_id, people_data, taxStatusMembership)
            }else{
                //If no data is found in lookup empty out the tax status membership
                //Note: If we have tax status requested or manual than no user form is return but we can't clear out thr membership
                if(!['requested', 'manual'].includes(taxStatusMembership?.membership_number?.toLowerCase()) && !['requested', 'manual'].includes(taxStatusMembership?.membership_level?.toLowerCase())){
                    if(calledFrom == 'Entries'){
                        await clearMembership(peopleData.people_id, 'Tax Status', true)
                    }else{
                        await clearMembership('Tax Status', true)
                    }
                }
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        });
    }


    let rtoData = calledFrom === 'RTO' ? methods.watch('people') : methods.watch('linked_people['+ peopleData.people_id +'].people_data')
    // update the grid with the response data when form submission or tincheck_lookup_response is updated for RTO
    useEffect(() => {
        if (gridRef) {
            let rowData = []
            let tincheck_lookup_response = rtoData ? rtoData?.tincheck_lookup_response : {}
            if (userFormSubmission && userFormSubmission?.status !== 'Requested' && userFormSubmission?.status !== 'Manual') {
                let formData = parseFormSubmissionAndLookupData(userFormSubmission)
                if(formData?.length){
                    rowData.push(...formData)
                }
            }
            if(tincheck_lookup_response && isValidJson(tincheck_lookup_response)){
                let lookUp_data = parseFormSubmissionAndLookupData(JSON.parse(tincheck_lookup_response))
                if(lookUp_data?.length){
                    rowData.push(...lookUp_data)
                }
            }
            
            // display warnings for W-9 only
            if(userFormSubmission && userFormSubmission?.status == 'Review' && !['W-8BEN', 'W-8BEN-E'].includes(userFormSubmission?.form_type)){
                let warning = [];
                if (userFormSubmission?.has_missing_tax_info) {
                    warning.push('Tax information missing');
                }
                
                if (userFormSubmission?.has_name_discrepancy) {
                    warning.push('Name mismatch');
                }

                if (userFormSubmission?.has_ssn_fid_discrepancy) {
                    warning.push('SSN/ FID mismatch');
                }

                setTaxFormWarning(warning)
            }else{
                setTaxFormWarning('')
            }
            setStatusForTaxForm()
            gridRef.setRowData(rowData)
        }
    }, [gridRef, userFormSubmission, rtoData.tincheck_lookup_response])

    const parseFormSubmissionAndLookupData = (lookup) => {
        if (lookup) {
            var lookup_entries = Object.entries(lookup)
            let data = []
            lookup_entries.map(([key, value]) => {
                if (value != null && typeof value === 'object') {
                    let person_data = Object.entries(value)
                    person_data.map(([key, value]) => {
                        if(['has_missing_tax_info', 'has_name_discrepancy', 'has_ssn_fid_discrepancy'].includes(key)){
                            value = value ? true : false
                        }
                        data.push({ 'key': key, "value": value })
                    })
                }
                else {
                    if(['has_missing_tax_info', 'has_name_discrepancy', 'has_ssn_fid_discrepancy'].includes(key)){
                        value = value ? true : false
                    }
                    data.push({ 'key': key, "value": value })
                }
            })
            return data
        }
    }   

    // Handle form submission for manual requests (Both scenarios: creating request and manually approving)
    const handleManualFormSubmission = async () => {
        setErrorMessage('');
        if (selectedRequestType == 'Create Request') {
            // Show confirmation dialog            
            if (peopleData.people_id > 0) {
                const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                const isEmailValid = emailPattern.test(formData.signer_email);
                const isSignerNameValid = formData.signer_name.trim() != '';

                // If name and email are valid
                if (isEmailValid && isSignerNameValid) {
                    // If cancel and rerequest then show different message
                    let choice = false;
                    if (cancelAction) {
                        choice = await confirmDialog({ message: `Are you sure you want to cancel and re-request Tax Status for ${formData.signer_name}?` });
                    } else {
                        // If first request, then show this message
                        choice = await confirmDialog({ message: `Are you sure you want to create Tax Status request and send email to ${formData.signer_email}?` });
                    }

                    if (choice) {
                        loadingOverlay({show: true});
                        const response = await createTaxFormRequest(peopleData.people_id, formData.signer_email, formData.signer_name, cancelAction, userFormSubmission?.sgl_id);

                        if (response.success) {
                            setResendRequest(false);
                            setSelectedFormAction(formActionOptions[0])
                            setRefetchData(!refetchData);
                            //updateCallbackFromParent();
                            alertDialog({message: 'Tax Status request has been sent successfully.'})
                            handleClose();
                        } else {
                            setErrorMessage({color: 'red', message: response.message});
                        }

                        loadingOverlay({show: false});
                    }
                } else {
                    setErrorMessage({color: 'red', message: 'Name and valid email address is required.'});
                }
            } else {
                setErrorMessage({color: 'red', message: 'Valid RTO ID is required.'});

            }
        } else if (selectedRequestType == 'Manual Request') { // Manually approve tax status

            if (peopleData.people_id > 0) {
                // Update RTO membership
                try {
                    if (cancelAction){
                        await changeTaxFormStatus('Cancelled', true); 
                    }

                    let choice = await confirmDialog({message: `Please confirm manual Tax Status received for ${peopleData.fl_name}.`});

                    if (choice) {
                        loadingOverlay({show: true});
                        let response = await axios.post(`${process.env.REACT_APP_NEST_API_URL}/people/createTaxStatusManualForm`, {
                            people_ids: [peopleData.people_id],
                            customer_id: customerId,
                            membership_type: selectedManualForm.value,
                            save_record: false
                        })
                        loadingOverlay({show: false});
                        if(response?.data?.success && response?.data?.memberships?.length > 0){
                            let taxStatusMembership = response?.data?.memberships[0]
                            if(taxStatusMembership){
                                taxStatusMembership.membership_exist = true; //  We are using membership_exist to handle show/ hide logic for Form FE components
                            }

                            // if manual taxform is created then clear user form submission
                            peopleData['userFormSubmission'] = null
                            peopleData['userFormStatusHistory'] = null

                            peopleData.taxform_statuscheckdate = timezoneInfo?.data?.date;
                            peopleData.taxform_statuschecktime = timezoneInfo?.data?.time;
                            await updatePersonRecordAfterTaxStatusLookup(peopleData.people_id, peopleData, taxStatusMembership)
                        }
                        //updateCallbackFromParent();
                        handleClose();
                    }
                } catch(e) {
                    // Do nothing
                }

            } else {
                setErrorMessage({color: 'red', message: 'Valid RTO ID is required.'});
            }
        }
    }

    const getAuthorizationHeadersForAdminAPI = async () => {
        // Get authorization tokens
        try {
            const authResponse = await axios.get(`${process.env.REACT_APP_NEST_API_URL}/show-management-companies/getAuthorizationHeaders`, {
                params: { customer_id: customerId }
            })
    
            if (authResponse.data.success && authResponse.data.authToken.trim() != '' && authResponse.data.dataToken.trim() != '') {
                const authHeader = `${authResponse.data.dataToken}:${authResponse.data.authToken}`
                return { success: true, authHeader: authHeader}
            } else {
                return { success: false, message: 'Error getting authorization headers.'}
            }
        } catch(e) {
            return { success: false, message: 'Error getting authorization headers.'}
        }
    }
    
    // Create tax form post request to admin api
    const createTaxFormRequest = async (peopleId, signerEmail, signerName, cancelAction=false, userFormSubmissionId=0) => {
        if (peopleId != '' && signerEmail != '' && signerName != '' && currentShowID != '' && customerId != '') {
            let formAction = '';

            // If cancel and re-request, then add action cancel
            if (cancelAction) {
                formAction = 'cancel';
            }

            try{
                let response = await axios.post(`${process.env.REACT_APP_NEST_API_URL}/people/createTaxRequestAndUpdateMemberships`, {
                    people_id: peopleId,
                    customer_id: customerId,
                    show_id: currentShowID,
                    recipient_email: signerEmail,
                    recipient_name: signerName,
                    action: formAction,
                    user_form_submission_id: userFormSubmissionId,
                    organization_code: 'Tax Status',
                    membership_number: 'Requested',
                    membership_name: '',
                    membership_level: 'Requested'
                })
    
                if (response.data.success) {
                    // update the person Tax Status Membership
                    let taxStatusMembership = response?.data?.taxStatusMembership?.membership
                    if(taxStatusMembership){
                        taxStatusMembership.membership_exist = true; //  We are using membership_exist to handle show/ hide logic for Form FE components
                    }
                    peopleData['userFormSubmission'] = response?.data?.taxStatusMembership?.person?.userFormSubmission
                    peopleData['userFormStatusHistory'] = response?.data?.taxStatusMembership?.person?.userFormStatusHistory
                    //update the form status
                    setUserFormSubmission({status: 'Requested'})
                    updatePersonRecordAfterTaxStatusLookup(peopleData.people_id, peopleData, taxStatusMembership)
                    handleClose()
                    return { success: false, message: response.data.message }
                } else {
                    return { success: false, message: response.data.message }
                }
    
            } catch(e)  {
                return { success: false, message: e.message }
            }
        }
    }

    // Input change handler for signer name and signer email
    const onInputChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    // Radio change handler for manual tax form requests
    const handleRadioChange = (value) => {
        if (value === 'Create Request') {
            setSelectedRequestType(value);
        } else {
            setSelectedRequestType(value);
        }
    }

    const handleFormActionChange = async (e) => {
        if(disableTaxFormStatusChange){
            alertDialog({message: 'Permission denied: Cannot update tax form status.'})
            return
        }

        setSelectedFormAction({value: e.value, label: e.label})
        switch (e.value) {
            case 'Resend':
            case 'Re-request':
                setResendRequest(true);
                break;
            case 'Cancel and Re-request':
                setCancelAction(true);
                setResendRequest(true);
                break;
            case 'Cancelled':
            case 'Completed':
            case 'Review':
                changeTaxFormStatus(e.value);                
                break;
            default:
                setResendRequest(false);
                break;
        }
    }

    // Hide or show actions (resend, re-request etc.)
    useEffect(() => {
        if (userFormSubmission?.formExist && !resendRequest) {
            setActionsVisibility('visible');
        } else {
            setActionsVisibility('hidden');
        }
    }, [userFormSubmission?.formExist, resendRequest])

    // Change tax form status
    const changeTaxFormStatus = async (newStatus, ignoreMessage=false) => {
        let choice = ignoreMessage ? true : await confirmDialog({ message: `Are you sure you want to change status from ${userFormSubmission?.status} to ${newStatus}?` });

        if (choice) {
            loadingOverlay({show: true, message: 'Changing Status...'})
        
            try{
                const response = await axios.get(`${process.env.REACT_APP_NEST_API_URL}/people/changeTaxFormStatusAndUpdatePeople`, {
                    params: {
                        people_id: peopleData.people_id,
                        customer_id: customerId,
                        user_form_submission_id: userFormSubmission?.sgl_id,
                        status: newStatus,
                    }
                })

                if (response?.data?.success) {
                    setRefetchData(!refetchData);
                    let taxStatusMembership = response?.data?.updated_memberships_list?.taxStatus
                    if(taxStatusMembership){
                        taxStatusMembership.membership_exist = true; //  We are using membership_exist to handle show/ hide logic for Form FE components
                    }
                    peopleData['userFormSubmission'] = response?.data?.person?.userFormSubmission
                    peopleData['userFormStatusHistory'] = response?.data?.person?.userFormStatusHistory
                    await updatePersonRecordAfterTaxStatusLookup(peopleData.people_id, peopleData, taxStatusMembership)
                    //updateCallbackFromParent();
                    if(!ignoreMessage){
                        alertDialog({ message: `Tax Status changed successfully from  ${userFormSubmission?.status} to ${newStatus}.`})
                    }
                    
                    loadingOverlay({ show: false })
                } else {
                    alertDialog({ message: `Failed to change tax status.`})
                    loadingOverlay({ show: false })
                }

            } catch (reason) {
                loadingOverlay({ show: false })
                if (reason?.response?.data?.statusCode === 403) {
                    alertDialog({ title: 'Forbidden Access', message: 'You have insufficient privileges to perform this action.', icon: "stop" })
                }
            }
        }
    }

    const setStatusForTaxForm = () => {
        let status = ''
        if(userFormSubmission?.status){
            status = userFormSubmission?.status
        }else{
            if(!['requested', 'manual'].includes(taxStatusMembership?.membership_number?.toLowerCase()) || !['requested', 'manual'].includes(taxStatusMembership?.membership_level?.toLowerCase())){
                status = taxStatusMembership?.membership_level
            }else{
                status = ''
            }
        }
        setTaxFormStatus(status)
    }

    const getTimezoneInfo = async() => {
        // update people 'taxform_statuscheckdate' and 'taxform_statuschecktime' when manual tax status membership is created
        let timeZoneInfoResponse = await axios.get(process.env.REACT_APP_NEST_API_URL + '/utility/getTimezoneInfo', {
            params: { customer_id: customerId }
        })

        setTimezoneInfo(timeZoneInfoResponse)
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName={modalClass}
            show={show}
            onHide={handleClose}
            onKeyDown={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused && !(taxStatusMembership?.membership_exist && !resendRequest)) {
                    handleManualFormSubmission()
                }
                if (event.key === 'Escape') {
                    handleClose()
                }
            }}
        >
            {/* Common Header */}
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.NAME' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                {/* Conditionally render, if membership exists then show data, else show option to generate request (manual or email) */}
                {(taxStatusMembership?.membership_exist &&  !resendRequest && !requestTaxformForManual) ?
                    <>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <label className="fs-6 fw-bold">
                                    {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.TINSTATUS' })}&nbsp;
                                    <span style={{ color: peopleData?.tincheck_status?.toLowerCase() == 'verified' ? 'green' : 'red' }}>{peopleData?.tincheck_status}</span>
                                </label>
                            </div>
                            <div  className='col-lg-3'>
                                <label className="fs-6 fw-bold">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.FORMTYPE' })} {taxStatusMembership?.membership_number}</label>
                            </div>
                            <div  className='col-lg-4'>
                                <div className='d-flex flex-column align-items-end p-0'>
                                    <label className="fs-6 d-flex align-items-end fw-bold">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.DOCUMENTSTATUS' })}
                                        &nbsp;
                                        <span style={{ color: taxFormStatus?.toLowerCase() != 'completed' && taxFormStatus?.toLowerCase() != 'manual' && userFormSubmission?.status?.toLowerCase() != '' ? 'red' : 'green' }}>{taxFormStatus}</span>
                                    </label>
                                </div>

                                {
                                    <>
                                        {/* last updated by user and date */}
                                        <div className='row mb-2'>
                                            <div className='col-lg-7 d-flex flex-column align-items-end p-0'>
                                                <label className="fs-7">
                                                    {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.USER' })}
                                                </label>

                                                <label className="fs-7">
                                                    {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.DATE' })}
                                                </label>
                                            </div>

                                            <div className='col-lg-5 d-flex flex-column align-items-end'>
                                                <label className="fs-7">
                                                    {`${!peopleData['userFormStatusHistory']?.SglLogin ? '\u00A0' : peopleData['userFormStatusHistory']?.SglLogin?.first + ' ' + peopleData['userFormStatusHistory']?.SglLogin?.last_name}`}
                                                </label>

                                                <label className="fs-7">
                                                    {`${(!peopleData['userFormStatusHistory'] && !peopleData['userFormSubmission']) || taxFormStatus?.toLowerCase() == 'manual'  ? '\u00A0' : moment(peopleData['userFormStatusHistory']?.created_at ?? methods.getValues('people.userFormSubmission')?.created_at).format('MM/DD/YYYY')}`}
                                                </label>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </>
                :
                    requestTaxformForManual ? null :
                    <>
                        {/* Manual request section */}
                        {/* Radio buttons for create request and manual request */}
                        <div className='row mb-2'>
                            <div className='col-lg-12 align-items-center ps-10 d-flex flex-end'>
                                <div className='me-5'>
                                    <input
                                        className="form-check-sm me-1"
                                        type="radio"
                                        onChange={(e) => { handleRadioChange('Create Request') }}
                                        checked={selectedRequestType == 'Create Request'}
                                        value={selectedRequestType == 'Create Request' ? 'Create Request' : 'Manual Request'}
                                        id="CREATEREQUEST"
                                        autoFocus
                                        tabIndex={1}
                                    />
                                    <label className='col-form-label fs-5 py-1 w-lg-120px me-8' htmlFor='CREATEREQUEST' data-tooltip-id="PEOPLE.DETAIL.MODAL.ELECTRONICDOCUMENTS.LABEL.CREATEREQUEST"> {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.RADIO.CREATEREQUEST' })}</label>
                                </div>
                                <div>
                                    <input
                                        className="form-check-sm me-1"
                                        type="radio"
                                        onChange={(e) => { handleRadioChange('Manual Request') }}
                                        checked={selectedRequestType == 'Manual Request'}
                                        value={selectedRequestType == 'Create Request' ? 'Create Request' : 'Manual Request'}
                                        id="MANUALREQUEST"
                                        tabIndex={2}
                                    />
                                    <label className='col-form-label fs-5 py-1 w-lg-120px' htmlFor='MANUALREQUEST' data-tooltip-id="PEOPLE.DETAIL.MODAL.ELECTRONICDOCUMENTS.LABEL.MANUALREQUEST"> {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.RADIO.MANUALREQUEST' })}</label>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {/* Common area for both forms (detail and create request) */}
                {/* TIN status and TIN status check button */}
                <div className='row mb-3'>
                    <div className='col-lg-6'>
                        {
                            !(taxStatusMembership?.membership_exist &&  !resendRequest && !requestTaxformForManual) ?
                            <h2 className="fs-7">
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.TINSTATUS' })}&nbsp;
                                <span style={{ color: peopleData?.tincheck_status?.toLowerCase() == 'verified' ? 'green' : 'red' }}>{peopleData?.tincheck_status}</span>
                            </h2> : 
                            taxFormWarning?.length > 0 ? (
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%' }}>
                                    <h2 className="fs-5" style={{ marginBottom: 0 }}>
                                        <span style={{ color: 'red' }}>{taxFormWarning?.join(', ')}</span>
                                    </h2>
                                </div>
                            ) : null
                        }
                    </div>
                    <div className='col-lg-6 d-flex flex-end'>
                        <button type='button' onClick={handleTinStatusCheck} className='btn btn-sm btn-secondary fw-bold h-25px py-1 w-180px' tabIndex={3} data-tooltip-id="PEOPLE.DETAIL.MODAL.ELECTRONICDOCUMENTS.BUTTON.RUNTINSTATUSCHECK">
                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.BUTTON.RUNTINSTATUSCHECK' })}
                        </button>
                    </div>
                </div>

                {(!userFormSubmission || resendRequest || requestTaxformForManual) && (
                    <div className='mb-2'>
                        {/* Create request form */}
                        {selectedRequestType == 'Create Request' || requestTaxformForManual
                            ?
                            <>
                                {/* Signer Name */}
                                <div className='row mb-2'>
                                    <div className='col-lg-4 col-form-label fs-5 py-1'>
                                        <label className='fs-6' data-tooltip-id="PEOPLE.DETAIL.MODAL.ELECTRONICDOCUMENTS.LABEL.SIGNERNAME">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.INPUT.SIGNERNAME' })}</label>
                                    </div>
                                    <div className='col-lg-8'>
                                        <input
                                            type='text'
                                            name='signer_name'
                                            className='form-control form-control-sm fs-6 min-h-20px py-1 text-uppercase'
                                            value={formData.signer_name}
                                            onChange={onInputChange}
                                            tabIndex={4}
                                        />
                                    </div>
                                </div>

                                {/* Signer Email */}
                                <div className='row'>
                                    <div className='col-lg-4 col-form-label fs-5 py-1'>
                                        <label className='fs-6' data-tooltip-id="PEOPLE.DETAIL.MODAL.ELECTRONICDOCUMENTS.LABEL.SIGNEREMAIL">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.INPUT.SIGNEREMAIL' })}</label>
                                    </div>
                                    <div className='col-lg-8'>
                                        <input
                                            type='text'
                                            name='signer_email'
                                            className='form-control form-control-sm fs-6 min-h-20px py-1 text-uppercase'
                                            value={formData.signer_email}
                                            onChange={onInputChange}
                                            tabIndex={5}
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className='row'>
                                    <div className='col-lg-4 col-form-label fs-5 py-1'>
                                        <label className='fs-6' data-tooltip-id="PEOPLE.DETAIL.MODAL.ELECTRONICDOCUMENTS.LABEL.FORMTYPE">{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.INPUT.FORMTYPE' })}</label>
                                    </div>
                                    <div className='col-lg-8'>
                                        <Select
                                            options={formOptions}
                                            styles={reactSelectStyles}
                                            defaultValue={selectedManualForm}
                                            value={formOptions.find((option) => option.value == selectedManualForm.value)}
                                            onChange={(e) => setSelectedManualForm({ value: e.value, label: e.label })}
                                            tabIndex={6}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                )}

                {/* If membership exist */}
                {taxStatusMembership?.membership_exist && !resendRequest && !requestTaxformForManual && selectedTaxFormRequestType != 'Create Request For Manual Taxform' ?
                    <>
                        {/* Data grid for TIN lookup response */}
                        <div>
                            <div className='col-lg-12'>
                                {columnDefs?.length > 0 && (
                                    <div className='membership-data-grid'>
                                        <OutputListingGridStatic
                                            columnDefs={columnDefs}
                                            rowData={rowData}
                                            containerStyle={containerStyle}
                                            setGridRef={setGridRef}
                                            tabIndex={7}
                                        ></OutputListingGridStatic>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                    :
                    null
                }

                {/* Footers */}
                {taxStatusMembership?.membership_exist && !resendRequest && !requestTaxformForManual
                ?
                    (taxFormStatus).toLowerCase() == 'manual' ?
                        <div className='card-footer d-flex justify-content-end py-3 px-0'>
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-lg-6'></div>
                                    <div className='col-lg-6 d-flex flex-end'>
                                        <button type='button' className='btn btn-sm btn-secondary fw-bold text-uppercase me-5 h-30px d-flex align-items-center' tabIndex={9} onClick={() => { setSelectedRequestType('Create Request'); setRequestTaxformForManual(true)}}>
                                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.BUTTON.CREATE.REQUEST' })}
                                        </button>
                                        <button type='button' onClick={handleClose} className='btn btn-sm btn-secondary fw-bold text-uppercase h-30px d-flex align-items-center' tabIndex={10}>
                                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.BUTTON.CLOSE' })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    : 
                    <>
                        {/* Footer buttons for detail form */}
                        <div className='card-footer d-flex justify-content-end py-3 px-0' style={{visibility: actionsVisibility}}>
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-lg-7'>
                                        <div className='row'>
                                            <div className='col-lg-2 d-flex align-items-center' style={{visibility: actionsVisibility}}>
                                                <label className='fs-6' data-tooltip-id="PEOPLE.DETAIL.MODAL.ELECTRONICDOCUMENTS.LABEL.ACTION">Action</label>
                                            </div>
                                            <div className='col-lg-9' style={{visibility: actionsVisibility}}>
                                                <Select 
                                                    options={formActionOptions} 
                                                    value={formActionOptions?.find((option) => selectedFormAction?.value == option.value)}
                                                    onChange={(e) => handleFormActionChange(e)}
                                                    styles={reactSelectStyles}
                                                    tabIndex={8}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-5 d-flex flex-end'>
                                        <button type='button' className='btn btn-sm btn-secondary fw-bold text-uppercase me-5 h-30px d-flex align-items-center' tabIndex={9} onClick={() => window.open(`${process.env.REACT_APP_SGL_ADMIN_URL}/${company_url_path}/electronic-documents/detail/${userFormSubmission?.sgl_id}`, '_blank')} style={{visibility: userFormSubmission?.sgl_id ? 'visible' : 'hidden'}}>
                                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.BUTTON.VIEWDETAIL' })}
                                        </button>
                                        <button type='button' onClick={handleClose} className='btn btn-sm btn-secondary fw-bold text-uppercase h-30px d-flex align-items-center' tabIndex={10}>
                                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.BUTTON.CLOSE' })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                :
                    <>
                        <div className='card-footer d-flex justify-content-end py-3 px-0'>
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-lg-7 d-flex align-items-center'>
                                        <label style={{color: errorMessage.color}} className='fs-7 fw-bold'>{errorMessage.message}</label>
                                    </div>
                                    <div className='col-lg-5 d-flex flex-end'>
                                        <button type='button' onClick={resendRequest ? () => {setResendRequest(false); setSelectedFormAction(formActionOptions[0])} : () => handleClose()} className='btn btn-sm btn-secondary fw-bold text-uppercase me-5' tabIndex={11}>
                                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.BUTTON.CANCEL' })}
                                        </button>
                                        <button type='button' onClick={handleManualFormSubmission} className='btn btn-sm btn-secondary fw-bold text-uppercase' tabIndex={12}>
                                            {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.ADDITIONAL.ELECTRONICDOCUMENTS.MODAL.BUTTON.OK' })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </Modal>,
        modalsRoot
    );
}

export default TaxFormInformationModal;