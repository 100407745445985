import { FC, Suspense, useEffect } from 'react'
import {Route, Routes, Navigate, useNavigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {Error404} from '../modules/errors/components/Error404'
import { ShowsList } from '../pages/shows/List'
import { ShowDetail } from '../pages/shows/Detail'
import { ClassRuleDetail } from '../pages/classrules/Detail'
import { EntriesList } from '../pages/entries/List'
import { HorsesList } from '../pages/horses/List'
import { PeopleList } from '../pages/people/List'
import { ClassesList } from '../pages/classes/List'
import { DivisionsList } from '../pages/divisions/List'
import { FeesList } from '../pages/fees/List'
import { MasterFeesList } from '../pages/masterfees/List'
import { PaymentsList } from '../pages/payments/List'
import { StablesList } from '../pages/stables/List'
import { ClassRulesList } from '../pages/classrules/List'
import { TaxRatesList } from '../pages/taxrates/List'
import { CircuitsList } from '../pages/circuits/List'
import { ShowSeriesList } from '../pages/showseries/List'
import { FacilitiesList } from '../pages/facilities/List'
import { RingsList } from '../pages/rings/List'
import { BarnsList } from '../pages/barns/List'
import { BarnsDetail } from '../pages/barns/Detail'
import { OrganizationsList } from '../pages/organizations/List'
import { ReportsList } from '../pages/reports/List'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { Scheduler } from '../pages/classes/simplescheduler/Scheduler'
import { AnnouncerTool } from '../pages/classes/announcer-interface/AnnouncerTool'
import { ClassDetail } from '../pages/classes/Detail'
import { HorseDetail } from '../pages/horses/Detail'
import { DivisionDetail } from '../pages/divisions/Detail'
import { StableDetail } from '../pages/stables/Detail'
import { MasterfeeDetail } from '../pages/masterfees/Detail'
import { ShowFeeDetail } from '../pages/fees/Detail'
import { CircuitDetail } from '../pages/circuits/Detail'
import { CircuitDivisionDetail } from '../pages/circuitdivisions/Detail'
import { EntryDetail } from '../pages/entries/Detail'
import { ShowSeriesDetail } from '../pages/showseries/Detail'
import { FacilitiesDetail } from "../pages/facilities/Detail"
import { RingsDetail } from '../pages/rings/Detail'
import { PeopleDetail } from "../pages/people/Detail"
import { OrderOfGo } from '../pages/classes/order-of-go/OrderOfGo'
import { PaymentDetail } from '../pages/payments/Detail'
import { ReportsDetail } from '../pages/reports/Detail'
import { OrganizationsDetail } from '../pages/organizations/Detail'
import useAccessChecker from '../modules/hooks/use-access-checker'
import ForbiddenAccess from '../modules/errors/components/ForbiddenAccess'
import { Profile } from '../pages/myprefs/Profile'
import { SiteMonitoringList } from '../pages/site-monitoring/List'
import { CloudApiLogsList } from '../pages/cloud-api-logs/List'
import { QueuedJobsList } from '../pages/queued-jobs/List'
import { CronJobsList } from '../pages/cronjobs/List'
import { SGL_CLOUD_REFERER } from '../modules/auth'
import { ErrorsList } from '../pages/errors/List'
import { TaxRateDetail } from '../pages/taxrates/Detail'
import { AnnouncerScheduler as SetPlannedTime } from '../pages/classes/simplescheduler/AnnouncerScheduler'
import { SchoolingRounds } from '../pages/classes/schooling-rounds/SchoolingRounds'
import { PaymentBatchesList } from '../pages/payment-batches/List'
import { PaymentBatchDetail } from '../pages/payment-batches/Detail'
import BarnDesigner from '../pages/barns/BarnManagement/BarnDesigner/BarnDesigner'
import { useLocation } from 'react-router-dom'
import history from '../modules/sgl-utils/unstableHistory'
import BarnReservationMode from '../pages/barns/BarnManagement/BarnReservation/BarnReservation'

const PrivateRoutes = () => {
  const { hasAtLeastOnePermission, hasAreaWritePermission, hasSpecialPermissionToAccess } = useAccessChecker()
  const getPermissionsFromArea = (area: string) => [`cloud_${area}:read`, `cloud_${area}:write`, `cloud_${area}:delete`]

  // Temp Method
  const checkAccess = (a:string) => true
  const location = useLocation();

  function IntermediateRoute() {

    const returnTo = new URLSearchParams(location.search).get('returnTo');
    if (returnTo) {
      const decodedReturnTo = decodeURIComponent(returnTo);
      history.push( `${decodedReturnTo}`, { allowNavigation: true });
    }
  
    return null; // Render nothing, just perform the redirect
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Intermediate Route */}
        <Route path="/saving"  element={<IntermediateRoute />} />

        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='auth/*' element={<Navigate to={`/${cloud_menus[0]['link']}`} />} /> */}
        <Route path='auth/*' element={<AfterAuthWrapper /> } />
            
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='/forbidden-access' element={<ForbiddenAccess />} />

        <Route 
          path='shows' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('shows')) ? <ShowsList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/shows/detail/:show_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('shows')) ? <ShowDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='classes' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('classes')) ? <ClassesList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/classes/detail/:class_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('classes')) ? <ClassDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='classrules' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('classrules')) ? <ClassRulesList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/classrules/detail/:class_rule_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('classrules')) ? <ClassRuleDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='circuits' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('circuits')) ? <CircuitsList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/circuits/detail/:circuit_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('circuits')) ? <CircuitDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='entries' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('entries')) ? <EntriesList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/entries/detail/:entry_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('entries')) ? <EntryDetail key={location.pathname} /> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='horses' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('horses')) ? <HorsesList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/horses/detail/:horse_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('horses')) ? <HorseDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='people' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('people')) ? <PeopleList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/people/detail/:people_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('people')) ? <PeopleDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='masterfees' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('masterfees')) ? <MasterFeesList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/masterfees/detail/:master_fee_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('masterfees')) ? <MasterfeeDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='fees' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('fees')) ? <FeesList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/fees/detail/:showfees_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('fees')) ? <ShowFeeDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='divisions' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('divisions')) ? <DivisionsList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/divisions/detail/:division_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('divisions')) ? <DivisionDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='payments' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('payments')) ? <PaymentsList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/payments/detail/:payment_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('payments')) ? <PaymentDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='stables' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('stables')) ? <StablesList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/stables/detail/:stable_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('stables')) ? <StableDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='showseries' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('showseries')) ? <ShowSeriesList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/showseries/detail/:show_series_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('showseries')) ? <ShowSeriesDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='organizations' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('organizations')) ? <OrganizationsList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/organizations/detail/:organization_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('organizations')) ? <OrganizationsDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='facilities' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('facilities')) ? <FacilitiesList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/facilities/detail/:facility_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('facilities')) ? <FacilitiesDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='rings' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('rings')) ? <RingsList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
          path='/rings/detail/:ring_id' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('rings')) ? <RingsDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='barns' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('barns')) ? <BarnsList /> : <Navigate to={'/forbidden-access'} />}   
        />
        <Route 
          path='/barns/detail/:barn_id' 
          element={<BarnsDetail key={location.pathname}/>}  
        />

        {/* Route to load the active barn design */}
        <Route 
          path='/barns/barn-designer/:barn_id/active/:active_barn_design_id' 
          element={ hasSpecialPermissionToAccess('cloud_barns:barn-designer') ? <BarnDesigner key={location.pathname} /> : <Navigate to={'/forbidden-access'}/>}  
        />

        {/* Route to load the barn design */}
        <Route 
          path='/barns/barn-designer/:barn_id' 
          element={ hasSpecialPermissionToAccess('cloud_barns:barn-designer') ? <BarnDesigner key={location.pathname} /> : <Navigate to={'/forbidden-access'}/>}  
        />

        <Route
          path='barns/barn-reservation/:barn_id/:barn_design_id'
          element={ hasSpecialPermissionToAccess('cloud_barns:barn-designer') ? <BarnReservationMode key={location.pathname} /> : <Navigate to={'/forbidden-access'}/>}
        />

        <Route 
          path='taxrates' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('taxrates')) ? <TaxRatesList /> : <Navigate to={'/forbidden-access'} />}  
              />

        <Route
            path='/taxrates/detail/:tax_rate_id'
            element={hasAtLeastOnePermission(getPermissionsFromArea('taxrates')) ? <TaxRateDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />}
        />

        <Route 
          path='reports' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('reports')) ? <ReportsList /> : <Navigate to={'/forbidden-access'} />}  
        />
        <Route 
            path='/reports/detail/:report_id' 
            element={hasAtLeastOnePermission(getPermissionsFromArea('reports')) ? <ReportsDetail key={location.pathname}/> : <Navigate to={'/forbidden-access'} />} 
        />

        <Route 
            path='classes/set-planned-time' 
            element={hasAreaWritePermission('set-planned-time') ? <SetPlannedTime /> :  <Navigate to={'/forbidden-access'} />} 
        />

        <Route 
            path='classes/schooling-rounds' 
            element={hasAreaWritePermission('schooling-round') ? <SchoolingRounds /> :  <Navigate to={'/forbidden-access'} />} 
        />

        <Route 
            path='classes/scheduler' 
            element={hasAreaWritePermission('simple-scheduler') ? <Scheduler /> :  <Navigate to={'/forbidden-access'} />} 
        />

        <Route 
            path='classes/announcer-interface' 
            element={hasAreaWritePermission('ingate-announcer') ? <AnnouncerTool /> :  <Navigate to={'/forbidden-access'} />} 
        />

        <Route 
            path='classes/order-of-go' 
            element={hasAreaWritePermission('oog') ? <OrderOfGo /> :  <Navigate to={'/forbidden-access'}/>} 
        />

        <Route 
            path='sitemonitoring' 
            element={hasSpecialPermissionToAccess('cloud_sgadmin:has_access') ? <SiteMonitoringList /> :  <Navigate to={'/forbidden-access'}/>} 
        />

        <Route 
            path='apilogs' 
            element={hasSpecialPermissionToAccess('cloud_sgadmin:has_access') ? <CloudApiLogsList /> :  <Navigate to={'/forbidden-access'}/>} 
        />

        <Route 
            path='queuedjobs' 
            element={hasSpecialPermissionToAccess('cloud_sgadmin:has_access') ? <QueuedJobsList /> :  <Navigate to={'/forbidden-access'}/>} 
        />

        <Route 
            path='cronjobs' 
            element={hasSpecialPermissionToAccess('cloud_sgadmin:has_access') ? <CronJobsList /> :  <Navigate to={'/forbidden-access'}/>} 
        />

        <Route 
            path='errors' 
            element={hasSpecialPermissionToAccess('cloud_sgadmin:has_access') ? <ErrorsList /> :  <Navigate to={'/forbidden-access'}/>} 
        />

        <Route 
          path='payment-batches' 
          element={hasAtLeastOnePermission(getPermissionsFromArea('payment-batches')) ? <PaymentBatchesList /> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route 
          path='/payment-batches/detail/:payment_batch_id' 
          element={hasAreaWritePermission('payment-batches') ? <PaymentBatchDetail /> : <Navigate to={'/forbidden-access'} />}  
        />

        <Route path='my-preferences' element={<Profile />} />
        <Route path='builder' element={checkAccess('builder')?<BuilderPageWrapper />:<Error404 />} />
        <Route path='menu-test' element={checkAccess('menu-test')?<MenuTestPage />:<Error404 />} />

        {/* <Route path='circuit-divisions-detail' element={checkAccess('circuit-divisions-detail')?<CircuitDivisionDetail />:<Error404 />} /> */}
        {/* <Route path='circuitdivisions/detail' element={checkAccess('circuitdivisions/detail')?<CircuitDivisionDetail />:<Error404 />} /> */}
        <Route path='/circuitdivisions/detail/:circuit_division_id' element={checkAccess('circuitdivisions/detail')?<CircuitDivisionDetail />:<Error404 />} />
        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const AfterAuthWrapper = () => {
    const navigate = useNavigate()

    useEffect(() => {
        try {
            // Get referer information from session storage
            const sglReferer = sessionStorage.getItem(SGL_CLOUD_REFERER) 
            let refererObject 

            if (!sglReferer) // Redirect to dashboard as there is no referer information!
                navigate('/dashboard')
            else 
                refererObject = JSON.parse(sglReferer) // Parse session storage object

            if (!refererObject || !refererObject.pathname) // Redirect to dashboard if pathname is not found for some reason? 
                navigate('/dashboard')

            // If there was a valid referer.. 
            let { pathname, queryParams } = refererObject
            pathname = pathname.replace('/sglcloud', '')  // check to handle dev environment, remove sgl-cloud from url at the start

            if (pathname) {
                // Remove referer from session storage and navigate to the referer
                sessionStorage.removeItem(SGL_CLOUD_REFERER)

                // Attach query params with pathname 
                // e.g., urlSearchParams = ?selectedDate=2021-07-14&selectedRing=11&selectedClassGroup=50780
                if (queryParams) {
                    if (Object.keys(queryParams).length > 0) {
                        pathname += ('?' + new URLSearchParams(queryParams).toString()) 
                    }
                }
                navigate(pathname)
            } else {
                // Incase there was no referer e.g., opened in a new tab with no referer
                navigate('/dashboard' )
            }
        } catch(err) {
            console.log("Error getting referer", err)
            navigate('/dashboard' )
        }
    }, [])

    return <></>
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
