import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useEffect, useState, useRef} from 'react'
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setCurrentListRecordIndex } from '../../redux/reducers/currentDetailPageInfoReducer';
import { useAlert, useConfirm, useDeleteConfirm } from './DialogsProvider'
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import { useMatch } from 'react-router-dom';
import { useAuth } from '../auth';
import useDeleteRecords from '../hooks/use-delete-record';
import axios from 'axios';

const InputFormFooter = (props) => {
    const methods = useFormContext();
    const intl = useIntl()
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const deleteConfirmDialog = useDeleteConfirm()
    const { deleteSingleRecord } = useDeleteRecords()
    const customerID = useAppSelector(state => state.showCompany.company_id)

    const [click, setClick] = useState(false)
    const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
    const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);
    const show_financials_locked = useAppSelector(state => state.currentShow.islocked);
    // to get the last part of the current URL 
    const match = useMatch("/:firstRoute/:secondRoute/*");
    const lastRoute = match.params["*"];
    const { currentCompany } = useAuth()
    const area = props.goBackPath.substring(1)
    // Show Save button only if User has WRITE Permissions for that (Detail) Area.
    const hasWriteAccess = currentCompany && currentCompany.permissions.includes(`cloud_${area}:write`) 
    // Show Delete button only if User has DELETE Permissions for that (Detail) Area.
    const hasDeleteAccess = currentCompany && currentCompany.permissions.includes(`cloud_${area}:delete`)

    let isProgrammaticClickRef = useRef(false); 

    const handlePrevClick = (e) => {
        if (isProgrammaticClickRef.current) {
            // Prevent default behavior if it's a programmatic click
            isProgrammaticClickRef.current = false;
            return;
        }
        // Normal manual click behavior
        updateRowIndex("Prev");
    };

    const handleNextClick = (e) => {
        if (isProgrammaticClickRef.current) {
            // Prevent default behavior if it's a programmatic click
            isProgrammaticClickRef.current = false;
            return;     
        }
        // Normal manual click behavior
        updateRowIndex("Next");
    };

    useEffect(()=>{
        if(row_selected && row_selected != "" && row_ids[row_selected]){
            if(props.propId == undefined && click){
                setClick(false)
            }
        }
    },[click])

    const updateRowIndex = (event) =>{
        dispatch(setCurrentListRecordIndex(event == "Next" ? String(Number(row_selected) + 1) : event == "Prev" ? String(Number(row_selected) - 1) : event == "First" ? String(0) : event == "Last" ? row_ids.length - 1: ""))
        setClick(true)
    }

    const handleClose = () =>{
        navigate(props.goBackPath)
    }

    const getTypedDeleteConfirmationPref = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_NEST_API_URL}/preferences/getSystemPrefs?customer_id=${customerID}&pref_name=RequireTypedConfirmationForDelete`
            );
    
            return response.data || false;
        } catch (error) {
            console.error("Error fetching system preference:", error);
            return false;
        }
    }

    const createFriendlyName = (slug) => {
        return slug
          .split("_")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
          .join(" ");
    }

    const handleDelete = async () => {
        if (show_financials_locked && ['payments', 'entries'].includes(area)) {
            alertDialog({ title: 'Warning', message: `Cannot delete ${area} record of locked show.` });
            return;
        }
    
        const requireTypedConfirmation = await getTypedDeleteConfirmationPref();
    
        let choice;
        if (requireTypedConfirmation) {
            choice = await deleteConfirmDialog({ moduleName: createFriendlyName(props?.data)});
        } else {
            choice = await confirmDialog({message: 'Are you sure you want to delete this record?', icon: 'warning', title: 'warning'})
        }
    
        if (choice) {
            await deleteSingleRecord();
        }
    }
    
    useEffect(() => {
        const handleEscKey = (event) => {
            const isModalOpen = JSON.parse(sessionStorage.getItem("modal_is_open") || "false");
            let isButtonFocused = false
            const activeElement = document.activeElement;
            let isReactSelectFocused = false;
            let isSearchFocused = false;
            let isCkEditor = false; //for master fee Product info tab
            let isAgGridTextInputFocused = false;
            const modalElements = document.querySelectorAll('[id^="kt_modal_create_app"]');
            let modalElement = modalElements[modalElements.length - 1];
            const isFlatpickr = activeElement.classList.contains('flatpickr-input');

            if (activeElement && activeElement.tagName === 'BUTTON') {
                isButtonFocused = true;
            } else if (
                activeElement &&
                activeElement.id &&
                activeElement.id.startsWith('react-select')
            ) {
                isReactSelectFocused = true;
            } else if (
                activeElement.getAttribute('name') === 'owner_search' ||
                activeElement.getAttribute('name') === 'horse_search' ||
                activeElement.getAttribute('name') === 'trainer_search' ||
                activeElement.getAttribute('name') === 'search_1' ||
                activeElement.getAttribute('name') === 'prize_money_recipient_search' ||
                activeElement.getAttribute('name') === 'responsible_party_search' ||
                activeElement.getAttribute('name') === 'rider_search' ||
                activeElement.getAttribute('name') === 'horse_search_in_modal' ||
                activeElement.getAttribute('name') === 'stable_search' ||
                // activeElement.getAttribute('name') === 'entry.number' ||
                activeElement.getAttribute('name') === 'ENTERDROPS' ||
                activeElement.getAttribute('name') === 'ENTERADDS'
            ) {
                isSearchFocused = true;
            } else if(activeElement?.className.includes('ck-editor')){
                isCkEditor = true;
            } else if(activeElement.className === 'ag-input-field-input ag-text-field-input'){
                isAgGridTextInputFocused = true
            }
            
            if (event.key === 'Escape' && modalElement == null) {
                const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
                if (((!forms_open) || (forms_open && forms_open.length == 1)) && !isModalOpen) {
                    handleClose();
                }
            } else if (event.key === 'Enter' && !event.shiftKey && !isButtonFocused && modalElement == null /*&& !isReactSelectFocused*/ && !isSearchFocused && !isCkEditor && !isAgGridTextInputFocused && !isFlatpickr) {
                const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
                if (((!forms_open) || (forms_open && forms_open.length == 1)) && !isModalOpen)  {
                    const saveButton = document.getElementById('detail_submit_button');
                    saveButton.click();
                }
            } else if ((event.ctrlKey || event.metaKey) && event.key === 'ArrowRight') {
                event.stopPropagation()
                const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
                const nextButton = document.getElementById('next-btn');
                
                if (((!forms_open) || (forms_open && forms_open.length == 1)) && !isModalOpen) {
                    if (!nextButton.disabled) {
                        isProgrammaticClickRef.current = true;
                        updateRowIndex("Next");
                        
                        setTimeout(() => {
                            nextButton.click();
                        }, 500);
                    }
                }
            } else if ((event.ctrlKey || event.metaKey) && event.key === 'ArrowLeft') {
                event.stopPropagation()
                const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
                const prevButton = document.getElementById('prev-btn');
            
                if (((!forms_open) || (forms_open && forms_open.length == 1)) && !isModalOpen) {
                    if (!prevButton.disabled) {
                        isProgrammaticClickRef.current = true;
                        updateRowIndex("Prev");
            
                        setTimeout(() => {
                            prevButton.click(); 
                        }, 400);
                    }
                }
            }
        };
        
        document.addEventListener('keydown', handleEscKey, true);
        
        return () => {
          document.removeEventListener('keydown', handleEscKey, true);
        };
    }, []);

    return (
        <>
            <div className='card-footer input-form-buttons py-3 px-0 row'> {/* Card Footer */}
                <div className='input-form-buttons-inner d-flex'>
                    <div className='col-1' style={{width: '7%'}}>&nbsp;</div>
                    <div className='col-1' style={{width: '7%'}}><label className="form-label fs-7 fw-bold"><b style={{color: '#2274a5'}}>ID: </b><span>{lastRoute}</span></label></div>
                    <div className='col d-flex flex-column'>
                        <label className="form-label fs-7 fw-bold" style={{ whiteSpace: 'pre' }}>
                            <b style={{color: '#2274a5'}}>Created</b>{'\t'}
                            {methods?.getValues(`${props.data}.creation_date`) && moment(methods?.getValues(`${props.data}.creation_date`)).isValid() ? (
                                <span>
                                {moment(methods?.getValues(`${props.data}.creation_date`)).format('M/DD/YYYY')} {'\t'}
                                {methods?.getValues(`${props.data}.creation_time`) && moment(methods?.getValues(`${props.data}.creation_time`), 'HH:mm:ss').isValid()
                                    ? moment(methods?.getValues(`${props.data}.creation_time`), 'HH:mm:ss').format('h:mm A')
                                    : ''}
                                </span>
                            ) : ''}
                        </label>

                        <label className="form-label fs-7 fw-bold" style={{ whiteSpace: 'pre' }}>
                            <b style={{color: '#2274a5'}}>Modified</b>{'\t'}
                            {methods?.getValues(`${props.data}.modified_date`) && moment(methods?.getValues(`${props.data}.modified_date`)).isValid() ? (
                                <span>
                                    {moment(methods?.getValues(`${props.data}.modified_date`)).format('M/DD/YYYY')} {'\t'}
                                    {methods?.getValues(`${props.data}.modified_time`) && moment(methods?.getValues(`${props.data}.modified_time`), 'HH:mm:ss').isValid()
                                        ? moment(methods?.getValues(`${props.data}.modified_time`), 'HH:mm:ss').format('h:mm A')
                                        : ''}
                                </span>
                            ) : ''}
                        </label>
                    </div>
                
                    <div className='col-4 d-flex justify-content-center'>
                        <button 
                            id='first-btn'
                            type='submit'
                            className='btn btn-sm btn-secondary me-2 fw-bold pe-3 ps-4' 
                            disabled={!hasWriteAccess /*|| row_selected == 0 */|| row_selected == -1 || props.propId != undefined || isNaN(Number(row_ids[row_selected])) ? true : false} 
                            onClick={(e) => {updateRowIndex("First")}} 
                        >
                            <i className='fas fa-angle-double-left fs-4'></i>
                        </button>

                        <button
                            id='prev-btn' 
                            type='submit'
                            className='btn btn-sm btn-secondary me-2 fw-bold pe-3 ps-4' 
                            disabled={!hasWriteAccess /*|| row_selected == 0*/ || row_selected == -1 || props.propId != undefined || isNaN(Number(row_ids[row_selected])) ? true : false} 
                            onClick={handlePrevClick}
                            >
                            <i className='fas fa-angle-left fs-4'></i>
                        </button>

                        <button 
                            id='next-btn'
                            type='submit' 
                            className='btn btn-sm btn-secondary me-2 fw-bold pe-3 ps-4' 
                            disabled={!hasWriteAccess /*|| row_selected == row_ids?.length -1 */|| row_selected == -1 || props.propId != undefined || isNaN(Number(row_ids[row_selected])) ? true : false} 
                            onClick={handleNextClick}
                            >
                            <i className='fas fa-angle-right fs-4'></i>
                        </button>

                        <button 
                            id='last-btn'
                            type='submit'
                            className='btn btn-sm btn-secondary me-7 fw-bold pe-3 ps-4' 
                            disabled={!hasWriteAccess /*|| row_selected == row_ids?.length -1 */|| row_selected == -1 || props.propId != undefined || isNaN(Number(row_ids[row_selected])) ? true : false} 
                            onClick={(e) => {updateRowIndex("Last")}} >
                            <i className='fas fa-angle-double-right fs-4'></i>
                        </button>
                    </div>

                    <div className='col-2 d-flex justify-content-center'>
                        {hasDeleteAccess && Number(lastRoute) !== 0 ? // has delete access & not new record.
                            <button tabIndex="-1" type='button' onClick={(e) => {e.preventDefault(); handleDelete()}} className='btn btn-sm btn-primary me-5 me-sm-4 fw-bold'>
                                <i className='fas fa-trash mb-1 mr-2 fs-12'></i>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.DELETE' })}
                            </button>
                        :null}
                    </div>

                    <div className='col-2 d-flex justify-content-end'>
                        <button type='button' id={"detail_cancel_button"} onClick={(e) => {e.preventDefault(); handleClose()}} className='btn btn-sm btn-secondary me-4 me-sm-2 fw-bold'>
                            {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CANCEL' })}
                        </button>

                        { 
                            // Show Save button only if User has WRITE Permissions for that Area.
                            hasWriteAccess &&
                            <button disabled={props?.disableSave} type='submit' id={"detail_submit_button"} className='btn btn-sm btn-dark fw-bold me-20'>
                                {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.SAVE' })}
                            </button>
                        }
                    </div>
                </div>
            </div> {/* End Card Footer */}
        </>
    );
}

export { InputFormFooter }