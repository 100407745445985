import { useState, useEffect, useCallback} from 'react'
import { useIntl } from 'react-intl'

// Custom Components
import { GeneralTab } from './Detail/GeneralTab'
import { EntriesTab } from './EntriesTab'
import { HistoryTab } from '../../modules/components/HistoryTab'

import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import { useForm, FormProvider } from "react-hook-form";
import { useAppSelector } from '../../redux/hooks';
import axios from 'axios'
import { useParams, useSearchParams } from 'react-router-dom';
import {useConfirm, useAlert, useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider';
import { useNavigate } from 'react-router-dom';
import { AccountTab } from './Detail/AccountTab'
import { AdditionalTab } from './Detail/AdditionalTab'
import { GeneralPutOnHoldForm } from './Detail/GeneralPutOnHoldForm'
import moment from 'moment'
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions'
import DetailPageModal from '../../modules/sgl-utils/DetailPageModal';
import {PageTitle} from '../../../_metronic/layout/core'
import history from '../../modules/sgl-utils/unstableHistory'
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt'
import useAccessChecker from '../../modules/hooks/use-access-checker'
import { useSyncUpdateContext } from '../../modules/output-listing/synchronizeUpdateContext'
import useAutoFocus from '../../modules/hooks/use-auto-focus'
import earthLogo from '../../../_metronic/assets/CustomIcons/blue_earth.png'
import HelpLink from '../../modules/components/HelpLink'
import { CommunicationsTab } from './Detail/CommunicationsTab'
import { useCurrencyFormatter, usePhoneNumberFormatter } from '../../modules/sgl-utils/Formatters'

const { PUBLIC_URL } = process.env

export const dirtyValues = (
    dirtyFields,
    allValues
) => {
    // NOTE: Recursive function.
  
    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
    }
  
    // Here, we have an object.
    return Object.fromEntries(
        Object.keys(dirtyFields).map((key) => [
            key,
            dirtyValues(dirtyFields[key], allValues[key])
        ])
    );
  };

const PeopleDetail = (props) => {
    const [showPutOnHoldForm, setShowPutOnHoldForm] = useState(false)
    const [isPutOnHold, setIsPutonHold] = useState()

    const intl = useIntl();
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const { people_id } = useParams();
    let current_people_id = props.id || props.id == 0 ? props.id : people_id
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const [peopleMetaData, setPeopleMetaData] = useState({})
    const [loading, setLoading] = useState(true)
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const [personData, setPersonData] = useState({
        first: '',
        last_name: '',
        city: '',
        state: '',
        company_name: '',
        fl_name: '',
        lf_name: '',
    });
    const { handleDetailPageCatch } = useAccessChecker()
    const navigate = useNavigate();
    const loadingOverlay = useLoadingOverlay()
    const phoneFormatter = usePhoneNumberFormatter()
    const [rowClicked, setRowClicked] = useState("")
    const [record_id, setRecordId] = useState("")
    const [sub_area, setSubArea] = useState("")
    const [child_component, setChildComponent] = useState("")
    const [open_forms, setOpenForms] = useState([])
    const [gridRowData, setGridRowData] = useState('')
    const { removeAllEventListeners, addSubscribedEvents, sse } = useSyncUpdateContext()
    const [syncEvent, setSyncEvent] = useState(null);
    const [showTaxInformation, setShowTaxInformation] = useState(false); // flag to show/hide the taxform membership dialog
    const [runTinCheck, setRunTinCheck] = useState(0) // flag to run tin check from tax document drop down
    const [selectedTaxFormRequestType, setSelectedTaxFormRequestType] = useState('') // open manual request form when selected 'Manual Request' from tax document drop down

    // Open the 'Account Tab' when accessed from either the Payment Batch Step 2 RTO Summary or when the RTO listing is opened with the 'Unapplied Prize Money' quick search applied.
    const [searchParams, setSearchParams] = useSearchParams();
    // const [selectedTab, setSelectedTab] = useState(0)
    const [selectedSubTab, setSelectedSubTab] = useState(0)
    const [creditHoldPastDueBalancePref, setCreditHoldPastDueBalancePref] = useState(0)
    const currencyFormatter = useCurrencyFormatter()
    useEffect(() => {
        let param_search_tab = searchParams?.get("selectedTab")
        let param_search_sub_tab = searchParams?.get("selectedSubTab")
        if(param_search_tab){
            setSearchParams(searchParams);
            setSelectedTab(param_search_tab)

            if(param_search_sub_tab){
                setSelectedSubTab(param_search_sub_tab)
            }
        }
    }, [])
    // Open the 'Account Tab' when accessed from either the Payment Batch Step 2 RTO Summary or when the RTO listing is opened with the 'Unapplied Prize Money' quick search applied.>>>

    const getDetailPageInfo = (id, area, component, data) => {
        setRecordId(id)
        setSubArea(area)
        setChildComponent(component)
        sessionStorage.setItem("dialog_is_open_in_people", true)
        setRowClicked(rowClicked => !rowClicked)
        const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
        setOpenForms(forms_open)
        setGridRowData(data)
      };
    
    // To open same detail page multiple times 
    useEffect(() => {
    if(sessionStorage.getItem("dialog_is_open_in_people") == "true"){
        sessionStorage.removeItem("dialog_is_open_in_people")
        setRowClicked(true)
        const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
        if(open_forms.includes(sub_area+"_"+record_id) == false){
            forms_open.push(sub_area+"_"+record_id);
            sessionStorage.setItem('forms_open', JSON.stringify(forms_open));
        }
    }
    }, [rowClicked])

	const [update_data, setUpdateData] = useState(0)
   	// prevent function being recreated on state change
    const updateData = useCallback(() => {setUpdateData(update_data + 1) }, [update_data]);
    
    const methods = useForm({
        defaultValues: {
            "people": {
                "first": "",
                "last_name": "",
                "company_name": "",
                "address": "",
                "address_two": "",
                "city": "",
                "postal_code": "",
                "lastRodeString": "No Entry Record",
                "lastOwnedString": "No Entry Record",
                "lastTrainedString": "No Entry Record",
                "dob": "0000-00-00",
                "isfarmrto": false,
                "country": "",
                "usef_response_eti_lookup": null,
                "tax_verify_email": "",
                'prize_preference': '',
                'bank_name': '',
                'account_number': '',
                'swift_code': ''
            },
            "showRedacted": 0,
            "paymentMethods": [],
            "organizationMembers": [],
            "accountRegisters": [],
            "responsiblePartyEntries": [],
            "prizeMoneyTrips":[],
            "pendingBatchPayments": [],
            "withholdingTax": [],
            "agingInfo": {},
            "rtosTotalBalances": {},
            "peopleEntries": [],
            "newPaymentMethod": {
                "address_1": "",
                "address_2": "", 
                "card_exp": "",
                "card_number": "",
                "city": "",
                "country": "",
                "description": "",
                "email": "",
                "name_on_card": "",
                "payment_method_id": 0,
                "phone": "",
                "zip": "",
                "state": ""
            },
            'lookup_organization': '',
            'organization_members_added': [],
            'memberships_removed': [],
            'membership_override_added': [],
            'membership_override_removed': [],
            "show_data": { require_usefmembership: 0, require_ecmembership: 0 },
            "withHoldingTaxYear": new Date().getFullYear(),
            "usef_show_pass_overrides": []

        }
    }); 
    const { register, unregister, handleSubmit, reset, watch, formState, getValues, setValue, setFocus } = methods
    const { dirtyFields } = formState;
    useNavigationPrompt(formState)
    const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
    const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);

    const onMedicalHold = methods.watch("people.on_medical_hold")
    const onCreditHold = methods.watch("people.on_credit_hold")
    const login_id = methods.watch('people.sgl_logins_id');

    const isFarmrto = watch("people.isfarmrto");

    // Watch values for conditional fields
    const companyName = watch('people.company_name');
    const firstName = watch('people.first');
    const lastName = watch('people.last_name');
  
    useEffect(() => {
        // Cleanup on unmount
        return () => unregister(); // This will unregister all fields
    }, [unregister]);
    
    useEffect(() => {
      if (isFarmrto) {
        setValue('people.company_name', companyName);
      } else {
        setValue('people.first', firstName);
        setValue('people.last_name', lastName);
      }
    }, [isFarmrto, companyName, firstName, lastName, setValue]);

    // clear out request type when taxform modal is closed
    useEffect(() => {
        if(!showTaxInformation){
            setSelectedTaxFormRequestType('')
        }
    }, [showTaxInformation])
  

    useEffect(() => {
            // Show loading overlay
            loadingOverlay({show: true})
            axios.all([
                axios.get( process.env.REACT_APP_NEST_API_URL + '/people/detail', {
                    params: {
                        people_id: current_people_id,
                        customer_id: customer_id,
                        'show_id': currentShowID
                    }
                }),
                axios.get(process.env.REACT_APP_NEST_API_URL + '/people/metaData', {
                    params: {
                        people_id: current_people_id,
                        customer_id: customer_id
                    },
                }),
                current_people_id > 0 &&
                axios.get(process.env.REACT_APP_NEST_API_URL + '/people/entries', {
                    params: {
                        people_id: current_people_id,
                        customer_id: customer_id,
                        type: 'Any'
                    },
                })])  
            .then (
                axios.spread((
                    { data : peopleDetailResponse }, 
                    { data: peopleMetaData },
                    { data: peopleEntries }
                ) => {
                    if (peopleDetailResponse) {
                        //Check if record id is invalid then redirect to list page
                        if ((peopleDetailResponse.people || peopleDetailResponse.people === '') && isInvalidRecord(peopleDetailResponse.people, current_people_id, 'people_id')){
                            navigate('/people')
                            loadingOverlay({show: false})
                        }
                        // convert state code to uppercase
                        peopleDetailResponse.people.state = peopleDetailResponse.people?.state?.toUpperCase()

                        // If phone number country code is empty then assign one
                        if(peopleDetailResponse.people && !peopleDetailResponse.people.phonenumber_country_code){
                            peopleDetailResponse.people.phonenumber_country_code = 1
                        }

                        // If mobile number country code is empty then assign one
                        if(peopleDetailResponse.people && !peopleDetailResponse.people.mobile_phone_country_code){
                            peopleDetailResponse.people.mobile_phone_country_code_country_code = 1
                        }

                        // When opened from entries update people data if changed from entries
                        if(props?.updatedData){
                            for(let key of Object.keys(props.updatedData.people_data)){
                                if(peopleDetailResponse.people[key] != props.updatedData.people_data[key]){
                                    methods.setValue(`people.${key}`, props.updatedData.people_data[key], {shouldDirty:true})
                                }
                            }

                            let membership_added = []
                            for(let membership of props.updatedData.membership_data){
                                let organization = peopleDetailResponse.organizationMembers.find((org) => org.organization_code == membership.organization_code)

                                if(organization.membership_number != membership.membership_number || 
                                    organization.valid_end != membership.valid_end || organization.valid_start != membership.valid_start){
                                        membership_added.push(membership)
                                        organization.membership_number = membership.membership_number
                                        organization.valid_end = membership.valid_end
                                        organization.valid_start = membership.valid_start
                                }
                                
                                if(organization.organization_code === 'Tax Status'){
                                    if(organization.membership_override !== membership.membership_override){
                                        organization.membership_override = membership.membership_override
                                    }
                                    if(organization.membership_level !== membership.membership_level){
                                        organization.membership_level = membership.membership_level
                                    }
                                }
                            }
                            if(membership_added.length > 0){
                                methods.setValue('organization_members_added',membership_added,{shouldDirty:true})
                                peopleDetailResponse = {
                                    ...peopleDetailResponse, 
                                    organization_members_added: methods.getValues('organization_members_added')
                                }
                            }
                            //membership override is added/edited/deleted
                            if(props?.updatedData?.membership_override_data?.membership_override_added?.length > 0 || props?.updatedData?.membership_override_data?.membership_override_removed?.length > 0){
                                methods.setValue('organizationMembers',props.updatedData.membership_data,{shouldDirty:true})
                                methods.setValue('membership_override_added',props.updatedData.membership_override_data.membership_override_added,{shouldDirty:true})
                                methods.setValue('membership_override_removed',props.updatedData.membership_override_data.membership_override_removed,{shouldDirty:true})
                                peopleDetailResponse = {
                                    ...peopleDetailResponse, 
                                    organizationMembers:  methods.getValues('organizationMembers'),
                                    membership_override_added: methods.getValues('membership_override_added'),
                                    membership_override_removed: methods.getValues('membership_override_removed')
                                }
                            }
                        }
                        
                        if(props?.id === undefined && Number(methods.getValues('people.people_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
							reset(peopleDetailResponse)
						}else{
							reset(peopleDetailResponse, { keepDirtyValues: true })
						}
                        var storedData = sessionStorage.getItem('newPersonData');
                        
                        if(storedData){
                            storedData = JSON.parse(storedData)                        
                        
                            if(current_people_id == 0){
                                methods.setValue('people.first',storedData.people.first, {shouldDirty: true})
                                methods.setValue('people.last_name',storedData.people.last_name, {shouldDirty: true})
                                methods.setValue('people.company_name',storedData.people.company_name, {shouldDirty: true})
                                methods.setValue('people.city',storedData.people.city, {shouldDirty: true})
                                methods.setValue('people.state',storedData.people.state, {shouldDirty: true})
                                methods.setValue('people.isfarmrto',storedData.people.isfarmrto, {shouldDirty: true})
                            }                        
                            var storedData = sessionStorage.removeItem('newPersonData');                            
                        }
                        // set is put on hold if either of credit/medical hold is true
                        setIsPutonHold(
                            peopleDetailResponse.people.on_credit_hold || peopleDetailResponse.people.on_medical_hold
                        )
                    }
                    setValue('peopleEntries', peopleEntries || []);
                    setPeopleMetaData(peopleMetaData)
                    getAccountDetails()
                    if(sessionStorage.getItem('forms_open') == undefined || current_people_id == people_id){
                        sessionStorage.setItem('forms_open', JSON.stringify(["People_"+Number(current_people_id)]));
                    }
                    // Show loading overlay
                    loadingOverlay({show: false})
                    setLoading(false)

                    setCreditHoldPastDueBalancePref(peopleDetailResponse?.creditHoldPastDueBalance)
                })
            )
            .catch(reason => {
                setLoading(false)
                handleDetailPageCatch(reason.response, loadingOverlay, props)
            })
    }, [/*currentShowID,*/ people_id, update_data])

    const getAccountDetails = () => {
        axios.all([
            axios.get( process.env.REACT_APP_NEST_API_URL + '/people/accountDetail', {
                params: {
                    people_id: current_people_id,
                    customer_id: customer_id,
                    year: new Date().getFullYear()
                }
            })
         ])  
        .then (
            axios.spread(({data : accountDetails}) => {
                setValue('accountRegisters', accountDetails.accountRegisters)
                setValue('pendingBatchPayments', accountDetails.pendingBatchPayments)
                setValue('responsiblePartyEntries', accountDetails.responsiblePartyEntries)
                setValue('prizeMoneyTrips', accountDetails.prizeMoneyTrips)
                setValue('withholdingTax', accountDetails.withHoldingTaxes)
                setValue('agingInfo', accountDetails.agingInfo)
                setValue('rtosTotalBalances', accountDetails.RTOBalanceTotals)
                setValue('withHoldingTaxYear', new Date().getFullYear())
            })
        )
    }

    const tabs = ["GENERAL", "ADDITIONAL", "ACCOUNT", "ENTRIES", "COMMUNICATIONS", "HISTORY"]
    const componentTags = [
        <GeneralTab peopleMetaData={peopleMetaData} showTaxInformation={showTaxInformation} setShowTaxInformation={setShowTaxInformation} runTinCheck={runTinCheck} setRunTinCheck={setRunTinCheck} setSelectedTaxFormRequestType={setSelectedTaxFormRequestType}/>, 
        <AdditionalTab updateCallbackFromParent={updateData} showTaxInformation={showTaxInformation} setShowTaxInformation={setShowTaxInformation} runTinCheck={runTinCheck} selectedTaxFormRequestType={selectedTaxFormRequestType}/>, 
        <AccountTab updateCallbackFromParent={updateData} selectedSubTab={selectedSubTab} setSelectedSubTab={setSelectedSubTab} current_people_id={current_people_id}/>, 
        <EntriesTab entriesSelectOptions={peopleMetaData.entriesSelectOptions} callbackFromParent={getDetailPageInfo}/>,
        <CommunicationsTab />,
        <HistoryTab area="People" rowData={ methods.getValues('change_history_logs') } />
    ]
    const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});

	useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

    useEffect(() => { // Update Callback useEffect
        if(syncEvent){
            let parsedEvent = JSON.parse(syncEvent.data)
            let variableName = ''
            let updateFlag = false
            
            switch(parsedEvent.type){                   
                case `People-${customer_id}-${current_people_id}`:
                    variableName = 'people'
                    updateFlag = true
                    break;
            }

            if(updateFlag){                

                axios.get( process.env.REACT_APP_NEST_API_URL + '/people/detail', {
                    params: {
                    people_id: current_people_id,
                    customer_id: customer_id,
                    show_id: currentShowID
                    }
                }).then((res) => {
                    if(parsedEvent.type.includes('People')){
                        if('organization_members_added' in dirtyFields){
                            let memberships = res.data['organizationMembers']
                            let updatedMemberships = methods.getValues('organization_members_added')
                            memberships = memberships.map(membership => {
                                let updatedMembership = updatedMemberships.find(m => m.organization_code == membership.organization_code)
                                if(updatedMembership){
                                    membership = updatedMembership
                                }
                                return membership
                            })

                            res.data['organizationMembers'] = memberships
                        }
                        // Keep Dirty Values can't handle arrays, so need to manually handle arrays
                        reset(res.data, { keepDirtyValues: true })
                    }else{
                        methods.setValue(variableName, res.data[variableName])
                    }
                })
            }            
        }
    }, [syncEvent]);

    useEffect(() => { // Remove all existing event listener and then generate and add new events with callback in SSE on change of output grid area
        removeAllEventListeners()
        return // SK - Disabling sync
        if(currentShowID && current_people_id && sse && current_people_id > 0){
            let syncEvents = []
            let eventIds = []
            // For Areas who contains show_id should add show id in its event id
            eventIds = [
                `People-${customer_id}-${current_people_id}`,
            ]

            for(let eventId of eventIds){
                // Sync Event will hold an array of eventId and callback
                syncEvents.push({
                    eventId, 
                    callback: (event) => setSyncEvent(event)
                })
            }
            addSubscribedEvents(syncEvents)
        }

        return () => removeAllEventListeners()

    }, [customer_id, currentShowID, current_people_id, sse]);

    const onSubmit = async (formData) => {
        let submitData = dirtyValues(dirtyFields, formData)
        let validation_error = validateMandatoryFields()
        if(validation_error == ''){
            let warning = checkWarnings()

            if(warning == ''){
                //call save api
                callSubmitAPI(submitData)
            } else {
                // display confirm box
                const choice = await confirmDialog({message: warning})
                if(choice){ 
                    callSubmitAPI(submitData)
                } 
            }
        } else {
            alertDialog({message: validation_error, title: 'Error'})
        }
        
    }
    function callSubmitAPI(submitData){
        const next_button = document.getElementById('next-btn');
        const last_button = document.getElementById('last-btn');
        const prev_button = document.getElementById('prev-btn');
        const first_button = document.getElementById('first-btn');
  
        // means detail page is not open in modal
        if(props?.id === undefined){
            next_button.disabled = false;
            prev_button.disabled = false;
            last_button.disabled = false;
            first_button.disabled = false;
        }

        if(submitData?.organization_members_added && submitData?.organization_members_added?.length > 0){
            for (const membership of submitData?.organization_members_added){
                membership.valid_start = (membership.valid_start && moment(membership.valid_start).isValid())?moment(membership.valid_start).format('YYYY-MM-DD'):'';
                membership.valid_end = (membership.valid_end && moment(membership.valid_end).isValid())?moment(membership.valid_end).format('YYYY-MM-DD'):'';
            }
        }

        // convert date of birth to YYYY-MM-DD format
        if (submitData.people?.dob) {
            submitData.people.dob = moment(submitData.people.dob).format("YYYY-MM-DD")
        }

        // setting country and country_code to pass default values 
        if (submitData.people?.country){
            submitData.people.country = methods.getValues('people.country')
        }
        if (submitData.people?.country_code){
            submitData.people.country_code = methods.getValues('people.country_code')
        }

        // validation check for state because when user enters a state abbrivation which is not valid then it should be empty
        if (submitData.people?.state){
            const state = peopleMetaData.states.find(
                state => state.value?.toLowerCase() === methods.watch("people.state").toLowerCase() || state.abbreviation?.toLowerCase() === methods.watch("people.state").toLowerCase()
            )

            if (!state) {
                submitData.people.state = ''
            }
        }

        loadingOverlay({show: true})
        return axios
            .post( process.env.REACT_APP_NEST_API_URL + '/people/detail', {
                params: {
                    people_id: current_people_id,
                    customer_id: customer_id,
                    show_id: currentShowID,
                    data: submitData
                }
            })
            .then((response) => {
                loadingOverlay({show: false})
                if(response.data.success) {
                    // Use Case (Param Id changed): Next/Prev buttons
                    if(props?.id == undefined && Number(methods.getValues('people.people_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                        if(row_selected && row_selected != "" && row_ids[row_selected]){
                            setRowClicked(false)
                            setLoading(true)
                            let param_search_tab = searchParams?.get("selectedTab")
                            if(param_search_tab){
                                history.push(`${PUBLIC_URL}/people/detail/${row_ids[row_selected]}?customer_id=${customer_id}&selectedTab=2&selectedSubTab=2`, { allowNavigation: true })
                            }else{
                                history.push(`${PUBLIC_URL}/people/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
                            }
                        }
                    }
                    else if(props?.id == undefined && current_people_id > 0){
                        history.push(`${PUBLIC_URL}/people`, { allowNavigation: true })
                    }
                    else if(props?.id == undefined && current_people_id == 0){
                        history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
                    }
                    else{
                        if(props.updateCallbackFromParent){
                            props.updateCallbackFromParent()
                            props.closeModal()
                        }
                    }
                    if( row_selected == row_ids?.length -1 && props?.id === undefined){
                        if (next_button && last_button) {
                            next_button.disabled = true;
                            last_button.disabled = true;
                            prev_button.disabled = false;
                            first_button.disabled = false;
                        }
                      }
                    if(row_selected == 0 && props?.id === undefined){
                        if (prev_button && first_button) {
                            prev_button.disabled = true;
                            first_button.disabled = true;
                            next_button.disabled = false;
                            last_button.disabled = false;
                        }
                    }
                } else {
                    alertDialog({message: response.data.errorMessage, title: 'Error'})
                }
            })
    }
    function validateMandatoryFields(){
        let people_data = methods.getValues('people')
        if(people_data.isfarmrto){
            if(people_data.company_name == ''){
                return 'Please enter Company Name'

            }
        } else {
            if(people_data.first == '' && people_data.last_name == ''){
                return 'Please enter First or Last name'
            }
        }
        return ''
    }
    function checkWarnings(){
        let show_data = methods.getValues('show_data')
        let organizationMembers = methods.getValues('organizationMembers')

        let message = '';
        let usefMembership = organizationMembers.find((obj => obj.organization_code == 'USEF'));
        let ecMembership = organizationMembers.find((obj => obj.organization_code == 'EC'));

        if(show_data?.require_usefmembership && show_data?.require_ecmembership){
            if (!(usefMembership?.membership_number && usefMembership?.membership_number != "") || !(ecMembership?.membership_number && ecMembership?.membership_number != "")){
                //If user has not specified USEF or EC number - then give him a chance to confirm his actions
                message = intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.CHECKWARNING.USEFANDECMEMBERSHIPREQUIRED' })
            }

        } else if(show_data?.require_usefmembership){
            if (!(usefMembership?.membership_number && usefMembership?.membership_number != "") ){
			    //If user has not specified USEF number - then give him a chance to confirm his actions
                message = intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.CHECKWARNING.USEFMEMBERSHIPREQUIRED' })
            }

        } else if(show_data?.require_ecmembership){
            if (!(ecMembership?.membership_number && ecMembership?.membership_number != "")){
                //If user has not specified USEF or EC number - then give him a chance to confirm his actions
                message = intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.PEOPLE.CHECKWARNING.ECMEMBERSHIPREQUIRED' })
            }
        }
        return message;
    }

    const handlePutonHold = async ()=>{
        if(isPutOnHold){
            let confirmMsg = "Are you sure you want to remove name from Hold?"

            if(current_people_id && current_people_id > 0 && customer_id && methods.getValues('rtosTotalBalances.accountBalance') > creditHoldPastDueBalancePref){
                await axios.get( process.env.REACT_APP_NEST_API_URL + '/people/checkCreditHold', {
                    params: {
                        people_id: current_people_id,
                        customer_id: customer_id
                    }
                }).then((res) => {
                    if(res.data && methods.getValues('people.on_credit_hold')){
                        confirmMsg = `RTO has ${currencyFormatter(methods.getValues('rtosTotalBalances.accountBalance'))} pending account balance. ${confirmMsg}`
                    }
                })
            }

            const choice = await confirmDialog({message: confirmMsg})
                if(choice){ 
                    methods.setValue('people.on_medical_hold', false ,{shouldDirty:true})
                    methods.setValue('people.on_credit_hold', false, {shouldDirty:true})
                    methods.setValue('people.credit_hold_reason', "", {shouldDirty:true})
                    setIsPutonHold(false) 
                } 
        }    
        else{
            setShowPutOnHoldForm(true)
        }
    }

    function updateLoginData(){
        loadingOverlay({show: true})
        return axios.post( process.env.REACT_APP_NEST_API_URL + '/people/updateLogins', {
          params: {
            people: methods.getValues("people"),
            customer_id: customer_id
          }
        }).then((response) => {
            if(response.data.success) {
              methods.setValue('people', response.data.people, {shouldDirty: true})
            }
            loadingOverlay({show: false})
          }).catch((reason) => {
            loadingOverlay({show: false})
            let errors = reason.response.data.response.errors.join("<br/>")
            alertDialog({message: errors, title: 'warning'})
        })
    }

    useAutoFocus('people.first', loading, update_data)

    return (
        <>
          {props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.watch('people.lf_name'), methods.watch('people.people_id')]}>{intl.formatMessage({ id: "MENU.PEOPLE" })}</PageTitle> : "" }
            <div id={"people_detail_page"} className='modal-body py-3 pt-0 px-4 horse-input-form input-form'> 
                <FormProvider {...methods}>   
                    {showPutOnHoldForm ?
                        <GeneralPutOnHoldForm 
                            show={showPutOnHoldForm} 
                            handleClose={() => setShowPutOnHoldForm(false)} 
                            handleSubmit={() => {setIsPutonHold(true)}} />
                        :
                        ""
                    }
                    <form id={"PeopleDetail_"+current_people_id} noValidate className='form max-width'  onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
                        <HelpLink tooltip="PEOPLE.DETAIL.LINK.HELP" classname="top" />
                        <div className='card-body p-0'> {/* Card Body */}
                            <div className="form-group row">

                                <div className='row mb-2'> {/* Row Starts */}
                                    
                                    <div className='col-lg-8'> {/* Column 1 Starts */}
                                        <div className='row mb-0'>
                                            <label className={`col-lg-2 col-form-label fw-bold fs-5 py-1 ${getValues("people.isfarmrto") ? 'mw-125px' : 'mw-65px'}`} htmlFor='name' data-tooltip-id="PEOPLE.DETAIL.LABEL.NAME" >
                                                { getValues("people.isfarmrto") ? 
                                                    intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.BARNCOMPANY' })
                                                    :
                                                    intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.NAME' })
                                                }
                                            </label>
                                            <div className='col-lg-7'>
                                                <div className='row mb-2'>
                                                    { 
                                                        watch("people.isfarmrto") ?  
                                                        <div className='col-lg-12'>
                                                            <input
                                                                {...register( 'people.company_name', 
                                                                {
                                                                    required: "Company name is required."
                                                                })
                                                                }
                                                                id='company_name'
                                                                type='text'                                                                 
                                                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                            />
                                                            { methods.formState.errors?.people?.company_name && <div><span className='error_message'>{methods.formState.errors.people.company_name?.message}</span></div>}
                                                        </div>
                                                        :
                                                        <>
                                                            <div className='col-lg-6'>
                                                                <input
                                                                    {...register( 'people.first', 
                                                                    {
                                                                        required: "First name is required."
                                                                    })
                                                                    }
                                                                    id='first'
                                                                    type='text'                                                                                                                                      
                                                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                                    autoFocus
                                                                />
                                                                { methods.formState.errors?.people?.first && <div><span className='error_message'>{methods.formState.errors.people.first?.message}</span></div>}
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <input
                                                                    {...register( 'people.last_name', 
                                                                    {
                                                                        required: "Last name is required."
                                                                    })
                                                                    }
                                                                    id='last_name'
                                                                    type='text'                                                                    
                                                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                                                />
                                                                { methods.formState.errors?.people?.last_name && <div><span className='error_message'>{methods.formState.errors.people.last_name?.message}</span></div>}
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-8 mw-200px'>
                                                    {
                                                        isPutOnHold ? (
                                                            <button
                                                                type="button"
                                                                onClick={() => handlePutonHold()}
                                                                className="btn btn-sm btn-secondary fw-bold h-30px py-1 text-uppercase"
                                                                id="removeFromHold"
                                                                data-tooltip-id="PEOPLE.DETAIL.BUTTON.REMOVEFROMHOLD"
                                                            >
                                                                {intl.formatMessage({ id: 'FORM.INPUT.HORSES.LABEL.REMOVEFROMHOLD' })}
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="button"
                                                                onClick={() => handlePutonHold()}
                                                                className="btn btn-sm btn-primary fw-bold h-30px py-1 text-uppercase"
                                                                id="putOnHold"
                                                                data-tooltip-id="PEOPLE.DETAIL.BUTTON.PUTONHOLD"
                                                            >
                                                                {intl.formatMessage({ id: 'FORM.INPUT.HORSES.LABEL.PUTONHOLD' })}
                                                            </button>
                                                        )
                                                    }
                                                    </div>
                                                    <div className='col ps-0 d-flex align-items-center'>
                                                        { isPutOnHold ?
                                                            <a className='col fw-bold fs-5' id="ONVETHOLD" data-tooltip-id="PEOPLE.DETAIL.LINK.ONVETHOLD">
                                                                {
                                                                    intl.formatMessage({ 
                                                                        id: `FORM.INPUT.PEOPLE.MODAL.LABEL.${ onMedicalHold && onCreditHold ? "ONCREDITMEDICALHOLD" : onMedicalHold ? "ONMEDICALHOLD" : "ONCREDITHOLD"
                                                                    }`})}
                                                            </a>
                                                            :
                                                            ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className='col'>

                                                <div>
                                                    {/* <label className='col-form-label fw-bold fs-5 py-1 w-lg-150px'></label> */}
                                                    <div className='form-check-sm form-check-custom ps-lg-3'>
                                                        <input
                                                            {...methods.register("people.isfarmrto", 
                                                            {
                                                                required: false
                                                            })
                                                            }
                                                            className='form-check-input'
                                                            type='checkbox'                                                             
                                                            id="isFarmRTO"
                                                        />
                                                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor="isFarmRTO" data-tooltip-id="PEOPLE.DETAIL.LABEL.FARMRTO"> 
                                                            Farm RTO
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> {/* Column 1 Ends */}

                                    <div className='col-lg-4 connected-login'> {/* Column 2 Starts */}
                                        {(login_id > 0 && methods.watch('people.sgl_fl_name'))?
                                            <>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span className='col-lg-11'>
                                                        <strong className='linked-label'>{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.LINKEDLOGIN'})} </strong>
                                                        <strong className='linked-value'>{methods.watch('people.sgl_fl_name')}</strong>
                                                    </span>
                                                    <span className='col-lg-1 text-end'>
                                                        <i onClick={updateLoginData} className="far fa-solid fa-rotate text-success fs-3 fw-bold cursor-pointer"></i>
                                                    </span>
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-7'>
                                                        {methods.watch('people.sgl_email') && <>
                                                            {methods.watch('people.sgl_email_verified')?
                                                                <i className="fa-sharp fa-solid fa-check-circle fa-xl text-success"></i>
                                                                :
                                                                <i className="fas fa-solid fa-circle-exclamation fa-xl text-warning"></i>
                                                            }
                                                            <span> <a href={"mailto:"+methods.watch('people.sgl_email')}>{methods.watch('people.sgl_email')}</a></span>
                                                        </>}
                                                    </div>
                                                    <div className='col-5'>
                                                        {methods.watch('people.sgl_mobile') && <>
                                                            {methods.watch('people.sgl_mobile_verified')?
                                                                <i className="fa-sharp fa-solid fa-check-circle fa-xl text-success"></i>
                                                                :
                                                                <i className="fas fa-solid fa-circle-exclamation fa-xl text-warning"></i>
                                                            }
                                                            <span> {phoneFormatter(methods.watch('people.sgl_mobile'))}</span>
                                                        </>}
                                                    </div>
                                                </div>
                                        
                                            </>
                                            :
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <span className='col-lg-11'>
                                                        <strong className='linked-label'>{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.LINKEDLOGIN'})} </strong>
                                                        <strong className='linked-value'>NONE</strong>
                                                </span>
                                                <span className='col-lg-1 text-end'>
                                                    <i onClick={updateLoginData} className="far fa-solid fa-rotate text-success fs-3 fw-bold cursor-pointer"></i>
                                                </span>
                                            </div>
                                        }
                                    </div> {/* Column 2 Ends */}

                                </div> {/* Row Ends */}

                                <input type='hidden' {...register("people.usef_number_ahsa_number")} />
                                <input type='hidden' {...register("people.usef_suspension")} />
                                <input type='hidden' {...register("people.usef_suspension_status")} />
                                <input type='hidden' {...register("people.usef_suspendcheckdate")} />
                                <input type='hidden' {...register("people.usef_suspendchecktime")} />
                                <input type='hidden' {...register("people.ec_standingsstatus")} />
                                <input type='hidden' {...register("people.ec_suspension_status")} />
                                <input type='hidden' {...register("people.ec_statuscheckdate")} />
                                <input type='hidden' {...register("people.ec_statuschecktime")} />
                                <input type='hidden' {...register("people.fei_suspension_status")} />
                                <input type='hidden' {...register("people.fei_statuscheckdate")} />
                                <input type='hidden' {...register("people.fei_statuschecktime")} />
                                <input type='hidden' {...register("people.country_code")} />
                                <input type='hidden' {...register("people.competing_nationality")} />
                                <input type='hidden' {...register("people.age")} />
                                <input type='hidden' {...register("people.lastRodeString")} />
                                <input type='hidden' {...register("people.lastTrainedString")} />
                                <input type='hidden' {...register("people.lastOwnedString")} />
                                <input type='hidden' {...register("people.ec_StandingStatus")} />
                                <input type='hidden' {...register("people.fei_id")} />
                                <input type='hidden' {...register("people.feiStandingStatus")} />
                                <input type='hidden' {...register("people.sgl_fl_name")} />
                                <input type='hidden' {...register("people.sgl_email")} />
                                <input type='hidden' {...register("people.sgl_email_verified")} />
                                <input type='hidden' {...register("people.sgl_mobile_verified")} />
                                <input type='hidden' {...register("people.sgl_mobile")} />
                                <input type='hidden' {...register("people.usef_response_eti_lookup")} />
                                <input type='hidden' {...register("people.ec_lookup_response")} />
                                <input type='hidden' {...register("people.fei_lookup_response")} />
                                <input type='hidden' {...register("people.norcal_lookup_response")} />
                                <input type='hidden' {...register("people.ushja_lookup_response")} />
                                <input type='hidden' {...register("people.ohja_lookup_response")} />
                                <input type='hidden' {...register("organization_members_added")} />
                                <input type='hidden' {...register("people.mobile_phone_country_code")} />
                                <input type='hidden' {...register("organizationMembers")} />
                                <input type='hidden' {...register("memberships_removed")} />
                                <input type='hidden' {...register("membership_override_removed")} />

                            <div className='d-flex flex-column mb-1 border p-2 bg-white'>
                                <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab; searchParams.delete('selectedTab'); searchParams.delete('selectedSubTab');} }}>
                                                    <a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == selectedTab ? ' active' : '')}
                                                        tabIndex="-1"
                                                        id={tab + "-tab"}
                                                        data-bs-toggle='tab'
                                                        href={"#" + tab + "-People-" +current_people_id}
                                                        data-tooltip-id={`PEOPLE.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.' + tab })}
                                                    </a>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>

                                <div className="tab-content mt-5 pb-2" id="myTabContent">
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab + "-People-" +current_people_id} role="tabpanel" >
                                                    <HelpLink tooltip={"PEOPLE.DETAIL.TAB."+tab+".LINK.HELP"}  />
                                                    {componentTags[index]}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div> {/* End Card Body */}
                    </div> {/* End Card Body */}

                    <InputFormFooter goBackPath={'/people'} propId={props?.id}  data={'people'}/>
                </form>
            </FormProvider>
            </div>
            {rowClicked && <DetailPageModal area={sub_area} detail_page={child_component} record_id={record_id} updateCallbackFromParent={updateData} open_forms={open_forms} gridRowData={gridRowData}/>}
        </>
    );
}

export { PeopleDetail }