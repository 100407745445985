import { createContext, useContext, useState, useEffect } from "react";
import { useAppSelector } from "../../../redux/hooks";
import axios from 'axios';
import { useAuth } from "../../../modules/auth";
  
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

// create contexts
const SchedulerContext = createContext();
const SchedulerContextUpdater = createContext();

// context consumer hook
const useSchedulerContext = () => {
  // get the context
  const context = useContext(SchedulerContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useSchedulerContext was used outside of its Provider");
  }

  return context;
};

// context consumer hook
const useSchedulerContextUpdater = () => {
  // get the context
  const context = useContext(SchedulerContextUpdater);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useSchedulerContextUpdater was used outside of its Provider");
  }

  return context;
};

const SchedulerContextProvider = ({ children }) => {
  const customerID = useAppSelector(state=> state.showCompany.company_id);
  const { currentCompany } = useAuth()
  const currentShowID = useAppSelector(state => state.currentShow.show_id);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedRing, setSelectedRing] = useState(0);
  const [selectedRingNo, setSelectedRingNo] = useState(0);
  const [currentClassGroup, setCurrentClassGroup] = useState({});
  const [scheduleSyncStats, setScheduleSyncStats] = useState({pending: 0});
  const [systemPrefs, setSystemPrefs] = useState({});

  const [showDates, setShowDates] = useState([]);
  const [showRings, setShowRings] = useState([]);

  const [classGroupsGridRef, setClassGroupsGridRef] = useState(null);
  const [classesGridRef, setClassesGridRef] = useState(null);
  const [scheduleBreaksGridRef, setScheduleBreaksGridRef] = useState(null);
  const [classGroupScheduleGridRef, setClassGroupScheduleGridRef] = useState(null);

  const [totalEntries, setTotalEntries] = useState(0);
  const [scheduleBreakAddedParams, setScheduleBreakAddedParams] = useState(null);
  const [changeHistoryLogs, setChangeHistoryLogs] = useState([])

  //Get pending scheduler sync queue records count for show company
  let syncStatsTimeout = null;

  // Get change history logs
  useEffect(() => {
    if (customerID && currentShowID) {
        axios.get(`${NEST_API_URL}/utility/getChangeHistoryLogs?customer_id=${customerID}&table_name=schedule_updates&record_id=${currentShowID}`)
        .then(res => {
        setChangeHistoryLogs(res.data)
        })
    }
  }, [customerID, currentShowID])

  useEffect(() => {
    if(classGroupsGridRef && scheduleBreaksGridRef){
      setCurrentClassGroup({}) //clear selected class group
      getClassGroups(true); //refresh class groups list
    
      if(selectedDate){
        axios.get(`${NEST_API_URL}/scheduler/getDefaultSignupSettings?customer_id=${customerID}&show_date=${selectedDate}`).then(res => {
          setSystemPrefs(res.data)
        })
      }

      // Clear the timeout when the component unmounts
      return () => {
          clearTimeout(syncStatsTimeout);
      };
    }
  }, [selectedDate, selectedRing, classGroupsGridRef, scheduleBreaksGridRef]);

  const setSchedulerState = async (params) => {
    
  };

  //Get latest class groups list for show and ring
  const getClassGroups = async (refreshList) =>  {
    if(!currentShowID || !customerID || !selectedDate || !selectedRing){ return false; }
    let class_groups_list = [];
    if(!refreshList){ 
      //Pass groups list order to calculate start times based on current group sequence set in list for ring and day
      classGroupsGridRef?.forEachNode(node => class_groups_list.push(node.data.class_group_id));
    }

    //Get data from webservice 
    classGroupsGridRef?.showLoadingOverlay()
    const grid_response = await axios.get(`${NEST_API_URL}/scheduler/classgroups?show_id=${currentShowID}&customer_id=${customerID}&day=${selectedDate}&ring_id=${selectedRing}&class_groups_list=${class_groups_list}`);
    let data = grid_response.data

    //Set response data in grids and variables
    scheduleBreaksGridRef?.setRowData(data.scheduleBreaks);
    setTotalEntries(data.totalEntries);

    //refresh list of class groups from the api response
    classGroupsGridRef?.setRowData(data.classGroups);
    classGroupsGridRef?.hideOverlay();
    getScheduleSyncStats();
  }

  //Get schedule entries and breaks for selected class group
  const updateGroupSchedule = async (updateGroups) => {
    if(!classGroupScheduleGridRef){ return false; }

    let groupSchedule = [];
    if(currentClassGroup){
      if("ClassGroupxclasses" in currentClassGroup){        
        classGroupScheduleGridRef.showLoadingOverlay()
        const grid_response = await axios.get(`${NEST_API_URL}/scheduler/classgroupschedule?show_id=${currentShowID}&customer_id=${customerID}&class_group_id=${currentClassGroup.class_group_id}`);
        
        //Set data for schedule grid
        groupSchedule = grid_response.data;

        classGroupScheduleGridRef.sizeColumnsToFit();
        classGroupScheduleGridRef.hideOverlay();

        if(updateGroups){ //refresh times for class groups but retain groups sequence
          await getClassGroups(false)
        }
      }
    }
    classGroupScheduleGridRef.setRowData(groupSchedule);
  }

  const getScheduleSyncStats = async () => {
    clearTimeout(syncStatsTimeout); //clear sync stats timeout
    if(currentCompany.cloud_config == 'full_cloud'){ //Do not call sync stats in frontend for full cloud companies
      return false; 
    }

    //Get pending sync stats for scheduler
    const sync_response = await axios.get(`${NEST_API_URL}/scheduler/getsyncstats?customer_id=${customerID}`);
    setScheduleSyncStats(sync_response.data)

    try { //set timeout to get pending sync records count
      syncStatsTimeout = setTimeout(async () => { 
        await getScheduleSyncStats();
      }, (30 * 60 * 1000)); //Get sync stats after timeout if no update received for the scheduler
    } catch(e){ 
      //Do Nothing
    }
  }

  return (
    // the Providers gives access to the context to its children
    <SchedulerContext.Provider value={{currentShowID, customerID, showDates, setShowDates, showRings, setShowRings, classGroupsGridRef, setClassGroupsGridRef, classesGridRef, setClassesGridRef, scheduleBreaksGridRef, setScheduleBreaksGridRef, currentClassGroup, setCurrentClassGroup, classGroupScheduleGridRef, setClassGroupScheduleGridRef, selectedDate, setSelectedDate, selectedRing, setSelectedRing, selectedRingNo, setSelectedRingNo, totalEntries, setTotalEntries, scheduleBreakAddedParams, setScheduleBreakAddedParams, getClassGroups, updateGroupSchedule, scheduleSyncStats, changeHistoryLogs, systemPrefs }}>
      <SchedulerContextUpdater.Provider value={setSchedulerState}>
        {children}
      </SchedulerContextUpdater.Provider>
    </SchedulerContext.Provider>
  );
};

export { SchedulerContextProvider, useSchedulerContext, useSchedulerContextUpdater };