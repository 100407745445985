import { useIntl } from "react-intl";
import { useFormContext, Controller } from "react-hook-form";

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { NumericFormat, PatternFormat } from "react-number-format";
import parsePhoneNumber from 'libphonenumber-js'
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css';
import { PhoneNumberFormatter } from '../../../modules/sgl-utils/Formatters'
import Select from 'react-select';
import { reactSelectStyles } from "../../../modules/sgl-utils/fieldControls";

const GeneralTab = (props) => {
  const intl = useIntl();
  const methods = useFormContext();

  return (
    <>
      <div className="form-group row">
        <div className="col-6">
          <div className="row">
            <div className="col-lg-2">
              <div className="d-flex align-items-center mb-2">
                <div className="form-check-sm form-check-custom me-2">
                  <input
                    {...methods.register("organization.system", {
                      required: false,
                    })}
                    className="form-check-input"
                    type="checkbox"
                    id="SYSTEM"
                    disabled={true}
                  />
                </div>
                <label
                  className="col-form-label fs-5 py-1"
                  htmlFor="SYSTEM"
                  data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.SYSTEM"
                >
                  {" "}
                  {intl.formatMessage({
                    id: "FORM.INPUT.ORGANIZATIONS.LABEL.SYSTEM",
                  })}
                </label>
              </div>
            </div>

            {
              !methods.getValues('organization.organization_id') ?
              <div className="col">
                <Controller
                  name='organization.system_organizations_id'
                  render={({ field: { onChange, value, name } }) => {
                      const selectedOption = props.systemOrganizationOptions.find((option) => option.value === value) ?? null;
                      return (
                      <Select
                          options={props.systemOrganizationOptions}
                          value={selectedOption || null} // Use null as the fallback if no option matches the value
                          id='system_organizations_id'
                          name={name}
                          isSearchable={true}
                          onChange={(org) => {
                            onChange(org.value);
                             
                            let systemOrganization = props.systemOrganizations.find((systemOrg) => systemOrg.sgl_id == org.value)
                            if(systemOrganization){
                              // Populate the form and mark fields as dirty
                              methods.setValue('organization.name', systemOrganization.name, { shouldDirty: true });
                              methods.setValue('organization.code', systemOrganization.code, { shouldDirty: true });
                              methods.setValue('organization.system', systemOrganization.system, { shouldDirty: true });
                              methods.setValue('organization.managed', systemOrganization.managed, { shouldDirty: true });
                              methods.setValue('organization.active', systemOrganization.active, { shouldDirty: true });
                              methods.setValue('organization.priority', systemOrganization.priority, { shouldDirty: true });
                              methods.setValue('organization.address', systemOrganization.address, { shouldDirty: true });
                              methods.setValue('organization.addresstwo', systemOrganization.addresstwo, { shouldDirty: true });
                              methods.setValue('organization.city', systemOrganization.city, { shouldDirty: true });
                              methods.setValue('organization.state', systemOrganization.state, { shouldDirty: true });
                              methods.setValue('organization.url', systemOrganization.url, { shouldDirty: true });
                              methods.setValue('organization.phone', systemOrganization.phone, { shouldDirty: true });
                              methods.setValue('organization.email', systemOrganization.email, { shouldDirty: true });
                              methods.setValue('organization.horse', systemOrganization.horse, { shouldDirty: true });
                              methods.setValue('organization.person', systemOrganization.person, { shouldDirty: true });
                              methods.setValue('organization.available_on_web', systemOrganization.available_on_web, { shouldDirty: true });
                              methods.setValue('organization.system_organizations_id', systemOrganization.sgl_id, { shouldDirty: true });
                            }else{
                              methods.reset(props.defaultValues);
                            }
                          }}
                          theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          })}
                          styles={reactSelectStyles}
                          placeholder='Select System Organization'
                      />
                      );
                  }}
              />
              </div> : null
            }
          </div>

          <div className="d-flex align-items-center mb-2">
            <div className="form-check-sm form-check-custom me-2">
              <input
                {...methods.register("organization.managed", {
                  required: false,
                })}
                className="form-check-input"
                type="checkbox"
                id="MANAGED"
                disabled={methods.watch("organization.system") || methods?.getValues('organization.system_organizations_id') > 0}
              />
            </div>
            <label
                className="col-form-label fs-5 py-1"
                htmlFor="MANAGED"
                data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.MANAGED"
              >
                {intl.formatMessage({
                  id: "FORM.INPUT.ORGANIZATIONS.LABEL.MANAGED",
                })}
              </label>
          </div>

          <div className="d-flex align-items-center mb-2">
            <div className="form-check-sm form-check-custom me-2">
              <input
                {...methods.register("organization.active", {
                  required: false,
                })}
                className="form-check-input"
                type="checkbox"
                id="ACTIVE"
                disabled={methods.watch("organization.system") || methods?.getValues('organization.system_organizations_id') > 0}
              />
            </div>
            <label
              className="col-form-label fs-5 py-1"
              htmlFor="ACTIVE"
              data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.ACTIVE"
            >
              {" "}
              {intl.formatMessage({
                id: "FORM.INPUT.ORGANIZATIONS.LABEL.ACTIVE",
              })}
            </label>
          </div>

          {/* <div className="row mb-2">
            <label
              className="col-3 col-form-label  fw-bold fs-5 py-1"
              htmlFor="CODE"
            >
              {intl.formatMessage({
                id: "FORM.INPUT.ORGANIZATIONS.LABEL.CODE",
              })}
            </label>
            <div className="col">
              <input
                {...methods.register("organization.code", {
                  required: false,
                })}
                id="CODE"
                type="text"
                className="form-control form-control-sm  fs-6 min-h-20px py-1"
              />
            </div>
          </div> */}

          <div className="row mb-2">
            <label
              className="col-2 col-form-label fs-5 py-1"
              htmlFor="PRIORITY"
              data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.PRIORITY"
            >
              {intl.formatMessage({
                id: "FORM.INPUT.ORGANIZATIONS.LABEL.PRIORITY",
              })}
            </label>
            <div className="col">
              <Controller
                control={methods.control}
                name="organization.priority"
                render={({ field: { onChange, name, value } }) => (
                  <NumericFormat
                    id="PRIORITY"
                    value={Number(value)}
                    allowNegative={false}
                    onValueChange={(e) => {
                      onChange(e.value);
                    }}
                    thousandSeparator=","
                    // isAllowed={withValueCap}
                    className="form-control form-control-sm fs-6 min-h-20px py-1"
                  />
                )}
              />
            </div>
          </div>
          {/* <div className="row mb-2">
            <label
              className="col-3 col-form-label  fw-bold fs-5 py-1"
              htmlFor="NAME"
            >
              {intl.formatMessage({
                id: "FORM.INPUT.ORGANIZATIONS.LABEL.NAME",
              })}
            </label>
            <div className="col">
              <input
                {...methods.register("organization.name", {
                  required: false,
                })}
                id="NAME"
                type="text"
                className="form-control form-control-sm  fs-6 min-h-20px py-1"
              />
            </div>
          </div> */}
          <div className="row mb-2">
            <label
              className="col-2 col-form-label fs-5 py-1"
              htmlFor="ADDRESS"
              data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.ADDRESS"
            >
              {intl.formatMessage({
                id: "FORM.INPUT.ORGANIZATIONS.LABEL.ADDRESS",
              })}
            </label>
            <div className="col">
              <input
                {...methods.register("organization.address", {
                  required: false,
                })}
                id="ADDRESS"
                type="text"
                className="form-control form-control-sm  fs-6 min-h-20px py-1"
              />
            </div>
          </div>
          <div className="row mb-2">
            <label
              className="col-2 col-form-label fs-5 py-1"
              htmlFor="ADDRESSTWO"
              data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.ADDRESS2"
            >
              {intl.formatMessage({
                id: "FORM.INPUT.ORGANIZATIONS.LABEL.ADDRESSTWO",
              })}
            </label>
            <div className="col">
              <input
                {...methods.register("organization.addresstwo", {
                  required: false,
                })}
                id="ADDRESSTWO"
                type="text"
                className="form-control form-control-sm  fs-6 min-h-20px py-1"
              />
            </div>
          </div>
          <div className="row mb-2">
            <label
              className="col-2 col-form-label fs-5 py-1"
              htmlFor="CITY"
              data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.CITY"
            >
              {intl.formatMessage({
                id: "FORM.INPUT.ORGANIZATIONS.LABEL.CITY",
              })}
            </label>
            <div className="col">
              <input
                {...methods.register("organization.city", {
                  required: false,
                })}
                id="CITY"
                type="text"
                className="form-control form-control-sm  fs-6 min-h-20px py-1"
              />
            </div>
          </div>
          <div className="row mb-2">
            <label
              className="col-2 col-form-label fs-5 py-1"
              htmlFor="STATE"
              data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.STATE"
            >
              {intl.formatMessage({
                id: "FORM.INPUT.ORGANIZATIONS.LABEL.STATE",
              })}
            </label>
            <div className="col">
              <input
                {...methods.register("organization.state", {
                  required: false,
                })}
                id="STATE"
                type="text"
                className="form-control form-control-sm  fs-6 min-h-20px py-1"
              />
            </div>
          </div>
          <div className="row mb-2">
            <label
              className="col-2 col-form-label fs-5 py-1"
              htmlFor="PHONE"
              data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.PHONE"
            >
              {intl.formatMessage({
                id: "FORM.INPUT.ORGANIZATIONS.LABEL.PHONE",
              })}
            </label>
            <div className='col'>
                  <Controller
                      control={methods.control}
                      name="organization.phone"
                      render={({ field: { onChange, value} }) => (
                          <IntlTelInput
                            id='PHONE'
                            inputClassName='form-control fs-6 py-0'
                            containerClassName='intl-tel-input country-code-picker'
                            style={{ width: '100%', borderRadius: '0px' }}
                            preferredCountries={["us", "ca"]}
                            placeholder=''
                            defaultCountry={parsePhoneNumber(`+${methods.getValues('organization.phone_country_code')}${value}`, 'US')?.country?.toLowerCase()}
                            allowDropdown={true}
                            value={PhoneNumberFormatter(value)}
                            onPhoneNumberChange={(_isValid, _rawValue,_countryData,_formattedValue,_extension) => {
                                const unformattedValue = _rawValue.replace(/\D/g, ''); // Remove non-digit characters
                                onChange(unformattedValue)
                                if(_countryData.dialCode){
                                    methods.setValue('organization.phone_country_code', _countryData.dialCode, { shouldDirty: true  })
                                }
                                if(unformattedValue.length < 10){
                                    methods.setError('organization.phone',{
                                        type: "custom",
                                        message: intl.formatMessage({id: 'FORM.INPUT.ALL.PHONE.VALIDATION.MESSAGE'})
                                    })
                                }
                                else{
                                    methods.clearErrors('organization.phone')
                                }
                            }}
                            onSelectFlag={(currentNumber, country) => {if(country){ methods.setValue('organization.phone_country_code', country.dialCode, { shouldDirty: true})}}}
                        />
                      )}
                  />
                  { methods.formState.errors?.organization?.phone && <div><span className='error_message'>{methods.formState.errors.organization.phone?.message}</span></div>}
              </div>
          </div>
          <div className="row mb-2">
            <label
              className="col-2 col-form-label fs-5 py-1"
              htmlFor="EMAIL"
              data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.EMAIL"
            >
              {intl.formatMessage({
                id: "FORM.INPUT.ORGANIZATIONS.LABEL.EMAIL",
              })}
            </label>
            <div className="col">
              <input
                {...methods.register("organization.email", {
                  required: false,
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                    message: "Please enter a valid email",
                  },
                })}
                id="EMAIL"
                type="text"
                className="form-control form-control-sm  fs-6 min-h-20px py-1"
              />
              {methods.formState.errors?.organization?.email && (
                <div>
                  <span className="error_message">
                    {methods.formState.errors.organization.email?.message}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="row mb-2">
            <label
              className="col-2 col-form-label fs-5 py-1"
              htmlFor="URL"
              data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.URL"
            >
              {intl.formatMessage({ id: "FORM.INPUT.ORGANIZATIONS.LABEL.URL" })}
            </label>
            <div className="col">
              <input
                {...methods.register("organization.url", {
                  required: false,
                })}
                id="URL"
                type="text"
                className="form-control form-control-sm  fs-6 min-h-20px py-1"
              />
            </div>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="form-check-sm form-check-custom me-2">
              <input
                {...methods.register("organization.horse", {
                  required: false,
                })}
                className="form-check-input"
                type="checkbox"
                id="FORHORSES"
              />
            </div>
            <label
              className="col-form-label fs-5 py-1 ignore-max-width"
              htmlFor="FORHORSES"
              data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.FORHORSES"
            >
              {" "}
              {intl.formatMessage({
                id: "FORM.INPUT.ORGANIZATIONS.LABEL.FORHORSES",
              })}
            </label>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="form-check-sm form-check-custom me-2">
              <input
                {...methods.register("organization.person", {
                  required: false,
                })}
                className="form-check-input"
                type="checkbox"
                id="FORPEOPLE"
              />
            </div>
            <label
              className="col-form-label fs-5 py-1 ignore-max-width"
              htmlFor="FORPEOPLE"
              data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.FORPEOPLE"
            >
              {" "}
              {intl.formatMessage({
                id: "FORM.INPUT.ORGANIZATIONS.LABEL.FORPEOPLE",
              })}
            </label>
          </div>
          <div className="mb-2 d-flex align-items-center">
            <div className="form-check-sm form-check-custom me-2">
              <input
                {...methods.register("organization.available_on_web", {
                  required: false,
                })}
                className="form-check-input"
                type="checkbox"
                id="AVAILABLEON"
              />
            </div>
            <label
              className="col-form-label fs-5 py-1"
              htmlFor="AVAILABLEON"
              data-tooltip-id="ORGANIZATIONS.DETAIL.TAB.GENERAL.LABEL.AVAILABLEON"
            >
              {" "}
              {intl.formatMessage({
                id: "FORM.INPUT.ORGANIZATIONS.LABEL.AVAILABLEON",
              })}
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export { GeneralTab };
