/* eslint-disable jsx-a11y/anchor-is-valid */
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import OutputListingComponent from '../../modules/output-listing/OutputListingComponent';
import BooleanCheckmarkRenderer from '../../modules/output-listing/renderers/BooleanCheckmarkRenderer';
import { BooleanFilterOptions } from '../../modules/output-listing/filters/BooleanFilter';
import DateRenderer from '../../modules/output-listing/renderers/DateRenderer';
import { renderValidInValidDot } from '../../modules/sgl-utils/agGridHelpers';
import { useNavigate } from 'react-router-dom';
import { useOutputContext } from '../../modules/output-listing/OutputListingContext';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { useCurrencyFormatter } from '../../modules/sgl-utils/Formatters';
import CreditDetails from './CreditDetails';

const EntriesList = () => {
  const intl = useIntl();
  const navigate = useNavigate()
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const currencyFormatter = useCurrencyFormatter()

  const { outputGrid, showVerificationColumns } = useOutputContext()
  const [selectedRTO, setSelectedRTO] = useState(0)
  const [showCreditDetail, setShowCreditDetail] = useState(false)
  const [role, setRole] = useState('')
  const [rtoName, setRtoName] = useState('')
   
  useEffect(() => {
    // Change verifications column visibility
    outputGrid.gridApi?.columnModel?.columnApi?.setColumnsVisible(
      ['verification', 'verification_status'], showVerificationColumns
    );
  }, [showVerificationColumns, outputGrid])

  const handleClose = () => {
    setRole('')
    setRtoName('')
    setSelectedRTO(0)
    setShowCreditDetail(false)
  }

  const columnDefs = [
    // { field: 'sgl_id', headerName: intl.formatMessage({id: 'LIST.COMMONS.SGLID'}), filter: 'agNumberColumnFilter', filterParams: { suppressAndOrCondition: true,}, },
    { field: 'verification', width: 30, maxWidth: 30, filter: 'agTextColumnFilter', headerName: "", cellRenderer: renderValidInValidDot, hide: !showVerificationColumns, cellClass: "text-center", sortable:false , cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)', textAlign: 'center' }; } return null; }},
    { field: 'entry_id', headerName: intl.formatMessage({ id: 'LIST.ENTRIES.ENTRYID' }), filter: 'agNumberColumnFilter',hide: !showVerificationColumns, cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)' }; } return null; } },
    { field: 'number', headerName: intl.formatMessage({ id: 'LIST.ENTRIES.NUMBER' }), filter: 'agNumberColumnFilter', cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)', textAlign: 'center' }; } return {textAlign: 'center'} } },
    // { field: 'horse', width: 300, headerName: intl.formatMessage({ id: 'LIST.ENTRIES.HORSE' }), filter: 'agTextColumnFilter', },
    {
      field: 'horse',
      width: 300,
      headerName: intl.formatMessage({ id: 'LIST.ENTRIES.HORSE' }),
      filter: 'agTextColumnFilter',
      valueGetter: (params) => {
          const entry = params.data;
          if (entry?.trainer_account) {
              return '-- TRAINER ACCOUNT --'
          }
          return entry?.horse;
      }
      , cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)' }; } return null; }
    },
    { field: 'trainer', width: 300, headerName: intl.formatMessage({ id: 'LIST.ENTRIES.TRAINER' }), filter: 'agTextColumnFilter', cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)'}; } return null; } },
    { field: 'owner', width: 300, headerName: intl.formatMessage({ id: 'LIST.ENTRIES.OWNER' }), filter: 'agTextColumnFilter', cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)' }; } return null; } },
    { field: 'stable', headerName: intl.formatMessage({ id: 'LIST.ENTRIES.STABLE' }), filter: 'agTextColumnFilter', cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)' }; } return null; } },
    {
      field: 'balance', 
      headerName: intl.formatMessage({ id: 'LIST.ENTRIES.BALANCE' }), 
      cellRenderer: (params) => currencyFormatter(params.value),
      filter: 'agNumberColumnFilter', 
      cellStyle: params => { 
        if (params?.data?.scratched) { 
            return { color: 'var(--kt-primary)', textAlign: 'right' }; 
        } 
        return { textAlign: 'right' }
    }
    },
    { field: 'rider_list', width: 400, headerName: intl.formatMessage({ id: 'LIST.ENTRIES.RIDERLIST' }), filter: 'agTextColumnFilter', cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)'}; } return null; } },
    { field: 'EntryRiders.status', valueGetter: (params) => { return params.data?.EntryRiders.map(rider => rider.status).filter(status => status !== "").join(',') }, width: 300, headerName: intl.formatMessage({ id: 'LIST.ENTRIES.ENTRYRIDERSTATUS' }), filter: false, sortable: false, cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)'}; } return null; } },
    // { field: 'status', headerName: intl.formatMessage({ id: 'LIST.ENTRIES.STATUS' }), filter: 'agTextColumnFilter', },
    { field: 'verification_status', headerName: intl.formatMessage({ id: 'LIST.ENTRIES.VERIFICATIONSTATUS' }), filter: 'agTextColumnFilter', width: 300, hide: !showVerificationColumns, sortable: false, cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)'}; } return null; }  },
    { field: 'Show.show_name', width: 400, headerName: intl.formatMessage({ id: 'LIST.ENTRIES.SHOWNAME' }), filter: 'agTextColumnFilter', cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)' }; } return null; }},
    { field: 'Show.start_date', headerName: intl.formatMessage({ id: 'LIST.ENTRIES.STARTDATE' }), filter: 'agDateColumnFilter', cellRenderer: params => DateRenderer(params, 'Entries Listing'), cellStyle: {textAlign: 'center'} },
    { field: 'location', headerName: intl.formatMessage({ id: 'LIST.ENTRIES.LOCATION' }), filter: 'agTextColumnFilter', cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)',  textAlign: 'center' }; } return null; } },
    { field: 'showing_status', headerName: intl.formatMessage({ id: 'LIST.ENTRIES.SHOWING' }), filter: false, cellRenderer: BooleanCheckmarkRenderer, sortable: false, cellStyle: {textAlign: 'center'}, },
    { field: 'picked_up', headerName: intl.formatMessage({ id: 'LIST.ENTRIES.PICKEDUP' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)',  textAlign: 'center' }; } return null; } },
    { field: 'scratched', headerName: intl.formatMessage({ id: 'LIST.ENTRIES.SCRATCHED' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: params => { const cellValue = params.value ? 'Scratched' : ''; return <span style={{ color: 'var(--kt-primary)' }}>{cellValue}</span>; }, },
    { field: 'secured_payment_method_id', headerName: intl.formatMessage({ id: 'LIST.ENTRIES.SECUREDPAYMENTMETHODID' }), filter: 'agNumberColumnFilter', filterParams: BooleanFilterOptions, cellRenderer: BooleanCheckmarkRenderer, cellStyle: params => { if (params?.data?.scratched) { return {color: 'var(--kt-primary)',  textAlign: 'center' }; } return null; } },
    // RP credit balance
    {
      field: 'ResponsibleParty.account_credit', 
      headerName: intl.formatMessage({ id: 'LIST.ENTRIES.RP.CREDIT.BALANCE' }), 
      filter: 'agNumberColumnFilter', 
      width: 185,
      cellRenderer: params => {
        let entry = params?.data
        let style = params?.data?.ResponsibleParty?.account_credit < 0 ? { textDecoration: 'underline', cursor: 'pointer', color: '#0d6efd' } : null
        
        if(params?.data?.scratched){
          style = style ? {...style, color: 'var(--kt-primary)'} : {color: 'var(--kt-primary)'}
        }

        // if A/R balance is debit display 0
        return(
            <span 
              onClick={() => {
                if(params?.data?.ResponsibleParty?.account_credit < 0){
                  setRole('RP')
                  setRtoName(params.data.responsibleparty)
                  setSelectedRTO(entry.responsibleparty_id)
                  setShowCreditDetail(true)
                }
              }}
              style={style}
            >
                { currencyFormatter(params?.data?.ResponsibleParty?.account_credit < 0 ? params?.data?.ResponsibleParty?.account_credit : 0) }
            </span>
        )
      },
      cellStyle: params => { return { textAlign: 'right' } }
    },
    // PMR credit balance
    {
      field: 'PMR.account_credit', 
      headerName: intl.formatMessage({ id: 'LIST.ENTRIES.PMR.CREDIT.BALANCE' }),
      filter: 'agNumberColumnFilter', 
      width: 185,
      cellRenderer: params => {
        let entry = params?.data
        let style = params?.data?.PMR?.account_credit < 0 ? { textDecoration: 'underline', cursor: 'pointer', color: '#0d6efd' } : null
        
        if(params?.data?.scratched){
          style = style ? {...style, color: 'var(--kt-primary)'} : {color: 'var(--kt-primary)'}
        }

        // if A/R balance is debit display 0
        return(
            <span 
              onClick={() => {
                if(params?.data?.PMR?.account_credit < 0){
                  setRole('PMR')
                  setRtoName(params.data.prizemoneyrecipient)
                  setSelectedRTO(entry.prizemoneyrecipient_id)
                  setShowCreditDetail(true)
                }
              }}
              style={style}
            >
                { currencyFormatter(params?.data?.PMR?.account_credit < 0 ? params?.data?.PMR?.account_credit : 0) }
            </span>
        )
      },
      cellStyle: params => { return { textAlign: 'right' } }
    }
  ]

  return (
    <>
      { showCreditDetail && selectedRTO ? <CreditDetails peopleId={selectedRTO} show={showCreditDetail} handleClose={handleClose} role={role} rtoName={rtoName}/> : null }
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.ENTRIES' })}</PageTitle>
      <OutputListingComponent key={customer_id} area={'Entries'} columnDefs={columnDefs} apiEndPoint={'entries/list'} 
        onRowDoubleClicked={(row_data) => {navigate('/entries/detail/'+row_data.entry_id+`?customer_id=${customer_id}`)}}
        onNewTabClick={(row_data) => window.open(process.env.PUBLIC_URL+'/entries/detail/'+row_data.entry_id+`?customer_id=${customer_id}`,'_blank')}>
      </OutputListingComponent>
    </>
  )
}

export { EntriesList }
