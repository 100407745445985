/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'
import {GeneralTab} from './Detail/GeneralTab'
import {AdditionalTab} from './Detail/AdditionalTab'
import {WebTab} from './Detail/WebTab'
import { RingsTab } from './Detail/RingsTab'
import {DivisionsTab} from './Detail/DivisionsTab'
import {ClassesTab} from './Detail/ClassesTab'
import {FeesTab} from './Detail/FeesTab'
import {EntriesTab} from './Detail/EntriesTab'
import {CheckListTab} from './Detail/CheckListTab'
import {WaiversTab} from './Detail/WaiversTab'
import { useForm, FormProvider, Controller } from "react-hook-form";
import axios from 'axios'
import { useAppSelector } from '../../redux/hooks';
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import { AgGridReact } from 'ag-grid-react';
import { useAlert, useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider';
import { reactSelectStyles,date_options, setDate, maskDateInput} from '../../modules/sgl-utils/fieldControls';
import { useNavigate, useParams} from 'react-router-dom';
import Select from "react-select";
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions'
import { useAppDispatch } from '../../redux/hooks';
import { setNewShowId } from '../../redux/reducers/newShowIdReducer';
import DetailPageModal from '../../modules/sgl-utils/DetailPageModal';
import { useCallback } from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { getNarrowHeaderHeight, getNarrowRowHeight } from '../../modules/sgl-utils/agGridHelpers'
import history from '../../modules/sgl-utils/unstableHistory'
import { NotesTab } from '../../modules/input/NotesTab'
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt'
import { HistoryTab } from '../../modules/components/HistoryTab'
import { useSyncUpdateContext } from '../../modules/output-listing/synchronizeUpdateContext'
import { BarnsTab } from './Detail/BarnsTab'
import { renderShowPicker } from '../../redux/reducers/renderShowPickerReducer'
import useAutoFocus from '../../modules/hooks/use-auto-focus'
import HelpLink from '../../modules/components/HelpLink'

const { PUBLIC_URL } = process.env

export const dirtyValues = (
  dirtyFields,
  allValues
) => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key])
    ])
  );
};

const ShowDetail = (props) => {
  const intl = useIntl();
  const {show_id} = useParams();
  let current_show_id = props.id || props.id == 0 ? props.id : show_id
  const customer_id = useAppSelector(state=> state.showCompany.company_id);
  const [form_meta, setFormMeta] = useState({})
  const [showData, setShowData] = useState({})
  const [loading, setLoading] = useState(true)
  const [showSearchForm, setShowSearchForm] = useState(false)
  const [viewLicense,setViewLicense] = useState(false)
  const [licenseInfo,setLicenseInfo] = useState([])
  const [encryptedLicense,setEncrptedLicense] = useState([])
  const [rowData,setRowData] = useState([])
  const alertDialog = useAlert()
  const [updateLicenseErrorMessage,setUpdateLicenseErrorMessage] = useState('')
  const [updateLicenseCount,setUpdateLicenseCount] = useState(0)
  const navigate = useNavigate();
  const [importCount,setImportCount] = useState(0)
  const loadingOverlay = useLoadingOverlay()
  const dispatch = useAppDispatch()
	const currentShowID = useAppSelector(state => state.currentShow.show_id);
  const [rowClicked, setRowClicked] = useState("")
  const [record_id, setRecordId] = useState("")
  const [sub_area, setSubArea] = useState("")
  const [child_component, setChildComponent] = useState("")
  const [gridRowData, setGridRowData] = useState('')
  const [syncEvent, setSyncEvent] = useState(null);
  const { removeAllEventListeners, addSubscribedEvents, sse } = useSyncUpdateContext()
  const [hideLoadingOverlay, setHideLoadingOverlay] = useState(false);
  const [disableSeriesDropdpwn, setDisableSeriesDropdpwn] = useState(false);

  const updateImportCount= () => {
    setImportCount(importCount+1);
  }

  function AgGridRowDataForLicense(resObj){
    var row_data_arr = [];
    Object.keys(resObj).forEach(function(key) {
      var obj = {}
      obj["Key"] = key;
      obj["Value"] = resObj[key];
      row_data_arr.push(obj);
      // setRowData(oldArray => [...oldArray, obj]);
    });
    setRowData(row_data_arr);

  }
  const columnDefs = [
    { field: 'Key', width: 200, },
    { field: 'Value', width: 400, cellStyle: {textAlign: 'left'} }
  ]

  const [open_forms, setOpenForms] = useState([])
  const getDetailPageInfo = (id, area, component, data) => {
    setRecordId(id)
    setSubArea(area)
    setChildComponent(component)
    sessionStorage.setItem("dialog_is_open_in_show", true)
    setRowClicked(rowClicked => !rowClicked)
    const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
    setOpenForms(forms_open)
    setGridRowData(data)
  };

  // To open same detail page multiple times 
  useEffect(() => {
    if(sessionStorage.getItem("dialog_is_open_in_show") == "true"){
      sessionStorage.removeItem("dialog_is_open_in_show")
      setRowClicked(true)
      const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
      if(open_forms.includes(sub_area+"_"+record_id) == false){
        forms_open.push(sub_area+"_"+record_id);
        sessionStorage.setItem('forms_open', JSON.stringify(forms_open));
      }
    }
  }, [rowClicked])

	const [update_data, setUpdateData] = useState(0)
  // prevent function being recreated on state change
  const updateData = useCallback(() => {setUpdateData(update_data + 1) }, [update_data]);

  const methods = useForm({
    defaultValues: 
    {
      'show': {
        "sync_id": "",
        "show_id":0,
        "show_name": "",
        "show_address": "",
        "city": "",
        "state": "",
        "postal_code": "",
        "start_date": "",
        "end_date": "",
        "starting_entry_number": 0,
        "entry_limit": 0,
        "customer_number": "",
        "mdfive_license": "",
        "start_time_default": "",
        "ring_count_default": 0,
        "raw_license": "",
        "show_number": "",
        "apply_results_as_payments": 0,
        "islocked": 0,
        "phone": "",
        "email_address": "",
        "fax": "",
        "secretary_name": "",
        "manager_name": "",
        "starting_class_number": 0,
        "zone": 0,
        "no_adds_days_before": 0,
        "no_adds_time_of_day": "",
        "no_drops_days_before": 0,
        "no_drops_time_of_day": "",
        "web_accountsreceivable": 0,
        "suppress_on_web": 0,
        "cc_enabled": 0,
        "starting_webentry_number": 0,
        "facility_id": 0,
        "web_included": 0,
        "web_promocode": 0,
        "post_entry_date": "",
        "post_entry_fee": 0,
        "post_entry_time": "",
        "web_post_entry_fee": 0,
        "web_post_entry_date": "",
        "web_post_entry_time": "",
        "class_add_fee": 0,
        "class_add_date": "",
        "class_add_fee_time": "",
        "class_drop_fee": 0,
        "class_drop_fee_date": "",
        "class_drop_fee_time": "",
        "class_bandit_fee": 0,
        "charge_add_fee_on_web": 0,
        "charge_drop_fee_on_web": 0,
        "class_add_fee_each": 0,
        "class_drop_fee_each": 0,
        "max_rider_limit": 0,
        "web_auto_capitalize": 0,
        "web_license": "",
        "web_authorization_date": "",
        "web_authorization_time": "",
        "web_authorized_to_date": "",
        "web_authorized_to_time": "",
        "web_enabled": 0,
        "show_series_id": 0,
        "use_show_cc_settings": 0,
        "authorize_net_login": "",
        "authorize_net_keyfield": "",
        "entry_blank_address": "",
        "entry_blank_info": "",
        "entry_blank_checks_payable_to": "",
        "ending_web_entry_number": 0,
        "auto_fees_once_per_horse": 0,
        "dont_auto_apply_prizes": 0,
        "web_deposit_required": 0,
        "web_deposit_type": "",
        "web_deposit_fixed_amount": 0,
        "web_deposit_class": 0,
        "web_deposit_fees": 0,
        "ignore_prize_money_mismatch": 0,
        "disallowwebentryreason": "",
        "enable_ca_addscratchrule": 0,
        "name_abbr": "",
        "stabling_show_id": 0,
        "stats_sent_date": "",
        "stats_sent_time": "",
        "customer_id": 0,
        "sgl_id": 0,
        "taxable_classes": 0,
        "class_tax_rate_id": 0,
        "governing_organization": "",
        "require_usefmembership": 0,
        "require_ecmembership": 0,
        "entry_scratch_fee": 0,
        "uuid": "",
        "require_membership_type": "",
        "no_ssn_for_pmr": 0,
        "ec_event_id": 0,
        "publish_on_web": 0,
        "last_update_timestamp": 0,
        "web_disallow_addentrytoseries": 0,
        "web_require_credit_card_on_file": 0,
        "ingate_add_fee": 0,
        "show_uuid": "",
        "paper_entry_closing_date": "",
        "web_entry_closing_date": "",
        "has_videos": 0,
        "usef_rating": "",
        "use_old_entry_status": 0,
        "credit_card_service_fee": 0,
        "credit_card_service_fee_id": 0,
        "credit_card_service_fee_percent": 0,
        "web_adds_disabled": 0,
        "web_adds_disabled_reason": "",
        "allow_agent_signature": 0,
        "dont_allow_agent_signature": 0,
        "require_rp_signature": 0,
        "require_web_entry_code": 0,
        "web_entry_code": "",
        "require_web_entry_stall": 0,
        "skip_announcement_email": 0,
        "disallow_agent_signature": 0,
        "country_code": "",
        "fei_venue": "",
        "fei_show_code": "",
        "rolling_show": 0,
        "order_delivery_cutoff_time": "",
        "order_delivery_cutoff_message": "",
        "starting_traineraccount_entryno": 0,
        "ending_traineraccount_entryno": 0,
        "free_video_days": 0,
        "masterfees_changeenabled": 0,
        "company_payment_gateway_id": 0,
        "barn_types": "",
        "pre_order_cutoff_date": "",
        "pre_order_cutoff_time": "",
        "locked_detail": "",
        "sponsor_id": 0,
        'web_entries_count': 0
      },
    'divisions_deleted': [],
    'classes_deleted': [],
    'fees_deleted': [],
    'entries_deleted': [],
    'check_list': [],
    'check_list_changed': false,
    'show_judges_deleted': [],
    'rings': [],
    'deleted_rings': [],
    'entry_blank_waivers': {},
    'notes': [],
    'deleted_notes': [], // used to keep track of notes that have been deleted
    'barns': [],
    'deleted_barns': [],
    },
  }); 
  const defaultPrefs = ['show.max_rider_limit', 'show.starting_entry_number', 'show.starting_class_number'];

  const { register, handleSubmit, watch, reset, formState, getValues, setValue , control, setFocus} = methods
  const { dirtyFields } = formState; 
  useNavigationPrompt(formState)
  const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
  const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);

  const tabs = ["GENERAL", "ADDITIONAL", "WEB", "RINGS", "BARNS", /* "DIVISIONS", "CLASSES",*/ "FEES", /*"ENTRIES",*/ "CHECKLIST", "WAIVERS", "NOTES", "HISTORY"]
  const componentTags = [
    <GeneralTab form_meta={form_meta} callbackFromParent={getDetailPageInfo} showData={showData} updateImportCount={updateImportCount} show_id={current_show_id} disableSeriesDropdpwn={disableSeriesDropdpwn}/>,
    <AdditionalTab form_meta={form_meta}/>,
    <WebTab form_meta={form_meta} showData={showData}/>,
    <RingsTab form_meta={form_meta} show_id={current_show_id} showData={showData} hideLoadingOverlay= {hideLoadingOverlay}/>,
    <BarnsTab form_meta={form_meta} show_id={current_show_id} showData={showData} hideLoadingOverlay= {hideLoadingOverlay}/>,
    // <DivisionsTab callbackFromParent={getDetailPageInfo}/>,
    // <ClassesTab callbackFromParent={getDetailPageInfo}/>,
    <FeesTab callbackFromParent={getDetailPageInfo} updateCallbackFromParent={updateData} show_id={current_show_id}/>,
    // <EntriesTab callbackFromParent={getDetailPageInfo}/>,
    <CheckListTab/>,
    <WaiversTab/>,
    <NotesTab />,
    <HistoryTab area="Shows" rowData={methods.getValues("change_history_logs")} />
  ]

  const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});

	useEffect(() => {
		const next_button = document?.getElementById('next-btn');
		const last_button = document?.getElementById('last-btn');
		const prev_button = document?.getElementById('prev-btn');
		const first_button = document?.getElementById('first-btn');
		if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
			next_button.disabled = true;
			last_button.disabled = true;
		}
		if (row_selected == 0 && prev_button && first_button) {
			prev_button.disabled = true;
			first_button.disabled = true;
		}
	}, [loading])

  const onSubmit = async (form_data) => {  
       // Show loading overlay
       loadingOverlay({ show: true });
      const next_button = document.getElementById('next-btn');
      const last_button = document.getElementById('last-btn');
      const prev_button = document.getElementById('prev-btn');
      const first_button = document.getElementById('first-btn');

      // means detail page is not open in modal
      if(props?.id === undefined){
        next_button.disabled = false;
        prev_button.disabled = false;
        last_button.disabled = false;
        first_button.disabled = false;
      }

      let submit_data = dirtyValues(dirtyFields, form_data)

      // Validate Checklist
      if(submit_data?.check_list_changed && submit_data?.check_list && submit_data.check_list?.length){
        let error_data = submit_data.check_list.filter(item => {
          return item?.abbreviation == '' || item?.check_list_item == ''
        })

        if(error_data.length){
          // Hide loading overlay
          loadingOverlay({ show: false });
          alertDialog({message: "The checklist items have missing values.", title: 'Error'})
          return
        }
      }

      // Create array of newly added RTO's if they were used as official >>>
      let newRTOS = submit_data?.rtos_added?.filter((rto) => {
        let existingOfficial = submit_data?.officials?.find((official) => official.membership_number == rto.person_data.membership_number)
        if(existingOfficial){
          return existingOfficial;
        }
      });
      
      if (newRTOS?.length > 0) {
        submit_data.rtos_added = newRTOS;
      }
      // Create array of newly added RTO's if they were used as official <<<

      // Always send islocked field in the submit_data even if its dirty or not
      if(submit_data.show){
        submit_data.show.islocked = form_data?.show?.islocked
      }else{
        submit_data.show = {
          islocked: form_data?.show?.islocked
        }
      }

      submit_data.show.facility_id = form_data?.show?.facility_id

      return axios.post( process.env.REACT_APP_NEST_API_URL + '/shows/detail', {
        params: {
          show_id: current_show_id,
          customer_id: customer_id,
          data: submit_data
        }
      }).then(async (response) => {
        // Hide loading overlay
        loadingOverlay({ show: false });
        if(response.data.success) {
          if(response.data.alert_message && response.data.alert_message != ''){
              await alertDialog({message: response.data.alert_message, title: 'Warning'})
              history.push(`${PUBLIC_URL}/shows`, { allowNavigation: true })
          }
          // Use Case (Param Id changed): Next/Prev buttons
          else if(props?.id == undefined && Number(methods.getValues('show.show_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
            if(row_selected && row_selected != "" && row_ids[row_selected]){
              setRowClicked(false)
              setLoading(true)
              history.push(`${PUBLIC_URL}/shows/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
            }
          }
					else if(props?.id == undefined){
            history.push(`${PUBLIC_URL}/shows`, { allowNavigation: true })
          }
          else{
            if(props.updateCallbackFromParent){
              props.updateCallbackFromParent()
              props.closeModal()
            }
          }
          if( row_selected == row_ids?.length -1 && props?.id === undefined){
            if (next_button && last_button) {
                next_button.disabled = true;
                last_button.disabled = true;
                prev_button.disabled = false;
                first_button.disabled = false;
            }
          }
          if(row_selected == 0 && props?.id === undefined){
            if (prev_button && first_button) {
                prev_button.disabled = true;
                first_button.disabled = true;
                next_button.disabled = false;
                last_button.disabled = false;
            }
          }

          const check_fields = ['show_address', 'city', 'state', 'islocked', 'name_abbr']
          if(submit_data?.show && check_fields.some(field => submit_data.show.hasOwnProperty(field) )){
            dispatch(renderShowPicker())
          }
      } else {
          // Hide loading overlay
          loadingOverlay({ show: false });
          alertDialog({message: response.data.error_message, title: 'Error'})
      }
      })
  }

  function updateLicense() {
      
      setUpdateLicenseErrorMessage('');
      axios.get(process.env.REACT_APP_NEST_API_URL + '/license/updateLicenseData', {
        params: {
          show_id: current_show_id,
          customer_id: customer_id
        }
      })
      .then(result => {   
        if(result.data.license_success) {
          var update_count = updateLicenseCount + 1;
          setUpdateLicenseCount(update_count);
        } else {
          setUpdateLicenseErrorMessage(result.data.error_message);
        }      
      })
      .catch(async function (error) {
          if (error.response) {
            setUpdateLicenseErrorMessage(error.response.data.error);
          }
      });
  }

  const setShowRanges = () => {
    if(methods.getValues('show.disallowwebentryreason') !== ''){
      methods.setValue('disallowwebentryreason',1)
    }

    // methods.setValue('show.starting_webentry_number',(Number(methods.watch('show.starting_entry_number')) + 10000))
    // methods.setValue('show.ending_web_entry_number',(Number(methods.watch('show.starting_webentry_number')) + 5000))
    // // Checks added to allow user updated value for traineraccount entry no
    // if(!methods.watch('show.starting_traineraccount_entryno')){
    //   methods.setValue('show.starting_traineraccount_entryno',(Number(methods.watch('show.ending_web_entry_number')) + 1)) 
    // }   
    // if(!methods.watch('show.ending_traineraccount_entryno')){
    //   methods.setValue('show.ending_traineraccount_entryno',(Number(methods.watch('show.starting_traineraccount_entryno')) + 5000)) 
    // }
  }
  
 useEffect(() => {
    // Show loading overlay
    loadingOverlay({show: true})
    axios.all(
    [
      axios.get( process.env.REACT_APP_NEST_API_URL + '/shows/MetaData', {
        params: {
          show_id: current_show_id,
          customer_id: customer_id
        }
      }),
      axios.get( process.env.REACT_APP_NEST_API_URL + '/shows/detail', {
        params: {
          show_id: current_show_id,
          customer_id: customer_id
        }
      }),
      axios.get( process.env.REACT_APP_NEST_API_URL + '/shows/viewDecryptedLicense', {
        params: {
          show_id: current_show_id,
          customer_id: customer_id
        }
      }),
      axios.get( process.env.REACT_APP_NEST_API_URL + '/shows/viewEncryptedLicense', {
        params: {
          show_id: current_show_id,
          customer_id: customer_id
        }
      }),
    ]  
    ).then (
      axios.spread( ({data : metaData}, {data : showData}, {data : decryptedLicense}, {data : encrptedLicense }) => {
        //Check if record id is invalid then redirect to list page
        if ((showData || showData === '') && isInvalidRecord(showData.show, current_show_id, 'show_id')){
          navigate('/shows')
          loadingOverlay({show: false})
        }
        if(current_show_id > 0 && showData.show.show_id != currentShowID){ //check if input show does not match current show in show Picker
          dispatch(setNewShowId(showData.show.show_id )) //In this case update Show in ShowPicker 
        }

        // When opened from series update show series id 
        if(props?.updatedData){
          const { show_series_id } = props.updatedData;
          methods.setValue('show.show_series_id', show_series_id, {shouldDirty:true})
          setDisableSeriesDropdpwn(true)
        }

        const moment = require('moment');
        for(let show of metaData.show_series){
          if(show.start_date && show.start_date != '0000-00-00' && show.end_date && show.end_date != '0000-00-00'){
              show.label = `${show.label} (${moment?.utc(show.start_date).format('MM/DD/YYYY')} - ${moment?.utc(show.end_date).format('MM/DD/YYYY')})`
          }
        }

      	// Assign default value 1 if phone country code and fax country code values are empty
        if(showData.shows && !showData.shows.phone_country_code){
          showData.shows.phone_country_code = 1
        }

        if(showData.shows && !showData.shows.fax_country_code){
          showData.shows.fax_country_code = 1
        }

        setFormMeta(metaData);
        setShowData(showData);

        if(props?.id === undefined && Number(methods.getValues('show.show_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
          reset(showData)
        }else{
          reset(showData, { keepDirtyValues: true })
        }
        
        defaultPrefs.forEach(key => {
          if (methods.getValues(key) === 0) {
            methods.setValue(key, 1, { shouldDirty: true });
          }
        })

        setLicenseInfo(decryptedLicense);
        setEncrptedLicense(encrptedLicense);
        if(sessionStorage.getItem('forms_open') == undefined || current_show_id == show_id){
          sessionStorage.setItem('forms_open', JSON.stringify(["Shows_"+Number(current_show_id)]));
        }

        setShowRanges()

        // Hide loading overlay
        loadingOverlay({show: false})
        setLoading(false);
      })
    )

  },[updateLicenseCount, importCount, show_id, update_data])

  useEffect(() => {
    AgGridRowDataForLicense(licenseInfo);
  },[licenseInfo])

  useEffect(() => { // Update Callback useEffect
    if(syncEvent){
      let parsedEvent = JSON.parse(syncEvent.data)
      let variableName = ''
      let updateFlag = false
      let updateId =  parsedEvent.additional_data.triggered_id
      
      switch(parsedEvent.type){
        case `Shows-${customer_id}-${show_id}`:
          variableName = 'shows'
          updateFlag = true
          break;

        case `Fees-${customer_id}-${show_id}`:
          let fees = methods.getValues('show_fees')
          if(fees?.find(fee => fee.showfees_id == updateId)){
            variableName = 'show_fees'
            updateFlag = true
          }
          break;
      }

      if(updateFlag){
        axios.get( process.env.REACT_APP_NEST_API_URL + '/shows/detail', {
          params: {
            show_id,
            customer_id: customer_id,
          }
        }).then((res) => {
          if(parsedEvent.type.includes('Shows')){
            setHideLoadingOverlay(true)
            if(!res.data.show.facility_id && form_meta.facilities.length == 1){
              res.data.show.facility_id = form_meta.facilities[0].value
            }

            reset(res.data, { keepDirtyValues: true })
            setShowRanges()
          }else{
            methods.setValue(variableName, res.data[variableName])
          }
        })
      }            
    }
  }, [syncEvent]);

  useEffect(() => { // Remove all existing event listener and then generate and add new events with callback in SSE on change of output grid area
    removeAllEventListeners()
    return // SK - Disabling sync
    if(show_id && sse && show_id > 0){
      let syncEvents = []
      let eventIds = []
      // For Areas who contains show_id should add show id in its event id
      eventIds = [
        `Shows-${customer_id}-${show_id}`,
        `Fees-${customer_id}-${show_id}`
      ]

      for(let eventId of eventIds){
        // Sync Event will hold an array of eventId and callback
        syncEvents.push({
          eventId, 
          callback: (event) => setSyncEvent(event)
        })
      }
      addSubscribedEvents(syncEvents) 
    }

    return () => removeAllEventListeners()

  }, [customer_id, show_id, sse]);

  useAutoFocus('show_address', loading, update_data, '', null, 1000)

  return (
    <>
		{!loading && (
       <>
      {props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.watch('show.show_name'), methods.getValues('show.show_id')]}>{intl.formatMessage({id: 'MENU.SHOWS'})}</PageTitle> : "" }
      <div id={"shows_detail_page"} className='modal-body py-3 px-4 show-input-form input-form'>
   
        <FormProvider {...methods}>
          <form id={"ShowDetail_"+current_show_id} noValidate className='form max-width' onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
            <HelpLink tooltip="SHOW.DETAIL.LINK.HELP" classname="top" />
            {/* VIEW LICENSE POPUP*/}
            {viewLicense && 
              <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal modal-dialog modal-dialog-centered mw-650px search-form'
                show={viewLicense}
                onHide={() => {setViewLicense(false)}}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    setViewLicense(false)
                  }
              }}
              >
                {/* header */}
                <div className="modal-header py-0 px-4">
                  <h2 className="fs-4">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.VIEWLICENSE.TITLE.VIEWLICENSE'})}</h2>
                  <div className="btn btn-sm btn-icon btn-active-color-dark" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setViewLicense(false)}>
                    <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x"/>
                  </div>
                </div>

                <div className="modal-body py-3 px-4">
                  {/* body */}
                  <div className='card-body p-0'>

                    {/* encrypted license */}
                    <div style={{width: "100%" , background:"white"}}>
                      <p style={{  overflowWrap: 'break-word',wordWrap: 'break-word',hyphens: 'auto'}}>
                        {encryptedLicense['raw_license']}
                      </p>
                    </div>
                  
                    <br/>

                    {/* decrypted license */}
                    <div className='ag-theme-alpine'
                    style={{height: "300px"}}
                    >
                      <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        headerHeight={getNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                      />
                    </div>
                  </div> 
                   

                  {/* footer */}
                  <div className="card-footer d-flex justify-content-end py-3 px-0">
                    <button
                        type='button'
                        className="btn btn-sm btn-secondary me-4 fw-bold"
                        data-bs-dismiss="modal"
                        onClick={()=>setViewLicense(false)}
                        autoFocus
                        data-tooltip-id="SHOW.DETAIL.MODAL.SHOWGROUNDSSHOWLICENSE.BUTTON.DONE"
                      >
                        {intl.formatMessage({id: 'FORM.INPUT.SHOWS.VIEWLICENSE.COMMON.BUTTON.DONE'})}
                    </button>
                  </div>
                </div>
              </Modal>
            }
            {/* END VIEW LICENSE POPUP*/}

          { showSearchForm &&
            <Modal
              id='kt_modal_create_app'
              tabIndex={-1}
              aria-hidden='true'
              dialogClassName='modal modal-dialog modal-dialog-centered mw-450px search-form'
              show={showSearchForm}
              onHide={() =>{ setShowSearchForm(false)}}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  if(
                      methods.watch('classes_setup') &&
                      methods.watch('divisions_setup') &&
                      methods.watch('show_fee_setup') &&
                      methods.watch('taxes_setup') &&
                      methods.watch('membership_setup') &&
                      methods.watch('show.web_entry_closing_date') !== '0000-00-00')
                      {
                        methods.setValue('show.publish_on_web', 1, {shouldDirty: true})
                        setShowSearchForm(false)
                      }
                }
              }}
              // onHide={showSearchForm}
            >
              <div className='modal-header py-0 px-4'>
                <h2 className='fs-4'>
                  {intl.formatMessage({id: 'FORM.INPUT.SHOWS.PUBLISHONWEB.TITLE.PUBLISHEDONWEB'})}
                </h2>
                {/* begin::Close */}
                <div
                  className='btn btn-sm btn-icon btn-active-color-dark'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={() => {
                    methods.resetField('classes_setup')
                    methods.resetField('divisions_setup')
                    methods.resetField('show_fee_setup')
                    methods.resetField('taxes_setup')
                    methods.resetField('membership_setup')
                    methods.resetField('show.web_entry_closing_date')
                    methods.resetField('show.skip_announcement_email')
                    setShowSearchForm(false)
                  }}
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
                </div>
                {/* end::Close */}
              </div>

              <div className='modal-body py-3 px-4'>
                <div className='card-body p-0'>
                  <div className='row mb-2'>
                    <div className='d-flex align-items-center'>
                      <div className='form-check-sm form-check-custom  me-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='classes_setup'
                          {...methods.register('classes_setup', {required: false})}
                          autoFocus
                        />
                      </div>
                      <label className='col-lg col-form-label fs-5 py-1' for='classes_setup' data-tooltip-id="SHOW.DETAIL.MODAL.PUBLISHSHOWONWEBCHECKLIST.LABEL.CLASSESSETUP">
                        {' '}
                        {intl.formatMessage({id: 'FORM.INPUT.SHOWS.PUBLISHONWEB.LABEL.CLASSESSETUP'})}
                      </label>
                    </div>
                  </div>

                  <div className='row mb-2'>
                    <div className='d-flex align-items-center'>
                      <div className='form-check-sm form-check-custom  me-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='divisions_setup'
                          {...methods.register('divisions_setup', {required: false})}
                        />
                      </div>
                      <label className='col-lg col-form-label fs-5 py-1' for='divisions_setup' data-tooltip-id="SHOW.DETAIL.MODAL.PUBLISHSHOWONWEBCHECKLIST.LABEL.DIVISIONSSETUPFORCLASSES">
                        {' '}
                        {intl.formatMessage({
                          id: 'FORM.INPUT.SHOWS.PUBLISHONWEB.LABEL.DIVISIONSSETUPFORCLASSES',
                        })}
                      </label>
                    </div>
                  </div>

                  <div className='row mb-2'>
                    <div className='d-flex align-items-center'>
                      <div className='form-check-sm form-check-custom  me-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='show_fee_setup'
                          {...methods.register('show_fee_setup', {required: false})}
                        />
                      </div>
                      <label className='col-lg col-form-label fs-5 py-1' for='show_fee_setup' data-tooltip-id="SHOW.DETAIL.MODAL.PUBLISHSHOWONWEBCHECKLIST.LABEL.SHOWFEESETUP">
                        {' '}
                        {intl.formatMessage({id: 'FORM.INPUT.SHOWS.PUBLISHONWEB.LABEL.SHOWFEESETUP'})}
                      </label>
                    </div>
                  </div>

                  <div className='row mb-2'>
                    <div className='d-flex align-items-center'>
                      <div className='form-check-sm form-check-custom  me-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='taxes_setup'
                          {...methods.register('taxes_setup', {required: false})}
                        />
                      </div>
                      <label className='col-lg col-form-label fs-5 py-1' for='taxes_setup' data-tooltip-id="SHOW.DETAIL.MODAL.PUBLISHSHOWONWEBCHECKLIST.LABEL.TAXESSETUP">
                        {' '}
                        {intl.formatMessage({id: 'FORM.INPUT.SHOWS.PUBLISHONWEB.LABEL.TAXESSETUP'})}
                      </label>
                    </div>
                  </div>

                  <div className='row mb-2'>
                    <div className='d-flex align-items-center'>
                      <div className='form-check-sm form-check-custom  me-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='membership_setup'
                          {...methods.register('membership_setup', {required: false})}
                        />
                      </div>
                      <label className='col-lg col-form-label fs-5 py-1' for='membership_setup' data-tooltip-id="SHOW.DETAIL.MODAL.PUBLISHSHOWONWEBCHECKLIST.LABEL.REQUIREDMEMBERSHIPSSETUP">
                        {' '}
                        {intl.formatMessage({
                          id: 'FORM.INPUT.SHOWS.PUBLISHONWEB.LABEL.REQUIREDMEMBERSHIPSSETUP',
                        })}
                      </label>
                    </div>
                  </div>

                  <div className='row mb-2'>
                    <label className='col-lg col-form-label fs-5 py-1' htmlFor='web_entry_closing_date1' data-tooltip-id="SHOW.DETAIL.MODAL.PUBLISHSHOWONWEBCHECKLIST.LABEL.WEBENTRYCLOSINGDATE">
                      {intl.formatMessage({id: 'FORM.INPUT.SHOWS.PUBLISHONWEB.LABEL.WEBENTRYCLOSINGDATE'})}
                    </label>
                    <div className='col-lg-7'>
                      <div className='row'>
                        <div className='col-lg-7 fv-row'>
                          <div className='input-group date'>
                            <Controller
                                {...methods.register('show.web_entry_closing_date', { required: false })}
                                name='show.web_entry_closing_date'
                                render={({ field: { onChange, value, name } }) => {
                                    const formattedValue = (value && moment(value).isValid()) 
                                        ? moment(value).format('MM/DD/YYYY') 
                                        : '';

                                    return (
                                        <Flatpickr
                                            allowInput={true}
                                            autoComplete="off"
                                            className='form-control form-control-sm mb-2 mb-lg-0 fs-6 min-h-20px py-1'
                                            id='web_entry_closing_date1'
                                            placeholder='MM/DD/YYYY'
                                            name={name}
                                            value={formattedValue}
                                            options={date_options}
                                            onClose={(value, dateStr, instance) => {
                                                const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                                if (!isValidDate) {
                                                    instance.input.value = ''; // Clear the input if the date is invalid
                                                } 
                                                setDate(dateStr, onChange); // Set the typed/selected date and format it to YYYY-MM-DD for backend
                                            }}
                                            onChange={(value, dateStr, instance) => {
                                              const isValidDate = moment(dateStr, 'MM/DD/YYYY', true).isValid();

                                              if (!isValidDate) {
                                                  instance.input.value = ''; // Clear the input if the date is invalid
                                              } 
                                              setDate(dateStr, onChange); // Set the typed/selected date and format it to YYYY-MM-DD for backend
                                          }}
                                            onInput={(e) => { maskDateInput(e) }}
                                        />
                                    );
                                }}
                            />
                            <div className='input-group-append'>
                              <label htmlFor='web_entry_closing_date1' className='date-label'
                                onClick={(e) => {
                                  e.stopPropagation(); 
                                  document.getElementById('web_entry_closing_date1')._flatpickr.open(); 
                                }}
                              >
                                <span className='input-group-text'>
                                  <i className='la la-calendar' for='web_entry_closing_date'></i>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mb-2'>
                    <div className='d-flex align-items-center'>
                      <div className='form-check-sm form-check-custom  me-2'>
                        <input
                          {...methods.register('show.skip_announcement_email', {required: false})}
                          className='form-check-input'
                          type='checkbox'
                          id='skip_announcement_email'
                        />
                      </div>
                      <label className='col-lg col-form-label fs-5 py-1' htmlFor='skip_announcement_email' data-tooltip-id="SHOW.DETAIL.MODAL.PUBLISHSHOWONWEBCHECKLIST.LABEL.DONOTSENDSHOWANNOUNCEMENTEMAIL">
                        {intl.formatMessage({id: 'FORM.INPUT.SHOWS.PUBLISHONWEB.LABEL.SHOWANNOUNCEMENTEMAIL'})}
                      </label>
                    </div>
                  </div>
                </div>

                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                  <button
                    type='button'
                    className='btn btn-sm btn-secondary me-4 fw-bold'
                    data-bs-dismiss='modal'
                    onClick={() => {
                      methods.resetField('classes_setup')
                      methods.resetField('divisions_setup')
                      methods.resetField('show_fee_setup')
                      methods.resetField('taxes_setup')
                      methods.resetField('membership_setup')
                      methods.resetField('show.web_entry_closing_date')
                      methods.resetField('show.skip_announcement_email')
                      setShowSearchForm(false)
                    }}
                  >
                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.PUBLISHONWEB.COMMON.BUTTON.CLOSE'})}
                  </button>
                  <button
                    type='button'
                    className='btn btn-sm btn-dark fw-bold'
                    autoFocus
                    disabled={
                      methods.watch('classes_setup') &&
                      methods.watch('divisions_setup') &&
                      methods.watch('show_fee_setup') &&
                      methods.watch('taxes_setup') &&
                      methods.watch('membership_setup') &&
                      methods.watch('show.web_entry_closing_date') !== '0000-00-00'
                        ? false
                        : true
                    }
                    onClick={() => {
                      methods.setValue('show.publish_on_web', 1, {shouldDirty: true})
                      setShowSearchForm(false)
                    }}
                    data-tooltip-id="SHOW.DETAIL.MODAL.PUBLISHSHOWONWEBCHECKLIST.BUTTON.PUBLISH"
                  >
                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.PUBLISHONWEB.COMMON.BUTTON.PUBLISH'})}
                  </button>
                </div>
              </div>
            </Modal>
          }  

            <div className='card-body p-0'> {/* Card Body */}
              
            <div className="form-group row"> {/* Column Group */}

            
            <div className='row mb-2'>
              <div className='col-lg-8'>{/* Column 1 */}

                <div className='row mb-2'>
                  <label className='col-lg-2 col-form-label  fw-bold fs-5 py-1' htmlFor="show_name" data-tooltip-id="SHOW.DETAIL.LABEL.SHOWNAME">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.SHOWNAME'})}</label>

                  <div className='col-lg-10 '>
                  <input
                    {...register( 'show.show_name', 
                      {
                        required: false
                      })
                    }
                    readOnly
                    id='show_name'
                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                  />
                  </div>
                </div>

                <div className='row mb-2'>
                  <label className='col-lg-2 col-form-label fw-bold fs-5 py-1' htmlFor='show_address' data-tooltip-id="SHOW.DETAIL.LABEL.ADDRESS">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.ADDRESS'})}</label>

                  <div className='col-lg-7 '>
                    <input
                      {...register( 'show.show_address', 
                        {
                          required: false
                        })
                      }
                      id='show_address'
                      className='form-control form-control-sm  fs-6 min-h-20px py-1'
                    />
                  </div>

                  <label className='col-lg-1 col-form-label fw-bold fs-5 py-1' htmlFor='name_abbr' data-tooltip-id="SHOW.DETAIL.LABEL.ABBR">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.ABBR'})}</label>

                  <div className='col-lg-2'>
                    <input
                      {...register( 'show.name_abbr', 
                        {
                          required: false
                        })
                      }
                      id='name_abbr'
                      className='form-control form-control-sm  fs-6 min-h-20px py-1'
                    />
                  </div>
                </div>

                <div className='row mb-2'>
                  <label className='col-lg-2 col-form-label  fw-bold fs-5 py-1' htmlFor="city" data-tooltip-id="SHOW.DETAIL.LABEL.CITY/STATE/ZIP">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.CITY|STATE|ZIP'})}</label>

                  <div className='col-lg-5 '>
                    <input
                      {...register( 'show.city', 
                        {
                          required: false
                        })
                      }
                      id='city'
                      className='form-control form-control-sm  fs-6 min-h-20px py-1'
                    />
                  </div>

                  <div className='col-lg-2'>
                    <input
                      {...register( 'show.state', 
                        {
                          required: false
                        })
                      }
                      id='state'
                      className='form-control form-control-sm  fs-6 min-h-20px py-1'
                    />
                  </div>

                  <div className='col-lg-3'>
                    <input
                      {...register( 'show.postal_code', 
                        {
                          required: false
                        })
                      }
                      id='postal_code'
                      className='form-control form-control-sm  fs-6 min-h-20px py-1'
                    />
                  </div>
                </div>

                <div className='row mb-2'>
                  <label className='col-lg-2 col-form-label fw-bold fs-5 py-1' htmlFor='country_code' data-tooltip-id="SHOW.DETAIL.LABEL.COUNTRY">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.COUNTRY'})}</label>

                  <div className='col-lg-4 '>
                      {
                        form_meta.countries && 
                        <Controller
                            name='show.country_code'
                            render={({
                                field: { onChange, value, name },
                            }) => (
                            <Select
                                placeholder="Select"
                                options = {form_meta.countries}
                                value = {form_meta.countries.find((c) => c.value === value)}
                                name={name}
                                id="country_code"
                                isSearchable={true}
                                onChange={(country) => {
                                    onChange(country.value);
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 0,
                                })}
                                styles={reactSelectStyles}
                            />    
                            )}
                        />
                    }
                  </div>

                  <label className='col-lg-1 col-form-label fw-bold fs-5 py-1'>{watch('show.country_code')}</label>
                

                  <label className='col-lg-1 col-form-label fw-bold fs-5 py-1' data-tooltip-id="SHOW.DETAIL.LABEL.DATES">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.DATES'})}</label>
                  <label className='col-lg-2 col-form-label fw-bold fs-5 py-1 w-100px'>{getValues('show.start_date') ? moment(getValues('show.start_date')).format("MM/DD/YY") : ''}</label>
                  <label className='col-lg-1 col-form-label fw-bold fs-5 py-1' data-tooltip-id="SHOW.DETAIL.LABEL.THRU">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.THRU'})}</label>
                  <label className='col-lg-1 col-form-label fw-bold fs-5 py-1'>{getValues('show.end_date') ? moment(getValues('show.end_date')).format("MM/DD/YY") : ''}</label>
              
                </div>
                <div className='row mb-2 justify-content-end'>

                  <div className='col-lg-10'>
                  {getValues('show.publish_on_web')? <label className='col-lg col-form-label  fw-bold fs-5 py-1 me-5 text-danger' data-tooltip-id="SHOW.DETAIL.LABEL.PUBLISHONWEB">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.PUBLISHED'})}</label>
                  : <button type='button' className="btn btn-sm btn-secondary fw-bold h-30px py-1 w-200px me-5" data-toggle="modal" onClick={(e)=>{ setShowSearchForm(true);e.preventDefault()}} data-tooltip-id="SHOW.DETAIL.BUTTON.PUBLISHONWEB"
                  >{intl.formatMessage({id: 'FORM.INPUT.SHOWS.BUTTON.PUBLISHONWEB'})}</button>}
                  </div>
                </div>

              </div>


              <div className='col-lg-4'>{/* Column 2 */}
              <fieldset>
                <legend className="fs-5 fw-bold d-flex mb-0">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.GROUPLABEL.LICENSEINFORMATION'})} <b></b></legend>
                
                <div className='row mb-0'>
                  <label className='col-lg-8 col-form-label fw-bold fs-5 py-0' data-tooltip-id="SHOW.DETAIL.SECTION.LICENSEINFORMATION.LABEL.ENTRIESALLOWED">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.ENTRIESALLOWED'})}</label>
                  <label className='col-lg-4 col-form-label fw-bold fs-5 py-0 text-end'>{getValues('show.entry_limit')}</label>
                </div>
              
                <div className='row mb-0'>
                  <label className='col-lg-2 col-form-label fw-bold fs-5 py-0' data-tooltip-id="SHOW.DETAIL.SECTION.LICENSEINFORMATION.LABEL.MD5">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.MD5'})}</label>
                  <label className='col-lg-10 col-form-label fw-bold fs-6 py-0 text-danger text-end'>{getValues('show.mdfive_license')}</label>
                </div>

                <div className='row mb-0'>
                  <label className='col-lg-2 col-form-label fw-bold fs-5 py-0' data-tooltip-id="SHOW.DETAIL.SECTION.LICENSEINFORMATION.LABEL.UUID">{intl.formatMessage({id: 'FORM.INPUT.SHOWS.LABEL.UUID'})}</label>
                  <label className='col-lg-10 col-form-label fw-bold fs-6 py-0   blue text-end'>{getValues('show.uuid')}</label>
                </div>

                <div className='d-flex justify-content-start pt-4 mb-2'>
                  {/* VIEW LICENSE */}
                  {/* <button className="btn btn-sm btn-secondary fw-bold h-30px py-1 w-150px me-5" data-bs-toggle="modal" data-bs-target="kt_modal_1" onClick={()=>setViewLicense(true)}> */}
                  <button type='button' className="btn btn-sm btn-secondary fw-bold h-30px py-0 me-3" data-toggle="modal" onClick={(e)=>{ setViewLicense(true);e.preventDefault()}} data-tooltip-id="SHOW.DETAIL.SECTION.LICENSEINFORMATION.BUTTON.VIEWLICENSE"
                  >

                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.BUTTON.VIEWLICENSE'})}
                  </button>
                  {/* END VIEW LICENSE */}

                  <button type='button' className='btn btn-sm btn-secondary fw-bold h-30px py-0 me-3' onClick={(e)=> { updateLicense();e.preventDefault()}} data-tooltip-id="SHOW.DETAIL.SECTION.LICENSEINFORMATION.BUTTON.UPDATELICENSE"
                  >
                    {intl.formatMessage({id: 'FORM.INPUT.SHOWS.BUTTON.UPDATELICENSE'})}
                  </button>
                  <button className='btn btn-sm btn-secondary fw-bold h-30px py-0' type='button' onClick={() => {methods.setValue('show.islocked', !methods.getValues('show.islocked'), {shouldDirty: true});methods.setValue('show.locked_detail', '', { shouldDirty: true })}} data-tooltip-id="SHOW.DETAIL.SECTION.LICENSEINFORMATION.BUTTON.LOCKSHOW">
                    {!methods.getValues('show.islocked')? intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.LOCKED'}): intl.formatMessage({id: 'FORM.INPUT.SHOWS.TAB.GENERAL.LABEL.UNLOCKED'})}
                  </button>
                  
                </div>
                {(updateLicenseErrorMessage != '') &&
                  <div><span className='error_message'>{updateLicenseErrorMessage}</span></div>
                }
                {(updateLicenseCount > 0) &&
                  <div><span className='success_message'>Your License is updated successfully.</span></div>
                }  
                <label className='col-lg col-form-label  fw-bold fs-5 py-1 me-5 text-danger'>{methods.getValues('show.locked_detail')}</label>
                

              </fieldset>
              </div>
            </div>

            </div> {/* End Column Group */}

            <div className='d-flex flex-column my-2 border p-2 bg-white'>
              <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                  {
                      tabs.map((tab, index) => {
                          return (
                              <li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
                                  <a className={"nav-link text-active-dark px-6 py-1 text-gray-700" + (index == selectedTab ? ' active' : '') + (index == 9 ? ' min-w-140px text-center' : '') }
                                      tabIndex="-1"
                                      id={tab + "-tab"}
                                      data-bs-toggle='tab'
                                      href={"#" + tab + "-Shows-" +current_show_id}
                                      data-tooltip-id={`SHOW.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.SHOWS.TAB.' + tab })}
                                  </a>
                              </li>
                          );
                      })
                  }
              </ul>
              
              <div className="tab-content mt-5 pb-2" id="myTabContent">
                  {
                      tabs.map((tab, index) => {
                          return (
                                <div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab + "-Shows-" +current_show_id} role="tabpanel" >
                                    <HelpLink tooltip={"SHOW.DETAIL.TAB."+tab+".LINK.HELP"}  />
                                    {componentTags[index]}
                                </div>
                          );
                      })
                  }
              </div>
          </div>
          <input type='hidden' {...register("show.show_series_id")} />
          <input type='hidden' {...register("rings")} />

            </div>{/* End Card Body */}

            <InputFormFooter goBackPath={'/shows'} propId={props?.id} data={'show'}/>
            
          </form>
        </FormProvider>
      </div>
      {rowClicked && <DetailPageModal area={sub_area} detail_page={child_component} record_id={record_id} updateCallbackFromParent={updateData} open_forms={open_forms} parent_id={current_show_id} parent_area={"Shows"} gridRowData={gridRowData}/>}
      </>
      )}
    </>
  )
}

export {ShowDetail}
