import {useAppSelector} from '../../../../redux/hooks'
import {useLoadingOverlay} from '../../../../modules/sgl-utils/DialogsProvider'
import {useParams} from 'react-router-dom'
import {useEffect, useState, useRef, useCallback} from 'react'
import './barn-reservation.css'
import {PageTitle} from '../../../../../_metronic/layout/core'
import axios from 'axios'
import {Stage, Layer, Rect, Text, Group, Line, Circle} from 'react-konva'
import {deserializeElement} from '../Components/CanvasUtility'
import CanvasZoomControls from '../Components/CanvasZoomControls'
import {
  calculateFitView,
  calculateWheelZoom,
  isStallTack,
  isStallTackColor,
  isStallAvailableColor,
  isStallDNRColor,
  isStallActiveColor,
  stallTypeColorLookup,
} from '../Components/CanvasUtility'
import Split from 'react-split'
import processStallProximityData from './ProximityAlgo'
import {RectangleHorizontal, RotateCcw, Search} from 'lucide-react'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const BarnReservationMode = () => {
  const customerId = useAppSelector((state) => state.showCompany.company_id)
  const loadingOverlay = useLoadingOverlay()
  const {barn_id, barn_design_id} = useParams()
  const [barnDetails, setBarnDetails] = useState(null)
  const [isDesignLoading, setIsDesignLoading] = useState(true)
  const [barnMetaData, setBarnMetaData] = useState(null)
  const [reservationStatus, setReservationStatus] = useState(
    'No stalls found yet. Click "Find Stalls" to search.'
  )
  const [initialCanvasLoadingState, setInitialCanvasLoadingState] = useState({})

  // UI state

  // Canvas Stage Related State
  const [isDragging, setIsDragging] = useState(false)
  const [stageScale, setStageScale] = useState(1)
  const [stagePosition, setStagePosition] = useState({x: 0, y: 0})
  const [shouldFitToScreen, setShouldFitToScreen] = useState(true)
  const [canvasElements, setCanvasElementsState] = useState([])
  const [allowElementDrag, setAllowElementDrag] = useState(false)
  // Add new state to track available groups and current index
  const [allStallGroups, setAllStallGroups] = useState([])
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0)
  const [mousePosition, setMousePosition] = useState({x: 0, y: 0})

  // Barn design ui
  const [barnLayout, setBarnLayout] = useState({})
  const [selectedElements, setSelectedElements] = useState([])
  const [selectedElement, setSelectedElement] = useState(null)
  const [currentDesignId, setCurrentDesignId] = useState(null)
  const [designNameLabel, setDesignNameLabel] = useState('')
  const [designDetails, setDesignDetails] = useState(null)

  // Proximity Algo related states
  const [clickOrigin, setClickOrigin] = useState({x: 50, y: 50})
  const [showClickOrigin, setShowClickOrigin] = useState(true)
  const [bestStallsFound, setBestStallsFound] = useState([])

  // Form related states
  const [formData, setFormData] = useState({
    stallNumber: 1,
    stallType: 'any', // default value
    stallSize: 'any', // default value
    bookingStartDate: '',
    bookingEndDate: '',
    includeTackStalls: false,
  })

  // Refs
  const stageRef = useRef(null)
  const transformerRef = useRef(null)
  const selectedShapeRef = useRef(null)

  // useCallback
  // Create a wrapper function that handles both state update and history
  const setCanvasElements = useCallback(
    (newElements) => {
      // If newElements is a function, call it with current state to get the new value
      const updatedElements =
        typeof newElements === 'function' ? newElements(canvasElements) : newElements

      // Update the state
      setCanvasElementsState(updatedElements)
    },
    [canvasElements]
  )

  // useEffect

  useEffect(() => {
    if (barnLayout.structure && shouldFitToScreen) {
      fitView()
    }
  }, [barnLayout.structure, shouldFitToScreen])

  // as soon as component mounts load the barn details
  useEffect(() => {
    setIsDesignLoading(true)

    // Load the barn meta data
    const loadBarnMetaData = async () => {
      try {
        const response = await axios.get(`${NEST_API_URL}/barns/MetaData`, {
          params: {
            barnId: barn_id,
            customerId: customerId,
          },
        })
        // Add "All" option to stallTypes and stallSizes
        const metaDataWithAllOptions = {
          ...response.data,
          stallTypes: [{value: 'any', label: 'Any'}, ...response.data.stallTypes],
          stallSizes: [{value: 'any', label: 'Any'}, ...response.data.stallSizes],
        }

        setBarnMetaData(metaDataWithAllOptions)
      } catch (err) {
        console.error('Error loading barn metadata:', err)
      }
    }

    const initialize = async () => {
      try {
        handleLoadBarnDesign(barn_design_id, customerId, barn_id)
        await loadBarnMetaData()
      } finally {
        setIsDesignLoading(true)
      }
    }
    initialize()
  }, [])

  // Add this handler function for input changes
  const handleInputChange = (e) => {
    const {name, value, type, checked} = e.target
    // Use checked for checkboxes, value for other inputs
    let newValue = type === 'checkbox' ? checked : value

    // Special handling for stallNumber to ensure it's always at least 1 and an integer
    if (name === 'stallNumber') {
      // Allow empty string during typing, but ensure final value is valid
      if (value === '' || value === null) {
        // Allow empty value temporarily during typing
        newValue = ''
      } else {
        // Convert to integer (not just number) and validate
        const numValue = parseInt(value, 10)

        // If tack stalls are included, ensure at least 2 stalls
        const minStalls = formData.includeTackStalls ? 2 : 1

        // If it's not a valid number or less than minimum required, set to minimum
        if (isNaN(numValue) || numValue < minStalls) {
          newValue = minStalls
        } else {
          // Ensure it's an integer by using parseInt
          newValue = numValue
        }
      }
    }

    setFormData((prev) => ({
      ...prev,
      [name]: newValue,
    }))
  }

  // Add this handler for the Find Stalls button
  const handleFindStalls = (event) => {
    event.preventDefault()

    // Ensure stallNumber is valid before proceeding
    const minStalls = formData.includeTackStalls ? 2 : 1

    if (
      formData.stallNumber === '' ||
      isNaN(parseInt(formData.stallNumber, 10)) ||
      parseInt(formData.stallNumber, 10) < minStalls
    ) {
      setFormData((prev) => ({
        ...prev,
        stallNumber: minStalls,
      }))
    }

    // clear the best stalls
    setBestStallsFound([])

    const request = {
      numberOfStalls: parseInt(formData.stallNumber, 10),
      stallType: formData.stallType,
      stallSize: formData.stallSize,
      includeTackStalls: formData.includeTackStalls, // Make sure this property name matches
      dateRange: {
        // Convert string dates to proper Date objects
        startDate: formData.bookingStartDate ? new Date(formData.bookingStartDate) : null,
        endDate: formData.bookingEndDate ? new Date(formData.bookingEndDate) : null,
      },
    }

    // const bestStallsIds = processStallProximityData(
    //   canvasElements,
    //   clickOrigin,
    //   request,
    //   setCanvasElements
    // )

    // console.log('bestStallsIds', bestStallsIds)

    // // Store the best stalls for display
    // const stallsList = bestStallsIds.map((id) => {
    //   const stall = canvasElements.find((el) => {
    //     if (el.isStall && el.id === id) {
    //       return el
    //     } else if (el.isGroup && el.children.some((child) => child.id === id)) {
    //       return el
    //     }
    //   })
    //   return {
    //     id: id,
    //     name: stall?.name || `Stall ${id}`,
    //     type:
    //       barnMetaData.stallTypes.find((type) => type.value === stall?.stallType)?.label ||
    //       'Standard',
    //     size:
    //       barnMetaData.stallSizes.find((size) => size.value === stall?.stallSize)?.label ||
    //       'Standard',
    //     isTackStall: stall?.isTackStall || false,
    //   }
    // })

    // if (stallsList.length === 0) {
    //   setReservationStatus('No stalls found.')
    // }
    // setBestStallsFound(stallsList)

    // fillBestStallsForReservation(isStallActiveColor, bestStallsIds)

    // const groupsResult = processStallProximityData(
    //   canvasElements,
    //   clickOrigin,
    //   request,
    //   setCanvasElements
    // );

    //  // Store all groups returned by the algorithm
    //  setStallGroups(groupsResult);

    //  // Reset to the first group
    //  setCurrentGroupIndex(0);

    //  // If no groups were found
    //  if (groupsResult.length === 0) {
    //    setReservationStatus('No suitable stall groups found.');
    //    setBestStallsFound([]);
    //    return;
    //  }

    // Call algorithm but store all results now
    const allGroups = processStallProximityData(
      canvasElements,
      clickOrigin,
      request,
      setCanvasElements
    )

    // Store all groups from the algorithm
    setAllStallGroups(allGroups)

    // Reset to first group
    setCurrentGroupIndex(0)

    // If we have groups, display the first one
    if (allGroups.length > 0) {
      const bestStallsIds = allGroups[0].stalls || []

      // Use your existing code format for processing the stalls
      const stallsList = bestStallsIds.map((id) => {
        const stall = canvasElements.find((el) => {
          if (el.isStall && el.id === id) {
            return el
          } else if (el.isGroup && el.children.some((child) => child.id === id)) {
            return el
          }
        })
        return {
          id: id,
          name: stall?.name || `Stall ${id}`,
          type:
            barnMetaData.stallTypes.find((type) => type.value === stall?.stallType)?.label ||
            'Standard',
          size:
            barnMetaData.stallSizes.find((size) => size.value === stall?.stallSize)?.label ||
            'Standard',
          isTackStall: stall?.isTackStall || false,
        }
      })

      if (stallsList.length === 0) {
        setReservationStatus('No stalls found.')
      } else {
        setReservationStatus(
          `Group 1 of ${allGroups.length} - Score: ${Math.round(allGroups[0].score || 0)}`
        )
      }

      setBestStallsFound(stallsList)
      fillBestStallsForReservation(isStallActiveColor, bestStallsIds)
    } else {
      setReservationStatus('No suitable stall groups found.')
      setBestStallsFound([])
    }
  }

  // Add these navigation functions
  const showPreviousGroup = () => {
    if (currentGroupIndex > 0) {
      const oldStallIds = allStallGroups[currentGroupIndex].stalls || []

      const newIndex = currentGroupIndex - 1
      setCurrentGroupIndex(newIndex)

      const bestStallsIds = allStallGroups[newIndex].stalls || []

      // different between old and new
      const differentStalls = oldStallIds.filter((id) => !bestStallsIds.includes(id))

      // make the different stalls reservable

      // Same format as your existing code for processing the stalls
      const stallsList = bestStallsIds.map((id) => {
        const stall = canvasElements.find((el) => {
          if (el.isStall && el.id === id) {
            return el
          } else if (el.isGroup && el.children.some((child) => child.id === id)) {
            return el
          }
        })
        return {
          id: id,
          name: stall?.name || `Stall ${id}`,
          type:
            barnMetaData.stallTypes.find((type) => type.value === stall?.stallType)?.label ||
            'Standard',
          size:
            barnMetaData.stallSizes.find((size) => size.value === stall?.stallSize)?.label ||
            'Standard',
          isTackStall: stall?.isTackStall || false,
        }
      })

      setCanvasElements((prev) =>
        prev.map((el) => {
          if (differentStalls.includes(el.id)) {
            if (el.isStall) {
              return {
                ...el,
                isReservable: true, // Mark as reservable when no longer selected
                children: el.children.map((child) => {
                  // Skip color change for badge elements
                  if (child.isBadge || child.isBadgeText) {
                    return child
                  }
                  if (child.type === 'line' && child.closed) {
                    return {...child, fill: isStallAvailableColor} // CHANGED from isStallActiveColor to isStallAvailableColor
                  }
                  return child
                }),
              }
            }
            return el
          }

          // If the element is a single element
          if (bestStallsIds.includes(el.id)) {
            // Special case: If this is a tack stall, we might want to use a different color
            // or maintain its existing tack stall color
            const isTackStall = isStallTack(el)
            const stallFillColor = isTackStall ? isStallTackColor : isStallActiveColor

            // Handle non-grouped elements
            // todo : logic to handle the reservation of tack stalls
            if (el.isStall) {
              return {
                ...el,
                isReservable: false, // Mark as not reservable once it's selected
                children: el.children.map((child) => {
                  // Skip color change for badge elements
                  if (child.isBadge || child.isBadgeText) {
                    return child
                  }
                  if (child.type === 'line' && child.closed) {
                    return {...child, fill: stallFillColor}
                  }
                  return child
                }),
              }
            }
            return el
          } else if (el.isGroup) {
            return {
              ...el,
              // Assign the result of map back to children
              children: el.children.map((groupChild) => {
                if (groupChild.isStall && bestStallsIds.includes(groupChild.id)) {
                  // Special case: If this is a tack stall, we might want to use a different color
                  // or maintain its existing tack stall color
                  const isTackStall = isStallTack(groupChild) // Note: changed el to groupChild
                  const stallFillColor = isTackStall ? isStallTackColor : isStallActiveColor
                  return {
                    ...groupChild,
                    isReservable: false, // Mark as not reservable once it's selected
                    children: groupChild.children.map((child) => {
                      // Skip color change for badge elements
                      if (child.isBadge || child.isBadgeText) {
                        return child
                      }
                      if (child.type === 'line' && child.closed) {
                        return {...child, fill: stallFillColor}
                      }
                      return child
                    }),
                  }
                }
                return groupChild
              }),
            }
          }

          return el
        })
      )

      setBestStallsFound(stallsList)
      setReservationStatus(
        `Group ${newIndex + 1} of ${allStallGroups.length} - Score: ${Math.round(
          allStallGroups[newIndex].score || 0
        )}`
      )
    }
  }

  const showNextGroup = () => {
    if (currentGroupIndex < allStallGroups.length - 1) {
      const oldStallIds = allStallGroups[currentGroupIndex].stalls || []

      const newIndex = currentGroupIndex + 1
      setCurrentGroupIndex(newIndex)

      const bestStallsIds = allStallGroups[newIndex].stalls || []

      // different between old and new
      const differentStalls = oldStallIds.filter((id) => !bestStallsIds.includes(id))

      // Same format as your existing code for processing the stalls
      const stallsList = bestStallsIds.map((id) => {
        const stall = canvasElements.find((el) => {
          if (el.isStall && el.id === id) {
            return el
          } else if (el.isGroup && el.children.some((child) => child.id === id)) {
            return el
          }
        })
        return {
          id: id,
          name: stall?.name || `Stall ${id}`,
          type:
            barnMetaData.stallTypes.find((type) => type.value === stall?.stallType)?.label ||
            'Standard',
          size:
            barnMetaData.stallSizes.find((size) => size.value === stall?.stallSize)?.label ||
            'Standard',
          isTackStall: stall?.isTackStall || false,
        }
      })

      setBestStallsFound(stallsList)

      setCanvasElements((prev) =>
        prev.map((el) => {
          if (differentStalls.includes(el.id)) {
            if (el.isStall) {
              return {
                ...el,
                isReservable: true, // Mark as reservable when no longer selected
                children: el.children.map((child) => {
                  // Skip color change for badge elements
                  if (child.isBadge || child.isBadgeText) {
                    return child
                  }
                  if (child.type === 'line' && child.closed) {
                    return {...child, fill: isStallAvailableColor} // CHANGED from isStallActiveColor to isStallAvailableColor
                  }
                  return child
                }),
              }
            }
            return el
          }

          // If the element is a single element
          if (bestStallsIds.includes(el.id)) {
            // Special case: If this is a tack stall, we might want to use a different color
            // or maintain its existing tack stall color
            const isTackStall = isStallTack(el)
            const stallFillColor = isTackStall ? isStallTackColor : isStallActiveColor

            // Handle non-grouped elements
            // todo : logic to handle the reservation of tack stalls
            if (el.isStall) {
              return {
                ...el,
                isReservable: false, // Mark as not reservable once it's selected
                children: el.children.map((child) => {
                  // Skip color change for badge elements
                  if (child.isBadge || child.isBadgeText) {
                    return child
                  }
                  if (child.type === 'line' && child.closed) {
                    return {...child, fill: stallFillColor}
                  }
                  return child
                }),
              }
            }
            return el
          } else if (el.isGroup) {
            return {
              ...el,
              // Assign the result of map back to children
              children: el.children.map((groupChild) => {
                if (groupChild.isStall && bestStallsIds.includes(groupChild.id)) {
                  // Special case: If this is a tack stall, we might want to use a different color
                  // or maintain its existing tack stall color
                  const isTackStall = isStallTack(groupChild) // Note: changed el to groupChild
                  const stallFillColor = isTackStall ? isStallTackColor : isStallActiveColor
                  return {
                    ...groupChild,
                    isReservable: false, // Mark as not reservable once it's selected
                    children: groupChild.children.map((child) => {
                      // Skip color change for badge elements
                      if (child.isBadge || child.isBadgeText) {
                        return child
                      }
                      if (child.type === 'line' && child.closed) {
                        return {...child, fill: stallFillColor}
                      }
                      return child
                    }),
                  }
                }
                return groupChild
              }),
            }
          }

          return el
        })
      )

      setReservationStatus(
        `Group ${newIndex + 1} of ${allStallGroups.length} - Score: ${Math.round(
          allStallGroups[newIndex].score || 0
        )}`
      )
    }
  }

  // Load barn design function
  const handleLoadBarnDesign = async (
    barnDesignId,
    customerId,
    barnId,
    fitToScreenAfterLoading
  ) => {
    try {
      setIsDesignLoading(true)
      loadingOverlay({show: true})
      const response = await axios.get(`${NEST_API_URL}/barns/getBarnDesign`, {
        params: {
          barnId: barnId,
          customerId: customerId,
          barnDesignId: barnDesignId,
        },
      })

      // extracting design data
      const design = response.data
      setInitialCanvasLoadingState(design)

      await new Promise((resolve) => setTimeout(resolve, 0))

      setDesignDetails(design)
      setShouldFitToScreen(true)
      setCurrentDesignId(design.id)
      setBarnLayout(design.barnLayout)
      setDesignNameLabel(design.name)

      setCanvasElements([])
      setSelectedElement(null)
      setSelectedElements([])

      if (design.canvasElements?.length > 0) {
        const validElements = design.canvasElements
          .filter(
            (element) =>
              element &&
              element.id &&
              typeof element.x === 'number' &&
              typeof element.y === 'number' &&
              typeof element.width === 'number' &&
              typeof element.height === 'number'
          )
          .map(deserializeElement)
        const completeCanvasElements = [
          {
            id: 'barn-structure',
            type: 'rectangle',
            x: design.barnLayout.structure.x ?? 50,
            y: design.barnLayout.structure.y ?? 50,
            width: design.barnLayout.structure.width,
            height: design.barnLayout.structure.height,
            draggable: false,
            name: 'Barn Structure',
            isBarnStructure: true,
            fill: '#ffffff',
            stroke: 'black',
            strokeWidth: 1,
          },
          ...validElements,
        ]
        setCanvasElements([...completeCanvasElements])

        // Find the horizontal aisle nearest to stall 1
        const aisleNearStall1 = findNearestHorizontalAisleToStall1(validElements)

        if (aisleNearStall1) {
          setClickOrigin(aisleNearStall1)
        }
        setShowClickOrigin(true)

        // Reset view and transform states

        if (fitToScreenAfterLoading) {
          setStageScale(1)
          setStagePosition({x: 0, y: 0})

          // Fit view after everything is loaded
          fitView()
        }
      }
    } catch (error) {
      console.error('Error loading barn design:', error)
    } finally {
      loadingOverlay({show: false})
      setIsDesignLoading(false)
    }
  }

  // Canvas Stage Related Functions
  const handleStageWheel = (e) => {
    e.evt.preventDefault()

    const stage = stageRef.current
    if (!stage) return

    const result = calculateWheelZoom({e, stage})
    if (result) {
      setStageScale(result.scale)
      setStagePosition(result.position)
    }
  }

  const handleStageClick = (e) => {
    // Check if click is on toolbar
    const targetElement = e.evt.target
    if (
      targetElement.closest('.element-toolbar') ||
      targetElement.closest('.multi-select-toolbar')
    ) {
      return // Don't handle stage clicks if clicking toolbar
    }

    // Only update click origin for left mouse button (button === 0)
    if (e.evt.button === 0) {
      // Use the reusable function to update click origin
      updateClickOrigin(e)
    }
  }

  const handleStageDragStart = (e) => {
    if (e.target === e.target.getStage()) {
      e.evt.preventDefault()
      e.evt.stopPropagation()
    }
  }

  const handleStageDragMove = (e) => {
    if (!isDragging && e.target === e.target.getStage()) {
      setStagePosition({
        x: e.target.x(),
        y: e.target.y(),
      })
    }
  }

  const handleElementClick = (elementId, e) => {
    // Handle right-click separately for booking cancellation
    if (e.evt.button === 2) {
      handleRightClickBookingCancel(elementId)
      return
    }

    // Get the clicked node
    const clickedNode = stageRef.current?.findOne(`#${elementId}`)
    if (!clickedNode) return

    // Get the element data
    const element = canvasElements.find((el) => el.id === elementId)

    const isGroupElement = element?.isGroup || false

    // Update click origin when clicking on an element with left mouse button only
    if (e.evt.button === 0) {
      updateClickOrigin(e)
    }

    e.cancelBubble = true

    if (e.evt.shiftKey) {
      // Shift is pressed - handle multi-select

      // if the shift key is pressed then user is trying to select multiple elements

      setSelectedElements((prev) => {
        let newSelection = []
        if (isGroupElement) {
          newSelection = prev.includes(elementId)
            ? prev.filter((id) => id !== elementId)
            : [...prev, elementId]
        } else {
          newSelection = prev.includes(elementId)
            ? prev.filter((id) => id !== elementId)
            : [...prev, elementId]
        }

        // If only one element is selected, set it as the selected element
        // This will initiate the transformer for the single element
        if (newSelection.length === 1) {
          setSelectedElements([elementId])
          setSelectedElement(elementId)
        }

        // Update transformer immediately
        requestAnimationFrame(() => {
          if (transformerRef.current && stageRef.current) {
            const nodes = newSelection
              .map((id) => stageRef.current.findOne(`#${id}`))
              .filter(Boolean)
            transformerRef.current.nodes(nodes)
            transformerRef.current.getLayer().batchDraw()
          }
        })

        return newSelection
      })
    } else {
      // No shift - single select
      const isDeselect = selectedElements.length === 1 && selectedElements[0] === elementId

      if (isDeselect) {
        setSelectedElements([])
        setSelectedElement(null)
        if (transformerRef.current) {
          transformerRef.current.nodes([])
          transformerRef.current.getLayer().batchDraw()
        }
      } else {
        if (isGroupElement) {
          setSelectedElements([elementId])
          // fix when the group is selected then transformer is not working
          setSelectedElement(elementId)
        } else {
          setSelectedElements([elementId])
          setSelectedElement(elementId)
        }

        requestAnimationFrame(() => {
          if (transformerRef.current && clickedNode) {
            transformerRef.current.nodes([clickedNode])
            transformerRef.current.getLayer().batchDraw()
          }
        })
      }
    }

    e.cancelBubble = true
  }

  const handleDoubleClick = () => {}

  const fitView = () => {
    const stage = stageRef.current
    if (!stage || !barnLayout.structure) return

    const result = calculateFitView({
      stage,
      structure: barnLayout.structure,
      structurePosition: {
        x: barnLayout.structure?.x || 50,
        y: barnLayout.structure?.y || 50,
      },
    })

    if (result) {
      setStageScale(result.scale)
      setStagePosition(result.position)
    }
  }

  const renderChild = (child, index, elementId, canvasElements) => {
    switch (child.type) {
      case 'rectangle':
        return <Rect key={`${elementId}-rect-${index}`} {...child} />
      case 'circle':
        return <Circle key={`${elementId}-circle-${index}`} {...child} />
      case 'line':
        return <Line key={`${elementId}-line-${index}`} {...child} />
      case 'text':
        return <Text key={`${elementId}-text-${index}`} {...child} />
      default:
        return null
    }
  }

  const fillBestStallsForReservation = (color, bestStallsIds) => {
    setCanvasElements((prev) =>
      prev.map((el) => {
        // If the element is a single element
        if (bestStallsIds.includes(el.id)) {
          // Special case: If this is a tack stall, we might want to use a different color
          // or maintain its existing tack stall color
          const isTackStall = isStallTack(el)
          const stallFillColor = isTackStall ? isStallTackColor : color

          // Handle non-grouped elements
          // todo : logic to handle the reservation of tack stalls
          if (el.isStall) {
            return {
              ...el,
              isReservable: false, // Mark as not reservable once it's selected
              children: el.children.map((child) => {
                // Skip color change for badge elements
                if (child.isBadge || child.isBadgeText) {
                  return child
                }
                if (child.type === 'line' && child.closed) {
                  return {...child, fill: stallFillColor}
                }
                return child
              }),
            }
          }
          return el
        } else if (el.isGroup) {
          return {
            ...el,
            // Assign the result of map back to children
            children: el.children.map((groupChild) => {
              if (groupChild.isStall && bestStallsIds.includes(groupChild.id)) {
                // Special case: If this is a tack stall, we might want to use a different color
                // or maintain its existing tack stall color
                const isTackStall = isStallTack(groupChild) // Note: changed el to groupChild
                const stallFillColor = isTackStall ? isStallTackColor : color
                return {
                  ...groupChild,
                  isReservable: false, // Mark as not reservable once it's selected
                  children: groupChild.children.map((child) => {
                    // Skip color change for badge elements
                    if (child.isBadge || child.isBadgeText) {
                      return child
                    }
                    if (child.type === 'line' && child.closed) {
                      return {...child, fill: stallFillColor}
                    }
                    return child
                  }),
                }
              }
              return groupChild
            }),
          }
        }

        return el
      })
    )
  }

  // Reusable function to update click origin from any event
  const updateClickOrigin = (e) => {
    if (!e || !e.target) return

    const stage = e.target.getStage()
    if (!stage) return

    const pointerPosition = stage.getPointerPosition()
    if (!pointerPosition) return

    const clickX = (pointerPosition.x - stagePosition.x) / stageScale
    const clickY = (pointerPosition.y - stagePosition.y) / stageScale

    setClickOrigin({x: clickX, y: clickY})
    setShowClickOrigin(true)
  }

  const findNearestHorizontalAisleToStall1 = (elements) => {
    if (!elements || elements.length === 0) return null

    // Look for horizontal aisles
    const horizontalAisles = elements.filter((el) => {
      // Check if it's a horizontal aisle
      return el.isAisle && el.orientation === 'horizontal'
    })

    // If no horizontal aisles found with orientation property, try to identify by shape
    if (horizontalAisles.length === 0) {
      const potentialAisles = elements.filter((el) => {
        // Check if it's an aisle with width significantly greater than height
        return (
          el.width &&
          el.height &&
          el.width > el.height * 3 &&
          el.width > 50 &&
          el.height > 10 &&
          // Exclude stalls and tack stalls
          !el.isStall &&
          !el.isTackStall
        )
      })

      if (potentialAisles.length > 0) {
        // Sort aisles by Y position (top to bottom)
        potentialAisles.sort((a, b) => a.y - b.y)

        // Get the first (topmost) aisle
        const firstAisle = potentialAisles[0]

        // Find the barn structure element
        const barnStructure =
          elements.find((el) => el.isBarnStructure) ||
          elements.find((el) => el.id === 'barn-structure')

        if (barnStructure) {
          // Calculate the left edge of the barn
          const barnLeftEdge = barnStructure.x

          // Place the click origin 20 pixels to the left of the barn's left edge,
          // at the same y-coordinate as the aisle
          return {
            x: barnLeftEdge - 20,
            y: firstAisle.y + firstAisle.height / 2,
          }
        }

        // If barn structure not found, place it at the left edge of the aisle
        return {
          x: firstAisle.x - 20, // 20 pixels to the left of the aisle
          y: firstAisle.y + firstAisle.height / 2,
        }
      }
    } else {
      // Sort horizontal aisles by Y position (top to bottom)
      horizontalAisles.sort((a, b) => a.y - b.y)

      // Get the first (topmost) horizontal aisle
      const firstAisle = horizontalAisles[0]

      // Find the barn structure element
      const barnStructure =
        elements.find((el) => el.isBarnStructure) ||
        elements.find((el) => el.id === 'barn-structure')

      if (barnStructure) {
        // Calculate the left edge of the barn
        const barnLeftEdge = barnStructure.x

        // Place the click origin 20 pixels to the left of the barn's left edge,
        // at the same y-coordinate as the aisle
        return {
          x: barnLeftEdge - 20,
          y: firstAisle.y + firstAisle.height / 2,
        }
      }

      // If barn structure not found, place it at the left edge of the aisle
      return {
        x: firstAisle.x - 20, // 20 pixels to the left of the aisle
        y: firstAisle.y + firstAisle.height / 2,
      }
    }

    // If no aisles found at all, return a default position
    // Find the barn structure element
    const barnStructure =
      elements.find((el) => el.isBarnStructure) || elements.find((el) => el.id === 'barn-structure')

    if (barnStructure) {
      // Place the click origin 20 pixels to the left of the barn's left edge,
      // at the middle height of the barn
      return {
        x: barnStructure.x - 20,
        y: barnStructure.y + barnStructure.height / 2,
      }
    }

    // Last resort fallback
    return {x: 30, y: 50}
  }

  const handleResetAllCanvasElements = () => {
    // Reset the form data
    setFormData({
      stallNumber: 1,
      stallType: 'any', // default value
      stallSize: 'any', // default value
      bookingStartDate: '',
      bookingEndDate: '',
      includeTackStalls: false,
    })

    setBestStallsFound([])

    setDesignDetails(initialCanvasLoadingState)
    setShouldFitToScreen(true)
    setCurrentDesignId(initialCanvasLoadingState.id)
    setBarnLayout(initialCanvasLoadingState.barnLayout)
    setDesignNameLabel(initialCanvasLoadingState.name)

    setCanvasElements([])
    setSelectedElement(null)
    setSelectedElements([])

    if (initialCanvasLoadingState.canvasElements?.length > 0) {
      const validElements = initialCanvasLoadingState.canvasElements
        .filter(
          (element) =>
            element &&
            element.id &&
            typeof element.x === 'number' &&
            typeof element.y === 'number' &&
            typeof element.width === 'number' &&
            typeof element.height === 'number'
        )
        .map(deserializeElement)
      const completeCanvasElements = [
        {
          id: 'barn-structure',
          type: 'rectangle',
          x: initialCanvasLoadingState.barnLayout.structure.x ?? 50,
          y: initialCanvasLoadingState.barnLayout.structure.y ?? 50,
          width: initialCanvasLoadingState.barnLayout.structure.width,
          height: initialCanvasLoadingState.barnLayout.structure.height,
          draggable: false,
          name: 'Barn Structure',
          isBarnStructure: true,
          fill: '#ffffff',
          stroke: 'black',
          strokeWidth: 1,
        },
        ...validElements,
      ]
      setCanvasElements([...completeCanvasElements])

      // Find the horizontal aisle nearest to stall 1
      const aisleNearStall1 = findNearestHorizontalAisleToStall1(validElements)

      if (aisleNearStall1) {
        setClickOrigin(aisleNearStall1)
      }
      setShowClickOrigin(true)
    }
  }

  const handleContextMenu = (e) => {
    e.evt.preventDefault()
    return false
  }

  const handleMouseMove = (e) => {
    if (!e.target.getStage()) return

    const stage = e.target.getStage()
    const pointerPosition = stage.getPointerPosition()
    if (!pointerPosition) return

    // Convert stage coordinates to canvas coordinates
    const x = (pointerPosition.x - stagePosition.x) / stageScale
    const y = (pointerPosition.y - stagePosition.y) / stageScale

    setMousePosition({
      x: Math.round(x),
      y: Math.round(y),
    })
  }

  // Add this new function to handle right-click cancellation
  // const handleRightClickBookingCancel = (elementId) => {
  //   // Check if the clicked element is part of the current booking
  //   const isPartOfBooking = bestStallsFound.some((stall) => stall.id === elementId)

  //   if (isPartOfBooking) {
  //     // Get all stall IDs from the current booking
  //     const stallIdsToReset = bestStallsFound.map((stall) => stall.id)

  //     // Reset the canvas elements (remove highlight from all booked stalls)
  //     setCanvasElements((prev) =>
  //       prev.map((el) => {
  //         if (stallIdsToReset.includes(el.id)) {
  //           // Reset this stall to original state
  //           if (el.isGroup) {
  //             return {
  //               ...el,
  //               children: el.children.map((groupChild) => {
  //                 if (
  //                   groupChild.id.startsWith('text-element-') &&
  //                   groupChild.children[0].type === 'text' &&
  //                   groupChild.children.length === 1
  //                 ) {
  //                   return {
  //                     ...groupChild,
  //                     children: groupChild.children.map((child) => {
  //                       if (child.type === 'text') {
  //                         return {...child, fill: '#000000'} // Reset to default black
  //                       }
  //                       return child
  //                     }),
  //                   }
  //                 }
  //                 return {
  //                   ...groupChild,
  //                   children: groupChild.children?.map((child) => {
  //                     if (child.type === 'line' && child.closed) {
  //                       return {...child, fill: isStallAvailableColor} // Use constant instead of '#ffffff'
  //                     }
  //                     if (['rectangle', 'circle'].includes(child.type)) {
  //                       return {...child, fill: isStallAvailableColor} // Use constant instead of '#ffffff'
  //                     }
  //                     return child
  //                   }),
  //                 }
  //               }),
  //             }
  //           } else {
  //             // Handle non-grouped elements
  //             return {
  //               ...el,
  //               isReservable: true, // Mark as reservable again
  //               children: el.children.map((child) => {
  //                 if (child.type === 'line' && child.closed) {
  //                   return {...child, fill: isStallAvailableColor} // Use constant instead of '#ffffff'
  //                 }

  //                 return child
  //               }),
  //             }
  //           }
  //         }

  //         return el
  //       })
  //     )

  //     // Clear the booking data
  //     setBestStallsFound([])
  //     setReservationStatus('Booking canceled. Click "Find Stalls" to search again.')
  //   }
  // }

  const handleRightClickBookingCancel = (elementId) => {
    // Check if the clicked element is part of the current booking
    // Need to check both direct stall IDs and stalls within groups
    const isPartOfBooking = bestStallsFound.some((stall) => {
      // Check if this is the direct stall ID
      if (stall.id === elementId) return true

      // Check if this ID belongs to a stall within a group
      const element = canvasElements.find(
        (el) => el.isGroup && el.children.some((child) => child.id === elementId && child.isStall)
      )

      const groupElement = canvasElements.find((el) => el.isGroup && el.id === elementId)
      if (groupElement) {
        return groupElement.children.find(
          (child) => child.isStall && bestStallsFound.some((stall) => stall.id === child.id)
        )
      }
      return !!element
    })

    if (isPartOfBooking) {
      // Get all stall IDs from the current booking
      const stallIdsToReset = bestStallsFound.map((stall) => stall.id)

      // Reset the canvas elements (remove highlight from all booked stalls)
      setCanvasElements((prev) =>
        prev.map((el) => {
          // Case 1: Element is a group and contains stalls to reset
          if (el.isGroup) {
            const initalGroup = initialCanvasLoadingState.canvasElements.find(
              (element) => element.id === el.id
            )
            return {
              ...el,
              children: el.children.map((groupChild) => {
                // Check if this child stall needs to be reset
                if (groupChild.isStall && stallIdsToReset.includes(groupChild.id)) {
                  const initialGroupChild = initalGroup.children.find(
                    (child) => child.id === groupChild.id
                  )
                  if (
                    initialGroupChild &&
                    initialGroupChild.isStall &&
                    stallIdsToReset.includes(initialGroupChild.id)
                  ) {
                    return {
                      ...initialGroupChild,
                      isReservable: true,
                    }
                  }
                  // return {
                  //   ...groupChild,
                  //   isReservable: true,
                  //   children: groupChild.children.map((child) => {
                  //     // Skip badge elements
                  //     if (child.isBadge || child.isBadgeText) {
                  //       return child
                  //     }
                  //     // Reset line elements (stall outline)
                  //     if (child.type === 'line' && child.closed) {
                  //       return {...child, fill: isStallAvailableColor}
                  //     }
                  //     return child
                  //   }),
                  // }
                }
                // If it's not a stall to reset, return unchanged
                return groupChild
              }),
            }
          }
          // Case 2: Element is an individual stall that needs to be reset
          else if (stallIdsToReset.includes(el.id)) {
            const stallOnLoad = initialCanvasLoadingState.canvasElements.find(
              (stall) => stall.id === el.id
            )

            if (stallOnLoad) {
              return {
                ...stallOnLoad,
                isReservable: true,
              }
            }
            // return {
            //   ...el,
            //   isReservable: true,
            //   children: el.children.map((child) => {
            //     // Skip badge elements
            //     if (child.isBadge || child.isBadgeText) {
            //       return child
            //     }
            //     // Reset line elements (stall outline)
            //     if (child.type === 'line' && child.closed) {
            //       return {...child, fill: isStallAvailableColor}
            //     }
            //     return child
            //   }),
            // }
          }
          // Case 3: Element is not affected by the reset
          return el
        })
      )

      // Clear the booking data
      setBestStallsFound([])
      setReservationStatus('Booking canceled. Click "Find Stalls" to search again.')
    }
  }

  return isDesignLoading ? (
    <PageTitle breadcrumbs={[]} description={[]}>
      Barn Reservation
    </PageTitle>
  ) : (
    <>
      {/* Page Title */}
      <PageTitle
        breadcrumbs={[]}
        description={[
          `Barn Design Name : ${designDetails.name}`,
          `Barn Design ID : ${designDetails.id}`,
          `Barn ID : ${barn_id}`,
        ]}
      >
        Barn Reservation
      </PageTitle>

      {/* Barn Stall Reservation Menu and Canvas */}
      <div id='barn-reservation'>
        <Split
          className='split-horizontal split-bottom'
          minSize={[250, 840]}
          maxSize={[250, Infinity]}
          gutterSize={6}
          snapOffset={0}
        >
          {/* Left Side - Stall Reservation Parameters Form */}
          <div className='parameters'>
            {/* Stalls Related Parameters */}
            <div className='editor-section' style={{marginBottom: '0px'}}>
              <h5 style={{marginBottom: '2px'}}>
                <RectangleHorizontal className='text-gray-600 mb-1 me-1' size={16} /> Stall
                Parameters
              </h5>
            </div>

            {/* Number of stalls required */}
            <div className='editor-section'>
              <h6>Stall Number</h6>
              <div>
                <input
                  type='number'
                  name='stallNumber'
                  min={formData.includeTackStalls ? '2' : '1'}
                  value={formData.stallNumber}
                  onChange={handleInputChange}
                  style={{
                    width: '100%',
                    padding: '3px',
                    paddingLeft: '6px',
                    paddingRight: '6px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                  }}
                  onBlur={() => {
                    // When input loses focus, ensure value is valid
                    const minStalls = formData.includeTackStalls ? 2 : 1

                    if (
                      formData.stallNumber === '' ||
                      isNaN(parseInt(formData.stallNumber, 10)) ||
                      parseInt(formData.stallNumber, 10) < minStalls
                    ) {
                      setFormData((prev) => ({
                        ...prev,
                        stallNumber: minStalls,
                      }))
                    } else {
                      // Ensure it's an integer
                      setFormData((prev) => ({
                        ...prev,
                        stallNumber: parseInt(formData.stallNumber, 10),
                      }))
                    }
                  }}
                />
              </div>
            </div>

            {/* Stall Type */}
            <div className='editor-section'>
              <h6>Stall Type</h6>
              <div>
                <select
                  name='stallType'
                  value={formData.stallType}
                  onChange={handleInputChange}
                  style={{
                    width: '100%',
                    padding: '3px',
                    paddingLeft: '6px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    fontSize: '14px',
                    color: '#333',
                    appearance: 'menulist',
                    cursor: 'pointer',
                    boxSizing: 'border-box',
                    height: 'auto',
                  }}
                >
                  {barnMetaData.stallTypes.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Stall Size : e.g medium, large, etc. */}
            <div className='editor-section'>
              <h6>Stall Size</h6>
              <div>
                <select
                  name='stallSize'
                  value={formData.stallSize}
                  onChange={handleInputChange}
                  style={{
                    width: '100%',
                    padding: '3px',
                    paddingLeft: '6px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    fontSize: '14px',
                    color: '#333',
                    appearance: 'menulist',
                    cursor: 'pointer',
                    boxSizing: 'border-box',
                    height: 'auto',
                  }}
                >
                  {barnMetaData.stallSizes.map((size) => (
                    <option key={size.value} value={size.value}>
                      {size.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Include Tack Stalls Checkbox */}
            <div className='editor-section'>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '5px',
                  marginBottom: '10px',
                }}
              >
                <input
                  type='checkbox'
                  id='includeTackStallsCheckbox'
                  name='includeTackStalls'
                  checked={formData.includeTackStalls || false}
                  onChange={(e) => {
                    const isChecked = e.target.checked

                    // If tack stalls are being included and stall number is less than 2,
                    // automatically set stall number to 2
                    if (
                      isChecked &&
                      (formData.stallNumber === '' || parseInt(formData.stallNumber, 10) < 2)
                    ) {
                      setFormData((prev) => ({
                        ...prev,
                        includeTackStalls: isChecked,
                        stallNumber: 2,
                      }))
                    } else {
                      setFormData((prev) => ({
                        ...prev,
                        includeTackStalls: isChecked,
                      }))
                    }
                  }}
                  style={{
                    marginRight: '8px',
                    cursor: 'pointer',
                  }}
                />
                <label
                  htmlFor='includeTackStallsCheckbox'
                  style={{
                    fontSize: '14px',
                    cursor: 'pointer',
                  }}
                >
                  Include Tack Stalls
                </label>
              </div>
            </div>

            <hr className='m-1' style={{borderColor: '#aaaaf6'}} />

            {/* Date Range : Booking Start Date and Booking End Date
              <div className='field-container'>
                <div className='field'>
                  <label>Booking Start Date</label>
                  <input
                    type='date'
                    name='bookingStartDate'
                    value={formData.bookingStartDate}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='field'>
                  <label>Booking End Date</label>
                  <input
                    type='date'
                    name='bookingEndDate'
                    value={formData.bookingEndDate}
                    onChange={handleInputChange}
                  />
                </div>
              </div> */}

            {/* <div
              style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                padding: '5px 10px',
                borderRadius: '4px',
                fontSize: '12px',
                zIndex: 10,
                pointerEvents: 'none',
              }}
            >
              X: {mousePosition.x}, Y: {mousePosition.y}
            </div> */}
            {/* Button to find the best possible stalls */}
            <div className='editor-section'>
              <button type='button' className='btn btn-primary w-100' onClick={handleFindStalls}>
                <Search size={16} strokeWidth={2.5} className='me-2 mb-1' /> Find Stalls
              </button>
            </div>

            <hr className='m-1' style={{borderColor: '#aaaaf6'}} />

            {/* Show the best possible stalls */}
            <div className='editor-section'>
              <h6>Best Possible Stalls</h6>
              {/* Add this score heading that's visible when groups are found */}
              {allStallGroups.length > 0 && (
                <div
                  className='score-heading'
                  style={{
                    backgroundColor: '#f0f8ff',
                    border: '1px solid #bdd6ff',
                    borderRadius: '4px',
                    padding: '8px 12px',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {`Group ${currentGroupIndex + 1} of ${
                    allStallGroups.length
                  } - Score: ${Math.round(allStallGroups[currentGroupIndex]?.score || 0)}`}
                </div>
              )}
              <div
                className='stalls-table-container'
                style={{
                  maxHeight: '250px',
                  overflowY: 'auto',
                  border: '1px solid #eee',
                  borderRadius: '4px',
                  marginBottom: '10px',
                  backgroundColor: '#f9f9f9',
                }}
              >
                {bestStallsFound.length > 0 ? (
                  <table
                    style={{
                      width: '100%',
                      borderCollapse: 'collapse',
                      fontSize: '12px',
                    }}
                  >
                    <thead
                      style={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#f0f0f0',
                        zIndex: 1,
                        boxShadow: '0 1px 0 rgba(0,0,0,0.1)',
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            padding: '6px 4px',
                            textAlign: 'left',
                            borderBottom: '1px solid #ddd',
                          }}
                        >
                          Name
                        </th>
                        <th
                          style={{
                            padding: '6px 4px',
                            textAlign: 'left',
                            borderBottom: '1px solid #ddd',
                          }}
                        >
                          Type
                        </th>
                        <th
                          style={{
                            padding: '6px 4px',
                            textAlign: 'left',
                            borderBottom: '1px solid #ddd',
                          }}
                        >
                          Size
                        </th>
                        <th
                          style={{
                            padding: '6px 4px',
                            textAlign: 'center',
                            borderBottom: '1px solid #ddd',
                          }}
                        >
                          Tack
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {bestStallsFound.map((stall, index) => (
                        <tr
                          key={stall.id}
                          style={{
                            backgroundColor: index % 2 === 0 ? '#ffffff' : '#f9f9f9',
                            borderBottom: '1px solid #eee',
                          }}
                        >
                          <td style={{padding: '5px 4px'}}>{stall.name}</td>
                          <td style={{padding: '5px 4px'}}>{stall.type}</td>
                          <td style={{padding: '5px 4px'}}>{stall.size}</td>
                          <td style={{padding: '5px 4px', textAlign: 'center'}}>
                            {stall.isTackStall ? (
                              <span style={{color: 'green', fontWeight: 'bold'}}>✓</span>
                            ) : (
                              <span style={{color: '#999'}}>-</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div style={{textAlign: 'center', color: '#999', padding: '10px'}}>
                    {reservationStatus}
                  </div>
                )}
              </div>
            </div>

            {allStallGroups.length > 1 && (
              <div className='card mb-3 mt-3'>
                <button
                  onClick={showPreviousGroup}
                  disabled={currentGroupIndex === 0}
                  className='btn btn-warning text-black'
                >
                  ← Previous
                </button>

                <button
                  onClick={showNextGroup}
                  disabled={currentGroupIndex === allStallGroups.length - 1}
                  className='btn btn-primary'
                >
                  Next →
                </button>
              </div>
            )}
            <hr className='m-1' style={{borderColor: '#aaaaf6'}} />

            <div className='editor-section'>
              <button
                type='button'
                className='btn btn-primary w-100'
                onClick={handleResetAllCanvasElements}
              >
                <RotateCcw size={16} strokeWidth={3} className='me-2 mb-1' /> Reset All Bookings
              </button>
            </div>

            <hr className='m-1' style={{borderColor: '#aaaaf6'}} />

            {/* Legend display colors and their meanings */}
            <div className='editor-section'>
              <h6>Legend</h6>
              <div style={{fontSize: '12px'}}>
                {/* Tack Stall */}
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
                  <div
                    style={{
                      width: '15px',
                      height: '15px',
                      backgroundColor: isStallTackColor,
                      border: '1px solid #ccc',
                      marginRight: '8px',
                    }}
                  />
                  <span>Tack Stall</span>
                </div>

                {/* DNR Stall */}
                <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
                  <div
                    style={{
                      width: '15px',
                      height: '15px',
                      backgroundColor: isStallDNRColor,
                      border: '1px solid #ccc',
                      marginRight: '8px',
                    }}
                  />
                  <span>DNR Stall</span>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
                  <div
                    style={{
                      width: '15px',
                      height: '15px',
                      backgroundColor: stallTypeColorLookup['fei'],
                      border: '1px solid #ccc',
                      marginRight: '8px',
                    }}
                  />
                  <span>FEI Stall</span>
                </div>

                <div style={{display: 'flex', alignItems: 'center', marginBottom: '8px'}}>
                  <div
                    style={{
                      width: '15px',
                      height: '15px',
                      backgroundColor: stallTypeColorLookup['premium'],
                      border: '1px solid #ccc',
                      marginRight: '8px',
                    }}
                  />
                  <span>Premium Stall</span>
                </div>
              </div>
            </div>

            <hr className='m-1' style={{borderColor: '#aaaaf6'}} />
          </div>

          {/* Right Side - Canvas */}
          <div id='canvas' style={{position: 'relative'}}>
            {/* Zoom Controls */}
            <CanvasZoomControls
              stageRef={stageRef}
              setStageScale={setStageScale}
              setStagePosition={setStagePosition}
              barnLayout={barnLayout}
            />
            {/* Canvas */}
            <Stage
              width={window.innerWidth - 250 - 75 - 5}
              height={window.innerHeight - 54 - 50 - 40}
              ref={stageRef}
              draggable={!isDragging}
              onWheel={handleStageWheel}
              scaleX={stageScale}
              scaleY={stageScale}
              x={stagePosition.x}
              y={stagePosition.y}
              onClick={handleStageClick}
              onDragStart={handleStageDragStart}
              onDragMove={handleStageDragMove}
              onContextMenu={handleContextMenu}
              onMouseMove={handleMouseMove}

            >
              <Layer>
                {/* Traversing the canvas elements and load the design on the canvas */}
                {canvasElements.map((element) => {
                  if (element.isBarnStructure) {
                    return (
                      <Rect
                        id='barn-structure'
                        key={element.id}
                        x={element.x}
                        y={element.y}
                        onClick={(e) => {
                          e.cancelBubble = true
                          handleElementClick('barn-structure', e)
                        }}
                        width={element.width}
                        height={element.height}
                        fill='#ffffff'
                        stroke='black'
                        strokeWidth={1}
                      />
                    )
                  }
                  const isSelected = selectedElement === element.id

                  if (element.isGroup) {
                    return (
                      <Group
                        key={element.id}
                        id={element.id}
                        x={element.x}
                        y={element.y}
                        rotation={element.rotation || 0}
                        scaleX={element.scaleX || 1}
                        scaleY={element.scaleY || 1}
                        draggable={false}
                        onClick={(e) => handleElementClick(element.id, e)}
                        onDblClick={handleDoubleClick}
                        onTap={(e) => handleElementClick(element.id, e)}
                      >
                        {/* Render each child of the group */}
                        {element.children.map((childElement) => (
                          <Group
                            key={childElement.id}
                            x={childElement.x}
                            y={childElement.y}
                            width={childElement.width}
                            height={childElement.height}
                            rotation={childElement.rotation}
                            scaleX={childElement.scaleX}
                            scaleY={childElement.scaleY}
                          >
                            {childElement.children?.map((child, index) => {
                              // Disable dragging for lines when they are part of the group
                              if (child.type === 'line') {
                                child.draggable = false
                              }
                              return renderChild(child, index, element.id, canvasElements)
                            })}
                          </Group>
                        ))}
                      </Group>
                    )
                  } else if (element.isLine) {
                    const isSelected =
                      selectedElement === element.id && selectedElements.length === 1

                    return (
                      <Group
                        key={element.id}
                        id={element.id}
                        x={element.children[0].x}
                        y={element.children[0].y}
                        draggable={false}
                        ref={isSelected ? selectedShapeRef : null}
                      >
                        {/* Line */}
                        <Line
                          points={element.children[0].points}
                          stroke={element.children[0].stroke}
                          strokeWidth={element.children[0].strokeWidth}
                          lineCap={element.children[0].lineCap}
                          dash={element.children[0].dash}
                          hitStrokeWidth={element.children[0].hitStrokeWidth}
                        />
                      </Group>
                    )
                  }

                  return (
                    <Group
                      key={element.id}
                      id={element.id}
                      x={element.x}
                      y={element.y}
                      width={element.width}
                      height={element.height}
                      draggable={false}
                      rotation={element.rotation || 0}
                      scaleX={element.scaleX || 1}
                      scaleY={element.scaleY || 1}
                      onClick={(e) => handleElementClick(element.id, e)}
                      onTap={(e) => handleElementClick(element.id, e)}
                      onDblClick={handleDoubleClick}
                      ref={isSelected ? selectedShapeRef : null}
                    >
                      {element.children?.map((child, index) =>
                        renderChild(child, index, element.id)
                      )}
                    </Group>
                  )
                })}

                {/* Proximity Algo : Focal Point Circle */}
                {showClickOrigin && (
                  <Circle
                    x={clickOrigin.x}
                    y={clickOrigin.y}
                    radius={25}
                    fill='blue'
                    stroke='white'
                    strokeWidth={2}
                    shadowColor='black'
                    shadowBlur={10}
                    shadowOpacity={0.6}
                  />
                )}
              </Layer>
            </Stage>
          </div>
        </Split>
      </div>
    </>
  )
}

export default BarnReservationMode
