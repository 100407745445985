import { useNavigate } from "react-router";
import ShowSelector from "../../pages/classes/Tools/AddClassesFromOtherShow/ShowSelector";
import AddRequirementToClasses from "../../pages/classes/Tools/AddRequirementToClasses";
import AddRuleToClasses from "../../pages/classes/Tools/AddRuleToClasses";
import RemoveRuleFromClasses from "../../pages/classes/Tools/RemoveRuleFromClasses";
import ExportShowSchedule from "../../pages/classes/Tools/ExportShowSchedule";
import PlacingTemplate from "../../pages/classes/Tools/PlacingTemplate";
import RapidAddDrop from "../../pages/entries/RapidAddDrop";
import ApplyPayments from "../../pages/entries/Tools/ApplyPayments";
import ApplyPrizeMoney from "../../pages/entries/Tools/ApplyPrizeMoney";
import FeeSplit from "../../pages/entries/Tools/FeeSplit";
import RapidAddEntryFee from "../../pages/entries/Tools/RapidAddEntryFee";
import RemoveFeeFromEntries from "../../pages/entries/Tools/RemoveFeeFromEntries";
import SetLocation from "../../pages/entries/Tools/SetLocation";
import TrainerSplit from "../../pages/entries/Tools/TrainerSplit";
import UpdateMemberships from "../../pages/entries/Tools/UpdateMemberships";
import RollBackPrizeMoney from "../../pages/entries/Tools/rollBackPrizeMoney";
import ExportECResults from "../../pages/shows/Tools/ExportECResults";
import ExportUSEFResults from "../../pages/shows/Tools/ExportUSEFResults/ExportUSEFResults";
import useGenerateExport from "./use-generate-export";
import useMarkPaymentAsVerifiedOrUnverified from "../../pages/payments/Tools/MarkPaymentAsVerifiedOrUnverified";
import useMergeTool from "./use-merge-tool";
import AssignEntryNumbers from "../../pages/entries/Tools/AssignEntryNumbers";
import CombineClasses from "../../pages/classes/Tools/CombineClasses";
import UnCombineClasses from "../../pages/classes/Tools/UnCombineClasses";
import useMarkEntriesAsPickedUp from "../../pages/entries/Tools/MarkEntriesAsPickedUp";
import SuppressWebResults from "../../pages/classes/Tools/SuppressWebResults";
import CombineDivisions from "../../pages/divisions/Tools/CombineDivisions";
import UnCombineDivisions from "../../pages/divisions/Tools/UnCombineDivisions";
import MoveEntriesToShow from "../../pages/entries/Tools/MoveEntriesToShow";
import { ReplicateEntriesToSeries } from "../../pages/entries/Tools/ReplicateEntriesToSeries";
import { useAppSelector } from "../../redux/hooks";
import { SetMembershipOverridesForm } from "../../pages/entries/Tools/SetMembershipOverrides/SetMembershipOverridesForm";
import useEntriesExportEmails from "../../pages/entries/Tools/EntriesExportEmails";
import EntriesExportFeePaidEmails from "../../pages/entries/Tools/EntriesExportFeePaidEmails";
import SetMembershipDates from "../../pages/entries/Tools/SetMembershipDates";
import ExportHorses from "../../pages/horses/Tools/ExportHorses";
import ExportFEIResults from "../../pages/shows/Tools/ExportFEIResults/ExportFEIResults";
import AddOfficial from "../../pages/classes/Tools/AddOfficial";
import RemoveOfficial from "../../pages/classes/Tools/RemoveOfficial";
import SplitClass from "../../pages/classes/Tools/SplitClass";
import ExportFeiResultsToExcel from "../../pages/classes/Tools/ExportFeiResultsToExcel";
import FeiRapidAdds from "../../pages/entries/Tools/FeiRapidAdds";
import useOverrideForMissingTaxStatus from "../../pages/people/Tools/OverrideMissingTaxStatus";
import useRunTinCheck from "../../pages/people/Tools/RunTinCheck";
import ExportAccountRegister from "../../pages/people/Tools/ExportAccountRegister";
import Export1099Report from "../../pages/people/Tools/Export1099Report";
import ClearOrganizationDates from "../../pages/people/Tools/ClearOrganizationDates";
import useSetWebOrdering from "../../pages/fees/Tools/SetWebOrdering";
import { ReplicateFeesToSeries } from "../../pages/fees/Tools/ReplicateFeesToSeries";
import { useAlert } from "../sgl-utils/DialogsProvider";
import { useIntl } from "react-intl";
import useExportDivisionClassAnalysis from "../../pages/circuits/Tools/use-export-division-class-analysis";
import useUpdateStandings from "../../pages/circuits/Tools/use-update-standings";
import ApplyWithholdingTax from "../../pages/people/Tools/ApplyWithholdingTax/ApplyWithholdingTax";
import BulkSectionCode from "../../pages/classes/Tools/BulkSectionCode";
import { useOutputContext } from "../output-listing/OutputListingContext";
import useRecalculateSoldToDate from "../../pages/fees/Tools/RecalculateSoldToDate";
import useUpdateEntriesBalances from "../../pages/entries/Tools/UpdateEntriesBalances";
import BalanceTransfer from "../../pages/entries/Tools/BalanceTransfer";
import useUpdateEntryCount from "../../pages/classes/Tools/UpdateEntryCount";
import ApplyPaymentTool from "../../pages/entries/Tools/ApplyPaymentTool";

const useQuickActions = () => {
    const intl = useIntl() 
    const navigate = useNavigate()
    const generateExport = useGenerateExport()
    const markPaymentAsVerifiedOrUnverified = useMarkPaymentAsVerifiedOrUnverified()
    const merge = useMergeTool()
    const alertDialog = useAlert()
    const markEntriesAsPickedUp = useMarkEntriesAsPickedUp();
    const overrideMissingTaxStatus = useOverrideForMissingTaxStatus()
    const setWebOrdering = useSetWebOrdering()
    const handleTinCheck = useRunTinCheck()
    const handleRecalculateQtySold = useRecalculateSoldToDate()
    const handleRecalculateEntryBalances = useUpdateEntriesBalances()
    const handleUpdateEntryCountofClasses = useUpdateEntryCount()
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)
    const exportEmails = useEntriesExportEmails();
    const exportDivisionClassAnalysis = useExportDivisionClassAnalysis()
    const updateStandings = useUpdateStandings()
    const { getOutputSelectionAreaIDs } = useOutputContext()

    // Export order to excel
    const handleExportOrderToExcel = () => {
        const settings = {
            url: 'classes/exportOrderToExcel',
            progressBarTitle: 'Export Order to Excel',
            showId: currentShowID
        }
        generateExport(settings)
    }

    // Export FEI Results to .txt file
    const handleExportFeiResults = () => {
        const settings = {
            url: 'classes/exportFeiResults',
            progressBarTitle: 'Export FEI Results',
            showId: currentShowID
        }
        generateExport(settings)
    }

    // Export order (exports a csv) file
    const handleExportOrder = () => {
        const settings = {
            url: 'classes/exportOrder',
            progressBarTitle: 'Export Order',
            showId: currentShowID
        }
        generateExport(settings)
    }

    const handleClassDayExport = () => {
        const settings = {
            url: 'classes/exportClassDay',
            progressBarTitle: 'Class Day Export',
        }
        generateExport(settings)
    }

    const handleTaxStatusExport = async() => {
        const settings = {
            url: 'people/exportTaxStatusList',
            progressBarTitle: 'RTO Tax Status Export',
            customer_id: customerID
        }
        generateExport(settings)
    }

    const handleMerge = async (area) => {
        if(showFinancialsLocked && ['classes'].includes(area)){
            const area_names = { 'people': 'RTO', 'classes': 'Classes' }
            alertDialog({ message: intl.formatMessage({ id: `Cannot merge ${area_names[area]} records of locked show.` }), title: "warning" })
        }else{
            await merge(area)
        }
    }

    //  Export Emails for RTO
    const handleRTOExportEmails = async () => {
        const settings = {
            url: 'people/exportEmails',
            progressBarTitle: 'Export Emails',
            showId: currentShowID
        }
        generateExport(settings)
    }

    //  Export Balance Summary from RTO
    const handleExportBalanceSummary = async () => {
        const settings = {
            url: 'people/exportBalanceSummary',
            progressBarTitle: 'Export Balance Summary',
            showId: currentShowID,
            exportType: 'xlsx'
        }
        generateExport(settings)
    }

    // Export order (exports a csv) file
    const handleDivisionStandingsExport = (exportType) => {
        const settings = {
            url: 'divisions/exportDivisionStandings',
            progressBarTitle: `Exporting Division Standings By ${exportType}`,
            exportType: exportType,
            showId: currentShowID,
        }
        generateExport(settings)
    }

    // Export show CQ results (exports a xlsx) file
    const handleShowExportCQClassResults = (exportType) => {
        getOutputSelectionAreaIDs()
        .then(res => { 
            if (res.length !==  1){
                // error message if no show is selected
                alertDialog({ message: intl.formatMessage({ id: "SHOWS.EXPORTECRESULTS.MODAL.SELECTSHOWSMESSAGE"}) })
            }
            else if (res.length ===  1) {
                let selectedShowID = res;
                const settings = {
                    url: 'shows/exportCQClassResults',
                    progressBarTitle: 'Export CQ Results',
                    showId: selectedShowID,
                    customer_id: customerID,
                }
                generateExport(settings)
            } 
        })
    }

    // generate DJS export for show classes
    const handleDJSExport = () => {
        // Get classes ids from ion
        getOutputSelectionAreaIDs(false)
        .then(res => { 
            if (res.length === 0) {
                alertDialog({ message: intl.formatMessage({ id: "CLASSES.DJS.EXPORT.ERRORMESSAGE.SELECTENTRIES"}) })
            } else{
                const settings = {
                    url: 'classes/generateDJSExport',
                    progressBarTitle: 'Classes DJS Export',
                    showId: currentShowID
                }
                generateExport(settings)
            }
        })
    }

    const paymentsQuickActions = [
        { 
            title: 'Mark Payment as Verified or Unverified', 
            handler: markPaymentAsVerifiedOrUnverified, 
            type: 'Hook',
            permissionsRequired: ['cloud_payments:verify-payments']
        }
    ]

    const entriesQuickActions = [ // Present in 4D Sort Order 
        { 
            title: 'Apply Payments', 
            Component: ApplyPaymentTool, 
            type: 'Modal',
            permissionsRequired: []
        },
        { 
            title: 'Checkout Entries', 
            Component: ApplyPaymentTool, 
            type: 'Modal',
            permissionsRequired: []
        },
        { 
            title: 'Transfer Prize Money to PMR', 
            Component: ApplyPrizeMoney, 
            type: 'Modal',
            permissionsRequired: []
        },
        { 
            title: 'Rollback Prize Money', 
            Component: RollBackPrizeMoney, 
            type: 'Modal',
            permissionsRequired: []
        },
        { 
            title: 'Fee Splits', 
            Component: FeeSplit, 
            type: 'Modal',
            permissionsRequired: []
        },
        { 
            title: 'Rapid Class Add Drop', 
            Component: RapidAddDrop, 
            type: 'Modal',
            permissionsRequired: []
        },
        { 
            title: 'Rapid Fee Input', 
            Component: RapidAddEntryFee, 
            type: 'Modal' ,
            permissionsRequired: [] 
        },
        {   
            title: 'Replicate entries to series',
            Component: ReplicateEntriesToSeries, 
            type: 'Modal' ,
            permissionsRequired: []
        },
        { 
            title: 'Trainer Split',
            Component: TrainerSplit, 
            type: 'Modal' ,
            permissionsRequired: []
        },
        { 
            title: 'Remove Fee From Entries', 
            Component: RemoveFeeFromEntries, 
            type: 'Modal',
            permissionsRequired: ['cloud_entries:delete-fee-from-entries'] 
        },
        { 
            title: 'Update Memberships', 
            Component: UpdateMemberships, 
            type: 'Modal',
            permissionsRequired: []
        },
        {
            title: 'FEI Rapid Adds',
            Component: FeiRapidAdds,
            type: 'Modal',
            permissionsRequired: []
        },
        { 
            title: 'Set Location', 
            Component: SetLocation, 
            type: 'Modal',
            permissionsRequired: [] 
        },
        { 
            title: 'Assign Entry Numbers', 
            Component: AssignEntryNumbers, 
            type: 'Modal',
            permissionsRequired: [] 
        },
        { 
            title: 'Mark Entries as Picked Up', 
            handler: markEntriesAsPickedUp, 
            type: 'Hook',
            permissionsRequired: ['cloud_payments:refund-payments'] 
        },
        {
            title: 'Move Entries To Show', 
            Component: MoveEntriesToShow, 
            type: 'Modal',
            permissionsRequired: [] 
        },
        {
            title: 'Set Membership Overrides', 
            Component: SetMembershipOverridesForm, 
            type: 'Modal',
            permissionsRequired: [] 
        },
        {
            title: 'Set Membership Dates', 
            Component: SetMembershipDates, 
            type: 'Modal',
            permissionsRequired: [] 
        },
        { 
            title: 'Export Emails', 
            handler: exportEmails, 
            type: 'Hook',
            permissionsRequired: [] 
        },
        { 
            title: 'Export Fee Paid Emails', 
            Component: EntriesExportFeePaidEmails, 
            type: 'Modal',
            permissionsRequired: [] 
        },
        { 
            title: 'Update Entries Balance', 
            handler: handleRecalculateEntryBalances, 
            type: 'Hook',
            permissionsRequired: []
        },
        {
            title: 'Transfer Balance', 
            Component: BalanceTransfer, 
            type: 'Modal',
            permissionsRequired: ['cloud_entries:transfer-balances'] 
        }
        
    ]

    const classesQuickActions = [ // Present in 4D Sort Order 
        { 
            title: 'Update Entry Count', 
            handler: handleUpdateEntryCountofClasses, 
            type: 'Hook',
            permissionsRequired: []
        },
        { 
            title: 'Simple Scheduler', 
            handler: () => navigate('/classes/scheduler'), 
            type: 'Link',
            permissionsRequired: ['cloud_simple-scheduler:write'] 
        },
        { 
            title: 'Export Order', 
            handler: handleExportOrder, 
            type: 'Hook',
            permissionsRequired: []
        },
        { 
            title: 'Export Order To Excel', 
            handler: handleExportOrderToExcel, 
            type: 'Hook',
            permissionsRequired: []
        },
        { 
            title: 'Export Show Schedule', 
            Component: ExportShowSchedule, 
            type: 'Modal' ,
            permissionsRequired: []
        },
        { 
            title: 'Placing Template', 
            Component: PlacingTemplate, 
            type: 'Modal',
            permissionsRequired: [] 
        },
        { 
            title: 'Announcer Interface', 
            handler: () => navigate('/classes/announcer-interface'), 
            type: 'Link',
            permissionsRequired: ['cloud_ingate-announcer:write']
        },
        // Export FEI Results .txt
        { 
            title: 'Export FEI Results', 
            handler: handleExportFeiResults, 
            type: 'Hook',
            permissionsRequired: [] 
        },
        // Export FEI Resutls to Excel
        { 
            title: 'Export FEI Results to Excel', 
            Component: ExportFeiResultsToExcel,
            type: 'Modal',
            permissionsRequired: [] 
        },
        { 
            title: 'Class Day Export', 
            handler: handleClassDayExport, 
            type: 'Hook',
            permissionsRequired: []
        },
        { 
            title: 'Add Rule To Classes', 
            Component: AddRuleToClasses, 
            type: 'Modal',
            permissionsRequired: []
        },
        { 
            title: 'Remove Rule From Classes', 
            Component: RemoveRuleFromClasses, 
            type: 'Modal',
            permissionsRequired: []
        },
        { 
            title: 'Merge Classes', 
            handler: async () => await handleMerge('classes'), 
            type: 'Hook', 
            permissionsRequired: ['cloud_misc:use-merge-tool']
        },
        { 
            title: 'Set Order Of Go', 
            handler: () => navigate('/classes/order-of-go'), 
            type: 'Link',
            permissionsRequired: ['cloud_oog:write'] 
        },
        { 
            title: 'Add Requirement to Classes', 
            Component: AddRequirementToClasses, 
            type: 'Modal',
            permissionsRequired: [] 
        },
        { 
            title: 'Combine Classes', 
            Component: CombineClasses, 
            type: 'Modal',
            permissionsRequired: ['cloud_classes:combine-class-division'] 
        },
        {
            title: 'Uncombine Classes', 
            Component: UnCombineClasses, 
            type: 'Modal',
            permissionsRequired: ['cloud_classes:combine-class-division'] 
        },
        {
            title: 'Suppress Web Results', 
            Component: SuppressWebResults, 
            type: 'Modal',
            permissionsRequired: [] 
        },
        { 
            title: 'Add Classes from Other Show', 
            Component: ShowSelector, 
            type: 'Modal',
            permissionsRequired: [] 
        },{ 
            title: 'Assign Officials to Classes', 
            Component: AddOfficial, 
            type: 'Modal',
            permissionsRequired: [] 
        },{ 
            title: 'Remove Officials from Classes', 
            Component: RemoveOfficial, 
            type: 'Modal',
            permissionsRequired: [] 
        },{
            title: 'Split Class', 
            Component: SplitClass, 
            type: 'Modal',
            permissionsRequired: [] 
        },
        {
            title: 'Add Section Code to Classes',
            Component: BulkSectionCode,
            type: 'Modal',
            permissionsRequired: []
        },
        { 
            title: 'Export DJS', 
            handler: handleDJSExport, 
            type: 'Hook',
            permissionsRequired: []
        }
    ]

    const horsesQuickActions = [
        { 
            title: 'Merge Records', 
            handler: async () => await handleMerge('horses'), 
            type: 'Hook', 
            permissionsRequired: ['cloud_misc:use-merge-tool']
        },{ 
            title: 'Export Horses', 
            Component: ExportHorses, 
            type: 'Modal',
            permissionsRequired: [] 
        },
    ]

    const peopleQuickActions = [
        { 
            title: 'Merge Records', 
            handler: async () => await handleMerge('people'), 
            type: 'Hook', 
            permissionsRequired: ['cloud_misc:use-merge-tool']
        },
        { 
            title: 'Override Missing Tax Status', 
            handler: overrideMissingTaxStatus, 
            type: 'Hook',
            permissionsRequired: []
        },
        {
            title: 'Export Tax Status List', 
            handler: handleTaxStatusExport, 
            type: 'Hook',
            permissionsRequired: []
        },{ 
            title: 'Run TIN Check', 
            handler: handleTinCheck, 
            type: 'Hook',
            permissionsRequired: ["cloud_sgadmin:has_access"]
        },
        {
            title: 'Clear Organization Dates',
            Component: ClearOrganizationDates,
            type: 'Modal',
            permissionsRequired: []
        },
        {
            title: 'Export Emails',
            handler: handleRTOExportEmails,
            type: 'Hook',
            permissionsRequired: []
        },
        {
            title: 'Export Account Register',
            Component: ExportAccountRegister,
            type: 'Modal',
            permissionsRequired: ['cloud_shows:export-show-results']
        },
        {
            title: 'Export Balance Summary to Excel',
            handler: handleExportBalanceSummary,
            type: 'Hook',
            permissionsRequired: ['cloud_shows:export-show-results']
        },
        {
            title: '1099 Export',
            Component: Export1099Report,
            type: 'Modal',
            permissionsRequired: ['cloud_shows:export-show-results']
        },{
            title: 'Withholding Payments',
            Component: ApplyWithholdingTax,
            type: 'Modal',
            permissionsRequired: []
        },
    ]

    const stableQuickActions = [
        { 
            title: 'Merge Records', 
            handler: async () => await handleMerge('stables'), 
            type: 'Hook', 
            permissionsRequired: ['cloud_misc:use-merge-tool']
        },
    ]

    const masterFeesQuickActions = [
        { 
            title: 'Merge Records', 
            handler: async () => await handleMerge('master_fees'), 
            type: 'Hook', 
            permissionsRequired: ['cloud_misc:use-merge-tool']
        },
    ]

    const showFeesQuickActions = [
        { 
            title: 'Replicate Fees to Series', 
            Component: ReplicateFeesToSeries,
            type: 'Modal',
            permissionsRequired: []
        },
        { 
            title: 'Enable for Web Ordering', 
            handler: () => { setWebOrdering(true) },
            type: 'Hook', 
            permissionsRequired: []
        },
        { 
            title: 'Disable Web Ordering', 
            handler: () => { setWebOrdering(false) },
            type: 'Hook', 
            permissionsRequired: []
        },
        { 
            title: 'Recalculate Qty. Sold', 
            handler: handleRecalculateQtySold, 
            type: 'Hook',
            permissionsRequired: []
        }
    ]

    const ringsQuickActions = [
        { 
            title: 'Merge Records', 
            handler: async () => await handleMerge('rings'), 
            type: 'Hook', 
            permissionsRequired: ['cloud_misc:use-merge-tool']
        },
    ]

    const showsQuickActions = [
        {
            title: 'Export USEF Results',
            Component: ExportUSEFResults,
            type: 'Modal',
            permissionsRequired: ['cloud_shows:export-show-results']
        },
        { 
            title: 'Export FEI Results', 
            Component: ExportFEIResults, 
            type: 'Modal',
            permissionsRequired: ['cloud_shows:export-show-results']
        },
        { 
            title: 'Export EC Results', 
            Component: ExportECResults, 
            type: 'Modal',
            permissionsRequired: ['cloud_shows:export-show-results']
        },
        {
            title: 'Export CQ Results',
            handler: async () => handleShowExportCQClassResults(), 
            type: 'Hook',
            permissionsRequired: ['cloud_shows:export-show-results']
        },
    ]

    const divisionsQuickActions = [
        { 
            title: 'Combine Divisions', 
            Component: CombineDivisions, 
            type: 'Modal',
            permissionsRequired: ['cloud_classes:combine-class-division']
        }, 
        {
            title: 'Uncombine Divisions', 
            Component: UnCombineDivisions, 
            type: 'Modal',
            permissionsRequired: ['cloud_classes:combine-class-division'] 
        },
        { 
            title: 'Export Division Standings By Dollars Report', 
            handler: async () => await handleDivisionStandingsExport('dollars'), 
            type: 'Hook', 
            permissionsRequired: []
        },
        { 
            title: 'Export Division Standings By Points Report', 
            handler: async () => await handleDivisionStandingsExport('points'), 
            type: 'Hook', 
            permissionsRequired: []
        },
    ]

    const circuitQuickActions = [
        {
            title: 'Update Standings',
            handler: async () => await updateStandings(), 
            type: 'Hook',
            permissionsRequired: []
        },
        {
            title: 'Export Division Class Analysis', 
            handler: async () => await exportDivisionClassAnalysis(), 
            type: 'Hook',
            permissionsRequired: []
        }
    ]
   
    return { entriesQuickActions, classesQuickActions, paymentsQuickActions, horsesQuickActions, peopleQuickActions, stableQuickActions, masterFeesQuickActions, showFeesQuickActions, ringsQuickActions, showsQuickActions, divisionsQuickActions, circuitQuickActions }
}

export default useQuickActions
