/**
 * Calculates appropriate scale and position values to fit an element in a container
 * 
 * @param {Object} params - Parameters for the fit view calculation
 * @param {Object} params.stage - The Konva stage reference
 * @param {Object} params.structure - The structure to fit (with width and height properties)
 * @param {Object} params.structurePosition - The current position of the structure {x, y}
 * @param {number} params.padding - Padding to apply around the element (default: 30)
 * @returns {Object} - The calculated scale and position {scale, position: {x, y}}
 */
export const calculateFitView = ({ 
    stage, 
    structure, 
    structurePosition = { x: 50, y: 50 },
    padding = 30 
  }) => {
    if (!stage || !structure) return null;
  
    // Get the available space
    const containerWidth = stage.width();
    const containerHeight = stage.height();
  
    // Get dimensions and position
    const layoutWidth = structure.width || 1;
    const layoutHeight = structure.height || 1;
    const structureX = structurePosition.x || 50;
    const structureY = structurePosition.y || 50;
  
    // Calculate scaling factors with padding
    const scaleX = (containerWidth - padding * 2) / layoutWidth;
    const scaleY = (containerHeight - padding * 2) / layoutHeight;
  
    // Use the smaller scale to maintain aspect ratio
    const newScale = Math.min(scaleX, scaleY);
  
    // Calculate center position considering structure's current position
    const offsetX = (containerWidth - layoutWidth * newScale) / 2 - structureX * newScale;
    const offsetY = (containerHeight - layoutHeight * newScale) / 2 - structureY * newScale;
  
    return {
      scale: newScale,
      position: {
        x: offsetX,
        y: offsetY,
      }
    };
  };


  /**
 * Calculates new scale and position for zooming on a Konva stage
 * 
 * @param {Object} params - Parameters for zoom calculation
 * @param {Object} params.e - The wheel event from Konva
 * @param {Object} params.stage - The Konva stage reference
 * @param {number} params.scaleBy - Scale factor for zoom (default: 1.05)
 * @param {number} params.minScale - Minimum allowed scale (default: 0.1)
 * @param {number} params.maxScale - Maximum allowed scale (default: 10)
 * @returns {Object} - The calculated scale and position {scale, position: {x, y}}
 */
export const calculateWheelZoom = ({ 
    e, 
    stage, 
    scaleBy = 1.05,
    minScale = 0.1,
    maxScale = 10
  }) => {
    if (!stage) return null;
    
    // Get the current scale
    const oldScale = stage.scaleX();
    
    // Calculate mousePointTo - the point in canvas space where the mouse is pointing
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };
    
    // Calculate the new scale based on wheel direction
    let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    
    // Apply limits
    newScale = Math.max(minScale, Math.min(maxScale, newScale));
    
    // Calculate new position maintaining the pointer position
    const newPos = {
      x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    };
    
    return {
      scale: newScale,
      position: newPos
    };
  };

  // Global configuration values for the barn designer

// Stall category color look up table
export const  stallTypeColorLookup = {
  'premium': '#1976d2',  
  'fei': '#4b0a57',      
  'standard': '#e9edef'  
}

export const  isStallDNRColor = '#00ffff';

export const  isStallTackColor = '#ffa500 ';

export const  isStallActiveColor = '#66cc66 ';

export const  isStallAvailableColor = '#e9edef';



const serializeElement = (element, stageRef) => {
  // First, get the actual node to capture current transforms
  const node = stageRef.current?.findOne(`#${element.id}`)
  const currentTransform = node
    ? {
      rotation: node.rotation(),
      scaleX: node.scaleX(),
      scaleY: node.scaleY(),
      x: node.x() || 0,
      y: node.y() || 0,
    }
    : {
      rotation: element.rotation || 0,
      scaleX: element.scaleX || 1,
      scaleY: element.scaleY || 1,
      x: element.x || 0,
      y: element.y || 0,
    }

  const serializedElement = {
    id: element.id,
    type: element.type || 'group',
    // x: element.x,
    // y: element.y,
    x: currentTransform.x,
    y: currentTransform.y,
    width: element.width,
    height: element.height,
    draggable: element.draggable,
    // Use actual transforms from the node
    rotation: currentTransform.rotation,
    scaleX: currentTransform.scaleX,
    scaleY: currentTransform.scaleY,
    // Preserve element type flags
    isGroup: element.isGroup || false,
    isStall: element.isStall || false,
    isAisle: element.isAisle || false,
    isLine: element.isLine || false,
    isText: element.isText || false,
    isBarnStructure: element.isBarnStructure || false,
    
    // Preserve stall-specific properties
    orientation: element.orientation,
    name: element.name,
    ...(element.isStall && element.sgl_id && { sgl_id: element.sgl_id }),
    // only add stallSize and stallType if the element is a stall
    ...(element.isStall && { stallSize: element.stallSize || 'standard' , stallType: element.stallType || 'standard' , isTackStall: element.isTackStall || false, isDnrStall: element.isDnrStall || false}),
  }

  // Handle children
  if (element.children) {
    serializedElement.children = element.children.map((child) => {
      const childNode = node?.findOne(
        `#${element.id}-${child.type}-${element.children.indexOf(child)}`
      )
      const childTransform = childNode
        ? {
          rotation: childNode.rotation(),
          scaleX: childNode.scaleX(),
          scaleY: childNode.scaleY(),
        }
        : {
          rotation: child.rotation || 0,
          scaleX: child.scaleX || 1,
          scaleY: child.scaleY || 1,
        }

      return {
        ...child,
        rotation: childTransform.rotation,
        scaleX: childTransform.scaleX,
        scaleY: childTransform.scaleY,
        // Preserve specific properties based on child type
        ...(child.type === 'line' && {
          points: child.points,
          stroke: child.stroke,
          strokeWidth: child.strokeWidth,
          lineCap: child.lineCap,
          dash: child.dash,
          hitStrokeWidth: child.hitStrokeWidth,
          fill: child.fill,
          closed: child.closed,
        }),
        ...(child.type === 'text' && {
          text: child.text,
          fontSize: child.fontSize,
          fill: child.fill,
          align: child.align,
          verticalAlign: child.verticalAlign,
          wrap: child.wrap,
          offsetX: child.offsetX,
          offsetY: child.offsetY,
        }),
        ...(child.type === 'rectangle' && {
          fill: child.fill,
          stroke: child.stroke,
          strokeWidth: child.strokeWidth,
        }),
        ...(child.type === 'circle' && {
          ...child,
          radius: child.radius,
          fill: child.fill,
          stroke: child.stroke,
          strokeWidth: child.strokeWidth,
        }),
      }
    })
  }
  return serializedElement
}


const deserializeElement = (element) => {
  const deserializedElement = {
    ...element,
    draggable: true,
    // Ensure transforms are numbers
    rotation: Number(element.rotation) || 0,
    scaleX: Number(element.scaleX) || 1,
    scaleY: Number(element.scaleY) || 1,
  }

  // Handle children with proper transform inheritance
  if (element.children) {
    deserializedElement.children = element.children.map((child) => {
      const baseChild = {
        ...child,
        // Ensure all numeric properties are properly converted
        x: Number(child.x) || 0,
        y: Number(child.y) || 0,
        // width: Number(child.width),
        // height: Number(child.height),
        ...(child.width != undefined && { width: Number(child.width) }),
        ...(child.height != undefined && { height: Number(child.height) }),
        // Handle transforms relative to parent
        rotation: Number(child.rotation) || 0,
        scaleX: Number(child.scaleX) || 1,
        scaleY: Number(child.scaleY) || 1,
      }

      // Add type-specific properties
      switch (child.type) {
        case 'line':
          return {
            ...baseChild,
            points: Array.isArray(child.points) ? child.points.map(Number) : [],
            strokeWidth: Number(child.strokeWidth) || 1,
          }
        case 'text':
          return {
            ...baseChild,
            fontSize: Number(child.fontSize) || 40,
          }
        case 'circle':
          return {
            ...baseChild,
            radius: Number(child.radius),
            fill: child.fill,
            stroke: child.stroke,
            strokeWidth: Number(child.strokeWidth) || 1,
          }
        default:
          return baseChild
      }
    })
  }

  return deserializedElement
}


export {
  serializeElement,
  deserializeElement
}

// Helper function for robust DNR checking - extracted to be reused throughout the code
export function isStallDNR(stall) {
  // Check all possible representations of a "true" DNR flag
  return (
    stall.isDnrStall === true ||
    stall.isDnrStall === 'true' ||
    stall.isDnrStall === 1 ||
    stall.isDnrStall === '1' ||
    (stall.status && stall.status.isDnr === true)
  )
}

export function isStallTack(stall) {
  // Check all possible representations of a "true" tack stall flag
  return (
    stall.isTackStall === true ||
    stall.isTackStall === 'true' ||
    stall.isTackStall === 1 ||
    stall.isTackStall === '1'
  )
}