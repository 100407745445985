import {useState, memo, useEffect} from 'react'
import '../BarnDesigner/barn-designer.css'
import {
  stallTypeColorLookup,
  isStallDNRColor,
  isStallTackColor,
  isStallAvailableColor,
} from '../Components/CanvasUtility'
import {
  Square,
  Circle as CircleIcon,
  Triangle,
  Type,
  Trash2,
  Copy,
  FlipHorizontal,
  FlipVertical,
  Minus,
  Save,
  SaveAll,
  Upload,
  Redo,
  Lock,
  FilePlus,
  LockOpen,
  ChevronUp,
  ChevronDown,
  ArrowUpToLine,
  ArrowDownToLine,
  Layers,
  FileEdit,
  Undo,
  RectangleHorizontal,
  RectangleVertical,
  Palette,
  Box,
} from 'lucide-react'

function ElementSelectionPanel({
  setCanvasElements,
  selectedElement,
  canvasElements,
  getStallTextArea,
  getStallPoints,
  selectedShapeRef,
  lastAisleNumber,
  setLastAisleNumber,
  totalVerticalAisles,
  handleSelect,
  setLastStallNumber,
  setTotalVerticalAisles,
  totalHorizontalAisles,
  setTotalHorizontalAisles,
  lastStallNumber,
  stageRef,
  deleteElement,
  selectedElements,
  moveElementUp,
  moveElementDown,
  moveElementToFront,
  moveElementToBack,
  barnMetaData,
}) {
  const CANVAS_FONT_FAMILY = "'Helvetica Neue', Arial, sans-serif"

  const {stallTypes: stallTypesList, stallSizes: stallSizesList} = barnMetaData

  const [initalElementText, setInitalElementText] = useState('')
  const [initialFontSize, setInitialFontSize] = useState(1)
  const [initialStrokeWidth, setInitalStrokeWidth] = useState(1)
  const [showPositionMenu, setShowPositionMenu] = useState(true)
  const [enablePositionDropDown, setEnablePositionDropDown] = useState(false)
  const [currentlySelectedLayeringPosition, setCurrentlySelectedLayeringPosition] = useState('')
  const [initialLineWidth, setInitialLineWidth] = useState(1)

  // state for stall size and type
  // inital value will always be the regular size and type
  const [stallSize, setStallSize] = useState(
    stallSizesList.find((size) => size.value === 'standard')['value']
  )
  const [stallType, setStallType] = useState(
    stallTypesList.find((type) => type.value === 'standard')['value']
  )

  useEffect(() => {
    setInitalElementText(element.children?.find((child) => child.type === 'text')?.text || '')
    setInitialFontSize(element.children?.find((child) => child.type === 'text')?.fontSize || 1)
    setInitalStrokeWidth(element.children?.[0]?.strokeWidth || 1)
    setInitialLineWidth(element.children?.[0]?.strokeWidth || 1)
    // set the initial stall size and type
    setStallSize(
      element.stallSize || stallSizesList.find((size) => size.value === 'standard')['value']
    )
    setStallType(
      element.stallType || stallTypesList.find((type) => type.value === 'standard')['value']
    )
  }, [selectedElement, selectedElements])

  const element = canvasElements.find((el) => el.id === selectedElement)

  if (!element) {
    return null
  }

  // --------------------------- IMPORTANT ELEMENTS CHECK ---------------------------//
  const isStandaloneLine =
    element?.children?.[0]?.type === 'line' && !element.isStall && element.shapeType !== 'triangle'
  const isTextElement = element.children?.length === 1 && element.children[0].type === 'text'
  const isBasicShape =
    element.children?.[0]?.type === 'rectangle' ||
    element.children?.[0]?.type === 'circle' ||
    (element.children?.[0]?.type === 'line' && element.shapeType === 'triangle')
  const isTopBottomStall =
    element.isStall && (element.orientation === 'top' || element.orientation === 'bottom')
  const isLeftRightStall =
    element.isStall && (element.orientation === 'left' || element.orientation === 'right')

  // =================== RELATED TO TEXT EDIT =================================//
  const handleTextUpdate = (updateTextValue) => {
    // logic related to the text update
    setCanvasElements((prev) =>
      prev.map((el) => {
        if (el.id === selectedElement) {
          const scaleX = el.scaleX || 1
          const scaleY = el.scaleY || 1
          const textArea = el.isStall
            ? getStallTextArea(el.width, el.height, el.orientation)
            : el.isText
            ? {
                x: el.width / 2,
                y: el.height / 2,
                // width: el.width - 20,
                // height: el.height - 20,
                width: undefined,
                height: undefined,
                offsetX: (el.width - 20) / 2,
                offsetY: (el.height - 20) / 2,
              }
            : {
                x: el.width / 2,
                y: el.height / 2,
                width: el.width - 20,
                height: el.height - 20,
                offsetX: (el.width - 20) / 2,
                offsetY: (el.height - 20) / 2,
              }

          return {
            ...el,
            ...(el.isStall ? {name: updateTextValue} : {}),
            children: el.children.map((child) =>
              child.type === 'text'
                ? {
                    ...child,
                    ...textArea,
                    text: updateTextValue,
                    // scaleX: 1 / scaleX,
                    // scaleY: 1 / scaleY,
                    wrap: 'word',
                    verticalAlign: 'middle',
                    align: 'center',
                  }
                : child
            ),
          }
        }
        return el
      })
    )
  }

  // =================== RELATED TO THE FONT SIZE =======================//
  const handleFontSizeInputChange = (fontSize) => {
    // logic related to the text update
    if (fontSize > 0 && !isNaN(fontSize)) {
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (el.id === selectedElement) {
            const node = stageRef.current.findOne(`#${selectedElement}`)
            const scaleX = el.scaleX || 1
            const scaleY = el.scaleY || 1
            const textArea = el.isStall
              ? getStallTextArea(el.width, el.height, el.orientation)
              : el.isText
              ? {
                  x: el.width / 2,
                  y: el.height / 2,
                  // width: el.width - 20,
                  // height: el.height - 20,
                  width: undefined,
                  height: undefined,
                  offsetX: (el.width - 20) / 2,
                  offsetY: (el.height - 20) / 2,
                }
              : {
                  x: el.width / 2,
                  y: el.height / 2,
                  width: el.width - 20,
                  height: el.height - 20,
                  offsetX: (el.width - 20) / 2,
                  offsetY: (el.height - 20) / 2,
                }

            return {
              ...el,
              children: el.children.map((child) => {
                if (child.type === 'text') {
                  return {
                    ...child,
                    ...textArea,
                    fontSize: fontSize,
                    // scaleX: 1 / scaleX,
                    // scaleY: 1 / scaleY,
                  }
                }
                return child
              }),
            }
          }
          return el
        })
      )
    }
  }

  // =================== RELATED TO THE FLIP OPERATIONS =======================//
  const handleFlipHorizontal = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setCanvasElements((prev) =>
      prev.map((el) => {
        if (el.id === selectedElement) {
          // For stalls, handle orientation change
          if (el.id.includes('stall')) {
            const orientation = el.orientation || 'top'

            // Only allow horizontal flip for left/right oriented stalls
            if (orientation !== 'left' && orientation !== 'right') {
              return el // Return unchanged if not left/right oriented
            }

            let newOrientation = orientation === 'left' ? 'right' : 'left'
            const points = getStallPoints(
              // el.width / (el.scaleX || 1),
              // el.height / (el.scaleY || 1),
              el.width,
              el.height,
              newOrientation
            )

            // Get new text area based on new orientation
            const textArea = getStallTextArea(
              // el.width / (el.scaleX || 1),
              // el.height / (el.scaleY || 1),
              el.width,
              el.height,
              newOrientation
            )

            return {
              ...el,
              orientation: newOrientation,
              children: el.children.map((child) => {
                if (child.type === 'line') {
                  return {...child, points}
                }
                if (child.type === 'text') {
                  return {
                    ...child,
                    ...textArea, // Apply all text area properties
                    text: child.text,
                    fontSize: child.fontSize,
                    fill: child.fill,
                    align: 'center',
                    verticalAlign: 'middle',
                    wrap: 'word',
                  }
                }
                return child
              }),
              rotation: el.rotation || 0, // Preserve element rotation
            }
          }

          // For other elements
          return {
            ...el,
            children: el.children.map((child) => {
              if (child.type === 'line') {
                const adjustedWidth = el.width / (el.scaleX || 1)
                return {
                  ...child,
                  points: child.points.map((p, index) => (index % 2 === 0 ? adjustedWidth - p : p)),
                }
              }
              if (child.type === 'text') {
                return {
                  ...child,
                  x: el.width / (el.scaleX || 1) / 2,
                  width: el.width / (el.scaleX || 1),
                  rotation: el.rotation || 0,
                }
              }
              return child
            }),
            rotation: el.rotation || 0,
          }
        }
        return el
      })
    )
  }

  const handleFlipVertical = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setCanvasElements((prev) =>
      prev.map((el) => {
        if (el.id === selectedElement) {
          // For stalls, handle orientation change
          if (el.id.includes('stall')) {
            const orientation = el.orientation || 'top'

            // Only allow vertical flip for top/bottom oriented stalls
            if (orientation !== 'top' && orientation !== 'bottom') {
              return el // Return unchanged if not top/bottom oriented
            }

            let newOrientation = orientation === 'top' ? 'bottom' : 'top'
            const points = getStallPoints(
              // el.width / (el.scaleX || 1),
              el.width,
              // el.height / (el.scaleY || 1),
              el.height,
              newOrientation
            )

            // Get new text area based on new orientation
            const textArea = getStallTextArea(
              // el.width / (el.scaleX || 1),
              el.width,
              // el.height / (el.scaleY || 1),
              el.height,
              newOrientation
            )

            return {
              ...el,
              orientation: newOrientation,
              children: el.children.map((child) => {
                if (child.type === 'line') {
                  return {...child, points}
                }
                if (child.type === 'text') {
                  return {
                    ...child,
                    ...textArea, // Apply all text area properties
                    text: child.text,
                    fontSize: child.fontSize,
                    fill: child.fill,
                    align: 'center',
                    verticalAlign: 'middle',
                    wrap: 'word',
                  }
                }
                return child
              }),
              rotation: el.rotation || 0, // Preserve element rotation
            }
          }

          // For other elements
          return {
            ...el,
            children: el.children.map((child) => {
              if (child.type === 'line') {
                const adjustedHeight = el.height / (el.scaleY || 1)
                return {
                  ...child,
                  points: child.points.map((p, index) =>
                    index % 2 === 1 ? adjustedHeight - p : p
                  ),
                }
              }
              if (child.type === 'text') {
                return {
                  ...child,
                  y: el.height / (el.scaleY || 1) / 2,
                  height: el.height / (el.scaleY || 1),
                  rotation: el.rotation || 0,
                }
              }
              return child
            }),
            rotation: el.rotation || 0,
          }
        }
        return el
      })
    )
  }

  // =================== RELATED TO THE BORDER CONTROL =======================//
  const handleStrokeWidth = (strokeWidth) => {
    if (strokeWidth > 0 && !isNaN(strokeWidth)) {
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (el.id === selectedElement) {
            return {
              ...el,
              children: el.children.map((child) => {
                if (child.type !== 'text') {
                  return {
                    ...child,
                    strokeWidth: strokeWidth,
                  }
                } else if (
                  child.type === 'text' &&
                  (el.isStall === undefined || el.isStall === false) &&
                  (el.isLine === undefined || el.isLine === false) &&
                  (el.isAisle === undefined || el.isAisle === false)
                ) {
                  return {
                    ...child,
                    strokeWidth: strokeWidth,
                  }
                }
                return child
              }),
            }
          }
          return el
        })
      )
    }
  }

  // ======================= Duplicate Element =================================//
  const handleDuplicate = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const elementToDuplicate = canvasElements.find((el) => el.id === selectedElement)
    if (!elementToDuplicate) return

    // Get the current node to access its transformation properties
    const node = selectedShapeRef.current

    if (!node) return

    let newId = `element-${Date.now()}`

    let newElement = {}

    // if the duplicated element is a stall, we need to update the stall number
    if (elementToDuplicate.isAisle) {
      const nextAisleNumber = lastAisleNumber + 1
      setLastAisleNumber(nextAisleNumber)
      // now update the id
      const newAisleId = `aisle-${nextAisleNumber}`
      // now update the aisle name
      const newAisleName = `Aisle ${nextAisleNumber}`
      // now update the aisle text
      const newAisleTextLabel = `Aisle ${Math.abs(
        nextAisleNumber - totalVerticalAisles
      )}`.toUpperCase()

      newId = newAisleId

      // check the orientation of the aisle
      if (elementToDuplicate.orientation === 'vertical') {
        // increment the total vertical aisles
        setTotalVerticalAisles(totalVerticalAisles + 1)
      } else if (elementToDuplicate.orientation === 'horizontal') {
        setTotalHorizontalAisles(totalHorizontalAisles + 1)
      }

      // Get the current node to access its transformation properties
      newElement = {
        ...elementToDuplicate,
        id: newAisleId,
        x: elementToDuplicate.x + 20,
        y: elementToDuplicate.y + 20,
        name: newAisleName,
        // Copy transformation properties from the current state
        rotation: node.rotation(),
        scaleX: node.scaleX(),
        scaleY: node.scaleY(),
        children: elementToDuplicate.children.map((child) => {
          // Handle text elements for aisles
          if (child.type === 'text' && elementToDuplicate.isAisle) {
            return {...child, text: `${newAisleTextLabel}`}
          }

          // If it's a line element with points (like for aisles or triangles)
          if (child.type === 'line' && child.points) {
            // We need to transform the points based on current scale
            const transformedPoints = [...child.points]
            return {...child, points: transformedPoints}
          }

          return child
        }),
      }
      // el has key sgl id remove it from the cloned element
      if (newElement.sgl_id) {
        delete newElement.sgl_id
      }
    } else if (elementToDuplicate.isStall) {
      const nextStallNumber = lastStallNumber + 1
      setLastStallNumber(nextStallNumber)
      // now update the id
      const newStallId = `stall-${nextStallNumber}`
      // now update the stall name
      const newStallName = `Stall ${nextStallNumber}`
      // now update the stall text
      const newStallTextLabel = `Stall ${nextStallNumber}`

      newId = newStallId

      // Get the current node to access its transformation properties
      newElement = {
        ...elementToDuplicate,
        id: newStallId,
        x: elementToDuplicate.x + 20,
        y: elementToDuplicate.y + 20,
        name: newStallName,
        // Copy transformation properties from the current state
        rotation: node.rotation(),
        scaleX: node.scaleX(),
        scaleY: node.scaleY(),
        children: elementToDuplicate.children.map((child) => {
          // Handle text elements for stalls
          if (child.type === 'text' && elementToDuplicate.isStall) {
            return {...child, text: `${newStallTextLabel}`}
          }

          // If it's a line element with points (like for stalls or triangles)
          if (child.type === 'line' && child.points) {
            // We need to transform the points based on current scale
            const transformedPoints = [...child.points]
            return {...child, points: transformedPoints}
          }

          return child
        }),
      }
      // el has key sgl id remove it from the cloned element
      if (newElement.sgl_id) {
        delete newElement.sgl_id
      }
    } else {
      newElement = {
        ...elementToDuplicate,
        id: newId,
        x: elementToDuplicate.x + 20,
        y: elementToDuplicate.y + 20,
        // Copy transformation properties from the current state
        rotation: node.rotation(),
        scaleX: node.scaleX(),
        scaleY: node.scaleY(),
        children: elementToDuplicate.children.map((child) => {
          // Handle text elements for stalls
          if (child.type === 'text' && elementToDuplicate.isStall) {
            return {...child, text: `${child.text}`}
          }

          // If it's a line element with points (like for stalls or triangles)
          if (child.type === 'line' && child.points) {
            // We need to transform the points based on current scale
            const transformedPoints = [...child.points]
            // return {...child, points: transformedPoints}

            // if we have a stand alone line, we need to update the points
            if (elementToDuplicate.isLine) {
              return {
                ...child,
                // points: transformedPoints.map((p, index) => (index % 2 === 0 ? p + 20 : p + 20)),
                x: elementToDuplicate.x + 20,
                y: elementToDuplicate.y + 20,
              }
            } else {
              // if line is used to make some other componenets like rectangle, circle, triangle etc
              return {...child, points: transformedPoints}
            }
          }

          return child
        }),
      }
    }

    setCanvasElements((prev) => [...prev, newElement])

    // Give React a chance to render the new element
    requestAnimationFrame(() => {
      // Find and simulate click on the new element
      const shape = stageRef.current?.findOne(`#${newId}`)
      if (shape) {
        // Trigger the click handler directly
        handleSelect(newId)
      }
    })
  }

  // ======================= DELETE ELEMENT =================================//
  const handleDelete = (e) => {
    e.preventDefault()
    e.stopPropagation()
    deleteElement(selectedElement)
  }

  // ======================= HANDLE LINE WIDTH =================================//
  const handleLineWidthChange = (updatedLineWidth) => {
    if (updatedLineWidth && !isNaN(updatedLineWidth)) {
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (el.id === selectedElement) {
            // Get the initial line template to use its base dash pattern
            const initialDash = [15, 15] // Original dash pattern from shapeTemplates
            const baseWidth = 5 // Original stroke width from shapeTemplates

            return {
              ...el,
              children: el.children.map((child) =>
                child.type === 'line'
                  ? {
                      ...child,
                      strokeWidth: updatedLineWidth,
                      // Scale dash pattern proportionally to width changes
                      dash: child.dash
                        ? initialDash.map((d) => d * (updatedLineWidth / baseWidth))
                        : undefined,
                    }
                  : child
              ),
            }
          }
          return el
        })
      )
    }
  }

  // ======================= HANDLE FILL COLOR =================================//
  const handleFillColorChange = (color) => {
    if (color && color !== '') {
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (el.id === selectedElement) {
            if (el.isGroup) {
              // Handle grouped elements
              return {
                ...el,
                children: el.children.map((groupChild) => ({
                  ...groupChild,
                  children: groupChild.children?.map((child) => {
                    // Skip color change for badge elements
                    if (child.isBadge || child.isBadgeText) {
                      return child
                    }
                    if (child.type === 'line' && child.closed) {
                      return {...child, fill: color}
                    }
                    if (['rectangle', 'circle'].includes(child.type)) {
                      return {...child, fill: color}
                    }
                    return child
                  }),
                })),
              }
            } else {
              // Handle non-grouped elements
              return {
                ...el,
                children: el.children.map((child) => {
                  // Skip color change for badge elements
                  if (child.isBadge || child.isBadgeText) {
                    return child
                  }
                  if (child.type === 'line' && child) {
                    return {...child, fill: color}
                  } else if (['rectangle', 'circle'].includes(child.type)) {
                    return {...child, fill: color}
                  } else if (
                    child.type === 'text' &&
                    (el.isStall === undefined || el.isStall === false) &&
                    (el.isLine === undefined || el.isLine === false) &&
                    (el.isAisle === undefined || el.isAisle === false) &&
                    !el.id.startsWith('element-')
                  ) {
                    return {...child, fill: color}
                  }
                  return child
                }),
              }
            }
          }
          return el
        })
      )
    }
  }
  // ======================= HANDLE BORDER COLOR =================================//
  const handleBorderColorChange = (color) => {
    if (color && color !== '') {
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (el.id === selectedElement) {
            // return {
            //   ...el,
            //   children: el.children.map((child) => {
            //     if (child.type !== 'text') {
            //       return {
            //         ...child,
            //         stroke: color,
            //       }
            //     } else if (
            //       child.type === 'text' &&
            //       (el.isStall === undefined || el.isStall === false) &&
            //       (el.isLine === undefined || el.isLine === false) &&
            //       (el.isAisle === undefined || el.isAisle === false)
            //     ) {
            //       return {
            //         ...child,
            //         stroke: color,
            //       }
            //     }
            //     return child
            //   }),
            // }

            if (
              el.id.startsWith('text-element-') &&
              el.children[0].type === 'text' &&
              el.children.length === 1
            ) {
              return {
                ...el,
                children: el.children.map((child) => {
                  if (child.type === 'text') {
                    return {
                      ...child,
                      stroke: color,
                    }
                  }
                  return child
                }),
              }
            }

            return {
              ...el,
              children: el.children.map((child) => {
                if (child.type !== 'text') {
                  return {
                    ...child,
                    stroke: color,
                  }
                }
                return child
              }),
            }
          }
          return el
        })
      )
    }
  }

  const handleContainerClick = (e) => {
    e.stopPropagation()
  }

  const ColorPickerExpanded = ({onColorChange, initialColor = '#333333'}) => {
    const [selectedColor, setSelectedColor] = useState(initialColor)

    const colors = [
      '#333333', // Dark gray
      '#e9edef', // Default gray
      '#FFB6C1', // Light pink
      '#ADD8E6', // Light blue
      '#98FB98', // Pale green
      '#DDA0DD', // Plum
      '#F0E68C', // Khaki
      '#E6E6FA', // Lavender
      '#FFDAB9', // Peach
      '#D3D3D3', // Light gray
      '#FFFFFF', // White
      '#90EE90', // Light green
      '#FFA07A', // Light salmon
      '#f6f6f6', // Very Light gray
      '#86dcc2', // Alt Green
      '#cbc8f7', // Light Purple
    ]

    const handleColorClick = (color) => {
      setSelectedColor(color)
      onColorChange(color)
    }

    return (
      <div
        style={{
          marginTop: '4px',
          padding: '4px',
          background: 'white',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '4px',
          width: '170px',
        }}
      >
        {colors.map((color) => (
          <button
            key={color}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleColorClick(color)
            }}
            style={{
              width: '24px',
              height: '24px',
              backgroundColor: color,
              border: `2px solid ${color === selectedColor ? '#333' : '#ddd'}`,
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            title={color}
            onMouseDown={(e) => e.stopPropagation()}
          />
        ))}
      </div>
    )
  }

  const BorderColorExpandedPallete = ({initialColor = '#333333', onBorderChange}) => {
    const [showControls, setShowControls] = useState(false)
    const [selectedColor, setSelectedColor] = useState(initialColor)
    const colors = [
      '#333333', // Dark gray
      '#666666', // Medium gray
      '#999999', // Light gray
      '#000000', // Black
      '#1a237e', // Dark blue
      '#4CAF50', // Green
      '#f44336', // Red
      '#2196F3', // Blue
      '#FF9800', // Orange
      '#9C27B0', // Purple
      '#795548', // Brown
      '#607D8B', // Blue gray
    ]
    const handleColorClick = (color) => {
      setSelectedColor(color)
      onBorderChange(color)
    }

    return (
      <div
        style={{
          marginTop: '4px',
          padding: '4px',
          background: 'white',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '4px',
          width: '170px',
        }}
      >
        {colors.map((color) => (
          <button
            key={color}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleColorClick(color)
            }}
            style={{
              width: '24px',
              height: '24px',
              backgroundColor: color,
              border: `2px solid ${color === selectedColor ? '#333' : '#ddd'}`,
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            title={color}
            onMouseDown={(e) => e.stopPropagation()}
          />
        ))}
      </div>
    )
  }

  // Position menu component
  const PositionMenu = ({elementId, onClose, onPositionSelect}) => {
    const elementIds = Array.isArray(elementId) ? elementId : [elementId]

    const handleAction = (action, e) => {
      e.preventDefault()
      e.stopPropagation()

      switch (action) {
        case 'up':
          moveElementUp(elementIds)
          onPositionSelect('Up')
          break
        case 'down':
          moveElementDown(elementIds)
          onPositionSelect('Down')
          break
        case 'front':
          moveElementToFront(elementIds)
          onPositionSelect('To Front')
          break
        case 'back':
          moveElementToBack(elementIds)
          onPositionSelect('To Bottom')
          break
        case 'close':
          onClose()
          break
      }
    }

    return (
      <div
        className='position-menu'
        style={{
          position: 'absolute',
          backgroundColor: 'white',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          padding: '8px',
          zIndex: 1000,
          marginTop: '8px',
          marginBottom: '8px',
          maxHeight: '300px', // Adjust this value based on your needs
          overflowY: 'auto',
          width: '85%',
          border: '1px solid #e5e5e5',
        }}
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
      >
        <button
          onClick={(e) => handleAction('up', e)}
          className='menu-item'
          onMouseDown={(e) => e.stopPropagation()}
        >
          <ChevronUp size={16} />
          <span>Up</span>
        </button>

        <button
          onClick={(e) => handleAction('down', e)}
          className='menu-item'
          onMouseDown={(e) => e.stopPropagation()}
        >
          <ChevronDown size={16} />
          <span>Down</span>
        </button>

        <button
          onClick={(e) => handleAction('front', e)}
          className='menu-item'
          onMouseDown={(e) => e.stopPropagation()}
        >
          <ArrowUpToLine size={16} />
          <span>To Forward</span>
        </button>

        <button
          onClick={(e) => handleAction('back', e)}
          className='menu-item'
          onMouseDown={(e) => e.stopPropagation()}
        >
          <ArrowDownToLine size={16} />
          <span>To Bottom</span>
        </button>

        <button
          onClick={(e) => handleAction('close', e)}
          className='menu-item'
          style={{
            borderTop: '1px solid #eee',
            marginTop: '4px',
            paddingTop: '4px',
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <span>Close</span>
        </button>
      </div>
    )
  }

  const handleStallSizeChange = (e) => {
    const newSize = e.target.value
    setStallSize(newSize)

    // Create a proper size abbreviation mapping
    const sizeAbbreviations = {
      small: 'S',
      large: 'L',
      standard: 'STD',
      'extra-large': 'XL',
    }

    // Get the appropriate abbreviation or use first letter as fallback
    const sizeAbbr = sizeAbbreviations[newSize] || newSize.charAt(0).toUpperCase()

    // Get current stall name/number from the text child
    const textChild = element.children?.find((child) => child.type === 'text' && !child.isBadge)
    const currentText = textChild?.text || ''

    // Extract the stall number using regex - match "Stall" followed by one or more digits
    const stallPattern = /Stall\s+(\d+)/i
    const stallMatch = currentText.match(stallPattern)

    let newText = currentText // Default to keeping the current text

    if (stallMatch) {
      const stallNumber = stallMatch[1] // The captured number

      if (newSize === 'standard') {
        // For standard size, just use the stall number without size indicator
        newText = `Stall ${stallNumber}`
      } else {
        // For non-standard sizes, format with the size indicator
        newText = `Stall ${stallNumber} ${sizeAbbr}`
      }
    }

    setInitalElementText(newText)

    // Update both the stallSize metadata property and the display text
    setCanvasElements((prev) =>
      prev.map((el) => {
        if (el.id === selectedElement) {
          return {
            ...el,
            stallSize: newSize,
            // Also update the name property if it exists
            ...(el.name ? {name: newText} : {}),
            // Update the text child to show the size indicator
            children: el.children.map((child) => {
              if (child.type === 'text' && !child.isBadge) {
                return {
                  ...child,
                  text: newText,
                }
              }
              return child
            }),
          }
        }
        return el
      })
    )
  }
  // const handleStallTypeChange = (e) => {
  //   const newType = e.target.value
  //   setStallType(newType)

  //   // Find the type object to get its color
  //   const typeObj = stallTypesList.find(type => type.value === newType)
  //   const typeColor = stallTypeColorLookup[newType] || '#ffffff'
  //   const typeLabel = typeObj?.label || newType
  //   const badgeText = typeLabel.charAt(0).toUpperCase()

  //   setCanvasElements((prev) =>
  //     prev.map((el) => {
  //       if (el.id === selectedElement) {
  //         // Determine badge position based on stall orientation
  //         const orientation = el.orientation || 'top'
  //         let badgePosition = {}

  //         // Set badge position based on orientation
  //         switch(orientation) {
  //           case 'top':
  //             badgePosition = { x: 10, y: el.height - 10 }; // bottom left
  //             break;
  //           case 'bottom':
  //             badgePosition = { x: 10, y: 10 }; // top left
  //             break;
  //           case 'right':
  //             badgePosition = { x: 10, y: 10 }; // top left
  //             break;
  //           case 'left':
  //             badgePosition = { x: el.width - 10, y: 10 }; // top right
  //             break;
  //           default:
  //             badgePosition = { x: 10, y: 10 }; // default to top left
  //         }

  //         // Find existing badge or create new badge configuration
  //         const existingBadgeIndex = el.children.findIndex(child => child.isBadge)
  //         const badgeConfig = {
  //           type: 'circle',
  //           x: badgePosition.x,
  //           y: badgePosition.y,
  //           radius: 8,
  //           fill: typeColor,
  //           stroke: '#333333',
  //           strokeWidth: 1,
  //           isBadge: true
  //         }

  //         // Properly center the text in the badge
  //         const badgeTextConfig = {
  //           type: 'text',
  //           x: badgePosition.x,
  //           y: badgePosition.y,
  //           text: badgeText,
  //           fontSize: 10,
  //           fill: '#ffffff',
  //           align: 'center',
  //           verticalAlign: 'middle',
  //           width: 16,
  //           height: 16,
  //           offsetX: 8, // Half the width to center
  //           offsetY: 8, // Half the height to center
  //           isBadge: true,
  //           isBadgeText: true
  //         }

  //         // Create new children array with updated or added badge
  //         let newChildren = [...el.children]

  //         if (existingBadgeIndex >= 0) {
  //           // Update existing badge
  //           newChildren[existingBadgeIndex] = badgeConfig

  //           // Find and update badge text if it exists
  //           const badgeTextIndex = newChildren.findIndex(child => child.isBadgeText)
  //           if (badgeTextIndex >= 0) {
  //             newChildren[badgeTextIndex] = badgeTextConfig
  //           } else {
  //             newChildren.push(badgeTextConfig)
  //           }
  //         } else {
  //           // Add new badge and text
  //           newChildren.push(badgeConfig)
  //           newChildren.push(badgeTextConfig)
  //         }

  //         // Update both the type and the fill color
  //         return {
  //           ...el,
  //           stallType: newType,
  //           children: newChildren
  //         }
  //       }
  //       return el
  //     })
  //   )
  // }

  // const handleStallTypeChange = (e) => {
  //   const newType = e.target.value
  //   setStallType(newType)

  //   // Find the type object to get its color
  //   const typeObj = stallTypesList.find(type => type.value === newType)
  //   const typeColor = stallTypeColorLookup[newType] || '#ffffff'
  //   const typeLabel = typeObj?.label || newType
  //   const badgeText = typeLabel.charAt(0).toUpperCase()

  //   setCanvasElements((prev) =>
  //     prev.map((el) => {
  //       if (el.id === selectedElement) {
  //         // Determine badge position based on stall orientation
  //         const orientation = el.orientation || 'top'
  //         let badgePosition = {}

  //         // Set badge position based on orientation
  //         switch(orientation) {
  //           case 'top':
  //             badgePosition = { x: 15, y: el.height - 15 }; // bottom left
  //             break;
  //           case 'bottom':
  //             badgePosition = { x: 15, y: 15 }; // top left
  //             break;
  //           case 'right':
  //             badgePosition = { x: 15, y: 15 }; // top left
  //             break;
  //           case 'left':
  //             badgePosition = { x: el.width - 15, y: 15 }; // top right
  //             break;
  //           default:
  //             badgePosition = { x: 15, y: 15 }; // default to top left
  //         }

  //         // Increased badge size
  //         const badgeRadius = 12;

  //         // Find existing badge or create new badge configuration
  //         const existingBadgeIndex = el.children.findIndex(child => child.isBadge)
  //         const badgeConfig = {
  //           type: 'circle',
  //           x: badgePosition.x,
  //           y: badgePosition.y,
  //           radius: badgeRadius,
  //           fill: typeColor,
  //           stroke: '#333333',
  //           strokeWidth: 1,
  //           isBadge: true
  //         }

  //         // Properly center the text in the badge with larger font
  //         const badgeTextConfig = {
  //           type: 'text',
  //           x: badgePosition.x,
  //           y: badgePosition.y,
  //           text: badgeText,
  //           fontSize: 14,  // Increased font size
  //           fontStyle: 'bold',  // Make it bold for better visibility
  //           fill: '#ffffff',
  //           align: 'center',
  //           verticalAlign: 'middle',
  //           width: badgeRadius * 2,
  //           height: badgeRadius * 2,
  //           offsetX: badgeRadius,  // Center horizontally
  //           offsetY: badgeRadius,  // Center vertically
  //           isBadge: true,
  //           isBadgeText: true
  //         }

  //         // Create new children array with updated or added badge
  //         let newChildren = [...el.children]

  //         if (existingBadgeIndex >= 0) {
  //           // Update existing badge
  //           newChildren[existingBadgeIndex] = badgeConfig

  //           // Find and update badge text if it exists
  //           const badgeTextIndex = newChildren.findIndex(child => child.isBadgeText)
  //           if (badgeTextIndex >= 0) {
  //             newChildren[badgeTextIndex] = badgeTextConfig
  //           } else {
  //             newChildren.push(badgeTextConfig)
  //           }
  //         } else {
  //           // Add new badge and text
  //           newChildren.push(badgeConfig)
  //           newChildren.push(badgeTextConfig)
  //         }

  //         // Update both the type and the fill color
  //         return {
  //           ...el,
  //           stallType: newType,
  //           children: newChildren
  //         }
  //       }
  //       return el
  //     })
  //   )
  // }

  const handleStallTypeChange = (e) => {
    const newType = e.target.value
    setStallType(newType)

    // Find the type object to get its color
    const typeObj = stallTypesList.find((type) => type.value === newType)
    const typeColor = stallTypeColorLookup[newType] || '#ffffff'
    const typeLabel = typeObj?.label || newType
    const badgeText = typeLabel.charAt(0).toUpperCase()

    setCanvasElements((prev) =>
      prev.map((el) => {
        if (el.id === selectedElement) {
          // If type is standard, remove any existing badges
          if (newType === 'standard') {
            return {
              ...el,
              stallType: newType,
              // Filter out any badge or badge text elements
              children: el.children.filter((child) => !child.isBadge && !child.isBadgeText),
            }
          }

          // For non-standard types, continue with badge creation/update
          // Determine badge position based on stall orientation
          const orientation = el.orientation || 'top'
          let badgePosition = {}

          // Set badge position based on orientation
          switch (orientation) {
            case 'top':
              badgePosition = {x: 20, y: el.height - 20} // bottom left
              break
            case 'bottom':
              badgePosition = {x: 20, y: 20} // top left
              break
            case 'right':
              badgePosition = {x: 20, y: 20} // top left
              break
            case 'left':
              badgePosition = {x: el.width - 20, y: 20} // top right
              break
            default:
              badgePosition = {x: 20, y: 20} // default to top left
          }

          // badge size
          const badgeRadius = 18

          // Find existing badge or create new badge configuration
          const existingBadgeIndex = el.children.findIndex((child) => child.isBadge)
          const badgeConfig = {
            type: 'circle',
            x: badgePosition.x,
            y: badgePosition.y,
            radius: badgeRadius,
            fill: typeColor,
            stroke: '#333333',
            strokeWidth: 1,
            isBadge: true,
          }

          // Properly center the text in the badge with much larger font
          const badgeTextConfig = {
            type: 'text',
            x: badgePosition.x,
            y: badgePosition.y,
            text: badgeText,
            fontSize: 32,
            fontStyle: 'bold',
            fill: '#ffffff',
            align: 'center',
            verticalAlign: 'middle',
            width: badgeRadius * 2,
            height: badgeRadius * 2,
            offsetX: badgeRadius, // Center horizontally
            offsetY: badgeRadius, // Center vertically
            isBadge: true,
            isBadgeText: true,
            fontFamily: CANVAS_FONT_FAMILY,
          }

          // Create new children array with updated or added badge
          let newChildren = [...el.children]

          if (existingBadgeIndex >= 0) {
            // Update existing badge
            newChildren[existingBadgeIndex] = badgeConfig

            // Find and update badge text if it exists
            const badgeTextIndex = newChildren.findIndex((child) => child.isBadgeText)
            if (badgeTextIndex >= 0) {
              newChildren[badgeTextIndex] = badgeTextConfig
            } else {
              newChildren.push(badgeTextConfig)
            }
          } else {
            // Add new badge and text
            newChildren.push(badgeConfig)
            newChildren.push(badgeTextConfig)
          }

          // Update both the type and the fill color
          return {
            ...el,
            stallType: newType,
            children: newChildren,
          }
        }
        return el
      })
    )
  }

  return (
    <div
      className='element-selection-panel editor-sidebar'
      onClick={handleContainerClick}
      onMouseDown={handleContainerClick}
      style={{
        position: 'absolute',
        right: '0px',
        top: '61px',
        padding: '15px',
        backgroundColor: 'white',
        borderRadius: '0px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
        width: '210px', // inherited from editor-sidebar as it has rule of !important 210px
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        height: '100%',
      }}
    >
      {/* Stall Properties */}
      {element.isStall && (
        <div className='editor-section' style={{marginBottom: '0px'}}>
          <h5 style={{marginBottom: '2px'}}>
            <RectangleHorizontal className='text-gray-600 mb-1 me-1' size={16} /> Stall Properties
          </h5>
        </div>
      )}

      {/* Stall Size Drop Down */}
      {element.isStall && (
        <div className='editor-section'>
          <h6>Stall Size</h6>
          <div>
            <select
              style={{
                width: '100%',
                padding: '3px',
                paddingLeft: '6px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                backgroundColor: '#fff',
                fontSize: '14px',
                color: '#333',
                appearance: 'menulist',
                cursor: 'pointer',
                boxSizing: 'border-box',
                height: 'auto',
              }}
              onChange={handleStallSizeChange}
              value={stallSize}
              onBlur={(e) => (e.target.style.boxShadow = '0 1px 2px rgba(0,0,0,0.05)')}
            >
              {stallSizesList.map((size) => (
                <option key={size.value} value={size.value}>
                  {size.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      {/* Stall Type Drop Down */}
      {element.isStall && (
        <div className='editor-section'>
          <h6>Stall Type</h6>
          <div>
            <select
              style={{
                width: '100%',
                padding: '3px',
                paddingLeft: '6px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                backgroundColor: '#fff',
                fontSize: '14px',
                color: '#333',
                appearance: 'menulist',
                cursor: 'pointer',
                boxSizing: 'border-box',
                height: 'auto',
              }}
              onChange={handleStallTypeChange}
              value={stallType}
              onBlur={(e) => (e.target.style.boxShadow = '0 1px 2px rgba(0,0,0,0.05)')}
            >
              {stallTypesList.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      {/* Tack Stall Checkbox */}
      {element.isStall && (
        <div className='editor-section'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '5px',
            }}
          >
            <input
              type='checkbox'
              id='tackStallCheckbox'
              checked={element.isTackStall || false}
              onChange={(e) => {
                const isChecked = e.target.checked
                setCanvasElements((prev) =>
                  prev.map((el) => {
                    if (el.id === selectedElement) {
                      return {
                        ...el,
                        isTackStall: isChecked,
                        // If tack is checked, ensure DNR is unchecked
                        isDnrStall: isChecked ? false : el.isDnrStall,
                        // Update children to apply the new fill color to the stall
                        children: el.children.map((child) => {
                          if (child.type === 'line') {
                            return {
                              ...child,
                              fill: isChecked
                                ? isStallTackColor
                                : el.isDnrStall && !isChecked
                                ? isStallDNRColor
                                : isStallAvailableColor,
                            }
                          }
                          return child
                        }),
                      }
                    }
                    return el
                  })
                )
              }}
              style={{
                marginRight: '8px',
                cursor: 'pointer',
              }}
            />
            <label
              htmlFor='tackStallCheckbox'
              style={{
                fontSize: '14px',
                cursor: 'pointer',
              }}
            >
              Tack Stall
            </label>
          </div>
        </div>
      )}

      {/* DNR Checkbox */}
      {element.isStall && (
        <div className='editor-section'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '5px',
            }}
          >
            <input
              type='checkbox'
              id='dnrCheckbox'
              checked={element.isDnrStall || false}
              onChange={(e) => {
                const isChecked = e.target.checked
                setCanvasElements((prev) =>
                  prev.map((el) => {
                    if (el.id === selectedElement) {
                      return {
                        ...el,
                        isDnrStall: isChecked,
                        // If DNR is checked, ensure tack is unchecked
                        isTackStall: isChecked ? false : el.isTackStall,
                        // Update children to apply the new fill color to the stall
                        children: el.children.map((child) => {
                          if (child.type === 'line') {
                            return {
                              ...child,
                              fill: isChecked
                                ? isStallDNRColor
                                : el.isTackStall && !isChecked
                                ? isStallTackColor
                                : isStallAvailableColor,
                            }
                          }
                          return child
                        }),
                      }
                    }
                    return el
                  })
                )
              }}
              style={{
                marginRight: '8px',
                cursor: 'pointer',
              }}
            />
            <label
              htmlFor='dnrCheckbox'
              style={{
                fontSize: '14px',
                cursor: 'pointer',
              }}
            >
              DNR Stall
            </label>
          </div>
        </div>
      )}

      {element.isStall && <hr className='m-1' style={{borderColor: '#aaaaf6'}} />}

      {/* Text Properties */}
      {!isStandaloneLine && (
        <div className='editor-section' style={{marginBottom: '0px'}}>
          <h5 style={{marginBottom: '2px'}}>
            <Type className='text-gray-600 mb-1 me-1' size={16} /> Text Properties
          </h5>
        </div>
      )}

      {/* Text Edit Tool */}
      {!isStandaloneLine && (
        <div className='editor-section'>
          <h6>Text Content</h6>
          <div>
            <input
              type='text'
              value={initalElementText}
              onChange={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const updatedText = e.target.value
                setInitalElementText(updatedText)
                handleTextUpdate(e.target.value)
              }}
              onBlur={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const updatedText = e.target.value
                handleTextUpdate(updatedText)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation()
                  e.preventDefault()
                  handleTextUpdate(e.target.value)
                }
              }}
              style={{
                width: '100%',
                padding: '3px',
                paddingLeft: '6px',
                paddingRight: '20px', // Make room for arrows
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            />
          </div>
        </div>
      )}

      {/* Font Size Increase Decrease */}
      {!isStandaloneLine && (
        <div className='editor-section '>
          <h6>Font Size</h6>
          <div>
            <input
              type='number'
              min={1}
              value={initialFontSize}
              onChange={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const value = e.target.value
                // Remove any non-digit characters
                const validValue = value.replace(/[^\d]/g, '')
                // Allow empty input during typing
                if (validValue === '') {
                  setInitialFontSize('')
                  return
                }
                let updatedFontSize = parseInt(validValue, 10)
                updatedFontSize = updatedFontSize < 1 ? 1 : updatedFontSize
                setInitialFontSize(updatedFontSize)
                handleFontSizeInputChange(updatedFontSize)
              }}
              onBlur={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const value = e.target.value
                // Allow empty input during typing
                if (value === '') {
                  setInitialFontSize(1)
                  return
                }
                const updatedFontSize = parseInt(e.target.value)
                handleFontSizeInputChange(updatedFontSize)
              }}
              onKeyDown={(e) => {
                // if arrow keys are pressed, up key +1 and down key -1
                if (e.key === 'ArrowUp') {
                  e.stopPropagation()
                  e.preventDefault()
                  const updatedFontSize = parseInt(initialFontSize) + 1
                  setInitialFontSize(updatedFontSize)
                  handleFontSizeInputChange(updatedFontSize)
                } else if (e.key === 'ArrowDown') {
                  e.stopPropagation()
                  e.preventDefault()
                  const updatedFontSize = parseInt(initialFontSize) - 1
                  setInitialFontSize(updatedFontSize)
                  handleFontSizeInputChange(updatedFontSize)
                }
              }}
              step={1}
              style={{
                width: '100%',
                padding: '3px',
                paddingLeft: '6px',
                paddingRight: '6px',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            />
          </div>
        </div>
      )}
      {!isStandaloneLine && <hr className='m-1' style={{borderColor: '#aaaaf6'}} />}

      {/* Show Line Width button only for lines
      {isStandaloneLine && (
        <div className='editor-section '>
          <h5 style={{marginBottom: '2px'}}>
            <Minus className='text-gray-600 mb-1 me-1' size={16} /> Line Width
          </h5>
          <div>
            <input
              type='number'
              min={1}
              value={initialLineWidth}
              onChange={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const value = e.target.value
                // Allow empty input during typing
                if (value === '') {
                  setInitialLineWidth('')
                  return
                }
                const updatedLineWidth = parseInt(e.target.value)
                setInitialLineWidth(updatedLineWidth)
                handleLineWidthChange(updatedLineWidth)
              }}
              onBlur={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const updatedLineWidth = parseInt(e.target.value)
                setInitialLineWidth(updatedLineWidth)
                handleLineWidthChange(updatedLineWidth)
              }}
              onKeyDown={(e) => {
                // if arrow keys are pressed, up key +1 and down key -1
                if (e.key === 'ArrowUp') {
                  e.stopPropagation()
                  e.preventDefault()
                  const updatedLineWidth = parseInt(initialLineWidth) + 1
                  setInitialLineWidth(updatedLineWidth)
                  handleLineWidthChange(updatedLineWidth)
                } else if (e.key === 'ArrowDown') {
                  e.stopPropagation()
                  e.preventDefault()
                  const updatedLineWidth = parseInt(initialLineWidth) - 1
                  setInitialLineWidth(updatedLineWidth)
                  handleLineWidthChange(updatedLineWidth)
                }
              }}
              step={1}
              style={{
                width: '100%',
                padding: '3px',
                paddingLeft: '6px',
                paddingRight: '6px',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            />
          </div>
        </div>
      )} */}

      {/* {isStandaloneLine && <hr className='m-1' style={{borderColor: '#aaaaf6'}} />} */}

      {/* Layering Positions */}
      <div className='editor-section'>
        <h5>
          <Layers className='text-gray-600 mb-1 me-1' size={16} /> Position
        </h5>
        {enablePositionDropDown && showPositionMenu ? (
          <PositionMenu
            elementId={selectedElement}
            onClose={() => {
              setShowPositionMenu(false)
              // Removed setEnablePositionDropDown(false) to keep dropdown enabled
            }}
            onPositionSelect={(position) => {
              setCurrentlySelectedLayeringPosition(position)
            }}
          />
        ) : (
          <button
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setEnablePositionDropDown(true)
              setShowPositionMenu(true)
            }}
            style={{width: '100%', padding: '3px 16px', borderColor: '#d5d5d5'}}
          >
            <span style={{width: '100%', textAlign: 'left'}}>
              {currentlySelectedLayeringPosition === ''
                ? 'Select...'
                : currentlySelectedLayeringPosition}
            </span>
            <ChevronDown size={16} className='text-gray-500' />
          </button>
        )}
      </div>

      <hr className='m-1' style={{borderColor: '#aaaaf6'}} />

      {/* Show Flip buttons only for stalls with correct orientation */}
      {/* Flip Horizontal Flip Vertical */}
      {!isStandaloneLine && !isTextElement && !isBasicShape && (
        <div className='editor-section'>
          <h5>
            <FlipVertical className='text-gray-600 mb-1 me-1' size={16} /> Transform
          </h5>
          <div className='shape-grid' style={{display: 'block'}}>
            {isTopBottomStall && (
              <button
                className='shape-button'
                onClick={handleFlipVertical}
                onMouseDown={(e) => e.stopPropagation()}
                title='Flip Vertical'
                style={{width: '100%', borderColor: '#d5d5d5'}}
              >
                <span style={{marginTop: '0px'}}>Flip Vertically</span>
              </button>
            )}

            {isLeftRightStall && (
              <button
                className='shape-button'
                onClick={handleFlipHorizontal}
                onMouseDown={(e) => e.stopPropagation()}
                title='Flip Horizontal'
                style={{width: '100%', borderColor: '#d5d5d5'}}
              >
                <span style={{marginTop: '0px'}}>Flip Horizontally</span>
              </button>
            )}
          </div>
        </div>
      )}

      {!isStandaloneLine && !isTextElement && !isBasicShape && (
        <hr className='m-1' style={{borderColor: '#aaaaf6'}} />
      )}

      {/* Fill Color */}
      {!isStandaloneLine && (
        <div className='editor-section'>
          <h5>
            <Palette className='text-gray-600 mb-1 me-1' size={16} /> Background Color
          </h5>

          <div style={{width: '100%'}}>
            {/* Div showing currently filled color */}
            <ColorPickerExpanded
              initialColor={
                element.isGroup
                  ? element.children?.[0]?.children?.[0]?.fill || '#e9edef'
                  : element.children?.[0]?.fill || '#e9edef'
              }
              onColorChange={handleFillColorChange}
            />
          </div>
        </div>
      )}

      {!isStandaloneLine && <hr className='m-1' style={{borderColor: '#aaaaf6'}} />}

      {/* Border Customization */}

      <div className='editor-section'>
        <h5 style={{marginBottom: '2px'}}>
          <RectangleHorizontal className='text-gray-600 mb-1 me-1' size={16} />
          Border Properties
        </h5>
      </div>

      <div className='editor-section '>
        <h6>
          <Minus className='text-gray-600 mb-1 me-1' size={16} /> Width
        </h6>
        <input
          type='number'
          value={initialStrokeWidth}
          onChange={(e) => {
            let value = e.target.value

            // Handle empty input
            if (value === '') {
              setInitalStrokeWidth('')
              return
            }

            // Convert to number and apply constraints
            let updatedStrokeWidth = parseInt(value.replace(/[^0-9]/g, ''))

            // Constrain between 1 and 50
            if (updatedStrokeWidth < 1) {
              updatedStrokeWidth = 1
            } else if (updatedStrokeWidth > 50) {
              updatedStrokeWidth = 50
            }

            setInitalStrokeWidth(updatedStrokeWidth)
            handleStrokeWidth(updatedStrokeWidth)
          }}
          onKeyDown={(e) => {
            // if arrow keys are pressed, up key +1 and down key -1
            if (e.key === 'ArrowUp') {
              e.stopPropagation()
              e.preventDefault()
              setInitalStrokeWidth(initialStrokeWidth + 1)
              handleStrokeWidth(initialStrokeWidth + 1)
            }
            if (e.key === 'ArrowDown') {
              e.stopPropagation()
              e.preventDefault()
              setInitalStrokeWidth(initialStrokeWidth - 1)
              handleStrokeWidth(initialStrokeWidth - 1)
            }
          }}
          step={1}
          style={{
            width: '100%',
            padding: '3px',
            paddingRight: '6px',
            paddingLeft: '6px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        />
      </div>

      <div className='editor-section '>
        <h6>
          <Palette className='text-gray-600 mb-1 me-1' size={16} /> Color
        </h6>

        <BorderColorExpandedPallete
          initialColor={element.children?.[0]?.stroke || '#333333'}
          onBorderChange={handleBorderColorChange}
        />
      </div>

      <hr className='m-1' style={{borderColor: '#aaaaf6'}} />

      {/* Other Operations */}
      <div className='editor-section'>
        <div className='shape-grid'>
          <button
            className='shape-button'
            onClick={handleDuplicate}
            onMouseDown={(e) => e.stopPropagation()}
            title='Duplicate'
          >
            <Copy size={24} />
            <span>Duplicate</span>
          </button>
          <button
            className='shape-button'
            style={{color: '#ff4444'}}
            onClick={handleDelete}
            onMouseDown={(e) => e.stopPropagation()}
            title='Delete'
          >
            <Trash2 size={24} />
            <span>Delete</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default memo(ElementSelectionPanel)
