import { AgGridReact } from "ag-grid-react";
import { useIntl } from "react-intl";
import { renderCheckBox } from "../../../../modules/sgl-utils/agGridHelpers";
import { getNarrowHeaderHeight,getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";
import { useCurrencyFormatter } from "../../../../modules/sgl-utils/Formatters";
import { useEffect, useMemo, useRef, useState } from "react";
import './../tab.css'
import axios from "axios";
import { useAppSelector } from "../../../../redux/hooks";
import { useAlert, useFlashAlert } from "../../../../modules/sgl-utils/DialogsProvider";
import { useOutputContext } from "../../../../modules/output-listing/OutputListingContext";
import useFileDownloader from "../../../../modules/hooks/use-file-downloader";
import { addFloatingNumbers } from "../../../../modules/sgl-utils/SglFunctions";
import { usePaymentBatchContext } from "../../PaymentBatchContext";

const CreateBatchGrid = ({ peopleData, setPeopleData, displayLoadingOverlay, selectAllRTO, setSelectAllRTO, getAgingAndRTOSummaryDetails, batchType, feiClassesOnly }) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()
    const containerStyle = useMemo(() => ({ width: '100%', height: '55vh' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const customerID = useAppSelector(state => state.showCompany.company_id);
    const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
    const alertDialog = useAlert()
    const { getEventID } = useOutputContext();
    const flashAlert = useFlashAlert()
    const { startFileDownloader, forceCloseFileDownloader } = useFileDownloader()
    const gridRef = useRef()
    const { taxFormEnabled } = usePaymentBatchContext()

    const defaultColDef = useMemo(() => {
        return {
          minWidth: 110,
          width: 110,
          resizable: true,
          sortable: true,
          suppressMenu: true,
          suppressMovable: true,
          cellStyle: function(params) {
            if (typeof params.value === 'number') {
                return {textAlign: 'center'};
            } else {
              return {textAlign: 'left'};
            }
          },
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerClass: "ag-center-aligned-header",
          singleClickEdit: true,
        };
    }, []);

    useEffect(() => {
        if(displayLoadingOverlay){
            gridRef?.current?.api?.showLoadingOverlay()
        }else{
            gridRef?.current?.api?.hideOverlay()
        }
    }, [displayLoadingOverlay])

    useEffect(() => {
        if(gridRef){
            calculateTotals()
        }
    }, [peopleData])

    function CheckboxHeader({ headerName, onChange, checked }) {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <div className='w-lg-20px form-check-sm form-check-custom py-2'>
                    <input
                        className='form-check-input'
                        type="checkbox"
                        id="checkAll" 
                        checked={checked}
                        onChange={(e) => onChange(e.target.checked)} 
                    />
                </div>
                { headerName.toUpperCase() }
            </div>
        );
    }

    const selectRTO = (params, checkAll, checked) => {
        const updatedData = peopleData.map((record) => {
            if(record?.payment_amount < 0){ // there is no payable amount or tax form memebersip is missing or invalid
                if (!checkAll && record.people_id === params?.node?.data?.people_id) {
                    record['include'] = params?.node?.data?.include;

                    // update setSelectAllRTO to false if one rto is unselected
                    if(!params?.node?.data?.include && selectAllRTO){
                        setSelectAllRTO(false)
                    }
                } else if (checkAll) {
                    record['include'] = checked;
                }
            }else{
                // RTO can't be paid off
                record['include'] = false; 
            }
            
            return record;
        });
    
        gridRef?.current?.api?.setRowData(updatedData)
        setPeopleData(updatedData);
    };

    const columnDefs = [
        { 
            field: 'people_id', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.GRID.COL.ID' }), 
            width: 90,
            hide: true
        },
        { 
            field: 'include',
            cellRenderer: params => params.node.rowPinned ? null : renderCheckBox(params, () => {
                selectRTO(params, false, null)
            }, !params?.node?.data?.canCreateBatch), // disable checkbox if RTO can't be paid off
            minWidth: 55,
            width: 55,
            headerComponentFramework: CheckboxHeader,
            headerComponentParams: {
                headerName: '',
                onChange: (checked) => { 
                    selectRTO(null, true, checked)
                    setSelectAllRTO(checked)
                },
                checked: selectAllRTO
            },
            cellStyle: ({textAlign: 'center'})
        },
        { 
            field: 'fl_name', 
            headerClass: 'ag-center-aligned-header',
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.GRID.COL.NAME' }),
            flex: 2,
            minWidth: 180, 
            cellStyle: function(params) {
                if (params.node.rowPinned) { //Renderer for footer rows
                    return {textAlign: 'center'};
                } else { //Renderer for other rows
                    return {textAlign: 'left'};
                }
            }
        },
        { 
            field: 'rtoSummary.prizeMoneyEarned', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.GRID.COL.PRIZE.MONEY.CREDIT" }),
            minWidth: 125,
            cellRenderer: params => currencyFormatter(Math.abs(params.value)),
            hide: (batchType === 'Balance Refund'), //hide prize money credit column when balance refund batch type selected
            cellStyle: { textAlign: 'right' }
        },
        { 
            field: 'rtoSummary.balanceRefundEarned', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.GRID.COL.BALANCE.REFUND.CREDIT" }),
            minWidth: 150,
            cellRenderer: params => currencyFormatter(Math.abs(params.value)),
            hide: (batchType === 'Prize Money'), //hide Balance Refund credit column when prize money batch type selected
            cellStyle: { textAlign: 'right' }
        },
        { 
            field: 'credit_earned', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.GRID.COL.TOTAL" }),
            minWidth: 120,
            resizable: true,
            cellRenderer: params => currencyFormatter(Math.abs(params.value)),
            hide: (['Balance Refund', 'Prize Money'].includes(batchType)),
            cellStyle: { textAlign: 'right' }
        },
        { 
            field: 'payment_amount', 
            headerName: `${feiClassesOnly ? 'FEI' : ''} ${intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.GRID.COL.CREDITPAYABLE" })}`,
            minWidth: 120,
            cellRenderer: params => currencyFormatter(Math.abs(params.value)),
            cellStyle: { textAlign: 'right' }
        },
        { 
            field: 'rto_balance', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.GRID.COL.BALANCEAFTERPAYMENT" }),
            minWidth: 115,
            cellRendererSelector: (params) => {
                if (params.node.rowPinned) { //Renderer for footer row
                    return null
                } else { //Renderer for other rows
                    return {component: params => currencyFormatter(params.value)}
                }
            },
            cellStyle: { textAlign: 'right' }
        },
        { 
            field: 'status', 
            width: 150,
            flex: 1,
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.GRID.COL.Status" }),
            resizable: true
        },
        // Check the use of this column in 4D
        { 
            field: 'missingTaxForm', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.GRID.COL.RTOMISSINGTAXFORMSTATUS" }),
            width: 250,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            hide: true
        },
        { 
            field: 'prize_preference', 
            width: 160,
            flex: 1,
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.GRID.COL.PAYMENT.TYPE" }),
            resizable: true
        },
    ]

    const rowClassRules = {
        'bg-expired': function(params) {
            return params.node.data?.color === 'red';
        },

        'bg-override': function(params) {
            return params.node.data?.color === 'yellow';
        }
    }

    const calculateTotals = () => {
        let pinnedRows = []
        
        let selectedRTOsTotal = { 
            people_id: -11,
            fl_name: 'Selected RTOs',
            credit_earned: 0, payment_amount: 0, 
            rtoSummary: { prizeMoneyEarned: 0, balanceRefundEarned: 0 }
        }
        let allRTOsTotal = { 
            people_id: -100,
            fl_name: 'All RTOs',
            credit_earned: 0, payment_amount: 0, 
            rtoSummary: { prizeMoneyEarned: 0, balanceRefundEarned: 0 }
        }
        peopleData.map((record) => {
            if(record.include){
                selectedRTOsTotal.credit_earned = addFloatingNumbers([selectedRTOsTotal.credit_earned, record.credit_earned], 2)
                selectedRTOsTotal.payment_amount = addFloatingNumbers([selectedRTOsTotal.payment_amount, record.payment_amount], 2)
                selectedRTOsTotal.rtoSummary.prizeMoneyEarned = addFloatingNumbers([selectedRTOsTotal.rtoSummary.prizeMoneyEarned, record.rtoSummary.prizeMoneyEarned], 2)
                selectedRTOsTotal.rtoSummary.balanceRefundEarned = addFloatingNumbers([selectedRTOsTotal.rtoSummary.balanceRefundEarned, record.rtoSummary.balanceRefundEarned], 2)
            }

            //Add data for second footer having sum of all RTOs in create batch list
            allRTOsTotal.credit_earned = addFloatingNumbers([allRTOsTotal.credit_earned, record.credit_earned], 2)
            allRTOsTotal.payment_amount = addFloatingNumbers([allRTOsTotal.payment_amount, record.payment_amount], 2)
            allRTOsTotal.rtoSummary.prizeMoneyEarned = addFloatingNumbers([allRTOsTotal.rtoSummary.prizeMoneyEarned, record.rtoSummary.prizeMoneyEarned], 2)
            allRTOsTotal.rtoSummary.balanceRefundEarned = addFloatingNumbers([allRTOsTotal.rtoSummary.balanceRefundEarned, record.rtoSummary.balanceRefundEarned], 2)
        })

        if(batchType == 'All'){
            pinnedRows.push(selectedRTOsTotal)
            pinnedRows.push(allRTOsTotal)
        }
        else{
            selectedRTOsTotal.fl_name = ''; //clear heading for label when we are not adding footer row for total
            pinnedRows.push(selectedRTOsTotal)
        }

        gridRef?.current?.api?.setPinnedBottomRowData(pinnedRows)
    }

    const exportRTODataForMissingTaxForm = async () => {

        let peopleWithMissingTaxForm = peopleData.filter((people) => people?.canCreateBatch)
        let peopleIdsWithMissingTaxForm = peopleWithMissingTaxForm.map((people) => people.people_id)

        if(peopleWithMissingTaxForm.length > 0){
            flashAlert({ type: 'info', message: intl.formatMessage({id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.EXPORT.STARTED'}) });
            const settings = {
                url: 'payment-batches/exportRTODataForMissingTaxStatus',
                progressBarTitle: 'Export RTO For Missing TaxForm'
            };

            // Generate Event Id for file buffer response and open event stream two, to receive files from server
            const fileBufferEventId = getEventID(`${settings.progressBarTitle}-filebuffer`);
            startFileDownloader(fileBufferEventId);

            // Send post request to download csv file
            await axios.post(`${process.env.REACT_APP_NEST_API_URL}/${settings.url}`, {
                customer_id: customerID,
                people_ids: peopleIdsWithMissingTaxForm,
                file_buffer_event_id: fileBufferEventId
            })
            .then(() => {
               // Display Flash Message
               flashAlert({ type: 'success', message: intl.formatMessage({id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.EXPORT.COMPLETED'}) });
            })
            .catch(() =>  {
                forceCloseFileDownloader();   
            })
        }
    }

    const handleRowClick = (params) => {
        getAgingAndRTOSummaryDetails(params?.node?.data?.people_id)
    }

    const getRowStyle = (params) => {
        if (params.node.rowPinned) {
            if(params.node.data.people_id == -11){ //selected RTOs footer
                return { fontWeight: "bold" };
            }

            if(params.node.data.people_id == -100){ //all RTOs footer
                return { fontWeight: "bold", fontStyle: "italic" };
            }
        }
    };

    const handleRowDoubleClicked = (row_data) => {
        if(row_data.data.people_id){
            window.open(process.env.PUBLIC_URL+'/people/detail/'+row_data.data.people_id+`?customer_id=${customerID}&selectedTab=2`,'_blank')
        }
    }

    return (
        <div>    
            {/* RTOs count */}
            <div className='row'>
                <div className='col d-flex justify-content-end'>
                    <label className='col-form-label fs-5 py-0 text-end'>
                        {  peopleData?.length  }
                    </label>
                </div>
            </div>
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        rowData={peopleData}
                        columnDefs={columnDefs} 
                        rowStyle={{ fontSize: '12px' }}
                        headerHeight={getNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        stopEditingWhenCellsLoseFocus={true}
                        rowClassRules={rowClassRules}
                        onRowClicked={handleRowClick}
                        suppressMovableColumns={true}
                        rowSelection="single"
                        getRowStyle={params => getRowStyle(params)}
                        suppressScrollOnNewData={true}
                        onRowDoubleClicked={(row_data) => handleRowDoubleClicked(row_data)}
                        overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while RTOs with credit are loading...</span>'}
                    />
                </div>
            </div>
          

            <div className="row">
                <div className="col-lg-8">
                    {
                        taxFormEnabled ? (
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className='col form-check-sm form-check-custom'>
                                        <div className="square-shape-payment-batch"></div>
                                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor='IncludeMissingSSNPrizeMoney'> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.TAXFORMINFORMATIONMISSING.LAB' })}</label>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <label className='col-form-label mx-2 fs-5 py-0' htmlFor='IncludeMissingSSNPrizeMoney' style={{color: '#2274a5', cursor: 'pointer'}} onClick={exportRTODataForMissingTaxForm}> {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CREATE.BATCH.EXPORT.LAB' })}</label>
                                </div>
                                <div className="col-lg-3"></div>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
       
    )
}


export default CreateBatchGrid