/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import { useAppSelector } from '../../../redux/hooks';
import axios from 'axios';
import moment from 'moment';

import ClassGroupsGrid from './ClassGroupsGrid';
import ClassGroupsScheduleGrid from './ClassGroupsScheduleGrid';
import ScheduleBreaksGrid from './ScheduleBreaksGrid';

import { MoveGroup } from './MoveGroup';
import { SignupGroup } from './SignupGroup';
import { History } from './History';
import { SaveSchedule, DeleteClassGroups, RefreshSchedule, PendingSyncStats } from './ScheduleButtons';

import { SchedulerContextProvider } from './SchedulerContext';
import AddEditGroup from './AddEditGroup';
import TotalEntries from './TotalEntries';
import { useAlert } from '../../../modules/sgl-utils/DialogsProvider';
import { PageTitle } from '../../../../_metronic/layout/core';
import AddOfficial from './AddOfficial';
import HelpLink from '../../../modules/components/HelpLink';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const Scheduler = () => {
    const intl = useIntl();
    const [showMoveGroup, setShowMoveGroup] = useState(false)
    const [showSignupGroup, setShowSignupGroup] = useState(false)
    const [showHistory, setShowHistory] = useState(false)
    const [showDates, setShowDates] = useState([]);
    const [showRings, setShowRings] = useState([]);
    // const [selectedDate, setSelectedDate] = useState('');
    // const [selectedRing, setSelectedRing] = useState(0);
    const [selectedDate, setSelectedDate] = useState(() => sessionStorage.getItem("selectedDate") || "");
    const [selectedRing, setSelectedRing] = useState(() => sessionStorage.getItem("selectedRing") || 0);
    const [scheduleStatus, setScheduleStatus] = useState();
    const [currentClassGroup, setCurrentClassGroup] = useState({});
    const [showAddOfficialModal, setShowAddOfficialModal] = useState(false)// show/hide the add official modal
    const [selectedClassGroupIds, setSelectedClassGroupIds] = useState([])// selected class groups
    const classGroupsGridRef = useRef();
    const alertDialog = useAlert();
    const [autoHeight, setAutoHeight] = useState(false)
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const customerID = useAppSelector(state=> state.showCompany.company_id);

    useEffect(() => {
        setupScheduler();
        getUserPrefs();
    }, [currentShowID, customerID]);

    async function setupScheduler() {
        if(!currentShowID || !customerID){ clearSchedulerState(); return false; }

        axios.get(`${NEST_API_URL}/scheduler?show_id=${currentShowID}&customer_id=${customerID}`)
        .then(base_response => {
            if(base_response.data.Days == null || base_response.data.RingxShows == null){ clearSchedulerState(); return false; }
            
            setShowDates(base_response.data.Days);
            setShowRings(base_response.data.RingxShows);

            //Set Show Date
            let showDate = '';
            const sessionDate = sessionStorage.getItem("selectedDate");
            if(sessionDate && Boolean(sessionDate) && base_response.data.Days.includes(sessionDate)){
                showDate = sessionDate;
            }else{
                const currentDate = moment().format("YYYY-MM-DD")
                const isCurrentDatePresent = base_response.data.Days.find((day) => day == currentDate)
                showDate = isCurrentDatePresent ? currentDate : base_response.data.Days[0];
            }
            setSelectedDate(showDate);
            sessionStorage.setItem("selectedDate", showDate);
         
            //Set show ring
            let showRing = -1;
            const showRingIds =  base_response.data.RingxShows.map((rxs) => rxs.ring_id);
            const sessionRing = sessionStorage.getItem("selectedRing");
            if (sessionRing && Boolean(sessionRing) && showRingIds.includes(Number(sessionRing))) {
                showRing = sessionRing;
            }else{
                showRing = base_response.data.RingxShows[0].ring_id;
            }
            setSelectedRing(showRing);
            sessionStorage.setItem("selectedRing", showRing);
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        });
    }

    async function getUserPrefs() {
        axios.get(`${NEST_API_URL}/scheduler/getSchedulerMetadata?customer_id=${customerID}`).then(response => {
            for (const up of response.data?.userPrefs) {
                if (up.prefname === 'scheduler_classgroup_grid_auto_height') {
                    setAutoHeight(up.prefvalue === "0" ? false : true)
                }
            }
        })
    }

    const handleToggle = async (check_value) => {
        let prefName = 'scheduler_classgroup_grid_auto_height';
        let prefValue = (check_value == true) ? "1": "0";
        setAutoHeight(check_value);
        await axios.post(`${NEST_API_URL}/preferences/saveUserPref`, {
            pref_name: prefName,
            pref_value: prefValue,
            customer_id: customerID
        })
    }

    const clearSchedulerState = () => {
        setShowDates([]);
        setShowRings([]);
        setSelectedDate('');
        setSelectedRing(0);
    }

    const displayAddOfficalDialog = () => {
        var selectedRow = classGroupsGridRef?.current?.api?.getSelectedRows()
        let classGroupIds = selectedRow.map((row) => row.class_group_id)
        if(selectedRow && selectedRow.length > 0){
            setSelectedClassGroupIds(classGroupIds)
            setShowAddOfficialModal(true)
        }else{
            alertDialog({message: intl.formatMessage({ id: "SCHEDULER.ERRORMESSAGE.NOCLASSGROUPSELECTED"})})
        }
    }

    const handleDateChange = (e) => {
        const newDate = e.target.value;
        setSelectedDate(newDate);
        sessionStorage.setItem("selectedDate", newDate);
    };

    const handleRingChange = (e) => {
        const newRing = e.target.value;
        setSelectedRing(newRing);
        sessionStorage.setItem("selectedRing", newRing);
    };

  return (
    <>
    <SchedulerContextProvider>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.SIMPLESCHEDULER'})}</PageTitle> */}
      <div className='modal-body py-0 px-4 scheduler bg-white'>
        <form noValidate className='form' onSubmit={e => e.preventDefault()}>
        <HelpLink tooltip="SIMPLESCHEDULER.DETAIL.LINK.HELP" classname="top" positionType="absolute" positionTop='0.5rem' positionRight='1.1rem' />
        <div className='card-body p-0'> {/* Card Body */}
          <div className="form-group row"> {/* Column Group */}
            <div className='col-lg-8'>{/* Column 1 */}
                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-100px' htmlFor='SHOWDATE' data-tooltip-id="SIMPLESCHEDULER.DETAIL.LABEL.SHOWDATE">
                        <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.SHOWDATE'})}</span>
                    </label>

                    <div className='col-lg-4 '>
                        <select
                            id="SHOWDATE"
                            className='form-select  form-select-sm fs-6 min-h-20px py-1'
                            onChange={(e) => handleDateChange(e)}
                            value={selectedDate}
                            >
                            {
                                showDates.map(date =>
                                <option key={date} value={date}>{moment(date).format('dddd, MMMM D, YYYY')}</option>)
                            }
                        </select>
                    </div>

                    <label className='col-lg-2 col-form-label fs-5 py-1 w-50px' htmlFor='RING' data-tooltip-id="SIMPLESCHEDULER.DETAIL.LABEL.RING">
                        <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.RING'})}</span>
                    </label>

                    <div className='col-lg-4 '>
                        <select
                            id="RING"
                            className='form-select  form-select-sm fs-6 min-h-20px py-1'
                            onChange={(e) => handleRingChange(e)}
                            value={selectedRing}
                            >
                            {
                                showRings.map(ring =>
                                <option key={ring.ring_id} value={ring.ring_id}>{(ring?.ring_name ? ring.ring_name : ring?.Ring?.name)} {ring?.Ring?.active ? '' : '[INACTIVE]' }</option>)
                            }
                        </select>
                    </div>

                    <RefreshSchedule />
                </div>

                <div className='row mb-2'>
                    <label className='col-lg-2 col-form-label fs-5 py-1 w-100px' htmlFor='NUMBERENTRIES' data-tooltip-id="SIMPLESCHEDULER.DETAIL.LABEL.NUMBEROFENTRIES">
                        <span className=''>{intl.formatMessage({id: 'SCHEDULER.LABEL.NUMBERENTRIES'})}</span>
                    </label>
                    <div className='col-lg-1 '>
                       <TotalEntries />
                    </div>
                    {/* <div className='col-lg-2 w-125px me-2'>
                        <a href="#" className="btn btn-sm btn-secondary fw-bold h-30px py-1 w-125px align-items-center d-flex justify-content-center px-2" 
                            data-toggle='modal'
                            data-bs-target='#kt_modal_signup_group'
                            onClick={displayAddOfficalDialog}
                        >
                            {intl.formatMessage({id: 'SCHEDULER.BUTTON.ASSIGNOFFICIAL'})}
                        </a>
                    </div> */}
                    <div className='col-lg-2 w-125px me-2'>
                        {/* begin::Filter Button */}
                        <a href="#" className="btn btn-sm btn-secondary fw-bold h-30px py-1 w-125px align-items-center d-flex justify-content-center px-2" 
                            data-toggle='modal'
                            data-bs-target='#kt_modal_signup_group'
                            onClick={() => setShowSignupGroup(true)}
                            data-tooltip-id="SIMPLESCHEDULER.DETAIL.BUTTON.SIGNUPGROUPS"
                            >
                            {intl.formatMessage({id: 'SCHEDULER.BUTTON.SIGNUPGROUP'})}
                        </a>
                        {/* end::Filter Button */}
                    </div>
                    <div className='col-lg-2 w-125px me-2'>
                        {/* begin::Filter Button */}
                        <a href="#" className="btn btn-sm btn-secondary fw-bold h-30px py-1 w-125px align-items-center d-flex justify-content-center px-2" 
                        data-toggle='modal'
                        data-bs-target='#kt_modal_move_group'
                        onClick={() => setShowMoveGroup(true)}
                        data-tooltip-id="SIMPLESCHEDULER.DETAIL.BUTTON.MOVEGROUPS"
                        >
                        {intl.formatMessage({id: 'SCHEDULER.BUTTON.MOVEGROUP'})}
                        </a>
                        {/* end::Filter Button */}
                    </div>
                    <div className='col-lg-2 w-125px me-2'>
                        {<DeleteClassGroups setScheduleStatus={setScheduleStatus}></DeleteClassGroups>}
                    </div>
                    <div className='col-lg-2 w-75px'>
                        {/* begin::Filter Button */}
                        <a href="#" className="btn btn-sm btn-secondary fw-bold h-30px py-1 w-75px align-items-center d-flex justify-content-center px-2" 
                            data-toggle='modal'
                            data-bs-target='#kt_modal_history'
                            onClick={() => setShowHistory(true)}
                            data-tooltip-id="SIMPLESCHEDULER.DETAIL.BUTTON.HISTORY"
                            >
                            {intl.formatMessage({id: 'SCHEDULER.BUTTON.HISTORY'})}
                        </a>
                        {/* end::Filter Button */}
                    </div>
                    <div className='form-check-sm  form-check-custom col me-0 ms-7'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            id='autoHeight'
                            checked={autoHeight}
                            onChange={e => handleToggle(e.target.checked)}
                            />
                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor='autoHeight' data-tooltip-id="SIMPLESCHEDULER.DETAIL.LABEL.AUTOSCROLL">
                        {intl.formatMessage({id: 'SCHEDULER.LABEL.AUTOHEIGHT'})}
                        </label>
                    </div>

                    { (currentClassGroup.cancelled == true) &&
                    <label className='col-lg-5 col-form-label fs-5 py-1 justify-content-end align-self-end' style={{ color: "#e62721" }} data-tooltip-id="SIMPLESCHEDULER.DETAIL.LABEL.CANCELSTATUS">
                       {intl.formatMessage({id: 'SCHEDULER.LABEL.CANCELSTATUS'})}
                    </label>
                    }
                </div>
            </div>

            <div className='col-lg-4'>{/* Column 2 */}
                <fieldset>
                    <div className='row mb-2'>
                        <label className='col-lg-12 col-form-label fs-5 py-0 d-flex'>
                            <span className='w-375px'>
                                <span className="square square-sm w-15px h-15px d-inline-flex align-items-center me-2 border border-dark" style={{backgroundColor:"red"}}>&nbsp;</span>
                                <span className="d-inline-flex align-items-center">{intl.formatMessage({id: 'SCHEDULER.LABEL.RED'})}</span>
                            </span>
                        </label>
                        <label className='col-lg-12 col-form-label fs-5 py-0 d-flex'>
                            <span className='w-375px'>
                                <span className="square square-sm w-15px h-15px d-inline-flex align-items-center me-2 border border-dark" style={{backgroundColor:"green"}}>&nbsp;</span>
                                <span className="d-inline-flex align-items-center">{intl.formatMessage({id: 'SCHEDULER.LABEL.GREEN'})}</span>
                            </span>
                        </label>
                        <label className='col-lg-12 col-form-label fs-5 py-0 d-flex'>
                            <span className='w-375px'>
                                <span className="square square-sm w-15px h-15px d-inline-flex align-items-center me-2 border border-dark" style={{backgroundColor:"yellow"}}>&nbsp;</span>
                                <span className="d-inline-flex align-items-center">{intl.formatMessage({id: 'SCHEDULER.LABEL.YELLOW'})}</span>
                            </span>
                        </label>
                    </div>
                </fieldset>
            </div>
          </div> {/* End Column Group */}

          <div className="form-group row"> {/* Column Group */}
            <div className='col-lg-8'>{/* Column 1 */}
                <div className='row mb-0'>
                    <div className='grid-classgroups'>
                        {<ClassGroupsGrid showDates={showDates} date={selectedDate} showRings={showRings} ring={selectedRing} setScheduleStatus={setScheduleStatus} classGroupsGridRef={classGroupsGridRef} autoHeight={autoHeight}></ClassGroupsGrid>}
                    </div>
                </div>
                <div className='row mb-2 mt-2'>
                    {<AddEditGroup setScheduleStatus={setScheduleStatus} setCurrentClassGroup={setCurrentClassGroup}/>}
                </div>
            </div>
            <div className='col-lg-4'>{/* Column 2 */}
                <fieldset>
                <legend className="fs-6 d-flex fw-bold mb-2">{intl.formatMessage({id: 'SCHEDULER.GROUPLABEL.SCHEDULEBREAKS'})} <b></b></legend>
                    <div className='grid-schedule-breaks'>
                        {<ScheduleBreaksGrid setScheduleStatus={setScheduleStatus}></ScheduleBreaksGrid>}
                    </div>
                </fieldset>

                <fieldset>
                <legend className="fs-6 d-flex fw-bold mb-2">{intl.formatMessage({id: 'SCHEDULER.GROUPLABEL.SCHEDULE'})} <b></b></legend>
                    <div className='grid-schedule'>
                        {<ClassGroupsScheduleGrid setScheduleStatus={setScheduleStatus}></ClassGroupsScheduleGrid>}
                    </div>
                </fieldset>
            </div>
          </div>
        </div>{/* End Card Body */}

        <div className='card-footer py-1 px-0 row d-flex justify-content-between'>   
            <PendingSyncStats></PendingSyncStats>
            <div className='col text-end'>
                <label className='col col-label fw-bold fs-5 py-1 me-2' style={{ color: "#e62721" }}>
                { scheduleStatus == 'Pending' &&
                    intl.formatMessage({id: 'SCHEDULER.STATUS.PENDING'})
                }
                { scheduleStatus == 'Saved' &&
                    intl.formatMessage({id: 'SCHEDULER.STATUS.SAVED'})
                }
                </label>      
                {<SaveSchedule setScheduleStatus={setScheduleStatus}></SaveSchedule>}
            </div>
        </div>

        </form>
      </div>
      { showMoveGroup && 
            <MoveGroup show={showMoveGroup} handleClose={() => setShowMoveGroup(false)}  setScheduleStatus={setScheduleStatus}/>
      }
      {
        showAddOfficialModal && 
        <AddOfficial show={showAddOfficialModal} handleClose={() => setShowAddOfficialModal(false)} classGroupIds={selectedClassGroupIds} setScheduleStatus={setScheduleStatus}/>
      }
      <SignupGroup show={showSignupGroup} handleClose={() => setShowSignupGroup(false)} />
      <History show={showHistory} handleClose={() => setShowHistory(false)} />
      </SchedulerContextProvider>
    </>
  )
}

export {Scheduler}
