import moment from 'moment';
import { findReplaceOrAdd, removeElementFromArray } from '../../../../modules/sgl-utils/SglFunctions';

export const updateMembershipRow = (gridApi, organization, data) => {
    let rowData = []
    gridApi.forEachNode(node => { //Map on Left grid Data
        if(organization == node.data.organization_code.toLowerCase()){
            if (organization == 'norcal' ){ //For Nrcal
                node.data.valid_start = data?.StartDate ?? "" //Update validStart in frontend
                node.data.valid_end = data?.EndDate ?? "" //Update validend in frontend
            }
            else if (organization == 'ec'){ //For EC
                node.data.valid_start = data?.membership_start_date ?? "" //Update validStart in frontend
                node.data.valid_end = data?.membership_end_date ?? "" //Update validend in frontend
            }
        }
        rowData.push(node.data)
    })
    gridApi.setRowData(rowData) //Set Grid after update
}

//Function for Gender Abbreviation
export const gender = (sex) => {
    if (sex == 'G'){
        sex = "Gelding"
    }
    else if (sex == "M"){
        sex = "Mare"
    }
    else if (sex == 'S'){
        sex = "Stallion"
    }
    else{
        sex = sex
    }
    return sex
}

//Function for isNumber valid from string
export const isNumber = (char) => {
    if (typeof char !== 'string') {
      return false;
    }
  
    if (char.trim() === '') {
      return false;
    }
    return !isNaN(char);
}

// 4D-> horse_convertHeight
export const horseConvertHeight = (height, measure) => {
    height = height ? height.toString() : '';
    let result = 0, counter = 0, spaceFound = false, digitPart = 0, fractionPart = "", hands = 0, inches = 0, fraction_numerator = 0, fraction_denominator = 0;
    let digit_part = "", fraction_part = "", extra_part = "", result_unit = "cm";
    
    if (measure === "inches") {
        result_unit = "inches";
    }
    
    // Handle special characters in height input
    // Handle 16:02 as 16 hands 2 inches
    // Handle 16:3 as 16 hands 3 inches
    if (height.includes(":") && !height.includes(".")) {
        height = height.replace(":", " ");
    }
    //Handle 16,3 as 16 hands 3 inches
    if (height.includes(",") && !height.includes(".")) {
        height = height.replace(",", " ");
    }
    
    //fix invalid hands values having invalid space >>>
    //12.13/4 hands -> 12.1 3/4 hands
    const find_slash = height.indexOf("/");
    if (find_slash > 0) {
        const temp = height.split('');
        if (!isNaN(parseInt(temp[find_slash - 1]))) {
            temp.splice(find_slash, 0, ' ');
            height = temp.join('');
        }
    }
    
    //Fetch/Seperate Digit part, fraction Part and extraPart
    for (counter = 0; counter < height.length; counter++) {
        if (!isNaN(height[counter]) || height[counter] === "." || height[counter] === "/") {
            if (!spaceFound) {
                digit_part += height[counter];
            } else {
                fraction_part += height[counter];
            }
        } 
        else {
            if (digit_part !== "") {
                if (height[counter] === " " || height[counter] === "-" || height[counter] === "'") {
                    spaceFound = true;
                }
            }
            extra_part += height[counter];
        }
    }
    //Remove space from etra part
    extra_part = extra_part.trim();
    digitPart = parseFloat(digit_part);
    
    if (digitPart > 0) {
        hands = Math.floor(digitPart);
        inches = parseFloat((digitPart - Math.floor(digitPart)).toFixed(3)) * 10;
        if (hands > 2 && hands <= 24) {
            result = hands * 4;
            // Check if inches less than 4
            if (inches > 0 && inches < 4) {
                result += inches;
            }
            
            if (fraction_part !== "") {
                const find = fraction_part.indexOf("/");
                
                if (find <= 0 || find === fraction_part.length) {
                    fraction_numerator = parseInt(fraction_part);
                    
                    if (fraction_numerator > 0) {
                        result += fraction_numerator;
                    }
                } else {
                    fraction_numerator = parseFloat(fraction_part.substring(0, find));
                    fraction_denominator = parseFloat(fraction_part.substring(find + 1));
                    
                    if (fraction_numerator > 0 && fraction_denominator > 0) {
                        result += fraction_numerator / fraction_denominator;
                    }
                }
            }
        } else if (digitPart <= 2 || extra_part.toLowerCase() === "m" || extra_part.toLowerCase() === "meters" || extra_part.toLowerCase() === "meter") {
            result = digitPart * 39.37;
        } else if (digitPart > 24 && digitPart < 250) {
            result = digitPart / 2.54;
        }
    }
    
    if (result > 0 && result_unit === "cm") {
        result *= 2.54;
    }
    
    result = parseFloat(result.toFixed(2));

    return result;
};

//Function for update membership number on frontend
export const getMembershipNumber = (gridApi, organization, horse) => {
    let rowData = []
        gridApi.forEachNode(node => {
            if(organization == node.data.organization_code){
                if (horse.usea_no){
                    node.data.membership_number = horse?.usea_no ?? ""
                }
                else if (horse.usdf_no){
                    node.data.membership_number = horse?.usdf_no ?? ""
                }
                else if (horse.ec_ppn){
                        node.data.membership_number = horse?.ec_ppn ?? ""
                }
                else if (horse.fei_id){
                    node.data.membership_number = horse?.fei_id ?? ""
                }
            }
            rowData.push(node.data)
        })
        gridApi.setRowData(rowData)
}

// Function for horseType
export const horseType = (height) =>{
    let type = ""
    if (height > 0){
        
        if (height <= 127){  //Small ponies are 12.2 hands (50 inches (130 cm)) and under
            type ="Small Pony"
        }
        else if (height <= 137.16){  //medium ponies are over 12.2 but no taller than 13.2 hands (54 inches (140 cm))
            type ="Medium Pony"
        }
        else if (height <= 147.32){  //large ponies are over 13.2 hands but no taller than 14.2 hands (58 inches)
            type ="Large Pony"
        }
    
        else if (height <= 162.56){  //Small horse - up to 16.0 hands
            type ="Small Horse"
        }
    
        else if (height > 162.56){  //Large horse - exceeding 16.0 hands
            type ="Large Horse"
        }
    return type
    }
}

//Function for competitionYear
export const competitionYear = (horse) =>{
    let competitionYearForDate = ""
        if(horse != ""){
            competitionYearForDate = horse
        }
        else{
            competitionYearForDate = moment()
        }
        if (moment(horse).month() >= 11){ //If month is December
            competitionYearForDate = moment(competitionYearForDate).add(1, "years")
        }
        return competitionYearForDate
}

//Get date from USEF method
export const USEFDate = (horse) =>{ //
    if(horse.usef_status == "Life" && moment(horse.USEFStartDate).isValid()) {
        horse.USEFEndDate = moment(competitionYear(horse.USEFStartDate)).add(10, "years").format("YYYY-MM-DDTHH:mm:SS") //ExpirationDate 
    }
    return moment(horse.USEFEndDate).format("MM/DD/YYYY")
}

//Get horseAge
export const horseAge = (foalDate) =>{
    let horseAges = 0
    if(moment(foalDate).isValid()) {
        let date = new Date(moment(foalDate).year(), "0", "1");
        horseAges = moment().diff(date,"years")
        if (moment(foalDate).add(horseAges + 1, "years") < moment()){
            horseAges = horseAges + 1
        }
        if (horseAges < 1){
            horseAges = 0
        }
    }
    return horseAges
}

//Update row MembershipDates in left bar and object from response
export const updateMembershipRowDates = (gridApi, organization, data, organizationMembers) => {
    let rowData = []
    let startDate = ""
    let endDate = ""
    let gridNode
    gridApi.forEachNode(node => {
        if(organization == node.data.organization_code.toLowerCase()){
            gridNode = node
            if(organization == "usef"){
                startDate = moment(data.USEFStartDate).format("MM/DD/YYYY")
                endDate = USEFDate(data)
            }
            if(organization == "norcal"){
                startDate = moment(data?.StartDate).format("MM/DD/YYYY")
                endDate = moment(data?.EndDate).format("MM/DD/YYYY")
            }
            if(organization == "fei"){
                startDate = moment(data?.membership_start_date??null).format("MM/DD/YYYY")
                endDate = moment(data?.membership_end_date??null).format("MM/DD/YYYY")
            }
            if (moment(startDate).isValid() && moment(endDate).clone().isValid()){
                node.data.valid_start = startDate
                if(node.data.valid_end?.trim() != ""){
                    node.data.valid_end = endDate
                }
                if (node.data.valid_start <= moment() && node.data.valid_end >= moment()){
                    organizationMembers.map(membershipData =>{
                        if (membershipData.organization_code.toLowerCase() == organization){
                            membershipData.is_membershipvalid = true
                        }
                    })
                }
                else{
                    organizationMembers.map(membershipData =>{
                        if (membershipData.organization_code.toLowerCase() == organization){
                            membershipData.is_membershipvalid = false
                        }
                    })
                }
            }
        }
        rowData.push(node.data)
    })
    gridApi.setRowData(rowData)
    return gridNode
}

//Fee_paid_year function
export const feePaidYear = (gridApi, organization, data, organizationMembers) => {
    let rowData = []
    gridApi.forEachNode(node => {
        if(organization == node.data.organization_code.toLowerCase()){
            let date1 = new Date(moment(node.data.valid_start).year(), "0", "1");
            let endDate = new Date(moment(date1).add(1, "years").subtract(1, "days"))
            // if (organization == 'usef' ){
            node.data.valid_start = date1
            node.data.valid_end = endDate
            if (node.data.valid_end >= moment()){
                organizationMembers.map(membershipData =>{
                    if (membershipData.organization_code.toLowerCase() == organization){
                        if (node.data.ec_status == "DECEASED" || node.data.ec_status == "RETIRED"){
                            membershipData.is_membershipvalid = false
                        }
                        else{
                            membershipData.is_membershipvalid = true
                        }
                    }
                })
            }
            // }
        }
        rowData.push(node.data)
    })
    gridApi.setRowData(rowData)
}

//Function for membership ID
export const membershipIDUpdate = (gridApi, organization, data, organizationMembers) => {
    let rowData = []
    gridApi.forEachNode(node => {
        if(organization == node.data.organization_code.toLowerCase()){
            if (node.data?.membership_number == ""){
                let organizationMembershipNumber = 0
                let organizationCode = ""
                if (organization == "usef"){
                    organizationMembershipNumber = data?.Memberships[0]?.MemberNumber
                    organizationCode = "USEF"
                }
                else if (organization == "fei"){
                    organizationMembershipNumber = data?.Memberships[0]?.MemberNumber
                    organizationCode = "FEI"
                }
                else if (organization == "norcal"){
                    organizationMembershipNumber = data.Memberships[0].MemberNumber
                    organizationCode = "Norcal"
                }
                if (organizationMembershipNumber?.trim() != ""){
                    node.data.organization_code = organizationCode
                    node.data.membership_number = organizationMembershipNumber
                    organizationMembers.map(membershipData =>{
                    if (membershipData.organization_code.toLowerCase() == organization){
                        if (organization == data?.Memberships[0].MemberNumber)
                        membershipData.organization_code = organizationCode
                        membershipData.membership_number = organizationMembershipNumber
                    }
                })
                }
            }
        }
        rowData.push(node.data)
    })
    gridApi.setRowData(rowData)
}

// 4D - Horse_HasVerifiedMeasurementCard
export const hasVerifiedMeasurementCard = (card, measurement_override_date) => {
    let flag = false
    if(card && card != '' && isJsonString(card)){
        let measurement_card = JSON.parse(card)
        if(measurement_card['MeasurementCards'] != null && measurement_card['MeasurementCards'].length > 0){
            if(JSON.stringify(measurement_card['MeasurementCards'][0]).includes("Heel") && measurement_override_date >= moment().format('YYYY-MM-DD'))
            {
                flag = true
            }
        }
        
        return flag
    }
}
function isJsonString(str) {
    try {
      var json = JSON.parse(str);
      return (typeof json === 'object');
    } catch (e) {
      return false;
    }
  }

//  4D hasValidLookupOrgMembership
const hasValidLookupOrgMembership = (type, entityID, organizationMembers) => {
    let hasValidMembership = false, membership
    if (entityID > 0 && type == "People"){
        let orgs = ["USEF", "EC", "FEI"]
        let orgsLength = orgs.length;

        for (let row = 0; row < orgsLength && !hasValidMembership; row++){
            let organizationCode = orgs[0]
            if(type=="People" && organizationMembers?.length > 0){
                membership = organizationMembers.find(data => data.organization_code?.toLowerCase() == organizationCode?.toLowerCase())
            }
            if (membership){
                if (moment().isAfter(membership.valid_start) && moment().isBefore(membership.valid_end)){
                    hasValidMembership = true
                }
            }
        }
    }
    return hasValidMembership
}

// 4D rto_ControlLookupFields
export const controlLookupFields = (people, organizationMember) =>{
    let hasValidMembership = false, returnobj= {}
    // let measurement_card = people.usef_response_eti_lookup?JSON.parse(people.usef_response_eti_lookup):''
    if (people.people_id > 0){
        hasValidMembership = hasValidLookupOrgMembership("People", people.people_id, organizationMember)
        if(hasValidMembership && moment(people.dob).isValid()){
            returnobj.disDob = true
        }
        else{
            returnobj.disDob = false
        }
        if(people.rider_status){
            hasValidMembership = hasValidLookupOrgMembership("People", people.people_id, organizationMember)
            if (hasValidMembership  && people.rider_status != ''){
                returnobj.disStatus = true
            }
            else{
                returnobj.disStatus = false
            }
        }
        else{
            returnobj.disStatus = false
        }   
    }
    return returnobj
}

//check is RTO membership override is allow for organization or not
export const allowManualOverrideForRTO = (org_code) => {
    return (org_code === 'USEF' || org_code === 'USHJA' || org_code === 'EC' || org_code === 'JA' || org_code === 'FEI' || org_code === 'SAFESPORT' || org_code === 'EC-Coach')
}

export const deleteMembershipOverride = (membership, membership_override_added, membership_override_removed) => {
    membership_override_added = membership_override_added ?? []
    membership_override_removed = membership_override_removed ?? []
    membership_override_added = removeElementFromArray(membership_override_added, membership.membership_override, 'organization_code')
    membership_override_removed = findReplaceOrAdd(membership_override_removed, membership.membership_override, 'organization_code')
    membership.membership_override = null
    membership.override = false
    membership.memberships_override_id = 0

    return {membership, membership_override_added, membership_override_removed}
}

export const isEcCoachMembershipValidByShowLevel = (membership_number) => {
    // EC-Coach validation rules if ec_rating added in show: 
    // 1- For Gold and Platinum show levels, EC Coach membership is valid if coach status is licensed.
    // 2- For Bronze and Silver show levels, EC Coach membership is valid if coach status is licensed or registered.
    // if(ec_rating){
        if(membership_number){
            return (membership_number.toLowerCase() === "licensed coach") // check with Licensed status only
            // if(ec_rating === 'Platinum' || ec_rating === 'Gold'){
            //     return (membership_number === "Licensed Coach" || membership_number.includes('Active'))
            // }else if(ec_rating === 'Bronze' || ec_rating === 'Silver'){
            //     return (membership_number === "Licensed Coach" || membership_number === "Registered Coach" || membership_number.includes('Active'))
            // }
        }
    // }else{
    //     return true
    // }  
    return false
}

export const isEcMembershipValid = (membership_level, membership_required) => {
    let requiredMemberships = ['Bronze', 'Silver', 'Gold', 'Platinum']
    if (membership_level === "Stable Registration" || membership_level === "Life@" || membership_level === "Corporate/syndicate" || membership_level == "Authorized foreign Competitor") { // valid if value is either of the followingS
        return true
    }else{
        // Find memberships in array
        const requiredMembershipIndex = requiredMemberships.indexOf(membership_required);
        const RTOMembershipIndex = requiredMemberships.indexOf(membership_level);
        // valid if membership_level is same as membership_required or membership_required is greater than or membership_level (e.g if membership_required= Gold and membership_level= Platinum than valid)
        if (requiredMembershipIndex > RTOMembershipIndex) {
          return false
        } else {
            return true
        }
    }
}