import { useEffect, useState, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../redux/hooks';
import { useAlert, useConfirm, useLoadingOverlay} from '../../../../modules/sgl-utils/DialogsProvider';
import { PatternFormat } from 'react-number-format';

import axios from 'axios';

// Custom Components
import { PersonLookupForm } from '../General/PersonLookupForm';
import { SearchInput } from '../SearchInput';
import moment from 'moment';
import { reactSelectStyles } from '../../../../modules/sgl-utils/fieldControls';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import { RolledbackPeoplePaymentsDetail } from './RolledbackPeoplePaymentsDetail';
import { PmrRpChangeDialog } from './PmrRpChangeDialog';
import { PDFDocument } from 'pdf-lib';
import { BankingDetailsDialog } from './BankingDetailsDialog';
import { handleTaxStatusFieldDisable } from '../../../../modules/sgl-utils/SglFunctions';
import useAccessChecker from '../../../../modules/hooks/use-access-checker';

const PrizeAndRecipientSection = (props) => {
    const intl = useIntl();
    const methods = useFormContext()
    const selectRef = useRef();
    const parentGuardianRef = useRef()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()

    const customer_id = useAppSelector(state=> state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const governingOrganization = useAppSelector(state => state.showCompany.governing_organization)
    const company_url_path = useAppSelector(state=> state.showCompany.urlpath)
    const show_financials_locked = methods.watch('show.islocked')
    const currencyFormatter = useCurrencyFormatter()

    const [showPersonLookup, setShowPersonLookup] = useState(false)
    const [searchDisabled, setSearchDisabled] = useState(true)
    const [taxFormStatus, setTaxFormStatus] = useState('') // tax form status/ membership level
    const [taxFormType, setTaxFormType] = useState('') // tax form type/ membership number
    const [isTaxStatusValid, setIsTaxStatusValid] = useState(false) // flag to check if taxform membership is valid or not
    const [displayBankingDetails, setDisplayBankingDetails] = useState(false) // flag to display the bank details dialog
    const [userFormExists, setUserFormExists] = useState(false)

    const [person_search_status, setPersonSearchStatus] = useState('');
    const [search_count_status, setSearchCountStatus] = useState('');
    const [default_org, setDefaultOrg] = useState(governingOrganization);
    const [people_search_list, setPeopleSearchList] = useState([]);
    const [pmrId, setPmrId] = useState(0);
    const [oldPmrId, setOldPmrId] = useState(0);
    const [oldPmrSource, setOldPmrSource] = useState('');
    const [displayPmrChangeConfirmDialog, setDisplayPmrChangeConfirmDialog] = useState(false); // display confirm dialog before pmr change
    const [displayPrizeMoneyRollbackDialog, setDisplayPrizeMoneyRollbackDialog] = useState(false); // display dialog that show which entries balance will be changed with rollback
    const [rolledbackPrizeMoneyDetail, setRolledbackPrizeMoneyDetails] = useState([])
    const [dialogData, setDialogData] = useState({
        first_name: '',
        last_name: '',
        city: '',
        state:'',
        usef_no: 0,
        search_term: '',
        company_name: ''
    })
    const loadingOverlay = useLoadingOverlay()
    const { hasSpecialPermissionToAccess } = useAccessChecker()

    useEffect(() => {
        if(methods.getValues('entry.prizemoneyrecipientsource')){
            // set prize money recipient id and name on load
            if(methods.getValues('entry.prizemoneyrecipientsource') == '2'){
                selectRef.current.value = methods.getValues("entry.prizemoneyrecipientsource")+ '_' + methods.getValues("entry.prizemoneyrecipient_id")
            }else{
                selectRef.current.value = methods.getValues("entry.prizemoneyrecipientsource")
            }
        }

        // Get responsible party source
        // If its '5' which is PMR, then update the RP as well
        if(methods.getValues('entry.responsiblepartysource') == '5'){
            let pmr_id = methods.getValues('entry.prizemoneyrecipient_id');
            methods.setValue('entry.responsibleparty_id', pmr_id);
            methods.setValue('entry.responsibleparty', methods.getValues(`linked_people[${pmr_id}].people_data.fl_name`));
        }
        
        
        // update prize money recipient name and input fields in entry
        let key = `linked_people[${methods.getValues("entry.prizemoneyrecipient_id")}].people_data.`
        methods.setValue('entry.prizemoneyrecipient', methods.getValues(key + "fl_name"))
        methods.setValue('prize_money_recipient_search', methods.getValues(key + "fl_name"), {shouldDirty: true})
        
        methods.setValue('people.social_security_number', methods.getValues(key + "social_security_number"))
        methods.setValue('people.ssn_notes', methods.getValues(key + "ssn_notes"))
        methods.setValue('people.federal_id', methods.getValues(key + "federal_id"))
        methods.setValue('people.foreigner', methods.getValues(key + "foreigner"))
        setPmrId(methods.getValues('entry.prizemoneyrecipient_id'))
        setSearchDisabled(selectRef.current.value !== '4')

        //Note: when pmr is same as RTO and it is foreigner than on RTO change pmr_id is not updated but pmr source is updated to 'Others'. To handle this case prizemoneyrecipientsource is added in depeneency.
    }, [methods.watch("entry.prizemoneyrecipient_id"), methods.watch("entry.prizemoneyrecipientsource")])

    useEffect(() => {
        if(methods.getValues('entry')){
            parentGuardianRef.current.value = ''
            let parentGuardian = createParentGuardianOption()
            if(parentGuardian){
                Object.keys(parentGuardian).map(rto_id => {
                    if(methods.getValues('entry.parent_guardian_id') == rto_id){
                        parentGuardianRef.current.value = methods.getValues('entry.parent_guardian_id')                    }
                })
            }
        }
    }, [methods.getValues('entry.owner'), methods.getValues('entry.trainer'), methods.getValues('entry.prizemoneyrecipient_id')]);

    useEffect(() => {
        props.setLockPrizeMoneyAction(false) // reset the value
    }, [methods.getValues('entry.entry_id')])

    const updatePMR = (field_name, value) => {
        methods.setValue('linked_people['+pmrId+'].people_data.isUpdated', true)
        methods.setValue('linked_people['+pmrId+'].people_data.'+field_name, value)
    }

    const onPrizeRecipientChange = (e) => {
        // set prize money recipient to the value of selected option (id of that people)
        methods.setValue("entry.prizemoneyrecipientsource", e.target.value, {shouldDirty: true})
        switch(e.target.value){
            case '1':
                methods.setValue("entry.prizemoneyrecipient_id", methods.getValues('entry.entryowner_id'), {shouldDirty: true})
                methods.setValue("entry.prizemoneyrecipient", methods.getValues(`linked_people[${methods.getValues('entry.entryowner_id')}].people_data.fl_name`), {shouldDirty: true})
                break

            case '3':
                methods.setValue("entry.prizemoneyrecipient_id", methods.getValues('entry.trainer_id'), {shouldDirty: true})
                methods.setValue("entry.prizemoneyrecipient", methods.getValues(`linked_people[${methods.getValues('entry.trainer_id')}].people_data.fl_name`), {shouldDirty: true})

                break

            
            case '0':
            case '4':
                methods.setValue("entry.prizemoneyrecipient_id", 0, {shouldDirty:true})
                methods.setValue("entry.prizemoneyrecipient", '', {shouldDirty: true})
                break

            default:
                let rider_id = e.target.value.split('_')[1]
                methods.setValue("entry.prizemoneyrecipientsource", e.target.value.split('_')[0], {shouldDirty: true})
                methods.setValue("entry.prizemoneyrecipient", methods.getValues(`linked_people[${rider_id}].people_data.fl_name`), {shouldDirty: true})
                methods.setValue("entry.prizemoneyrecipient_id", rider_id, {shouldDirty: true})
                break
        }
    }

    const prizeRecipientSelect = () => {
        if (methods.getValues("entry")) {
            const entryRiders = methods.getValues("entry.EntryRiders")

            let entryRidersOptions
            if (entryRiders) {
                let riderNumber = 0
                entryRidersOptions = entryRiders.map(
                    (er, idx) =>  {
                        if (!er.isDeleted) {
                        // If rider is not deleted populate the dropdown
                        if(!er.isDeleted){
                            riderNumber++
                            return <option key={riderNumber} value={'2_'+ er.rider_id}>Rider {riderNumber} ({ er.rider_name})</option>
                        }
                    }
                })}

            return (
                <>
                    <select
                        ref={selectRef}
                        className='form-select form-select-sm fs-6 min-h-20px py-1' 
                        onChange={(e) => onPrizeRecipientChange(e)}
                        disabled={show_financials_locked}
                        >
                        <option value='0'>Select...</option>
                        <option value='1'>Owner</option>
                        <option value='3'>Trainer</option>
                        { entryRidersOptions }
                        <option value='4'>Other</option>
                    </select>
                    {/* <Select
                        ref={selectRef}
                        onChange={(e) => onPrizeRecipientChange(e)}
                        defaultValue={entryRiderOptions2[0]}
                        styles={{
                            ...reactSelectStyles,
                            control: (provided) => ({
                                ...provided,
                                fontSize: 12,
                                color: "#3F4254 !important",
                                fontWeight: 'normal',
                                width: '150px',
                                minHeight: '12px',
                            }),
                            indicatorsContainer: (provided, state) => ({
                                ...provided,
                                height: '30px',
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                fontSize: 12,
                                padding: 0,
                                color: state.isSelected ? "#ffffff" : "#3F4254 !important",
                                fontWeight: 'normal',
                                padding: '0 6px'
                            }),
                            valueContainer: (provided, state) => ({
                                ...provided,
                                height: '30px',
                                padding: '0 6px'
                            }),
                        }}
                        options={entryRiderOptions2}
                    /> */}
                </>
            )
        }
    }

    const createParentGuardianOption = () => {
        if (methods.getValues("entry")) {

            let entryRiders = methods.getValues("entry.EntryRiders")?methods.getValues("entry.EntryRiders"):[]
            entryRiders = entryRiders.filter(rider => !rider.isDeleted)
            let rider_ids = entryRiders.map(key => key.rider_id)
            const entry = methods.getValues('entry')

            let new_parent_ids = []

            if(Number(entry?.prizemoneyrecipient_id)){
                new_parent_ids.push(Number(entry?.prizemoneyrecipient_id))
            }
            if(entry?.entryowner_id){
                new_parent_ids.push(entry?.entryowner_id)
            }
            if(entry?.trainer_id){
                new_parent_ids.push(entry.trainer_id)
            }
            if(Number(entry?.responsibleparty_id)){
                new_parent_ids.push(Number(entry.responsibleparty_id))
            }
            if(rider_ids.length > 0){
                new_parent_ids = [...new_parent_ids, ...rider_ids]
            }

            let parentGuardian = {}
            for(let new_parent_id of new_parent_ids){
                parentGuardian[new_parent_id] = methods.getValues(`linked_people[${new_parent_id}].people_data.fl_name`)
            }

            if (entryRiders) {
                entryRiders.map(
                    (er) =>  {
                        // Add rider if is not already deleted or not present in the list1
                        if(!er.isDeleted && !parentGuardian[er.rider_id]){
                            parentGuardian[er.rider_id] = er.rider_name
                        }
                    }
                )
            }

            return parentGuardian
        }
    }

    const parentGuardianSelect = () => {
        if (methods.getValues("entry")) {
            let parentGuardian = createParentGuardianOption()
            // const entryRiders = methods.getValues("entry.EntryRiders")
            // const entry = methods.getValues('entry')

            // let parentGuardian = {}

            // // Set Owner By default
            // parentGuardian[entry.entryowner_id] = entry.owner

            // // Add if trainer, pmr, rp doesn't already exist
            // if(!parentGuardian[entry.trainer_id]){
            //     parentGuardian[entry.trainer_id] = entry.trainer
            // }

            // if(!parentGuardian[entry.responsibleparty_id]){
            //     parentGuardian[entry.responsibleparty_id] = methods.getValues(`linked_people[${entry.responsibleparty_id}].people_data.fl_name`)
            // }

            // if(!parentGuardian[entry.prizemoneyrecipient_id]){
            //     parentGuardian[entry.prizemoneyrecipient_id] = methods.getValues(`linked_people[${entry.prizemoneyrecipient_id}].people_data.fl_name`)
            // }

            // Commented react select options
            // const options = Object.entries(parentGuardian).map(([rtoId, label]) => ({
            //     value: rtoId,
            //     label: label
            // }));
            // options.unshift({value: '', label: 'Select Parent Guardian'})

            return (
                <>
                <select
                    ref={parentGuardianRef}
                    className='form-select form-select-sm fs-6 min-h-20px py-1 w-lg-200px'
                    onChange={(e) => updateParentGuardian(e)}
                    >
                    <option value=''>Select Parent Guardian</option>

                    {Object.keys(parentGuardian).map((rto_id, idx) => {
                            return <option key={idx} value={rto_id}>{parentGuardian[rto_id]}</option>
                        })
                    }
                </select>

                {/* Commented react select */}
                {/* <Select
                    ref={parentGuardianRef}
                    onChange={(e) => updateParentGuardian(e)}
                    defaultValue={options[0]}
                    options={options}
                    styles={{
                        ...reactSelectStyles,
                        control: (provided) => ({
                            ...provided,
                            fontSize: 12,
                            color: "#3F4254 !important",
                            fontWeight: 'normal',
                            width: '180px',
                            minHeight: '12px',
                        }),
                        indicatorsContainer: (provided, state) => ({
                            ...provided,
                            height: '30px',
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            fontSize: 12,
                            padding: 0,
                            color: state.isSelected ? "#ffffff" : "#3F4254 !important",
                            fontWeight: 'normal',
                            padding: '0 6px'
                        }),
                        valueContainer: (provided, state) => ({
                            ...provided,
                            height: '30px',
                            padding: '0 6px'
                        }),
                    }}
                /> */}
                </>
            )
        }
    }

    const updateParentGuardian = (e) => {
        if(e.target.value != ''){
            methods.setValue('entry.parent_guardian_id', e.target.value, {shouldDirty: true})
        } else {
            methods.setValue('entry.parent_guardian_id', 0, {shouldDirty: true})

        }
    }

    
    const onPrizeMoneyActionChange = (e) => {
        if(e.target.value == '1'){
            methods.setValue('prize_money_action', 'Apply')
            applyPrizeMoney()
        } else if(e.target.value == '2'){
            
        } else if(e.target.value == '3'){
            methods.setValue('prize_money_action', 'Rollback')
            rollbackPrizeMoney()
        } else if(e.target.value == '4'){
            
        }

    }

    function applyPrizeMoney(confirmation_needed=true){
        let pmr_id = methods.getValues('entry.prizemoneyrecipient_id')

        let message = ''
        if(!pmrId){
            message = 'Please set Prize Money Recipient for the entry.'
        }

        if(!methods.getValues(`linked_people[${pmr_id}].people_data`)){
            message = 'Prize Money Recipient not found.'
        }

        if(message){
            alertDialog({message})
            return
        }

        axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/applyPrizeMoney', {
            params: {
                customer_id: customer_id,
                entry: methods.getValues('entry'),
                trips: methods.getValues('trips'),
                show_id: methods.getValues('show.show_id'),
                prizemoneyrecipient: methods.getValues(`linked_people[${pmr_id}].people_data`),
                payoffEntry: false, // don't payoff entry when prize money is applied from drop down
                confirmation_needed
            }
        }).then( async response => {
            if(response.data.success){
                if(response.data.check_confirmation){
                    let choice = await confirmDialog({message: response.data.confirm_message})
                    if(choice){
                        applyPrizeMoney(false)
                    }
                } else {
                    if(response?.data?.errors?.length > 0){
                        alertDialog({message: response?.data?.errors, title: 'Error'})
                    }

                    if(response?.data?.noTripWithAppliedPrizeMoney === 0){ 
                        // prize money is not applied to any trip
                        return
                    }

                    props.setLockPrizeMoneyAction(true)
                    methods.setValue('trips', response.data.trips, {shouldDirty: true})
                    methods.setValue('prizeMoneyAppliedTrips', response.data.updatedTripIds, {shouldDirty: true})
                    // entries payoff will create the payment and paymentxentries records
                    if(response.data.paymentRecords){
                        let new_payments = methods.getValues('new_payments')?methods.getValues('new_payments'):[]
                        new_payments.push(...response.data.paymentRecords)
                        methods.setValue('new_payments', new_payments, {shouldDirty: true})
                    }
                    if(response.data.paymentxEntriesRecords){
                        let paymentxEntries = methods.getValues('paymentxEntries')
                        paymentxEntries.push(...response.data.paymentxEntriesRecords)
                        methods.setValue('paymentxEntries', paymentxEntries, {shouldDirty: true})
                    }
                    if(response.data.prizeMoneyPayments && response.data.prizeMoneyPayments.length > 0){
                        let new_prize_money_payments = methods.getValues('new_prize_money_payments')?methods.getValues('new_prize_money_payments'):[]
                        new_prize_money_payments = new_prize_money_payments.concat(response.data.prizeMoneyPayments)
                        methods.setValue('new_prize_money_payments', new_prize_money_payments, {shouldDirty: true})
                    }
                    if(response.data.updatedWithholdingTax && response.data.updatedWithholdingTax.length > 0){
                        let new_withholding_taxes = methods.getValues('new_withholding_taxes')?methods.getValues('new_withholding_taxes'):[]
                        new_withholding_taxes = new_withholding_taxes.concat(response.data.updatedWithholdingTax)
                        methods.setValue('new_withholding_taxes', new_withholding_taxes, {shouldDirty: true})
                    }
                    if(response.data.updatedClassPrizes && Object.keys(response.data.updatedClassPrizes).length > 0){
                        methods.setValue('updated_class_prizes', response.data.updatedClassPrizes, {shouldDirty: true})
                    }

                    // update account register records
                    if(response.data.accountRegisterRecords && response.data.accountRegisterRecords.length > 0){
                        let updated_account_registers = methods.getValues('updated_account_registers')?methods.getValues('updated_account_registers'):[]
                        updated_account_registers = updated_account_registers.concat(response.data.accountRegisterRecords)
                        methods.setValue('updated_account_registers', updated_account_registers, {shouldDirty: true})
                        methods.setValue('accountRegisterRecordsForTrips', methods.getValues('accountRegisterRecordsForTrips')?methods.getValues('accountRegisterRecordsForTrips'):[].concat(response.data.accountRegisterRecords))
                    }

                    // update the account register distributions
                    if(response.data.distributionRecords && response.data.distributionRecords.length > 0){
                        let updated_account_register_distributions = methods.getValues('updated_account_register_distributions')?methods.getValues('updated_account_register_distributions'):[]
                        updated_account_register_distributions = updated_account_register_distributions.concat(response.data.distributionRecords)
                        methods.setValue('updated_account_register_distributions', updated_account_register_distributions, {shouldDirty: true})
                    }

                    let message = ''
                    let totalPayment = 0
                    if(response.data.paymentRecords && response.data.paymentRecords.length > 0){// prize money is applied to the entry
                        totalPayment = response.data.paymentRecords.reduce((val, payment) => val + payment.amount, 0)
                        message += `${currencyFormatter(totalPayment)} Prize Money applied to entry. `
                    }

                    if(response.data.accountRegisterRecords && response.data.accountRegisterRecords.length > 0){
                        let amountMovedToAR = response.data.accountRegisterRecords.reduce((val, ar) => val + (ar.available ?? 0), 0) - totalPayment
                        if(amountMovedToAR > 0){
                            message += `${currencyFormatter(amountMovedToAR)} Prize Money moved to Account Register.`
                        }
                    }

                    if (response.data.prizeMoneySummary) { // Update Prize Money Summary Box
                        methods.setValue('prizeMoneySummary', response.data.prizeMoneySummary)
                    }

                    if(message){
                        alertDialog({message})
                    }
                }    
            } else {
                alertDialog({message: response.data.error_message, title: 'Error'})
            }
        })
        
    }

    let rollbackPrizeMoney = async(confirmation_needed=true) => {
        let pmr_id = oldPmrId
        let prizeMoneyRolledback = false

        await axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/rollbackPrizeMoney', {
            params: {
                customer_id: customer_id,
                entry: methods.getValues('entry'),
                trips: methods.getValues('trips'),
                show_id: methods.getValues('show.show_id'),
                prizemoneyrecipient: methods.getValues(`linked_people[${pmr_id}].people_data`),
                confirmation_needed
            }
        }).then( async response => {
            if(response.data.success){
                if(response.data.check_confirmation){
                    let choice = await confirmDialog({message: response.data.confirm_message})
                    if(choice){
                        rollbackPrizeMoney(false)
                    }
                } else {
                    props.setLockPrizeMoneyAction(true)
                    // update trip data
                    methods.setValue('trips', response.data.trips, {shouldDirty: true})
                    if(methods.getValues('prize_money_action') !== 'PMR Change'){
                        methods.setValue('prizeMoneyRollbackTrips', response.data.updatedTripIds, {shouldDirty: true})
                    }

                    // update payment records
                    if(response.data.payments && response.data.payments.length > 0){
                        let new_payments = methods.getValues('new_payments')?methods.getValues('new_payments'):[]
                        new_payments.push(...response.data.payments)
                        methods.setValue('new_payments', new_payments, {shouldDirty: true})
                    }

                    // update paymentxentries record
                    if(response.data.paymentxEntries && response.data.paymentxEntries.length > 0){
                        let paymentxEntries = methods.getValues('paymentxEntries') ? methods.getValues('paymentxEntries') : []
                        let newPaymentxEntries = response?.data?.paymentxEntries?.filter((pxe) => pxe.entry_id === methods.getValues('entry')?.entry_id)
                        paymentxEntries.push(...newPaymentxEntries)
                        if(methods.getValues('prize_money_action') === 'PMR Change'){
                            let newAmount = -1*newPaymentxEntries.reduce((val, pxe) => val + pxe.amount_applied, 0)
                            methods.setValue('entry.balance', methods.getValues('entry.balance') ? methods.getValues('entry.balance') + newAmount : newAmount)
                        }
                        methods.setValue('paymentxEntries', paymentxEntries, {shouldDirty: true})
                        let paymentxEntriesForOtherEntries = methods.getValues('paymentxEntriesForOtherEntries') ? methods.getValues('paymentxEntriesForOtherEntries') : []
                        let newPaymentxEntriesForOtherEntries = response?.data?.paymentxEntries?.filter((pxe) => pxe.entry_id !== methods.getValues('entry')?.entry_id)
                        paymentxEntriesForOtherEntries.push(...newPaymentxEntriesForOtherEntries)
                        methods.setValue('paymentxEntriesForOtherEntries', paymentxEntriesForOtherEntries, {shouldDirty: true})
                    }
                    if(response.data.deleted_prize_money_payment_ids && response.data.deleted_prize_money_payment_ids.length > 0){
                        let deleted_prize_money_payment_ids = methods.getValues('deleted_prize_money_payment_ids')?methods.getValues('deleted_prize_money_payment_ids'):[]
                        deleted_prize_money_payment_ids = deleted_prize_money_payment_ids.concat(response.data.deleted_prize_money_payment_ids)
                        methods.setValue('deleted_prize_money_payment_ids', deleted_prize_money_payment_ids, {shouldDirty: true})
                    }
                    if(response.data.withHoldingTaxes && response.data.withHoldingTaxes.length > 0){
                        let updated_withholding_taxes = methods.getValues('updated_withholding_taxes')?methods.getValues('updated_withholding_taxes'):[]
                        updated_withholding_taxes = updated_withholding_taxes.concat(response.data.withHoldingTaxes)
                        methods.setValue('updated_withholding_taxes', updated_withholding_taxes, {shouldDirty: true})
                    }

                    // update withholding tax payments
                    if(response.data.updatedWithholdingTaxPayments && response.data.updatedWithholdingTaxPayments.length > 0){
                        let updated_withholding_tax_payments = methods.getValues('updated_withholding_tax_payments')?methods.getValues('updated_withholding_tax_payments'):[]
                        updated_withholding_tax_payments = updated_withholding_tax_payments.concat(response.data.updatedWithholdingTaxPayments)
                        methods.setValue('updated_withholding_tax_payments', updated_withholding_tax_payments, {shouldDirty: true})
                    }

                    // update class prize money
                    if(response.data.updatedClassPrizes && Object.keys(response.data.updatedClassPrizes).length > 0){
                        methods.setValue('updated_class_prizes', response.data.updatedClassPrizes, {shouldDirty: true})
                    }

                    // update account register records
                    if(response.data.updatedAccountRegisterRecords && response.data.updatedAccountRegisterRecords.length > 0){
                        let updated_account_registers = methods.getValues('updated_account_registers')?methods.getValues('updated_account_registers'):[]
                        updated_account_registers = updated_account_registers.concat(response.data.updatedAccountRegisterRecords)
                        methods.setValue('updated_account_registers', updated_account_registers, {shouldDirty: true})
                    }

                    // update account register Items
                    if(response.data.updatedAccountRegisterItems && Object.keys(response.data.updatedAccountRegisterItems).length > 0){
                        methods.setValue('updated_account_register_items', response.data.updatedAccountRegisterItems, {shouldDirty: true})
                    }

                    // update the account register distributions
                    if(response.data.updatedDistributions && response.data.updatedDistributions.length > 0){
                        let updated_account_register_distributions = methods.getValues('updated_account_register_distributions')?methods.getValues('updated_account_register_distributions'):[]
                        updated_account_register_distributions = updated_account_register_distributions.concat(response.data.updatedDistributions)
                        methods.setValue('updated_account_register_distributions', updated_account_register_distributions, {shouldDirty: true})
                    }

                    if (response.data.prizeMoneySummary) {  // Update Prize Money Summary Box
                        methods.setValue('prizeMoneySummary', response.data.prizeMoneySummary)
                    }

                    prizeMoneyRolledback = true
                }    
            } else {
                alertDialog({message: response.data.error, title: 'Error'})
            }
        })

        return prizeMoneyRolledback
        
    }

    const onBlur = (e) => {
        if(e.target.value && e.target.value !=''){
            callSearchPersonApi(e.target.value)
        }
    }

    const onSearch = (e) => {
        if(e.key == 'Enter'){
            e.target.blur();
        }
    }

    // //Function for handling Search Tem
    const handleSearchTerm = (search_term) => {
    
        let firstName = props.getFirstNameFromSearchString(search_term)
        let lastName = props.getLastNameFromSearchString(search_term)
        let searchTerm = firstName != ''? firstName + (lastName != '' ?' ':'')+ lastName : lastName == '' ? '' : lastName
        setDialogData((prevDialogData) => ({...prevDialogData, first_name: firstName, last_name: lastName, search_term: searchTerm}))
    }

    async function callSearchPersonApi(search_term){
        loadingOverlay({show: true})    
        let search_response = await props.callSearchPersonApi(search_term, 'PERSON')
        if (search_response.showPersonLookup){
            setDialogData(search_response.dialog_data)
            setPeopleSearchList(search_response.search_list)
            setSearchCountStatus(search_response.people_count_status)
            setDefaultOrg(search_response.default_org)
            setShowPersonLookup(search_response.showPersonLookup)
        } else {
            setPersonSearchStatus(search_response.search_status)
        }
        loadingOverlay({show: false})
    }

    async function getSelectedPeople(people, organization, person_role){
        let search_status = await props.getSelectedPeople(people, organization, person_role)
        if(search_status){
            setPersonSearchStatus(search_status)
        }
    }

    const getPmrWarning = () => {
        let pmr_id = methods.getValues('entry.prizemoneyrecipient_id')
        let pmr = methods.getValues(`linked_people[${pmr_id}].people_data`)
        let status = ''

        // TODO -- Global variable <>vr_WithholdingTaxRate implementation
        let WithholdingTaxRate = 10

        if(methods.getValues('entryWithholdingTaxApplicable') && pmr?.foreigner && pmr?.tax_applicable_from_date != '0000-00-00'){
            status = `${WithholdingTaxRate}% Withholding Tax applicable from ${moment(pmr?.tax_applicable_from_date).format('MM/DD/YYYY')}`
        }else{
            //if SSN or FIN are empty then display warning, Check if SSN is required for Prize Money and User is Foreign user
            if(methods.getValues('prizeMoneyPrefs.PMR_SSN_Required') && pmr?.federal_id == "" && pmr?.social_security_number == "" && pmr?.foreigner){
                status = intl.formatMessage({id:  "FORM.INPUT.ENTRIES.TAB.CLASSES.PRIZEMONEYRECIPIENT.PMRFOREIGNER"})
            }
        }

        return status;
    }
      
    useEffect(() => {
        if(!methods.getValues('RTO Action')){
            validatePmrChange()
        }
    }, [methods.watch("entry.prizemoneyrecipient_id"), methods.getValues('RTO Action')])

    const validatePmrChange = async() => {
        let trips = methods.getValues('trips')
        trips = trips?.filter((trip) => trip?.prize_money_spent) // trips were prize money is applied
        if(
            oldPmrId && 
            methods.getValues('entry')?.entry_id > 0 && //not new entry
            trips?.length > 0 && // there exists some trip on which prize money is applied
            methods?.formState?.dirtyFields?.entry?.hasOwnProperty('prizemoneyrecipient_id') && // pmr is changed by user
            oldPmrId != methods.getValues('entry.prizemoneyrecipient_id') && // there is some actual change in pmr
            methods.getValues('prize_money_action') !== 'Apply' // if prize money is applied and not saved yet
        ){ 
            methods.setValue('RTO Action', 'PMR is updating')
            setDisplayPmrChangeConfirmDialog(true)
        }else{
            setOldPmrId(methods.getValues('entry.prizemoneyrecipient_id'))
            setOldPmrSource(methods.getValues('entry.prizemoneyrecipientsource'))
        }
    }

    const handleTaxDocumentActions = (action) => {
        if(!methods.getValues('linked_people['+ methods.getValues("entry.prizemoneyrecipient_id") +']')){
            return 
        }
        
        if(['callTaxFormLookup', 'manualRequest'].includes(action)){
            if(action == 'manualRequest'){
                // open the taxform with manual request pre selected
                props.setSelectedTaxFormRequestType('Manual Request')
            }else if(action == 'callTaxFormLookup' && taxFormStatus?.toLowerCase() == 'manual'){
                // request userform for RTO having manual taxform membership
                props.setSelectedTaxFormRequestType('Create Request For Manual Taxform')
            }

            // call taxform lookup and display dialog
            props.setPersonIdForTaxStatusLookup(methods.getValues('entry.prizemoneyrecipient_id'))
            props.setShowTaxInformation(true)
        }else if(action == 'tinCheck'){
            // run tin check and update membership
            props.handleTinStatusCheck(methods.getValues('entry.prizemoneyrecipient_id'))
        }
    }

    const taxFormMembershipExists = () => {
        let taxFormMembership = methods.getValues('linked_people['+ methods.getValues("entry.prizemoneyrecipient_id") +'].membership_data')?.find((item) => item.organization_code == 'Tax Status')
        if(taxFormMembership && 'membership_exist' in taxFormMembership && !taxFormMembership['membership_exist']){
            return false
        }

        return true
    }

    const setStatusForTaxForm = async () => {
        let organizations = methods.getValues('linked_people['+ methods.getValues("entry.prizemoneyrecipient_id") +'].membership_data')
        let taxStatusMembership = organizations ? organizations?.find((item) => item.organization_code == 'Tax Status') : ''
        let status = taxStatusMembership?.membership_level ? taxStatusMembership?.membership_level : ''
        let formType = 'N/A'

        if(!['requested', 'manual'].includes(taxStatusMembership?.membership_number?.toLowerCase())){ 
            // taxform is created and has type
            formType = taxStatusMembership?.membership_number
        }

        // check tax form membership is valid or not
        let isTaxformMembershipValid = false
        let today = moment().format('YYYY-MM-DD');

        // check membership level
        if(['completed', 'manual'].includes(status?.toLowerCase())){

            // check start and end date
            if(taxStatusMembership && taxStatusMembership?.valid_end && taxStatusMembership?.valid_end !='' && taxStatusMembership?.valid_start && taxStatusMembership?.valid_start !=''){
                if(moment(today).isSameOrBefore(moment(taxStatusMembership.valid_end).format('YYYY-MM-DD')) && moment(today).isSameOrAfter(moment(taxStatusMembership.valid_start).format('YYYY-MM-DD'))){ // if valid end is future date
                    isTaxformMembershipValid = true
                }
            }
        }

        setIsTaxStatusValid(isTaxformMembershipValid)
        setTaxFormStatus(status)
        setTaxFormType(formType)
        await getUserSubmissionFormHistory()
        setUserFormExists(!['manual', 'requested'].includes(status?.toLowerCase()) && methods.getValues('linked_people['+ methods.getValues("entry.prizemoneyrecipient_id") +'].people_data.userFormSubmission')?.nextform_submission_id)
    }

    const getPDFLink = async () => {
        // call webservice for RTO with completed forms only
        if(!userFormExists){
            return
        }

        // Show loading overlay
        loadingOverlay({ show: true })

        const response = await axios.get(`${process.env.REACT_APP_NEST_API_URL}/people/getUserSubmissionFormLink`, {
            params: {
                people_id: methods.getValues("entry.prizemoneyrecipient_id"),
                customer_id,
            }
        })

        // Hide loading overlay
        loadingOverlay({ show: false })

        if(response?.data?.success){
            if(response?.data?.file){
                // Load the array buffer into pdf lib document
                const pdfDoc = await PDFDocument.load(response?.data?.file);
                const pdfBytes = await pdfDoc.save();
            
                const blob = await new Blob([pdfBytes], { type: 'application/pdf' });
                const documentBlobObjectUrl = URL.createObjectURL(blob);

                window.open(documentBlobObjectUrl, '_blank');
            }else{
                alertDialog({message: 'Error in fetching Tax Form.'})
            }
        }else{
            // document not found for the RTO
            alertDialog({message: response?.data?.error})
        }
    };

    const getUserSubmissionFormHistory = async() => {
        let organizations = methods.getValues('linked_people['+ methods.getValues("entry.prizemoneyrecipient_id") +'].membership_data')
        let taxStatusMembership = organizations ? organizations?.find((item) => item.organization_code == 'Tax Status') : ''
        let status = taxStatusMembership?.membership_level ? taxStatusMembership?.membership_level : ''

        if(!status || status?.toLowerCase() == 'manual'){
            return
        }

        await axios.post( process.env.REACT_APP_NEST_API_URL + '/people/getUserSubmissionFormHistory', {
            person_id: methods.getValues("entry.prizemoneyrecipient_id"),
            customer_id,
            userFormSubmission: null,
            tax_status: status
        })
        .then(async (response) => {
            methods.setValue('linked_people['+ methods.getValues("entry.prizemoneyrecipient_id") +'].people_data.userFormSubmission', response.data.userFormSubmission)
            methods.setValue('linked_people['+ methods.getValues("entry.prizemoneyrecipient_id") +'].people_data.userFormStatusHistory', response.data.userFormStatusHistory)
        })
        .catch((reason) => {
            
        })
    }

    useEffect(() => {
        setStatusForTaxForm()
    }, [methods.getValues('linked_people['+ methods.getValues("entry.prizemoneyrecipient_id") +'].membership_data')])

    return (
        <>
            <fieldset className='mb-2'>
                {/* <PersonLookupForm 
                    show={showPersonLookup} 
                    handleClose={() => setShowPersonLookup(false)} 
                    fieldName={'prize_money_recipient_search'} 
                /> */}
                {showPersonLookup && <PersonLookupForm 
                    show={showPersonLookup} 
                    handleClose={() => {methods.setValue('prize_money_recipient_search', methods.getValues('entry.prizemoneyrecipient'));setShowPersonLookup(false);}} 
                    customer_id={customer_id} 
                    fieldName={'prize_money_recipient_search'} 
                    dialogData={dialogData}    
                    setDialogData={setDialogData}                 
                    person_search_list={people_search_list}
                    default_org={default_org}
                    person_search_status={search_count_status}
                    type = {'RECIPIENT'}
                    show_id = {currentShowID}
                    handleSearchTerm = {handleSearchTerm}
                    getSelectedPeople = {getSelectedPeople}
                    generateNewRTOId = {props.generateNewRTOId}
                    updatePeople = {props.updatePeople}
                />}

                {
                    (displayPmrChangeConfirmDialog) ? <PmrRpChangeDialog show={displayPmrChangeConfirmDialog} handleClose={() => {methods.setValue('RTO Action', '');setDisplayPmrChangeConfirmDialog(false)}} prevPeopleId={oldPmrId} setPrevPeopleId={setOldPmrId} prevPeopleSource={oldPmrSource} setPrevPeopleSource={setOldPmrSource} setDisplayEntriesChangesDetail={setDisplayPrizeMoneyRollbackDialog} setEntriesAndARChangesDetail={setRolledbackPrizeMoneyDetails} rollbackPeoplePayments={rollbackPrizeMoney} entriesAndARChangesDetail={rolledbackPrizeMoneyDetail} calledFor={'PMR'}/> : null
                }

                {
                    displayPrizeMoneyRollbackDialog && <RolledbackPeoplePaymentsDetail show={displayPrizeMoneyRollbackDialog} handleClose={() => setDisplayPrizeMoneyRollbackDialog(false)} data={rolledbackPrizeMoneyDetail} calledFor={'PMR'}/>
                }

                {
                    displayBankingDetails ? <BankingDetailsDialog show={displayBankingDetails} handleClose={() => setDisplayBankingDetails(false)} pmrId={pmrId} updatePMR={updatePMR}/> : null 
                }
                <legend className="fs-5 fw-bold d-flex entries-search-legend">
                    <div className='d-flex align-self-center min-w-lg-150px'><label data-tooltip-id="ENTRIES.DETAIL.TAB.CLASSES.SECTION.PRIZEMONEYRECIPIENT.LABEL.PRIZEMONEYRECIPIENT">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.GROUPLABEL.PRIZERECIPIENT' })}</label></div>
                    <div className='d-flex align-items-center my-1 w-150px me-3'>
                     { prizeRecipientSelect() }
                    </div>
                    <SearchInput containerClass='me-2 w-225px' onSearch={onSearch} onBlur={onBlur} disabled={searchDisabled ||  show_financials_locked} fieldName={'prize_money_recipient_search'} id={'prize_money_recipient_search'}/>
                    <div className='col-lg-1 d-flex align-items-center w-lg-25px'>
                        {/* <i className="fa-sharp fa-solid fa-circle-info fa-xl py-3 text-white"></i> */}
                        {methods.getValues('entry.prizemoneyrecipient_id') > 0 ?<i className="fa-sharp fa-solid fa-circle-info fa-xl py-3" style={{ color: 'white' }}  onClick ={() =>{props.callbackFromParent(methods.getValues('entry.prizemoneyrecipient_id'), methods.getValues(`linked_people[${methods.getValues('entry.prizemoneyrecipient_id')}]`), "People", "PeopleDetail")}}></i>: null}               
                    </div>
                    <div className='col d-flex align-items-center my-1 justify-content-end ms-2 min-w-lg-400px'>
                        <label className='fs-5 py-1 me-2' htmlFor='PARENTGUARDIAN' data-tooltip-id="ENTRIES.DETAIL.TAB.CLASSES.SECTION.PRIZEMONEYRECIPIENT.LABEL.PARENTGUARDIAN">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.LABEL.PARENTGUARDIAN' })}</label>
                        {/* <select id="RIDER" className='form-select form-select-sm fs-6 min-h-20px py-1 w-lg-175px'>
                            <option value=''>Select</option>
                        </select> */}
                        {parentGuardianSelect()}
                    </div>
                    <b></b>
                </legend>
                <div className='row mb-2'>
                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-100px' htmlFor='SSN' data-tooltip-id="ENTRIES.DETAIL.TAB.CLASSES.SECTION.PRIZEMONEYRECIPIENT.LABEL.SSN">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.LABEL.SSN' })}</label>
                    <div className='col-lg-3'>
                        {/* <input
                            id='SSN'
                            type='text'
                            value={methods.watch(`linked_people[${pmrId}].people_data.social_security_number`)}
                            data-fieldref={'social_security_number'}
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.LABEL.SSN' })}
                            onChange={(e) => {updatePMR(e.target.dataset.fieldref, e.target.value)}}
                        /> */}
                        <PatternFormat
                            id='SSN'
                            value={methods.watch(`linked_people[${pmrId}].people_data.social_security_number`) || ''}
                            onChange={(e) => {updatePMR(e.target.dataset.fieldref, e.target.value.replace(/\D/g, ''))}}
                            format="###-##-####"
                            data-fieldref={'social_security_number'}
                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.LABEL.SSN' })}
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            disabled={handleTaxStatusFieldDisable('social_security_number', methods.watch(`linked_people[${pmrId}].people_data`), hasSpecialPermissionToAccess, props?.form_meta?.isTaxFormLookupEnabled)}
                        />
                    </div>
                    <div className='col-lg-1 w-lg-100px form-check-sm form-check-custom'>
                        <input
                            checked={methods.watch(`linked_people[${pmrId}].people_data.foreigner`)}
                            className='form-check-input'
                            data-fieldref={'foreigner'}
                            type='checkbox'
                            id='FOREIGN'
                            disabled={true}
                            onChange={(e) => {updatePMR(e.target.dataset.fieldref, e.target.value)}}
                        />
                        <label className='col-form-label mx-2 fs-5 py-1' htmlFor='FOREIGN' data-tooltip-id="ENTRIES.DETAIL.TAB.CLASSES.SECTION.PRIZEMONEYRECIPIENT.LABEL.FOREIGN"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.LABEL.FOREIGN' })}</label>
                    </div>
                    <div className='col'>
                        <div className='row justify-content-end'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px' htmlFor='PRIZEMONEYACTION' data-tooltip-id="ENTRIES.DETAIL.TAB.CLASSES.SECTION.PRIZEMONEYRECIPIENT.LABEL.PRIZEMONEYACTION">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.LABEL.PRIZEMONEYACTION' })}</label>
                            <div className='col-lg-6'>
                                <select 
                                    id="PRIZEMONEYACTION" 
                                    className='form-select form-select-sm fs-6 min-h-20px py-1'
                                    disabled={props.lockPrizeMoneyAction || show_financials_locked}
                                    onChange={(e) => onPrizeMoneyActionChange(e)}
                                    value = '0'
                                >
                                    {/* generate and void check options are on hold for now */}
                                    <option value='0'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.PMR.OPTION.SELECT' })}</option>
                                    <option value='1'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.PMR.OPTION.APPLYPRIZEMONEY' })}</option>
                                    {/* <option value='2'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.PMR.OPTION.GENERATECHECKPRIZEMONEY' })}</option> */}
                                    <option value='3'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.PMR.OPTION.ROLLBACKPRIZEMONEY' })}</option>
                                    {/* <option value='4'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.PMR.OPTION.VOIDCHECKPRIZEMONEY' })}</option> */}
                                </select>

                                {/* <Select
                                    inputId='PRIZEMONEYACTION'
                                    onChange={(e) => onPrizeMoneyActionChange(e)}
                                    defaultValue={applyPrizeMoneyOptions[0]}
                                    options={applyPrizeMoneyOptions}
                                    isDisabled={lockPrizeMoneyAction}
                                    styles={reactSelectStyles}
                                /> */}

                                

                            </div>

                        </div>
                    </div>
                </div>

                <div className='row mb-2'>
                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-100px' htmlFor='FEDERALID' data-tooltip-id="ENTRIES.DETAIL.TAB.CLASSES.SECTION.PRIZEMONEYRECIPIENT.LABEL.FEDERALID">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.LABEL.FEDERALID' })}</label>
                    <div className='col-lg-3'>
                        {/* <input
                            value={methods.watch(`linked_people[${pmrId}].people_data.federal_id`) || ''}
                            data-fieldref={'federal_id'}
                            id='FEDERALID'
                            type='text'
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.LABEL.FEDERALID' })}
                            onChange={(e) => {updatePMR(e.target.dataset.fieldref, e.target.value)}}
                        /> */}
                        <PatternFormat
                            id='FEDERALID'
                            value={methods.watch(`linked_people[${pmrId}].people_data.federal_id`) || ''}
                            onChange={(e) => {updatePMR(e.target.dataset.fieldref, e.target.value)}}
                            format="##-#######"
                            data-fieldref={'federal_id'}
                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.LABEL.FEDERALID' })}
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            disabled={handleTaxStatusFieldDisable('federal_id', methods.watch(`linked_people[${pmrId}].people_data`), hasSpecialPermissionToAccess, props?.form_meta?.isTaxFormLookupEnabled)}
                        />
                    </div>
                    <div className='col-lg-1 w-lg-100px form-check-sm form-check-custom'></div>

                    {/* Prize Preference */}
                    <div className='col'>
                        <div className='row justify-content-end'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-150px d-flex align-items-center' htmlFor='prizePreference' style={{paddingRight: '0px'}}>
                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.LABEL.PRIZEPREFERENCE' })}
                                
                                {((
                                    methods.watch(`linked_people[${pmrId}].people_data.prize_preference`) == 'Wire Transfer' 
                                    && 
                                    (
                                        !methods.watch(`linked_people[${pmrId}].people_data.bank_name`)
                                        || 
                                        !methods.watch(`linked_people[${pmrId}].people_data.account_number`)
                                        ||
                                        !methods.watch(`linked_people[${pmrId}].people_data.swift_code`)
                                    )
                                ) || (
                                    ['ACH', 'EFT'].includes(methods.watch(`linked_people[${pmrId}].people_data.prize_preference`))
                                    && 
                                    (
                                        !methods.watch(`linked_people[${pmrId}].people_data.account_number`)
                                        ||
                                        !methods.watch(`linked_people[${pmrId}].people_data.routing_number`)
                                    )
                                )) ? 
                                <>
                                    <span title={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.BANKINFO.WARNING' })}>
                                        <div className='col-lg-1 w-lg-20px d-flex align-items-center fs-7'>
                                        <i className="fas fa-solid fa-triangle-exclamation fa-xl text-warning mx-2"></i>
                                        </div>
                                    </span>
                                </> : null
                                }
                            </label>

                            <div className={['Wire Transfer', 'ACH', 'EFT'].includes(methods.watch(`linked_people[${pmrId}].people_data.prize_preference`)) ? 'col-lg-5' : 'col-lg-6'} style={['Wire Transfer', 'ACH', 'EFT'].includes(methods.watch(`linked_people[${pmrId}].people_data.prize_preference`)) ? {paddingRight: '0px'} : {}}>
                                <select 
                                    id="prizePreference" 
                                    className='form-select form-select-sm fs-6 min-h-20px py-1'
                                    disabled={show_financials_locked}
                                    data-fieldref={'prize_preference'}
                                    onChange={(e) => {updatePMR(e.target.dataset.fieldref, e.target.value)}}
                                    value = {methods.watch(`linked_people[${pmrId}].people_data.prize_preference`) || ''}
                                >
                                    {
                                        props?.form_meta?.prizePreferences?.map((pref) => {
                                            return <option value={pref.value} key={pref.value}>{pref.label}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {
                                ['Wire Transfer', 'ACH', 'EFT'].includes(methods.watch(`linked_people[${pmrId}].people_data.prize_preference`)) ?
                                <div className='col-lg-1 d-flex align-items-center'>
                                    <i className="fa-sharp fa-solid fa-circle-info fa-xl py-3" style={{ color: '#578EBE' }}  onClick ={() =>{setDisplayBankingDetails(true)}}></i> 
                                </div> : null
                            }
                        </div>
                    </div>
                    </div>

                {/* Tax Form Status */}
                {
                    props?.form_meta?.isTaxFormLookupEnabled ?
                    <>
                        <div className='row mb-2'>
                            <label 
                                className='col-lg-1 col-form-label fs-5 py-1 w-lg-100px'
                                htmlFor='taxformStatus'
                            >
                                { `${intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.TAXSTATUS' })}` }
                            </label>

                            <div className='col-3'>
                                <div className='row align-items-center'>
                                    {/* Tax Form Status and Tax Status Form */}
                                    <div className='col d-flex align-items-center'>
                                        <label
                                            className={`col col-form-label fs-5 py-1 ${userFormExists ? 'cursor-pointer text-decoration-underline' : ''}`}
                                            htmlFor='taxStatus'
                                            style={{ color: isTaxStatusValid ? 'green' : 'red' }}
                                        >
                                            <span onClick={getPDFLink}>
                                                {taxFormStatus ? `${taxFormStatus} (${taxFormType})` : 'N/A'}
                                            </span>
                                            {
                                                (!['', 'manual'].includes(taxFormStatus?.toLowerCase()) && methods.getValues('linked_people['+ methods.getValues("entry.prizemoneyrecipient_id") +'].people_data.userFormSubmission')?.sgl_id > 0) ?
                                                <span className='justify-content-center ms-2'>
                                                    <i className={"fas fa-external-link py-0 fs-5 fa-solid cursor-pointer"} onClick={() => window.open(`${process.env.REACT_APP_SGL_ADMIN_URL}/${company_url_path}/electronic-documents/detail/${methods.getValues('linked_people['+ methods.getValues("entry.prizemoneyrecipient_id") +'].people_data.userFormSubmission')?.sgl_id}`, '_blank')}></i>
                                                </span> : null
                                            }
                                        </label>
                                    </div>
                                    
                                    {/* Update Button */}
                                    {/* <div className='col-lg-2 d-flex justify-content-end'>
                                        <label
                                            className='col-form-label fs-5 py-1 cursor-pointer pe-2'
                                        >
                                            <i onClick={() => handleTaxDocumentActions('callTaxFormLookup')} className='far fa-solid fa-rotate text-success fs-3 fw-bold'></i>
                                        </label>
                                    </div> */}
                                </div>
                            </div>

                            {/* TIN Status */}
                            <label
                                className='col-lg-1 col-form-label fs-5 py-1 w-lg-100px'
                                htmlFor='tinStatus'
                            >
                                {intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.TAB.GENERAL.TINSTATUS' })}
                            </label>
                            <div className='col'>
                                <label
                                    className='col col-form-label fs-5 py-1 px-0'
                                    htmlFor='tinStatus'
                                    style={{ color:  methods.getValues(`linked_people[${methods.getValues('entry.prizemoneyrecipient_id')}]`)?.people_data?.tincheck_status?.toLowerCase() == 'verified' ? 'green' : 'red' }}
                                >
                                    {methods.getValues(`linked_people[${methods.getValues('entry.prizemoneyrecipient_id')}]`)?.people_data?.tincheck_status ? methods.getValues(`linked_people[${methods.getValues('entry.prizemoneyrecipient_id')}]`)?.people_data?.tincheck_status : 'N/A'}
                                </label>
                            </div>

                            {
                                props?.form_meta?.isTaxFormLookupEnabled ?
                                    <div className='col-2'>
                                        {/* Tax Document Actions */}
                                        <div className='row'>
                                            <div className='col ps-0'>
                                                <div className='row'>
                                                    <div className='col d-flex align-items-center justify-content-end'>
                                                        <div className='col-lg-1 w-lg-20px d-flex align-items-center'>
                                                            <i className="fa-sharp fa-solid fa-clock fa-xl py-3" style={{ fontSize: "1.2em" }}></i>
                                                        </div>
                                                        <label className='col-form-label fs-6 py-1'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.TAX.DOCUMENT.LABEL.TAX.DOCUMENT'})}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-1 w-20px ps-0 d-flex py-1 align-items-center dropdown'>
                                                { methods.getValues(`linked_people[${methods.getValues('entry.prizemoneyrecipient_id')}]`) ?
                                                <div className="dropdown">
                                                    <a className="fa-sharp fa-solid fa-caret-down fa-lg text-dark" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a>
                                                        <ul className="dropdown-menu dropdown-menu-end mt-2" style={{zIndex: '1021'}}>
                                                            <li className='dropdown-item cursor-pointer' onClick={() => handleTaxDocumentActions('callTaxFormLookup')}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.TAX.DOCUMENT.OPTION.REQUEST' })}</li>
                                                            { 
                                                                taxFormMembershipExists() ? 
                                                                null : 
                                                                <li className='dropdown-item cursor-pointer' onClick={() => handleTaxDocumentActions('manualRequest')}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.TAX.DOCUMENT.OPTION.MANUAL' })}</li>
                                                            }
                                                            <li className='dropdown-item cursor-pointer' onClick={() => handleTaxDocumentActions('tinCheck')}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.TAX.DOCUMENT.OPTION.TIN' })}</li>
                                                        </ul>
                                                </div>
                                                : null }
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                        </div>

                    </> : null
                }
                <div className='row mb-2'>
                    <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-100px' htmlFor='SSN_NOTES' data-tooltip-id="ENTRIES.DETAIL.TAB.CLASSES.SECTION.PRIZEMONEYRECIPIENT.LABEL.NOTES">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.LABEL.NOTES' })}</label>
                    <div className='col'>
                        <input
                            value={methods.watch(`linked_people[${pmrId}].people_data.ssn_notes`) || ''}
                            id='SSN_NOTES'
                            type='text'
                            data-fieldref={'ssn_notes'}
                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.ALLCLASSES.LABEL.NOTES' })}
                            onChange={(e) => {updatePMR(e.target.dataset.fieldref, e.target.value)}}
                        />
                    </div>
                </div>
                {/* Warnings */}
                <div className='row'>
                    <div className='col-lg-6'>
                    {getPmrWarning()? 
                        <div className='row mx-0'>
                            <div className='col-lg-1 w-lg-25px d-flex align-items-center px-0'>
                                <i className="fas fa-xl fa-regular fa-triangle-exclamation text-warning"></i>
                            </div>
                            <label className='col col-form-label fs-6 py-1 text-danger' data-tooltip-id="ENTRIES.DETAIL.TAB.CLASSES.SECTION.PRIZEMONEYRECIPIENT.LABEL.PMRWARNING">{ getPmrWarning()}</label>
                        </div>
                    : null}
                    </div>

                    <div className='col-lg-6 d-flex justify-content-end'>
                        {/* {props.lockPrizeMoneyAction &&
                        <div className='row mb-2 mx-0'> 
                            <div className='col-lg-12 d-flex justify-content-end'> 
                                <div className='col-lg-6 px-0'>
                                    <label className='col-form-label fs-5 py-1 w-lg-500px text-danger text-end'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.PMR.WARNING.LOCKACTION' })}</label>
                                </div>
                            </div>
                        </div>
                        } */}
                        {props.lockPrizeMoneyAction &&
                        <label className='col-form-label fs-5 py-1 w-lg-500px text-danger text-end'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.TAB.PMR.WARNING.LOCKACTION' })}</label>
                        }
                    </div>
                </div>
            </fieldset>
        </>
    );
}

export { PrizeAndRecipientSection }