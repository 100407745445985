import {useMemo, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import BooleanCheckmarkRenderer from '../../../modules/output-listing/renderers/BooleanCheckmarkRenderer'
import OutputListingGridStatic from '../../../modules/output-listing/OutputListingGridStatic'
import {useFormContext} from 'react-hook-form'
import DateRenderer from '../../../modules/output-listing/renderers/DateRenderer'
import TimeRenderer from '../../../modules/output-listing/renderers/TimeRenderer'
import {
  DEFAULT_VISIBLE_ROWS,
  RowSelector,
} from '../../../modules/output-listing/OutputListingRowOptions'
import {useParams} from 'react-router-dom'
import {useAppSelector} from '../../../redux/hooks'
import axios from 'axios'
import {useLoadingOverlay} from '../../../modules/sgl-utils/DialogsProvider'
import {Undo, Trash2} from 'lucide-react'
import {useRef} from 'react'
import useAccessChecker from "../../../modules/hooks/use-access-checker";


const StallsTab = (props) => {
  const intl = useIntl()
  const [gridRef, setGridRef] = useState(false)

  const methods = useFormContext()
  const rowData = methods.getValues('linkedStalls')
  const {barn_id} = useParams()
  const customerId = useAppSelector((state) => state.showCompany.company_id)
  /* WA - Row Selector */
  const [visibleRows, setVisibleRows] = useState(DEFAULT_VISIBLE_ROWS)
  /* WA - Row Selector */

  const loadingOverlay = useLoadingOverlay()

  const activeBarnDesignName = methods.getValues('activeBarnDesignName')

  const { hasSpecialPermissionToAccess } = useAccessChecker();

  const isDeleteUnDeleteAllowed = hasSpecialPermissionToAccess('cloud_barns:barn-designer');

  const columnDefs = [
    {
      field: 'stall_name',
      flex: 1,
      headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.STALLNAME'}),
    },
    {
      field: 'stall_size',
      width: 110,
      headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.SIZE'}),
      cellStyle: {textAlign: 'center'},
    },
    {
      field: 'stall_type',
      width: 110,
      headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.TYPE'}),
      cellStyle: {textAlign: 'center'},
    },
    {
      field: 'is_dnr',
      width: 140,
      headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.DNR'}),
      cellStyle: {textAlign: 'center'},
      cellRenderer: BooleanCheckmarkRenderer,
    },
    
    {
      field: 'is_tack',
      width: 60,
      headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.TACK'}),
      cellStyle: {textAlign: 'center'},
      cellRenderer: BooleanCheckmarkRenderer,
    },
    {
      field: 'active',
      width: 60,
      headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.ACTIVE'}),
      cellStyle: {textAlign: 'center'},
      cellRenderer: BooleanCheckmarkRenderer,
    },
    
    {
      field: 'orientation',
      width: 110,
      headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.ORIENTATION'}),
      cellStyle: {textAlign: 'center'},
    },

    ...(isDeleteUnDeleteAllowed ? [{
      field: 'deleted',
      width: 200,
      headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.DELETEUNDELETE'}),
      cellStyle: {textAlign: 'center'},
      cellRenderer: (params) => {
        // undo discussion needed
        const text = params.value ? 'Undelete' : 'Delete'
        return (
          <div
            onClick={async () => {
              const confirm = window.confirm(
                `Are you sure you want to ${text.toLowerCase()} this stall?`
              )
              if (!confirm) return
              const rowData = params.data
  
              try {
                loadingOverlay({show: true})
  
                // api payload
                const payload = {
                  customerId: customerId,
                  barnId: barn_id,
                  stallId: rowData.sgl_id,
                  isDeleted: !rowData.deleted,
                }
                // now call the api to delete the stall
                const response = await axios.post(
                  process.env.REACT_APP_NEST_API_URL + '/barns/toggleDeleteUnDeleteBarnStall',
                  payload
                )
                if (response.data.success) {
                  // Update the row data
                  params.node.setData({
                    ...rowData,
                    deleted: !rowData.deleted,
                    active: !rowData.deleted ? false : true,
                  })
                  // Force refresh the row to update styling
                  params.api.refreshCells({
                    force: true,
                    rowNodes: [params.node],
                  })
                } else {
                  window.alert('Error in deleting the stall')
                }
              } catch (error) {
                console.log(error)
                window.alert('Error in deleting the stall')
              } finally {
                loadingOverlay({show: false})
              }
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '5px',
              cursor: 'pointer',
            }}
          >
            {params.value ? (
              <Undo size={14} color='#4CAF50' />
            ) : (
              <Trash2 size={12} color='#ff4444' />
            )}
            {text}
          </div>
        )
      },
    }] : []),
    // {
    //   field: 'deleted',
    //   width: 200,
    //   headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.DELETEUNDELETE'}),
    //   cellStyle: {textAlign: 'center'},
    //   cellRenderer: (params) => {
    //     // undo discussion needed
    //     const text = params.value ? 'Undelete' : 'Delete'
    //     return (
    //       <div
    //         onClick={async () => {
    //           const confirm = window.confirm(
    //             `Are you sure you want to ${text.toLowerCase()} this stall?`
    //           )
    //           if (!confirm) return
    //           const rowData = params.data

    //           try {
    //             loadingOverlay({show: true})

    //             // api payload
    //             const payload = {
    //               customerId: customerId,
    //               barnId: barn_id,
    //               stallId: rowData.sgl_id,
    //               isDeleted: !rowData.deleted,
    //             }
    //             // now call the api to delete the stall
    //             const response = await axios.post(
    //               process.env.REACT_APP_NEST_API_URL + '/barns/toggleDeleteUnDeleteBarnStall',
    //               payload
    //             )
    //             if (response.data.success) {
    //               // Update the row data
    //               params.node.setData({
    //                 ...rowData,
    //                 deleted: !rowData.deleted,
    //                 active: !rowData.deleted ? false : rowData.active,
    //               })
    //               // Force refresh the row to update styling
    //               params.api.refreshCells({
    //                 force: true,
    //                 rowNodes: [params.node],
    //               })
    //             } else {
    //               window.alert('Error in deleting the stall')
    //             }
    //           } catch (error) {
    //             console.log(error)
    //             window.alert('Error in deleting the stall')
    //           } finally {
    //             loadingOverlay({show: false})
    //           }
    //         }}
    //         style={{
    //           display: 'flex',
    //           alignItems: 'center',
    //           justifyContent: 'center',
    //           gap: '5px',
    //           cursor: 'pointer',
    //         }}
    //       >
    //         {params.value ? (
    //           <Undo size={14} color='#4CAF50' />
    //         ) : (
    //           <Trash2 size={12} color='#ff4444' />
    //         )}
    //         {text}
    //       </div>
    //     )
    //   },
    // },
    // {
    //   field: 'created_by_name',
    //   width: 200,
    //   headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.CREATEDBY'}),
    //   cellStyle: {textAlign: 'center'},
    // },
    // {
    //   field: 'created_date',
    //   width: 140,
    //   headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.CREATEDDATE'}),
    //   cellStyle: {textAlign: 'center'},
    //   cellRenderer: DateRenderer,
    // },
    // {
    //   field: 'created_time',
    //   width: 140,
    //   headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.CREATEDTIME'}),
    //   cellStyle: {textAlign: 'center'},
    //   cellRenderer: TimeRenderer,
    // },
    // {
    //   field: 'modified_by_name',
    //   width: 200,
    //   headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.MODIFIEDBY'}),
    //   cellStyle: {textAlign: 'center'},
    // },
    // {
    //   field: 'modified_date',
    //   width: 140,
    //   headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.MODIFIEDDATE'}),
    //   cellStyle: {textAlign: 'center'},
    //   cellRenderer: DateRenderer,
    // },
    // {
    //   field: 'modified_time',
    //   width: 140,
    //   headerName: intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.STALLS.MODIFIEDTIME'}),
    //   cellStyle: {textAlign: 'center'},
    //   cellRenderer: TimeRenderer,
    // },
  ]

  const containerStyle = useMemo(() => ({width: '100%', height: '320px'}), [])

  // Reason Adding this
  // After adding the design thumbnail, the page was not scrolling to the top instead
  // it was scrolling to the bottom of the page. So, added this ref to scroll to top
  // in this way scroll is maintained.
  const topRef = useRef(null)
  useEffect(() => {
    // Scroll to top when component mounts
    topRef.current?.scrollIntoView({behavior: 'smooth'})
  }, [])

  return (
    <>
      <div ref={topRef} className='row mb-2'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {activeBarnDesignName !== undefined && activeBarnDesignName !== '' ? (
            <div>
              Active Design Name: <strong>{activeBarnDesignName}</strong>
            </div>
          ) : (
            <strong>Currently no active design.</strong>
          )}
          <div style={{display: 'flex', justifyContent: 'right'}}>
            <div className='me-4'>{rowData !== undefined ? rowData.length : ''}</div>
            <div>
              <RowSelector
                value={visibleRows}
                onChange={setVisibleRows}
                area={'Barns - Stalls Tab'}
              />
            </div>
          </div>
        </div>{' '}
        <div className='col-lg d-flex align-items-center mb-2'>
          <OutputListingGridStatic
            area={'Barns - Stalls Tab'}
            setGridRef={setGridRef}
            columnDefs={columnDefs}
            rowData={rowData}
            containerStyle={containerStyle}
            visibleRows={visibleRows}
            getRowStyle={(params) => {
              if (params.data.deleted) {
                return {background: '#ff8c81'} // light red background
              }
              return null // default background
            }}
          ></OutputListingGridStatic>
        </div>
      </div>

    </>
  )
}

export {StallsTab}
