import { useIntl } from 'react-intl'
import { ShowSection } from '../ShowSection';
import { reactSelectStyles } from '../../../../modules/sgl-utils/fieldControls';
import Select from 'react-select'; 
import RequestedPaymentsGrid from './RequestedPaymentsGrid';
import PostedPaymentsGrid from './PostedPaymentsGrid';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import axios from 'axios';
import { useAlert, useConfirm, useLoadingOverlay, useFlashAlert } from '../../../../modules/sgl-utils/DialogsProvider';
import { useNavigate } from 'react-router';
import { NumericFormat } from 'react-number-format';
import { useOutputContext } from '../../../../modules/output-listing/OutputListingContext';
import useFileDownloader from '../../../../modules/hooks/use-file-downloader';
import PaymentQBExport from './PaymentQBExportDialog';
import { usePaymentBatchContext } from '../../PaymentBatchContext';
import { addFloatingNumbers, loadingSpinnerBtnRelease, loadingSpinnerBtnWait } from '../../../../modules/sgl-utils/SglFunctions';
import useAccessChecker from '../../../../modules/hooks/use-access-checker';
import { useLoadReportAndPrint } from '../../../reports/ReportTypeModal';
import PostPaymentsTabButtons from './PostPaymentsTabButtons';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import { getRowData, updateAGGridWithoutFlickering } from '../../../../modules/sgl-utils/agGridHelpers';
import { HistoryTab } from '../../../../modules/components/HistoryTab';

const PostPaymentsTab = () => {
    const intl = useIntl();
    const {selectedShow, setSelectedShow, selectedBatch, setSelectedBatch, updateCreateBatchTabData, setUpdateCreateBatchTabData, closePaymentBatchDetail, updatePostPaymentsTabData, refreshPaymentBatch, openedFrom}= usePaymentBatchContext()
    const customerID = useAppSelector(state => state.showCompany.company_id);
    const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
    const alertDialog = useAlert()
    const navigate = useNavigate()
    const confirmDialog = useConfirm()
    const { getEventID } = useOutputContext();
    const flashAlert = useFlashAlert()
    const { startFileDownloader, forceCloseFileDownloader } = useFileDownloader()
    const { hasSpecialPermissionToAccess } = useAccessChecker();

    const [batchListForShow, setBatchListForShow] = useState([]) // options for batch drop down
    const [postedPayments, setPostedPayments] = useState([])
    const [pendingPayments, setPendingPayments] = useState([])
    const [startingCheckNo, setStartingCheckNo] = useState(0)
    const [searchedBatchNo, setSearchedBachNo] = useState(0)
    const [checkAll, setCheckAll] = useState(false)
    const [displayPaymentQBExportForm, setDisplayPaymentQBExportForm] = useState(false)
    const [eventIDQBExportForm, setEventIDQBExportForm] = useState(null)
    const [displayLoadingOverlay, setDisplayLoadingOverlay] = useState(false)
    const [reversedAmountData, setReversedAmountData] = useState([]) // store check and there reversed amount
    const [displayReverseAmountDialog, setDisplayReverseAmountDialog] = useState(false) // display dialog for reversed amount
    const [batchInformation, setBatchInformation] = useState(null)
    const [historyLogs, setHistoryLogs] = useState([]) // history logs for selected batch
    const [historyLogGridApi, setHistoryLogGridApi] = useState(null)
    const {loadReportsAndPrint, loadSinglePageReportsandPrint} = useLoadReportAndPrint();
    const loadingOverlay = useLoadingOverlay();
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const currencyFormatter = useCurrencyFormatter()

    useEffect(() => {
        let startingCheckNo = document.getElementById('startingCheckNo')
        if (startingCheckNo) {
            startingCheckNo.focus()
            startingCheckNo.select();
        }     
    }, [])

    useEffect(() => {
        updatePaymentBatchList()
    }, [selectedShow])

    useEffect(() => {
        // open the 'unposted check' or 'posted check' tab based on data
        getUpdatedPaymentsData(true)
    }, [selectedBatch])

    useEffect(() => {
        // retain the currently selected tab
        getUpdatedPaymentsData()
    }, [refreshPaymentBatch])

    useEffect(() => {
        updatePaymentBatchList()
        setSelectedShow(updatePostPaymentsTabData.show_id)
        setSelectedBatch(updatePostPaymentsTabData.batch_id)
    }, [updatePostPaymentsTabData])

    // update Batch summary whenever posted or unposted payments are updated
    useEffect(() => {
        let pendingChecks = pendingPayments?.map((payment) => payment.amount)
        let postedChecks = postedPayments?.map((payment) => payment.amount)
        let voidChecks = postedPayments?.filter((payment) => payment.void_check)?.map((payment) => payment.amount)
        let nonVoidChecks = postedPayments?.filter((payment) => !payment.void_check)?.map((payment) => payment.amount)
        setBatchInformation({
            totalChecks: pendingPayments?.length + postedPayments?.length,
            totalPayments: addFloatingNumbers([...pendingChecks, ...nonVoidChecks], 2),
            postedChecks: postedChecks?.length,
            postedPayments: addFloatingNumbers([...postedChecks], 2),
            unpostedChecks: pendingPayments?.length,
            unpostedPayments: addFloatingNumbers([...pendingChecks], 2),
            voidChecks: voidChecks?.length,
            voidPayments: addFloatingNumbers([...voidChecks], 2)
        })
    }, [pendingPayments, postedPayments])

    const updatePaymentBatchList = () => {
        setBatchListForShow([])
        if(selectedShow){
            //update the batch drop down options
            axios.get(`${NEST_API_URL}/payment-batches/getPaymentBatchesForShow?show_id=${selectedShow}&customer_id=${customerID}`)
            .then(response => {
                if(response.data.success){
                    let paymentBatchOptions = response?.data?.paymentBatches?.map((batch) => ({
                        value: batch.payment_batch_id,
                        label: batch.batchno,
                    }));
                    paymentBatchOptions?.unshift({value: '', label: 'Select'});
                    setBatchListForShow(paymentBatchOptions ?? [])
                }
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            })
        }
    }

    const getUpdatedPaymentsData = (changeTab=false) => {
        // update the posted and pending payments arrays
        if(selectedBatch && selectedBatch != 0 && selectedShow){
            setDisplayLoadingOverlay(true)
            axios.get(`${NEST_API_URL}/payment-batches/getPostedAndPendingPaymentsForBatch?show_id=${selectedShow}&customer_id=${customerID}&payment_batch_id=${selectedBatch}`)
            .then(response => {
                if(response.data.success){
                    //switch tab between Unposted and posted check based on available data
                    if((response.data.postedPayments.length > 0 || response.data.pendingPayments.length > 0) && changeTab){
                        // if pending or posted payments is found 
                        if(response.data.pendingPayments.length === 0){
                            // if pending payments is not found then set the tab to posted
                            setSelectedTab(1)
                        }else{
                            // if pending payments is found then set the tab to unposted
                            setSelectedTab(0)
                        }
                    }

                    setPostedPayments(response.data.postedPayments ?? [])
                    // check all the pending payments if the checkAll checkbox is by default selected
                    response.data.pendingPayments = response?.data?.pendingPayments?.map((payment) => {
                        payment['include'] = checkAll
                        return payment
                    })
                    setPendingPayments(response.data.pendingPayments ?? [])

                    // history logs for current batch
                    setHistoryLogs(response.data.historyLogs ?? [])
                }
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            })
            .finally(() => {
                setDisplayLoadingOverlay(false)
            })
        }else{
            clearData()
        }
    }

    /*
    Update the Payment Batch Item when data is changed
    Cases:
    1- Autofill Check Number (Pending Payments) 
    2- Change in Check Number through ag grid (Pending and Posted Payments)
    3- Change in Payment Type, Description through ag grid (Pending Payments)
     */
    const updatePaymentBatchItems = (batchItems, action, property, updatedValue, oldValue, gridRef, event=null) => {
        if(batchItems.length > 0 && action && selectedShow && selectedBatch){

            if(event){
                loadingSpinnerBtnWait(event)
            }
            axios.post( process.env.REACT_APP_NEST_API_URL + '/payment-batches/updatePaymentBatchItem', {
                show_id: selectedShow,
                customer_id: customerID,
                payment_batch_items_ids: batchItems,
                payment_batch_id: selectedBatch,
                property,
                value: updatedValue,
                action
            }).then(response => {
                if(response.data.success){
                    if(action === 'autoFillChecks'){
                        setPendingPayments((prevState) => {
                            return response.data.pendingPayments.map((item)=> {
                                let oldBatchItem = pendingPayments.find((payment) => payment.payment_batchitems_id === item.payment_batchitems_id)
                                if(oldBatchItem){
                                    item['include'] = oldBatchItem.include
                                }
                                return item
                            })
                        })
                    }

                // history logs for current batch
                // setHistoryLogs(response.data.historyLogs ?? [])   
                updateAGGridWithoutFlickering({current: {api: historyLogGridApi}}, response.data.historyLogs)
                }
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }

                // revert the changes made
                let gridData = getRowData(gridRef?.current?.api)
                for(let data of gridData){
                    if(data.payment_batchitems_id == batchItems[0]){
                        if(action != 'updateBankingInfo'){
                            data[property] = oldValue
                        }else{
                            data = {...data, ...oldValue}
                        }
                    }
                }
                updateAGGridWithoutFlickering(gridRef, gridData)
                
            }).finally(() => {
                if(event){
                    loadingSpinnerBtnRelease(event)
                }
            })
        }
    }

    const clearData = () => {
        setPostedPayments([])
        setPendingPayments([])
        setStartingCheckNo(0)
    }

    // Search for Payment Batch against number search if found display its data
    const searchOnBatchNo = (event) => {
        let displayError = true
        if(searchedBatchNo > 0){
            loadingSpinnerBtnWait(event)
            axios.get(`${NEST_API_URL}/payment-batches/getPaymentBatch?payment_batch_id=${searchedBatchNo}&customer_id=${customerID}`)
            .then(response => {
                if(response.data.success){
                    if(response.data.paymentBatch){
                        let paymentBatch = response.data.paymentBatch
                        // Display Flash Message
                        flashAlert({ type: 'success', message: `Batch #${paymentBatch?.payment_batch_id} found.` });
                        setSelectedShow(paymentBatch?.show_id)
                        setSelectedBatch(paymentBatch?.payment_batch_id)
                        displayError = false
                        setSearchedBachNo(0)
                        navigate('/payment-batches/detail/'+paymentBatch?.payment_batch_id+`?customer_id=${customerID}&batch_show_id=${paymentBatch?.show_id}`)
                    }
                }
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({icon: "stop", message: error.response.data.error});
                    displayError = false
                }
            }).finally(() => {
                if(displayError){
                    alertDialog({icon: "stop", message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.ERROR' })})
                }
                loadingSpinnerBtnRelease(event)
            })
        }
    }

    const voidPostedChecks = (event) => {
        let selectedPostedPayments = postedPayments.filter((payment)=> payment.include)
        if(selectedPostedPayments?.length > 0){
            confirmDialog({ message: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.VOID.CHECK.CONFIRM"})})
            .then((choice) => {
                if(choice){
                    loadingSpinnerBtnWait(event)
                    let batchItems = selectedPostedPayments.map((payment) => { return payment.payment_batchitems_id })
                    axios.post( process.env.REACT_APP_NEST_API_URL + '/payment-batches/voidPostedPayments', {
                        customer_id: customerID,
                        payment_batch_items_ids: batchItems,
                        payment_batch_id: selectedBatch,
                        show_id: selectedShow
                    }).then(response => {
                        if(response.data.success){
                            // Display Flash Message
                            flashAlert({ type: 'success', message: ` ${batchItems.length} ${intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.FLASHMESSAGE.VOIDCHECK"})}` });
                            // refresh Create Batch and Post Payments Tabs data
                            getUpdatedPaymentsData()
                            setUpdateCreateBatchTabData(!updateCreateBatchTabData)
                            // TODO: Don't update aging data
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            alertDialog({message: error.response.data.error});
                        }
                    }).finally(() => {
                        loadingSpinnerBtnRelease(event)
                    })
                }
            })

        }else{
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.ERROR.NO.PAYMENT.SELECTED.VOID' })})
        }
    }
    
    const exportBatchCSV = async (event) => {
        if (selectedBatch) {
            if (postedPayments?.length !== 0 || pendingPayments?.length !== 0) {
                // Payment batch has some posted/non-posted items so generate export
                const settings = {
                    url: 'payment-batches/exportPaymentBatchCSV',
                    progressBarTitle: 'Export Payment Batch'
                };
    
                // Generate Event Id for file buffer response and open event stream two, to receive files from server
                const fileBufferEventId = getEventID(`${settings.progressBarTitle}-filebuffer`);
                startFileDownloader(fileBufferEventId);
    
                // Send post request to download csv file
                loadingSpinnerBtnWait(event)
                await axios.post(`${process.env.REACT_APP_NEST_API_URL}/${settings.url}`, {
                    customer_id: customerID,
                    show_id: selectedShow,
                    payment_batch_id: selectedBatch,
                    file_buffer_event_id: fileBufferEventId
                })
                .then(() => {
                   // Display Flash Message
                   flashAlert({ type: 'success', message: intl.formatMessage({id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.EXPORT.COMPLETED'}) });
                })
                .catch(() =>  {
                    forceCloseFileDownloader();   
                })
                .finally(() => {
                    loadingSpinnerBtnRelease(event)
                });
            } else {
                // Payment batch has no posted/non-posted items
                alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.ERROR2.EXPORT.CSV' }) });
            }
        } else {
            alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.ERROR1.EXPORT.CSV' }) });
        }
    };

    const exportToQB = async (event) => {
        if (selectedBatch) {
            let selectedPendingPayments = pendingPayments.filter((payment) => payment.include)
            if (postedPayments?.length !== 0 || selectedPendingPayments?.length !== 0) {
                loadingSpinnerBtnWait(event)
                axios.get(`${NEST_API_URL}/payment-batches/getRequiredPreferencesForQBExport?customer_id=${customerID}`)
                .then(response => {
                    if(response.data.success){
                        if(response.data.bankAccountPref === ''){ // User has already added account information in System Preferences
                            alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.ERROR.MISSING.BANKACCOUNT' }) });
                        }else if(response.data.prizeMoneyAccountPref === ''){ // User has already added Prize Money account information in System Preferences
                            alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.ERROR.MISSING.PRIZEMONEYACCOUNT' }) });
                        }else{
                            setEventIDQBExportForm(event)
                            setDisplayPaymentQBExportForm(true)
                        }
                    }
                })
                .catch((error) => {
                        if (error.response) {
                            alertDialog({message: error.response.data.error});
                        }
                })
                .finally(() => {
                    loadingSpinnerBtnRelease(event)
                })
            } else {
                if(postedPayments?.length === 0 && pendingPayments?.length == 0){
                    // Payment batch has no posted/non-posted items
                    alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.ERROR2.EXPORT.CSV' }) });
                }else{
                    // When no pending payment is selected
                    alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.ERROR3.EXPORT.CSV' }) });
                }       
            }
        } else {
            alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.ERROR1.EXPORT.CSV' }) });
        }
    }

    const getPaymentsSum = (paymentStatus) => {
        let payments = []
        if(paymentStatus === 'pending'){
            payments = pendingPayments
        }else if(paymentStatus === 'posted'){
            payments = postedPayments
        }

        let total = 0
        payments?.map((data) => {
            //Added if condition to filter out amount for selected pending payments only
            //Added if condition to filter out amount for voided check
            if((data.include && paymentStatus === 'pending') || (!data.void_check && paymentStatus === 'posted')){
                total = total + data.amount
            }
        })
        return total
    }

    const exportBusinessCentralFiles = async (event) => {
        loadingSpinnerBtnWait(event)
        // We will export RTO data for the posted payments only
        if (postedPayments?.length > 0) {
            const fileBufferEventId = getEventID('export-business-central-files');
            startFileDownloader(fileBufferEventId);

            // Send call to nest api and get export
            axios.post(process.env.REACT_APP_NEST_API_URL + `/payment-batches/exportBusinessCentralFiles`, {
                customer_id: customerID,
                show_id: selectedShow,
                payment_batch_id: selectedBatch,
                file_buffer_event_id: fileBufferEventId
            })
            .then(async response => {
                if(response.data.success){
                    // Display Flash Message
                    flashAlert({ type: 'success', message: intl.formatMessage({id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.EXPORT.COMPLETED'}) });
                }else{
                    alertDialog({ message: response.data.error })
                }
            })
            .catch((error) => {
                // Do Nothing
            })
            .finally(() => {
                loadingSpinnerBtnRelease(event)
            })
        }
    }

    // Print reports based on preferences, RTO reports take RTO Ids, and invocie report sends in entry ids as record_ids and is called from printinvoice function
    const printReports = async (event, prefName, recordIds=[]) => {
        // Get ids of RTOs from grid
        loadingSpinnerBtnWait(event)
        if (['RtoPaymentSummaryReportId', 'RtoPaymentSummaryWithTaxDetailReportId'].includes(prefName)) {
            // We do not need record ids for payment batch report, we will get data using batch id
            recordIds = selectedBatch
        } else if (prefName == 'PaymentStatementReportId') {
            if (pendingPayments.length > 0) {
                recordIds = pendingPayments.map((payment) => payment.People.people_id)
            }

            if (postedPayments.length > 0) {
                recordIds = [...recordIds, ...postedPayments.map((payment) => payment.People.people_id)]
            }
        } else if (prefName == 'BatchPrintCheckReport') {
            let selectedPendingPayments = pendingPayments.filter((payment) => payment.include).map((payment) => payment.payment_batchitems_id)
            let selectedPostedPayments = postedPayments.filter((payment) => payment.include).map((payment) => payment.payment_batchitems_id)
            recordIds = [...selectedPendingPayments, ...selectedPostedPayments]
        }

        loadingOverlay({ show: true, message: `Loading Report...` })

        if (recordIds.length == 0) {   //  safety check
            loadingSpinnerBtnRelease(event);
            loadingOverlay({ show: false })
            return false;
        }

        await axios.post(process.env.REACT_APP_NEST_API_URL + '/reports/getPrefReportForPrinting', {
            pref_name: prefName,
            customer_id: customerID,
            show_id: selectedShow, 
            params: {ids: [recordIds], payment_batch_id: selectedBatch}
        })
            .then(async (response) => {
                if (response?.data?.success) {
                    const report = response?.data?.report;
                    report.report_definition = JSON.parse(report?.report_definition);

                    // Load report to ARJS and the print
                    await loadReportsAndPrint(report);
                } else {
                    alertDialog({ message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS..ALERT.REPORTNOTFOUND' }) })
                }
                loadingOverlay({show: false})
            })
            .catch((error) => {
                loadingOverlay({show: false})
            })
        
        loadingSpinnerBtnRelease(event);
    }

    // Get entry ids for this batch item first, if there are entries then print invoice for those entries
    const printInvoice = async (event) => {
        loadingSpinnerBtnWait(event)
        loadingOverlay({ show: true, message: `Loading Report...` })

        let entryIds = [];

        // Get entry ids 
        // TODO: maybe add it in metadata api
        await axios.get(`${NEST_API_URL}/payment-batches/getPaymentBatchEntries?customer_id=${customerID}&show_id=${selectedShow}&payment_batch_id=${selectedBatch}`)
            .then(response => {
                if (response.data.success) {
                    entryIds = response.data.entryIds;
                } else {
                    alertDialog({message: response.data.error})
                }
            })
            .catch((error) => {
                console.log(error)
            })

        if (entryIds.length > 0) {
            await printReports(event, 'Default_Invoice', entryIds);
        } else {    // If no entries for this batch
            alertDialog({ message: 'No entries found.' });
        }
        loadingSpinnerBtnRelease(event);
    }

    // post the pending checks
    const postPendingPaymentsToAccount = (event) => {
        let paymentBatchItemsToBePosted = pendingPayments.filter((payment) => payment.include);
        if (paymentBatchItemsToBePosted?.length > 0) {
            confirmDialog({
                message: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.CONFIRM.MESSAGE.POSTTOACCOUNT" })
            }).then((choice) => {
                if (choice) {
                    loadingSpinnerBtnWait(event)
                    let batchItems = paymentBatchItemsToBePosted.map((payment) => { return payment.payment_batchitems_id })
                    axios.post( process.env.REACT_APP_NEST_API_URL + '/payment-batches/postPendingPayments', {
                        customer_id: customerID,
                        payment_batch_items_ids: batchItems,
                        show_id: selectedShow,
                        batch_id: selectedBatch,
                        update_batch_item_data: false
                    }).then(response => {
                        if(response.data.success){
                            if(response?.data?.checkWithReversedAmount?.length > 0){
                                setReversedAmountData(response?.data?.checkWithReversedAmount)
                                setDisplayReverseAmountDialog(true)
                            }

                            // Display Flash Message
                            flashAlert({ type: 'success', message: ` ${batchItems.length} ${intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.FLASHMESSAGE.POSTTOACCOUNT" })}` });
                            // refresh Create Batch and Post Payments Tabs data
                            setUpdateCreateBatchTabData(!updateCreateBatchTabData)
                            getUpdatedPaymentsData(true)
                            setSelectedTab(1) // move to Posted Payments Tab
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            alertDialog({message: error.response.data.error});
                        }
                    })
                    .finally(() => {
                        loadingSpinnerBtnRelease(event)
                    })
                }
            });
        } else {
            alertDialog({
                message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.ERROR.POSTTOACCOUNT' })
            });
        }
    };

    // delete the pending checks
    const deletedSelectedPendingPayments = (event) => {
        let paymentBatchItemsToBeDeleted = pendingPayments.filter((payment) => payment.include)
        if(paymentBatchItemsToBeDeleted?.length > 0){
            confirmDialog({ message: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.CONFIRM.MESSAGE.DELETE"}) +" "+ paymentBatchItemsToBeDeleted.length +" selected payment"+(paymentBatchItemsToBeDeleted?.length > 1 ? 's' : '')+' ?'})
            .then((choice) => {
                if(choice){
                    loadingSpinnerBtnWait(event)
                    let batchItems = paymentBatchItemsToBeDeleted.map((payment) => { return payment.payment_batchitems_id })
                    axios.post( process.env.REACT_APP_NEST_API_URL + '/payment-batches/deletePendingPayments', {
                        customer_id: customerID,
                        payment_batch_items_ids: batchItems,
                        payment_batch_id: selectedBatch
                    }).then(response => {
                        if(response.data.success){
                            // Display Flash Message
                            flashAlert({ type: 'success', message: ` ${batchItems.length} ${intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.FLASHMESSAGE.DELETE" })}` });
                            // refresh Create Batch and Post Payments Tabs data
                            setUpdateCreateBatchTabData(!updateCreateBatchTabData)
                            getUpdatedPaymentsData()
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            alertDialog({message: error.response.data.error});
                        }
                    })
                    .finally(() => {
                        loadingSpinnerBtnRelease(event)
                    })
                }
            })

        }else{
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.ERROR.NO.PAYMENT.SELECTED.DELETE' })})
        }
    }

    const tabs = ["PENDINGPAYMENTS", "POSTEDPAYMENTS", "HISTORY"]
    const [selectedTab, setSelectedTab] = useState(0)
    const componentTags = [
        <RequestedPaymentsGrid pendingPayments={pendingPayments} setPendingPayments={setPendingPayments} updatePaymentBatchItems={updatePaymentBatchItems} getRequestedPaymentsSum={() => getPaymentsSum('pending')} displayLoadingOverlay={displayLoadingOverlay} getUpdatedPaymentsData={getUpdatedPaymentsData} checkAll={checkAll} setCheckAll={setCheckAll} closePaymentBatchDetail={closePaymentBatchDetail} setSelectedTab={setSelectedTab} reversedAmountData={reversedAmountData} setReversedAmountData={setReversedAmountData} displayReverseAmountDialog={displayReverseAmountDialog} setDisplayReverseAmountDialog={setDisplayReverseAmountDialog}/>,
        <PostedPaymentsGrid postedPayments={postedPayments} setPostedPayments={setPostedPayments} updatePaymentBatchItems={updatePaymentBatchItems} getSumForPostedPayments={() => getPaymentsSum('posted')} displayLoadingOverlay={displayLoadingOverlay} printReports={printReports} printInvoice={printInvoice} batchListForShow={batchListForShow} exportToQB={exportToQB} exportBatchCSV={exportBatchCSV} hasSpecialPermissionToAccess={hasSpecialPermissionToAccess} voidPostedChecks={voidPostedChecks} closePaymentBatchDetail={closePaymentBatchDetail}/>,
        <HistoryTab area={"PaymentBatches"} rowData={historyLogs} setGridRef={setHistoryLogGridApi}/>
    ]
    
    return (
        <>
            {displayPaymentQBExportForm ? <PaymentQBExport show={displayPaymentQBExportForm} handleClose={() => setDisplayPaymentQBExportForm(false)} postedPayments={postedPayments} pendingPayments={pendingPayments} getPaymentsSum={getPaymentsSum} eventIDQBExportForm={eventIDQBExportForm} setEventIDQBExportForm={setEventIDQBExportForm}/> : null }
            <div className='row mb-2'>
                <div className='col-lg-8'>
                    <ShowSection tabName={"POSTPAYMENTS"} />
                    {/* Batch Select */}
                    <div className='row mb-2'>
                        <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 label-width-130 pe-0' htmlFor='Batch'  >{intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.BATCH' })}</label>
                            <div className='col mw-450px'>
                                <Select 
                                    options={batchListForShow}
                                    id="Batch"
                                    value={batchListForShow?.find((option) => option.value == selectedBatch) ?? {value: '', label: 'Select'}}
                                    onChange={(e) => {
                                        let value = e.value ? e.value : 0
                                        setSelectedBatch(value)
                                        navigate('/payment-batches/detail/'+value+`?customer_id=${customerID}&batch_show_id=${selectedShow}`)
                                    }}
                                    styles={reactSelectStyles}
                                    isDisabled={!(openedFrom === 'Add New')} // disable select if payment batch is opened from listing
                                />
                            </div>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className='row mb-2 mt-2 d-flex justify-content-between align-items-center'>
                        <div className='d-flex'>
                            <label className='col-lg-4 d-flex justify-content-end col-form-label fw-bold fs-5 py-1 pe-3' data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.LABEL.ENTERBATCHNO">
                                {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.ENTERBATCHNO' })}
                            </label>

                            <div className='col-lg-4 d-flex justify-content-end px-1'>
                                <NumericFormat
                                    id='searchedBatchNo'
                                    allowNegative={false}
                                    decimalScale={0}
                                    value={Number(searchedBatchNo)}
                                    type='text'
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    onValueChange={(e) => {
                                        setSearchedBachNo(e.value);
                                    }}
                                />
                            </div>
                            
                            <div className='col-lg-4 d-flex justify-content-end w-150px'>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-secondary fw-bold h-30px py-1 w-150px'
                                    onClick={(e) => searchOnBatchNo(e)}
                                    data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.BUTTON.SEARCH"
                                >
                                    <span className='spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1' role='status' aria-hidden='true'></span>
                                    {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.BUTTON.SEARCH' })}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-2'></div>
                        <div className='col-10'>
                            {/* RTO Summary Separator */}
                            <label className='fs-7 rto-summary separator-heading mt-1'>
                                {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.POSTED.BATCH.SUMMARY' })}
                            </label>

                            {/* Unposted Checks */}
                            <div className='row align-items-center justify-content-end'>
                                <div className='col-6'>
                                    <label className='fs-7'>
                                        {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.UNPOSTED.BATCH.CHCK' })}
                                    </label>
                                </div>
                                <div className='col-3'>
                                    <span className='fs-7 text-end'>
                                        {batchInformation ? batchInformation.unpostedChecks : 0}
                                    </span>
                                </div>
                                <div className='col-3'>
                                    <span className='fs-7 d-flex justify-content-end'>
                                        {`${currencyFormatter(batchInformation ? batchInformation.unpostedPayments : 0)}`}
                                    </span>
                                </div>
                            </div>

                            {/* Posted Checks */}
                            <div className='row align-items-center justify-content-end'>
                                <div className='col-6'>
                                    <label className='fs-7'>
                                        {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.POSTED.BATCH.CHECK' })}
                                    </label>
                                </div>
                                <div className='col-3'>
                                    <span className='fs-7 text-end'>
                                        {batchInformation ? batchInformation.postedChecks : 0}
                                    </span>
                                </div>
                                <div className='col-3'>
                                    <span className='fs-7 d-flex justify-content-end'>
                                        {`${currencyFormatter(batchInformation ? batchInformation.postedPayments : 0)}`}
                                    </span>
                                </div>
                            </div>

                            {/* Total Checks */}
                            <div className='row align-items-center justify-content-end'>
                                <div className='col-6'>
                                    <label className='fs-7'>
                                        {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.BATCH.TOTAL.NO.CHECK' })}
                                    </label>
                                </div>
                                <div className='col-3'>
                                    <span className='fs-7 text-end'>
                                        {batchInformation ? batchInformation.totalChecks : 0}
                                    </span>
                                </div>
                                <div className='col-3'>
                                    <span className='fs-7 d-flex justify-content-end'>
                                        {`${currencyFormatter(batchInformation ? batchInformation.totalPayments : 0)}`}
                                    </span>
                                </div>
                            </div>

                            {/* Void Checks */}
                            <div className='row align-items-center justify-content-end'>
                                <div className='col-6'>
                                    <label className='fs-7'>
                                        {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.LABEL.POSTED.VOID.CHECK' })}
                                    </label>
                                </div>
                                <div className='col-3'>
                                    <span className='fs-7 text-end'>
                                        {batchInformation ? batchInformation.voidChecks : 0}
                                    </span>
                                </div>
                                <div className='col-3'>
                                    <span className='fs-7 d-flex justify-content-end'>
                                        {`${currencyFormatter(batchInformation ? batchInformation.voidPayments : 0)}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className='card-body pt-0 pb-3' style={{ height: "100%" }}> {/* Card Body */}
                        
                <div className='d-flex flex-column my-2 border p-2 bg-white' style={{ height: "100%", position: "relative" }}>
                    <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist">
                    {
                        tabs.map((tab, index) => 
                            <li key={`${tab}-${index}`} className='nav-item' style={{lineHeight: "16px",fontSize: "13px", paddingBottom: "1px", backgroundColor:"#2274a5"}} onClick={() => setSelectedTab(index)}>
                                <a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == selectedTab ? ' active' : '')}
                                    tabIndex="-1"
                                    id={tab + "-tab"}
                                    data-bs-toggle='tab'
                                    href={"#" + tab + "-PaymentBatches" }>{`${intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.' + tab })} ${tab != 'HISTORY' ? (index == 0 ? (pendingPayments && pendingPayments.length > 0 ? `(${pendingPayments.length})` : '') : (postedPayments && postedPayments.length > 0 ? `(${postedPayments.length})` : '')) : ''}`}
                                </a>
                            </li>
                        )
                    }
                    </ul>
                
                    <div className="tab-content  mt-3 pb-2" id="myTabContent" style={{ height: "100%" }}>
                        {    
                            tabs.map((tab, index) => {
                                return (
                                    <div className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab + "-PaymentBatches"} role="tabpanel" style={{ height: "100%" }}>
                                        {
                                            <>
                                            {componentTags[index]}
                                            <PostPaymentsTabButtons postedPayments={postedPayments} getRequestedPaymentsSum={() => getPaymentsSum('pending')} postPendingPaymentsToAccount={postPendingPaymentsToAccount} deletedSelectedPendingPayments={deletedSelectedPendingPayments} printReports={printReports} printInvoice={printInvoice} batchListForShow={batchListForShow} exportToQB={exportToQB} exportBatchCSV={exportBatchCSV} hasSpecialPermissionToAccess={hasSpecialPermissionToAccess} voidPostedChecks={voidPostedChecks} closePaymentBatchDetail={closePaymentBatchDetail} tab={tab} exportBusinessCentralFiles={exportBusinessCentralFiles}/>
                                            </>
                                        }
                                    </div>
                                );
                            }) 
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export { PostPaymentsTab }