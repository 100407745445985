import { useIntl } from "react-intl";

const PostPaymentsTabButtons = ({ postedPayments, getRequestedPaymentsSum, postPendingPaymentsToAccount, deletedSelectedPendingPayments, printReports, printInvoice, batchListForShow, exportToQB, exportBatchCSV, hasSpecialPermissionToAccess, voidPostedChecks, closePaymentBatchDetail, tab, exportBusinessCentralFiles }) => {
    const intl = useIntl()

    return (
        <div className="row mb-2">
            <div className="col"></div>

            <div className='row mt-3 mx-0 px-0'>
                <div className='col'>
                    {
                        tab === 'PENDINGPAYMENTS' && 
                        <>
                        {/* Post To Account */}
                        <button type='button' className="btn btn-sm btn-secondary fw-bold text-uppercase" disabled={(getRequestedPaymentsSum() == 0)} onClick={(e) => postPendingPaymentsToAccount(e)}  data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.BUTTON.POSTTOACCOUNT">
                        <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                        { intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.BUTTON.POST.TO.ACCOUNT' }) }
                        </button>

                        {/* Delete */}
                        <button type='button' className="btn btn-sm btn-secondary fw-bold text-uppercase ms-2 button-no-wrap" disabled={(getRequestedPaymentsSum() == 0)} onClick={(e) => deletedSelectedPendingPayments(e)}  data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.BUTTON.DELETE">
                        <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                        { intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.REQUESTED.PAYMENTS.GRID.BUTTON.DELETE' }) }
                        </button>

                        </>
                    }

                    {
                        tab === 'POSTEDPAYMENTS' &&
                        <>
                        {/* Void Checks */}
                        <button type='button' className="btn btn-sm btn-secondary fw-bold text-uppercase" disabled={!(postedPayments?.find((payment) => payment.include) || !hasSpecialPermissionToAccess('cloud_payment-batches:void-payment'))} onClick={(e) => voidPostedChecks(e)}  data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.BUTTON.VOIDSELECTEDCHECK">
                            <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                            { intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.BUTTON.VOID.SELECTED.CHECK' }) }
                        </button>

                        </>
                    }

                    {
                       tab != 'HISTORY' ?
                       <>
                            {/* Print Statement */}
                            <button onClick={(e) => printReports(e, 'PaymentStatementReportId')} type='button' className="btn btn-sm btn-secondary fw-bold text- ms-3" disabled={(batchListForShow?.length < 2)}  data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.BUTTON.PRINTSTATEMENT">
                                <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1" role="status" aria-hidden="true"></span>
                                { intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.BUTTON.PRINT.STATEMENT' }) }
                            </button>

                            {/* Print Summary */}
                            <button onClick={(e) => {printReports(e, 'RtoPaymentSummaryReportId')}} type='button' className="btn btn-sm btn-secondary fw-bold text-uppercase ms-2" disabled={(batchListForShow?.length < 2)}  data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.BUTTON.PRINTSUMMARY">
                                <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1" role="status" aria-hidden="true"></span>
                                { intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.BUTTON.PRINT.SUMMARY' }) }
                            </button>

                            {/* Print Summary with Tax Detail */}
                            <button onClick={(e) => {printReports(e, 'RtoPaymentSummaryWithTaxDetailReportId')}} type='button' className="btn btn-sm btn-secondary fw-bold text-uppercase ms-2" disabled={(batchListForShow?.length < 2)}>
                                <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1" role="status" aria-hidden="true"></span>
                                { intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.BUTTON.TAX.DETAIL' }) }
                            </button>

                            {/* Print Invoice */}
                            <button onClick={(e) => printInvoice(e)} type='button' className="btn btn-sm btn-secondary fw-bold text-uppercase ms-2" disabled={(batchListForShow?.length < 2)}  data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.BUTTON.PRINTINVOICE">
                                <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1" role="status" aria-hidden="true"></span>
                                { intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.BUTTON.PRINT.INVOICE' }) }
                            </button>

                            {/* Print Batch Check */}
                            <button 
                                onClick={(e) => printReports(e, 'BatchPrintCheckReport')} 
                                type='button' 
                                className="btn btn-sm btn-secondary fw-bold text-uppercase ms-2" 
                                disabled={(tab == 'PENDINGPAYMENTS') ? getRequestedPaymentsSum() == 0 : !(postedPayments?.find((payment) => payment.include))}
                            >
                                <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1" role="status" aria-hidden="true"></span>
                                { intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.BUTTON.PRINT.CHECK' }) }
                            </button>

                            {/* Export To QB */}
                            <button type='button' className="btn btn-sm btn-secondary fw-bold text-uppercase ms-2" disabled={(batchListForShow?.length < 2)} onClick={(e) => exportToQB(e)}  data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.BUTTON.EXPORTTOQB">
                                <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                { intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.BUTTON.EXPORT.TO.QB' }) }
                            </button>

                            {
                                tab === 'POSTEDPAYMENTS' &&
                                // BC Export
                                <button onClick={(e) => { exportBusinessCentralFiles(e) }} type='button' className="btn btn-sm btn-secondary fw-bold text-uppercase ms-2" disabled={postedPayments.length==0} data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.BUTTON.BCEXPORT">
                                    <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1" role="status" aria-hidden="true"></span>
                                    {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.BUTTON.EXPORT.TO.BCEXPORT' })}
                                </button>
                            }

                            {/* Export CSV */}
                            <button type='button' className="btn btn-sm btn-secondary fw-bold text-uppercase ms-2" disabled={(batchListForShow?.length < 2)} onClick={(e) => exportBatchCSV(e)}  data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.BUTTON.EXPORTCSV">
                                <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                                { intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.BUTTON.EXPORT.CSV' }) }
                            </button>
                       </> : null
                    }
                    
                </div>

                {/* Close */}
                <div className='col-1 d-flex justify-content-end'>
                    <button type='button' className="my-1 mx-0 btn btn-sm btn-secondary fw-bold text-uppercase" onClick={closePaymentBatchDetail} data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.POSTPAYMENTS.BUTTON.CLOSE">
                        { intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.POST.PAYMENTS.POSTED.PAYMENTS.GRID.BUTTON.CLOSE' }) }
                    </button>  
                </div>
            </div>
        </div>
       
    )
}


export default PostPaymentsTabButtons