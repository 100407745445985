import { useMemo, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import { useAppSelector } from '../../../../redux/hooks';
import { useParams } from 'react-router-dom';
import { USEFLookupMismatchModal } from './USEFLookupMismatchModal';
import DateRenderer from '../../../../modules/output-listing/renderers/DateRenderer';
// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { getExtraNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers';
import HideJsonRenderer from '../../../../modules/output-listing/renderers/HideJsonRenderer';

// Assets
import '../tab.css'
import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider';
import { isValidJson } from '../../../../modules/sgl-utils/SglFunctions';

const USEFMembershipTab = (props) => {
    const intl = useIntl();
    const methods = useFormContext()
    const {horse_id} = useParams();
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const [loading, setLoading] = useState(true)
    const [usef_response_eti_lookup, setUsef_response_eti_lookup] = useState([])
    const [gridRef, setGridRef] = useState()
    const [rowData, setRowData] = useState()
    const [rawData, setRawData] = useState()
    const [measurementRawData, setMeasurementRawData] = useState()
    const alertDialog = useAlert()

    const columnDefs = [
        { field: 'key', maxWidth: 245, minWidth:245, lockVisible: false, lockPosition: 'left', cellStyle: {'border-right': '1px solid #eee'}
        , cellRenderer: 'agGroupCellRenderer',
        cellRendererSelector: function (params) {
            if (params.data.value != null && Array.isArray(params.data.value) && params.data.value?.length > 0) {
                params.node.setExpanded(true);
            }
        }
        },
        { field: 'value', flex:1, lockVisible: false, lockPosition: 'left', cellStyle: {textAlign: 'left'} ,cellRenderer: HideJsonRenderer},
    ]

    useEffect(() => {
        if(gridRef){
            if(methods.getValues('horse.usef_response_eti_lookup') && isValidJson(methods.getValues('horse.usef_response_eti_lookup'))){
                parseUSEFData(JSON.parse(methods.getValues('horse.usef_response_eti_lookup')))
            }
            else{
                gridRef.setRowData([])
                setMeasurementRawData([])
                methods.setValue('microchip_status', '');
            }
        }
    }, [gridRef, methods.getValues('horse.usef_response_eti_lookup')])

    const parseUSEFData = (lookup) => {
        if (lookup){
            var usef_lookup_entries = Object.entries(lookup)
            let usef_data = []
            usef_lookup_entries.map(([key, value]) => {
                if(value != null && Array.isArray(value)){
                    if(key == 'MeasurementCards'){
                        setMeasurementRawData(value)
                    }
                    // else{
                    //     value.map((data) => {
                    //         let lookup = Object.entries(data)
                    //         lookup.map(([key, value]) => {
                    //             usef_data.push({'key': key, "value": value})
                    //         })
                    //     })
                    // }
                }
                // else{
                    usef_data.push({'key':key, "value":value})
                // }
            })

            if (lookup.hasOwnProperty("MicrochipStatus")) {
                const microchipStatus = lookup["MicrochipStatus"];
                methods.setValue("microchip_status", microchipStatus);
            }
    
            gridRef.setRowData(usef_data)
        }
    }

    const measCardColumnDefs = [
        { field: 'MeasurementDate',flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.DATE' }), lockVisible: false, lockPosition: 'left', cellClass: 'text-center', cellRenderer: DateRenderer },
        { field: 'Height',flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.HEIGHT' }), lockVisible: false, lockPosition: 'left' },
        { field: 'CardType',flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.CARDTYPE' }) ,lockVisible: false, lockPosition: 'left' },
        { field: 'LeftHeel', flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.LEFTHEEL' }), lockVisible: false, lockPosition: 'left' },
        { field: 'RightHeel',flex:1, headerName: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.RIGHTHEEL' }), lockVisible: false, lockPosition: 'left' },
    ]

    const containerStyle = useMemo(() => ({ width: '100%', height: '400px' }), []);
    const measCardContainerStyle = useMemo(() => ({ width: '100%', height: '200px' }), []);

    const lookup_data = () => { 
        let memObj = {}, org = "USEF";
        props.membershipGridState.forEachNode(node => {
          if(org == node.data.organization_code)
          memObj = node.data
        });
        if(!isNaN(memObj.membership_number) && memObj.membership_number > 0){
            props.getLookupData(org, memObj, 'usefLookup')
        } else {
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.ERROR.USEF.INVALID' })})
        } 
    }
    
    return (
        <>
            <div className='form-group'>
                <div className='row align-items-center mb-10 membership-data-grid'>
                    {props.showUsefMismatchForm && <USEFLookupMismatchModal show={props.showUsefMismatchForm} handleClose={() => props.setShowUsefMismatchForm(false)} usef_lookup_field={methods.getValues('usef_lookup_field')} horse={'horse'} />}
                    <OutputListingGridStatic  area ={"Horses - Additional USEFMembership"} setGridRef={setGridRef} columnDefs={columnDefs} rowData={rowData?rowData:[]} containerStyle={containerStyle} rowHeight={getExtraNarrowRowHeight} suppressRowClickSelection={true} masterDetail={true} keepDetailRows={true} keepDetailRowsCount={2} detailRowAutoHeight={true}></OutputListingGridStatic>
                </div>
                <div className='row align-items-center mb-2'>
                    <label className='col-lg-12 col-form-label fw-bold fs-5 py-1'>{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.TAB.USEFMEMBERSHIP.LABEL.MEASCARD' })}</label>
                    <div className='col-lg-12'></div>
                    <OutputListingGridStatic  area ={"Horses - MeasCard"} columnDefs={measCardColumnDefs} rowData={measurementRawData?measurementRawData:[]} containerStyle={measCardContainerStyle} rowHeight={getExtraNarrowRowHeight}></OutputListingGridStatic>
                </div>
                <div className='row align-items-center mb-2'>
                    <div className='col-lg-2 w-175px'>
                        <button type="button" onClick={() => (lookup_data())} className="btn btn-sm btn-secondary fw-bold h-30px py-1 text-uppercase">
                            {intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.ADDITIONAL.UPDATE' })}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export { USEFMembershipTab }