import { useState, useEffect, useCallback} from 'react'
import { useIntl } from 'react-intl'

// Custom Components
import { GeneralTab } from './Detail/GeneralTab'
import { HistoryTab } from '../../modules/components/HistoryTab'
import { EntriesTab } from './Detail/EntriesTab'
import { ResultsTab } from './Detail/ResultsTab'
import { GeneralPutOnHoldForm } from './Detail/GeneralPutOnHoldForm'
import { AdditionalTab } from './Detail/AdditionalTab'
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import { useForm, FormProvider } from "react-hook-form";
import { useAppSelector } from '../../redux/hooks';
import axios from 'axios'
import { useParams } from 'react-router-dom';
import {useConfirm, useAlert, useLoadingOverlay } from '../../modules/sgl-utils/DialogsProvider';
import { useNavigate } from 'react-router-dom';
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions'
import DetailPageModal from '../../modules/sgl-utils/DetailPageModal';
import {PageTitle} from '../../../_metronic/layout/core'
import history from '../../modules/sgl-utils/unstableHistory'
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt'
import useAccessChecker from '../../modules/hooks/use-access-checker'
import { useSyncUpdateContext } from '../../modules/output-listing/synchronizeUpdateContext'
import moment from 'moment'
import useAutoFocus from '../../modules/hooks/use-auto-focus'
import HelpLink from '../../modules/components/HelpLink'

const { PUBLIC_URL } = process.env

export const dirtyValues = (
    dirtyFields,
    allValues
  ) => {
    // NOTE: Recursive function.
  
    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
      return allValues;
    }
  
    // Here, we have an object.
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [
        key,
        dirtyValues(dirtyFields[key], allValues[key])
      ])
    );
  };

const HorseDetail = (props) => {
    const [showPutOnHoldForm, setShowPutOnHoldForm] = useState(false)

    const intl = useIntl();
    const {horse_id} = useParams();
    let current_horse_id = 	props.id || props.id == 0 ? props.id : horse_id
    const customer_id = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const [form_meta, setFormMeta] = useState([])
    const [loading, setLoading] = useState(true)
    const confirmDialog = useConfirm()
    const alertDialog = useAlert()
    const { handleDetailPageCatch } = useAccessChecker()
    const navigate = useNavigate();
    const [putonhold, setPutonHold] = useState()
    const loadingOverlay = useLoadingOverlay()
    const [rowClicked, setRowClicked] = useState("")
    const [record_id, setRecordId] = useState("")
    const [sub_area, setSubArea] = useState("")
    const [child_component, setChildComponent] = useState("")
    const [open_forms, setOpenForms] = useState([])
    const [gridRowData, setGridRowData] = useState('')
    const getDetailPageInfo = (id, area, component, data) => {
        setRecordId(id)
        setSubArea(area)
        setChildComponent(component)
        sessionStorage.setItem("dialog_is_open_in_horse", true)
        setRowClicked(rowClicked => !rowClicked)
        const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
        setOpenForms(forms_open)
        setGridRowData(data)
    };
    const { removeAllEventListeners, addSubscribedEvents, sse } = useSyncUpdateContext()
    const [syncEvent, setSyncEvent] = useState(null);
    
    // To open same detail page multiple times 
    useEffect(() => {
        if(sessionStorage.getItem("dialog_is_open_in_horse") == "true"){
            sessionStorage.removeItem("dialog_is_open_in_horse")
            setRowClicked(true)
            const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
            if(open_forms.includes(sub_area+"_"+record_id) == false){
                forms_open.push(sub_area+"_"+record_id);
                sessionStorage.setItem('forms_open', JSON.stringify(forms_open));
            }
        }
    }, [rowClicked])

	const [update_data, setUpdateData] = useState(0)
	// prevent function being recreated on state change
    const updateData = useCallback(() => {setUpdateData(update_data + 1) }, [update_data]);

    const methods = useForm({
    defaultValues: {
        "horse": {"sync_id": "",
        "horse_id": 0,
        "name": "",
        "color": "",
        "sex": "",
        "age": 0,
        "usef_no": "0",
        "aqha_no": "",
        "green": "",
        "type": "",
        "height": "0",
        "rider_id": 0,
        "meas_card_no": "", 
        "dob": "0000-00-00",
        "breed": "",
        "ownerid": 0,
        "sire": "",
        "dam": "",
        "dormant": 0,
        "heel": "",
        "breedregistrynumber": "",
        "usef_status": "",
        "suspended": 0,
        "usdf_no": "",
        "usea_no": "",
        "trainer_id": 0,
        "stable_id": 0,
        "status": "",
        "responsibleparty_id": 0,
        "usef_suspended": 0,
        "usef_suspendcheckdate": "0000-00-00",
        "usef_suspendchecktime": "00:00:00",
        "entry_user": "",
        "entry_date": "0000-00-00",
        "temp_membership_one": "",
        "temp_membership_two": "",
        "temp_membership_three": "",
        "temp_membership_four": "",
        "temp_membership_five": "",
        "temp_owner_name": "",
        "temp_stable_with": "",
        "prizemoneyrecipient_id": 0,
        "sireofdam": "",
        "country": "",
        "age_text": "",
        "creation_user": "",
        "modified_user": "",
        "creation_date": "0000-00-00",
        "modified_date": "0000-00-00",
        "creation_time": "00:00:00",
        "modified_time": "00:00:00",
        "coggins_date": "0000-00-00",
        "customer_id": 0,
        "sgl_id": 0,
        "web_add": 0,
        "web_batchid": 0,
        "ec_ppn": "",
        "ec_status": "",
        "ec_standingsstatus": "",
        "ec_statuscheckdate": "0000-00-00",
        "ec_statuschecktime": "00:00:00",
        "ec_lifetimenumber": "",
        "section": "",
        "ushjahorseregistration": "",
        "usef_membership_year": "0000",
        "web_id": 0,
        "ec_fee_paid_year": "",
        "dam_s_sire": "",
        "second_sire": "",
        "third_sire": "",
        "sale_price": 0,
        "sale_contact_name": "",
        "sale_contact_number": "",
        "fei_id": "",
        "fei_isactive": 0,
        "fei_statuscheckdate": "0000-00-00",
        "fei_statuschecktime": "00:00:00",
        "fei_membership_year": "",
        "fei_inactive_reason": "",
        "fei_registration_date": "",
        "fei_discipline_code": "",
        "fei_birthcountry_code": "",
        "fei_ueln": "",
        "fei_recognition_code": "",
        "fei_birth_name": "",
        "horse_uuid": "",
        "fei_current_name": "",
        "usef_response_eti_lookup": "",
        "breeder": "",
        "microchip_number": "",
        "ueln": "",
        "foreigner": 0,
        "microchip_status": "",
        "usef_age_verified": 0,
        "height_centimeters": 0,
        "usef_measurement_cards": "",
        "measurement_override_date": "0000-00-00",
        "owner_not_in_good_standing": 0,
        "needs_measurement": 0,
        "ushja_id": "",
        "ushja_registration_type": "",
        "ushja_statuscheckdate": "0000-00-00",
        "ushja_statuschecktime": "00:00:00",
        "ushja_membership_start": "0000-00-00",
        "ushja_membership_end": "0000-00-00T00:00:00",
        "on_vet_hold": 0,
        "on_vet_hold_reason": "",
        "declaration": "",
        "vet_statement": "",
        "master_id": 0,
        "norcal_id": "",
        "norcal_statuscheckdate": "0000-00-00",
        "norcal_statuschecktime": "00:00:00",
        "norcal_membership_start": "",
        "norcal_membership_end": "",
        "norcal_registration_type": "",
        "norcal_registration_status": "",
        "norcal_lookup_response": "",
        "ec_lookup_response": "",
        "fei_lookup_response": "",
        "ushja_lookup_response": ""
    },
    'organization_members_added': [],
    'memberships_removed': [],
    'membership_override_added': [],
    'membership_override_removed': [],
    'lookup_organization': ''
    }
  }); 
  const { register,unregister, handleSubmit, reset, formState, setFocus } = methods
  const { dirtyFields } = formState;
  useNavigationPrompt(formState)
  const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
  const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);

    const tabs = ["GENERAL", "ADDITIONAL", "ENTRIES", "RESULTS", "HISTORY"]
    const componentTags = [
        <GeneralTab form_meta={form_meta} />, 
        <AdditionalTab form_meta={form_meta} />, 
        <EntriesTab callbackFromParent={getDetailPageInfo}/>, 
        <ResultsTab />, 
        <HistoryTab area={"Horses"} rowData={methods.getValues('change_history_logs')} />
    ]
    const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});

    useEffect(() => {
        // Cleanup on unmount
        return () => unregister(); // This will unregister all fields
    }, [unregister]);

  useEffect(() => {
        // Show loading overlay
        loadingOverlay({show: true})
        axios.all([
        axios.get( process.env.REACT_APP_NEST_API_URL + '/horses/detail', {
            params: {
            horse_id: current_horse_id,
            customer_id: customer_id,
            show_id: currentShowID
            }
        }),
        axios.get(process.env.REACT_APP_NEST_API_URL + '/horses/MetaData', {
            params: {
            show_id : currentShowID,
            horse_id: current_horse_id,
            customer_id: customer_id
            },
        }),
        ])  
        .then (
        axios.spread( ( {data : horseData}, {data: metaData}) => {
            //Check if record id is invalid then redirect to list page
            if ((horseData || horseData === '') && isInvalidRecord(horseData.horse, current_horse_id, 'horse_id')){
                navigate('/horses')
                loadingOverlay({show: false})
            }
            setFormMeta(metaData)

            // When opened from entries update horse data if changed from entries
            if(props?.updatedData){
                for(let key of Object.keys(props.updatedData.horse_data)){
                    if(horseData.horse[key] != props.updatedData.horse_data[key]){
                        methods.setValue(`horse.${key}`, props.updatedData.horse_data[key], {shouldDirty:true})
                    }
                }
                
                let membership_added = []
                for(let membership of props.updatedData.memberships_data){
                    let organization = horseData.organizationMembers.find((org) => org.organization_code == membership.organization_code)

                    if(organization.membership_number != membership.membership_number || 
                        organization.valid_end != membership.valid_end || organization.valid_start != membership.valid_start){
                            membership_added.push(membership)
                            organization.membership_number = membership.membership_number
                            organization.valid_end = membership.valid_end
                            organization.valid_start = membership.valid_start
                    }
                }
                if(membership_added.length > 0){
                    methods.setValue('organization_members_added', membership_added, { shouldDirty: true })
                    horseData = {
                        ...horseData, 
                        organization_members_added: methods.getValues('organization_members_added')
                    }
                }

                if(props?.updatedData?.membership_override_removed?.length > 0 || props?.updatedData?.membership_override_added?.length > 0){
                    methods.setValue('organizationMembers', props?.updatedData?.memberships_data, { shouldDirty: true })
                    horseData = {
                        ...horseData, 
                        organizationMembers: props?.updatedData?.memberships_data
                    }

                    if(props?.updatedData?.membership_override_removed?.length > 0){
                        methods.setValue('membership_override_removed', props?.updatedData?.membership_override_removed, { shouldDirty: true })
                        horseData = {
                            ...horseData, 
                            membership_override_removed: props?.updatedData?.membership_override_removed
                        }
                    }
    
                    if(props?.updatedData?.membership_override_added?.length > 0){
                        methods.setValue('membership_override_added', props?.updatedData?.membership_override_added, { shouldDirty: true })
                        horseData = {
                            ...horseData, 
                            membership_override_added: props?.updatedData?.membership_override_added
                        }
                    }
                }
            }

            if(props?.id === undefined && Number(methods.getValues('horse.horse_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                reset(horseData)
            }else{
                if(props?.updatedData?.horse_data?.sex){
                    reset({ ...horseData, horse: { ...horseData.horse, sex: props.updatedData.horse_data.sex  } }, { keepDirtyValues: true });
                }else{
                    reset(horseData, { keepDirtyValues: true });
                }
            }
            setPutonHold(horseData.horse && horseData.horse.on_vet_hold && horseData.horse.on_vet_hold_reason.length > 0)
            if(sessionStorage.getItem('forms_open') == undefined || current_horse_id == horse_id){
                sessionStorage.setItem('forms_open', JSON.stringify(["Horses_"+Number(current_horse_id)]));
            }
         
            // Hide loading overlay
            loadingOverlay({show: false})
            setLoading(false)
        }))
        .catch(reason => {
            handleDetailPageCatch(reason.response, loadingOverlay, props)
        })
  },[/*currentShowID,*/ horse_id, update_data])

    useEffect(() => {
        const next_button = document?.getElementById('next-btn');
        const last_button = document?.getElementById('last-btn');
        const prev_button = document?.getElementById('prev-btn');
        const first_button = document?.getElementById('first-btn');
        if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
            next_button.disabled = true;
            last_button.disabled = true;
        }
        if (row_selected == 0 && prev_button && first_button) {
            prev_button.disabled = true;
            first_button.disabled = true;
        }
    }, [loading])

    useEffect(() => { // Update Call back useEffect
        if(syncEvent){
            let parsedEvent = JSON.parse(syncEvent.data)
            let variableName = ''
            let updateFlag = false
            
            switch(parsedEvent.type){
                case `Horses-${customer_id}-${current_horse_id}`:
                    variableName = 'horse'
                    updateFlag = true
                    break;
            }

            if(updateFlag){                

                axios.get( process.env.REACT_APP_NEST_API_URL + '/horses/detail', {
                    params: {
                    horse_id: current_horse_id,
                    customer_id: customer_id,
                    show_id: currentShowID
                    }
                }).then((res) => {
                    if(parsedEvent.type.includes('Horses')){
                        if('organization_members_added' in dirtyFields){
                            let memberships = res.data['organizationMembers']
                            let updatedMemberships = methods.getValues('organization_members_added')
                            memberships = memberships.map(membership => {
                                let updatedMembership = updatedMemberships.find(m => m.organization_code == membership.organization_code)
                                if(updatedMembership){
                                    membership = updatedMembership
                                }
                                return membership
                            })

                            res.data['organizationMembers'] = memberships
                        }
                        // Keep Dirty Values can't handle arrays, so need to manually handle arrays
                        reset(res.data, { keepDirtyValues: true })
                    }
                })
            }            
        }
    }, [syncEvent]);

    useEffect(() => { // Remove all existing event listener and then generate and add new events with callback in SSE on change of output grid area
        removeAllEventListeners()
        return // SK - Disabling sync
        if(currentShowID && current_horse_id && sse && current_horse_id > 0){
            let syncEvents = []
            let eventIds = []
            // For Areas who contains show_id should add show id in its event id
            eventIds = [
                `Horses-${customer_id}-${current_horse_id}`,
            ]

            for(let eventId of eventIds){
                // Sync Event will hold an array of eventId and callback
                syncEvents.push({
                    eventId, 
                    callback: (event) => setSyncEvent(event)
                })
            }
            addSubscribedEvents(syncEvents)
        }

        return () => removeAllEventListeners()

    }, [customer_id, currentShowID, current_horse_id, sse]);

  const onSubmit = async (form_data) => {
        const next_button = document.getElementById('next-btn');
        const last_button = document.getElementById('last-btn');
        const prev_button = document.getElementById('prev-btn');
        const first_button = document.getElementById('first-btn');

        // means detail page is not open in modal
        if(props?.id === undefined){
            next_button.disabled = false;
            prev_button.disabled = false;
            last_button.disabled = false;
            first_button.disabled = false;
        }

        let submit_data = dirtyValues(dirtyFields, form_data)
        if(submit_data?.organization_members_added && submit_data?.organization_members_added?.length > 0){
            for (const membership of submit_data?.organization_members_added){
                membership.valid_start = (membership.valid_start && moment(membership.valid_start).isValid())?moment(membership.valid_start).format('YYYY-MM-DD'):'';
                membership.valid_end = (membership.valid_end && moment(membership.valid_end).isValid())?moment(membership.valid_end).format('YYYY-MM-DD'):'';
            }
        }

        if(submit_data.horse && submit_data.horse.name && !submit_data?.horse?.name?.trim()){
            alertDialog({message: "Horse name is required"})
            return 
        }

        loadingOverlay({show: true})
        return axios.post( process.env.REACT_APP_NEST_API_URL + '/horses/detail', {
        params: {
            horse_id: current_horse_id,
            customer_id: customer_id,
            data: submit_data,
            show_id: currentShowID
        }
        }).then((response) => {
            loadingOverlay({show: false})
            if(response.data.success) {
                // Use Case (Param Id changed): Next/Prev buttons
                if(props?.id == undefined && Number(methods.getValues('horse.horse_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                    if(row_selected && row_selected != "" && row_ids[row_selected]){
                        setRowClicked(false)
                        setLoading(true)
                        history.push(`${PUBLIC_URL}/horses/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
                    }
                }
                else if(props?.id == undefined && current_horse_id > 0){
                    history.push(`${PUBLIC_URL}/horses`, { allowNavigation: true })
                }
                else if(props?.id == undefined && current_horse_id == 0){
					history.push(`${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname), { allowNavigation: true });
				}
                else{
                    if(props.updateCallbackFromParent){
                        props.updateCallbackFromParent()
                        props.closeModal()
                    }
                }
                if( row_selected == row_ids?.length -1 && props?.id === undefined){
                    if (next_button && last_button) {
                        next_button.disabled = true;
                        last_button.disabled = true;
                        prev_button.disabled = false;
                        first_button.disabled = false;
                    }
                  }
                if(row_selected == 0 && props?.id === undefined){
                    if (prev_button && first_button) {
                        prev_button.disabled = true;
                        first_button.disabled = true;
                        next_button.disabled = false;
                        last_button.disabled = false;
                    }
                }
            } else {
                alertDialog({message: response.data.error_message, title: 'Error'})
            }
            
        }).catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        });
    }

    const handlePutonHold = async ()=>{
        if(putonhold){
            let confirmMsg = "Are you sure you want to remove name from Hold?"
            const choice = await confirmDialog({message: confirmMsg})
                if(choice){ 
                    methods.setValue('horse.on_vet_hold_reason', "",{shouldDirty:true})
                    methods.setValue('horse.on_vet_hold', false, {shouldDirty:true})
                    document.getElementById('putonhold').innerHTML ="Put on Hold";
                    document.getElementById('ONVETHOLD').style.display = "none" 
                    setPutonHold(false) 
                } 
        }    
        else{
            setShowPutOnHoldForm(true)
        }
    }

    const changePutonHold = (onVetHold, onVetHoldReason) => {
        methods.setValue('horse.on_vet_hold', onVetHold, {shouldDirty: true})
        methods.setValue('horse.on_vet_hold_reason', onVetHoldReason, {shouldDirty: true})
        setPutonHold(true)
    }

    useAutoFocus('name', loading, update_data)

    return (
        <>
        {/* {!loading && (
			<> */}
            {props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.watch('horse.name'), methods.getValues('horse.horse_id')]}>{intl.formatMessage({ id: 'MENU.HORSES' })}</PageTitle> : ""}
            <div id={"horses_detail_page"} className='modal-body py-3 px-4 horse-input-form input-form'> 
                <FormProvider {...methods}>   
                    {(showPutOnHoldForm) &&
                        <GeneralPutOnHoldForm show={showPutOnHoldForm} handleClose={() => setShowPutOnHoldForm(false)} handleSubmit={(onVetHold, onVetHoldReason) => {changePutonHold(onVetHold, onVetHoldReason)}} onVetHold={'horse.horse_data.on_vet_hold'} onVetHoldReason={'horse.on_vet_hold_reason'} />
                    }
                   <form id={"HorseDetail_"+current_horse_id} noValidate className='form max-width'  onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
                   <HelpLink tooltip="HORSE.DETAIL.LINK.HELP" classname="top" />
                    <div className='card-body p-0'> {/* Card Body */}
                        <div className="form-group row"> {/* Column Group */}
                            <div className='row mb-2'>
                                <div className='col-lg-12'>
                                    <div className='row mb-2'>
                                        <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-50px' htmlFor='name' data-tooltip-id="HORSE.DETAIL.LABEL.NAME">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.LABEL.NAME' })}</label>
                                        <div className='col-lg-4'>
                                            <input
                                                {...register( 'horse.name', 
                                                {
                                                    required: "Horse name is required."
                                                })
                                                }
                                                disabled = {methods.watch('disableName')}
                                                id='name'
                                                type='text'
                                                className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            />
                                            { methods.formState.errors?.horse?.name && <div><span className='error_message'>{methods.formState.errors.horse.name?.message}</span></div>}
                                        </div>
                                        <div className='col-lg-3 w-lg-200px'>
                                            <button type="button" onClick={() => handlePutonHold()} className="btn btn-sm btn-secondary fw-bold h-30px py-1 text-uppercase" id="putonhold" data-tooltip-id="HORSE.DETAIL.BUTTON.PUTONHOLD">
                                                {putonhold?
                                                intl.formatMessage({ id: 'FORM.INPUT.HORSES.LABEL.REMOVEFROMHOLD' })
                                                :intl.formatMessage({ id: 'FORM.INPUT.HORSES.LABEL.PUTONHOLD' })}
                                            </button>
                                        </div>
                                        {putonhold?
                                        <a className='col-lg-1 fw-bold fs-5 py-1 w-lg-150px' htmlFor='NAME' id="ONVETHOLD" data-tooltip-id="HORSE.DETAIL.LINK.ONVETHOLD">{intl.formatMessage({ id: 'FORM.INPUT.HORSES.MODAL.LABEL.ONVETHOLD' })}</a>:""
                                    }
                                    </div>
                                </div>
                            </div> {/* End Column Group */}

                            <input type='hidden' {...register("horse.usef_no")} />
                            <input type='hidden' {...register("horse.usef_suspended")} />
                            <input type='hidden' {...register("horse.usef_suspension_status")} />
                            <input type='hidden' {...register("horse.usef_suspendcheckdate")} />
                            <input type='hidden' {...register("horse.usef_suspendchecktime")} />
                            <input type='hidden' {...register("horse.ec_ppn")} />
                            <input type='hidden' {...register("horse.ec_status")} />
                            <input type='hidden' {...register("horse.ec_suspension_status")} />
                            <input type='hidden' {...register("horse.ec_statuscheckdate")} />
                            <input type='hidden' {...register("horse.ec_statuschecktime")} />
                            <input type='hidden' {...register("horse.fei_id")} />
                            <input type='hidden' {...register("horse.fei_inactive_reason")} />
                            <input type='hidden' {...register("horse.fei_suspension_status")} />
                            <input type='hidden' {...register("horse.fei_statuscheckdate")} />
                            <input type='hidden' {...register("horse.fei_statuschecktime")} />
                            <input type='hidden' {...register("horse.usef_age_verified")} />
                            <input type='hidden' {...register("horse.usef_response_eti_lookup")} />
                            <input type='hidden' {...register("horse.ec_lookup_response")} />
                            <input type='hidden' {...register("horse.fei_lookup_response")} />
                            <input type='hidden' {...register("horse.norcal_lookup_response")} />
                            <input type='hidden' {...register("horse.ushja_lookup_response")} />
                            <input type='hidden' {...register("organization_members_added")} />
                            <input type='hidden' {...register("horse.type")} />
                            <input type='hidden' {...register("horse.height_centimeters")} />
                            <input type='hidden' {...register("microchip_status")} />

                            <div className='d-flex flex-column my-2 border p-2 bg-white'>
                                <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
                                                    <a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == selectedTab ? ' active' : '')}
                                                        tabIndex="-1"
                                                        id={tab + "-tab"}
                                                        data-bs-toggle='tab'
                                                        href={"#" + tab + "-Horse-" + current_horse_id}
                                                        data-tooltip-id={`HORSE.DETAIL.TAB.${tab}`}>{intl.formatMessage({ id: 'FORM.INPUT.HORSES.TAB.' + tab })}
                                                    </a>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>

                                <div className="tab-content mt-5 pb-2" id="myTabContent">
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <div key={index} className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab + "-Horse-" + current_horse_id} role="tabpanel" >
                                                    <HelpLink tooltip={"HORSE.DETAIL.TAB."+tab+".LINK.HELP"}  />
                                                    {componentTags[index]}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div> {/* End Card Body */}
                    </div> {/* End Card Body */}

                    <InputFormFooter goBackPath={'/horses'} propId={props?.id}  data={'horse'}/>
                </form>
            </FormProvider>
            </div>
            {rowClicked && <DetailPageModal area={sub_area} detail_page={child_component} record_id={record_id} updateCallbackFromParent={updateData} open_forms={open_forms} gridRowData={gridRowData}/>}
        	{/* </>
    	)} */}
        </>
    );
}

export { HorseDetail }